import { Pipe, PipeTransform } from "@angular/core";

import { AdaaHelper } from "../utils";

@Pipe({
  name: "dubaiTime",
  pure: true,
  standalone: true,
})
export class DubaiTimePipe implements PipeTransform {
  transform = (value: number | null) => {
    if (value === null || value === undefined) return;
    return AdaaHelper.getDubaiTime(value);
  };
}
