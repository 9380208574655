<adaa-data-table
    titleHeader="government_scores.scopes.main_kpis"
    [configKey]="constants.MAIN_TABLE_LIST_CONF_KEY.GOV_MEASUREMENT_SCOPE_CONF_LIST"
    [enableExport]="false"
    [enableAdd]="enableEdit()"
    [enableEditAction]="enableEdit()"
    [enableDeleteAction]="enableEdit()"
    [isLocalData]="true"
    [localData]="kpisData"
    (addNewClicked)="addNewClicked()"
    (actionClicked)="actionClicked($event)"
></adaa-data-table>
