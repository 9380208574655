import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { FileInputType, PageMode } from "../../../constants/enums";
import { AttachFile, AttachmentModelType, ValidatorModelType, ValueText } from "../../../models";
import { FormDropdownComponent, FormInputComponent, FormInputDateComponent, FormInputFileComponent } from "../../form";

@Component({
  selector: "adaa-attachments-modal",
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    FormDropdownComponent,
    FormInputComponent,
    FormInputDateComponent,
    FormInputFileComponent,
  ],
  templateUrl: "./attachments-modal.component.html",
  styleUrl: "./attachments-modal.component.scss",
})
export class AttachmentsModalComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  modal = inject(NgbActiveModal);

  @Input() attachment: AttachmentModelType;
  @Input() pageMode: PageMode;
  @Input() formValidation: ValidatorModelType;
  @Input() attachmentTypes: ValueText[];
  @Input() enableDate: boolean = true;
  @Input() enableType: boolean = true;
  @Input() showSubmit: boolean = true;

  defaultDate = signal<number>(Date.now());

  attachmentForm: FormGroup;
  submitted: boolean = false;
  PageMode = PageMode;
  allowedFiles: FileInputType[] = [
    FileInputType.pdf,
    FileInputType.txt,
    FileInputType.doc,
    FileInputType.docx,
    FileInputType.xls,
    FileInputType.xlsx,
  ];

  public get title(): string {
    return this._translateService.instant(
      this.pageMode != PageMode.create ? "nkpi.attachment" : "nkpi.add_new_attachment"
    );
  }

  public get allowedFilesMessage(): string {
    if (this.pageMode === PageMode.view) return "";
    else
      return `${this._translateService.instant("attachment.file_types_allowed")} <br> ${this._translateService.instant("attachment.max_file_size")}`;
  }

  public get attachmentFile(): AttachFile {
    return {
      id: this.attachmentForm.get("id")?.value,
      filename: this.attachmentForm.get("filename")?.value ?? "",
    };
  }

  public ngOnInit(): void {
    this.attachmentForm = this._prepareForm();
    this.attachmentForm.patchValue(this.attachment);
  }

  public onFileChange(event: AttachFile | null) {
    this.attachmentForm.get("id")?.setValue(event?.id);
    this.attachmentForm.get("attachFile")?.setValue(event?.id);
    this.attachmentForm.get("filename")?.setValue(event?.filename);
    this.attachmentForm.get("auxAttachFile")?.setValue({
      filename: event?.filename,
      id: event?.id,
      status: Constants.OBJECT_STATUS.ACTIVE,
    });
  }

  public attachmentTypeChanged(value: number) {
    const type = this.attachmentTypes.find((e) => e.value === value);

    if (!type) return;

    this.attachmentForm.get("attachTypeNameAE")?.setValue(type.text);
    this.attachmentForm.get("attachTypeNameEN")?.setValue(type.text);
  }

  public save() {
    this.submitted = true;
    if (!this.attachmentForm.valid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    this.modal.close(this.attachmentForm.getRawValue());
  }

  private _prepareForm() {
    const isDisabled = this.pageMode === PageMode.view;
    return this._formBuilder.group({
      id: { value: null, disabled: isDisabled },
      nameAE: [{ value: null, disabled: isDisabled }, Validators.required],
      nameEN: [{ value: null, disabled: isDisabled }, Validators.required],
      attachDate: this.enableDate
        ? [{ value: AdaaHelper.getDubaiTime(Date.now()), disabled: isDisabled }, Validators.required]
        : { value: AdaaHelper.getDubaiTime(Date.now()), disabled: isDisabled },
      filename: [{ value: null, disabled: isDisabled }, Validators.required],
      attachFile: { value: null, disabled: isDisabled },
      auxAttachFile: { value: null, disabled: isDisabled },
      attachType: this.enableType
        ? [{ value: null, disabled: isDisabled }, Validators.required]
        : { value: null, disabled: isDisabled },
      attachTypeNameAE: { value: null, disabled: isDisabled },
      attachTypeNameEN: { value: null, disabled: isDisabled },
      itemId: { value: null, disabled: isDisabled },
      itemType: { value: null, disabled: isDisabled },
      assocId: { value: null, disabled: isDisabled },
      activityId: { value: null, disabled: isDisabled },
      taskId: { value: null, disabled: isDisabled },
      status: { value: Constants.OBJECT_STATUS.ACTIVE, disabled: isDisabled },
      fakeId: { value: null, disabled: isDisabled },
    });
  }
}
