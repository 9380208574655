import { inject, Injectable, signal } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as localForage from "localforage";

import { AdaaHelper, useNewDS } from "../../core/utils";
import { Constants } from "../constants/constants";
import { AdaaBoolean } from "../constants/enums";
import type { MainResponseModel, ParameterCatalog, ValidatorModelType } from "../models";
import { ValidatorApiService } from ".";

@Injectable({
  providedIn: "root",
})
export class ConsoleService {
  private _validatorApiService = inject(ValidatorApiService);
  private _translateService = inject(TranslateService);
  private _router = inject(Router);

  navConsoleItems = signal<ParameterCatalog[] | undefined>(undefined);
  consoleItems = signal<ParameterCatalog[] | undefined>(undefined);

  public init(useCache: boolean = true): void {
    this.consoleItems.set([]);
    useCache ? this._getConsoleIconsLocally() : this._getConsoleIcons();
  }

  private _getConsoleIcons(): void {
    this._validatorApiService.searchByKey("console_conf").subscribe({
      next: (res) => this._setConsoleIcons(res),
    });
  }

  private _getConsoleIconsLocally(): void {
    const url = "validator/getbysearchkeyv2/console_conf";
    localForage.getItem(`${url}`).then((res) => {
      res == undefined ? this._getConsoleIcons() : this._setConsoleIcons(res as MainResponseModel<ValidatorModelType>);
    });
  }

  private _setConsoleIcons(data: MainResponseModel<ValidatorModelType>): void {
    const items = this._handleSpecialCases(data);

    this.navConsoleItems.set(items.filter((e) => e.visibleParameter === AdaaBoolean.Y && "navbar" === e.url));

    this.consoleItems.set(
      items.filter((e) => e.visibleParameter === AdaaBoolean.Y && this._router.url === "/" + e.url)
    );
  }

  private _handleSpecialCases(data: MainResponseModel<ValidatorModelType>) {
    const entityId = AdaaHelper.getLocalStorage("entity", { property: "id", type: "prop" });

    const items = data.responseData.parameterCatalogs.reduce<ParameterCatalog[]>((list, item) => {
      if (item.fieldName === "initiatives") {
        const alreadyExists = list.find(({ fieldName }) => fieldName === "initiatives");
        if (alreadyExists) return list;

        const key = !useNewDS() ? "breadcrumbs.initiative_module.list" : "national_projects.projects";

        return [
          ...list,
          {
            ...item,
            nameAE: this._translateService.instant(key),
            nameEN: this._translateService.instant(key),
            urlRoute: !useNewDS() ? "/console/initiatives/list" : "/console/initiatives",
          },
        ];
      }
      if (item.fieldName === "objectives") {
        return [
          ...list,
          {
            ...item,
            urlRoute:
              Constants.CONSTANT_PMO_ID === Number(entityId) ? "/console/pmo-objectives" : "/console/objectives",
          },
        ];
      }

      return [...list, item];
    }, []);

    return items;
  }
}
