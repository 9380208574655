export enum Language {
  Arabic = "ae",
  English = "en",
}

export enum VerificationMethod {
  SMS = "SMS",
  EMAIL = "EMAIL",
  QUESTIONS = "QUESTIONS",
}

export enum ENTITY_FLAGS {
  MUST_BE_PMO = 1,
  MUST_NOT_BE_PMO = 2,
}

export enum CellType {
  text = "text",
  percentage = "percentage",
  percentagePeriod = "percentage_period",
  trend = "trend",
  metric = "metric",
  workflow = "workflow",
  rules = "rules",
  yesNo = "yes_no",
  trueFalse = "true_false",
  propList = "propList",
  checkbox = "checkbox",
  date = "date",
  datetime = "datetime",
  status = "status",
  specialCase = "special_case",
  enabled = "enabled",
  dropdown = "dropdown",
  html = "html",
  actual = "actual",
}

export enum Direction {
  LTR = "ltr",
  RTL = "rtl",
}

export enum AdaaBoolean {
  Y = "Y",
  N = "N",
}

export enum ActionsEvents {
  view = "view",
  edit = "edit",
  delete = "delete",
  connections = "connections",
  custom = "custom",
}

export enum FilterType {
  date = "date",
  dateRange = "date_range",
  dropdownSingle = "dropdown_single",
  dropdownMulti = "dropdown_multi",
  dropdownTree = "dropdown_tree",
  textFields = "text",
  ignored = "no_filter",
  valueRange = "value_range",
}

export enum PermissionAction {
  view = "view",
  create = "create",
  manage = "manage",
  execute = "execute",
}

export enum PermissionEntityFlag {
  pmo = 1,
  notPmo = 2,
}

export enum PublishedState {
  published = 350,
  notPublished = 351,
}

export enum PageMode {
  view = "view",
  edit = "edit",
  create = "create",
}

export enum AdministrativeTitle {
  minister = 1,
  directorGeneral = 2,
}

export enum FileInputType {
  pdf = ".pdf",
  txt = ".txt",
  doc = ".doc",
  docx = ".docx",
  png = ".png",
  jpg = ".jpg",
  gif = ".gif",
  jpeg = ".jpeg",
  bmp = ".bmp",
  svg = ".svg",
  zip = ".zip",
  xls = ".xls",
  xlsx = ".xlsx",
  ppt = ".ppt",
  pptx = ".pptx",
  csv = ".csv",
}

export enum NonSystemTeamMemberType {
  ADAA_ENTITY = 1,
  OTHER_ENTITY = 2,
  PARENT_PROJECT = 3,
}

export enum NtkpiContinuityOptions {
  CONTINUITY = "continuity",
  COMPLETED = "completed",
}

export enum TpAgmStatus {
  ALL = 0,
  COMPLETED = "completed",
  ON_TIME = "onTime",
  DELAYED = "delayed",
  MOST_COMPLETED = "mostCompleted",
  MOST_DELAYED = "mostDelayed",
  BEST_VISION = "bestVision",
  LOWEST_VISION = "lowestVision",
}

export enum UaePassErrors {
  CANCELLED_ON_WEB = "Cancelled+by+user+on+UAE+PASS+web.",
  CANCELLED_ON_APP = "Cancelled+by+user+on+UAE+PASS+app.",
  USER_CLICKED_ON_EXIT = "User+clicks+on+exit+on+UAE+PASS+notification+during+data+sharing.",
}

export enum DIMENSIONS_SYNC_TYPES {
  STRUCTURE = 1,
  VALUE = 2,
}

export enum ImportExportStatus {
  SUCCESS = "SUCCESS",
  DONE = "DONE",
  PENDING = "PENDING",
  FAIL = "FAIL",
}

export enum ImportExportStatusValues {
  SUCCESS = 0,
  DONE = 1,
  PENDING = 2,
  FAIL = 3,
}

export enum ImportExportType {
  EXPORT = "EXPORT",
  IMPORT = "IMPORT",
}

export enum ImportExportObjectType {
  PLAN = "PLAN",
  KPIDATA = "KPIDATA",
  KPI = "KPI",
}

export enum WFTriggerRuleType {
  FIELD_CHECK = 1,
  VALUE_CHECK = 2,
  FALL_BACK_CHECK = 4,
}
