<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center fw-bold">{{ "kpi.kpis_list" | translate }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden">
        <adaa-data-table
            [apiPrefix]="periodType() === 'year' ? 'calculationreadingannual' : 'calculationreading'"
            apiName="getKpiScoresByAggregationType"
            [paramsString]="paramString()"
            [configKey]="modalType() === 'pmo' ? pmoPerfAggConfList : kpiPerfAggConfList"
            [enableAdd]="false"
            [enableEditAction]="false"
            [enableDeleteAction]="false"
            (actionClicked)="actionClicked($event)"
        ></adaa-data-table>
    </div>
</ng-template>
