import { inject, Injectable, signal } from "@angular/core";
import { catchError, EMPTY, Subject, switchMap, tap } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import type { PeriodModelType, SelectedPeriodModelType } from "../models";
import { PeriodApiService } from "./period-api.service";

@Injectable({
  providedIn: "root",
})
export class PeriodSliderService {
  private _periodApiService = inject(PeriodApiService);

  startDate = signal<number | undefined>(undefined);
  endDate = signal<number | undefined>(undefined);
  isReady = signal<boolean>(true);
  actualPeriod = signal<SelectedPeriodModelType | undefined>(undefined);
  periodSlots = signal<
    {
      year: number;
      quarters: PeriodModelType[];
    }[]
  >([]);

  private readonly _triggerRefresh = new Subject<void>();
  readonly triggerRefresh$ = this._triggerRefresh.asObservable();

  public init(type: "quarter" | "year" = "quarter", frequency: number) {
    this.isReady.set(false);
    this.actualPeriod.set(undefined);

    if (frequency === Constants.FREQUENCY.ANNUAL) {
      type = "year";
    }

    return this.getActualPeriod(type).pipe(switchMap(() => this._fetchPeriods(frequency)));
  }

  public getActualPeriod(type: "quarter" | "year" = "quarter", useLoader = false) {
    return this._periodApiService.getActualPeriod({ useLoader }).pipe(
      tap({
        next: (res) => {
          if (res.inError) return;

          this.actualPeriod.update(() => ({ type: type, period: this._resolveActualPeriod(res.responseData) }));
        },
      })
    );
  }

  private _fetchPeriods(frequency: number) {
    return this._periodApiService
      .getDateRange({
        startTS: this.startDate() ?? AdaaHelper.getDubaiTime(AdaaHelper.plan?.startYear as number),
        endTS: this.endDate() ?? AdaaHelper.getDubaiTime(AdaaHelper.plan?.endYear as number),
        frequency,
        useLoader: false,
      })
      .pipe(
        tap({
          next: (res) => {
            if (res.inError) {
              this._generatePeriodSlots([], frequency);
              return;
            }
            const periodSlots = this._generatePeriodSlots(res.responseData, frequency);
            this.periodSlots.update(() => periodSlots);
            this.isReady.set(true);
          },
        }),
        catchError(() => {
          this.isReady.set(true);
          return EMPTY;
        })
      );
  }

  private _generatePeriodSlots(input: PeriodModelType[], frequency: number) {
    if (frequency === Constants.FREQUENCY.ANNUAL) {
      return this._generateYearly(input);
    }
    return this._generateQuarterly(input);
  }

  private _generateYearly(input: PeriodModelType[]) {
    const slots = [];
    for (const item of input) {
      slots.push({ year: item.year, quarters: [item] });
    }
    return slots;
  }

  private _generateQuarterly(input: PeriodModelType[]) {
    const years = Array.from(new Set(input.map(({ year }) => year)));
    const slots = [];

    for (const item of years) {
      const quarters: PeriodModelType[] = [];
      const list = input.filter(({ year }) => item === year);

      if (list.length > 4) {
        list.forEach((_, index) => {
          if (index === 2) {
            quarters.push(list[index]);
          } else if (index === 5) {
            quarters.push(list[index]);
          } else if (index === 8) {
            quarters.push(list[index]);
          } else if (index === 11) {
            quarters.push(list[index]);
          }
        });
      } else {
        quarters.push(...list);
      }

      slots.push({ year: item, quarters });
    }

    return slots;
  }

  private _resolveActualPeriod(period: PeriodModelType): PeriodModelType {
    // month 1 - 2
    if (+period.month >= 1 && +period.month < 3) {
      period.id = period.id + (3 - +period.month);
      period.month = 3;
      return period;
    }

    // month 4 - 5
    if (+period.month >= 4 && +period.month < 6) {
      period.id = period.id + (6 - +period.month);
      period.month = 6;
      return period;
    }

    // month 7 - 8
    if (+period.month >= 7 && +period.month < 9) {
      period.id = period.id + (9 - +period.month);
      period.month = 9;
      return period;
    }

    // month 10 - 11
    if (+period.month >= 10 && +period.month < 12) {
      period.id = period.id + (12 - +period.month);
      period.month = 12;
      return period;
    }

    return period;
  }
}
