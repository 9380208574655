import { Injectable } from "@angular/core";
import { NgbDateAdapter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";

import { Constants } from "../../shared/constants/constants";

@Injectable({
  providedIn: "root",
})
export class InputDateAdapter extends NgbDateAdapter<number> {
  readonly DELIMITER = "-";

  public fromModel(value: number | null): NgbDateStruct | null {
    if (value) {
      const date = moment(value).utcOffset(Constants.uaeTimezone);
      return {
        day: date.date(),
        month: date.month() + 1,
        year: date.year(),
      };
    }
    return null;
  }

  public toModel(date: NgbDateStruct | null): number | null {
    if (date) {
      let stringDate = date.year.toString();
      stringDate +=
        date.month.toString().length == 1
          ? `${this.DELIMITER}0${date.month.toString()}`
          : `${this.DELIMITER}${date.month.toString()}`;
      stringDate +=
        date.day.toString().length == 1
          ? `${this.DELIMITER}0${date.day.toString()}`
          : `${this.DELIMITER}${date.day.toString()}`;
      return moment(stringDate).utcOffset(Constants.uaeTimezone).valueOf();
    } else return null;
  }
}
