import { Component, computed, inject, input, output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { PageMode } from "../../../constants/enums";
import { CustomTableButton, TableButtonClicked, ValueText, WorkflowTriggerConfig } from "../../../models";
import { LanguageService } from "../../../services";
import { ConfirmationModalComponent } from "../../general";
import { ItemsOrderModalComponent } from "../../modals";
import { DataTableComponent } from "../../table/data-table/data-table.component";
import { WorkflowTriggersRulesModalComponent } from "../workflow-triggers-rules-modal/workflow-triggers-rules-modal.component";

@Component({
  selector: "adaa-workflow-triggers-rules-list",
  imports: [DataTableComponent],
  templateUrl: "./workflow-triggers-rules-list.component.html",
  styleUrl: "./workflow-triggers-rules-list.component.scss",
})
export class WorkflowTriggersRulesListComponent {
  private _modalService = inject(NgbModal);
  private _languageService = inject(LanguageService);

  rules = input.required<WorkflowTriggerConfig[]>();
  pageMode = input.required<PageMode>();
  enableAdd = input.required<boolean>();
  entityId = input.required<number>();
  actionTypeId = input.required<number>();
  itemTypeId = input.required<number>();

  rulesChanged = output<WorkflowTriggerConfig[]>();

  isView = computed(() => this.pageMode() === PageMode.view);

  public get filteredRules() {
    return this.rules()
      .sort((a, b) => {
        if (a.triggerOrder < b.triggerOrder) return -1;
        if (a.triggerOrder > b.triggerOrder) return 1;
        return 0;
      })
      .filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE);
  }

  Constants = Constants;
  customButtons = computed<CustomTableButton[]>(() =>
    this.isView()
      ? []
      : [
          {
            eventName: "sort",
            iconTitle: "navigation.tooltips.order_tooltip",
            iconName: "adaa-icon-sort",
          },
        ]
  );

  public addNewClicked(): void {
    const rule: WorkflowTriggerConfig = {
      id: undefined,
      field: undefined,
      nameAE: undefined,
      nameEN: undefined,
      operator: undefined,
      order: this._getMaxOrder(),
      status: Constants.OBJECT_STATUS.ACTIVE,
      triggerOrder: this._getMaxOrder(),
      value: undefined,
      wfConfigId: undefined,
      workflowRuleTypeId: undefined,
      workflowTemplateId: undefined,
      workflowTemplateNameAE: undefined,
      workflowTemplateNameEN: undefined,
      fakeId: Math.floor(Math.random() * 1000),
    };

    this._openEditorModal(rule, PageMode.create);
  }

  public actionClicked(action: TableButtonClicked): void {
    switch (action.event) {
      case "delete":
        this._deleteRule(action.data);
        break;
      case "edit":
        this._openEditorModal(action.data, PageMode.edit);
        break;
      case "view":
        this._openEditorModal(action.data, PageMode.view);
        break;
      default:
        break;
    }
  }

  public buttonClicked(): void {
    const modal = this._modalService.open(ItemsOrderModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    const data: ValueText[] = [];

    this.rules()
      .filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE)
      .forEach((e) => data.push({ value: e.id ? e.id : e.fakeId, text: AdaaHelper.getItemValueByToken(e, "name") }));

    modal.componentInstance.data = data;
    modal.componentInstance.inputLabel = "workflow.trigger_rules_table";

    modal.result.then((result: number[] | undefined) => {
      if (!result) return;
      this.rules().forEach((rule) => {
        const index = result.findIndex((e) => e === rule.id || e === rule.fakeId);
        rule.order = index + 1;
        rule.triggerOrder = index + 1;
      });

      this.rulesChanged.emit(this.rules());
    });
  }

  private _getMaxOrder(): number {
    const rule = this.rules().reduce(
      (max, current) => (current.triggerOrder > max.triggerOrder ? current : max),
      this.rules()[0]
    );

    return rule ? rule.triggerOrder + 1 : 1;
  }

  private _openEditorModal(rule: WorkflowTriggerConfig, pageMode: PageMode): void {
    const modal = this._modalService.open(WorkflowTriggersRulesModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.rule = rule;
    modal.componentInstance.pageMode = pageMode;
    modal.componentInstance.entityId = this.entityId();
    modal.componentInstance.actionTypeId = this.actionTypeId();
    modal.componentInstance.itemTypeId = this.itemTypeId();

    modal.result.then((rule) => {
      if (!rule) return;

      if (pageMode === PageMode.create) this.rules().push(rule);
      else {
        const index = this.rules().findIndex((e) => (rule.id ? e.id === rule.id : e.fakeId === rule.fakeId));
        this.rules().splice(index, 1, rule);
      }

      this.rulesChanged.emit(this.rules());
    });
  }

  private _deleteRule(data: WorkflowTriggerConfig): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = "common.form.modals.delete_confirmation";
    modal.componentInstance.title = "workflow.delete_rule_confirmation";

    modal.result.then((e) => {
      if (e) this._deleteRuleConfirmed(data);
    });
  }

  private _deleteRuleConfirmed(data: WorkflowTriggerConfig): void {
    if (data.fakeId) {
      const index = this.rules().findIndex((e) => e.fakeId === data.fakeId);
      if (index > -1) this.rules().splice(index, 1);
    } else {
      const rule = this.rules().find((e) => e.id === data.id);
      if (rule) rule.status = Constants.OBJECT_STATUS.REMOVE;
    }

    this.rules()
      .sort((a, b) => {
        if (a.triggerOrder < b.triggerOrder) return -1;
        if (a.triggerOrder > b.triggerOrder) return 1;
        return 0;
      })
      .filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE)
      .forEach((e, index) => {
        e.order = index + 1;
        e.triggerOrder = index + 1;
      });

    this.rulesChanged.emit(this.rules());
  }
}
