@if (enableFilter) {
    <button
        type="button"
        class="btn btn-sm bg-white rounded-circle shadow-sm"
        [class.text-primary]="!isFilterApplied"
        [class.text-info]="isFilterApplied"
        [title]="'tooltips.filter' | translate"
        (click)="filterClicked.emit(true)"
    >
        <fa-icon [icon]="['fas', 'filter']"></fa-icon>
    </button>
}

@if (enableSettings) {
    <button
        type="button"
        class="btn btn-sm bg-white text-primary rounded-circle shadow-sm"
        [title]="'tooltips.settings' | translate"
        (click)="settingsClicked.emit(true)"
    >
        <fa-icon [icon]="['fas', 'gear']"></fa-icon>
    </button>
}

@if (enablePrint) {
    <button
        type="button"
        class="btn btn-sm bg-white text-primary rounded-circle shadow-sm"
        [title]="'tooltips.print' | translate"
        (click)="printClicked.emit(true)"
    >
        <fa-icon [icon]="['fas', 'print']"></fa-icon>
    </button>
}

@if (enableExport) {
    <button
        type="button"
        class="btn btn-sm bg-white text-primary rounded-circle shadow-sm"
        [title]="'tooltips.export' | translate"
        (click)="exportClicked.emit(true)"
    >
        <fa-icon [icon]="['fas', 'file-export']"></fa-icon>
    </button>
}

@for (item of customTableButtons; track item) {
    <button
        type="button"
        class="btn btn-sm bg-white text-primary rounded-circle shadow-sm"
        [title]="item.iconTitle | translate"
        (click)="buttonClicked.emit(item.eventName)"
    >
        <i [class]="item.iconName"></i>
    </button>
}

@if (enableAdd) {
    <button
        type="button"
        class="btn btn-sm bg-white text-primary rounded-circle shadow-sm"
        [title]="'tooltips.add' | translate"
        *adaaHasPermission="createPermission()"
        (click)="addNewClicked.emit()"
    >
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
    </button>
}
