<adaa-item-slider class="my-3" [showPerformance]="true">
    <h5 title class="py-2 fw-bold">
        {{ "landingPage.entity_page.government_enabler_performance" | translate }}
    </h5>

    <adaa-doughnut
        performance
        [size]="'md'"
        [amount]="overallPillar()?.govEnablerPerformance!"
        [label]="'landingPage.entity_page.enabler_performance' | translate"
        [colorId]="overallPillar()?.govEnablerPerformanceColor!"
        [showGraphIcon]="true"
        (onclick)="openPerformanceGraph(performanceGraphModal)"
    />

    @for (pillar of pillars(); track pillar.id) {
        <adaa-gov-enabler-perf-card
            [data]="pillar"
            (graphClicked)="selectedGovEnablerPerf.set($event); openPerformanceGraph(performanceGraphModal)"
        />
    }
</adaa-item-slider>

<ng-template #performanceGraphModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title w-100 text-center fw-bold">{{ chartTitle }}</h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="entityInfo.set({}); selectedGovEnablerPerf.set(undefined); modal.dismiss()"
        ></button>
    </div>
    <div class="modal-body overflow-x-hidden">
        @if (selectedPeriod()?.type === "year") {
            <adaa-entity-type-size-form
                (selected)="updateEntityInfo($event.type, $event.data)"
                (entitySizes)="entitySizes.set($event)"
                (entityTypes)="entityTypes.set($event)"
            />
        }

        <adaa-chart-action-buttons elementId="performanceChart" [enableDownloadCSV]="false" />

        @if (entitiesPerformance().length > 0 || averagePerformance()) {
            <canvas
                id="performanceChart"
                class="performanceChart"
                baseChart
                type="bar"
                [labels]="performanceChartLabels()"
                [datasets]="performanceChartDataset()"
                [options]="chartService.barChartOptions"
            ></canvas>
        }
    </div>
</ng-template>
