import { Component, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { TpAgmDashboardFilter, ValueText } from "../../../models";
import { FormDropdownComponent } from "../../form";

@Component({
  selector: "adaa-tp-filters",
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, FormDropdownComponent],
  templateUrl: "./tp-filters.component.html",
  styleUrl: "./tp-filters.component.scss",
})
export class TpFiltersComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  modal: NgbActiveModal = inject(NgbActiveModal);

  @Input() years: ValueText[];
  @Input() entities: ValueText[];
  @Input() sectors: ValueText[];
  @Input() statuses: ValueText[];
  @Input() dashboardFilters: TpAgmDashboardFilter;
  @Input() showStatusFilter: boolean = true;

  adaaHelper = AdaaHelper;
  filterForm: FormGroup;

  public ngOnInit(): void {
    this.filterForm = this._formBuilder.group({
      status: [this.dashboardFilters ? this.dashboardFilters.status : 0],
      year: [this.dashboardFilters ? this.dashboardFilters.year : ""],
      entityId: [this.dashboardFilters ? this.dashboardFilters.entityId : ""],
      sectorId: [this.dashboardFilters ? this.dashboardFilters.sectorId : ""],
    });
  }

  public apply() {
    this.modal.close(this.filterForm.value);
  }

  public reset() {
    this.modal.close(true);
  }
}
