import { Component, computed, inject, input, signal, type TemplateRef } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import type { ChartConfiguration } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { EMPTY, filter, switchMap, tap } from "rxjs";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import type {
  AggregationPerfModelType,
  AverageEntityPerformanceModelType,
  EntityPerformanceModelType,
  GetPopupGraphArgs,
  SelectedPeriodModelType,
  ValueText,
} from "../../../models";
import { AggregationPerfService, ChartsService, GraphApiService } from "../../../services";
import { ChartActionButtonsComponent } from "../../chart-action-buttons/chart-action-buttons.component";
import { DoughnutComponent } from "../../charts";
import { EntityTypeSizeFormComponent } from "../../general";
import { AggregationPerfCardComponent } from "../aggregation-perf-card/aggregation-perf-card.component";
import { ItemSliderComponent } from "../item-slider/item-slider.component";

@Component({
  selector: "adaa-aggregated-performance",
  standalone: true,
  templateUrl: "./aggregated-performance.component.html",
  styleUrl: "./aggregated-performance.component.scss",
  imports: [
    BaseChartDirective,
    FontAwesomeModule,
    TranslateModule,
    DoughnutComponent,
    TranslateTokenPipe,
    AggregationPerfCardComponent,
    ItemSliderComponent,
    EntityTypeSizeFormComponent,
    ChartActionButtonsComponent,
  ],
})
export class AggregatedPerformanceComponent {
  readonly modalService = inject(NgbModal);
  readonly chartService = inject(ChartsService);
  private readonly _graphApiService = inject(GraphApiService);
  private readonly _aggregationPerfService = inject(AggregationPerfService);

  isPmo = input.required<boolean>();
  selectedPeriod = input<SelectedPeriodModelType>();

  entityTypes = signal<ValueText[]>([]);
  entitySizes = signal<ValueText[]>([]);
  entityInfo = signal<{ entitySize?: number; entityType?: number }>({});
  selectedAggregationType = signal<AggregationPerfModelType | undefined>(undefined);
  entitiesPerformance = signal<EntityPerformanceModelType[]>([]);
  averagePerformance = signal<AverageEntityPerformanceModelType | undefined>(undefined);

  aggregationPerf = computed(() => this._aggregationPerfService.aggregationPerf());
  overallAggregationPerf = computed(() => this._aggregationPerfService.overallAggregationPerf());
  performanceChartLabels = computed(() =>
    this.entitiesPerformance().map((data) => {
      const label = AdaaHelper.getItemValueByToken(data, "entityName");
      if (!label) return "";
      return label;
    })
  );
  performanceChartDataset = computed(() => {
    const barChartData = this.entitiesPerformance().map((data) => data.score ?? 0);
    const barChartColors = this.entitiesPerformance().map((data) => {
      const label = AdaaHelper.getItemValueByToken(data, "entityName");
      if (!label) {
        return this.getColor(Constants.CONSTANT_COLORS.EVALUATION_GRAY);
      }
      return this.getColor(data.scoreColor);
    });

    const datasets: ChartConfiguration<"bar" | "line">["data"]["datasets"] = [
      {
        data: barChartData,
        backgroundColor: barChartColors,
        order: 1,
        tension: 0.5,
      },
    ];

    if (this.averagePerformance()) {
      const lineChartData = this.entitiesPerformance().map(() => this.averagePerformance()?.score || 0);
      const lineChartColor = this.getColor(Constants.CONSTANT_COLORS.EVALUATION_LIGHTBLUE);

      datasets.push({
        order: 0,
        tension: 0.75,
        type: "line",
        fill: false,
        showLine: true,
        data: lineChartData,
        borderColor: lineChartColor,
        label: AdaaHelper.getItemValueByToken(this.averagePerformance(), "governmentName"),
        pointStyle: "line",
      });
    }

    return datasets;
  });

  public get icon() {
    return {
      leftArrow: faArrowLeftLong,
      rightArrow: faArrowRightLong,
    };
  }

  public getColor(code: number) {
    return Constants.COLOR_CODES[code];
  }

  public get chartTitle() {
    const isAnnual = this.selectedPeriod()?.type === "year";
    const year = this.selectedPeriod()?.period?.year;
    const quarter = this.selectedPeriod()?.period?.quarter;
    const label = AdaaHelper.getItemValueByToken(this.selectedAggregationType(), "label");

    return `${isAnnual ? year : `Q${quarter} ${year}`} ${label}`;
  }

  public updateEntityInfo(type: "entitySize" | "entityType", data: number) {
    this.entityInfo.update((info) => ({ ...info, [type]: data }));
    this._fetchPerformanceData();
  }

  public openPerformanceGraph(content: TemplateRef<unknown>) {
    this.modalService.open(content, {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      fullscreen: false,
      ariaLabelledBy: this.chartTitle,
    });

    this._fetchPerformanceData();
  }

  private _fetchPerformanceData() {
    let periodId: number;
    if (this.selectedPeriod()?.type === "year") {
      periodId = this.selectedPeriod()?.period.year as number;
    } else {
      periodId = this.selectedPeriod()?.period.id as number;
    }

    const { entityType, entitySize } = this.entityInfo();

    const params = {
      entitySize,
      entityType,
      periodId,
      isAnnual: this.selectedPeriod()?.type === "year",
      id: this.selectedAggregationType()?.aggregationType as number,
      isGov: this.isPmo(),
      isEnabler: false,
    };

    this._graphApiService
      .getPerformanceGraphItems(params)
      .pipe(
        tap((res) => {
          if (res.inError) return;
          this.entitiesPerformance.update(() => res.responseData);
        }),
        switchMap(() => this._getAveragePerformance(params)),
        filter((res) => AdaaHelper.isDefined(res)),
        tap((res) => {
          if (res.inError) return;
          this.averagePerformance.update(() => res.responseData);
        })
      )
      .subscribe();
  }

  private _getAveragePerformance(params: GetPopupGraphArgs) {
    this._updateChartOptions();
    if (!this.isPmo()) return EMPTY;
    return this._graphApiService.getPerformanceGraphData(params);
  }

  private _updateChartOptions() {
    this.chartService.initEntityComparisonBarChartOptions({
      title: this.chartTitle,
      subtitle: this._getSubTitle(),
      usePerformance: true,
      showActual: false,
    });
  }

  private _getSubTitle(): string {
    let subTitle = "";
    const { entityType, entitySize } = this.entityInfo();

    if (entityType) subTitle = this.entityTypes().find((e) => e.value === entityType)!.text;
    if (entitySize) {
      subTitle += AdaaHelper.isDefinedAndNotEmpty(subTitle) ? " - " : "";
      subTitle += this.entitySizes().find((e) => e.value === entitySize)!.text;
    }

    return subTitle;
  }
}
