<div class="modal-header">
    <h4 class="modal-title">{{ "tooltips.filter" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="filterForm" class="row">
        @if (showStatusFilter) {
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-dropdown
                    controlName="status"
                    formControlName="status"
                    [label]="'helpdesk.status' | translate"
                    [options]="statuses"
                    [searchable]="true"
                />
            </div>
        }
        @if (adaaHelper.isPMOEntity()) {
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-dropdown
                    controlName="entityId"
                    formControlName="entityId"
                    [label]="'entity.create.entity' | translate"
                    [options]="entities"
                    [clearable]="true"
                    [searchable]="true"
                />
            </div>
        }
        <div class="col-md-6 col-sm-12 col-xs-12">
            <adaa-form-dropdown
                controlName="year"
                formControlName="year"
                [label]="'nkpi.benchYear' | translate"
                [options]="years"
                [searchable]="true"
            />
        </div>

        <div class="col-md-6 col-sm-12 col-xs-12">
            <adaa-form-dropdown
                controlName="sectorId"
                formControlName="sectorId"
                [label]="'national_projects.national_sector.title' | translate"
                [options]="sectors"
                [clearable]="true"
                [searchable]="true"
            />
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close()">
        {{ "common.form.button.cancel" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="reset()">
        {{ "common.form.button.reset" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="apply()">
        {{ "common.form.button.apply" | translate }}
    </button>
</div>
