import { CommonModule } from "@angular/common";
import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";

import { AdaaHelper } from "../../../../core/utils/adaa-helper";
import { Language } from "../../../constants/enums";
import { RadioOption } from "../../../models/shared.model";
import { AppService } from "../../../services/app.service";

@Component({
  selector: "adaa-form-radio",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./form-radio.component.html",
  styleUrl: "./form-radio.component.scss",
})
export class FormRadioComponent implements OnInit {
  private _appService = inject(AppService);

  direction: string = "ltr";

  @Input() setDefaultValue: unknown = null;
  @Input() options: RadioOption[] = [];
  @Input() controlName: string | null = null;
  @Input() controlId: string | null = null;
  @Input() styles: string = "";
  @Input() label: string = "";

  @Output() inputChanges = new EventEmitter();

  public ngOnInit(): void {
    if (!AdaaHelper.isDefined(this.controlId)) this.controlId = this.controlName;
    this.direction = this._appService.language() === Language.Arabic ? "rtl" : "ltr";
  }
}
