import { inject, Injectable } from "@angular/core";

import { EntityMapModelType, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class EntityMapApiService {
  private _mainService = inject(MainService);

  public updateEntityMap(data: EntityMapModelType) {
    const url = `map/update?isFinish=false`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public getEntityMap(planId: number, entityId: number) {
    const url = `map/getByPlanIdAndEntityId/${planId}/${entityId}`;

    return this._mainService.sendRequest<MainResponseModel<EntityMapModelType>>({
      method: "GET",
      url,
    });
  }
}
