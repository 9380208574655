<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ "helpdesk.comment_add" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <div class="row">
            <div class="col-12">
                <adaa-form-input [label]="'helpdesk.comment' | translate" [isTextArea]="true" (inputChanges)="value.set($event)"></adaa-form-input>
            </div>
        </div>
    </div>
    <div class="modal=footer d-flex justify-content-center mt-3">
        <adaa-form-action-buttons (cancelClicked)="modal.close()" (saveClicked)="submit()"></adaa-form-action-buttons>
    </div>
</ng-template>
