import { inject, Injectable, signal } from "@angular/core";
import { filter, tap } from "rxjs";

import type { PillarPerformanceModelType } from "../models";
import { GraphApiService } from "./graph-api.service";

@Injectable({
  providedIn: "root",
})
export class PillarEkpiPerformanceService {
  private readonly _graphApiService = inject(GraphApiService);

  ekpiPillarPerformance = signal<PillarPerformanceModelType[]>([]);

  public getData(periodId: number, isQuarter: boolean, entityId: number) {
    return this._graphApiService.getPillarPerformance(periodId, entityId, isQuarter).pipe(
      filter((res) => !res.inError),
      tap({
        next: (res) => {
          if (res.inError) return;
          this.ekpiPillarPerformance.update(() => res.responseData);
        },
      })
    );
  }
}
