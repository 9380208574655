import { Directive, ElementRef, EventEmitter, inject, Input, OnInit, Output, Renderer2 } from "@angular/core";

import { Language } from "../constants/enums";
import { AppService } from "../services";

@Directive({
  selector: "th[resizable]",
  standalone: true,
})
export class ResizeColumnDirective implements OnInit {
  @Input() resizable: boolean;
  @Input() index: number;
  @Output() getResizeResult = new EventEmitter();
  @Output() currentWidth = new EventEmitter();
  @Output() resizing = new EventEmitter();

  private _startX: number;
  private _startWidth: number;
  private _column: HTMLElement;
  private _table: HTMLElement;
  private _pressed: boolean;
  private _width: number;

  private _appSerivce = inject(AppService);
  private _renderer2 = inject(Renderer2);
  private _elementRef = inject(ElementRef);

  constructor() {
    if (this._elementRef) {
      this._column = this._elementRef.nativeElement;
    }
  }

  public ngOnInit() {
    if (this.resizable) {
      const row = this._renderer2.parentNode(this._column);
      const thead = this._renderer2.parentNode(row);
      this._table = this._renderer2.parentNode(thead);

      const resizer = this._renderer2.createElement("span");
      this._renderer2.addClass(resizer, "resize-holder");
      this._renderer2.appendChild(this._column, resizer);
      this._renderer2.listen(resizer, "mousedown", this.onMouseDown);
      this._renderer2.listen("document", "mouseup", this.onMouseUp);
    }
  }

  onMouseDown = (event: MouseEvent) => {
    this._renderer2.listen(this._table, "mousemove", this.onMouseMove);
    const movementDirection =
      this._appSerivce.language() === Language.English ? event.pageX : Math.abs(screen.availWidth - event.pageX);
    this._pressed = true;
    this.resizing.emit(this._pressed);
    this._startX = movementDirection;
    this._startWidth = this._column.offsetWidth;
  };

  onMouseMove = (event: MouseEvent) => {
    if (this._pressed) {
      const movementDirection =
        this._appSerivce.language() === Language.English ? event.pageX : Math.abs(screen.availWidth - event.pageX);
      const offset = 0;
      if (this._pressed && event.buttons) {
        this._renderer2.addClass(this._table, "resizing");

        // Calculate width of column
        let width = this._startWidth + (movementDirection - this._startX - offset);

        const tableCells = Array.from(
          this._table.querySelectorAll("tr")
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ).map((row: any) => row.querySelectorAll("td").item(this.index));

        if (width < 100) {
          width = 100;
        }

        // Set table header width
        this._renderer2.setStyle(this._column, "width", `${width}px`);

        // Set table cells width
        for (const cell of tableCells) {
          if (cell) {
            this._renderer2.setStyle(cell, "width", `${width}px`);
          }
        }
        this._width = width;
        this.currentWidth.emit(width);
      }
    }
  };

  onMouseUp = () => {
    if (this._pressed) {
      this._pressed = false;
      this.resizing.emit(this._pressed);
      this._renderer2.removeClass(this._table, "resizing");
      this.getResizeResult.emit(this._width);
    }
  };
}
