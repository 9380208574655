import { CommonModule } from "@angular/common";
import { Component, EventEmitter, forwardRef, inject, Input, OnInit, Output } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import {
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper, InputDateAdapter, InputDateFormatterAdapter } from "../../../../core/utils";
import { ParameterCatalog } from "../../../models";

@Component({
  selector: "adaa-form-input-date-range",
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, NgbDatepickerModule, FontAwesomeModule],
  templateUrl: "./form-input-date-range.component.html",
  styleUrl: "./form-input-date-range.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputDateRangeComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormInputDateRangeComponent),
    },
    {
      provide: NgbDateAdapter,
      useClass: InputDateAdapter,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: InputDateFormatterAdapter,
    },
  ],
})
export class FormInputDateRangeComponent implements OnInit, ControlValueAccessor {
  inputControl: FormControl = new FormControl();
  adaaHelper = AdaaHelper;

  private _onValidatorChange: () => void;
  private _onTouched: () => void;
  private _onChange: (value: string | null) => void;

  @Input() required: boolean = false;
  @Input() invalid: boolean = false;
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() isDisabled: boolean = false;
  @Input() controlName: string | null = null;
  @Input() controlId: string | null = null;
  @Input() maxDate?: NgbDateStruct | null;
  @Input() minDate?: NgbDateStruct | null;

  @Input()
  public set setDefaultValue(value: string) {
    if (value) {
      this.writeValue(value);
    }
  }

  @Input()
  public set setValidator(validatorList: { parameterCatalogs: ParameterCatalog[] }) {
    if (validatorList) {
      if (validatorList.parameterCatalogs) {
        const field = validatorList.parameterCatalogs.find((e) => e.fieldName === this.controlName);

        if (field) {
          this.required = field.mandatory === "Y";
          this.inputControl.updateValueAndValidity();
        }
      }
    }
  }

  @Output() inputChanges = new EventEmitter();

  private _iconLibrary: FaIconLibrary = inject(FaIconLibrary);
  private _dateAdapter: NgbDateAdapter<number> = inject(NgbDateAdapter);
  private _dateFormatAdapter: NgbDateParserFormatter = inject(NgbDateParserFormatter);

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  formatedDate: string = "";

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faCalendar);

    if (!this.adaaHelper.isDefined(this.controlId)) this.controlId = this.controlName;
    this.inputControl.updateValueAndValidity();
  }

  public writeValue(obj: string): void {
    if (obj === null) {
      this.inputControl.reset();
      return;
    }

    const datesArr = obj.split("-");
    if (!this.adaaHelper.isDefined(datesArr)) return;

    if (this.adaaHelper.isDefined(datesArr[0])) {
      const date = this._dateAdapter.fromModel(parseInt(datesArr[0]));
      if (date) {
        this.fromDate = new NgbDate(date.year, date.month, date.day);
      }
    }

    if (this.adaaHelper.isDefined(datesArr[1])) {
      const date = this._dateAdapter.fromModel(parseInt(datesArr[1]));
      if (date) {
        this.toDate = new NgbDate(date.year, date.month, date.day);
      }
    }
    const valueDate = `${this._dateAdapter.toModel(this.fromDate)} - ${this._dateAdapter.toModel(this.toDate)}`;
    this.inputControl.setValue(valueDate);

    //This Time out to fix the date in the textbox
    setTimeout(() => {
      this.formatedDate = `${this._dateFormatAdapter.format(this.fromDate)} - ${this._dateFormatAdapter.format(this.toDate)}`;
    }, 500);
  }

  public registerOnChange(fn: (value: string | null) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this._onValidatorChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  public validate(_value: FormControl): false | { required: boolean } {
    const isRequired =
      (this.required && this.inputControl.value === null) || (this.required && this.inputControl.value === "");
    return (
      isRequired && {
        required: true,
      }
    );
  }

  public valueChanged(event: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = event;
    } else if (this.fromDate && !this.toDate && event?.after(this.fromDate)) {
      this.toDate = event;
    } else {
      this.toDate = null;
      this.fromDate = event;
    }

    this.formatedDate = `${this._dateFormatAdapter.format(this.fromDate)} - ${this._dateFormatAdapter.format(this.toDate)}`;

    const valueDate = `${this._dateAdapter.toModel(this.fromDate)} - ${this._dateAdapter.toModel(this.toDate)}`;
    this._onChange(valueDate);
    this.inputChanges.emit(valueDate);
  }

  public isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public resetInput(): void {
    this.fromDate = null;
    this.toDate = null;
    this.formatedDate = "";

    if (this._onChange) this._onChange(null);
    this.inputChanges.emit(null);
  }
}
