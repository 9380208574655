import { Component, input } from "@angular/core";
import { CountUpModule } from "ngx-countup";

import { AdaaHelper } from "../../core/utils";

@Component({
  standalone: true,
  imports: [CountUpModule],
  selector: "adaa-count-up",
  styles: `
    :host {
      font-size: var(--count-up-var);
    }
    span {
      font-variant-ligatures: historical-ligatures;
    }
  `,
  template: `<span
    [style.--count-up-var]="fontSize()"
    [countUp]="this.getTotal"
    [reanimateOnClick]="false"
    [options]="{
      suffix: getSymbol,
      decimalPlaces: getDecimalPlaces,
    }"
  ></span>`,
})
export class CountUpComponent {
  total = input.required<number>();
  symbol = input<"%" | "N" | "C">("%");
  decimalPlaces = input<number>(2);
  fontSize = input<string>("2rem");

  public get getTotal(): number {
    if (this.symbol() === "C") {
      const value = AdaaHelper.amountToString(this.total());
      return value ?? this.total();
    }
    return this.total();
  }

  public get getSymbol() {
    switch (this.symbol()) {
      case "%": {
        return "%";
      }
      case "C": {
        return " " + AdaaHelper.getCurrencySymbol(this.total(), true);
      }
      default: {
        return "";
      }
    }
  }

  public get getDecimalPlaces(): number {
    return this.symbol() === "%" ? 2 : 0;
  }
}
