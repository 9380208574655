import { NgClass } from "@angular/common";
import { Component, input } from "@angular/core";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faBell, faQuestion, faSearch } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  standalone: true,
  selector: "adaa-activity-bar",
  styleUrls: ["./styles.scss"],
  imports: [FontAwesomeModule, TranslateModule, NgClass, RouterLink, RouterLinkActive],
  template: `
    <div class="topbar--helpful-actions" [ngClass]="[type()]" [dir]="direction()">
      <button
        role="link"
        id="appSearch"
        class="btn btn-sm bg-white text-primary rounded-circle shadow-sm mx-3 d-inline-block hide-search-btn activity-bar-opts"
        [dir]="direction()"
        [routerLink]="['/', 'console', 'search']"
        routerLinkActive="active"
      >
        <fa-icon [icon]="icon.search" [title]="'breadcrumbs.search' | translate"></fa-icon>
      </button>

      <button
        role="link"
        id="notificationCenter"
        class="btn btn-sm bg-white text-primary rounded-circle shadow-sm position-relative activity-bar-opts"
        [class.text-primary]="!hasNotifications()"
        [class.text-info]="hasNotifications()"
        [routerLink]="['communications']"
      >
        <fa-icon [icon]="icon.notification" [title]="'tooltips.notification_center' | translate"></fa-icon>

        <!-- badge -->
        @if (hasNotifications()) {
          <span
            data-sms-count
            class="position-absolute translate-middle start-0 px-1 text-white bg-info border border-light rounded-pill"
          >
            {{ !count() ? "" : count() }}
            <span class="visually-hidden">
              {{ "communication.title" | translate }}
            </span>
          </span>
        }
      </button>

      <!-- TODO: Add dropdown items for helpdesk -->
      <button
        role="link"
        id="helpCenter"
        class="btn btn-sm bg-white text-primary rounded-circle shadow-sm mx-3 activity-bar-opts"
        [dir]="direction()"
      >
        <fa-icon [icon]="icon.helpdesk" [title]="'tooltips.help_center' | translate"></fa-icon>
      </button>
    </div>
  `,
})
export class ActivityBarComponent {
  count = input.required<string>();
  hasNotifications = input.required<boolean>();
  direction = input.required<"ltr" | "rtl">();
  type = input.required<"full" | "mini">();

  public get icon() {
    return {
      search: faSearch,
      helpdesk: faQuestion,
      notification: faBell,
    };
  }
}
