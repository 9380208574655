import { NgClass } from "@angular/common";
import { Component, inject, input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { Constants } from "../../../../../../../constants/constants";
import { Language, PageMode } from "../../../../../../../constants/enums";
import { CardEntryInfoType } from "../../../../../../../models";
import { DataentryApiService, LanguageService } from "../../../../../../../services";
import { EditDataEntryModalComponent } from "../../../../../..";
import { commonFormatter } from "../../../configs/common";
import type { BoundedNoFormulaMetricType, NoFormulaMetricType, UnboundedNoFormulaMetricType } from "../types";

@Component({
  selector: "adaa-no-formula-view",
  standalone: true,
  styleUrl: "../styles.scss",
  imports: [TranslateModule, NgClass],
  template: `
    <section class="row d-flex mt-2">
      <div class="col-5 fw-bold px-3 py-2 data-entry-col"></div>
      <div class="col-1 fw-bold px-3 py-2 data-entry-col"></div>
      <div class="col-3 fw-bold px-3 py-2 data-entry-col text-primary">{{ "data_entry.actual" | translate }}</div>
      <div class="col-3 fw-bold px-3 py-2 data-entry-col text-primary">{{ "data_entry.target" | translate }}</div>
    </section>

    <section class="row d-flex">
      <div class="col-5 fw-bold px-3 py-2 data-entry-col"></div>
      <div class="col-1 fw-bold px-3 py-2 data-entry-col"></div>
      <div class="col-3 fw-bold">
        <div class="row d-flex text-primary">
          <div class="col-6 px-3 py-2 data-entry-col">
            {{ "communication.difference.new_value" | translate }}
          </div>
          <div class="col-6 px-3 py-2 data-entry-col">
            {{ "communication.difference.old_value" | translate }}
          </div>
        </div>
      </div>
      <div class="col-3" [ngClass]="{ 'px-3 py-2 data-entry-col': !isBounded() }">
        @if (isBounded()) {
          <div class="row d-flex">
            <div class="col-6 fw-bold px-3 py-2 data-entry-col text-primary">{{ "kpi.lower_limit" | translate }}</div>
            <div class="col-6 fw-bold px-3 py-2 data-entry-col text-primary">{{ "kpi.upper_limit" | translate }}</div>
          </div>
        }
      </div>
    </section>

    @for (metric of newMetrics(); track metric.id) {
      <section class="row d-flex">
        <div class="col-5 fw-bold px-3 py-2 data-entry-col">
          {{ getPeriodLabel(metric) }}
        </div>

        <div
          class="col-1 fw-bold px-3 py-0 data-entry-col d-flex justify-content-center align-content-center align-items-center"
        >
          <i class="adaa-icon-view1 fs-3 cursor-pointer" role="button" (click)="viewDifferences(metric)"></i>
        </div>

        <div class="col-3">
          <div class="row d-flex">
            <div
              class="col-6 px-3 py-2 data-entry-col"
              dir="ltr"
              [class.has-diff]="showOnlyNew() || metric?.wfProcessCtlId"
            >
              @if (!showNAFlag(+$index, "new")) {
                {{ metric.actual }} &nbsp;
              } @else {
                {{ "N/A" }}
              }
            </div>

            <div class="col-6 px-3 py-2 data-entry-col" dir="ltr" [class.has-diff]="metric?.wfProcessCtlId">
              @if (!showNAFlag(+$index, "old")) {
                {{ metric.previous }} &nbsp;
              } @else {
                {{ "N/A" }}
              }
            </div>
          </div>
        </div>

        <div class="col-3" [ngClass]="{ 'px-3 py-2 data-entry-col': !isBounded() }">
          @if (isBounded()) {
            <div class="row d-flex">
              <div class="col-6 px-3 py-2 data-entry-col">{{ getBoundedValue("lower", metric) }} &nbsp;</div>

              <div class="col-6 px-3 py-2 data-entry-col">{{ getBoundedValue("high", metric) }} &nbsp;</div>
            </div>
          } @else {
            {{ getUnboundedValue(metric) }} &nbsp;
          }
        </div>
      </section>
    }
  `,
})
export class NoFormulaViewComponent {
  private _translateService = inject(TranslateService);
  private _languageService = inject(LanguageService);
  private _modalService = inject(NgbModal);
  private _dataentryApiService = inject(DataentryApiService);

  isBounded = input<boolean>();
  showOnlyNew = input<boolean>();
  baselineYear = input<number>();
  isBaselineKpi = input<boolean>();
  newMetrics = input<NoFormulaMetricType[]>([]);
  oldMetrics = input<NoFormulaMetricType[]>([]);

  public getUnboundedValue(metric: NoFormulaMetricType) {
    return this._getBaselineValue(metric.year, (metric as UnboundedNoFormulaMetricType).target);
  }

  public getBoundedValue(type: "lower" | "high", metric: NoFormulaMetricType) {
    let target: number;
    switch (type) {
      case "lower": {
        target = (metric as BoundedNoFormulaMetricType).lowerLimit;
        break;
      }

      case "high": {
        target = (metric as BoundedNoFormulaMetricType).highLimit;
        break;
      }
    }

    return this._getBaselineValue(metric.year, target);
  }

  public showNAFlag(metricIndex: number, version: "old" | "new") {
    const metrics = version === "old" ? this.oldMetrics() : this.newMetrics();
    if (metrics == null) return false;

    const metric = metrics.find((_: NoFormulaMetricType, index: number) => metricIndex == index);
    if (!metric) return false;

    return metric.ignored;
  }

  public getPeriodLabel(metric: NoFormulaMetricType) {
    if (metric.frequency === Constants.FREQUENCY_MONTHLY) {
      return commonFormatter.monthly(metric.date) + " " + metric.year;
    }

    if (metric.frequency === Constants.FREQUENCY_QUARTERLY) {
      switch (this._languageService.current()) {
        case Language.English:
          return `${this._translateService.instant(`targets.periods.quarters.${metric.quarter}`)} ${metric.year}`;
        default:
          return `${this._translateService.instant("targets.quarter")} ${metric.quarter} ${metric.year}`;
      }
    }

    if (metric.frequency === Constants.FREQUENCY_SEMIANNUAL) {
      switch (this._languageService.current()) {
        case Language.Arabic:
          return `${this._translateService.instant(`targets.periods.semestral.${metric.semester}`)} ${metric.year}`;
        default:
          return `${this._translateService.instant("targets.semiannual")} ${metric.semester} ${metric.year}`;
      }
    }

    return metric.year;
  }

  private _getBaselineValue(year: number, value: number) {
    if (year === this.baselineYear() && this.isBaselineKpi()) {
      return this._translateService.instant("kpi.baselineYear");
    }
    return value;
  }

  public viewDifferences(metric: NoFormulaMetricType) {
    this._dataentryApiService.getAnalysisDifferencesInfo(metric.itemId, metric.periodId, metric.itemType).subscribe({
      next: (response) => this._openEditDataEntryModal(metric.year, response.responseData),
    });
  }

  private _openEditDataEntryModal(yearLabel: number, dataEntry: CardEntryInfoType): void {
    const modal = this._modalService.open(EditDataEntryModalComponent, {
      centered: true,
      size: "lg",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = `${this._translateService.instant("data_entry.period")}` + ": " + `${yearLabel}`;
    modal.componentInstance.dataEntry = dataEntry;
    modal.componentInstance.pageMode = PageMode.view;
    modal.componentInstance.showSubmit = false;
  }
}
