import { inject, Injectable, signal } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { TpFiltersComponent } from "../components";
import { Constants } from "../constants/constants";
import { TpAgmStatus } from "../constants/enums";
import { AgmDashboardResponse, TpAgmDashboardFilter, ValueText } from "../models";
import { AgmCycleApiService } from "./agm-cycle-api.service";
import { AgmProjectApiService } from "./agm-project-api.service";
import { LanguageService } from "./language.service";
import { TpSectorApiService } from "./tp-sector-api.service";

@Injectable({
  providedIn: "root",
})
export class AgmDashboardService {
  private _agmProjectApiService = inject(AgmProjectApiService);
  private _tpSectorApiService = inject(TpSectorApiService);
  private _agmCycleApiService = inject(AgmCycleApiService);
  private _modalService = inject(NgbModal);
  private _languageService = inject(LanguageService);
  private _translateService = inject(TranslateService);

  private _currentYear = new Date().getFullYear();

  dashboardData = signal<AgmDashboardResponse | undefined>(undefined);
  dashboardFilters: TpAgmDashboardFilter = {
    status: 0,
    year: this._currentYear,
    cycleId: undefined,
    entityId: undefined,
    ownerId: undefined,
    sectorId: undefined,
    mainSectorId: undefined,
    onlyProjectsOwnedByEntity: false,
    filterBy: undefined,
  };
  dashboardCycles: ValueText[] = [];
  dashboardYears: ValueText[] = [];
  dashboardEntities: ValueText[] = [];
  dashboardSectors: ValueText[] = [];
  dashboardStatuses: ValueText[] = [];

  public get isFilterApplied(): boolean {
    return (
      this.dashboardFilters.year !== this._currentYear ||
      this.dashboardFilters.status !== TpAgmStatus.ALL ||
      AdaaHelper.isDefined(this.dashboardFilters.sectorId) ||
      (AdaaHelper.isPMOEntity()
        ? AdaaHelper.isDefined(this.dashboardFilters.entityId) &&
          this.dashboardFilters.entityId !== Constants.CONSTANT_PMO_ID
        : false)
    );
  }

  public initDashboard(clearLocalStorage: boolean = false): void {
    this.dashboardData.set(undefined);

    forkJoin([
      this._agmProjectApiService.getProjectEntities(),
      this._agmProjectApiService.getProjectYears(),
      this._agmCycleApiService.getCycles(),
      this._tpSectorApiService.getAgmSectors(),
      this._translateService.get([
        "national_projects.filter.all",
        "national_projects.filter.completed",
        "national_projects.filter.ontime",
        "national_projects.filter.delayed",
        "national_projects.filter.most_completed",
        "national_projects.filter.most_delayed",
        "national_projects.filter.best_target",
        "national_projects.filter.lowest_target",
      ]),
    ]).subscribe({
      next: ([entities, years, cycles, sectors, translation]) => {
        this.dashboardYears = [];
        for (let index = years.responseData.minYear; index < years.responseData.maxYear; index++) {
          this.dashboardYears.push({ value: index, text: index.toString() });
        }

        this.dashboardEntities = AdaaHelper.setDropdownArray(
          entities.responseData,
          "id",
          AdaaHelper.getFieldLanguage("name")
        );

        this.dashboardSectors = AdaaHelper.setDropdownArray(
          sectors.responseData,
          "id",
          AdaaHelper.getFieldLanguage("name")
        );

        this.dashboardCycles = AdaaHelper.setDropdownArray(
          cycles.responseData,
          "id",
          AdaaHelper.getFieldLanguage("name")
        );

        this.dashboardStatuses = [
          {
            value: TpAgmStatus.ALL,
            text: translation["national_projects.filter.all"],
          },
          {
            value: TpAgmStatus.COMPLETED,
            text: translation["national_projects.filter.completed"],
          },
          {
            value: TpAgmStatus.ON_TIME,
            text: translation["national_projects.filter.ontime"],
          },
          {
            value: TpAgmStatus.DELAYED,
            text: translation["national_projects.filter.delayed"],
          },
          {
            value: TpAgmStatus.MOST_COMPLETED,
            text: translation["national_projects.filter.most_completed"],
          },
          {
            value: TpAgmStatus.MOST_DELAYED,
            text: translation["national_projects.filter.most_delayed"],
          },
          {
            value: TpAgmStatus.BEST_VISION,
            text: translation["national_projects.filter.best_target"],
          },
          {
            value: TpAgmStatus.LOWEST_VISION,
            text: translation["national_projects.filter.lowest_target"],
          },
        ];

        if (clearLocalStorage) AdaaHelper.deleteLocalStorage(Constants.localStorageKeys.agmCycle);
      },
    });
  }

  public getData(): void {
    this.dashboardFilters.entityId = AdaaHelper.entity?.id;
    this.getDashboardData();
  }

  public openFilters(showStatusFilter: boolean = true) {
    const modal = this._modalService.open(TpFiltersComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.dashboardFilters = this.dashboardFilters;
    modal.componentInstance.years = this.dashboardYears;
    modal.componentInstance.entities = this.dashboardEntities;
    modal.componentInstance.sectors = this.dashboardSectors;
    modal.componentInstance.statuses = this.dashboardStatuses;
    modal.componentInstance.showStatusFilter = showStatusFilter;

    modal.result.then((e) => {
      if (e === true) {
        this.resetFilters();
      } else if (e === undefined) {
        return;
      } else {
        if (showStatusFilter) this.dashboardFilters.status = e.status;

        this.dashboardFilters.year = e.year;
        this.dashboardFilters.entityId = e.entityId;
        this.dashboardFilters.sectorId = e.sectorId;

        this.getDashboardData();
      }
    });
  }

  public resetFilters(showStatusFilter: boolean = true, fetchData: boolean = true) {
    if (showStatusFilter) this.dashboardFilters.status = 0;

    this.dashboardFilters.year = this._currentYear;
    this.dashboardFilters.entityId = AdaaHelper.entity?.id;
    this.dashboardFilters.sectorId = undefined;
    fetchData && this.getDashboardData();
  }

  public getDashboardData(): void {
    this._agmProjectApiService.getAgmDashboard(this.dashboardFilters).subscribe((response) => {
      if (response.inError) return;

      this._formatDashboardData(response.responseData);
    });
  }

  private _formatDashboardData(data: AgmDashboardResponse): void {
    data.completedWidth = data.annualMeetingCount ? (data.completedCount * 100) / data.annualMeetingCount : 0;
    data.ongoingWidth = data.annualMeetingCount ? (data.ongoingCount * 100) / data.annualMeetingCount : 0;
    data.delayedWidth = data.annualMeetingCount ? (data.delayedCount * 100) / data.annualMeetingCount : 0;

    data.annualMeetings.forEach((e) => (e.projectStatusId = this._getColorId(e.projectStatus)));
    this.dashboardData.set(data);
  }

  private _getColorId(status: string): number {
    switch (status) {
      case "COMPLETED":
        return 33;
      case "ON_GOING":
        return 35;
      case "DELAYED":
        return 34;
      default: //CANCELED
        return 72;
    }
  }
}
