import { NgClass, NgStyle } from "@angular/common";
import { Component, input, output } from "@angular/core";
import { RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { ParameterCatalog } from "../../../models";

@Component({
  selector: "adaa-console-icon",
  standalone: true,
  imports: [NgClass, NgStyle, RouterLink, TranslateTokenPipe, TranslateModule],
  styleUrl: "./console-icon.component.scss",
  template: `
    <a
      class="console-item m-2"
      [routerLink]="'/' + item()?.urlRoute"
      (mouseenter)="hovered = true"
      (mouseleave)="hovered = false"
      (click)="wasClicked.emit()"
    >
      <i
        class="m-2"
        [ngClass]="['adaa-icon-' + item()?.iconKey]"
        [class.console-heartbeat-anime]="hovered"
        [ngStyle]="{ fontSize: size() }"
      ></i>

      <p>{{ item()! | translateToken: "name" | translate }}</p>
    </a>
  `,
})
export class ConsoleIconComponent {
  size = input<`${number}px`>("58px");
  item = input<Partial<ParameterCatalog> | undefined>(undefined);

  wasClicked = output<void>();

  hovered: boolean;
}
