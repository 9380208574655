<div class="modal-header">
    <h4 class="modal-title">{{ header }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body" [formGroup]="dataEntryForm">
    <div class="row">
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="analysisAE"
                formControlName="analysisAE"
                [isTextArea]="true"
                [invalid]="submitted && dataEntryForm.get('analysisAE')?.invalid!"
                [label]="'data_entry.analysis' | translate"
            />
        </div>
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="recommendationsAE"
                formControlName="recommendationsAE"
                [isTextArea]="true"
                [invalid]="submitted && dataEntryForm.get('recommendationsAE')?.invalid!"
                [label]="'data_entry.recommendations' | translate"
            />
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="areasOfImprovementsAE"
                formControlName="areasOfImprovementsAE"
                [isTextArea]="true"
                [invalid]="submitted && dataEntryForm.get('areasOfImprovementsAE')?.invalid!"
                [label]="'data_entry.areas_of_improvement' | translate"
            />
        </div>
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="pmoNotesAE"
                formControlName="pmoNotesAE"
                [isTextArea]="true"
                [invalid]="submitted && dataEntryForm.get('pmoNotesAE')?.invalid!"
                [label]="'kpi.pmo_notes' | translate"
            />
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12 px-3">
            <adaa-attachments-list
                [enableDate]="false"
                [enableType]="false"
                [isDisabled]="pageMode === 'view'"
                [tableConf]="constants.MAIN_TABLE_LIST_CONF_KEY.DATA_ENTRY_ATTACHMENT_LIST"
                [attachments]="dataEntryForm.get('attachFileList')?.value ?? []"
                [showSubmit]="showSubmit"
                [replaceViewWithDownload]="true"
                (attachmentsChanged)="dataEntryForm.get('attachFileList')?.setValue($event)"
            />
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ (showSubmit ? "common.form.button.cancel" : "common.form.button.close") | translate }}
    </button>
    @if (showSubmit) {
        <button type="button" class="btn btn-primary" (click)="modal.close(dataEntryForm.getRawValue())">
            {{ "common.form.button.submit" | translate }}
        </button>
    }
</div>
