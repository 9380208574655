import { Component } from "@angular/core";

@Component({
  selector: "adaa-ntkpi-card-screen",
  standalone: true,
  imports: [],
  templateUrl: "./ntkpi-card-screen.component.html",
  styleUrl: "./ntkpi-card-screen.component.scss",
})
export class NtkpiCardScreenComponent {}
