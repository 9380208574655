import { Component, inject, type OnInit, signal } from "@angular/core";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { PermissionActionDescriptorType } from "../../../../../adaa-types";
import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { PermissionAction } from "../../../constants/enums";
import { HasPermissionDirective } from "../../../directives";
import { PermissionActionModelType } from "../../../models";
import { ConsoleService, LanguageService, SystemLayoutService } from "../../../services";
import { ColorLegendComponent } from "../../charts/color-legend/color-legend.component";
import { SpinnerComponent } from "../../spinner/spinner.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { TopbarComponent } from "./topbar/topbar.component";

@Component({
  selector: "adaa-system-layout",
  standalone: true,
  templateUrl: "./system-layout.component.html",
  styleUrl: "./system-layout.component.scss",
  imports: [
    TranslateModule,
    RouterLink,
    RouterLinkActive,
    FontAwesomeModule,
    SpinnerComponent,
    TopbarComponent,
    BreadcrumbComponent,
    TranslateTokenPipe,
    HasPermissionDirective,
    ColorLegendComponent,
  ],
})
export class SystemLayoutComponent implements OnInit {
  readonly languageService = inject(LanguageService);
  readonly systemLayoutService = inject(SystemLayoutService);
  readonly consoleService = inject(ConsoleService);

  isDesktopDevice = signal<boolean>(true);
  sidebarType = signal<"full" | "mini">("full");

  readonly currentYear = () => new Date().getFullYear();

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  public get icon() {
    return {
      menu: faBars,
    };
  }

  public ngOnInit(): void {
    this.systemLayoutService.init();

    this.isDesktopDevice.set(AdaaHelper.isDesktopDevice());
    if (!AdaaHelper.isDesktopDevice()) {
      this.sidebarType.set("mini");
    }

    this.systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe(() => {
      this.consoleService.init(false);
    });

    this.systemLayoutService.hasCycleChanged$.pipe(this.#untilDestroy()).subscribe(() => {
      this.consoleService.init(false);
    });
  }

  public toggleSidebar(): void {
    this.sidebarType.update((t) => (t === "full" ? "mini" : "full"));
  }

  public getPermission(permissionId: string | undefined): PermissionActionDescriptorType | undefined {
    if (permissionId) {
      const permissions = permissionId.split(",");
      const permissionsArray: PermissionActionModelType[] = [];

      permissions.forEach((e) => {
        permissionsArray.push(
          {
            permissionAction: PermissionAction.view,
            objectTypeId: +e,
          },
          {
            permissionAction: PermissionAction.manage,
            objectTypeId: +e,
          },
          {
            permissionAction: PermissionAction.execute,
            objectTypeId: +e,
          }
        );
      });

      return {
        actor: {
          modifier: "or",
          permissions: permissionsArray,
        },
      };
    } else return undefined;
  }
}
