import { Constants } from "../../../../constants/constants";
import { ChartFrequencyOptionType } from "./_types";

export * from "./_types";

export const CHART_FREQUENCY_CYCLES: ChartFrequencyOptionType[] = [
  {
    id: Constants.CHART_FREQUENCY_OPTION.ONE_YEAR,
    label: "kpi.year1",
    visibleIf: (data: { frequency: number }) => data.frequency < Constants.FREQUENCY.ANNUAL,
  },
  {
    id: Constants.CHART_FREQUENCY_OPTION.TWO_YEAR,
    label: "kpi.year2",
    visibleIf: (data: { frequency: number }) => data.frequency < Constants.FREQUENCY.EVERY_TWO_YEARS,
  },
  {
    id: Constants.CHART_FREQUENCY_OPTION.CYCLE,
    label: "cycle.full_cycle",
    visibleIf: (data: { kpiType: number }) => data.kpiType !== Constants.CONSTANT_MOKPITYPE,
  },
  {
    id: Constants.CHART_FREQUENCY_OPTION.FULL_TREND,
    label: "kpi.full_trend",
    visibleIf: (data: { kpiType: number }) =>
      data.kpiType === Constants.CONSTANT_NKPITYPE ||
      data.kpiType === Constants.CONSTANT_MOKPITYPE ||
      data.kpiType === Constants.CONSTANT_UAEKPI,
  },
];
