import { AfterViewInit, Component, ElementRef, input, output, viewChild } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import type { EkpiPerformanceModelType } from "../../../models";

@Component({
  selector: "adaa-pillar-ekpi-info",
  standalone: true,
  imports: [TranslateTokenPipe, FontAwesomeModule, TranslateModule],
  styleUrl: "./government-enabler-pillars.component.scss",
  template: `
    <div #kpiList>
      @for (item of ekpiList(); track item.id) {
        <div class="d-flex w-100 align-items-center my-1">
          <span class="ekpi-performance-indicator" [style.background-color]="getColor(item.evaluation)"></span>
          <button
            class="ekpi-performance-button"
            [title]="'common.form.label.entity_comparison' | translate"
            (click)="$event.stopPropagation(); graphClicked.emit(item)"
          >
            <fa-icon [icon]="graphIcon" />
          </button>
          <span class="ekpi-value mx-1 fw-bold" [class.wider]="increaseWidth(item.actual)">
            {{ getValue(item.actual, item.measurementUnit) }}
          </span>
          <span class="ekpi-label" (mouseover)="$event.stopPropagation(); labelClicked.emit(item)">{{
            item | translateToken: "ekpiName"
          }}</span>
        </div>
      }
    </div>
  `,
})
export class EkpiInfoComponent implements AfterViewInit {
  ekpiList = input.required<EkpiPerformanceModelType[]>();

  kpiListContent = output<string>();
  graphClicked = output<EkpiPerformanceModelType>();
  labelClicked = output<EkpiPerformanceModelType>();

  readonly graphIcon = faChartSimple;
  kpiList = viewChild<ElementRef>("kpiList");

  public ngAfterViewInit() {
    const content = this.kpiList()?.nativeElement.innerHTML;
    this.kpiListContent.emit(content);
  }

  public getColor(code?: number) {
    return Constants.COLOR_CODES[code ?? Constants.CONSTANT_COLORS.EVALUATION_GRAY];
  }

  public increaseWidth(value?: number) {
    return !(value === null || value === undefined);
  }

  public getValue(value?: number, measurement?: number) {
    if (value === null || value === undefined) return "-";
    if (measurement && measurement === Constants.MEASUREMENT.CONSTANT_MEASUREMENT_PERCENTAGE) {
      return AdaaHelper.percentageValue(value);
    }
    return AdaaHelper.roundValue(value);
  }
}
