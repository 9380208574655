import { Component, computed, effect, inject, Injector, input, OnInit, signal } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslatePipe } from "@ngx-translate/core";
import { filter, map } from "rxjs";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { Constants } from "../../../constants/constants";
import type { SelectedPeriodModelType, VisionDirectionModelType, VisionSubGovDirection } from "../../../models";
import { CalculationEngineApiService, LanguageService } from "../../../services";
import { DoughnutComponent } from "../../charts";
import { ProgressIndicatorComponent } from "../../general";
import { ItemSliderComponent } from "../item-slider/item-slider.component";
import { KpiVisionChartComponent } from "./kpi-vision-chart/kpi-vision-chart.component";
import { SubGovVisionCardComponent } from "./sub-gov-vision-card/sub-gov-vision-card.component";

@Component({
  selector: "adaa-gov-vision-performance",
  imports: [
    ItemSliderComponent,
    TranslateTokenPipe,
    DoughnutComponent,
    TranslatePipe,
    SubGovVisionCardComponent,
    ProgressIndicatorComponent,
  ],
  templateUrl: "./gov-vision-performance.component.html",
  styleUrl: "./gov-vision-performance.component.scss",
})
export class GovVisionPerformanceComponent implements OnInit {
  private readonly _injector = inject(Injector);
  private readonly _modalService = inject(NgbModal);
  private readonly _languageService = inject(LanguageService);
  private readonly _calcEngineApiService = inject(CalculationEngineApiService);

  id = input.required<number>();
  period = input.required<number>();
  isAnnual = input.required<boolean>();
  selectedPeriod = input<SelectedPeriodModelType>();

  data = signal<VisionDirectionModelType[]>([]);

  govVisionData = computed(() => {
    const data = this.data();
    if (!data || data.length === 0) return undefined;
    return data[0];
  });
  subGovVisionData = computed<VisionSubGovDirection[]>(() => {
    const data = this.data();
    if (!data || data.length === 0) return [];
    const list = data[0].subGovDirections;
    return list ?? [];
  });

  readonly #fetchData = () => {
    effect(
      () => {
        const period = this.id();
        const isAnnual = this.isAnnual();

        this._calcEngineApiService
          .getVisionDirectionsV2({
            period,
            isAnnual,
          })
          .pipe(
            filter((res) => !res.inError),
            map((res) => res.responseData)
          )
          .subscribe({
            next: (data) => {
              this.data.set(data.filter((e) => e.visionType === "nkpi"));
            },
          });
      },
      {
        injector: this._injector,
      }
    );
  };

  public get overallPerfColor() {
    return Constants.CONSTANT_COLORS.EVALUATION_GOLD;
  }

  public getChartTitle(label: string) {
    const isAnnual = this.selectedPeriod()?.type === "year";
    const year = this.selectedPeriod()?.period?.year;
    const quarter = this.selectedPeriod()?.period?.quarter;

    return `${isAnnual ? year : `Q${quarter} ${year}`} ${label}`;
  }

  public ngOnInit() {
    this.#fetchData();
  }

  public overallGovDirKpiVision(data: { govDirId: number; periodId: number; name: string }) {
    this._openKpiVisionModal({
      ...data,
      kpiType: Constants.KPI_TYPE.NKPI,
    });
  }

  public subGovDirKpiVision(data: { govDirId: number; periodId: number; name: string; subGovDirId?: number }) {
    this._openKpiVisionModal({
      ...data,
      kpiType: Constants.KPI_TYPE.NKPI,
    });
  }

  private _openKpiVisionModal({
    name,
    kpiType,
    govDirId,
    periodId,
    subGovDirId,
  }: {
    name: string;
    kpiType: number;
    govDirId: number;
    periodId: number;
    subGovDirId?: number;
  }) {
    const modal = this._modalService.open(KpiVisionChartComponent, {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      fullscreen: false,
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.chartName.set(this.getChartTitle(name));
    modal.componentInstance.periodId.set(periodId);
    modal.componentInstance.kpiType.set(kpiType);
    modal.componentInstance.govDirId.set(govDirId);
    if (subGovDirId) {
      modal.componentInstance.subGovDirId.set(subGovDirId);
    }
  }
}
