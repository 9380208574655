@if (benchmarks() && benchmarks().length > 0) {
    <div class="w-100 d-block my-4">
        <h4 class="mb-3">{{ "sidebar_tables.benchmarks" | translate }}</h4>
        <div ngbAccordion [closeOthers]="true">
            @for (item of benchmarks(); let $i = $index; track $i) {
                <div ngbAccordionItem>
                    <h2 ngbAccordionHeader>
                        <button
                            ngbAccordionButton
                            type="button"
                            class="accordion-button fw-normal justify-content-between"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            [class.has-diff]="item.hasDiff"
                            [attr.data-bs-target]="'#collapse' + $i"
                            [attr.dir]="languageService.direction()"
                        >
                            <span class="text-start" style="flex-grow: 2" [attr.dir]="languageService.direction()">{{
                                item.new | translateToken: "name"
                            }}</span>
                        </button>
                    </h2>
                    <div ngbAccordionCollapse class="overflow-hidden">
                        <section class="row d-flex align-content-center">
                            <div class="col-4 p-2 border border-white tbl-col bg-white text-primary fw-bold text-center fs-6 mt-3">
                                {{ "communication.difference.key" | translate }}
                            </div>

                            <div
                                class="p-2 border border-white tbl-col bg-white text-primary fw-bold text-center fs-6 mt-3"
                                [class.rounded-end-1]="showOnlyNew()"
                                [class.col-6]="showOnlyNew()"
                                [class.col-4]="!showOnlyNew()"
                            >
                                {{
                                    !showOnlyNew()
                                        ? ("communication.difference.new_value" | translate)
                                        : ("communication.difference.value" | translate)
                                }}
                            </div>

                            @if (!showOnlyNew()) {
                                <div class="col-4 p-2 border border-white tbl-col bg-white text-primary fw-bold text-center fs-6 mt-3">
                                    {{ "communication.difference.old_value" | translate }}
                                </div>
                            }
                        </section>
                        <div class="attachment row px-2">
                            <div class="col-12">
                                <!-- section:name-ae -->
                                <section class="row d-flex align-content-center">
                                    <div
                                        class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center txt-col"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                    >
                                        {{ "common.form.label.nameAE" | translate }}
                                    </div>

                                    <div
                                        class="p-2 border border-white tbl-col d-flex align-items-center"
                                        [class.rounded-end-1]="showOnlyNew()"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                        [class.tbl-diff]="
                                            showOnlyNew() || item.new?.status !== item.old?.status || !isEqual(item.new?.nameAE!, item.old?.nameAE!)
                                        "
                                    >
                                        @if (item.new) {
                                            <p class="txt-col p-0 text-left">
                                                {{ item.new.nameAE }}
                                            </p>
                                        }
                                    </div>

                                    @if (!showOnlyNew()) {
                                        <div
                                            class="col-4 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-center"
                                            [class.tbl-diff]="
                                                !item.old || item.new?.status !== item.old.status || !isEqual(item.new?.nameAE!, item.old.nameAE!)
                                            "
                                        >
                                            @if (item.old?.status !== Constants.OBJECT_STATUS.DRAFT) {
                                                {{ item.old?.nameAE }}
                                            }
                                        </div>
                                    }
                                </section>

                                <!-- section:name-en -->
                                <section class="row d-flex align-content-center">
                                    <div
                                        class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center txt-col"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                    >
                                        {{ "common.form.label.nameEN" | translate }}
                                    </div>

                                    <div
                                        class="p-2 border border-white tbl-col d-flex align-items-center"
                                        [class.rounded-end-1]="showOnlyNew()"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                        [class.tbl-diff]="
                                            showOnlyNew() ||
                                            !item.new ||
                                            item.new.status !== item.old?.status ||
                                            !isEqual(item.new.nameEN!, item.old?.nameEN!)
                                        "
                                    >
                                        @if (item.new) {
                                            <p class="txt-col p-0 text-left">
                                                {{ item.new.nameEN }}
                                            </p>
                                        }
                                    </div>

                                    @if (!showOnlyNew()) {
                                        <div
                                            class="col-4 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-center"
                                            [class.tbl-diff]="
                                                !item.old || item.new?.status !== item.old.status || !isEqual(item.new?.nameEN!, item.old.nameEN!)
                                            "
                                        >
                                            @if (item.old?.status !== Constants.OBJECT_STATUS.DRAFT) {
                                                {{ item.old?.nameEN }}
                                            }
                                        </div>
                                    }
                                </section>

                                <!-- section:benchmark-type -->
                                <section class="row d-flex align-content-center">
                                    <div
                                        class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center txt-col"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                    >
                                        {{ "nkpi.attachmentType" | translate }}
                                    </div>

                                    <div
                                        class="p-2 border border-white tbl-col d-flex align-items-center"
                                        [class.rounded-end-1]="showOnlyNew()"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                        [class.tbl-diff]="
                                            showOnlyNew() ||
                                            !item.new ||
                                            item.new.status !== item.old?.status ||
                                            !isEqual(item.new.benchType!, item.old?.benchType!)
                                        "
                                    >
                                        {{ benchType(item.new?.benchType!) }}
                                    </div>

                                    @if (!showOnlyNew()) {
                                        <div
                                            class="col-4 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-center txt-col"
                                            [class.tbl-diff]="
                                                !item.old ||
                                                item.new?.status !== item.old.status ||
                                                !isEqual(item.new?.benchType!, item.old.benchType!)
                                            "
                                        >
                                            @if (item.old?.status !== Constants.OBJECT_STATUS.DRAFT) {
                                                {{ benchType(item.old?.benchType!) }}
                                            }
                                        </div>
                                    }
                                </section>

                                <!-- section:benchmark-source -->
                                <section class="row d-flex align-content-center">
                                    <div
                                        class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center txt-col"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                    >
                                        {{ "ekpi.source" | translate }}
                                    </div>

                                    <div
                                        class="p-2 border border-white tbl-col d-flex align-items-center"
                                        [class.rounded-end-1]="showOnlyNew()"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                        [class.tbl-diff]="
                                            showOnlyNew() ||
                                            !item.new ||
                                            item.new.status !== item.old?.status ||
                                            !isEqual(item.new.benchSource!, item.old?.benchSource!)
                                        "
                                    >
                                        {{ item.new?.benchSource! }}
                                    </div>

                                    @if (!showOnlyNew()) {
                                        <div
                                            class="col-4 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-center txt-col"
                                            [class.tbl-diff]="
                                                !item.old ||
                                                item.new?.status !== item.old.status ||
                                                !isEqual(item.new?.benchSource!, item.old.benchSource!)
                                            "
                                        >
                                            @if (item.old?.status !== Constants.OBJECT_STATUS.DRAFT) {
                                                {{ item.old?.benchSource! }}
                                            }
                                        </div>
                                    }
                                </section>

                                <!-- section:benchmark-attachments -->
                                <section class="row d-flex align-content-center mx-1">
                                    <adaa-attachment-diff-view
                                        [newData]="item.new?.benchFileList!"
                                        [oldData]="item.old?.benchFileList!"
                                        [showOnlyNew]="false"
                                        [showCustomFields]="true"
                                    ></adaa-attachment-diff-view>
                                </section>

                                <!-- section:benchmark-list_of_values -->
                                @if (item.new?.kpiBenchMarkLovs?.length) {
                                    <section class="row d-flex align-content-center mx-1">
                                        <p class="fs-5 mt-3">{{ "helpdesk.list_of_values" | translate }}</p>
                                        <div
                                            class="p-2 border border-white tbl-col d-flex align-items-center col-12"
                                            [class.rounded-end-1]="showOnlyNew()"
                                            [class.tbl-diff]="showOnlyNew() || !item.new"
                                        >
                                            <table class="w-100 lov">
                                                <thead>
                                                    <tr>
                                                        <th>{{ "nkpi.country" | translate }}</th>
                                                        <th>{{ "nkpi.benchYear" | translate }}</th>
                                                        <th>{{ "communication.difference.new_value" | translate }}</th>
                                                        <th>{{ "communication.difference.old_value" | translate }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    @for (
                                                        lov of mergeNewAndOld(item.new?.kpiBenchMarkLovs!, item.old?.kpiBenchMarkLovs!);
                                                        track $index
                                                    ) {
                                                        <tr>
                                                            <td [class.has-diff]="lov.new.status !== lov.old.status">
                                                                {{ lov.new | translateToken: "countryName" }}
                                                            </td>
                                                            <td [class.has-diff]="lov.new.status !== lov.old.status">{{ lov.new.year }}</td>
                                                            <td [class.has-diff]="lov.new.status !== lov.old.status">{{ lov.new.value }}</td>
                                                            <td [class.has-diff]="lov.new.status !== lov.old.status">
                                                                @if (lov.old.status !== Constants.OBJECT_STATUS.DRAFT) {
                                                                    {{ lov.old.value }}
                                                                }
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
}
