import { Component, computed, inject, input, signal } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs";

import { TranslateTokenPipe } from "../../../../../../../core/pipes";
import { AdaaHelper, useNewDS } from "../../../../../../../core/utils";
import { Constants } from "../../../../../../constants/constants";
import { MetricsDetailModelType, PropTypeModelType } from "../../../../../../models";
import { LanguageService, PropertiesService } from "../../../../../../services";
import type { ScreenSectionConfigType } from "../../wf-difference.types";

@Component({
  selector: "adaa-formula-diff-view",
  standalone: true,
  imports: [TranslateModule, TranslateTokenPipe],
  templateUrl: "./formula-diff-view.component.html",
  styleUrl: "./formula-diff-view.component.scss",
})
export class FormulaDiffViewComponent {
  readonly languageService = inject(LanguageService);
  private _propertiesService = inject(PropertiesService);
  private _translateService = inject(TranslateService);

  isNew = input<boolean>(false);
  showOnlyNew = input.required<boolean>();
  config = input.required<ScreenSectionConfigType>();
  newData = input<Record<string, never>>();
  oldData = input<Record<string, never>>();
  showDIffOnly = input<boolean>(false);

  ytpCalcs = signal<PropTypeModelType[]>([]);
  title = signal<string>("");

  useNewDS = computed(() => {
    if (this.newData()) return useNewDS(this.newData()?.planId);
    if (this.oldData()) return useNewDS(this.oldData()?.planId);
    return false;
  });
  label = computed(() => {
    const { label } = this.config();
    if (!label) return "";
    if (typeof label === "string") return label;
    return label({ useNewDS: this.useNewDS() } as never);
  });
  newMetrics = computed(() => {
    const data = this.newData()?.metrics;
    return data as unknown as MetricsDetailModelType[];
  });
  oldMetrics = computed(() => {
    const data = this.oldData()?.metrics;
    return data as unknown as MetricsDetailModelType[];
  });
  formulaIsDiff = computed(() => this.newData()?.formula !== this.oldData()?.formula);

  readonly isFormula = (formula: string) => formula !== "NOFORMULAV";
  readonly getFormula = (formula: string) => {
    if (formula === "NOFORMULAV") return "skpi.no_formula";
    return formula;
  };
  readonly getYtp = (id: number) => {
    const ytp = this.ytpCalcs().find((e) => e.id === id);
    return ytp ? AdaaHelper.getItemValueByToken(ytp, "name") : "";
  };
  readonly metricIsDiff = (id: number, compareWith: "new" | "old") => {
    const metrics = compareWith === "new" ? this.newMetrics() : this.oldMetrics();
    const index = metrics.findIndex((i) => i.id === id);
    if (index === -1) return "hasDiff";
    return metrics[index];
  };
  readonly isAboveAnnual = (frequency: number) => {
    const annualFrequency = Constants.FREQUENCY.ANNUAL;
    return frequency >= annualFrequency;
  };

  public ngOnInit() {
    this._fetchConstants();
  }

  private _fetchConstants() {
    this._propertiesService
      .getPropById(Constants.CONSTANT_YTP)
      .pipe(filter((res) => !res.inError))
      .subscribe({
        next: (res) => this.ytpCalcs.set(res.responseData),
      });
  }

  public getInfo(metricType: string) {
    this.title.set(
      this._translateService.instant(metricType === Constants.METRICS_TYPE.LOCAL ? "metrics.local" : "metrics.global")
    );
  }
}
