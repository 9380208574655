import { Constants } from "../../shared/constants/constants";
import { AdaaHelper } from "./adaa-helper";

const isNewDS = (planId: number | undefined) => {
  if (!planId) return false;

  switch (+planId) {
    case 233:
    case 234:
    case 235:
    case 236:
      return false;
    case 237:
    default:
      return true;
  }
};

export const useNewDS = (currentPlanId?: number) => {
  const planId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, {
    property: "id",
    type: "prop",
  });

  return isNewDS(currentPlanId ?? +planId);
};
