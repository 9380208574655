import { DatePipe } from "@angular/common";
import { Component, inject, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { DubaiTimePipe, TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import type { CardNote } from "../../../models";
import { CardNoteApiService } from "../../../services";

@Component({
  selector: "adaa-display-notes",
  standalone: true,
  imports: [TranslateModule, TranslateTokenPipe, DubaiTimePipe, DatePipe],
  styleUrl: "./styles.scss",
  template: `
    <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 px-1 notes shadow-sm">
      <div class="w-100 d-flex align-content-center notes--item">
        <span class="d-block w-50 fw-bold px-2"> {{ "sidebar_tables.notes" | translate }} ({{ notes().length }}) </span>
      </div>

      @for (note of notes(); track note.id) {
        <div class="card w-100 bg-white rounded-0 note mb-3">
          <div class="card-title d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-sm btn-secondary text-white border rounded-circle"
              (click)="deleteNote(note.id)"
            >
              <i class="adaa-icon-delete"></i>
            </button>
          </div>

          <div class="card-body">
            {{ note | translateToken: "note" }}
          </div>

          <div class="card-footer d-flex">
            <span class="text-primary fw-bold">
              {{ note | translateToken: "name" }} -
              <span class="mx-1 text-secondary">{{ note.createTS | dubaiTime | date: "dd/MM/yyyy hh:mm aa" }}</span>
            </span>
          </div>
        </div>
      }
    </div>
  `,
})
export class DisplayNotesComponent {
  private readonly _cardNoteApiService = inject(CardNoteApiService);

  id = input.required<number>();
  type = input.required<number>();

  notes = signal<CardNote[]>([]);

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  public ngOnInit() {
    this._getNotes({
      id: this.id(),
      type: this.type(),
    });
    this._cardNoteApiService.refreshNotesListener.pipe(this.#untilDestroy()).subscribe({
      next: (data) => this._getNotes(data),
    });
  }

  public deleteNote(id: number) {
    this._cardNoteApiService.deleteById(id).subscribe({
      next: () => {
        this._getNotes({
          id: this.id(),
          type: this.type(),
        });
      },
    });
  }

  private _getNotes({ id, type }: { id: number; type: number }) {
    this._cardNoteApiService.getAllByItemType(id, type).subscribe({
      next: (res) => {
        this.notes.set([...res.responseData]);
      },
    });
  }
}
