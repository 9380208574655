import { NgClass } from "@angular/common";
import { Component, computed, inject, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../../../../core/pipes";
import { LanguageService } from "../../../../../../services";
import {
  NationalStrategyGovDirType,
  NationalStrategySubGovDirType,
} from "../../../../../general/national-strategies-selector/types";

@Component({
  selector: "adaa-linked-national-strategies-card-view",
  standalone: true,
  imports: [TranslateModule, TranslateTokenPipe, NgClass],
  styleUrl: "../styles.scss",
  template: `
    <div class="card d-block w-100 position-relative rounded-1 bg-white shadow-sm national-strategies-view">
      <div class="card-title py-1 px-2 mb-0 border-1 border-bottom">
        <label class="fw-bold">
          {{ useNewDS() ? ("dtkpi.nationalStrategy" | translate) : ("initiatives.government_direction" | translate) }}
        </label>

        <p class="m-0">{{ data() | translateToken: "name" }}</p>
      </div>
      <div class="card-body p-0">
        <div class="row mx-0 border-1 border-bottom">
          <div
            class="col-8 border-1"
            [class.border-end]="languageService.direction() === 'ltr'"
            [class.border-start]="languageService.direction() === 'rtl'"
          >
            <label class="fw-bold">
              {{
                useNewDS()
                  ? ("dtkpi.enablerObjective" | translate)
                  : ("initiatives.sub_government_directions" | translate)
              }}
            </label>
          </div>
        </div>

        @for (subGovDir of subGovDirs(); track subGovDir.id) {
          <div class="row mx-0" [ngClass]="{ 'border-1': !$last, 'border-bottom': !$last }">
            <div
              class="col-8 border-1 p-2"
              [class.border-end]="languageService.direction() === 'ltr'"
              [class.border-start]="languageService.direction() === 'rtl'"
            >
              {{ subGovDir | translateToken: "name" }}
            </div>
          </div>
        }
      </div>
    </div>
  `,
})
export class LinkedNationalStrategiesCardViewComponent {
  readonly languageService = inject(LanguageService);

  useNewDS = input<boolean>();
  data = input<NationalStrategyGovDirType & { subGovs: NationalStrategySubGovDirType[] }>();

  subGovDirs = computed(() => this.data()?.subGovs ?? []);
}
