<ng-template #modal let-modal>
    <div class="modal-header bg-white">
        <div class="w-100 d-flex justify-content-center">
            <h4 class="fw-bold w-100 text-center py-1">{{ "nkpi.available_fields" | translate }}</h4>

            <button type="button" class="btn-close mt-2" aria-label="Close" (click)="modal.dismiss()"></button>
        </div>
    </div>

    <div class="modal-body">
        <div
            class="w-100 d-flex justify-content-center align-items-start align-content-start gap-2 flex-wrap p-2"
            [class.py-5]="unselectedFields().length === 0"
        >
            @for (field of unselectedFields(); track $index) {
                <div
                    class="bg-white p-3 w-100 text-secondary fw-bold text-center shadow-sm border border-1 available-field"
                    [class.is-cached]="isFieldCached(field)"
                    (click)="cacheField(field)"
                >
                    {{ field | translateToken: "name" }}
                </div>
            }

            @if (unselectedFields().length === 0) {
                <div class="bg-body-tertiary p-3 w-100 text-dark fw-bold text-center shadow border border-1 border-secondary">
                    {{ "common.tables.no_info" | translate }}
                </div>
            }
        </div>
    </div>

    <div class="modal-footer d-flex w-100 align-content-center justify-content-center gap-2 py-2">
        <button type="button" class="btn btn-primary px-4" (click)="modal.dismiss()">
            {{ "common.form.button.close" | translate }}
        </button>

        <button type="button" class="btn btn-primary px-4" [disabled]="unselectedFields().length === 0" (click)="addField(); modal.dismiss()">
            {{ "common.form.button.save" | translate }}
        </button>
    </div>
</ng-template>

<div class="container mb-1">
    <div class="position-relative d-flex justify-content-end">
        <button class="btn btn-secondary text-light" (click)="openModal(modal)"><i class="fa fa-add"></i> {{ "tooltips.add" | translate }}</button>
    </div>
</div>

@if (selectedFields().length === 0) {
    <div class="container d-flex p-2 mt-3 mb-2 rounded-1 shadow-sm border border-1 bg-white align-items-center align-content-center">
        <p class="fw-bold text-center w-100 p-0 m-0 text-secondary">
            {{ "common.tables.no_info" | translate }}
        </p>
    </div>
}

@for (field of selectedFields(); track $index) {
    <div class="bg-white px-2 pb-1 w-100 text-secondary fw-bold shadow-sm border border-1 mt-4 mb-2 py-1 ext-field">
        @if (field.mandatory !== "Y") {
            <div class="w-100 d-flex justify-content-end position-relative top">
                <button class="btn btn-sm btn-danger rounded-circle border border-1 text-white position-absolute" (click)="removeField(field)">
                    <i class="fa fa-trash-alt"></i>
                </button>
            </div>
        }

        @if (attributeHelpers.isInputSimpleType(field.dataType)) {
            <adaa-form-input
                [controlName]="'field-' + $index"
                [label]="field | translateToken: 'name'"
                [required]="field.mandatory === 'Y'"
                [isTextArea]="attributeHelpers.isTextAreaInputType(field.dataType)"
                [setDefaultValue]="field.value"
                (inputChanges)="captureInput($event, field)"
            />
        }

        @if (attributeHelpers.isDatePickerInputType(field.dataType)) {
            <adaa-form-input-date
                [controlName]="'field-' + $index"
                [label]="field | translateToken: 'name'"
                [required]="field.mandatory === 'Y'"
                [setDefaultValue]="field.value"
                (inputChanges)="captureInput($event, field)"
            />
        }

        @if (attributeHelpers.isTreeInputType(field.dataType)) {
            <adaa-form-dropdown-tree
                [controlName]="'field-' + $index"
                [label]="field | translateToken: 'name'"
                [options]="orgUnitOptions()"
                [required]="field.mandatory === 'Y'"
                [setDefaultValue]="field.value"
                (inputChanges)="captureInput($event, field)"
            />
        }

        @if (attributeHelpers.isDropDownInputType(field.dataType)) {
            @if (attributeHelpers.isUsersType(field.dataType)) {
                <adaa-form-dropdown
                    [controlName]="'field-' + $index"
                    [label]="field | translateToken: 'name'"
                    [options]="usersOptions()"
                    [required]="field.mandatory === 'Y'"
                    [setDefaultValue]="field.value"
                    (inputChanges)="captureInput($event, field)"
                />
            } @else {
                <adaa-form-dropdown
                    [controlName]="'field-' + $index"
                    [label]="field | translateToken: 'name'"
                    [options]="textmapOptions(field.libExtAttrLovs ?? [])"
                    [required]="field.mandatory === 'Y'"
                    [setDefaultValue]="field.value"
                    (inputChanges)="captureInput($event, field)"
                />
            }
        }
    </div>
}
