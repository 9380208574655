import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import { Constants } from "../constants/constants";
import {
  CycleModelType,
  EntityMapModelType,
  EntityModelType,
  type MainResponseModel,
  type SearchFields,
} from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class CyclesApiService {
  private _mainService = inject(MainService);

  public getAll() {
    const url = `plan/getall`;

    return this._mainService
      .sendRequest<MainResponseModel<CycleModelType[]>>({
        method: "GET",
        url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getAllCycles(limit: string | number = 10, offset: string | number = 0, filters?: SearchFields) {
    const url = "plan/getallv2";
    const qs = new URLSearchParams();

    qs.append("offset", `${offset}`);
    qs.append("limit", `${limit}`);
    qs.append("itemType", `${Constants.CONSTANT_CYCLES}`);

    return this._mainService
      .sendRequest<MainResponseModel<CycleModelType[]>>({
        method: "POST",
        url,
        data: filters,
      })
      .pipe(filter((res) => !res.inError));
  }

  public createPlan(data: CycleModelType) {
    const url = `plan/create?isFinish=true`;

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "POST",
        url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public updatePlan(data: CycleModelType) {
    const url = `plan/update?isFinish=true`;

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "POST",
        url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getById(id: number) {
    const url = `plan/getbyid/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<CycleModelType>>({
        method: "GET",
        url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getByPlanId(id: number) {
    const url = `map/getByPlanId/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<EntityMapModelType[]>>({
        method: "GET",
        url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public deletePlan(id: number) {
    const url = `plan/deleteById/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<EntityModelType[]>>({
        method: "DELETE",
        url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getAllIncludeArchived() {
    return this.getAllCycles("", "", {
      searchFields: {
        status: {
          value: undefined,
          lov: [1, 4, 5, 6],
          order: undefined,
        },
      },
    } satisfies SearchFields).pipe(filter((res) => !res.inError));
  }

  public sendToEntities(data: Partial<EntityMapModelType>[]) {
    const url = `map/sendToEntities`;

    return this._mainService
      .sendRequest<MainResponseModel<null>>({
        method: "POST",
        url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }
}
