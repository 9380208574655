import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { PercentageValuePipe, RoundValuePipe, SafeHtmlPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { AdaaBoolean, CellType } from "../../../constants/enums";
import { ParameterCatalog, TableInputChangedOutput, TableInputs, ValueText } from "../../../models";
import { DataTableCellSpecialComponent } from "../data-table-cell-special/data-table-cell-special.component";

@Component({
  selector: "adaa-data-table-cell",
  standalone: true,
  imports: [
    TranslateModule,
    PercentageValuePipe,
    DatePipe,
    SafeHtmlPipe,
    RoundValuePipe,
    DataTableCellSpecialComponent,
  ],
  templateUrl: "./data-table-cell.component.html",
  styleUrl: "./data-table-cell.component.scss",
})
export class DataTableCellComponent {
  @Input() configKey: string;
  @Input() column: ParameterCatalog;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: any;
  @Input() tableInputs: TableInputs[] = [];
  @Output() inputChanged = new EventEmitter<TableInputChangedOutput>();

  CellType = CellType;
  Constants = Constants;
  AdaaBoolean = AdaaBoolean;
  AdaaHelper = AdaaHelper;

  public get isCheckboxChecked(): boolean {
    if (this.tableInputs) {
      const input = this.tableInputs.find((e) => e.fieldName === this.column.fieldName);
      if (input) {
        if (input.defaultValue) {
          return input.checkboxMode === "trueFalse" ? input.defaultValue : input.defaultValue === "Y";
        }
        return input.checkboxMode === "trueFalse"
          ? this.data[this.column.fieldName]
          : this.data[this.column.fieldName] === "Y";
      }

      return false;
    } else return this.data[this.column.fieldName];
  }

  public get isInputDisabled(): boolean {
    if (this.tableInputs) {
      const input = this.tableInputs.find((e) => e.fieldName === this.column.fieldName);
      if (input?.disableRules) {
        let disabled = false;

        for (let index = 0; index < input.disableRules.length; index++) {
          const rule = input.disableRules[index];

          if (rule.operator === "equal") {
            if (this.data[rule.propertyName] === rule.compareWith) {
              disabled = true;
            }
          } else if (rule.operator === "not-equal") {
            disabled = true;
            if (this.data[rule.propertyName] === rule.compareWith) {
              disabled = false;
              break;
            }
          } else if (rule.operator === "notNull") {
            disabled = true;
            if (!AdaaHelper.isDefined(this.data[rule.propertyName])) {
              disabled = false;
            }
          } else if (rule.operator === "null") {
            disabled = true;
            if (AdaaHelper.isDefined(this.data[rule.propertyName])) {
              disabled = false;
            }
          }
        }

        return disabled;
      }

      return false;
    } else
      return this.data[this.column.fieldName + "Active"] ? this.data[this.column.fieldName + "Active"] !== "Y" : false;
  }

  public get getDdlOptions(): ValueText[] {
    let options: ValueText[] = [];

    if (this.tableInputs) {
      const input = this.tableInputs.find((e) => e.fieldName === this.column.fieldName);

      if (input?.options) options = input.options;
    }

    return options;
  }

  public getPercentageColor(): string {
    //Milestone Table is using the onTimeProgressColor instead of progressColor
    if (
      this.configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.PROJECT_MILESTONES_CONF_LIST ||
      this.configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.NATIONAL_PROJECTS_CONF_LIST
    ) {
      if (AdaaHelper.isDefined(this.data[this.column.fieldName + "Color"])) {
        return Constants.COLOR_CODES[this.data["onTimeProgressColor"]];
      }
    }

    if (AdaaHelper.isDefined(this.data[this.column.fieldName + "Color"])) {
      return Constants.COLOR_CODES[this.data[this.column.fieldName + "Color"]];
    } else if (AdaaHelper.isDefined(this.data[this.column.fieldName + "Evaluation"])) {
      return Constants.COLOR_CODES[this.data[this.column.fieldName + "Evaluation"]];
    } else {
      if (AdaaHelper.isDefined(this.data["evaluationPerformance"])) {
        return Constants.COLOR_CODES[this.data["evaluationPerformance"]];
      } else if (AdaaHelper.isDefined(this.data["performanceEvaluation"])) {
        return Constants.COLOR_CODES[this.data["performanceEvaluation"]];
      } else if (AdaaHelper.isDefined(this.data["evaluationScore"])) {
        return Constants.COLOR_CODES[this.data["evaluationScore"]];
      } else if (AdaaHelper.isDefined(this.data["performance"])) {
        return Constants.COLOR_CODES[this.data["evaluation"]];
      } else {
        return Constants.COLOR_CODES[this.data["evaluationPerformance"]];
      }
    }
  }

  public checkboxValueChanged(event: Event, fieldName: string) {
    const target = event.target as HTMLInputElement;

    if (this.tableInputs) {
      const input = this.tableInputs.find((e) => e.fieldName === this.column.fieldName);
      if (input) {
        input.checkboxMode === "trueFalse"
          ? this.inputChanged.emit({ value: target.checked ? 1 : 0, data: this.data, fieldName })
          : this.inputChanged.emit({
              value: target.checked ? AdaaBoolean.Y : AdaaBoolean.N,
              data: this.data,
              fieldName,
            });

        return;
      }
    }
    this.inputChanged.emit({ value: target.checked ? 1 : 0, data: this.data, fieldName });
  }

  public getNumberValue(fieldName: string) {
    return this.data[fieldName] as number;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public dropdownValueChanged(event: any, fieldName: string) {
    const value = +(event.target as unknown as HTMLSelectElement).value;
    this.inputChanged.emit({ value: value, data: this.data, fieldName });
  }
}
