import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import type { MainResponseModel, TaskModelType } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class CommunicationsApiService {
  private _mainService = inject(MainService);

  public countUnreadMessages(opts?: { useLoader?: boolean }) {
    const url = "commcenter/unread";

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "GET",
        url,
        loader: opts?.useLoader,
      })
      .pipe(filter((res) => !res.inError));
  }

  public pendingActions({ itemId, itemType }: { itemId: number; itemType: number }) {
    const url = `commcenter/pendingAction/${itemId}/${itemType}`;

    return this._mainService
      .sendRequest<MainResponseModel<TaskModelType>>({
        method: "GET",
        url,
        ignoreNotifications: true,
      })
      .pipe(filter((res) => !res.inError));
  }

  public pendingActionsPerEntity() {
    const url = "commcenter/getPendingByEntityId";

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "GET",
        url,
      })
      .pipe(filter((res) => !res.inError));
  }
}
