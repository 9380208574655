import { inject, Injectable } from "@angular/core";
import { Chart } from "chart.js";
import * as htmlToImage from "html-to-image";

import { LoaderService } from "./loader.service";

@Injectable({
  providedIn: "root",
})
export class PrintService {
  private _loaderService = inject(LoaderService);
  public async printPage(): Promise<void> {
    this._loaderService.start();
    await this._convertChartsToImages(); // Convert charts to images
    await this._convertSlidersToImages(); // Convert sliders to images

    const page = document.querySelector(".page-content") as HTMLElement;
    if (!page) return;

    // Create an iframe to render the content for printing
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "100%";
    iframe.style.height = "auto"; // Automatically adjust height to fit the content
    iframe.style.border = "none";
    iframe.style.top = "-9999px"; // Hide iframe offscreen
    document.body.appendChild(iframe);

    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
    if (!iframeDoc) return;

    // Clone the content of the page and insert into iframe
    const clonedContent = page.cloneNode(true) as HTMLElement;

    const elementToRemove = clonedContent.querySelector("adaa-float-action");
    if (elementToRemove) {
      elementToRemove.remove(); // Remove the element from the cloned content
    }

    iframeDoc.body.appendChild(clonedContent);

    // Copy styles from the main document to iframe to preserve the layout and styling
    const styles = Array.from(document.querySelectorAll("link, style"));
    styles.forEach((style) => {
      iframeDoc.head.appendChild(style.cloneNode(true)); // Clone styles into iframe
    });

    // Remove any scroll in the iframe and ensure all content is visible
    iframeDoc.body.style.margin = "0";
    iframeDoc.body.style.padding = "0";
    iframeDoc.body.style.overflow = "visible"; // Remove scrollbars and ensure content is fully visible
    iframeDoc.body.style.height = `${clonedContent.scrollHeight}px`; // Set iframe body height to content's height

    // Ensure the content doesn't overflow
    clonedContent.style.overflow = "visible"; // Make sure cloned content doesn't have overflow

    // After content is inserted, trigger print
    setTimeout(() => {
      // Focus on iframe and trigger print dialog
      iframe.contentWindow?.focus();
      iframe.contentWindow?.print(); // Open print dialog

      // Cleanup after print dialog is triggered
      setTimeout(() => {
        this._loaderService.end();
        window.location.reload();
      }, 500);
    }, 2000); // Delay to ensure content is rendered before print dialog
  }

  private async _convertChartsToImages(): Promise<void> {
    const canvasElements = Array.from(document.querySelectorAll("canvas")) as HTMLCanvasElement[];

    for (const canvas of canvasElements) {
      const chartInstance = Chart.getChart(canvas);

      if (chartInstance) {
        chartInstance.resize(); // Ensure correct size before capturing
        chartInstance.update();

        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)); // Wait for rendering
      }

      const img = document.createElement("img");

      // Capture exact size
      img.src = canvas.toDataURL("image/png");
      img.style.width = `${canvas.clientWidth}px`; // Force image inside container
      img.style.height = `${canvas.clientHeight}px`;
      img.style.maxWidth = "100%"; // Ensure it doesn’t overflow
      img.style.maxHeight = "100%";
      img.style.objectFit = "contain"; // Prevent stretching
      img.classList.add("chart-image");

      canvas.parentNode?.replaceChild(img, canvas);
    }
  }

  private async _convertSlidersToImages(): Promise<void> {
    const sliderElements = Array.from(document.querySelectorAll(".slider-container")) as HTMLElement[];

    for (const slider of sliderElements) {
      const img = document.createElement("img");

      // Optionally capture a screenshot (or manually render slider's appearance)
      img.src = await this._captureSliderAsImage(slider);
      img.style.width = `${slider.clientWidth}px`;
      img.style.height = `${slider.clientHeight}px`;
      img.style.maxWidth = "100%";
      img.style.maxHeight = "100%";
      img.classList.add("slider-image");

      slider.parentNode?.replaceChild(img, slider);
    }
  }

  private _captureSliderAsImage(slider: HTMLElement): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // Use html2canvas to capture the slider element as an image
      htmlToImage
        .toSvg(slider)
        .then((dataUrl) => {
          resolve(dataUrl); // Return the image URL (base64)
        })
        .catch((error) => {
          reject(error); // Handle errors
        });
    });
  }
}
