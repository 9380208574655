<adaa-data-table
    titleHeader="workflow.trigger_rules_table"
    [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.WORKFLOW_TRIGGERS_RULES"
    [isLocalData]="true"
    [localData]="filteredRules"
    [enableAdd]="enableAdd()"
    [enableViewAction]="isView()"
    [enableEditAction]="!isView()"
    [enableDeleteAction]="!isView()"
    [customTableButtons]="customButtons()"
    (buttonClicked)="buttonClicked()"
    (addNewClicked)="addNewClicked()"
    (actionClicked)="actionClicked($event)"
/>
