<div class="modal-header">
    <h4 class="modal-title">{{ "national_projects.national_projects_milestone.sub_milestone" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body" [formGroup]="subMilestoneForm">
    <adaa-form-steps [tabs]="tabs" [showActions]="false" (currentTab)="activeTab.set($event)">
        <div class="tab-pane fade" id="tab-0" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 0 }">
            <div class="bg-white shadow-md rounded p-2 mb-2">
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            controlName="nameAE"
                            formControlName="nameAE"
                            [invalid]="submitted && subMilestoneForm.get('nameAE')?.invalid!"
                            [label]="'common.form.label.nameAE' | translate"
                            [setValidator]="formValidation"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            controlName="nameEN"
                            formControlName="nameEN"
                            [invalid]="submitted && subMilestoneForm.get('nameEN')?.invalid!"
                            [label]="'common.form.label.nameEN' | translate"
                            [setValidator]="formValidation"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            controlName="dscAE"
                            formControlName="dscAE"
                            [invalid]="submitted && subMilestoneForm.get('dscAE')?.invalid!"
                            [label]="'common.form.label.descriptionAE' | translate"
                            [setValidator]="formValidation"
                            [isTextArea]="true"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            controlName="dscEN"
                            formControlName="dscEN"
                            [invalid]="submitted && subMilestoneForm.get('dscEN')?.invalid!"
                            [label]="'common.form.label.descriptionEN' | translate"
                            [setValidator]="formValidation"
                            [isTextArea]="true"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input-date
                            controlName="startDate"
                            formControlName="startDate"
                            [invalid]="submitted && subMilestoneForm.get('startDate')?.invalid!"
                            [label]="'national_projects.planned_start_date' | translate"
                            [setValidator]="formValidation"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input-date
                            controlName="endDate"
                            formControlName="endDate"
                            [invalid]="submitted && subMilestoneForm.get('endDate')?.invalid!"
                            [label]="'national_projects.planned_end_date' | translate"
                            [setValidator]="formValidation"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-dropdown
                            controlName="entityId"
                            formControlName="entityId"
                            [options]="entities"
                            [invalid]="submitted && subMilestoneForm.get('entityId')?.invalid!"
                            [label]="'national_projects.accountable_entity' | translate"
                            [setValidator]="formValidation"
                            (inputChanges)="entityIdChanged($event)"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-dropdown
                            controlName="otherEntityId"
                            formControlName="otherEntityId"
                            [clearable]="true"
                            [options]="otherEntities"
                            [invalid]="submitted && subMilestoneForm.get('otherEntityId')?.invalid!"
                            [label]="'national_projects.other_accountable_entity' | translate"
                            [setValidator]="formValidation"
                            (inputChanges)="otherEntityIdChanged($event)"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <adaa-form-checkbox controlName="completed" formControlName="completed" [label]="'national_projects.completed' | translate" />
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-pane fade" id="tab-0" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 1 }">
            <div class="bg-white shadow-md rounded p-2 mb-2">
                <adaa-team-members-list
                    tableTitle="national_projects.national_projects_milestone.sub_milestone_team_members"
                    [tableConf]="constants.MAIN_TABLE_LIST_CONF_KEY.MILESTONE_MEMBERS_CONF_LIST"
                    [teamMembers]="subMilestoneForm.get('teamMembers')?.value ?? []"
                    [entities]="filteredEntites"
                    [otherEntities]="filteredOtherEntites"
                    (teamMembersChanged)="subMilestoneForm.get('teamMembers')?.setValue($event)"
                />
            </div>
        </div>
    </adaa-form-steps>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ "common.form.button.close" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        {{ "common.form.button.save" | translate }}
    </button>
</div>
