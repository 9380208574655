import { inject, Injectable } from "@angular/core";

import { AdaaBoolean, Language } from "../constants/enums";
import type { EntityModelType, MainResponseModel } from "../models";
import { LanguageService } from "./language.service";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class EntitiesApiService {
  private _mainService = inject(MainService);
  private _languageService = inject(LanguageService);

  public createEntity(data: EntityModelType) {
    const url = `entity/create?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url: url,
      data,
    });
  }

  public updateEntity(data: EntityModelType) {
    const url = `entity/update?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url: url,
      data,
    });
  }

  public getAll(includeExternal: AdaaBoolean) {
    const lang = this._languageService.current();
    const url = `entity/getall?lang=${lang ?? Language.Arabic}&includeExternal=${includeExternal}`;

    return this._mainService.sendRequest<MainResponseModel<EntityModelType[]>>({
      method: "GET",
      url: url,
    });
  }

  public getById(entityId: number) {
    const url = `entity/getbyid/${entityId}`;

    return this._mainService.sendRequest<MainResponseModel<EntityModelType>>({
      method: "GET",
      url: url,
    });
  }

  public getAllV2() {
    const url = `entity/getallv2`;

    return this._mainService.sendRequest<MainResponseModel<EntityModelType[]>>({
      method: "POST",
      url: url,
      data: {},
    });
  }

  public getEntitiesByEnabler() {
    const url = `entity/getallbyenabler`;

    return this._mainService.sendRequest<MainResponseModel<EntityModelType[]>>({
      method: "GET",
      url,
    });
  }

  public getEntitiesForCycles() {
    const url = `entity/getAllForCycles`;

    return this._mainService.sendRequest<MainResponseModel<EntityModelType[]>>({
      method: "GET",
      url,
    });
  }

  public updateAddresses(data: EntityModelType) {
    const url = `entity/updateAddresses`;

    return this._mainService.sendRequest<MainResponseModel<EntityModelType[]>>({
      method: "POST",
      url,
      data,
    });
  }

  public updateState(data: EntityModelType) {
    const url = `entity/updateStatus`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "PUT",
      url,
      data,
    });
  }

  public deleteEntity(id: number) {
    const url = `entity/deleteById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "DELETE",
      url: url,
    });
  }

  public getEntitiesByPillarId(pillarId: number) {
    const url = `entity/getentitiesbypillarid/${pillarId}`;
    return this._mainService.sendRequest<MainResponseModel<EntityModelType[]>>({
      method: "GET",
      url: url,
    });
  }
}
