import { Component, EventEmitter, inject, input, OnInit, Output } from "@angular/core";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { FormCheckboxComponent } from "../../form";

@Component({
  selector: "adaa-table-legend-data-entry",
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule, FormCheckboxComponent],
  templateUrl: "./table-legend-data-entry.component.html",
  styleUrl: "./table-legend-data-entry.component.scss",
})
export class TableLegendDataEntryComponent implements OnInit {
  private _iconLibrary: FaIconLibrary = inject(FaIconLibrary);

  showCalculation = input<boolean>(false);
  hasAuditIssue = input<boolean>(false);
  showActions = input<boolean>(true);

  @Output() checkboxClicked = new EventEmitter<{ checkbox: "options" | "targets" | "calculation"; checked: boolean }>();

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faSquare);
  }
}
