import { Directive, inject, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective, NgControl } from "@angular/forms";

@Directive({
  selector: "([formControlName], [formControl])[adaaInputDisabled]",
  standalone: true,
  providers: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class FormControlDisabledDirective {
  private readonly _controlContainer = inject(ControlContainer);
  private readonly _ngControl = inject(NgControl, { self: true, optional: true });

  @Input() public set adaaInputDisabled(state: boolean) {
    const action = state ? "disable" : "enable";
    (this._controlContainer as FormGroupDirective).form.controls[this._ngControl?.name as string][action]();
  }
}
