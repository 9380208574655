import { AfterViewInit, Component, computed, ElementRef, inject, input, output, viewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import Handsontable from "handsontable";
import { CellChange } from "handsontable/common";
import { DetailedSettings } from "handsontable/plugins/nestedHeaders/nestedHeaders";
import { ColumnSettings, GridSettings } from "handsontable/settings";

import { environment } from "../../../../core/environments/environment";
import { AdaaHelper } from "../../../../core/utils/adaa-helper";
import { Constants } from "../../../constants/constants";
import { Language } from "../../../constants/enums";
import { AuditItem, DataEntry, DataEntryAuditEntry, DataEntryDetails } from "../../../models";
import { DataentryService } from "../../../services";
import { TableLegendDataEntryComponent } from "../../data-entry";

@Component({
  selector: "adaa-ekpi-audit",
  imports: [TableLegendDataEntryComponent],
  templateUrl: "./ekpi-audit.component.html",
  styleUrl: "./ekpi-audit.component.scss",
})
export class EkpiAuditComponent implements AfterViewInit {
  private _dataEntryService = inject(DataentryService);
  private _translateService = inject(TranslateService);

  dataEntryDetails = input.required<DataEntryDetails>();
  kpiId = input.required<number>();
  kpiType = input.required<number>();
  valueChanged = output<AuditItem[]>();

  hotTableContainer = viewChild.required<ElementRef>("hotTableContainer");

  private _columnsIndices: { year: number; col: number; isComment: boolean }[] = [];
  hotInstance: Handsontable;
  auditValuesList: AuditItem[] = [];

  isBounded = computed<boolean>(() => this.dataEntryDetails().trend === Constants.TREND.BOUNDED);
  isFormula = computed<boolean>(() => this.dataEntryDetails().formula !== Constants.FORMULA_STRING.NOFORMULA);
  dataEntriesGroupedByPeriod = computed<Map<number, DataEntry[]>>(() =>
    this.dataEntryDetails().dataEntries
      ? AdaaHelper.groupBy(this.dataEntryDetails().dataEntries!, (e: { periodId: number }) => e.periodId)
      : new Map<number, DataEntry[]>([[0, []]])
  );

  public ngAfterViewInit(): void {
    const container = this.hotTableContainer().nativeElement;
    const options = this._getOptions();
    this.hotInstance = new Handsontable(container, options);
  }

  private _getOptions(): GridSettings {
    const currentLang = AdaaHelper.getCurrentLang();
    const { columnWidths, columns } = this._getColumns();

    return {
      data: this.isFormula() ? this._getFormulaData() : this._getNoFormulaData(),
      rowHeaders: this._getRowHeaders(),
      rowHeaderWidth: 400,
      nestedHeaders: this._getColHeaders(),
      columns: columns,
      colWidths: columnWidths,
      cells: (row, column, _prop) =>
        this._dataEntryService.setCellColorEKpiAudit(
          row,
          column,
          this.dataEntryDetails(),
          this.auditValuesList,
          this._columnsIndices
        ),
      afterChange: (changes, _source) => this._handleCellValueChanged(changes),
      //Same Conf
      stretchH: "none",
      height: "auto",
      viewportColumnRenderingOffset: 999999,
      viewportRowRenderingOffset: 999999,
      hiddenColumns: true,
      layoutDirection: currentLang === Language.Arabic ? "rtl" : "ltr",
      language: currentLang === Language.Arabic ? "ar-AR" : "en-US",
      licenseKey: environment.handsontable_key,
    };
  }

  private _getRowHeaders(): string[] {
    const headers: string[] = [];

    //GOV KPI
    headers.push(this._translateService.instant("kpi.govKpi"));

    //Entities
    this.dataEntryDetails().entityDataEntries?.forEach((e) => {
      headers.push(AdaaHelper.getItemValueByToken(e, "entityName"));
    });

    return headers;
  }

  private _getColHeaders() {
    const headerColSpan = this.isBounded() ? 3 : 2;

    const firstHeader: Array<string | DetailedSettings> = [];
    const seconedHeader: Array<string | DetailedSettings> = [];
    this.dataEntriesGroupedByPeriod().forEach((dataEntry, _key) => {
      const entry = dataEntry[0];
      if (!entry) return;

      firstHeader.push({
        label: this._dataEntryService.getLabelForPeriodColumn(entry, this.dataEntryDetails().frequency!),
        colspan: headerColSpan,
      });

      if (this.isBounded())
        seconedHeader.push(
          this._translateService.instant("kpi.lower_limit"),
          this._translateService.instant("kpi.upper_limit")
        );
      else seconedHeader.push(this._translateService.instant("data_entry.target"));

      seconedHeader.push(this._translateService.instant("data_entry.actuals"));

      //Audit
      if (entry.month === 12) {
        firstHeader.push({ label: entry.year.toString(), colspan: 3 });
        seconedHeader.push(
          this._translateService.instant("data_entry.annual_actual"),
          this._translateService.instant("data_entry.audited_annual_actual"),
          this._translateService.instant("common.form.label.comments")
        );
      }
    });

    return [firstHeader, seconedHeader];
  }

  private _getColumns() {
    const columnWidths: number[] = [];
    const columns: Array<ColumnSettings> = [];
    let index = 0;

    this.dataEntriesGroupedByPeriod().forEach((dataEntry, _key) => {
      const entry = dataEntry[0];
      if (!entry) return;

      //Targets
      if (this.isBounded()) {
        columnWidths.push(150, 150);
        columns.push(
          { readOnly: true, className: "disabledCell" }, // * Lower Limit
          { readOnly: true, className: "disabledCell" } // * Upper Limit
        );
        index += 2;
      } else {
        columnWidths.push(150);
        columns.push({ readOnly: true, className: "disabledCell" });
        index += 1;
      }

      //Actuals - Supports text map and normal input - textmapId
      columnWidths.push(150);
      if (this.dataEntryDetails().textmapId) {
        columns.push({
          type: "dropdown",
          source: [],
          readOnly: true,
          className: "disabledCell",
        });
      } else {
        columns.push({
          readOnly: true,
          className: "disabledCell",
        });
      }
      index += 1;

      //Audit
      if (entry.month === 12) {
        columnWidths.push(150, 150, 200);
        columns.push(
          { readOnly: true, className: "disabledCell" }, //annual actual
          {}, //audited annual actual
          {} //comment column
        );
        index += 3;
        this._columnsIndices.push(
          { year: entry.year, col: index - 2, isComment: false },
          { year: entry.year, col: index - 1, isComment: true }
        );
      }
    });

    return { columnWidths, columns };
  }

  private _getNoFormulaData() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let innerData: any[] = [];

    if (!this.dataEntryDetails().entityDataEntries || !this.dataEntryDetails().frequency) return data;

    //GOV KPI
    this.dataEntryDetails().dataEntries?.forEach((entry) => {
      //TARGETS
      if (this.isBounded()) {
        const dataArray = entry.isBaseLineYear
          ? [this._translateService.instant("kpi.baselineYear"), this._translateService.instant("kpi.baselineYear")] //baseline
          : this.dataEntryDetails().textmapId
            ? ["", ""] //textmapid
            : [entry.lowerLimit, entry.highLimit]; //default;

        innerData.push(...dataArray);
      } else {
        const dataArray = entry.isBaseLineYear
          ? [this._translateService.instant("kpi.baselineYear")] //baseline
          : this.dataEntryDetails().textmapId
            ? [""] //textmapid
            : [entry.target]; //default;
        innerData.push(...dataArray);
      }

      //ACTUALS
      if (entry.ignored) innerData.push("N/A");
      else if (this.dataEntryDetails().textmapId) innerData.push("");
      else innerData.push(entry.actual);

      //AUDITED VALUES
      if (entry?.month === 12) {
        const auditEntityByPeriod = this.dataEntryDetails().auditEntries?.find((e) => e.year === entry.year);
        if (auditEntityByPeriod) {
          //Annual Actual Column
          innerData.push(auditEntityByPeriod.annualActualIgnored ? "N/A" : auditEntityByPeriod.annualActual);
          //Audited Annual Actual Column
          const ignored = auditEntityByPeriod.wfProcessCtlId
            ? auditEntityByPeriod.proposedAuditIgnored
            : auditEntityByPeriod.auditedActualIgnored;
          innerData.push(
            ignored
              ? "N/A"
              : auditEntityByPeriod.wfProcessCtlId
                ? auditEntityByPeriod.proposedAuditActual
                : auditEntityByPeriod.auditedActual
          );
          //Audited Annual Actual Column
          innerData.push(
            auditEntityByPeriod.wfProcessCtlId ? auditEntityByPeriod.proposedComment : auditEntityByPeriod.comment
          );
        }
      }
    });
    data.push(innerData);

    //Entities
    this.dataEntryDetails().entityDataEntries?.forEach((entity) => {
      innerData = [];

      entity.dataEntries?.forEach((entry) => {
        //TARGETS
        if (this.isBounded()) {
          const dataArray = entry.isBaseLineYear
            ? [this._translateService.instant("kpi.baselineYear"), this._translateService.instant("kpi.baselineYear")] //baseline
            : this.dataEntryDetails().textmapId
              ? ["", ""] //textmapid
              : [entry.lowerLimit, entry.highLimit]; //default;

          innerData.push(...dataArray);
        } else {
          const dataArray = entry.isBaseLineYear
            ? [this._translateService.instant("kpi.baselineYear")] //baseline
            : this.dataEntryDetails().textmapId
              ? [""] //textmapid
              : [entry.target]; //default;
          innerData.push(...dataArray);
        }

        //ACTUALS
        if (entry.ignored) innerData.push("N/A");
        else if (this.dataEntryDetails().textmapId) innerData.push("");
        else innerData.push(entry.actual);

        //AUDITED VALUES
        if (entry?.month === 12) {
          const auditEntity = this.dataEntryDetails().ekpiAuditEntries?.find((e) => e.entityId === entity.entityId);
          if (auditEntity) {
            const auditEntityByPeriod = auditEntity.audits?.find((e) => e.year === entry.year);
            if (auditEntityByPeriod) {
              //Annual Actual Column
              innerData.push(auditEntityByPeriod.annualActualIgnored ? "N/A" : auditEntityByPeriod.annualActual);
              //Audited Annual Actual Column
              const ignored = auditEntityByPeriod.wfProcessCtlId
                ? auditEntityByPeriod.proposedAuditIgnored
                : auditEntityByPeriod.auditedActualIgnored;
              innerData.push(
                ignored
                  ? "N/A"
                  : auditEntityByPeriod.wfProcessCtlId
                    ? auditEntityByPeriod.proposedAuditActual
                    : auditEntityByPeriod.auditedActual
              );
              //Audited Annual Actual Column
              innerData.push(
                auditEntityByPeriod.wfProcessCtlId ? auditEntityByPeriod.proposedComment : auditEntityByPeriod.comment
              );
            }
          }
        }
      });

      data.push(innerData);
    });

    return data;
  }

  private _getFormulaData() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let innerData: any[] = [];

    if (!this.dataEntryDetails().entityDataEntries || !this.dataEntryDetails().frequency) return data;

    //GOV KPI
    this.dataEntriesGroupedByPeriod().forEach((dataEntry, _key) => {
      const entry = dataEntry[0];
      if (!entry) return;

      if (this.isBounded()) {
        const dataArray = entry.isBaseLineYear
          ? [this._translateService.instant("kpi.baselineYear"), this._translateService.instant("kpi.baselineYear")] //baseline
          : this.dataEntryDetails().textmapId
            ? ["", ""] //textmapid
            : [entry.lowerLimit, entry.highLimit]; //default;

        innerData.push(...dataArray);
      } else {
        const dataArray = entry.isBaseLineYear
          ? [this._translateService.instant("kpi.baselineYear")] //baseline
          : this.dataEntryDetails().textmapId
            ? [""] //textmapid
            : [entry.target]; //default;
        innerData.push(...dataArray);
      }

      //CALC - ACTUALS
      const isIgnored = dataEntry.some((e) => e.ignored);
      innerData.push(
        isIgnored
          ? "N/A"
          : this._dataEntryService.calcFormula(
              dataEntry,
              this.dataEntryDetails().metricDetails,
              this.dataEntryDetails().formula,
              true
            )
      );

      //AUDITED VALUES
      if (entry?.month === 12) {
        const auditEntityByPeriod = this.dataEntryDetails().auditEntries?.find((e) => e.year === entry.year);
        if (auditEntityByPeriod) {
          //Annual Actual Column
          innerData.push(auditEntityByPeriod.annualActualIgnored ? "N/A" : auditEntityByPeriod.annualActual);
          //Audited Annual Actual Column
          const ignored = auditEntityByPeriod.wfProcessCtlId
            ? auditEntityByPeriod.proposedAuditIgnored
            : auditEntityByPeriod.auditedActualIgnored;
          innerData.push(
            ignored
              ? "N/A"
              : auditEntityByPeriod.wfProcessCtlId
                ? auditEntityByPeriod.proposedAuditActual
                : auditEntityByPeriod.auditedActual
          );
          //Audited Annual Actual Column
          innerData.push(
            auditEntityByPeriod.wfProcessCtlId ? auditEntityByPeriod.proposedComment : auditEntityByPeriod.comment
          );
        }
      }
    });
    data.push(innerData);

    //Entities
    this.dataEntryDetails().entityDataEntries?.forEach((entity) => {
      innerData = [];

      if (!entity.dataEntries) return;

      const entityEntriesGroupedByPeriod = AdaaHelper.groupBy(
        entity.dataEntries!,
        (e: { periodId: number }) => e.periodId
      ) as Map<number, DataEntry[]>;

      entityEntriesGroupedByPeriod.forEach((dataEntry, _key) => {
        const entry = dataEntry[0];
        if (!entry) return;

        //TARGETS
        if (this.isBounded()) {
          const dataArray = entry.isBaseLineYear
            ? [this._translateService.instant("kpi.baselineYear"), this._translateService.instant("kpi.baselineYear")] //baseline
            : this.dataEntryDetails().textmapId
              ? ["", ""] //textmapid
              : [entry.lowerLimit, entry.highLimit]; //default;

          innerData.push(...dataArray);
        } else {
          const dataArray = entry.isBaseLineYear
            ? [this._translateService.instant("kpi.baselineYear")] //baseline
            : this.dataEntryDetails().textmapId
              ? [""] //textmapid
              : [entry.target]; //default;
          innerData.push(...dataArray);
        }

        //CALC - ACTUALS
        const isIgnored = dataEntry.some((e) => e.ignored);
        innerData.push(
          isIgnored
            ? "N/A"
            : this._dataEntryService.calcFormula(
                dataEntry,
                this.dataEntryDetails().metricDetails,
                this.dataEntryDetails().formula,
                true
              )
        );

        //AUDITED VALUES
        if (entry?.month === 12) {
          const auditEntity = this.dataEntryDetails().ekpiAuditEntries?.find((e) => e.entityId === entity.entityId);
          if (auditEntity) {
            const auditEntityByPeriod = auditEntity.audits?.find((e) => e.year === entry.year);
            if (auditEntityByPeriod) {
              //Annual Actual Column
              innerData.push(auditEntityByPeriod.annualActualIgnored ? "N/A" : auditEntityByPeriod.annualActual);
              //Audited Annual Actual Column
              const ignored = auditEntityByPeriod.wfProcessCtlId
                ? auditEntityByPeriod.proposedAuditIgnored
                : auditEntityByPeriod.auditedActualIgnored;
              innerData.push(
                ignored
                  ? "N/A"
                  : auditEntityByPeriod.wfProcessCtlId
                    ? auditEntityByPeriod.proposedAuditActual
                    : auditEntityByPeriod.auditedActual
              );
              //Audited Annual Actual Column
              innerData.push(
                auditEntityByPeriod.wfProcessCtlId ? auditEntityByPeriod.proposedComment : auditEntityByPeriod.comment
              );
            }
          }
        }
      });

      data.push(innerData);
    });

    return data;
  }

  private _handleCellValueChanged = (changes: CellChange[] | null) => {
    if (!changes || !this.hotInstance || this.hotInstance.isDestroyed) return;

    changes.forEach((change) => {
      let [row, col, prev, next] = change;
      row = +row;
      col = +col;

      if (!AdaaHelper.isDefinedAndNotEmpty(prev)) prev = undefined;
      if (!AdaaHelper.isDefinedAndNotEmpty(next)) next = undefined;

      //Avoid saving changes on empty cells
      if (!AdaaHelper.isDefined(prev) && !AdaaHelper.isDefined(next)) return;

      const index = this._columnsIndices.find((e) => e.col === col);

      if (!index) return;

      let entityId = 0;
      if (row === 0) entityId = Constants.CONSTANT_PMO_ID;
      else {
        const entity = this.dataEntryDetails().entityDataEntries![row - 1];
        entityId = entity?.entityId ? entity.entityId : 0;
      }
      if (!entityId) return;

      if (!index.isComment) this._handleAuditAnnualActual(row, col, index.year, entityId, next);
      else this._handleComment(row, index.year, entityId, next);
    });

    this.hotInstance?.render();
  };

  private _handleAuditAnnualActual(
    row: number,
    col: number,
    year: number,
    entityId: number,
    auditedActual: string | undefined
  ) {
    let audit: DataEntryAuditEntry | undefined = undefined;
    if (entityId === Constants.CONSTANT_PMO_ID)
      audit = this.dataEntryDetails().auditEntries?.find((e) => e.year === year);
    else {
      const entityAudit = this.dataEntryDetails().ekpiAuditEntries?.find((e) => e.entityId === entityId);
      audit = entityAudit?.audits?.find((e) => e.year === year);
    }
    if (!audit) return;

    const isNA = typeof auditedActual === "string" && auditedActual.toUpperCase() === "N/A";

    let invalidCell = false;
    if (isNA || !auditedActual) {
      this.hotInstance.removeCellMeta(row, col, "valid");
      invalidCell = false;
    } else if (isNaN(+auditedActual)) {
      this.hotInstance.setCellMeta(row, col, "valid", false);
      invalidCell = true;
    } else {
      this.hotInstance.removeCellMeta(row, col, "valid");
      invalidCell = false;
    }
    this.hotInstance.render();

    this._handleAuditArrayChange(audit, auditedActual, isNA, null, invalidCell, row === 0);
  }

  private _handleComment(row: number, year: number, entityId: number, comment: string | undefined) {
    let audit: DataEntryAuditEntry | undefined = undefined;
    if (entityId === Constants.CONSTANT_PMO_ID)
      audit = this.dataEntryDetails().auditEntries?.find((e) => e.year === year);
    else {
      const entityAudit = this.dataEntryDetails().ekpiAuditEntries?.find((e) => e.entityId === entityId);
      audit = entityAudit?.audits?.find((e) => e.year === year);
    }
    if (!audit) return;

    this._handleAuditArrayChange(audit, null, null, comment, null, row === 0);
  }

  //If auditedActual/ignored/comment is null ignore updating the value
  private _handleAuditArrayChange(
    audit: DataEntryAuditEntry,
    auditedActual: string | undefined | null,
    ignored: boolean | null,
    comment: string | undefined | null,
    invalidCell: boolean | null,
    isGov: boolean
  ) {
    const value = this.auditValuesList.find((e) => e.year === audit.year && e.entityId === audit.entityId);

    if (value) {
      if (auditedActual !== null) value.auditedActual = auditedActual == null || ignored ? undefined : +auditedActual;
      if (ignored !== null) value.ignored = ignored;
      if (comment !== null) value.comment = comment;
      if (invalidCell !== null) value.invalidCell = invalidCell;

      //If the changes are identical the remove it from the array
      if (
        value.ignored == audit.annualActualIgnored &&
        value.auditedActual == audit.auditedActual &&
        value.comment == audit.comment
      ) {
        this.auditValuesList = this.auditValuesList.filter(
          (e) => e.year !== value.year && e.entityId !== value.entityId
        );
      }
    } else {
      this.auditValuesList.push({
        id: audit.auditId ?? null,
        year: audit.year ?? 0,
        itemId: isGov ? this.kpiId() : (audit.kpiId ?? 0),
        itemType: this.kpiType(),
        entityId: audit.entityId ?? 0,
        updateTS: Date.now(),
        auditedActual: this._getAuditedActualValue(audit, ignored, auditedActual),
        comment: comment === null ? audit.comment : comment ? comment : undefined,
        ignored: ignored ?? false,
        invalidCell: invalidCell === null ? false : invalidCell,
        planId: audit.planId,
        changed: true,
      });
    }

    this.valueChanged.emit(this.auditValuesList);
  }

  private _getAuditedActualValue(
    audit: DataEntryAuditEntry,
    ignored: boolean | null,
    auditedActual: string | undefined | null
  ) {
    if (ignored) return undefined;

    return auditedActual === null ? audit.auditedActual : auditedActual ? +auditedActual : undefined;
  }
}
