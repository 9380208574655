import { Component, inject, Input, signal } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { FormInputComponent } from "../../form";

@Component({
  selector: "adaa-changes-justification",
  standalone: true,
  imports: [FormInputComponent, TranslateModule],
  templateUrl: "./changes-justification.component.html",
  styleUrl: "./changes-justification.component.scss",
})
export class ChangesJustificationComponent {
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  modal = inject(NgbActiveModal);

  @Input() priorityChanged: boolean;
  @Input() assignedChanged: boolean;
  @Input() expectedCompletionDateChanged: boolean;
  @Input() currentStatusChanged: boolean;

  priorityChangeReason = signal<string>("");
  assignChangeReason = signal<string>("");
  expectedCompletionDateChangeReason = signal<string>("");
  resolvedChangeReason = signal<string>("");

  public submit() {
    if (
      (this.priorityChanged && !AdaaHelper.isDefinedAndNotEmpty(this.priorityChangeReason())) ||
      (this.assignedChanged && !AdaaHelper.isDefinedAndNotEmpty(this.assignChangeReason())) ||
      (this.expectedCompletionDateChanged &&
        !AdaaHelper.isDefinedAndNotEmpty(this.expectedCompletionDateChangeReason())) ||
      (this.currentStatusChanged && !AdaaHelper.isDefinedAndNotEmpty(this.resolvedChangeReason()))
    ) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }

    this.modal.close({
      priorityChangeReason: this.priorityChangeReason(),
      assignChangeReason: this.assignChangeReason(),
      expectedCompletionDateChangeReason: this.expectedCompletionDateChangeReason(),
      resolvedChangeReason: this.resolvedChangeReason(),
    });
  }
}
