@if (!showDIffOnly() || formulaIsDiff() || isNew()) {
    <section class="row d-flex align-content-center">
        <div
            class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6"
            [class.col-6]="showOnlyNew()"
            [class.col-4]="!showOnlyNew()"
        >
            {{ label() | translate }}
        </div>

        <!-- new-version -->
        <div
            class="p-2 border border-white tbl-col d-flex align-items-start align-content-start flex-wrap"
            [class.rounded-end-1]="showOnlyNew()"
            [class.col-6]="showOnlyNew()"
            [class.col-4]="!showOnlyNew()"
            [class.has-diff]="formulaIsDiff() || isNew()"
        >
            <div
                class="d-block w-100 mb-2 px-1 fw-bold"
                [class.text-end]="isFormula(newData()!.formula) && languageService.direction() === 'rtl'"
                [dir]="isFormula(newData()!.formula) ? 'ltr' : languageService.direction()"
            >
                {{ getFormula(newData()!.formula) | translate }}
            </div>

            @if (isFormula(newData()!.formula)) {
                @let metrics = newMetrics();
                <div class="d-block metric-details px-3">
                    <div class="row d-flex w-100 flex-nowrap">
                        @for (detail of metrics; track detail.id) {
                            @let checkDiff = metricIsDiff(detail.id, "old");
                            <div
                                class="col flex-shrink-0"
                                [class.has-diff]="
                                    isNew() ||
                                    checkDiff === 'hasDiff' ||
                                    (detail | translateToken: 'name' : true) !== (checkDiff | translateToken: 'name' : true)
                                "
                            >
                                @if (detail.metricType) {
                                    <i class="fa fa-pie-chart icon" (mouseover)="getInfo(detail.metricType)" [title]="title()"></i>
                                }
                                {{ detail | translateToken: "name" : true }}
                            </div>
                        }
                    </div>

                    <div class="row d-flex w-100 flex-nowrap">
                        @for (detail of metrics; track detail.id) {
                            @let checkDiff = metricIsDiff(detail.id, "old");
                            <div
                                class="col flex-shrink-0"
                                [class.has-diff]="
                                    isNew() ||
                                    checkDiff === 'hasDiff' ||
                                    (detail | translateToken: 'dsc' : true) !== (checkDiff | translateToken: 'dsc' : true)
                                "
                            >
                                {{ detail | translateToken: "dsc" : true }}
                            </div>
                        }
                    </div>

                    @let $isAboveAnnual = isAboveAnnual(newData()!.frequency);

                    @if (!$isAboveAnnual) {
                        <div class="row d-flex w-100 flex-nowrap">
                            @for (detail of metrics; track detail.id) {
                                @let checkDiff = metricIsDiff(detail.id, "old");
                                <div
                                    class="col flex-shrink-0"
                                    [class.has-diff]="isNew() || checkDiff === 'hasDiff' || detail.ytpCalc !== checkDiff.ytpCalc"
                                >
                                    {{ getYtp(detail.ytpCalc) }}
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>

        @if (!showOnlyNew()) {
            <div
                class="col-4 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-start align-content-start flex-wrap"
                [class.has-diff]="formulaIsDiff()"
            >
                <div
                    class="d-block w-100 mb-2 px-1 fw-bold"
                    [class.text-end]="isFormula(oldData()!.formula) && languageService.direction() === 'rtl'"
                    [dir]="isFormula(oldData()!.formula) ? 'ltr' : languageService.direction()"
                >
                    {{ getFormula(oldData()!.formula) | translate }}
                </div>

                @if (isFormula(oldData()!.formula)) {
                    @let metrics = oldMetrics();
                    <div class="d-block metric-details px-3">
                        <div class="row d-flex w-100 flex-nowrap">
                            @for (detail of metrics; track detail.id) {
                                @let checkDiff = metricIsDiff(detail.id, "new");
                                <div
                                    class="col flex-shrink-0"
                                    [class.has-diff]="
                                        checkDiff === 'hasDiff' ||
                                        (detail | translateToken: 'name' : true) !== (checkDiff | translateToken: 'name' : true)
                                    "
                                >
                                    @if (detail.metricType) {
                                        <i class="fa fa-pie-chart icon" (mouseover)="getInfo(detail.metricType)" [title]="title()"></i>
                                    }
                                    {{ detail | translateToken: "name" : true }}
                                </div>
                            }
                        </div>

                        <div class="row d-flex w-100 flex-nowrap">
                            @for (detail of metrics; track detail.id) {
                                @let checkDiff = metricIsDiff(detail.id, "new");
                                <div
                                    class="col flex-shrink-0"
                                    [class.has-diff]="
                                        checkDiff === 'hasDiff' ||
                                        (detail | translateToken: 'dsc' : true) !== (checkDiff | translateToken: 'dsc' : true)
                                    "
                                >
                                    {{ detail | translateToken: "dsc" : true }}
                                </div>
                            }
                        </div>

                        @let $isAboveAnnual = isAboveAnnual(oldData()!.frequency);

                        @if (!$isAboveAnnual) {
                            <div class="row d-flex w-100 flex-nowrap">
                                @for (detail of metrics; track detail.id) {
                                    @let checkDiff = metricIsDiff(detail.id, "new");
                                    <div
                                        class="col flex-shrink-0"
                                        [class.has-diff]="isNew() || checkDiff === 'hasDiff' || detail.ytpCalc !== checkDiff.ytpCalc"
                                    >
                                        {{ getYtp(detail.ytpCalc) }}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        }
    </section>
}
