import { Component, computed, inject, input, type TemplateRef, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, type NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { Language } from "../../../constants/enums";
import { TableActionEvent } from "../../../models";
import { AppService } from "../../../services";
import { DataTableComponent } from "../../table/data-table/data-table.component";

@Component({
  selector: "adaa-kpi-scores-by-aggregation-type",
  standalone: true,
  imports: [TranslateModule, DataTableComponent],
  templateUrl: "./kpi-scores-by-aggregation-type.component.html",
  styleUrl: "./kpi-scores-by-aggregation-type.component.scss",
})
export class KpiScoresByAggregationTypeComponent {
  private readonly _translateService = inject(TranslateService);
  private readonly _modalService = inject(NgbModal);
  private readonly _appService = inject(AppService);
  private readonly _router = inject(Router);

  modalType = input.required<"pmo" | "kpi">();
  aggregationType = input.required<number>();
  periodId = input.required<number | undefined>();
  year = input.required<number | undefined>();
  periodType = input.required<"year" | "quarter">();

  modal = viewChild.required<TemplateRef<unknown>>("content");

  paramString = computed(() => {
    const periodId =
      this.periodId() ??
      +AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, { type: "prop", property: "id" });

    return this.periodType() === "year"
      ? `aggregationType=${this.aggregationType()}&year=${this.year()}`
      : `aggregationType=${this.aggregationType()}&periodId=${periodId}`;
  });

  readonly pmoPerfAggConfList = Constants.MAIN_TABLE_LIST_CONF_KEY.PMO_HOMEPAGE_PERF_AGG_CONF_LIST;
  readonly kpiPerfAggConfList = Constants.MAIN_TABLE_LIST_CONF_KEY.KPI_HOMEPAGE_PERF_AGG_CONF_LIST;

  modalRef: NgbModalRef;

  public open() {
    this.modalRef = this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      ariaLabelledBy: this._translateService.instant("kpi.kpis_list"),
      size: "xl",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });
  }

  public actionClicked(event: TableActionEvent) {
    const url = Constants.ITEM_TYPE_ROUTES.find((e) => e.itemTypeId === event.data.kpiType);
    if (url) {
      this.modalRef.close();
      this._router.navigateByUrl(`${url.route}${event.data.id}`);
    }
  }
}
