import { inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import type { ChartConfiguration } from "chart.js";
import { filter, map } from "rxjs";

import type { GraphByAggregationTypeModelType } from "../../../models";
import { GraphApiService } from "../../../services";

const GRAPH_TYPE = "BARS";
const GRAPH_FREQUENCY = "CYCLE";

@Injectable({
  providedIn: "root",
})
export class DataProviderService {
  private readonly _translateService = inject(TranslateService);
  private readonly _graphApiService = inject(GraphApiService);

  public gatherData(aggregationType: number, entityId: number, periodId: number) {
    return this._graphApiService
      .getGraphByByAggregationType({
        itemTypeId: aggregationType,
        frequency: GRAPH_FREQUENCY,
        graph: GRAPH_TYPE,
        entityId,
        periodId,
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData),
        map((data) => {
          const importantMonths = [3, 6, 9, 12];
          return data.filter((o) => importantMonths.indexOf(o.period.month) !== -1);
        }),
        map(this._generateLineChartData.bind(this))
      );
  }

  private _generateLineChartData(data: GraphByAggregationTypeModelType[]): ChartConfiguration["data"] {
    const labels = data.map(({ period }) => `${period.year}/${period.month}`);
    const performanceData = data.map(({ performance }) => performance);
    const annualPerformanceData = data.map(({ annualPerformance }) => annualPerformance);

    return {
      labels,
      datasets: [
        {
          data: annualPerformanceData,
          label: this._translateService.instant("landingPage.entity_page.annual_performance"),
          backgroundColor: "rgba(255, 165, 0, 0.25)",
          borderColor: "rgba(255, 165, 0, 1)",
          pointBackgroundColor: "rgba(255, 165, 0, 1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(255, 165, 0, 0.75)",
          fill: false,
        },
        {
          data: performanceData,
          label: this._translateService.instant("common.tables.performance"),
          backgroundColor: "rgba(41, 169, 224, 0.25)",
          borderColor: "rgba(41, 169, 224, 0.75)",
          pointBackgroundColor: "rgba(41, 169, 224, 1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(41, 169, 224, 0.75)",
          fill: true,
        },
      ] as ChartConfiguration["data"]["datasets"],
    };
  }
}
