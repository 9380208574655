import { Component, computed, inject, input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { compile } from "mathjs";

import { AdaaHelper } from "../../../../../../../../core/utils";
import { Constants } from "../../../../../../../constants/constants";
import { Language, PageMode } from "../../../../../../../constants/enums";
import { CardEntryInfoType } from "../../../../../../../models";
import { DataentryApiService, LanguageService } from "../../../../../../../services";
import { EditDataEntryModalComponent } from "../../../../../..";
import { commonFormatter } from "../../../configs";
import type { BoundedFormulaMetricType, FormulaMetricType } from "../types";

@Component({
  selector: "adaa-targets-formula-view",
  standalone: true,
  styleUrl: "../styles.scss",
  imports: [TranslateModule],
  templateUrl: "./targets-formula-view.component.html",
})
export class TargetsFormulaViewComponent {
  private _modalService = inject(NgbModal);
  private _translateService = inject(TranslateService);
  private _languageService = inject(LanguageService);
  private _dataentryApiService = inject(DataentryApiService);

  formula = input<string>();
  isBounded = input<boolean>();
  showOnlyNew = input<boolean>();
  baselineYear = input<number>();
  isBaselineKpi = input<boolean>();
  newDataentryRequests = input<FormulaMetricType[]>([]);
  oldDataentryRequests = input<FormulaMetricType[]>([]);

  metricList = computed(() => {
    let metricList: FormulaMetricType["metrics"] = [];
    let maxSize = -1;
    for (const metric of this.newDataentryRequests()) {
      if (!metric.metrics || metric.metrics.length < 1) continue;
      if (metric.metrics.length > maxSize) {
        maxSize = metric.metrics.length;
        metricList = metric.metrics;
      }
    }

    return metricList.reduce<FormulaMetricType["metrics"]>((acc, curr) => {
      const metric = acc?.find(({ kpiMetricId }) => kpiMetricId === curr.kpiMetricId);
      if (!metric) acc?.push(curr);
      return acc;
    }, []);
  });

  frequency = computed(() => {
    for (const metric of this.newDataentryRequests()) {
      if (!metric.metrics || metric.metrics.length < 1) continue;
      const $m = metric.metrics[0];
      if ($m.frequency) {
        return $m.frequency;
      }
    }
    return 0;
  });

  public getBoundedValue(type: "lower" | "high", metric: FormulaMetricType) {
    let target: number;
    switch (type) {
      case "lower": {
        target = (metric as BoundedFormulaMetricType).lowerLimit;
        break;
      }

      case "high": {
        target = (metric as BoundedFormulaMetricType).highLimit;
        break;
      }
    }

    return this._getBaselineValue(metric.year, target);
  }

  public getCalculation(requestIndex: number) {
    const requests = this.newDataentryRequests();
    const metrics = this.metricList() ?? [];
    if (!AdaaHelper.isDefined(requests)) return;

    const request = requests[requestIndex];

    const ignoredValues = (request.metrics ?? []).filter((m) => m?.ignored).length;
    if (ignoredValues > 0) return "N/A";

    const nullValues = (request.metrics ?? []).filter((m) => !AdaaHelper.isDefined(m?.actual)).length;
    if (nullValues > 0) return;

    const scope = request.metrics?.reduce((acc, curr) => {
      if (curr.metricNameAE in acc) return acc;
      return {
        ...acc,
        [curr.metricNameAE]: curr.actual,
      };
    }, {});

    if (Object.keys(scope!).length !== metrics.length) return;

    try {
      const formula = this.formula();
      if (!formula) return "";

      const expr = compile(formula);
      return AdaaHelper.roundValue(expr.evaluate(scope), 2, "");
    } catch (_e) {
      return "";
    }
  }

  public getCalculationTarget(requestIndex: number) {
    const requests = this.newDataentryRequests();
    const metrics = this.metricList() ?? [];
    if (!AdaaHelper.isDefined(requests)) return;

    const request = requests[requestIndex];

    const nullValues = (request.metrics ?? []).filter((m) => !AdaaHelper.isDefined(m?.target)).length;
    if (nullValues > 0) return;

    const scope = request.metrics?.reduce((acc, curr) => {
      if (curr.metricNameAE in acc) return acc;
      return {
        ...acc,
        [curr.metricNameAE]: curr.target,
      };
    }, {});

    if (Object.keys(scope!).length !== metrics.length) return;

    try {
      const formula = this.formula();
      if (!formula) return "";

      const expr = compile(formula);
      return AdaaHelper.roundValue(expr.evaluate(scope), 2, "");
    } catch (_e) {
      return "";
    }
  }

  public showNAFlag(requestIndex: number, metricIndex: number, version: "old" | "new") {
    const requests = version === "old" ? this.oldDataentryRequests() : this.newDataentryRequests();
    if (requests == null) return false;

    const request = requests[requestIndex];

    const metric = (request.metrics ?? []).find((_, index: number) => metricIndex == index);
    if (!metric) return false;

    return metric.ignored;
  }

  public getPeriodLabel(metric: FormulaMetricType) {
    if (this.frequency() === Constants.FREQUENCY_MONTHLY) {
      return commonFormatter.monthly(metric.date) + " " + metric.year;
    }

    if (this.frequency() === Constants.FREQUENCY_QUARTERLY) {
      switch (this._languageService.current()) {
        case Language.English:
          return `${this._translateService.instant(`targets.periods.quarters.${metric.quarter}`)} ${metric.year}`;
        default:
          return `${this._translateService.instant("targets.quarter")} ${metric.quarter} ${metric.year}`;
      }
    }

    if (this.frequency() === Constants.FREQUENCY_SEMIANNUAL) {
      switch (this._languageService.current()) {
        case Language.Arabic:
          return `${this._translateService.instant(`targets.periods.semestral.${metric.semester}`)} ${metric.year}`;
        default:
          return `${this._translateService.instant("targets.semiannual")} ${metric.semester} ${metric.year}`;
      }
    }

    return metric.year;
  }

  public viewDifferences(metric: FormulaMetricType) {
    this._dataentryApiService.getAnalysisDifferencesInfo(metric.itemId, metric.periodId, metric.itemType).subscribe({
      next: (response) => this._openEditDataEntryModal(metric.year, response.responseData),
    });
  }

  private _openEditDataEntryModal(yearLabel: number, dataEntry: CardEntryInfoType): void {
    const modal = this._modalService.open(EditDataEntryModalComponent, {
      centered: true,
      size: "lg",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = `${this._translateService.instant("data_entry.period")}` + ": " + `${yearLabel}`;
    modal.componentInstance.dataEntry = dataEntry;
    modal.componentInstance.pageMode = PageMode.view;
    modal.componentInstance.showSubmit = false;
  }

  private _getBaselineValue(year: number, value: number) {
    if (year === this.baselineYear() && this.isBaselineKpi()) {
      return this._translateService.instant("kpi.baselineYear");
    }
    return value;
  }
}
