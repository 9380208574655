/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faAngleDown, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import { AdaaHelper } from "../../../../core/utils";
import { Language } from "../../../constants/enums";
import { ValueText } from "../../../models";

@Component({
  selector: "adaa-form-dropdown-tree-node",
  standalone: true,
  imports: [FontAwesomeModule],
  template: `
    <li>
      @if (option.children && option.children.length > 0) {
        <button (click)="isExpanded = !isExpanded">
          <fa-icon [icon]="isExpanded ? closeArrowIcon : expandArrowIcon" />
        </button>
      }
      <span [class.selected]="checkIfSelected(option.value)" (click)="selectOption(option.value)">
        {{ option.text }}</span
      >
      @if (isExpanded && option.children && option.children.length > 0) {
        <ul>
          @for (item of option.children; track $index) {
            <adaa-form-dropdown-tree-node
              [isMultiple]="isMultiple"
              [option]="item"
              [selectedValue]="selectedValue"
              (valueSelected)="valueChanged($event)"
            ></adaa-form-dropdown-tree-node>
          }
        </ul>
      }
    </li>
  `,
})
export class FormDropdownTreeNodeComponent {
  @Input() isMultiple: boolean = false;
  @Input() option: ValueText;
  @Input() selectedValue: any[] = [];
  @Output() valueSelected = new EventEmitter<any[]>();

  readonly expandArrowIcon = AdaaHelper.getCurrentLang() === Language.English ? faAngleRight : faAngleLeft;
  readonly closeArrowIcon = faAngleDown;
  isExpanded: boolean = false;

  public checkIfSelected(value: any) {
    return this.selectedValue ? this.selectedValue.findIndex((e) => e === value) > -1 : false;
  }

  public selectOption(value: any) {
    if (this.isMultiple) {
      const index = this.selectedValue.findIndex((e) => e === value);
      if (index > -1) {
        this.selectedValue.splice(index, 1);
      } else {
        this.selectedValue.push(value);
      }
    } else {
      this.selectedValue = [value];
    }
    this.valueSelected.emit(this.selectedValue);
  }

  public valueChanged(event: any[]): void {
    this.selectedValue = event;
    this.valueSelected.emit(this.selectedValue);
  }
}
