<div class="modal-header">
    <h4 class="modal-title">{{ "national_projects.project_team_members.add_member" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body" [formGroup]="teamMemberForm">
    @if (isSystemUser) {
        <div class="row">
            <div class="col-sm-6 col-xs-12">
                <adaa-form-dropdown
                    controlName="entityId"
                    formControlName="entityId"
                    [invalid]="submitted && teamMemberForm.get('entityId')?.invalid!"
                    [label]="'initiatives.entity' | translate"
                    [setValidator]="formValidation"
                    [options]="entities"
                    (inputChanges)="entityChanged($event)"
                />
            </div>
            <div class="col-sm-6 col-xs-12">
                <adaa-form-dropdown
                    controlName="userId"
                    formControlName="userId"
                    [invalid]="submitted && teamMemberForm.get('userId')?.invalid!"
                    [label]="'breadcrumbs.user.user' | translate"
                    [setValidator]="formValidation"
                    [options]="owners()"
                    (inputChanges)="userChanged($event)"
                />
            </div>
        </div>
    } @else {
        <div class="row mb-2">
            <div class="col-12">
                <adaa-form-radio
                    controlName="entitySource"
                    [label]="'common.select_entity_source' | translate"
                    [options]="entitySourceOptions()"
                    [styles]="'d-flex justify-content-between'"
                    [setDefaultValue]="nonSystemTeamMemberType.ADAA_ENTITY"
                    (inputChanges)="entitySourceChanged($event)"
                />
            </div>
        </div>

        @if (teamMemberForm.get("entitySource")?.value === nonSystemTeamMemberType.ADAA_ENTITY) {
            <div class="row">
                <div class="col-12">
                    <adaa-form-dropdown
                        controlName="entityId"
                        formControlName="entityId"
                        [invalid]="submitted && teamMemberForm.get('entityId')?.invalid!"
                        [label]="'initiatives.entity' | translate"
                        [setValidator]="formValidation"
                        [options]="entities"
                        (inputChanges)="entityChanged($event)"
                    />
                </div>
            </div>
        } @else if (teamMemberForm.get("entitySource")?.value === nonSystemTeamMemberType.OTHER_ENTITY) {
            <div class="row">
                <div class="col-12">
                    <adaa-form-dropdown
                        controlName="otherEntityId"
                        formControlName="otherEntityId"
                        [invalid]="submitted && teamMemberForm.get('otherEntityId')?.invalid!"
                        [label]="'other_entities.singular' | translate"
                        [setValidator]="formValidation"
                        [options]="otherEntities"
                        (inputChanges)="otherEntityChanged($event)"
                    />
                </div>
            </div>
        } @else if (teamMemberForm.get("entitySource")?.value === nonSystemTeamMemberType.PARENT_PROJECT) {
            <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <adaa-form-dropdown
                        controlName="tpTeamMember"
                        formControlName="tpTeamMember"
                        [invalid]="submitted && teamMemberForm.get('tpTeamMember')?.invalid!"
                        [label]="'national_projects.national_projects_milestone.project_team_members' | translate"
                        [setValidator]="formValidation"
                        [options]="projectTeamMembers()"
                        (inputChanges)="tpTeamMemberChanged($event)"
                    />
                </div>
                <div class="col-sm-6 col-xs-12">
                    @if (teamMemberForm.get("otherEntityId")?.value) {
                        <adaa-form-input
                            [controlName]="adaaHelper.getFieldLanguage('otherEntityName')"
                            [formControlName]="adaaHelper.getFieldLanguage('otherEntityName')"
                            [label]="'other_entities.singular' | translate"
                            [setValidator]="formValidation"
                        />
                    } @else {
                        <adaa-form-input
                            [controlName]="adaaHelper.getFieldLanguage('entityName')"
                            [formControlName]="adaaHelper.getFieldLanguage('entityName')"
                            [label]="'initiatives.entity' | translate"
                            [setValidator]="formValidation"
                        />
                    }
                </div>
            </div>
        }
    }
    <div class="row">
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="nameAE"
                formControlName="nameAE"
                [invalid]="submitted && teamMemberForm.get('nameAE')?.invalid!"
                [label]="'common.form.label.nameAE' | translate"
                [setValidator]="formValidation"
            />
        </div>
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="nameEN"
                formControlName="nameEN"
                [invalid]="submitted && teamMemberForm.get('nameEN')?.invalid!"
                [label]="'common.form.label.nameEN' | translate"
                [setValidator]="formValidation"
            />
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="jobTitleAE"
                formControlName="jobTitleAE"
                [invalid]="submitted && teamMemberForm.get('jobTitleAE')?.invalid!"
                [label]="'common.form.label.jobTitleAE' | translate"
                [setValidator]="formValidation"
            />
        </div>
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="jobTitleEN"
                formControlName="jobTitleEN"
                [invalid]="submitted && teamMemberForm.get('jobTitleEN')?.invalid!"
                [label]="'common.form.label.jobTitleEN' | translate"
                [setValidator]="formValidation"
            />
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="email"
                formControlName="email"
                inputType="email"
                [invalid]="submitted && teamMemberForm.get('email')?.invalid!"
                [label]="'common.form.label.email' | translate"
                [setValidator]="formValidation"
            />
        </div>
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="mobilePhone"
                formControlName="mobilePhone"
                inputType="tel"
                [invalid]="submitted && teamMemberForm.get('mobilePhone')?.invalid!"
                [label]="'common.form.label.mobilePhone' | translate"
                [setValidator]="formValidation"
            />
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="memberRoleAE"
                formControlName="memberRoleAE"
                [invalid]="submitted && teamMemberForm.get('memberRoleAE')?.invalid!"
                [label]="'national_projects.project_team_members.member_role_ae' | translate"
                [setValidator]="formValidation"
            />
        </div>
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="memberRoleEN"
                formControlName="memberRoleEN"
                [invalid]="submitted && teamMemberForm.get('memberRoleEN')?.invalid!"
                [label]="'national_projects.project_team_members.member_role_en' | translate"
                [setValidator]="formValidation"
            />
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ "common.form.button.close" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        {{ "common.form.button.save" | translate }}
    </button>
</div>
