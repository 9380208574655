import type { AdaaBooleanType } from "../../../../../../../../adaa-types";
import { AdaaHelper, useNewDS } from "../../../../../../../core/utils";
import { Constants } from "../../../../../../constants/constants";
import { commonFormatter, SharedFields } from "../../configs/common";
import type { ScreenSectionConfigType } from "../../wf-difference.types";
import { isActivity, isInitiative, isService, isTpAggreementReadyToPublish } from "../../wf-difference.utils";

export const descriptionField = SharedFields.description.map((config) => ({
  ...config,
  visibleIf: (data: { itemTypeId: number }) => !isTpAggreementReadyToPublish(data.itemTypeId),
}));

export const objectiveIdField: ScreenSectionConfigType = {
  ...SharedFields.objectiveIdField,
  format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "objectiveName"),
  visibleIf: (data: { itemTypeId: number }) => isInitiative(data.itemTypeId) || isService(data.itemTypeId),
};

export const readyToPublishField: ScreenSectionConfigType = {
  field: "readyPublish",
  view: "normal",
  label: "ready_to_publish",
  format: (value: { readyPublish: boolean }) => (value.readyPublish ? "common.form.label.yes" : "common.form.label.no"),
  visibleIf: (data: { itemTypeId: number }) => isTpAggreementReadyToPublish(data.itemTypeId),
};

export const khadhamatiField: ScreenSectionConfigType = {
  field: "khadamatiId",
  view: "normal",
  label: "services.khadamati_id",
  visibleIf: (data: { itemTypeId: number }) => isService(data.itemTypeId),
};

export const highPriorityField: ScreenSectionConfigType = {
  field: "priorityHigh",
  view: "normal",
  label: "initiatives.high_priority",
  visibleIf: (data: { itemTypeId: number; planId: number }) => {
    if (!useNewDS(data.planId ?? undefined) && isInitiative(data.itemTypeId)) return true;
    return isActivity(data.itemTypeId);
  },
};

export const linkedToNationalStrategiesField: ScreenSectionConfigType = {
  field: "linkedToNationalStrategies",
  view: "normal",
  label: "initiatives.linked_to_national_strategies",
  format: (value: { linkedToNationalStrategies: boolean }) =>
    value.linkedToNationalStrategies ? "common.form.label.yes" : "common.form.label.no",
  visibleIf: (data: { itemTypeId: number; planId: number }) =>
    useNewDS(data.planId ?? undefined) && isInitiative(data.itemTypeId),
};

export const highPriorityJustificationField: ScreenSectionConfigType = {
  field: "highPriorityJustification",
  view: "normal",
  label: "common.form.label.highPriorityJustification",
  format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "highPriorityJustification"),
  visibleIf: (data: { itemTypeId: number; planId: number; priorityHigh: AdaaBooleanType }) =>
    data.priorityHigh === "Y" && !useNewDS(data.planId ?? undefined) && isInitiative(data.itemTypeId),
  hasDiff: (
    newDataField: { highPriorityJustificationEN: boolean; highPriorityJustificationAE: boolean },
    oldDataField: { highPriorityJustificationEN: boolean; highPriorityJustificationAE: boolean }
  ) => {
    const language = AdaaHelper.getLocalStorage(Constants.localStorageKeys.language);
    if (language === "en")
      return newDataField?.highPriorityJustificationEN !== oldDataField?.highPriorityJustificationEN;
    return newDataField?.highPriorityJustificationAE !== oldDataField?.highPriorityJustificationEN;
  },
};

export const monitoredField: ScreenSectionConfigType = {
  field: "monitored",
  view: "normal",
  label: "initiatives.monitored",
  format: (value: { monitored: AdaaBooleanType }) => commonFormatter.matchYesNo(value.monitored),
  visibleIf: (data: { itemTypeId: number }) => isInitiative(data.itemTypeId),
};

export const initiativeManagerNameField: ScreenSectionConfigType = {
  field: "initiativeManager",
  view: "normal",
  label: "common.form.label.initiativeManagerName",
  format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "initiativeManagerName"),
  visibleIf: (data: { itemTypeId: number; monitored: AdaaBooleanType }) =>
    data.monitored === "Y" && isInitiative(data.itemTypeId),
  hasDiff: (newData: { initiativeManager: number }, oldData: { initiativeManager: number }) =>
    newData?.initiativeManager !== oldData?.initiativeManager,
};

export const progressManagerNameField: ScreenSectionConfigType = {
  field: "progressManager",
  view: "normal",
  label: "common.form.label.progressManagerName",
  format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "progressManagerName"),
  visibleIf: (data: { itemTypeId: number; monitored: AdaaBooleanType }) =>
    data.monitored === "Y" && isInitiative(data.itemTypeId),
  hasDiff: (newData: { progressManager: number }, oldData: { progressManager: number }) =>
    newData?.progressManager !== oldData?.progressManager,
};

export const initiativesBudgetEnabledField: ScreenSectionConfigType = {
  field: "budgetEnabled",
  view: "normal",
  label: "initiatives.budget_enabled",
  format: (value: { budgetEnabled: boolean }) =>
    value.budgetEnabled ? "common.form.label.yes" : "common.form.label.no",
  visibleIf: (data: { itemTypeId: number; planId: number }) =>
    useNewDS(data.planId ?? undefined) && isInitiative(data.itemTypeId),
};

export const activitiesBudgetEnabledField: ScreenSectionConfigType = {
  field: "initiativeBudgetEnabled",
  view: "normal",
  label: "initiatives.budget_enabled",
  format: (value: { initiativeBudgetEnabled: boolean }) =>
    value.initiativeBudgetEnabled ? "common.form.label.yes" : "common.form.label.no",
  visibleIf: (data: { itemTypeId: number; planId: number }) =>
    useNewDS(data.planId ?? undefined) && isActivity(data.itemTypeId),
};

export const budgetField: ScreenSectionConfigType = {
  field: "budget",
  view: "normal",
  label: "activities.budget",
  visibleIf: (data: {
    itemTypeId: number;
    planId: number;
    initiativeBudgetEnabled: boolean;
    budgetEnabled: boolean;
  }) => {
    if (useNewDS(data.planId) && isInitiative(data.itemTypeId) && data.budgetEnabled) return true;
    if (!useNewDS(data.planId) && isInitiative(data.itemTypeId)) return true;
    if (useNewDS(data.planId) && isActivity(data.itemTypeId) && data.initiativeBudgetEnabled) return true;
    if (!useNewDS(data.planId) && isActivity(data.itemTypeId)) return true;
    return isService(data.itemTypeId);
  },
};

export const expensesField: ScreenSectionConfigType = {
  field: "expenses",
  view: "normal",
  label: "activities.expenses",
  visibleIf: budgetField.visibleIf?.bind(undefined),
};

export const progressField: ScreenSectionConfigType = {
  field: "progress",
  view: "normal",
  label: "initiatives.current_progress",
  visibleIf: (data: { itemTypeId: number }) => isActivity(data.itemTypeId),
};

export const hyperiondRefCodeField: ScreenSectionConfigType = {
  field: "hyperiondRefCode",
  view: "normal",
  label: "activities.hyperiondRefCode",
  visibleIf: (data: { itemTypeId: number }) => isActivity(data.itemTypeId),
};

export const activityWorkloadsField: ScreenSectionConfigType = {
  view: "activity-workloads",
  field: "workloads",
  label: "initiatives.activity_workload_title",
  visibleIf: (data: { itemTypeId: number }) => isInitiative(data.itemTypeId),
};

export const linkedNationalStrategiesField: ScreenSectionConfigType = {
  view: "linked-national-strategies",
  field: "govDirections",
  label: "national_strategies.strategies",
  visibleIf: (data: { itemTypeId: number }) => isInitiative(data.itemTypeId),
};

export const successFactorsField: ScreenSectionConfigType = {
  field: "keySuccessFactorsAE",
  label: "objectives.key_success_factors",
  format: (value: Record<string, string>) => {
    const map = JSON.parse(value.keySuccessFactorsAE)
      .map((item: { nameAE: string }) => item.nameAE)
      .join("\n");
    return map;
  },
  view: "normal",
  showMultipleLines: true,
  hasDiff: ($n: string, $o: string) => {
    const newData = JSON.parse($n)
      .map((item: { nameAE: string }) => item.nameAE)
      .join("\n");
    const oldData = JSON.parse($o)
      .map((item: { nameAE: string }) => item.nameAE)
      .join("\n");

    return newData !== oldData;
  },
  visibleIf: (data: { keySuccessFactorsAE: string }) => AdaaHelper.isDefined(data.keySuccessFactorsAE),
};
