import { AdaaHelper } from "../../../../../../../core/utils";
import { SharedFields } from "../../configs";
import { AccordionSectionConfigType, ListSectionConfigType, ScreenSectionConfigType } from "../../wf-difference.types";
import { measurementScopeMainKpisConfig } from "./measurement-scope-main-kpis.config";

const sectorConfig: ScreenSectionConfigType = {
  field: "sectorId",
  label: "national_projects.national_sector.title",
  view: "normal",
  visibleIf: (data: { sectorId: number }) => AdaaHelper.isDefined(data?.sectorId),
  format: (data: unknown) => AdaaHelper.getItemValueByToken(data, "sectorName"),
};

const pillarConfig: ScreenSectionConfigType = {
  field: "pillarId",
  label: "ekpi.piller",
  view: "normal",
  visibleIf: (data: { pillarId: number }) => AdaaHelper.isDefined(data?.pillarId),
  format: (data: unknown) => AdaaHelper.getItemValueByToken(data, "pillarName"),
};

const layoutConfig: ScreenSectionConfigType = {
  field: "layoutId",
  label: "government_scores.scopes.layout_type",
  view: "normal",
  visibleIf: (data: { pillarId: number }) => AdaaHelper.isDefined(data?.pillarId),
  format: (data: unknown) => AdaaHelper.getItemValueByToken(data, "layoutName"),
};

const objectivesField: ListSectionConfigType = {
  field: "objectives",
  label: "breadcrumbs.objectives.objectives",
  view: "list",
  idKey: "id",
  labelledItem: (data: unknown) => AdaaHelper.getItemValueByToken(data, "objectiveTypeName"),
  derivedList: (data: { id?: number }[]) => data.filter(($i) => AdaaHelper.isDefined($i?.id)),
};

const govScoresScopeMainKpisField: AccordionSectionConfigType = {
  view: "accordion",
  field: "govScoresScopeMainKpis",
  title: ($o: unknown) => AdaaHelper.getItemValueByToken($o, "name"),
  idKey: "id",
  config: measurementScopeMainKpisConfig,
  label: "government_scores.scopes.main_kpis",
};

export const measurementScopeScreenConfig = [
  ...SharedFields.name,
  ...SharedFields.description,
  SharedFields.entityIdField,
  pillarConfig,
  sectorConfig,
  layoutConfig,
  objectivesField,
  govScoresScopeMainKpisField,
];
