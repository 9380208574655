@if (!showDIffOnly() || hasAnyDiff()) {
    @if (data() && data().length) {
        <div class="w-100 d-block my-4">
            <h4 class="mb-3">{{ "common.form.label.extended_fields" | translate }}</h4>
            <section class="row d-flex align-content-center">
                <div class="col-4 p-2 border border-white tbl-col bg-white text-primary fw-bold text-center fs-6">
                    {{ "communication.difference.key" | translate }}
                </div>

                <div
                    class="p-2 border border-white tbl-col bg-white text-primary fw-bold text-center fs-6"
                    [class.rounded-end-1]="showOnlyNew()"
                    [class.col-6]="showOnlyNew()"
                    [class.col-4]="!showOnlyNew()"
                >
                    {{ !showOnlyNew() ? ("communication.difference.new_value" | translate) : ("communication.difference.value" | translate) }}
                </div>

                @if (!showOnlyNew()) {
                    <div class="col-4 p-2 border border-white tbl-col bg-white text-primary fw-bold text-center fs-6">
                        {{ "communication.difference.old_value" | translate }}
                    </div>
                }
            </section>

            @for (item of data(); track $index) {
                <!-- section:name-ae -->
                @if (!showDIffOnly() || showOnlyNew() || hasDiffValue(item.new, item.old)) {
                    <section class="row d-flex align-content-center">
                        <div
                            class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6"
                            [class.col-6]="showOnlyNew()"
                            [class.col-4]="!showOnlyNew()"
                        >
                            {{ item.new | translateToken: "name" }}
                        </div>

                        <div
                            class="p-2 border border-white tbl-col d-flex align-items-center"
                            [class.rounded-end-1]="showOnlyNew()"
                            [class.col-6]="showOnlyNew()"
                            [class.col-4]="!showOnlyNew()"
                            [class.tbl-diff]="showOnlyNew() || hasDiffValue(item.new, item.old)"
                        >
                            {{ getValue(item.new) }}
                        </div>

                        @if (!showOnlyNew()) {
                            <div
                                class="col-4 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-center"
                                [class.tbl-diff]="hasDiffValue(item.new, item.old)"
                            >
                                {{ getValue(item.new) }}
                            </div>
                        }
                    </section>
                }
            }
        </div>
    }
}
