import { Component, computed, inject, input, OnInit, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import * as fileSaver from "file-saver";
import { forkJoin, map, tap } from "rxjs";

import { Constants } from "../../../../../../constants/constants";
import { AdaaBoolean } from "../../../../../../constants/enums";
import type {
  CycleModelType,
  EntityModelType,
  InitiativeModelType,
  OrgUnit,
  UserModelType,
} from "../../../../../../models";
import {
  CyclesApiService,
  EntitiesApiService,
  FilesApiService,
  OrgUnitApiService,
  TpApiService,
  UsersApiService,
} from "../../../../../../services";
import {
  AccordionDiffViewComponent,
  AttachmentDiffViewComponent,
  LinkedNationalStrategiesDiffViewComponent,
  ListDiffViewComponent,
  NormalDiffViewComponent,
} from "../../_lib";
import {
  AccordionSectionConfigType,
  CompositeSectionConfigType,
  ListSectionConfigType,
  ScreenSectionConfigType,
  ScreenType,
} from "../../wf-difference.types";
import { agmProjectMilestoneScreenConfig, agmProjectScreenConfig } from "./agm-screen.config";

@Component({
  selector: "adaa-agm-screen",
  imports: [
    TranslateModule,
    NormalDiffViewComponent,
    AttachmentDiffViewComponent,
    AccordionDiffViewComponent,
    ListDiffViewComponent,
    LinkedNationalStrategiesDiffViewComponent,
  ],
  templateUrl: "./agm-screen.component.html",
  styleUrl: "./agm-screen.component.scss",
})
export class AgmScreenComponent implements OnInit {
  private readonly _tpApiService = inject(TpApiService);
  private readonly _usersApiService = inject(UsersApiService);
  private readonly _entitiesApiService = inject(EntitiesApiService);
  private readonly _cyclesApiService = inject(CyclesApiService);
  private readonly _orgUnitApiService = inject(OrgUnitApiService);
  private readonly _filesApiService = inject(FilesApiService);

  showOnlyNew = input.required<boolean>();
  isNew = input<boolean>(false);
  itemType = input<number>();
  newObject = input.required<Record<string, never>>();
  oldObject = input.required<Record<string, never>>();
  viewOnlyDifferences = input<boolean>(false);

  isScreenReady = signal<boolean>(false);
  cycles = signal<CycleModelType[]>([]);
  entities = signal<EntityModelType[]>([]);
  strategicProjects = signal<InitiativeModelType[]>([]);
  owners = signal<UserModelType[]>([]);
  orgUnits = signal<OrgUnit[]>([]);

  newData = computed(() => {
    let object = this.newObject();

    const owners = this.owners();
    if (owners && owners.length > 1) {
      if ("owner" in object) {
        const item = owners.find(({ id }) => id === object.owner);
        if (item) {
          object = {
            ...object,
            ownerNameAE: item.nameAE as never,
            ownerNameEN: item.nameEN as never,
          };
        }
      }

      if ("projectCoordinator" in object) {
        const item = owners.find(({ id }) => id === object.projectCoordinator);
        if (item) {
          object = {
            ...object,
            projectCoordinatorNameAE: item.nameAE as never,
            projectCoordinatorNameEN: item.nameEN as never,
          };
        }
      }
    }

    const orgUnits = this.orgUnits();
    if (orgUnits && orgUnits.length > 1) {
      if ("orgUnitId" in object) {
        const item = orgUnits.find(({ id }) => id === object.orgUnitId);
        if (!item) return object;

        object = {
          ...object,
          orgUnitNameAE: item.nameAE as never,
          orgUnitNameEN: item.nameEN as never,
        };
      }
    }

    return object;
  });
  oldData = computed(() => {
    let object = this.oldObject();

    const owners = this.owners();
    if (owners && owners.length > 1) {
      if ("owner" in object) {
        const item = owners.find(({ id }) => id === object.owner);
        if (item) {
          object = {
            ...object,
            ownerNameAE: item.nameAE as never,
            ownerNameEN: item.nameEN as never,
          };
        }
      }

      if ("projectCoordinator" in object) {
        const item = owners.find(({ id }) => id === object.projectCoordinator);
        if (item) {
          object = {
            ...object,
            projectCoordinatorNameAE: item.nameAE as never,
            projectCoordinatorNameEN: item.nameEN as never,
          };
        }
      }
    }

    const orgUnits = this.orgUnits();
    if (orgUnits && orgUnits.length > 1) {
      if ("orgUnitId" in object) {
        const item = orgUnits.find(({ id }) => id === object.orgUnitId);
        if (!item) return object;

        object = {
          ...object,
          orgUnitNameAE: item.nameAE as never,
          orgUnitNameEN: item.nameEN as never,
        };
      }
    }

    return object;
  });

  config: {
    type: ScreenType;
    sections: (ScreenSectionConfigType | AccordionSectionConfigType)[];
  };
  readonly $asAccordionConfig = ($s: CompositeSectionConfigType) => $s as AccordionSectionConfigType;
  readonly $asListConfig = ($s: CompositeSectionConfigType) => $s as ListSectionConfigType;

  readonly #getOwners = () => {
    const newEntityId = this.newObject().entityId;
    const oldEntityId = this.oldObject().entityId;
    const getOwnersPerEntity$ = [this._usersApiService.getOwnersPerEntity(newEntityId)];

    if (newEntityId !== oldEntityId) {
      getOwnersPerEntity$.push(...getOwnersPerEntity$, this._usersApiService.getOwnersPerEntity(oldEntityId));
    }

    return forkJoin(getOwnersPerEntity$).pipe(
      map((res) => {
        const list = [];
        for (const { responseData } of res) {
          list.push(...(responseData ?? []));
        }
        return list;
      }),
      tap((data) => this.owners.set(data))
    );
  };
  readonly #getOrgUnits = () => {
    const newEntityId = this.newObject().entityId;
    const oldEntityId = this.oldObject().entityId;
    const getbyEntityidAndObjectype$ = [
      this._orgUnitApiService.getbyEntityidAndObjectype(newEntityId, Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS),
    ];

    if (newEntityId !== oldEntityId) {
      getbyEntityidAndObjectype$.push(
        ...getbyEntityidAndObjectype$,
        this._orgUnitApiService.getbyEntityidAndObjectype(oldEntityId, Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS)
      );
    }

    return forkJoin(getbyEntityidAndObjectype$).pipe(
      map((res) => {
        const list = [];
        for (const { responseData } of res) {
          list.push(...(responseData ?? []));
        }
        return list;
      }),
      tap((data) => this.orgUnits.set(data))
    );
  };
  readonly #getStrategicProjects = () => {
    const newEntityId = this.newObject().entityId;
    const oldEntityId = this.oldObject().entityId;
    const getStrategicProjects$ = [this._tpApiService.getStrategicProjects(newEntityId)];

    if (newEntityId !== oldEntityId) {
      getStrategicProjects$.push(...getStrategicProjects$, this._tpApiService.getStrategicProjects(oldEntityId));
    }

    return forkJoin(getStrategicProjects$).pipe(
      map((res) => {
        const list = [];
        for (const { responseData } of res) {
          list.push(...(responseData ?? []));
        }
        return list;
      }),
      tap((data) => this.strategicProjects.set(data))
    );
  };
  readonly #apiRequests = () => {
    const api$ = [this._cyclesApiService.getAll(), this._entitiesApiService.getAll(AdaaBoolean.Y)];
    const obs$ = forkJoin(api$).pipe(
      tap({
        next: ([cycles, entities]) => {
          this.cycles.set(cycles.responseData as CycleModelType[]);
          this.entities.set(entities.responseData as EntityModelType[]);
        },
      })
    );

    return forkJoin([obs$, this.#getOwners(), this.#getOrgUnits(), this.#getStrategicProjects()]).subscribe({
      next: () => {
        this.isScreenReady.set(true);
      },
    });
  };

  public ngOnInit() {
    this.config =
      this.itemType() === Constants.CONSTANT_WORKFLOW.ANNUAL_PROJECT_PROJECTS
        ? agmProjectScreenConfig
        : agmProjectMilestoneScreenConfig;
    this.#apiRequests();
  }

  public download(data: Record<string, unknown>) {
    data.downloading = true;
    this._filesApiService.download(data.id as number, true).subscribe({
      next: (blob) => {
        fileSaver.saveAs(blob, data.filename as string);
      },
      error: () => {
        data.downloading = false;
      },
      complete: () => {
        data.downloading = false;
      },
    });
  }
}
