import { Component, effect, inject, input, signal } from "@angular/core";

import { SafeHtmlPipe } from "../../../../core/pipes";
import { FilesApiService } from "../../../services";

@Component({
  selector: "adaa-picture",
  standalone: true,
  imports: [SafeHtmlPipe],
  templateUrl: "./picture.component.html",
  styleUrl: "./picture.component.scss",
})
export class PictureComponent {
  pictureId = input<number>();
  picture = input<string>("/assets/images/profile_placeholder.png");
  style = input<string>("object-fit: cover; border-radius: 10px");
  width = input<string>("70px");
  height = input<string>("auto");

  isSVG: boolean = false;
  contentString = signal<string>("");

  private readonly _fileService = inject(FilesApiService);

  constructor() {
    effect(() => {
      this._downloadFile();
    });
  }

  private _downloadFile() {
    if (this.pictureId())
      this._fileService.download(this.pictureId()!).subscribe({
        next: async (blob) => {
          const content = await blob.text();
          this.isSVG = content.includes("svg") || content.includes("symbol");

          if (!this.isSVG) {
            const dataUrl = window.URL.createObjectURL(blob);
            this.contentString.set(dataUrl);
          } else {
            this.contentString.set(content);
          }
        },
      });
  }
}
