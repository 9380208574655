import { inject, Injectable, signal } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import { AdaaBoolean, AdministrativeTitle, PageMode } from "../constants/enums";
import {
  OrgUnit,
  UserEntitiesResponsibleFor,
  UserModelType,
  UserResponsiblitiesRequest,
  ValidatorModelType,
  ValueText,
} from "../models";
import { EntitiesApiService } from "./entities-api.service";
import { OrgUnitApiService } from "./org-unit-api.service";
import { RolesApiService } from "./roles-api.service";
import { UsersApiService } from "./users-api.service";
import { ValidatorApiService } from "./validator-api.service";

@Injectable({
  providedIn: "root",
})
export class UserEditorService {
  private _formBuilder = inject(FormBuilder);
  private _validatorApiService = inject(ValidatorApiService);
  private _entitiesApiService = inject(EntitiesApiService);
  private _usersApiService = inject(UsersApiService);
  private _orgUnitApiService = inject(OrgUnitApiService);
  private _translateService = inject(TranslateService);
  private _rolesApiService = inject(RolesApiService);
  private _toastrService = inject(ToastrService);
  private _router = inject(Router);

  user = signal<UserModelType | undefined>(undefined);
  entities = signal<ValueText[]>([]);
  orgUnits = signal<ValueText[]>([]);
  orgUnitsGrantsModal = signal<OrgUnit[]>([]);
  grants = signal<ValueText[]>([]);
  formValidation = signal<ValidatorModelType | undefined>(undefined);

  administrativeTitles: ValueText[] = [];

  public initPage(): void {
    //On init page reset the orgUnits ddl - leaving and going back to the page it casing some issues with orgUnits ddl
    this.orgUnits.set([]);

    this._entitiesApiService.getAllV2().subscribe({
      next: (response) => {
        if (response.inError) return;
        this.entities.set(
          AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"))
        );
      },
    });

    this._validatorApiService.searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_USERS).subscribe({
      next: (response) => {
        if (response.inError) return;
        this.formValidation.set(response.responseData);
      },
    });

    this._translateService.get(["user.create.minister", "user.create.director_general"]).subscribe((values) => {
      this.administrativeTitles = [
        { value: AdministrativeTitle.minister, text: values["user.create.minister"] },
        {
          value: AdministrativeTitle.directorGeneral,
          text: values["user.create.director_general"],
        },
      ];
    });
  }

  public getUser(userId: number) {
    return new Observable<UserModelType | undefined>((subscriber) => {
      this._usersApiService.getUser(userId).subscribe({
        next: (response) => {
          if (response.inError) subscriber.next(undefined);
          this.user.set(response.responseData);
          subscriber.next(response.responseData);
        },
      });
    });
  }

  public getResponsiblities(userId: number) {
    return new Observable<UserEntitiesResponsibleFor[]>((subscriber) => {
      this._usersApiService.getEntitiesResponsible(userId).subscribe({
        next: (response) => {
          if (response.inError) subscriber.next([]);
          subscriber.next(response.responseData);
        },
      });
    });
  }

  public getOrgUnits(entityId: number, isGrantsModal: boolean = false) {
    this._orgUnitApiService.getAllByEntity(entityId).subscribe({
      next: (response) => {
        if (response.inError) return;
        if (isGrantsModal) {
          this.orgUnitsGrantsModal.set(response.responseData);
        } else {
          this.orgUnits.set(
            AdaaHelper.setDropdownArray(
              response.responseData,
              "id",
              AdaaHelper.getFieldLanguage("name"),
              "parentOrgUnit"
            )
          );
        }
      },
    });
  }

  public getGrants(entityId: number) {
    this._rolesApiService.getAllRoles(AdaaHelper.isPMOEntity(entityId)).subscribe({
      next: (response) => {
        if (response.inError) return;
        response.responseData = response.responseData.filter((e) => e.enabled === AdaaBoolean.Y);
        this.grants.set(AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name")));
      },
    });
  }

  public prepareForm(pageMode: PageMode) {
    const isViewMode = pageMode === PageMode.view;
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return this._formBuilder.group({
      id: { value: null, disabled: isViewMode },
      username: [
        {
          value: null,
          disabled: true,
        },
        [Validators.required],
      ],
      email: [
        {
          value: null,
          disabled: isViewMode || pageMode === PageMode.edit,
        },
        [Validators.required, Validators.pattern(emailPattern)],
      ],
      jobTitleAE: [{ value: null, disabled: isViewMode }, Validators.required],
      jobTitleEN: [{ value: null, disabled: isViewMode }, Validators.required],
      orgUnitIds: { value: [], disabled: isViewMode },
      mobilePhone: [{ value: null, disabled: isViewMode }, Validators.required],
      directPhone: { value: null, disabled: isViewMode },
      updateUser: null,
      smClassification: { value: null, disabled: isViewMode },
      nkifpClassification: { value: null, disabled: isViewMode },
      ekpifpClassification: { value: null, disabled: isViewMode },
      sfpClassification: { value: null, disabled: isViewMode },
      opmfpClassification: { value: null, disabled: isViewMode },
      dkpifpClassification: { value: null, disabled: isViewMode },
      grants: null,
      userGroups: null,
      profilePicture: null,
      profilePictureId: null,
      nameAE: [{ value: null, disabled: isViewMode }, Validators.required],
      nameEN: [{ value: null, disabled: isViewMode }, Validators.required],
      entityId: [{ value: null, disabled: isViewMode || !AdaaHelper.isPMOEntity() }, Validators.required],
      status: {
        value: pageMode === PageMode.create ? Constants.OBJECT_STATUS.ACTIVE : null,
        disabled: isViewMode,
      },
      directorGeneral: { value: null, disabled: isViewMode },
      minister: { value: null, disabled: isViewMode },
      enabled: { value: null, disabled: isViewMode },
      //FE ONLY
      administrativeTitle: { value: null, disabled: isViewMode },
    });
  }

  public prepareGrantsForm() {
    const entityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "prop",
      property: "id",
    }) as number | undefined;

    return this._formBuilder.group({
      entityId: AdaaHelper.isPMOEntity() ? null : entityId,
      orgUnitId: null,
      grants: null,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public saveUser(userId: number | undefined, userData: any, responsiblities: UserEntitiesResponsibleFor[]) {
    this._usersApiService.saveUser(userId ? "update" : "create", userData).subscribe({
      next: (response) => {
        if (response.inError) return;
        this._saveResponsiblities(userId ? userId : response.responseData, responsiblities);
      },
    });
  }

  private _saveResponsiblities(userId: number, responsiblities: UserEntitiesResponsibleFor[]) {
    const data: UserResponsiblitiesRequest = {
      userId: userId,
      list: responsiblities,
    };
    responsiblities.forEach((e) => (e.userId = userId));

    this._usersApiService.saveUserResponsiblities(data).subscribe({
      next: (response) => {
        if (response.inError) {
          this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
          return;
        }
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigate(["/console/users"]);
      },
    });
  }
}
