import { Component, inject, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { PercentageValuePipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../constants/constants";
import { AdaaBoolean } from "../../../../constants/enums";
import { StructureViewColumnConfig } from "../../../../models";
import { StructureViewService } from "../../../../services";

@Component({
  selector: "adaa-objects-tree-row",
  standalone: true,
  imports: [TranslateModule, PercentageValuePipe],
  templateUrl: "./objects-tree-row.component.html",
  styleUrl: "./objects-tree-row.component.scss",
})
export class ObjectsTreeRowComponent {
  structureViewService = inject(StructureViewService);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data = input.required<any>();
  columns = input.required<StructureViewColumnConfig[]>();
  isEven = input.required<boolean>();
  periodId = input.required<number>();
  level = input.required<number>();

  Constants = Constants;
  AdaaHelper = AdaaHelper;
  AdaaBoolean = AdaaBoolean;

  public get isRowSelected(): boolean {
    return (
      this.structureViewService.openedTreeNodes.findIndex((e) => e.level === this.level() && e.id === this.data().id) >
      -1
    );
  }
}
