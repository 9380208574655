import { Component, inject, input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

import { LanguageService } from "../../../services";

@Component({
  selector: "adaa-item-slider",
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: "./item-slider.component.html",
  styleUrl: "./item-slider.component.scss",
})
export class ItemSliderComponent {
  readonly languageService = inject(LanguageService);

  hasTitle = input<boolean>(true);
  showPerformance = input<boolean>(false);

  readonly leftArrow = faArrowLeftLong;
  readonly rightArrow = faArrowRightLong;

  public onClick(container: HTMLDivElement, dir: "left" | "right") {
    container.scrollBy({
      top: 0,
      left: dir === "right" ? 400 : -400,
      behavior: "smooth",
    });
  }
}
