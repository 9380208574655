@if (isScreenReady()) {
    <section class="row d-flex align-content-center mt-2" [dir]="languageService.direction()">
        <div
            class="p-2 border border-white tbl-col rounded-start-1 bg-white text-primary fw-bold text-center"
            [class.col-6]="showOnlyNew()"
            [class.col-4]="!showOnlyNew()"
        >
            {{ "communication.difference.key" | translate }}
        </div>

        <div
            class="p-2 border border-white tbl-col bg-white text-primary fw-bold text-center"
            [class.rounded-end-1]="showOnlyNew()"
            [class.col-6]="showOnlyNew()"
            [class.col-4]="!showOnlyNew()"
        >
            {{ !showOnlyNew() ? ("communication.difference.new_value" | translate) : ("communication.difference.value" | translate) }}
        </div>

        @if (!showOnlyNew()) {
            <div class="col-4 p-2 border border-white tbl-col rounded-end-1 bg-white text-primary fw-bold text-center">
                {{ "communication.difference.old_value" | translate }}
            </div>
        }
    </section>

    @for (section of config.sections; track $index) {
        @switch (section.view) {
            @case ("normal") {
                <adaa-normal-diff-view
                    [isNew]="isNew()"
                    [config]="section"
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newKpiObject()"
                    [oldData]="oldKpiObject()"
                    [showDIffOnly]="viewOnlyDifferences()"
                />
            }

            @case ("list") {
                <adaa-list-diff-view
                    [isAuditTrail]="isAuditTrail()"
                    [config]="$asListConfig(section)"
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newKpiObject()!"
                    [oldData]="oldKpiObject()!"
                    [showDIffOnly]="viewOnlyDifferences()"
                />
            }

            @case ("formula") {
                <adaa-formula-diff-view
                    [isNew]="isNew()"
                    [config]="section"
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newKpiObject()"
                    [oldData]="oldKpiObject()"
                    [showDIffOnly]="viewOnlyDifferences()"
                />
            }

            @case ("targets") {
                <adaa-targets-diff-view
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newKpiObject()"
                    [oldData]="oldKpiObject()"
                    [showDIffOnly]="viewOnlyDifferences()"
                />
            }

            @case ("ext-fields") {
                <adaa-extended-fields-diff
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newKpiObject()?.extendedFields ?? []"
                    [oldData]="oldKpiObject()?.extendedFields ?? []"
                    [showDIffOnly]="viewOnlyDifferences()"
                />
            }

            @case ("attachment") {
                <adaa-attachment-diff-view
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newKpiObject()!.attachments"
                    [oldData]="oldKpiObject()!.attachments"
                    [showDIffOnly]="viewOnlyDifferences()"
                />
            }

            @case ("benchmark") {
                <adaa-benchmark-diff-view
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newKpiObject()!.benchmarks"
                    [oldData]="oldKpiObject()!.benchmarks"
                />
            }

            @case ("accordion") {
                <adaa-accordion-diff-view
                    [isNew]="isNew()"
                    [config]="$asAccordionConfig(section)"
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newKpiObject()!"
                    [oldData]="oldKpiObject()!"
                    [props]="props()"
                    [showDIffOnly]="viewOnlyDifferences()"
                />
            }
        }
    }
} @else {
    <div class="w-100 d-flex justify-content-center py-3">
        <div class="zsk__loader zsk__loader--sm"></div>
    </div>
}
