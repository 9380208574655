import { inject, Injectable } from "@angular/core";

import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class ResourcesApiService {
  private _mainService = inject(MainService);

  public ping() {
    return this._mainService.sendRequest({
      method: "GET",
      url: "resources/ping",
      loader: false,
    });
  }
}
