<div class="modal-header">
    <h4 class="modal-title">
        {{
            pageMode === PageMode.create
                ? ("national_strategies.enabler_objectives.new_eo" | translate)
                : ("national_strategies.enabler_objectives.edit_eo" | translate)
        }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <form class="col-12" [formGroup]="subDirectionForm">
        @if (pageMode !== PageMode.create) {
            <div class="row">
                <div class="col-12">
                    <adaa-form-input
                        controlName="refCode"
                        formControlName="refCode"
                        [invalid]="submitted && subDirectionForm.get('refCode')?.invalid!"
                        [label]="'common.form.label.refcode' | translate"
                        [required]="isFieldRequired(validations, 'refCode')"
                    />
                </div>
            </div>
        }
        <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-input
                    controlName="nameAE"
                    formControlName="nameAE"
                    [invalid]="submitted && subDirectionForm.get('nameAE')?.invalid!"
                    [label]="'common.form.label.nameAE' | translate"
                    [required]="isFieldRequired(validations, 'nameAE')"
                />
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-input
                    controlName="nameEN"
                    formControlName="nameEN"
                    [invalid]="submitted && subDirectionForm.get('nameEN')?.invalid!"
                    [label]="'common.form.label.nameEN' | translate"
                    [required]="isFieldRequired(validations, 'nameEN')"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-input
                    controlName="dscAE"
                    formControlName="dscAE"
                    [invalid]="submitted && subDirectionForm.get('dscAE')?.invalid!"
                    [label]="'common.form.label.descriptionAE' | translate"
                    [required]="isFieldRequired(validations, 'dscAE')"
                    [isTextArea]="true"
                />
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-input
                    controlName="dscEN"
                    formControlName="dscEN"
                    [invalid]="submitted && subDirectionForm.get('dscEN')?.invalid!"
                    [label]="'common.form.label.descriptionEN' | translate"
                    [required]="isFieldRequired(validations, 'dscEN')"
                    [isTextArea]="true"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-dropdown
                    controlName="owner"
                    formControlName="owner"
                    appendTo=".modal-content"
                    [label]="'directions.team_owner' | translate"
                    [required]="isFieldRequired(validations, 'dscEN')"
                    [invalid]="submitted && subDirectionForm.get('owner')?.invalid!"
                    [options]="ownerGroups"
                    [searchable]="true"
                />
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 d-flex align-items-center mt-4">
                <adaa-form-checkbox [label]="'directions.is_main' | translate" controlName="isMain" formControlName="isMain" />
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ "common.form.button.cancel" | translate }}
    </button>
    @if (pageMode !== PageMode.view) {
        <button type="button" class="btn btn-primary" (click)="save()">
            {{ "common.form.button.save" | translate }}
        </button>
    }
</div>
