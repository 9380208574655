<div class="m-1 {{ classes }}" [class.invalid]="invalid" [dir]="languageService.direction()">
    @if (adaaHelper.isDefinedAndNotEmpty(label)) {
        <label for="{{ controlId }}" class="form-label" [dir]="languageService.direction()">
            {{ label }}
            @if (required || showAsRequired) {
                <span class="form-required text-danger fw-bolder">*</span>
            }
        </label>
    }

    <ng-select
        [class.invalid]="invalid"
        [class.bold-label]="boldLabel()"
        [dir]="languageService.direction()"
        [id]="controlId"
        [items]="options"
        [appendTo]="appendTo"
        bindLabel="text"
        bindValue="value"
        [searchable]="searchable"
        [clearable]="clearable"
        [formControl]="inputControl"
        [placeholder]="inputPlaceholder | translate"
        (search)="searchChannel$.next($event.term)"
        (ngModelChange)="valueChanged($event)"
        (clear)="onClear.emit(null)"
    >
        <ng-template ng-option-tmp let-item="item">
            @if (enableTooltips) {
                <div class="ng-option-label" [title]="item.text">{{ item.text }}</div>
            } @else {
                <div class="ng-option-label">{{ item.text }}</div>
            }
        </ng-template>
    </ng-select>
</div>
