import { DatePipe } from "@angular/common";
import { Component, computed, inject, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import * as fileSaver from "file-saver";

import { DubaiTimePipe, TranslateTokenPipe } from "../../../../../../../core/pipes";
import { Constants } from "../../../../../../constants/constants";
import type { AttachmentModelType, PropTypeModelType } from "../../../../../../models";
import { FilesApiService, PropertiesService } from "../../../../../../services";

@Component({
  selector: "adaa-attachment-diff-view",
  standalone: true,
  styleUrl: "../styles.scss",
  imports: [TranslateModule, DubaiTimePipe, DatePipe, TranslateTokenPipe],
  templateUrl: "./attachment-diff-view.component.html",
})
export class AttachmentDiffViewComponent {
  private readonly _propertiesService = inject(PropertiesService);
  private readonly _filesApiService = inject(FilesApiService);

  showOnlyNew = input.required<boolean>();
  showCustomFields = input<boolean>(false);
  newData = input<AttachmentModelType[]>([]);
  oldData = input<AttachmentModelType[]>([]);
  showDIffOnly = input<boolean>(false);

  attachments = signal<
    { new?: AttachmentModelType & { downloading: boolean }; old?: AttachmentModelType & { downloading?: boolean } }[]
  >([]);
  props = signal<PropTypeModelType[]>([]);

  hasAnyDiff = computed(() => {
    if (this.showOnlyNew()) return true;

    const data = this.attachments();
    return data.some(({ new: $new, old }) => {
      if (!$new || !old || !this.isEqual($new?.nameAE, old?.nameAE)) return true;
      if (!$new || !old || !this.isEqual($new?.nameEN, old?.nameEN)) return true;
      if (this.showCustomFields()) {
        if (!$new || !old || !this.isEqual($new?.filename, old?.filename)) return true;
      } else {
        if (!$new || !old || !this.isEqual($new?.attachType, old?.attachType)) return true;
        if (!$new || !old || !this.isEqual($new?.attachDate, old?.attachDate)) return true;
      }
      return false;
    });
  });

  readonly getAttachType = (attachType: number) =>
    this.props().find((data: PropTypeModelType) => data.id === attachType);

  public ngOnInit() {
    this._fetchAttachmentTypes();
    this._processAttachments();
  }

  public isEqual($new: unknown, $old: unknown) {
    return $new === $old;
  }

  public download(data: Record<string, unknown>) {
    data.downloading = true;
    this._filesApiService.download(data.id as number, true).subscribe({
      next: (blob) => {
        fileSaver.saveAs(blob, data.filename as string);
      },
      error: () => {
        data.downloading = false;
      },
      complete: () => {
        data.downloading = false;
      },
    });
  }

  private _fetchAttachmentTypes() {
    this._propertiesService.getPropById(Constants.CONSTANT_ATTACHTYPE).subscribe({
      next: (res) => this.props.set(res.responseData || []),
    });
  }

  private _processAttachments() {
    for (const item of this.newData() ?? []) {
      const oldItem = this.oldData()?.find((data) => data.id === item.id);
      if (!oldItem) {
        //* is added
        this.attachments.update((items) => [...items, { new: { ...item, downloading: false } }]);
      } else {
        this.attachments.update((items) => [
          ...items,
          { new: { ...item, downloading: false }, old: { ...oldItem, downloading: false } },
        ]);
      }
    }

    for (const item of this.oldData() ?? []) {
      const newItem = this.newData()?.find((data) => data.id === item.id);
      if (!newItem) {
        //* is removed
        this.attachments.update((items) => [...items, { old: { ...item, downloading: false } }]);
      }
    }
  }
}
