import { Component, inject, type OnInit, output, signal } from "@angular/core";
import { FormBuilder, type FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { ValueText } from "../../../models";
import { LanguageService, PropertiesService } from "../../../services";
import { FormDropdownComponent } from "../../form";

@Component({
  selector: "adaa-entity-type-size-form",
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, FormDropdownComponent],
  templateUrl: "./entity-type-size-form.component.html",
  styleUrl: "./entity-type-size-form.component.scss",
})
export class EntityTypeSizeFormComponent implements OnInit {
  readonly languageService = inject(LanguageService);
  private readonly _formBuilder = inject(FormBuilder);
  private readonly _propertiesService = inject(PropertiesService);

  selected = output<{ type: "entitySize" | "entityType"; data: number }>();
  entityTypes = output<ValueText[]>();
  entitySizes = output<ValueText[]>();

  readonly isPMOEntity = AdaaHelper.isPMOEntity;

  form: FormGroup;
  entityTypesOptions = signal<ValueText[]>([]);
  entitySizesOptions = signal<ValueText[]>([]);

  public ngOnInit() {
    this._getEntitesTypes();
    this._prepareForm();
  }

  private _prepareForm(): void {
    this.form = this._formBuilder.group({
      entitySize: [0],
      entityType: [0],
    });
  }

  private _getEntitesTypes(): void {
    const allEntitiesOption: ValueText = {
      value: 0,
      text: this.languageService.translate("national_projects.all_entities"),
    };

    this._propertiesService
      .getPropByIdList([Constants.CONSTANT_ENTITY_TYPE, Constants.CONSTANT_ENTITY_SIZE])
      .subscribe((response) => {
        if (response.inError) return;
        const entitySizes = response.responseData.filter((e) => e.propType == Constants.CONSTANT_ENTITY_SIZE);
        this.entitySizesOptions.set(
          AdaaHelper.setDropdownArray(entitySizes, "id", AdaaHelper.getFieldLanguage("name"))
        );

        const entityTypes = response.responseData.filter((e) => e.propType == Constants.CONSTANT_ENTITY_TYPE);
        this.entityTypesOptions.set(
          AdaaHelper.setDropdownArray(entityTypes, "id", AdaaHelper.getFieldLanguage("name"))
        );

        this.entitySizesOptions().unshift(allEntitiesOption);
        this.entityTypesOptions().unshift(allEntitiesOption);

        this.entitySizes.emit(this.entitySizesOptions());
        this.entityTypes.emit(this.entityTypesOptions());
      });
  }
}
