<div class="m-1" [class.invalid]="invalid">
    @if (adaaHelper.isDefinedAndNotEmpty(label)) {
        <label for="{{ controlId }}" class="form-label">
            {{ label }}
            @if (required || showAsRequired()) {
                <span class="form-required text-danger fw-bolder">*</span>
            }
            @if (maxSelect !== DEFAULT_MAX_SELECT) {
                <fa-icon
                    class="fw-bolder text-danger"
                    [title]="('common.form.validations.max_allowed_to_select' | translate) + ' ' + maxSelect"
                    [icon]="['fas', 'question']"
                ></fa-icon>
            }
        </label>
    }

    <ng-select
        class="shadow-sm"
        [class.invalid]="invalid"
        bindLabel="text"
        bindValue="value"
        [multiple]="true"
        [id]="controlId"
        [items]="options"
        [searchable]="searchable"
        [clearable]="clearable"
        [formControl]="inputControl"
        [dir]="languageService.direction()"
        [maxSelectedItems]="maxSelect"
        [placeholder]="inputPlaceholder | translate"
        (search)="searchChannel$.next($event.term)"
        (ngModelChange)="valueChanged($event)"
    />
</div>
