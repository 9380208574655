import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import {
  AffectedEntity,
  CommonKpiModelType,
  EkpiAuditInfoType,
  EkpiItemTableValueModel,
  KpiAnnualWeightsConfigModelType,
  KpiAnnualWeightsModelType,
  KpiAuditInfoType,
  MainResponseModel,
  WeightInputModelType,
} from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class KpisApiService {
  private _mainService = inject(MainService);

  public create(data: unknown, isFinish = false) {
    const url = `kpi/create?isFinish=${isFinish}`;

    return this._mainService
      .sendRequest<MainResponseModel<unknown>>({
        method: "POST",
        url: url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public update(data: unknown, isFinish = false) {
    const url = `kpi/update?isFinish=${isFinish}`;

    return this._mainService
      .sendRequest<MainResponseModel<unknown>>({
        method: "POST",
        url: url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getAllByTypeAndEntityList(id: number, entityIds: number[], year?: number) {
    let url = `kpi/getAllByTypeAndEntityList/${id}`;
    if (year) url += `?year=${year}`;

    return this._mainService.sendRequest<MainResponseModel<unknown[]>>({
      method: "POST",
      url,
      data: entityIds,
    });
  }

  public getCardById({ id, periodId }: { id: number; periodId?: string | number }) {
    const url = `kpi/getCardById/${id}`;
    const qs = new URLSearchParams({ ...(periodId ? { periodId: `${periodId}` } : {}) });

    return this._mainService.sendRequest<MainResponseModel<CommonKpiModelType>>({
      method: "GET",
      url: `${url}?${qs}`,
    });
  }

  public getById({ id, periodId }: { id: number; periodId?: string | number }) {
    const url = `kpi/getbyid/${id}`;
    const qs = new URLSearchParams({ ...(periodId ? { periodId: `${periodId}` } : {}) });

    return this._mainService.sendRequest<MainResponseModel<CommonKpiModelType>>({
      method: "GET",
      url: `${url}?${qs}`,
    });
  }

  public deleteById(id: number) {
    const url = `kpi/deleteById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<unknown>>({
      method: "DELETE",
      url,
    });
  }

  public getAuditInfo({ kpiId, includeLegacy = false }: { kpiId: number; includeLegacy?: boolean }) {
    const url = `kpi/getAuditInfo/${kpiId}`;
    const qs = new URLSearchParams();
    if (includeLegacy) qs.set("includeLegacy", `true`);

    return this._mainService.sendRequest<MainResponseModel<KpiAuditInfoType[]>>({
      method: "GET",
      url: `${url}?${qs}`,
    });
  }

  public getEKPIAuditInfo(kpiId: number) {
    const url = `kpi/getEKPIAuditInfo/${kpiId}`;

    return this._mainService.sendRequest<MainResponseModel<EkpiAuditInfoType[]>>({
      method: "GET",
      url,
    });
  }

  public getLegacyKpis({
    kpiType,
    startDate,
    limit = 20,
    ...opts
  }: {
    kpiType: number;
    startDate: number;
    kpiId: number | undefined;
    searchTerm?: string;
    limit?: number;
  }) {
    const url = `kpi/getLegacyKpis/${kpiType}/${startDate}`;
    const qs = new URLSearchParams({
      limit: `${limit}`,
    });

    if (opts.kpiId) qs.set("kpiId", `${opts.kpiId}`);
    if (opts.searchTerm) qs.set("text", opts.searchTerm);

    return this._mainService.sendRequest<MainResponseModel<CommonKpiModelType[]>>({
      method: "GET",
      url: `${url}?${qs}`,
    });
  }

  public getItemDataByTypeAndIdByEntity({
    id,
    periodId,
    kpiType,
    frequency,
    entityId,
    includeLegacyKpi,
  }: {
    id: number;
    periodId?: number;
    kpiType: number;
    entityId: number;
    frequency: string;
    includeLegacyKpi?: boolean;
  }) {
    const url = `kpi/getItemDataByTypeAndIdByEntity/${id}/${kpiType}/${entityId}`;
    const qs = new URLSearchParams({
      frequency: `${frequency}`,
    });
    if (periodId) {
      qs.append("periodId", `${periodId}`);
    }
    if (includeLegacyKpi) {
      qs.append("includeLegacyKpi", `${includeLegacyKpi}`);
    }

    return this._mainService
      .sendRequest<MainResponseModel<EkpiItemTableValueModel[]>>({
        method: "GET",
        url: `${url}?${qs}`,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getItemDataByTypeAndId({
    id,
    periodId,
    kpiType,
    frequency,
  }: {
    id: number;
    periodId: number;
    kpiType: number;
    frequency: string;
  }) {
    const url = `kpi/getItemDataByTypeAndId/${id}/${kpiType}`;
    const qs = new URLSearchParams({
      periodId: `${periodId}`,
      frequency: `${frequency}`,
      kpiType: `${kpiType}`,
    });

    return this._mainService
      .sendRequest<MainResponseModel<EkpiItemTableValueModel[]>>({
        method: "GET",
        url: `${url}?${qs}`,
      })
      .pipe(filter((res) => !res.inError));
  }

  /**
   * @desc
   *  Below is the KPI Annual Weights API Calls
   * */
  public getKPIAnnualWeightsConfig(entityId: number) {
    const url = `kpi-annual-weight-config/get/${entityId}`;

    return this._mainService.sendRequest<MainResponseModel<KpiAnnualWeightsConfigModelType[]>>({
      method: "GET",
      url,
    });
  }

  public updadeAnnualConfig(id: number, data: { useWeight: boolean }) {
    const url = `kpi-annual-weight-config/update/${id}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "PUT",
      url,
      data,
    });
  }

  public getKPIAnnualStatusConfig(entityId: number, kpiType: number) {
    const url = `kpi-annual-weight-config/getStatus/${entityId}?kpiType=${kpiType}`;

    return this._mainService.sendRequest<MainResponseModel<{ year: number; enabled: boolean }[]>>({
      method: "GET",
      url,
    });
  }

  public getKPIAnnualWeights(entityId: number, kpiType: number) {
    const url = `kpi-annual-weight/get/${entityId}?itemType=${kpiType}`;

    return this._mainService.sendRequest<MainResponseModel<{ weights: KpiAnnualWeightsModelType[] }>>({
      method: "GET",
      url,
    });
  }

  public getTransformationalTargetKpis(targetId: number) {
    const url = `kpi/getTransformationalTargetKpis?transformationalTargetId=${targetId}`;

    return this._mainService.sendRequest<MainResponseModel<CommonKpiModelType[]>>({
      method: "POST",
      url,
      data: {},
    });
  }

  public updadeAnnualWeights(id: number, data: { weights: WeightInputModelType[] }) {
    const url = `kpi-annual-weight/update/${id}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "PUT",
      url,
      data,
    });
  }

  public getCountAuditIssues({ entityId, period }: { period: number; entityId: number }) {
    const url = `kpi/getCountAuditIssues`;

    const qs = new URLSearchParams();
    qs.append("planId", period.toString());
    qs.append("entityId", entityId.toString());

    return this._mainService.sendRequest<MainResponseModel<number>>({
      url: `${url}?${qs}`,
      method: "GET",
    });
  }

  public getEkpiAffectedEntities(id: number) {
    return this._mainService.sendRequest<MainResponseModel<AffectedEntity[]>>({
      url: `kpi/getEkpiEntities/${id}`,
      method: "GET",
    });
  }
}
