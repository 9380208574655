import { inject, Injectable, signal } from "@angular/core";

import type { GovEnablerModelType, GovEnablerPillarModelType } from "../models";
import { CalculationEngineApiService } from "./calculation-engine-api.service";

@Injectable({
  providedIn: "root",
})
export class GovEnablerPerfService {
  private readonly _calcEngineApiService = inject(CalculationEngineApiService);

  overallPillar = signal<Omit<GovEnablerModelType, "pillars"> | undefined>(undefined);
  pillars = signal<GovEnablerPillarModelType[]>([]);

  public getQuarterlyGovEnablerPillars(periodId: number) {
    this._calcEngineApiService
      .getGovEnablerPillars({
        period: periodId,
        isAnnual: false,
      })
      .subscribe({
        next: (res) => {
          if (!res.inError) {
            this.pillars.update(() => res.responseData.pillars);
            this.overallPillar.update(() => ({
              govEnablerAggregationType: res.responseData.govEnablerAggregationType,
              govEnablerPerformanceColor: res.responseData.govEnablerPerformanceColor,
              govEnablerPerformance: res.responseData.govEnablerPerformance,
            }));
          }
        },
      });
  }

  public getAnnualGovEnablerPillars(year: number) {
    this._calcEngineApiService
      .getGovEnablerPillars({
        period: year,
        isAnnual: true,
      })
      .subscribe({
        next: (res) => {
          if (!res.inError) {
            this.pillars.update(() => res.responseData.pillars);
            this.overallPillar.update(() => ({
              govEnablerAggregationType: res.responseData.govEnablerAggregationType,
              govEnablerPerformanceColor: res.responseData.govEnablerPerformanceColor,
              govEnablerPerformance: res.responseData.govEnablerPerformance,
            }));
          }
        },
      });
  }
}
