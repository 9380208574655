<div class="m-1 w-100" [class.invalid]="invalid" (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
    @if (adaaHelper.isDefinedAndNotEmpty(label)) {
        <label class="form-label">
            {{ label | translate }}
            @if (required) {
                <span class="form-required text-danger fw-bolder">*</span>
            }
        </label>
    }

    <div class="input-group justify-content-center">
        <input
            #fileInput
            type="file"
            class="form-control shadow-sm"
            [class.is-invalid]="invalid"
            (change)="onFileChange($event)"
            [disabled]="isDisabled"
            hidden
        />

        @if (!fileName() || fileName().length == 0) {
            <div class="file-dropper" [class.disabled]="isDisabled" [dir]="languageService.direction()" (click)="!isDisabled && fileInput.click()">
                <p class="m-0">{{ !isDisabled ? (dropFileZoneMessage | translate) : (dropFileZoneDisabledMessage | translate) }}</p>
                @if (!isDisabled) {
                    <fa-icon [icon]="['fas', 'upload']"></fa-icon>
                }
            </div>
            @if (isLoading) {
                <div class="w-100 d-flex justify-content-center py-2 my-2">
                    <div class="zsk__loader zsk__loader--sm"></div>
                </div>
            }
        } @else {
            <div class="row">
                <div class="col-12 mb-2">
                    <div class="row d-flex flex-nowrap">
                        <div class="col-1 d-flex align-items-center justify-content-center">
                            <i [ngClass]="fileIcon()" class="uploaded-file-icon"></i>
                        </div>

                        <div
                            class="col-10 d-flex align-items-center justify-content-center uploaded-file-name"
                            role="button"
                            (click)="downloadFile()"
                        >
                            {{ fileName() }}
                        </div>

                        @if (!isDisabled) {
                            <div class="col-1 d-flex align-items-center justify-content-center">
                                <button class="btn btn-outline-danger mx-1 p-0 flex-shrink-0 btn-remove-file" (click)="removeFile()" type="button">
                                    <i class="fa fa fa-xmark"></i>
                                </button>
                            </div>
                        }
                    </div>
                </div>
                @if (showContent && file && file.id) {
                    <div class="col-12 d-flex align-items-center justify-content-center">
                        <adaa-picture width="200px" [pictureId]="file.id" />
                    </div>
                }
            </div>
        }
    </div>
    <p class="text-black-50 fst-italic text-center mt-1" [innerHTML]="allowedFilesMessage | translate">
        @if (required) {
            <span class="form-required text-danger fw-bolder">*</span>
        }
    </p>
</div>
