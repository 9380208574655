import { inject, Injectable, signal } from "@angular/core";
import { filter, tap } from "rxjs";

import type { AggregationPerfModelType } from "../models";
import { CalculationEngineApiService } from "./calculation-engine-api.service";
import { KpisApiService } from "./kpis-api.service";

const ENTITY_OVERALL_PERFORMANCE = 102560;
const PMO_OVERALL_PERFORMANCE = 102576;

@Injectable({
  providedIn: "platform",
})
export class AggregationPerfService {
  private readonly _calcEngineApiService = inject(CalculationEngineApiService);
  private readonly _kpisApiService = inject(KpisApiService);

  overallAggregationPerf = signal<AggregationPerfModelType | undefined>(undefined);

  aggregationPerf = signal<AggregationPerfModelType[]>([]);
  hasKPIsWithIssue = signal<boolean>(false);

  public pmoAnnualPerformance(year: number) {
    return this._calcEngineApiService.getPMOPerformance({ period: year, isAnnual: true }).pipe(
      tap({
        next: (response) => {
          if (response.inError) {
            return;
          }

          const overallPerf = response.responseData?.find(
            ({ aggregationType }) => aggregationType === PMO_OVERALL_PERFORMANCE
          );

          this.overallAggregationPerf.set(overallPerf);

          this.aggregationPerf.set(
            response.responseData?.filter(({ aggregationType }) => aggregationType !== PMO_OVERALL_PERFORMANCE)
          );
        },
      })
    );
  }

  public pmoQuarterlyPerformance(period: number) {
    return this._calcEngineApiService.getPMOPerformance({ period, isAnnual: false }).pipe(
      tap({
        next: (response) => {
          if (response.inError) {
            return;
          }

          const overallPerf = response.responseData?.find(
            ({ aggregationType }) => aggregationType === PMO_OVERALL_PERFORMANCE
          );

          this.overallAggregationPerf.set(overallPerf);

          this.aggregationPerf.set(
            response.responseData?.filter(({ aggregationType }) => aggregationType !== PMO_OVERALL_PERFORMANCE)
          );
        },
      })
    );
  }

  public entityAnnualPerformance(year: number, entityId: number) {
    return this._calcEngineApiService.getEntityPerformance({ entityId, period: year, isAnnual: true }).pipe(
      filter((res) => !res.inError),
      tap({
        next: (response) => {
          if (response.inError) {
            return;
          }

          const overallPerf = response.responseData?.find(
            ({ aggregationType }) => aggregationType === ENTITY_OVERALL_PERFORMANCE
          );

          this.overallAggregationPerf.set(overallPerf);

          this.aggregationPerf.set(
            response.responseData?.filter(({ aggregationType }) => aggregationType !== ENTITY_OVERALL_PERFORMANCE)
          );
        },
      })
    );
  }

  public entityQuarterlyPerformance(period: number, entityId: number) {
    return this._calcEngineApiService.getEntityPerformance({ entityId, period, isAnnual: false }).pipe(
      filter((res) => !res.inError),
      tap({
        next: (response) => {
          if (response.inError) {
            return;
          }

          const overallPerf = response.responseData?.find(
            ({ aggregationType }) => aggregationType === ENTITY_OVERALL_PERFORMANCE
          );

          this.overallAggregationPerf.set(overallPerf);

          this.aggregationPerf.set(
            response.responseData?.filter(({ aggregationType }) => aggregationType !== ENTITY_OVERALL_PERFORMANCE)
          );
        },
      })
    );
  }

  public getCountAuditIssues(period: number, entityId: number) {
    return this._kpisApiService.getCountAuditIssues({ entityId, period }).pipe(
      filter((res) => !res.inError),
      tap({
        next: (response) => {
          if (response.inError) return;

          this.hasKPIsWithIssue.set(response.responseData > 0);
        },
      })
    );
  }
}
