<div class="row">
    <div class="col-12">
        <div class="slider-container bg-white shadow-md rounded-1 border border-secondary-subtle p-0">
            @if (showChartCycle() && !disableSlider()) {
                <div class="row d-flex justify-content-space-between px-2 pt-1">
                    @if (isEKPI()) {
                        <div class="d-flex align-items-center align-content-center" [class.col-6]="isEKPI()">
                            <ng-content select="[slot='ekpi-entity-selector']"></ng-content>
                        </div>
                    }

                    <div
                        class="d-flex justify-content-end align-items-center align-content-center gap-2"
                        [class.col-12]="!isEKPI()"
                        [class.col-6]="isEKPI()"
                    >
                        @for (cycle of chartFrequencyCycles(); track cycle.id) {
                            <button
                                class="btn btn-sm btn-chart-freq-cycle rounded-0"
                                [class.active]="isActive(cycle.id)"
                                [class.fw-bold]="isActive(cycle.id)"
                                [class.btn-info]="isActive(cycle.id)"
                                [class.btn-outline-info]="!isActive(cycle.id)"
                                [style.--bs-btn-padding-y]="'2px'"
                                [style.--bs-btn-padding-x]="'8px'"
                                [style.--bs-btn-font-size]="'11px'"
                                [style.--bs-btn-hover-color]="'#FFF'"
                                (click)="selectChartCycle(cycle.id)"
                            >
                                {{ cycle.label | translate }}
                            </button>
                        }
                    </div>
                </div>
            }

            <div class="row d-flex justify-content-space-between px-0 mx-0">
                <div class="col-12 text-center pt-2 pb-3 px-0" [class.bold-years]="boldYears()">
                    @if (!isReady()) {
                        <div class="w-100 d-flex justify-content-center py-2">
                            <div class="zsk__loader zsk__loader--sm"></div>
                        </div>
                    } @else {
                        <ngx-slider [value]="selectedPeriod()!" [options]="sliderOptions()" (valueChange)="periodChanged($event)"></ngx-slider>
                    }
                </div>
            </div>
        </div>
    </div>
</div>
