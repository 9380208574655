<div class="position-relative d-flex flex-nowrap align-items-center justify-content-between uae-vision-card" (click)="cardClicked.emit(data())">
    <div class="image">
        <img [src]="logo()" [class.svg]="logoType() === 'svg'" />
    </div>

    <div class="content px-3">
        <div class="title">
            <h6 class="fw-bold">{{ data()! | translateToken: "name" }}</h6>
        </div>

        <div class="stats">
            @if (useIndicator().performance) {
                <div
                    class="progress my-1 d-flex align-items-center position-relative"
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="height: 30px; border-radius: 5px"
                    [attr.aria-label]="'landingPage.entity_page.annual_performance' | translate"
                >
                    <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        style="height: 30px"
                        [style.width]="data().performance! | percentageValue: 2 : '0'"
                        [style.background-color]="getColor(data().performanceColor!)"
                    ></div>
                    <span class="position-absolute fw-bold mx-2 text-white">
                        {{ "landingPage.entity_page.annual_performance" | translate }} {{ data().performance! | percentageValue }}
                    </span>
                </div>
            }

            @if (useIndicator().visionAchievement) {
                <div
                    class="progress my-1 d-flex align-items-center position-relative"
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="height: 30px; border-radius: 5px"
                    [attr.aria-label]="'landingPage.entity_page.overall_performance' | translate"
                >
                    <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        style="height: 30px"
                        [style.width]="data().visionAchievement! | percentageValue: 2 : '0'"
                        [style.background-color]="getColor(data().visionAchievementColor!)"
                    ></div>
                    <span class="position-absolute fw-bold mx-2 text-white">
                        {{ "landingPage.entity_page.overall_performance" | translate }}: {{ data().visionAchievement! | percentageValue }}
                    </span>
                </div>
            }

            @if (useIndicator().ntkpiCount) {
                <div
                    class="progress my-1 d-flex align-items-center position-relative"
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style="height: 30px; border-radius: 5px"
                    [attr.aria-label]="'landingPage.entity_page.targets' | translate"
                >
                    <div class="progress-bar progress-bar-striped position-relative bg-info" style="height: 30px" [style.width]="'100%'"></div>
                    <span class="position-absolute fw-bold mx-2 text-white">
                        {{ "landingPage.entity_page.targets" | translate }}: {{ data().ntKpiCount! }}
                    </span>
                </div>
            }
        </div>
    </div>
</div>
