<div
    class="d-flex flex-column justify-content-around align-items-center px-2 agg-type text-white position-relative"
    [style.background-color]="isDefined(amount()) ? color() : defaultColor"
    (click)="data().uri && openUri(data().uri)"
>
    <div class="d-flex justify-content-center agg-type-amount">
        <span class="w-100 fs-2 text-center pt-3 fw-bolder">
            @if (isDefined(amount()) && amount() > 0) {
                <adaa-count-up [total]="amount()" />
            } @else {
                {{ amount() | percentageValue }}
            }
        </span>
    </div>

    <div class="d-flex justify-content-center align-content-center align-items-center flex-wrap agg-type-label position-relative">
        <div class="d-flex justify-content-center align-content-center align-items-center flex-wrap">
            <img class="img-fluid agg-type-image" [src]="image()" [attr.alt]="data()! | translateToken: 'label'" />

            <span class="w-100 fs-6 text-center mt-1 fw-bold">{{ data()! | translateToken: "label" }}</span>
        </div>
    </div>

    <div class="d-flex justify-content-between align-items-center agg-type-btn position-relative">
        <div class="agg-type-actions d-flex position-relative">
            @if (hideKpiListButton(data().id)) {
                <button class="btn btn-primary mx-2" [title]="'breadcrumbs.kpi' | translate" (click)="buttonClicked($event, 'kpi')">
                    <i class="adaa-icon-console_kpis"></i>
                </button>
            }

            <button
                class="btn btn-primary mx-2 position-relative"
                [title]="'common.form.label.performance_comparison' | translate"
                (click)="buttonClicked($event, 'graph')"
            >
                <fa-icon [icon]="graphIcon" />
            </button>
        </div>

        @if (children().length) {
            <button
                class="btn btn-primary more-btn"
                [dir]="languageService.direction()"
                (click)="$event.stopPropagation(); showMore() ? showMore.set(false) : showMore.set(true)"
            >
                @if (showMore()) {
                    <fa-icon [icon]="moreIcon[languageService.direction() === 'rtl' ? 'ltr' : 'rtl']" />
                } @else {
                    <fa-icon [icon]="moreIcon[languageService.direction()]" />
                }
            </button>
        }
    </div>
</div>

<adaa-kpi-scores-by-aggregation-type
    modalType="pmo"
    [periodId]="period()?.period?.id"
    [year]="period()?.period?.year"
    [periodType]="period()?.type!"
    [aggregationType]="data().id"
    #kpiScoresByAggregationType
/>

@if (showMore()) {
    <div class="d-flex flex-column align-items-center px-2">
        @for (childData of children(); track childData.id) {
            <div
                class="d-flex flex-column align-items-center px-2 my-1 agg-type agg-type--child text-white position-relative"
                [style.background-color]="isDefined(childData.value) ? getColor(childData.color) : defaultColor"
            >
                <div class="d-flex justify-content-center agg-type-amount position-relative">
                    <span class="w-100 fs-4 text-center py-2 fw-bolder">
                        @if (isDefined(childData.value) && childData.value > 0) {
                            <adaa-count-up [total]="childData.value" />
                        } @else {
                            {{ childData.value | percentageValue }}
                        }
                    </span>
                </div>

                <div class="d-flex justify-content-center align-content-center align-items-center flex-wrap agg-type-label position-relative">
                    <div class="d-flex justify-content-center align-content-center align-items-center flex-wrap">
                        <span class="w-100 fs-6 text-center mt-1 fw-bold">{{ childData | translateToken: "label" }}</span>
                    </div>
                </div>

                <div class="agg-type-actions d-flex justify-content-end align-items-center position-relative">
                    @if (hideKpiListButton(childData.id)) {
                        <button class="btn btn-primary mx-1" (click)="childKpiScoresByAggregationType.open()">
                            <i class="adaa-icon-console_kpis"></i>
                        </button>
                    }

                    <button class="btn btn-primary mx-1 position-relative" (click)="performanceGraphClicked.emit(childData)">
                        <fa-icon [icon]="graphIcon" />
                    </button>
                </div>
            </div>

            <adaa-kpi-scores-by-aggregation-type
                modalType="pmo"
                [periodId]="period()?.period?.id"
                [year]="period()?.period?.year"
                [periodType]="period()?.type!"
                [aggregationType]="childData.id"
                #childKpiScoresByAggregationType
            />
        }
    </div>
}
