import { NgClass } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { AdaaBoolean } from "../../../constants/enums";
import { ParameterCatalog } from "../../../models";
import { FormCheckboxComponent } from "../../form";

@Component({
  selector: "adaa-data-table-settings",
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, NgClass, FormCheckboxComponent],
  templateUrl: "./data-table-settings.component.html",
  styleUrl: "./data-table-settings.component.scss",
})
export class DataTableSettingsComponent implements OnInit {
  @Input() tableColumns: ParameterCatalog[] = [];

  modal: NgbActiveModal = inject(NgbActiveModal);
  private _formBuilder: FormBuilder = inject(FormBuilder);

  settingsForm: FormGroup;
  AdaaHelper = AdaaHelper;

  public ngOnInit(): void {
    this._prepareForm();
  }

  public apply(): void {
    this.tableColumns.forEach((param) => {
      param.show = this.settingsForm.value[param.fieldName];
    });
    this.modal.close(this.tableColumns);
  }

  public selectALL(event: boolean): void {
    this.tableColumns.forEach((param) => {
      if (!this.settingsForm.get(param.fieldName)?.disabled) this.settingsForm.get(param.fieldName)?.setValue(event);
    });
  }

  private _prepareForm() {
    this.settingsForm = this._formBuilder.group({});
    if (!this.tableColumns) return;

    this.tableColumns.forEach((param) => {
      this.settingsForm.addControl(
        param.fieldName,
        new FormControl(
          {
            value: param.userVisibleParameter === AdaaBoolean.Y || param.userVisibleParameter == null,
            disabled: param.mandatory === AdaaBoolean.Y,
          },
          []
        )
      );
    });

    const selectedCount = this.tableColumns.filter(
      (e) => e.userVisibleParameter === AdaaBoolean.Y || e.userVisibleParameter == null
    ).length;

    this.settingsForm.addControl("selectALL", new FormControl(selectedCount === this.tableColumns.length, []));
  }
}
