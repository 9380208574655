import { AfterViewInit, Component, ElementRef, EventEmitter, inject, input, OnInit, Output } from "@angular/core";
import { Chart } from "chart.js/auto";
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";

import { Language } from "../../../constants/enums";
import { TpDetailedSectorsResponse } from "../../../models";
import { LanguageService } from "../../../services";

@Component({
  selector: "adaa-tp-sectors",
  standalone: true,
  imports: [],
  templateUrl: "./tp-sectors.component.html",
  styleUrl: "./tp-sectors.component.scss",
})
export class TpSectorsComponent implements OnInit, AfterViewInit {
  sectors = input.required<TpDetailedSectorsResponse>();
  @Output() cardClicked = new EventEmitter<number>();

  private _elementRef = inject(ElementRef);
  private _languageService = inject(LanguageService);

  public ngOnInit(): void {
    //THIS CHART IS NOT FROM CHARTJS THATS WHY YOU NEED TO REGISTER IT
    //THE CONIFG IS HERE BEACUSE IT'S NOT SIMILAR TO THE REST OF THE CHARTS
    Chart.register(TreemapController, TreemapElement);
  }

  public ngAfterViewInit(): void {
    const data = this.sectors().counts;
    const isRTL = this._languageService.current() === Language.Arabic;
    const cardClicked = this.cardClicked;

    const ctx = this._elementRef.nativeElement.querySelector(`#tpSectors`);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const tpSectors = new Chart(ctx, {
      type: "treemap",
      data: {
        datasets: [
          {
            rtl: isRTL,
            tree: this.sectors().counts,
            key: "count",
            data: [],
            borderWidth: 1,
            backgroundColor(e) {
              if (e.dataIndex == undefined) e.dataIndex = 0;
              return data[e.dataIndex] ? data[e.dataIndex].color : "";
            },
            labels: {
              display: true,
              color: "#ffffff",
              font: {
                weight: "bold",
                family: "Open Sans, NotoKufiArabic",
              },

              formatter(e) {
                if (e.dataIndex == undefined) e.dataIndex = 0;
                //Using AdaaHelper will break it
                return !isRTL ? data[e.dataIndex].nameEN : data[e.dataIndex].nameAE;
              },
            },
          },
        ],
      },
      options: {
        onClick(_event, element) {
          if (element[0] && data[element[0].index]) cardClicked.emit(data[element[0].index].id);
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            rtl: isRTL,
            bodyFont: {
              weight: "bold",
              family: "Open Sans, NotoKufiArabic",
            },
            callbacks: {
              title() {
                return "";
              },
              label(e) {
                if (e.dataIndex == undefined) e.dataIndex = 0;

                return ` ${!isRTL ? data[e.dataIndex].nameEN : data[e.dataIndex].nameAE}: ${data[e.dataIndex].count}`;
              },
            },
          },
        },
      },
    });
  }
}
