<div class="row">
    <div class="col-12 bg-white rounded-1 shadow-sm border border-1 objects-tree-container">
        @for (branch of tree(); track branch) {
            <div class="objects-tree-list print-fh">
                <div class="accordion" [id]="'branch-' + branch.level">
                    @for (node of branch.nodes; track $index) {
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button
                                    class="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    aria-expanded="true"
                                    [attr.data-bs-target]="'#node-' + node.type"
                                    [attr.aria-controls]="'node-' + node.type"
                                >
                                    {{ structureViewService.getTypeLabel(node.type) }}
                                </button>
                            </h2>
                            <div [id]="'node-' + node.type" class="accordion-collapse collapse show">
                                <div class="accordion-body">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                @for (col of columns(); track col) {
                                                    <th [style.width]="col.width" [ngClass]="col.alignment ? 'align-' + col.alignment : 'align-C'">
                                                        {{ col.title | translate }}
                                                    </th>
                                                }
                                            </thead>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                    @for (row of node.data; track row; let i = $index) {
                                        <adaa-objects-tree-row
                                            [data]="row"
                                            [columns]="columns()"
                                            [isEven]="i % 2 === 0"
                                            [periodId]="periodId()"
                                            [level]="branch.level"
                                            (click)="selectRow(row, branch.level)"
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
</div>
