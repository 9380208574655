import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  input,
  Output,
  signal,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { MainResponseModel } from "../../../models";
import { UserService } from "../../../services";
import { EntityChangeDetectionComponent } from "./entity-change-detection.component";
import { PlanChangeDetectionComponent } from "./plan-change-detection.component";

export * from "./entity-change-detection.component";
export * from "./plan-change-detection.component";

@Component({
  selector: "adaa-entity-plan-change-detection",
  standalone: true,
  template: `
    <ng-container #page></ng-container>
    @if (isReady() && !ignoreDetection()) {
      @if (!ignoreEntityDetection()) {
        <adaa-entity-change-detection [entityId]="entityId()" (continue)="isEntityOk.set(true); renderPage()" />
      }

      @if (!ignorePlanDetection()) {
        <adaa-plan-change-detection [planId]="planId()" (continue)="isPlanOk.set(true); renderPage()" />
      }
    }
  `,
  imports: [EntityChangeDetectionComponent, PlanChangeDetectionComponent],
})
export class EntityPlanChangeDetectionComponent implements AfterViewInit {
  private readonly _router = inject(Router);
  private readonly _userService = inject(UserService);

  ignoreDetection = input<boolean>(false);
  ignorePlanDetection = input<boolean>(false);
  ignoreEntityDetection = input<boolean>(false);
  entityUserCheck = input<boolean>(false);
  redirectionUrl = input<string | undefined>();
  request$ = input.required<Observable<unknown>>();
  template = input.required<TemplateRef<unknown>>();

  isReady = signal<boolean>(false);
  entityId = signal<number | null>(null);
  planId = signal<number | null>(null);
  pageData = signal<unknown>(null);
  isPlanOk = signal<boolean>(false);
  isEntityOk = signal<boolean>(false);
  preventRender = signal<boolean>(false);

  @Output() continue = new EventEmitter<never>();

  @ViewChild("page", { read: ViewContainerRef }) page!: ViewContainerRef;

  readonly #responseAsArray = (res: unknown) => res as MainResponseModel[];
  readonly #responseAsObject = (res: unknown) => res as MainResponseModel;

  public ngAfterViewInit() {
    if (!this._entityUserCheck()) {
      const url = this.redirectionUrl();
      this._router.navigateByUrl(url ?? "/console");
      return;
    }

    if (this.ignoreDetection()) {
      this.page.createEmbeddedView(this.template());
      this.continue.emit();
      return;
    }

    if (this.ignorePlanDetection()) this.isPlanOk.set(true);
    if (this.ignoreEntityDetection()) this.isEntityOk.set(true);

    this.request$().subscribe({
      next: (res) => {
        if (Array.isArray(res)) {
          this._handleArrayResponse(res);
        } else {
          this._handleObjectResponse(res);
        }
      },
      complete: () => {
        this.isReady.set(true);
      },
    });
  }

  public renderPage() {
    if (this.preventRender()) return;

    if (this.isEntityOk() && this.isPlanOk()) {
      this.page.createEmbeddedView(this.template());
      this.continue.emit(this.pageData() as never);
    }
  }

  private _entityUserCheck() {
    const user = this._userService.currentUser();
    if (user?.entityId === Constants.CONSTANT_PMO_ID) return true;
    if (!this.entityUserCheck()) return true;
    if (!this.entityId()) return true;
    if (!user?.entityId) return true;
    return this.entityId() === user?.entityId;
  }

  private _handleArrayResponse(res: unknown) {
    const response = this.#responseAsArray(res);

    if (response.some(({ responseData }) => !AdaaHelper.isDefined(responseData))) {
      this.preventRender.set(true);
      this._router.navigateByUrl(this.redirectionUrl() ?? "/console");
      return;
    }

    for (const { responseCode, inError } of response) {
      if (inError && +responseCode === Constants.CONSTANT_ERROR_CODE.FORBIDDEN) {
        this.preventRender.set(true);
        this._router.navigate([this.redirectionUrl() ?? "/403"]);
        return;
      }
    }

    const { entityId, planId } = response[0].responseData as { entityId: number; planId: number };

    this.entityId.set(entityId);
    this.planId.set(planId);
    this.pageData.set(res);
  }

  private _handleObjectResponse(res: unknown) {
    const { responseData, inError, responseCode } = this.#responseAsObject(res);

    if (!AdaaHelper.isDefined(responseData)) {
      this.preventRender.set(true);
      this._router.navigateByUrl(this.redirectionUrl() ?? "/console");
      return;
    }

    if (inError && +responseCode === Constants.CONSTANT_ERROR_CODE.FORBIDDEN) {
      this.preventRender.set(true);
      this._router.navigate([this.redirectionUrl() ?? "/403"]);
      return;
    }
    const { entityId, planId } = responseData as { entityId: number; planId: number };

    this.entityId.set(entityId);
    this.planId.set(planId);
    this.pageData.set(res);
  }
}
