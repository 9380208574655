import { DatePipe } from "@angular/common";
import { Component, inject, input, signal } from "@angular/core";
import { NgbCollapseModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { DubaiTimePipe, TranslateTokenPipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../constants/constants";
import type { ExtendedFieldAttributeModelType, OrgUnit, ValueText } from "../../../../models";
import { OrgUnitApiService, UsersApiService } from "../../../../services";
import { isDatePickerInputType, isDropDownInputType, isOrgUnitType, isTreeInputType } from "../utils";

@Component({
  selector: "adaa-ext-fields-view-card",
  styleUrl: "../ext-fields.scss",
  standalone: true,
  imports: [TranslateModule, TranslateTokenPipe, DatePipe, DubaiTimePipe, NgbCollapseModule],
  template: `
    <div class="card w-100 rounded-1 bg-white px-3 mb-2 pt-3 mt-3">
      <div class="card-title d-flex w-100 justify-content-between align-items-center">
        <h4 class="fw-bold m-0">{{ "common.form.label.extended_fields" | translate }}</h4>

        <button
          class="btn btn-sm btn-outline-secondary card-toggler"
          aria-controls="collapseExample"
          [attr.aria-expanded]="show"
          (click)="collapse.toggle()"
        >
          <span class="px-1">
            {{ "common.form.button.view_all" | translate }}
          </span>

          @if (show) {
            <i class="fa-solid fa-folder-plus mx-1"></i>
          } @else {
            <i class="fa-solid fa-folder-minus mx-1"></i>
          }
        </button>
      </div>

      <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="show">
        @for (attr of attrs(); track attr.attrId) {
          <div class="d-flex row attr my-1 border border-1 border-secondary-subtle rounded-1">
            <div class="col-4 attr-label p-2 bg-secondary-subtle">
              <span class="fw-bold">{{ attr | translateToken: "name" }}</span>
            </div>

            <div class="col-8 attr-value p-2">
              @if (isDateValue(attr.dataType)) {
                {{ attr.valueDate! | dubaiTime | date: datetimeFormat }}
              } @else if (isNumberValue(attr.dataType)) {
                @if (isOrgUnit(attr.dataType)) {
                  {{ getOrgUnit(attr.valueNumber!) | translateToken: "name" }}
                } @else if (isUser(attr.dataType)) {
                  {{ getUser(attr.valueNumber!)?.text }}
                } @else {
                  {{ getTextMapping(attr) | translateToken: "lovText" }}
                }
                {{ attr | translateToken: "valueText" }}
              } @else {
                {{ attr | translateToken: "valueText" }}
              }
            </div>
          </div>
        }
      </div>
    </div>
  `,
})
export class ExtFieldsViewCardComponent {
  private readonly _orgUnitApiService = inject(OrgUnitApiService);
  private readonly _translateService = inject(TranslateService);
  private readonly _usersApiService = inject(UsersApiService);

  attrs = input<ExtendedFieldAttributeModelType[]>([]);

  orgUnits = signal<OrgUnit[]>([]);
  usersOptions = signal<ValueText[]>([]);

  readonly datetimeFormat = Constants.normalDate;

  readonly isOrgUnit = (dataType: number) => isOrgUnitType(dataType);
  readonly isUser = (dataType: number) => isOrgUnitType(dataType);
  readonly isDateValue = (dataType: number) => isDatePickerInputType(dataType);
  readonly getUser = (id: number) => this.usersOptions().find(({ value }) => value === id);
  readonly getOrgUnit = (value: number) => this.orgUnits().find(({ id }) => value === id);
  readonly getTextMapping = (attr: ExtendedFieldAttributeModelType) => {
    return (attr.libExtAttrLovs ?? []).find(({ id }) => attr.valueNumber === id);
  };
  readonly isNumberValue = (dataType: number) => {
    return isDropDownInputType(dataType) || isTreeInputType(dataType) || isOrgUnitType(dataType);
  };

  show = true;

  public ngOnInit() {
    this._getOrgUnits();
    this._getUsers();
  }

  private _getOrgUnits() {
    this._orgUnitApiService.getAllByEntity(Number(AdaaHelper?.entity?.id)).subscribe({
      next: (res) => {
        this.orgUnits.set(res.responseData ?? []);
      },
    });
  }

  private _getUsers() {
    this._usersApiService.getOwnersPerEntity(Number(AdaaHelper?.entity?.id)).subscribe({
      next: (res) => {
        this.usersOptions.set([
          ...AdaaHelper.setDropdownArray(res.responseData ?? [], "id", AdaaHelper.getFieldLanguage("name")),
        ]);
      },
    });
  }
}
