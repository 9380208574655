import { inject, Injectable } from "@angular/core";

import type { MainResponseModel } from "../models";
import type { ValidatorModelType } from "../models/validator.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class ValidatorApiService {
  private _mainService = inject(MainService);

  public searchByKey(key: string) {
    const url = `validator/getbysearchkeyv2/${key}`;

    return this._mainService.sendRequest<MainResponseModel<ValidatorModelType>>({
      url,
      method: "GET",
      loader: true,
    });
  }
}
