<div class="me-5">
    <adaa-chart-action-buttons
        [elementId]="''"
        [elementIds]="elementIds()"
        [chartTitles]="chartTitles()"
        [extraContent]="extraContent()"
        [enableDownloadImage]="false"
    ></adaa-chart-action-buttons>
</div>

<div class="d-flex align-items-stretch justify-content-center my-1 flex-wrap gov-enabler-pillars--container">
    @for (pillar of pillars(); track pillar.id; let index = $index) {
        <div class="card gov-enabler-pillars--card my-2">
            <div class="card-title text-center py-2">
                <h5 class="fw-bold">{{ pillar | translateToken: "pillarName" }}</h5>
            </div>

            <div class="card-body d-flex w-100 justify-content-center py-0 position-relative flex-grow-0 flex-shrink-0">
                <adaa-pillar-performance-graph #pillarPerfGraph [data]="pillar" [chartId]="'enablerChart' + index" [periodId]="period().period.id" />
            </div>

            <div class="card-footer flex-grow-1">
                <adaa-pillar-ekpi-info
                    [ekpiList]="pillar.ekpiList"
                    (kpiListContent)="setContent($event)"
                    (labelClicked)="labelClicked(pillarPerfGraph, $event)"
                    (graphClicked)="graphClicked($event)"
                />
            </div>
        </div>
    }
</div>
