import { Pipe, PipeTransform } from "@angular/core";

import { AdaaHelper } from "../utils";

@Pipe({
  name: "currencyValue",
  pure: false,
  standalone: true,
})
export class CurrencyValuePipe implements PipeTransform {
  public transform(value: number | undefined, shortcut: boolean = false, nullSymbol: string = "-"): string {
    const ammount = AdaaHelper.amountToString(value);
    return !ammount ? nullSymbol : `${ammount} ${AdaaHelper.getCurrencySymbol(value, shortcut)}`;
  }
}
