import { Component, EventEmitter, input, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { TpOwnerResponse } from "../../../models";
import { PictureComponent, ProgressIndicatorComponent } from "../../general";

@Component({
  selector: "adaa-tp-owner",
  standalone: true,
  imports: [TranslateModule, TranslateTokenPipe, ProgressIndicatorComponent, PictureComponent],
  templateUrl: "./tp-owner.component.html",
  styleUrl: "./tp-owner.component.scss",
})
export class TpOwnerComponent {
  owner = input.required<TpOwnerResponse>();

  @Output() cardClicked = new EventEmitter<number[]>();
}
