<div class="modal-header">
    <h4 class="modal-title">
        {{
            (pageMode === PageMode.create
                ? "workflow.create_rule_trigger"
                : pageMode === PageMode.edit
                  ? "workflow.edit_rule_trigger"
                  : "workflow.view_rule_trigger"
            ) | translate
        }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body" [formGroup]="ruleForm">
    <div class="row">
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                formControlName="nameAE"
                controlName="nameAE"
                [label]="'common.form.label.nameAE' | translate"
                [required]="true"
                [invalid]="submited && ruleForm.get('nameAE')?.invalid!"
            />
        </div>
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                formControlName="nameEN"
                controlName="nameEN"
                [required]="true"
                [label]="'common.form.label.nameEN' | translate"
                [invalid]="submited && ruleForm.get('nameEN')?.invalid!"
            />
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-xs-12">
            <adaa-form-dropdown
                formControlName="workflowTemplateId"
                controlName="workflowTemplateId"
                [required]="true"
                [clearable]="true"
                [options]="templates()"
                [label]="'workflow.workflow_template' | translate"
                [invalid]="submited && ruleForm.get('workflowTemplateId')?.invalid!"
                appendTo=".modal-content"
            />
        </div>
        <div class="col-sm-6 col-xs-12">
            <adaa-form-dropdown
                formControlName="workflowRuleTypeId"
                controlName="workflowRuleTypeId"
                [required]="true"
                [clearable]="true"
                [options]="rules()"
                [label]="'workflow.rule_type' | translate"
                [invalid]="submited && ruleForm.get('workflowRuleTypeId')?.invalid!"
                appendTo=".modal-content"
                (inputChanges)="ruleTypeChanged($event)"
            />
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <adaa-form-dropdown
                formControlName="wfConfigId"
                controlName="wfConfigId"
                [label]="'workflow.configuration' | translate"
                [clearable]="true"
                [options]="configurations()"
                [invalid]="submited && ruleForm.get('wfConfigId')?.invalid!"
                appendTo=".modal-content"
            />
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 col-xs-12">
            <adaa-form-dropdown
                formControlName="field"
                controlName="field"
                appendTo=".modal-content"
                [label]="'workflow.field' | translate"
                [clearable]="true"
                [options]="fieldTypes()"
                [invalid]="submited && ruleForm.get('field')?.invalid!"
                [required]="isFieldRequired"
            />
        </div>
        <div class="col-sm-4 col-xs-12">
            <adaa-form-dropdown
                formControlName="operator"
                controlName="operator"
                appendTo=".modal-content"
                [label]="'workflow.operator' | translate"
                [clearable]="true"
                [options]="operatorOptions()"
                [invalid]="submited && ruleForm.get('operator')?.invalid!"
                [required]="isOperatorAndValueRequired"
            />
        </div>
        <div class="col-sm-4 col-xs-12">
            <adaa-form-input
                formControlName="value"
                controlName="value"
                [label]="'workflow.value' | translate"
                [invalid]="submited && ruleForm.get('value')?.invalid!"
                [required]="isOperatorAndValueRequired"
            />
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ "common.form.button.close" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        {{ "common.form.button.save" | translate }}
    </button>
</div>
