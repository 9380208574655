@if (!disableTableActions()) {
    <div class="row mb-1" [attr.aria-hidden]="disableTableActions()">
        <div class="col-md-6 col-sm-12 col-xs-12 d-flex align-items-center">
            @if (titleHeader) {
                @if (!useSmHeader) {
                    <h4 class="fw-bold m-0">
                        {{ titleHeader | translate }}
                        @if (required) {
                            <span class="form-required text-danger fw-bold">*</span>
                        }
                    </h4>
                } @else {
                    <h5 class="fw-bold m-0">
                        {{ titleHeader | translate }}
                        @if (required) {
                            <span class="form-required text-danger fw-bold">*</span>
                        }
                    </h5>
                }
            }
        </div>

        <div class="col-md-6 col-sm-12 col-xs-12 d-flex flex-row-reverse">
            <adaa-data-table-buttons
                [enableAdd]="enableAdd && isNotArchived"
                [enableFilter]="enableFilter"
                [enablePrint]="enablePrint"
                [enableSettings]="enableSettings"
                [enableExport]="enableExport"
                [createPermission]="createPermission()"
                [customTableButtons]="customTableButtons"
                [isFilterApplied]="isFilterApplied"
                (addNewClicked)="addNewClicked.emit()"
                (printClicked)="printClicked($event)"
                (filterClicked)="filterClicked($event)"
                (settingsClicked)="settingsClicked($event)"
                (exportClicked)="exportClicked($event)"
                (buttonClicked)="buttonClicked.emit($event)"
            >
            </adaa-data-table-buttons>
        </div>
    </div>
}

<div [id]="generatedID" class="table-responsive shadow rounded">
    <table class="table {{ tableClasses }} ">
        <thead>
            <tr class="dndList adaa--th-row" dndDropzone [dndHorizontal]="true" (dndDrop)="onDrop($event)">
                <div class="thDropPlaceHolder dropPlaceHolder" dndPlaceholderRef></div>
                @if (tableWithChildren()) {
                    <th></th>
                }

                @for (col of tableColumns; track col.id; let i = $index) {
                    <th
                        [dir]="languageService.direction()"
                        class="adaa--th-col"
                        dndEffectAllowed="move"
                        scope="col"
                        [ngClass]="col.alignment ? 'align-' + col.alignment : 'align-C'"
                        [resizable]="true"
                        [index]="tableWithChildren() ? i + 1 : i"
                        [dndDraggable]="col"
                        [dndDisableDragIf]="resizingInProgress"
                        [style.min-width]="
                            col.width ? (col.width * innerWidth) / 2400 + 'px' : tableColumns.length > 1 ? (150 * innerWidth) / 2300 + 'px' : '900px'
                        "
                        (getResizeResult)="columnResized($event, col)"
                        (resizing)="resizingInProgress = $event"
                        (currentWidth)="col.width = $event"
                    >
                        <span
                            [sortable]="col.fieldName"
                            [direction]="currentSort && currentSort.column === col.fieldName ? currentSort.direction : ''"
                            (sort)="onSort($event)"
                        >
                            {{ adaaHelper.getItemValueByToken(col, "name") }}

                            <fa-icon class="sortUp m-1" [icon]="['fas', 'sort-up']"></fa-icon>
                            <fa-icon class="sortDown m-1" [icon]="['fas', 'sort-down']"></fa-icon>
                        </span>
                    </th>
                }

                @if (enableActionsCell) {
                    <th class="adaa--th-actions align-C">
                        {{ "common.tables.actions" | translate }}
                    </th>
                }
            </tr>
        </thead>

        @if (tableData && tableData.length > 0) {
            <tbody>
                @for (data of tableData; let i = $index; track i) {
                    <tr
                        class="adaa--td-row table-danger"
                        [class.table-danger]="rowHasIssue(data, +i)"
                        [class.row-selected]="isRowSelected(data)"
                        [class.selectable]="enableSelection"
                        [class.pointer]="enableSelection"
                        (click)="rowClicked(data)"
                    >
                        @if (tableWithChildren()) {
                            @if (data[childKey()] && data[childKey()].length > 0) {
                                <td>
                                    @if (!data["expanded"]) {
                                        <fa-icon
                                            class="pointer m-1"
                                            [icon]="adaaHelper.getCurrentLang() === language.Arabic ? ['fas', 'angle-left'] : ['fas', 'angle-right']"
                                            (click)="expandCollapseChild(data, true)"
                                        ></fa-icon>
                                    } @else {
                                        <fa-icon
                                            class="pointer m-1"
                                            [icon]="['fas', 'angle-down']"
                                            (click)="expandCollapseChild(data, false)"
                                        ></fa-icon>
                                    }
                                </td>
                            } @else {
                                <td></td>
                            }
                        }

                        @for (col of tableColumns; track col.id) {
                            <td
                                class="adaa--td-col"
                                [ngClass]="col.alignment ? 'align-' + col.alignment : 'align-C'"
                                [style.min-width]="
                                    col.width
                                        ? (col.width * innerWidth) / 2400 + 'px'
                                        : tableColumns.length > 1
                                          ? (150 * innerWidth) / 2400 + 'px'
                                          : '900px'
                                "
                            >
                                <adaa-data-table-cell
                                    [configKey]="configKey"
                                    [column]="col"
                                    [data]="data"
                                    [tableInputs]="tableInputs"
                                    (inputChanged)="inputChanged($event)"
                                ></adaa-data-table-cell>
                            </td>
                        }

                        @if (enableActionsCell) {
                            <td class="adaa--td-actions align-C">
                                <adaa-data-table-actions
                                    class="d-flex justify-content-center"
                                    [data]="data"
                                    [enableConnectionsAction]="enableConnectionsAction"
                                    [enableEditAction]="enableEditAction && isNotArchived"
                                    [enableDeleteAction]="enableDeleteAction && isNotArchived"
                                    [enableViewAction]="enableViewAction"
                                    [hideViewRules]="hideViewRules"
                                    [hideEditRules]="hideEditRules"
                                    [hideConnectionsRules]="hideConnectionsRules"
                                    [hideDeleteRules]="hideDeleteRules"
                                    [disableViewRules]="disableViewRules"
                                    [disableEditRules]="disableEditRules"
                                    [disableConnectionsRules]="disableConnectionsRules"
                                    [disableDeleteRules]="disableDeleteRules"
                                    [customActionButtons]="customActionButtons"
                                    [viewPermission]="viewPermission()"
                                    [managePermission]="managePermission()"
                                    (actionClicked)="actionClicked.emit({ event: $event, data: data, index: +i })"
                                    (customClicked)="actionClicked.emit({ event: $event, data: data, index: +i })"
                                ></adaa-data-table-actions>
                            </td>
                        }
                    </tr>

                    @if (data["expanded"]) {
                        @for (child of data[childKey()]; track $index) {
                            <tr class="child-row">
                                <td></td>

                                @for (col of tableColumns; track col.id) {
                                    <td class="adaa--td-col" [ngClass]="col.alignment ? 'align-' + col.alignment : 'align-C'">
                                        <adaa-data-table-cell [column]="col" [data]="child" [configKey]="configKey"></adaa-data-table-cell>
                                    </td>
                                }

                                @if (enableChildActionsCell()) {
                                    <td>
                                        <adaa-data-table-actions
                                            [data]="child"
                                            [enableEditAction]="enableEditChildAction()"
                                            [enableViewAction]="enableViewChildAction()"
                                            [enableDeleteAction]="enableDeleteChildAction()"
                                            [enableConnectionsAction]="enableConnectionsChildAction()"
                                            [viewPermission]="viewPermission()"
                                            [managePermission]="managePermission()"
                                            (actionClicked)="childClicked.emit({ event: $event, data: child, index: +i })"
                                        ></adaa-data-table-actions>
                                    </td>
                                } @else {
                                    <td></td>
                                }
                            </tr>
                        }
                    }
                }
            </tbody>
        } @else {
            <tr class="m-0">
                <td [attr.colspan]="tableColumns.length + 1">
                    {{ "common.tables.no_info" | translate }}
                </td>
            </tr>
        }
    </table>
</div>

<div class="d-flex align-items-center justify-content-end mt-2 mb-1 adaa--pagination {{ tableClasses }}">
    @if (!disablePagination && totalRows) {
        <ngb-pagination [collectionSize]="totalRows" [(page)]="currentPage" [pageSize]="limit" [maxSize]="5" (click)="onPageChange($event)">
        </ngb-pagination>
    }
</div>
