import { HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { filter, map } from "rxjs";

import { AdaaBoolean } from "../constants/enums";
import type {
  MainResponseModel,
  RelatedObjectDetails,
  UserEntitiesResponsibleFor,
  UserGroup,
  UserModelType,
  UserResponsiblitiesRequest,
  UserStatusDTO,
} from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class UsersApiService {
  private _mainService = inject(MainService);
  private _translateService = inject(TranslateService);

  public hasSysAdminRole() {
    return this._mainService.sendRequest<MainResponseModel<boolean>>({
      method: "GET",
      url: `users/hasSysAdminRole`,
    });
  }

  public getUserInfo() {
    const headers = new HttpHeaders();
    headers.append("entityId", "");

    return this._mainService.sendRequest<MainResponseModel<UserModelType>>({
      method: "GET",
      url: `users/getUserInfo`,
      headers,
    });
  }

  public getHashedSessionId(sessionId: string) {
    return this._mainService.sendRequest<MainResponseModel<string>>({
      method: "GET",
      url: `users/hashSessionId/${sessionId}`,
    });
  }

  public resetLoginAttempts(userId: number) {
    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "GET",
      url: `users/resetLoginAttempts/${userId}`,
    });
  }

  public updateState(userStatus: UserStatusDTO) {
    return this._mainService.sendRequest<MainResponseModel>({
      method: "POST",
      url: `users/updateState`,
      data: userStatus,
    });
  }

  public delete(id: number) {
    return this._mainService.sendRequest<MainResponseModel>({
      method: "DELETE",
      url: `users/deleteById/${id}`,
    });
  }

  public getPermissionDetail(itemTypeId: number, entityId: number | undefined, orgUnitId: number | undefined) {
    const url = `users/getPermissionDetail`;

    const qs = new URLSearchParams();
    if (itemTypeId) qs.append("itemTypeId", itemTypeId.toString());
    if (entityId) qs.append("entityId", entityId.toString());
    if (orgUnitId) qs.append("orgUnitId", orgUnitId.toString());

    return this._mainService.sendRequest<MainResponseModel<RelatedObjectDetails[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getUser(id: number) {
    return this._mainService.sendRequest<MainResponseModel<UserModelType>>({
      method: "GET",
      url: `users/getById/${id}`,
    });
  }

  public getEntitiesResponsible(id: number) {
    return this._mainService.sendRequest<MainResponseModel<UserEntitiesResponsibleFor[]>>({
      method: "GET",
      url: `users/getEntitiesResponsibleFor/${id}`,
    });
  }

  public getOwnersPerEntity(entityId: number, isActive = true) {
    const notActive = ` (${this._translateService.instant("common.form.label.inactive")})`;

    return this._mainService
      .sendRequest<MainResponseModel<UserModelType[]>>({
        method: "GET",
        url: `users/getAllByEntityId/${entityId}?isActive=${isActive}`,
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => ({
          ...res,
          responseData: res.responseData.map((e) => ({
            ...e,
            nameEN: e.enabled === AdaaBoolean.N ? `${e.nameEN}${notActive}` : e.nameEN,
            nameAE: e.enabled === AdaaBoolean.N ? `${e.nameAE}${notActive}` : e.nameAE,
          })),
        }))
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public saveUser(action: "create" | "update", data: any) {
    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      data,
      url: `users/${action}`,
    });
  }

  public saveUserResponsiblities(data: UserResponsiblitiesRequest) {
    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "POST",
      data,
      url: `users/updateEntitiesResponsibleFor`,
    });
  }

  public getOwnersPerOrgUnit(orgUnitId: number) {
    const notActive = ` (${this._translateService.instant("common.form.label.inactive")})`;

    return this._mainService
      .sendRequest<MainResponseModel<UserModelType[]>>({
        method: "GET",
        url: `users/getAllByOrgunit?orgunitId=${orgUnitId}`,
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => ({
          ...res,
          responseData: res.responseData.map((e) => ({
            ...e,
            nameEN: e.enabled === AdaaBoolean.N ? `${e.nameEN}${notActive}` : e.nameEN,
            nameAE: e.enabled === AdaaBoolean.N ? `${e.nameAE}${notActive}` : e.nameAE,
          })),
        }))
      );
  }

  public getAll(entitiesIds?: number[]) {
    const url = `users/getall`;

    const qs = new URLSearchParams();
    if (entitiesIds) entitiesIds.forEach((e) => qs.append("entityId", e.toString()));

    return this._mainService.sendRequest<MainResponseModel<UserModelType[]>>({
      method: "POST",
      url: `${url}?${qs.toString()}`,
      data: {},
    });
  }

  public getAllUserGroupsByUserId(id: number) {
    return this._mainService.sendRequest<MainResponseModel<UserGroup[]>>({
      method: "POST",
      url: `users/getAllUserGroupsByUserId?userId=${id}`,
      data: {},
    });
  }
}
