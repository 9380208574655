/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, Injectable, signal } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import { OpenedTreeNode, PropTypeModelType, SelectedPeriodModelType } from "../models";

@Injectable({
  providedIn: "root",
})
export class StructureViewService {
  private _translateService = inject(TranslateService);
  private _router = inject(Router);

  types = signal<PropTypeModelType[]>([]);
  openedNodes: number[] = [];
  openedTreeNodes: OpenedTreeNode[] = [];
  selectedLayout = signal<number>(1);
  selectedPeriod = signal<Pick<SelectedPeriodModelType, "period"> | undefined>(undefined);

  public reset() {
    this.types.set([]);
    this.openedNodes = [];
    this.openedTreeNodes = [];
    this.selectedLayout.set(1);
    this.selectedPeriod.set(undefined);
  }

  public getAlignment(alignment: "C" | "R" | "L" | undefined) {
    return alignment ? "align-" + alignment : "align-C";
  }

  public getColor(data: any, colorKey?: string): string {
    if (!colorKey || !data) return "";
    return Constants.COLOR_CODES[data[colorKey]];
  }

  public itemTranslationById(data: any, key: string): string {
    if (!data) return "";

    switch (data[key] as number) {
      case Constants.CONSTANT_OBJECTIVETYPE:
        return this._getObjectiveTypeLabel(data);
      case Constants.CONSTANT_INITIATIVE:
        return this._getProjectTypeLabel(data);
      case Constants.CONSTANT_ACTIVITYTYPE:
        return this._getActivityTypeLabel(data);
      default:
        return this.getTypeLabel(data[key]);
    }
  }

  public getTypeLabel(type: number) {
    const typeLabel = this.types().find((e) => type === e.id);
    return typeLabel ? AdaaHelper.getItemValueByToken(typeLabel, "name") : "";
  }

  public viewClicked(data: any, valueKey?: string): void {
    if (!valueKey || !data) return;

    const route = this._getItemUrl(data, valueKey);
    if (!AdaaHelper.isDefined(route)) return;

    this._router.navigateByUrl(route);
  }

  private _getObjectiveTypeLabel(data: any): string {
    if (AdaaHelper.isPlanArchived()) return this._translateService.instant("objectives.objective");

    switch (data.objectiveType) {
      case Constants.CONSTANT_MAIN_OBJECTIVE:
        return this._translateService.instant("breadcrumbs.objectives.main");
      case Constants.CONSTANT_STRATEGIC_OBJECTIVE:
        return this._translateService.instant("breadcrumbs.objectives.strategic");
      case Constants.CONSTANT_TRANSFORMATIONAL_OBJECTIVE:
        return this._translateService.instant("objectives.transformational_objective");
      case Constants.CONSTANT_ENABLER_OBJECTIVE:
        return this._translateService.instant("objectives.enabler_objective");
      default:
        return this._translateService.instant("objectives.objective");
    }
  }

  private _getProjectTypeLabel(data: any): string {
    if (AdaaHelper.isPlanArchived()) return this._translateService.instant("initiatives.initiatives");

    switch (data.initiativeType) {
      case Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE:
        return this._translateService.instant("initiatives.regular_task");
      case Constants.CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE:
        return this._translateService.instant("initiatives.strategic_project");
      default:
        return "";
    }
  }

  private _getActivityTypeLabel(data: any): string {
    if (AdaaHelper.isPlanArchived()) return this._translateService.instant("activities.activity");

    switch (data.activityType) {
      case Constants.CONSTANT_OPERATIONAL_ACTIVITYTYPE:
        return this._translateService.instant("activities.operational");
      case Constants.CONSTANT_STRATEGIC_ACTIVITYTYPE:
        return this._translateService.instant("activities.strategic");
      default:
        return "";
    }
  }

  private _getItemUrl(data: any, valueKey: string): string {
    let url = "";
    switch (data[valueKey]) {
      case Constants.CONSTANT_OBJECTIVETYPE:
        url = this._getObjectiveUrl(data);
        break;
      case Constants.CONSTANT_INITIATIVE:
        url = this._getProjectUrl(data);
        break;
      case Constants.CONSTANT_ACTIVITYTYPE:
        url = this._getActivityUrl(data);
        break;
      case Constants.KPI_TYPE.SKPI:
        url = `/console/kpi/skpi/${data.id}`;
        break;
      case Constants.KPI_TYPE.NKPI:
        url = `/console/kpi/nkpi/${data.id}`;
        break;
      case Constants.KPI_TYPE.NTKPI:
        url = `/console/kpi/ntkpi/${data.id}`;
        break;
      case Constants.KPI_TYPE.DTKPI:
        url = `/console/kpi/dtkpi/${data.id}`;
        break;
      case Constants.KPI_TYPE.UAE:
        url = `/console/kpi/uae/${data.id}`;
        break;
      case Constants.KPI_TYPE.OPM:
        url = `/console/kpi/opm/${data.id}`;
        break;
      case Constants.KPI_TYPE.EKPI:
        url = `/console/kpi/ekpi/${data.id}`;
        break;
      case Constants.KPI_TYPE.SRVKPI:
        url = `/console/kpi/srvkpi/${data.id}`;
        break;
      case Constants.KPI_TYPE.MOKPI:
        url = `/console/kpi/mokpi/${data.id}`;
        break;
      case Constants.KPI_TYPE.MTKPI:
        url = `/console/kpi/mtkpi/${data.id}`;
        break;
      case Constants.KPI_TYPE.GSKPI:
        url = `/console/kpi/gskpi/${data.id}`;
        break;
      case Constants.CONSTANT_MAINSERVICE:
        url = `/console/services/main-service/${data.id}`;
        break;
      case Constants.CONSTANT_VARSERVICE:
        url = `/console/services/var-service/${data.id}`;
        break;
      case Constants.CONSTANT_AUXSERVICE:
        url = `/console/services/aux-service/${data.id}`;
        break;
      case Constants.GOV_SCORES_SECTOR:
        url = `/console/government-scores/sectors/${data.id}`;
        break;
      case Constants.GOV_SCORES_PILLAR:
        url = `/console/government-scores/pillars/${data.parentObjectId}`;
        break;
      case Constants.GOV_SCORES_ENTITY:
        url = `/console/government-scores/pillars/${data.id}`;
        break;
      case Constants.GOV_SCORES_SCOPE:
        url = `/console/government-scores/scopes/${data.id}`;
        break;
      default:
        break;
    }
    return url;
  }

  private _getObjectiveUrl(data: any): string {
    let url: string;

    if (AdaaHelper.isPlanArchived()) {
      url = `/console/objectives/${data.id}`;
      return url;
    }

    switch (data.objectiveType) {
      case Constants.CONSTANT_MAIN_OBJECTIVE:
        url = `/console/objectives/main-objective/${data.id}`;
        break;
      case Constants.CONSTANT_STRATEGIC_OBJECTIVE:
        url = `/console/objectives/strategic-objective/${data.id}`;
        break;
      case Constants.CONSTANT_TRANSFORMATIONAL_OBJECTIVE:
        url = `/console/pmo-objectives/transformational-objective/${data.id}`;
        break;
      case Constants.CONSTANT_ENABLER_OBJECTIVE:
        url = `/console/objectives/strategic-objective/${data.id}`;
        break;
      default:
        url = `/console/objectives/${data.id}`;
        break;
    }

    return url;
  }

  private _getProjectUrl(data: any): string {
    let url: string;

    if (AdaaHelper.isPlanArchived()) {
      url = `/console/initiatives/${data.id}`;
      return url;
    }

    switch (data.initiativeType) {
      case Constants.CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE:
        url = `/console/initiatives/strategic-projects/${data.id}`;
        break;
      case Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE:
        url = `/console/initiatives/regular-tasks/${data.id}`;
        break;
      default:
        url = `/console/initiatives/${data.id}`;
        break;
    }
    return url;
  }

  private _getActivityUrl(data: any): string {
    let url: string;

    if (AdaaHelper.isPlanArchived()) {
      url = `/console/activities/${data.id}`;
      return url;
    }

    switch (data.activityType) {
      case Constants.CONSTANT_OPERATIONAL_ACTIVITYTYPE:
        url = `/console/activities/operational/${data.id}`;
        break;
      case Constants.CONSTANT_STRATEGIC_ACTIVITYTYPE:
        url = `/console/activities/strategic/${data.id}`;
        break;
      default:
        url = `/console/activities/${data.id}`;
        break;
    }

    return url;
  }
}
