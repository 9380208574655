<div [formGroup]="linksForm" class="bg-white shadow-md rounded p-2 mb-2">
    <div class="row">
        <div class="col-12">
            <adaa-form-dropdown-multi
                controlName="subKpisIds"
                formControlName="subKpisIds"
                [searchable]="true"
                [clearable]="true"
                [label]="'government_scores.scopes.sub_kpis' | translate"
                [options]="subKpis()"
                [invalid]="linksForm.get('subKpisIds')?.touched! && linksForm.get('subKpisIds')?.invalid!"
                (inputChanges)="onSubKpiChange($event)"
                (onSearch)="onSearch($event, 'subKpi')"
            />
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <adaa-form-dropdown-multi
                controlName="metricsIds"
                formControlName="metricsIds"
                [searchable]="true"
                [clearable]="true"
                [label]="'government_scores.scopes.metrics' | translate"
                [options]="metrics()"
                [invalid]="linksForm.get('metricsIds')?.touched! && linksForm.get('metricsIds')?.invalid!"
                (inputChanges)="onMetricChange($event)"
                (onSearch)="onSearch($event, 'metric')"
            />
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <adaa-form-dropdown-multi
                controlName="projectsIds"
                formControlName="projectsIds"
                [searchable]="true"
                [clearable]="true"
                [label]="'national_strategies.initiatives' | translate"
                [options]="projects()"
                [invalid]="linksForm.get('projectsIds')?.touched! && linksForm.get('projectsIds')?.invalid!"
                (inputChanges)="onProjectChange($event)"
            />
        </div>
    </div>
</div>
