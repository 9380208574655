<div class="row">
    <div class="col-12">
        <div class="row mb-3">
            <div class="col-md-2 col-sm-4 col-xs-4 d-flex flex-wrap flex-column justify-content-center my-4">
                <h5 class="fw-bold">{{ "national_projects.overall_progress" | translate }}</h5>
                <h6 class="fw-bold my-2">
                    <fa-icon class="text-secondary" [icon]="['fas', 'circle']"></fa-icon>
                    {{ "national_projects.planned" | translate }}
                </h6>
                <h6 class="fw-bold">
                    <fa-icon class="text-primary" [icon]="['fas', 'circle']"></fa-icon>
                    {{ "national_projects.actual" | translate }}
                </h6>
            </div>
            <div class="col-md-1 col-sm-4 col-xs-4 d-flex justify-content-center flex-wrap overflow-auto">
                <adaa-doughnut
                    [amount]="project().progress"
                    [colorId]="project().onTimeProgressColor"
                    [size]="'xxs'"
                    [enableAnimation]="false"
                    [title]="'common.tables.progress' | translate"
                ></adaa-doughnut>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-4 d-flex flex-wrap align-items-center">
                <h6>{{ project() | translateToken: "entityName" }}</h6>
            </div>
            @if (sliderOption) {
                <div class="col-md-7 col-sm-12 col-xs-12">
                    <ngx-slider
                        class="planned"
                        [class.noStart]="!project().plannedStartDate"
                        [class.noEnd]="!project().plannedEndDate"
                        [title]="getTitle(project().plannedStartDate, project().plannedEndDate)"
                        [options]="sliderOption"
                        [value]="project().plannedStartDate"
                        [highValue]="project().plannedEndDate"
                    ></ngx-slider>
                    <ngx-slider
                        class="actual"
                        [class.noStart]="!project().actualStartDate"
                        [class.noEnd]="!project().actualEndDate"
                        [title]="getTitle(project().actualStartDate, project().actualEndDate, true)"
                        [options]="sliderOption"
                        [value]="project().actualStartDate ?? 0"
                        [highValue]="project().actualEndDate ?? 0"
                    ></ngx-slider>
                </div>
            }
        </div>
        <h6 class="bars-title">
            <span>{{ "national_projects.main_executive_milestones" | translate }}</span>
        </h6>
        @for (milestone of milestones(); track milestone; let i = $index) {
            <div class="row mb-3">
                <div class="col-md-2 col-sm-4 col-xs-4 d-flex flex-wrap align-items-center my-4">
                    <span class="fw-bold">
                        <span class="badge rounded-pill bg-primary text-white shadow-sm mx-1">{{ i + 1 }} </span>
                        <span class="pointer" (click)="milestoneClicked.emit(milestone.id)">
                            {{ milestone | translateToken: "name" }}
                        </span>
                    </span>
                </div>
                <div class="col-md-1 col-sm-4 col-xs-4 d-flex justify-content-center flex-wrap overflow-auto">
                    <adaa-doughnut
                        [amount]="milestone.progress ?? 0"
                        [colorId]="milestone.onTimeProgressColor ?? 0"
                        [size]="'xxs'"
                        [enableAnimation]="false"
                        [title]="'common.tables.progress' | translate"
                    ></adaa-doughnut>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-4 d-flex flex-wrap align-items-center">
                    <h6>{{ milestone | translateToken: "entityName" }}</h6>
                </div>
                @if (sliderOption) {
                    <div class="col-md-7 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-md-10 col-sm-12 col-xs-12">
                                <ngx-slider
                                    class="planned"
                                    [class.noStart]="!milestone.plannedStartDate"
                                    [class.noEnd]="!milestone.plannedEndDate"
                                    [title]="getTitle(milestone.plannedStartDate, milestone.plannedEndDate)"
                                    [options]="sliderOption"
                                    [value]="milestone.plannedStartDate ?? 0"
                                    [highValue]="milestone.plannedEndDate ?? 0"
                                ></ngx-slider>
                                <ngx-slider
                                    class="actual"
                                    [class.noStart]="!milestone.actualStartDate"
                                    [class.noEnd]="!milestone.actualEndDate"
                                    [title]="getTitle(milestone.actualStartDate, milestone.actualEndDate, true)"
                                    [options]="sliderOption"
                                    [value]="milestone.actualStartDate ?? 0"
                                    [highValue]="milestone.actualEndDate ?? 0"
                                ></ngx-slider>
                            </div>
                            <div class="col-md-2 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center my-3">
                                <button
                                    class="btn btn-rounded btn-outline-primary rounded-pill"
                                    [title]="'national_projects.comments' | translate"
                                    (click)="mainAchievementsClicked.emit(milestone.id)"
                                >
                                    <fa-icon [icon]="['fas', 'timeline']"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
</div>
