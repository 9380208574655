import { Component, computed, inject, input } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper, useNewDS } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { DataEntryDetails } from "../../../models";

@Component({
  selector: "adaa-kpi-details-data-entry",
  standalone: true,
  imports: [TranslateModule, TranslateTokenPipe],
  templateUrl: "./kpi-details-data-entry.component.html",
  styleUrl: "./kpi-details-data-entry.component.scss",
})
export class KpiDetailsDataEntryComponent {
  private _translateService = inject(TranslateService);

  dataEntryDetails = input.required<DataEntryDetails>();

  formula = computed<string>(() => {
    let formula = this.dataEntryDetails().formula ?? "";

    if (formula === "NOFORMULAV") formula = this._translateService.instant("kpi.NOFORMULAV");
    else {
      formula = this.dataEntryDetails().formula
        ? `<span class="keep-to-left">${this.dataEntryDetails().formula}</span>`
        : "";

      this.dataEntryDetails().metricDetails?.forEach((metric) => {
        formula += `<br /> ${AdaaHelper.getItemValueByToken(metric, "metricName")} - [ ${this._getMetricType(
          metric.metricType
        )} ] - ${AdaaHelper.getItemValueByToken(metric, "dsc")}`;
      });
    }

    return formula;
  });

  readonly constants = Constants;
  readonly useNewDS = () => useNewDS();

  readonly initiativeTypeText = (type: number | undefined) => {
    if (this.useNewDS()) {
      return type === Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE
        ? "initiatives.regular_task"
        : "initiatives.strategic_project";
    }

    return "initiatives.title";
  };

  private _getMetricType(type: string | undefined): string {
    switch (type) {
      case Constants.METRICS_TYPE.GLOBAL:
        return this._translateService.instant("main_types.G");
      case Constants.METRICS_TYPE.LOCAL:
        return this._translateService.instant("main_types.L");
      case Constants.METRICS_TYPE.DIMENSION:
        return this._translateService.instant("main_types.M");
      default:
        return this._translateService.instant("metrics.custom_var");
    }
  }
}
