<div class="m-1" [class.invalid]="invalid">
    <label *ngIf="adaaHelper.isDefinedAndNotEmpty(label)" for="{{ controlId }}" class="form-label">
        {{ label }}
        <span *ngIf="required" class="form-required text-danger fw-bolder">*</span></label
    >

    <div class="input-group">
        <input
            #dateInput
            [id]="controlId"
            class="form-control shadow-sm"
            [placeholder]="placeholder"
            [formControl]="inputControl"
            [class.is-invalid]="invalid"
            ngbDatepicker
            [maxDate]="$any(maxDate)"
            [minDate]="$any(minDate)"
            #picker="ngbDatepicker"
            (dateSelect)="valueChanged($event)"
            readonly="true"
            container="body"
        />
        <button class="btn btn-outline-secondary" (click)="picker.toggle()" type="button" [disabled]="isDisabled">
            <fa-icon [icon]="['fas', 'calendar']"></fa-icon>
        </button>
    </div>
</div>
