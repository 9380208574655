import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import type { AuditTrailDiffModelType, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class AuditTrailApiService {
  private _mainService = inject(MainService);

  public getDifferences(itemId: number) {
    const url = `audittrail/getById/${itemId}`;

    return this._mainService.sendRequest<MainResponseModel<AuditTrailDiffModelType>>({
      url,
      method: "GET",
    });
  }

  public getActionTypes() {
    const url = "audittrail/getActionTypes";

    return this._mainService
      .sendRequest<MainResponseModel<{ id: number; nameAE: string; nameEN: string }[]>>({
        url,
        method: "GET",
      })
      .pipe(filter((res) => !res.inError));
  }

  public getObjectTypes() {
    const url = "audittrail/getObjectTypes";

    return this._mainService
      .sendRequest<MainResponseModel<{ id: number; nameAE: string; nameEN: string }[]>>({
        url,
        method: "GET",
      })
      .pipe(filter((res) => !res.inError));
  }
}
