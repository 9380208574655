<div class="row text-center">
    @if (adaaHelper.isDefinedAndNotEmpty(label)) {
        <div class="col-12">
            <label class="form-label fs-6">
                {{ label | translate }}
            </label>
        </div>
    }
    <div class="col-5">
        @if (adaaHelper.isDefinedAndNotEmpty(notSelectedLabel)) {
            <label class="form-label">
                {{ notSelectedLabel | translate }}
            </label>
        }
        <input #searchNotSelected class="form-control shadow-sm" (keyup)="search(searchNotSelected.value, true)" />
        <ul
            #dndSelectedList
            class="list-group shadow-sm"
            [ngClass]="{ disabled: isDisabled }"
            [dndDropzone]="['dndNotSelectedList']"
            (dndDrop)="onDrop($event)"
        >
            <li class="dropPlaceHolder" dndPlaceholderRef></li>

            @for (option of notSelectedList; track option) {
                <li
                    [dndDraggable]="option"
                    [dndDisableDragIf]="
                        !allowSort ||
                        adaaHelper.isDefinedAndNotEmpty(searchSelected.value) ||
                        adaaHelper.isDefinedAndNotEmpty(searchNotSelected.value)
                    "
                    dndEffectAllowed="move"
                    class="list-group-item fw-bold"
                    [class.active]="hasItem(option.value, true)"
                    (click)="selectItem(option.value, true)"
                >
                    {{ option.text }}
                </li>
            } @empty {
                @if (adaaHelper.isDefinedAndNotEmpty(emptyNotSelectedListLabel)) {
                    <li class="list-group-item fw-bold">{{ emptyNotSelectedListLabel | translate }}</li>
                }
            }
        </ul>
    </div>
    <div class="col-2" style="padding-top: 25px" [class.disable-control]="isDisabled">
        <span class="btn bg-white text-primary shadow-sm select-button" role="button" (click)="selectAll(true)">
            @if (languageService.current() === language.English) {
                <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon>
            } @else {
                <fa-icon [icon]="['fas', 'arrow-circle-left']"></fa-icon>
            }
        </span>

        <span class="btn bg-white text-primary shadow-sm select-button" role="button" (click)="select(true)">
            @if (languageService.current() === language.English) {
                <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
            } @else {
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
            }
        </span>

        <span class="btn bg-white text-primary shadow-sm select-button" role="button" (click)="select()">
            @if (languageService.current() === language.English) {
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
            } @else {
                <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
            }
        </span>

        <span class="btn bg-white text-primary shadow-sm select-button" role="button" (click)="selectAll()">
            @if (languageService.current() === language.English) {
                <fa-icon [icon]="['fas', 'arrow-circle-left']"></fa-icon>
            } @else {
                <fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon>
            }
        </span>
    </div>
    <div class="col-5">
        @if (adaaHelper.isDefinedAndNotEmpty(selectedLabel)) {
            <label class="form-label">
                {{ selectedLabel | translate }}
                @if (required) {
                    <span class="form-required text-danger fw-bolder">*</span>
                }
            </label>
        }

        <input #searchSelected class="form-control shadow-sm" (keyup)="search(searchSelected.value)" />
        <ul
            #dndNotSelectedList
            class="list-group shadow-sm"
            [ngClass]="{ disabled: isDisabled }"
            [dndDropzone]="['dndSelectedList']"
            (dndDrop)="onDrop($event, true)"
        >
            <li class="dropPlaceHolder" dndPlaceholderRef></li>

            @for (option of selectedList; track option) {
                <li
                    [dndDraggable]="option"
                    [dndDisableDragIf]="
                        !allowSort ||
                        adaaHelper.isDefinedAndNotEmpty(searchSelected.value) ||
                        adaaHelper.isDefinedAndNotEmpty(searchNotSelected.value)
                    "
                    dndEffectAllowed="move"
                    class="list-group-item fw-bold"
                    [class.active]="hasItem(option.value)"
                    (click)="selectItem(option.value)"
                >
                    {{ option.text }}
                </li>
            } @empty {
                @if (adaaHelper.isDefinedAndNotEmpty(emptySelectedListLabel)) {
                    <li class="list-group-item fw-bold">{{ emptySelectedListLabel | translate }}</li>
                }
            }
        </ul>
    </div>
</div>
