import { Component, EventEmitter, inject, Input, input, OnInit, Output } from "@angular/core";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faFileExport, faFilter, faGear, faPlus, faPrint } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { PermissionActionDescriptorType } from "../../../../../adaa-types";
import { HasPermissionDirective } from "../../../directives";
import { CustomTableButton } from "../../../models";

@Component({
  selector: "adaa-data-table-buttons",
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule, HasPermissionDirective],
  templateUrl: "./data-table-buttons.component.html",
  styleUrl: "./data-table-buttons.component.scss",
})
export class DataTableButtonsComponent implements OnInit {
  @Input() enableAdd = true;
  @Input() enablePrint = false;
  @Input() enableSettings = true;
  @Input() enableFilter = true;
  @Input() enableExport = true;
  @Input() customTableButtons: CustomTableButton[] = [];
  @Input() isFilterApplied = false;

  createPermission = input.required<PermissionActionDescriptorType>();

  @Output() addNewClicked = new EventEmitter<void>();
  @Output() printClicked = new EventEmitter<boolean>();
  @Output() filterClicked = new EventEmitter<boolean>();
  @Output() settingsClicked = new EventEmitter<boolean>();
  @Output() exportClicked = new EventEmitter<boolean>();
  @Output() buttonClicked = new EventEmitter<string>();

  private _iconLibrary: FaIconLibrary = inject(FaIconLibrary);

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faPlus, faGear, faFilter, faPrint, faFileExport);
  }
}
