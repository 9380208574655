import { NgOptimizedImage } from "@angular/common";
import { Component, inject } from "@angular/core";

import { LanguageService } from "../../../services";
import { SpinnerComponent } from "../../spinner/spinner.component";

@Component({
  selector: "adaa-auth-layout",
  standalone: true,
  templateUrl: "./auth-layout.component.html",
  styleUrl: "./auth-layout.component.scss",
  imports: [NgOptimizedImage, SpinnerComponent],
})
export class AuthLayoutComponent {
  languageService = inject(LanguageService);
}
