import { AdaaHelper } from "../../../../../../../core/utils";
import { milestoneTeamMembersSectionConfig, SharedFields } from "../../configs";
import { AccordionSectionConfigType, ListSectionConfigType, ScreenSectionConfigType } from "../../wf-difference.types";

export const actualStartDateField: ScreenSectionConfigType = {
  field: "actualStartDate",
  label: "national_projects.actual_start_date",
  view: "normal",
  format: (data: { actualStartDate: number }) => {
    if (data?.actualStartDate) {
      return AdaaHelper.formatDate(data?.actualStartDate, false);
    }
    return "";
  },
};

export const actualEndDateField: ScreenSectionConfigType = {
  field: "actualEndDate",
  label: "national_projects.actual_end_date",
  view: "normal",
  format: (data: { actualEndDate: number }) => {
    if (data?.actualEndDate) {
      return AdaaHelper.formatDate(data?.actualEndDate, false);
    }
    return "";
  },
};

const budgetField: ScreenSectionConfigType = {
  field: "budget",
  label: "initiatives.budget",
  view: "normal",
};

const weightField: ScreenSectionConfigType = {
  field: "workload",
  label: "nkpi.weight",
  view: "normal",
  hasDiff: ($n: { workload: { workload: number } }, $o: { workload: { workload: number } }) => {
    return $n?.workload?.workload !== $o?.workload?.workload;
  },
  format: (data: { workload: { workload: number } }) => {
    if (data?.workload && typeof data.workload?.workload === "number") {
      return data.workload?.workload;
    }
    return "";
  },
};

const milestoneOrderField: ScreenSectionConfigType = {
  field: "milestoneOrder",
  label: "common.form.label.order",
  view: "normal",
  visibleIf: ($d: { milestoneOrder: number }) => {
    if ($d?.milestoneOrder) return true;
    return false;
  },
};

const contributingEntitiesField: ListSectionConfigType = {
  field: "contributingEntities",
  label: "kpi.contributing_entities",
  view: "list",
  idKey: "entityId",
  derivedList: (data: { otherEntityId?: number }[]) => data.filter(($i) => !AdaaHelper.isDefined($i?.otherEntityId)),
  format: ($o) => AdaaHelper.getItemValueByToken($o, "entityName"),
};

const otherContributingEntitiesField: ListSectionConfigType = {
  field: "contributingEntities",
  label: "other_entities.contrib_entities",
  view: "list",
  idKey: "otherEntityId",
  derivedList: (data: { otherEntityId?: number }[]) => data.filter(($i) => AdaaHelper.isDefined($i?.otherEntityId)),
  format: ($o) => AdaaHelper.getItemValueByToken($o, "otherEntityName"),
};

const milestonesMembersField: AccordionSectionConfigType = {
  view: "accordion",
  field: "teamMembers",
  title: ($o) => AdaaHelper.getItemValueByToken($o, "name"),
  idKey: "id",
  config: milestoneTeamMembersSectionConfig,
  label: "national_projects.national_projects_milestone.team_members",
};

export const milestonesSectionConfig = [
  ...SharedFields.name,
  ...SharedFields.description,
  {
    ...SharedFields.startDate,
    label: "national_projects.planned_start_date",
  },
  {
    ...SharedFields.endDate,
    label: "national_projects.planned_end_date",
  },
  {
    ...SharedFields.entityIdField,
    format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "entityName"),
    label: "national_projects.accountable_entity",
  },
  {
    ...SharedFields.entityIdField,
    format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "otherEntityName"),
    label: "national_projects.other_accountable_entity",
  },
  SharedFields.ownerField,
  budgetField,
  weightField,
  milestoneOrderField,
  contributingEntitiesField,
  otherContributingEntitiesField,
  SharedFields.nationalSectorsField,
  milestonesMembersField,
];
