import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import type { MainResponseModel, SearchModelType, SearchResultItemType } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class SearchApiService {
  private _mainService = inject(MainService);

  public doSearch({
    limit = 10,
    offset = 0,
    query,
    type,
  }: {
    query: string;
    type: SearchResultItemType | "";
    limit?: number;
    offset?: number;
  }) {
    const url = `search/doSearch`;

    return this._mainService
      .sendRequest<MainResponseModel<SearchModelType>>({
        method: "POST",
        url,
        loader: false,
        data: {
          limit,
          offset,
          query,
          type,
        },
      })
      .pipe(filter((res) => !res.inError));
  }
}
