import { CommonModule } from "@angular/common";
import { Component, inject, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../../../../core/utils";
import { Constants } from "../../../../../../constants/constants";
import { Language } from "../../../../../../constants/enums";
import { CycleModelType, KpiAnnualWeightsModelType } from "../../../../../../models";
import { LanguageService } from "../../../../../../services";

@Component({
  selector: "adaa-kpi-annual-weights-screen",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: "./kpi-annual-weights-screen.component.html",
  styleUrl: "./kpi-annual-weights-screen.component.scss",
})
export class KpiAnnualWeightsScreenComponent {
  data = input.required<KpiAnnualWeightsModelType[]>();
  wfProcessCtlId = input.required<number | undefined>();

  languageService = inject(LanguageService);

  language = Language;
  planYears = signal<number[]>([]);
  currentPlan = signal<CycleModelType>({
    id: 0,
    nameAE: "",
    nameEN: "",
    dscAE: "",
    dscEN: "",
    startYear: 0,
    endYear: 0,
    dataRevisionId: 0,
    entityMaps: [],
    status: 0,
    wfStatus: "",
    updateTS: 0,
  });
  items = new Set<number>();

  public ngOnInit() {
    this._preparePlanYears();
  }

  private _preparePlanYears() {
    const plan = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, { type: "json" });
    this.currentPlan.set(plan as CycleModelType);
    if (!this.currentPlan()) return;
    const startYear = new Date(this.currentPlan().startYear).getFullYear();
    const endYear = new Date(this.currentPlan().endYear).getFullYear();
    const years: number[] = [];
    for (let i = startYear; i <= endYear; i++) {
      years.push(i);
    }
    this.planYears.set(years);
    this._buildDataSet();
  }

  private _buildDataSet() {
    this.items.clear();
    for (const { itemId } of this.data()) {
      this.items.add(itemId);
    }
  }

  public get datalist() {
    return Array.from(this.items);
  }

  public getWeightProperty(itemId: number, key: keyof KpiAnnualWeightsModelType, year?: number, oldVersion = false) {
    const weights = this.data().filter((val: KpiAnnualWeightsModelType) => val.itemId === itemId);

    if (weights.length < 1) return undefined;

    switch (key) {
      case "itemId":
      case "itemType":
      case "kpiNameEN":
      case "kpiNameAE":
      case "kpiEndDate":
      case "kpiStartDate":
      case "refCode":
        return weights[0][key];

      case "wfProcessCtlId": {
        const findingWF = weights.find((weight) => weight.year === year);
        if (AdaaHelper.isDefined(findingWF)) {
          return findingWF?.wfProcessCtlId;
        }
        return null;
      }

      case "id": {
        const findingId = weights.find((weight) => weight.year === year);
        if (AdaaHelper.isDefined(findingId)) {
          return findingId?.id;
        }
        return null;
      }

      case "year": {
        const findingYear = weights.find((weight) => weight.year === year);
        if (AdaaHelper.isDefined(findingYear)) {
          return findingYear?.year;
        }
        return year || null;
      }

      case "weight": {
        const weightsInReview = this.data().filter(
          (val: KpiAnnualWeightsModelType) =>
            val.itemId === itemId &&
            AdaaHelper.isDefined(val.wfProcessCtlId) &&
            val.wfProcessCtlId === this.wfProcessCtlId()
        );

        if (!oldVersion && weightsInReview.length > 0) {
          const findingWeightInReview = weightsInReview.find(
            (weight: KpiAnnualWeightsModelType) => weight.year === year
          );
          if (AdaaHelper.isDefined(findingWeightInReview)) {
            return findingWeightInReview?.snapshotWeight;
          }
        } // * So no snapshot

        const findingWeight = weights.find((weight: KpiAnnualWeightsModelType) => weight.year === year);
        if (AdaaHelper.isDefined(findingWeight)) {
          return findingWeight?.weight;
        }
        return null;
      }
      default:
        return null;
    }
  }
}
