import { Component, input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { SDGModelType } from "../../../models";
import { DoughnutComponent } from "../../charts";

@Component({
  selector: "adaa-sdg-circle",
  standalone: true,
  imports: [TranslateTokenPipe, DoughnutComponent, TranslateModule, RouterModule],
  templateUrl: "./sdg-circle.component.html",
  styleUrl: "./sdg-circle.component.scss",
})
export class SdgCircleComponent {
  sdgModel = input.required<SDGModelType | undefined>();

  adaaHelper = AdaaHelper;
  constants = Constants;
  tooltip: number | undefined = undefined;
}
