import { Component, EventEmitter, input, OnInit, Output, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import {
  DndDraggableDirective,
  DndDropEvent,
  DndDropzoneDirective,
  DndModule,
  DndPlaceholderRefDirective,
} from "ngx-drag-drop";

import { AdaaHelper } from "../../../core/utils";
import { ValueText } from "../../models";

@Component({
  selector: "adaa-draggable-sort-list",
  standalone: true,
  imports: [TranslateModule, DndModule, DndDropzoneDirective, DndDraggableDirective, DndPlaceholderRefDirective],
  templateUrl: "./draggable-sort-list.component.html",
  styleUrl: "./draggable-sort-list.component.scss",
})
export class DraggableSortListComponent implements OnInit {
  label = input.required<string>();
  list = input.required<ValueText[]>();
  showNumber = input<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() listChanged = new EventEmitter<any[]>();

  items = signal<ValueText[]>([]);

  public ngOnInit(): void {
    this.items.set(this.list());

    this.listChanged.emit(this.items().map((e) => e.value));
  }

  public onDrop(event: DndDropEvent) {
    if (event.index == undefined) return;

    const index = this.items().findIndex((e) => e.value === event.data.value);
    if (index != -1) {
      this.items.set(AdaaHelper.moveItemInArray(this.items(), index, event.index));

      this.listChanged.emit(this.items().map((e) => e.value));
    }
  }
}
