<div class="m-1" [class.invalid]="invalid">
    @if (adaaHelper.isDefinedAndNotEmpty(label)) {
        <label for="{{ controlId }}" class="form-label">
            {{ label }}
            @if (isRequired || showAsRequired()) {
                <span class="form-required text-danger fw-bolder">*</span>
            }
        </label>
    }

    @if (!isTextArea && inputType === "tel") {
        <input
            #textInput
            [id]="controlId"
            [type]="controlType"
            [maxlength]="maxLength"
            [ngClass]="classes"
            [class.is-invalid]="invalid"
            [class.text-center]="textAlignment === 'center'"
            [class.text-left]="textAlignment === 'left'"
            [class.text-right]="textAlignment === 'right'"
            [placeholder]="placeholder"
            [formControl]="inputControl"
            phone-number
            (ngModelChange)="valueChanged($event)"
        />
    } @else if (!isTextArea) {
        <input
            #textInput
            [id]="controlId"
            [type]="controlType"
            [maxlength]="maxLength"
            [ngClass]="classes"
            [class.is-invalid]="invalid"
            [class.text-center]="textAlignment === 'center'"
            [class.text-left]="textAlignment === 'left'"
            [class.text-right]="textAlignment === 'right'"
            [placeholder]="placeholder"
            [formControl]="inputControl"
            (ngModelChange)="valueChanged($event)"
        />
    } @else {
        <textarea
            #textarea
            [id]="controlId"
            cols="20"
            [attr.rows]="rows"
            [ngClass]="classes"
            [class.is-invalid]="invalid"
            [class.text-center]="textAlignment === 'center'"
            [class.text-left]="textAlignment === 'left'"
            [class.text-right]="textAlignment === 'right'"
            style="resize: none"
            [maxlength]="maxLength"
            [placeholder]="placeholder"
            [formControl]="inputControl"
            (ngModelChange)="valueChanged($event)"
        ></textarea>
    }
</div>
