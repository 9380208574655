import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  inject,
  input,
  Output,
  Renderer2,
  viewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import Handsontable from "handsontable";
import { CellChange } from "handsontable/common";
import Core from "handsontable/core";
import { ColumnSettings, GridSettings } from "handsontable/settings";

import { environment } from "../../../../core/environments/environment";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { Language } from "../../../constants/enums";
import { DataEntry, DataEntryDetails, DataEntryOptionClicked } from "../../../models";
import { DataentryService } from "../../../services";
import { TableLegendDataEntryComponent } from "../table-legend-data-entry/table-legend-data-entry.component";

@Component({
  selector: "adaa-no-formula-kpi",
  standalone: true,
  imports: [TableLegendDataEntryComponent],
  templateUrl: "./no-formula-kpi.component.html",
  styleUrl: "./no-formula-kpi.component.scss",
})
export class NoFormulaKpiComponent implements AfterViewInit {
  private _translateService = inject(TranslateService);
  private _dataEntryService = inject(DataentryService);
  private _elementRef = inject(ElementRef);
  private _renderer = inject(Renderer2);

  dataEntryDetails = input.required<DataEntryDetails>();
  dataEntrySave = input.required<DataEntryDetails>();

  @Output() tableOptionsClicked = new EventEmitter<DataEntryOptionClicked>();
  @Output() valueChanged = new EventEmitter<DataEntryDetails>();

  hotTableContainer = viewChild.required<ElementRef>("hotTableContainer");

  hotInstance: Handsontable;

  isNTKPI = computed<boolean>(() => this.dataEntryDetails().kpiType === Constants.KPI_TYPE.NTKPI);
  isBounded = computed<boolean>(() => this.dataEntryDetails().trend === Constants.TREND.BOUNDED);
  hasAuditIssue = computed<boolean>(() =>
    this.dataEntryDetails().auditEntries ? this.dataEntryDetails().auditEntries!.some((e) => e.hasAuditIssue) : false
  );

  constructor() {
    this._renderer.listen(this._elementRef.nativeElement, "click", (event) => this._handleOnCellMouseDown(event));
  }

  public ngAfterViewInit(): void {
    const container = this.hotTableContainer().nativeElement;
    const options = this._getOptions();
    this.hotInstance = new Handsontable(container, options);
  }

  public legendClicked(event: { checkbox: "options" | "targets" | "calculation"; checked: boolean }): void {
    if (!this.hotInstance || this.hotInstance.isDestroyed) return;

    switch (event.checkbox) {
      case "options":
        event.checked
          ? this.hotInstance.getPlugin("hiddenColumns").showColumn(0)
          : this.hotInstance.getPlugin("hiddenColumns").hideColumn(0);
        this.hotInstance.render();
        break;
      case "targets":
        event.checked
          ? this.hotInstance.getPlugin("hiddenColumns").showColumns(this.isBounded() ? [2, 3] : [2])
          : this.hotInstance.getPlugin("hiddenColumns").hideColumns(this.isBounded() ? [2, 3] : [2]);
        this.hotInstance.render();
        break;
      case "calculation":
        break;
    }
  }

  private _getOptions(): GridSettings {
    const currentLang = AdaaHelper.getCurrentLang();
    const { columnWidths, columns } = this._getColumns();

    return {
      data: this._getData(),
      rowHeaders: this._getRowHeaders(),
      rowHeaderWidth: 150,
      colHeaders: this._getColHeaders(),
      columns: columns,
      colWidths: columnWidths,
      cells: (row, column, _prop) =>
        this._dataEntryService.setCellColorNoFormulaKpi(row, column, this.dataEntryDetails()),
      afterChange: (changes, _source) => this._handleCellValueChanged(changes),
      mergeCells: this.hasAuditIssue() ? this._dataEntryService.getMergedCells(this.dataEntryDetails(), 3) : false,
      //Same Conf
      stretchH: "none",
      height: "auto",
      viewportColumnRenderingOffset: 999999,
      viewportRowRenderingOffset: 999999,
      hiddenColumns: true,
      layoutDirection: currentLang === Language.Arabic ? "rtl" : "ltr",
      language: currentLang === Language.Arabic ? "ar-AR" : "en-US",
      licenseKey: environment.handsontable_key,
    };
  }

  private _getData() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let innerData: any[] = [];

    if (!this.dataEntryDetails().dataEntries) return data;

    this.dataEntryDetails().dataEntries?.forEach((dataEntry) => {
      innerData = [];

      //OPTIONS
      innerData.push("");

      //ACTUALS
      if (dataEntry.ignored) innerData.push("N/A");
      else if (this.dataEntryDetails().textmapId) innerData.push("");
      else innerData.push(dataEntry.actual);

      //TARGETS
      if (this.isBounded()) {
        const dataArray = dataEntry.isBaseLineYear
          ? [this._translateService.instant("kpi.baselineYear"), this._translateService.instant("kpi.baselineYear")] //baseline
          : this.dataEntryDetails().textmapId
            ? ["", ""] //textmapid
            : [dataEntry.lowerLimit, dataEntry.highLimit]; //default;

        innerData.push(...dataArray);
      } else {
        const dataArray = dataEntry.isBaseLineYear
          ? [this._translateService.instant("kpi.baselineYear")] //baseline
          : this.dataEntryDetails().textmapId
            ? [""] //textmapid
            : [dataEntry.target]; //default;

        innerData.push(...dataArray);
      }

      //AUDITED VALUES
      if (this.hasAuditIssue() && this.dataEntryDetails().auditEntries) {
        const auditEntityByPeriod = this.dataEntryDetails().auditEntries!.find((e) => e.year === dataEntry.year);

        if (auditEntityByPeriod) {
          //Annual Actual Column
          innerData.push(auditEntityByPeriod.annualActualIgnored ? "N/A" : auditEntityByPeriod.annualActual);

          //Audited Annual Actual Column
          innerData.push(auditEntityByPeriod.auditedActualIgnored ? "N/A" : auditEntityByPeriod.auditedActual);

          //Comments Column
          innerData.push(auditEntityByPeriod.comment);
        }
      }
      data.push(innerData);
    });

    return data;
  }

  private _getColHeaders(): string[] {
    const headers = ["", this._translateService.instant("data_entry.actual")];

    if (this.isBounded()) {
      headers.push(
        this._translateService.instant("data_entry.target_lower_limit"),
        this._translateService.instant("data_entry.target_upper_limit")
      );
    } else {
      headers.push(this._translateService.instant("data_entry.target"));
    }

    if (this.hasAuditIssue()) {
      headers.push(
        this._translateService.instant("data_entry.annual_actual"),
        this._translateService.instant("data_entry.audited_annual_actual"),
        this._translateService.instant("common.form.label.comments")
      );
    }

    return headers;
  }

  private _getRowHeaders(): string[] {
    const headers: string[] = [];
    this.dataEntryDetails().dataEntries?.forEach((e) => {
      headers.push(this._dataEntryService.getLabelForPeriodColumn(e, this.dataEntryDetails().frequency!));
    });
    return headers;
  }

  private _getColumns() {
    const columnWidths = this.isBounded() ? [100, 150, 150, 150, 150, 150, 200] : [100, 150, 150, 150, 150, 200];

    const columns: Array<ColumnSettings> = [];
    columns.push(
      { readOnly: true, renderer: this._renderOptionsCell } // * options
    );

    //Actuals - Supports text map and normal input - textmapId
    if (this.dataEntryDetails().textmapId) {
      columns.push({
        type: "dropdown",
        source: [],
      });
    } else {
      columns.push({});
    }

    //Targets
    if (this.isBounded()) {
      columns.push(
        { readOnly: true, className: "disabledCell" }, // * Lower Limit
        { readOnly: true, className: "disabledCell" } // * Upper Limit
      );
    } else {
      columns.push({ readOnly: true, className: "disabledCell" });
    }

    //Audit
    if (this.hasAuditIssue()) {
      columns.push(
        { readOnly: true, className: "disabledCell" }, //annual actual
        { readOnly: true, className: "disabledCell" }, //audited annual actual
        { readOnly: true, className: "disabledCell" } //comment column
      );
    }

    return { columnWidths, columns };
  }

  private _renderOptionsCell = (_instance: Core, TD: HTMLTableCellElement, row: number, column: number) => {
    TD.innerHTML = this._dataEntryService.getOptionCell(row, column, this.isNTKPI());
  };

  private _handleCellValueChanged = (changes: CellChange[] | null) => {
    if (!changes || !this.hotInstance || this.hotInstance.isDestroyed) return;

    changes.forEach((change) => {
      let invalidCell = false;
      let [row, col, prev, next] = change;

      row = +row;
      col = +col;

      const isNA = typeof next === "string" && next.toUpperCase() === "N/A";

      if (!AdaaHelper.isDefinedAndNotEmpty(prev)) prev = undefined;
      if (!AdaaHelper.isDefinedAndNotEmpty(next)) next = undefined;

      if (isNA) next = next.toUpperCase();

      //Avoid saving changes on empty cells
      if (!AdaaHelper.isDefined(prev) && !AdaaHelper.isDefined(next)) return;

      //Avoid saving if metric does not exists
      if (!AdaaHelper.isDefined(this.dataEntryDetails()?.dataEntries![row])) return;

      //Avoid saving if it is NA and data entry is ignored
      if (isNA && this.dataEntryDetails()?.dataEntries![row].ignored) return;

      if (isNA || !next) {
        this.hotInstance.removeCellMeta(row, col, "valid");
        invalidCell = false;
      } else if (isNaN(next)) {
        this.hotInstance.setCellMeta(row, col, "valid", false);
        invalidCell = true;
      } else {
        this.hotInstance.removeCellMeta(row, col, "valid");
        invalidCell = false;
      }

      const tableEntry = this.dataEntryDetails().dataEntries![row];
      if (tableEntry) {
        tableEntry.invalid = invalidCell;
        tableEntry.ignored = isNA;
        tableEntry.actual = isNA ? undefined : +next;
        this._updateDataEntrySave(tableEntry);
      }
    });

    this.hotInstance.render();
  };

  private _handleOnCellMouseDown = (event: { target: Element }) => {
    const action = (<Element>event.target).attributes.getNamedItem("data-type");
    if (action?.value) {
      const row = (<Element>event.target).attributes.getNamedItem("data-row");
      if (!row) return;

      this.tableOptionsClicked.emit({
        action: action.value,
        periodId: this.dataEntryDetails().dataEntries![+row.value].periodId!,
        dateLabel: this._dataEntryService.getLabelForPeriodColumn(
          this.dataEntryDetails().dataEntries![+row.value],
          this.dataEntryDetails().frequency!
        ),
      });
    }
  };

  private _updateDataEntrySave(tableEntry: DataEntry) {
    const entry = this.dataEntrySave().dataEntries?.find((e) => e.id === tableEntry.id);
    if (entry) {
      entry.ignored = tableEntry.ignored;
      entry.actual = tableEntry.actual;
      entry.invalid = tableEntry.invalid;
    } else {
      this.dataEntrySave().dataEntries?.push({
        id: tableEntry.id,
        actual: tableEntry.actual,
        ignored: tableEntry.ignored,
        periodId: tableEntry.periodId,
        year: tableEntry.year,
        allowEdit: tableEntry.allowEdit,
        invalid: tableEntry.invalid,
      });
    }

    this.valueChanged.emit(this.dataEntrySave());
  }
}
