import { NgClass } from "@angular/common";
import { Component, computed, ElementRef, EventEmitter, inject, input, Output, signal, viewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbDropdownModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../../../core/pipes";
import { AdaaHelper } from "../../../../../../core/utils";
import { Constants } from "../../../../../constants/constants";
import { Language } from "../../../../../constants/enums";
import type { EntityModelType } from "../../../../../models";
import { LanguageService } from "../../../../../services";

@Component({
  selector: "adaa-entity-selector",
  standalone: true,
  imports: [NgbDropdownModule, NgbTooltipModule, TranslateTokenPipe, TranslateModule, NgClass, FormsModule],
  styleUrls: ["./selectors.scss"],
  template: `
    <div ngbDropdown class="entity-selector" [ngClass]="[type()]" (openChange)="dropdownOpened()">
      <button type="button" class="btn btn-outline-white main" id="sysLayoutEntitySelector" ngbDropdownToggle>
        <span>{{ activeEntity() | translateToken: "name" }}</span>
      </button>

      <div ngbDropdownMenu aria-labelledby="sysLayoutEntitySelector" role="menu">
        @if (isPmoUser) {
          <input
            #searchInput
            type="search"
            class="form-control"
            autocomplete="on"
            inputmode="search"
            [placeholder]="'breadcrumbs.search' | translate"
            [value]="searchTerm()"
            (input)="onSearch($event)"
          />
        }
        @for (entity of displayedEntities(); track entity.id) {
          <button
            ngbDropdownItem
            [ngbTooltip]="
              isExternalEntity(entity)
                ? getTooltipForExternalEntity(entity | translateToken: 'name')
                : (entity | translateToken: 'name')
            "
            tooltipClass="custom-class"
            [class.external]="isExternalEntity(entity)"
            [class.active]="entity.id === activeEntity().id"
            (click)="onEntitySelect(entity)"
          >
            <span class="text">{{ entity | translateToken: "name" }}</span>

            @if (isExternalEntity(entity)) {
              <span class="badge rounded-0 text-bg-warning label">
                {{ "national_projects.external_entity" | translate }}
              </span>
            }
          </button>
        }
      </div>
    </div>
  `,
})
export class EntitySelectorComponent {
  languageService = inject(LanguageService);

  type = input<"full" | "mini">("mini");
  activeEntity = input.required<EntityModelType>();
  entities = input.required<EntityModelType[]>();

  searchInput = viewChild<ElementRef<HTMLElement>>("searchInput");
  searchTerm = signal<string>("");
  displayedEntities = computed(() => {
    const key = this.languageService.current() === Language.Arabic ? "nameAE" : "nameEN";
    return this.entities().filter((e) => e[key].toLowerCase().includes(this.searchTerm().toLowerCase()));
  });

  getTooltipForExternalEntity = (entityName: string) =>
    `(${this.languageService.translate("national_projects.external_entity")}) ${entityName}`;

  isExternalEntity = (item: EntityModelType) => item?.entityType === Constants.CONSTANT_EXTERNAL_ENTITY_TYPE;

  @Output() entitySelected = new EventEmitter<EntityModelType>();

  public get isPmoUser() {
    const currentUserEntity = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "prop",
      property: "entityId",
    });

    return +currentUserEntity === Constants.CONSTANT_PMO_ID;
  }

  public onEntitySelect(entity: EntityModelType) {
    if (this.activeEntity()?.id !== entity.id) {
      this.entitySelected.emit(entity);
    }
  }

  public dropdownOpened(): void {
    if (!this.searchInput()?.nativeElement) return;

    setTimeout(() => {
      this.searchInput()!.nativeElement!.focus();
      this.searchTerm.set("");
    }, 500);
  }

  public onSearch(event: Event) {
    this.searchTerm.set((event.target as HTMLInputElement).value);
  }
}
