import { AdaaHelper } from "../../../../../../../core/utils";
import { Constants } from "../../../../../../constants/constants";
import { SharedFields, teamMembersSectionConfig } from "../../configs";
import {
  AccordionSectionConfigType,
  ListSectionConfigType,
  ScreenSectionConfigType,
  ScreenType,
} from "../../wf-difference.types";
import { milestonesSectionConfig } from "./milestones.config";

const sourceOfBudgetField: ScreenSectionConfigType = {
  field: "sourceOfBudget",
  label: "national_projects.external_budget_source",
  view: "normal",
};

const contributingEntitiesField: ListSectionConfigType = {
  field: "contributingEntities",
  label: "kpi.contributing_entities",
  view: "list",
  idKey: "entityId",
  derivedList: (data: { otherEntityId?: number }[]) => data.filter(($i) => !AdaaHelper.isDefined($i?.otherEntityId)),
  format: ($o) => AdaaHelper.getItemValueByToken($o, "entityName"),
};

const otherContributingEntitiesField: ListSectionConfigType = {
  field: "contributingEntities",
  label: "other_entities.contrib_entities",
  view: "list",
  idKey: "otherEntityId",
  derivedList: (data: { otherEntityId?: number }[]) => data.filter(($i) => AdaaHelper.isDefined($i?.otherEntityId)),
  format: ($o) => AdaaHelper.getItemValueByToken($o, "otherEntityName"),
};

const transformationalTargetsField: ListSectionConfigType = {
  field: "transformationalTargets",
  label: "national_projects.transformational_targets.title",
  view: "list",
  idKey: "id",
  format: ($o) => AdaaHelper.getItemValueByToken($o, "name"),
};

const stoppedStrategicProjectsField: ListSectionConfigType = {
  field: "stoppedStrategicProjects",
  label: "national_projects.stop_strategic_projects",
  view: "list",
  idKey: "id",
  format: ($o) => AdaaHelper.getItemValueByToken($o, "initiativeName"),
};

const decisionsField: ListSectionConfigType = {
  field: "decisions",
  label: "national_projects.pmo_decisions",
  view: "list",
  idKey: "optionId",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format: ($o: any) => {
    let value = AdaaHelper.getItemValueByToken($o, "option");
    if ($o.optionId === Constants.CONSTANT_PMO_DECISION_OTHERS_ID) {
      value += ` (${$o.comment})`;
    }
    return value;
  },
  hasDiff: ($n: { optionId?: number; comment?: string }, $o: { optionId?: number; comment?: string }) => {
    if (
      $n.optionId === Constants.CONSTANT_PMO_DECISION_OTHERS_ID &&
      $o.optionId === Constants.CONSTANT_PMO_DECISION_OTHERS_ID
    ) {
      return $n.comment !== $o.comment;
    }

    return $n.optionId !== $o.optionId;
  },
};

const targetAudienceField: ListSectionConfigType = {
  field: "targetAudience",
  label: "national_projects.target_audience",
  view: "list",
  idKey: "id",
  format: ($o) => AdaaHelper.getItemValueByToken($o, "audience"),
};

const expectedImpactField: ListSectionConfigType = {
  field: "expectedImpact",
  label: "national_projects.expected_impact",
  view: "list",
  idKey: "id",
  format: ($o) => AdaaHelper.getItemValueByToken($o, "impact"),
};

const commentsField: ListSectionConfigType = {
  field: "comments",
  label: "national_projects.comments",
  view: "list",
  idKey: "id",
  format: ($o) => AdaaHelper.getItemValueByToken($o, "comments"),
};

const projectMilestonesField: AccordionSectionConfigType = {
  view: "accordion",
  field: "milestones",
  title: ($o) => AdaaHelper.getItemValueByToken($o, "name"),
  idKey: "id",
  config: milestonesSectionConfig,
  label: "national_projects.national_projects_milestone.title",
};

const projectTeamMembersField: AccordionSectionConfigType = {
  view: "accordion",
  field: "projectTeamMembers",
  title: ($o) => AdaaHelper.getItemValueByToken($o, "name"),
  idKey: "id",
  config: teamMembersSectionConfig,
  label: "national_projects.project_team_members.popup_title",
};

export const linkedNationalStrategiesField: ScreenSectionConfigType = {
  view: "linked-national-strategies",
  field: "govDirections",
  label: "national_strategies.strategies",
};

export const commonTpScreenConfig = {
  type: "kpi" as ScreenType,
  sections: [
    ...SharedFields.name,
    ...SharedFields.description,
    {
      ...SharedFields.objectiveIdField,
      format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "objectiveName"),
    },
    {
      ...SharedFields.startDate,
      label: "national_projects.planned_start_date",
    },
    {
      ...SharedFields.endDate,
      label: "national_projects.planned_end_date",
    },
    {
      ...SharedFields.entityIdField,
      format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "accountableEntityName"),
      label: "national_projects.accountable_entity",
    },
    {
      ...SharedFields.entityIdField,
      format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "otherEntityName"),
      label: "national_projects.other_accountable_entity",
    },
    {
      ...SharedFields.ownerField,
      label: "national_projects.entity_lead",
    },
    {
      ...SharedFields.ownerField,
      field: "projectCoordinator",
      label: "national_projects.project_coordinator",
      format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "projectCoordinatorName"),
    },
    sourceOfBudgetField,
    contributingEntitiesField,
    otherContributingEntitiesField,
    transformationalTargetsField,
    SharedFields.nationalSectorsField,
    stoppedStrategicProjectsField,
    decisionsField,
    targetAudienceField,
    expectedImpactField,
    commentsField,
    linkedNationalStrategiesField,
    projectMilestonesField,
    projectTeamMembersField,
    SharedFields.attachmentsField,
  ],
};
