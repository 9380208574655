import { inject, Injectable, signal } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import { AdaaBoolean, PageMode } from "../constants/enums";
import { GovDirection, ValidatorModelType, ValueText } from "../models";
import {
  EntitiesApiService,
  GovDirectionApiService,
  UserGroupsApiService,
  UsersApiService,
  ValidatorApiService,
} from ".";

@Injectable({
  providedIn: "root",
})
export class GovernmentDirectionsEditorService {
  private _formBuilder = inject(FormBuilder);
  private _translateService = inject(TranslateService);
  private _validatorApiService = inject(ValidatorApiService);
  private _govDirectionApiService = inject(GovDirectionApiService);
  private _entitiesApiService = inject(EntitiesApiService);
  private _usersApiService = inject(UsersApiService);
  private _userGroupsApiService = inject(UserGroupsApiService);
  private _toastrService = inject(ToastrService);
  private _router = inject(Router);

  formValidation = signal<ValidatorModelType | undefined>(undefined);
  subDirectionFormValidation = signal<ValidatorModelType | undefined>(undefined);
  auxDirectionFormValidation = signal<ValidatorModelType | undefined>(undefined);
  entities = signal<ValueText[]>([]);
  owners = signal<ValueText[]>([]);
  strategyOwnerGroups = signal<ValueText[]>([]);
  subStrategyOwnerGroups = signal<ValueText[]>([]);

  public initPage(): void {
    this._getEntities();
    this._getOwners();
    this._getStrategyOwnerGroup();
    this._initSubDirectionPage();
    this._initAuxDirectionPage();

    this._validatorApiService.searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_GOVERNMENT_DIRECTIONS).subscribe({
      next: (response) => {
        if (response.inError) return;
        this.formValidation.set(response.responseData);
      },
    });
  }

  public prepareForm(pageMode: PageMode) {
    const isViewMode = pageMode === PageMode.view;

    return this._formBuilder.group({
      id: { value: null, disabled: isViewMode },
      nameAE: [{ value: null, disabled: isViewMode }, Validators.required],
      nameEN: [{ value: null, disabled: isViewMode }, Validators.required],
      dscAE: { value: null, disabled: isViewMode },
      dscEN: { value: null, disabled: isViewMode },
      startDate: [{ value: AdaaHelper.plan?.startYear as number, disabled: isViewMode }, Validators.required],
      endDate: [{ value: AdaaHelper.plan?.endYear as number, disabled: isViewMode }, Validators.required],
      entityId: [{ value: Constants.CONSTANT_PMO_ID, disabled: true }], //By default entityId is PMO this ddl only for display
      owner: { value: 0, disabled: isViewMode },
      strategyOwnerGroup: { value: 0, disabled: isViewMode },
      vision: { value: AdaaBoolean.N, disabled: isViewMode },
      available: { value: AdaaBoolean.N, disabled: isViewMode },
      isNationalStrategy: { value: false, disabled: isViewMode },
      logo: { value: null, disabled: isViewMode },
      subdirections: { value: null, disabled: isViewMode },
    });
  }

  public getDirection(directionId: number) {
    return new Observable<GovDirection | undefined>((subscriber) => {
      this._govDirectionApiService.getDirection(directionId).subscribe({
        next: (response) => {
          if (response.inError) subscriber.next(undefined);
          subscriber.next(response.responseData);
        },
      });
    });
  }

  public prepareSubDirectionForm(pageMode: PageMode) {
    const isViewMode = pageMode === PageMode.view;

    return this._formBuilder.group({
      id: { value: null, disabled: isViewMode },
      refCode: { value: null, disabled: true },
      govDirectionId: { value: null, disabled: isViewMode },
      nameAE: [{ value: null, disabled: isViewMode }, Validators.required],
      nameEN: [{ value: null, disabled: isViewMode }, Validators.required],
      dscAE: [{ value: null, disabled: isViewMode }, Validators.required],
      dscEN: { value: null, disabled: isViewMode },
      owner: [{ value: 0, disabled: isViewMode }, Validators.required],
      logo: { value: null, disabled: isViewMode },
      subGovDirectionType: { value: null, disabled: isViewMode },
      isMain: { value: false, disabled: isViewMode },
    });
  }

  public prepareAuxDirectionForm() {
    return this._formBuilder.group({
      id: { value: null },
      govSubDirectionId: { value: null },
      nameAE: [{ value: null }, Validators.required],
      nameEN: [{ value: null }, Validators.required],
      dscAE: [{ value: null }, Validators.required],
      dscEN: [{ value: null }, Validators.required],
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public saveDirection(directionId: number | undefined, data: any) {
    this._govDirectionApiService.saveDirection(directionId ? "update" : "create", data).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigate(["/console/directions"]);
      },
    });
  }

  private _getEntities() {
    this._entitiesApiService.getAllV2().subscribe({
      next: (response) => {
        if (response.inError) return;
        this.entities.set(
          AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"))
        );
      },
    });
  }

  private _getOwners() {
    this._usersApiService.getOwnersPerEntity(Constants.CONSTANT_PMO_ID).subscribe({
      next: (response) => {
        if (response.inError) return;

        const ddl = AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"));
        this._translateService.get("common.form.label.none").subscribe({
          next: (translatedTitle) => {
            ddl.unshift({ value: 0, text: translatedTitle });
            this.owners.set(ddl);
          },
        });
      },
    });
  }

  private _getStrategyOwnerGroup() {
    this._userGroupsApiService.getall(Constants.CONSTANT_PMO_ID).subscribe({
      next: (response) => {
        if (response.inError) return;

        this.subStrategyOwnerGroups.set(
          AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"))
        );

        const ddl = AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"));
        this._translateService.get("common.form.label.none").subscribe({
          next: (translatedTitle) => {
            ddl.unshift({ value: 0, text: translatedTitle });
            this.strategyOwnerGroups.set(ddl);
          },
        });
      },
    });
  }

  private _initSubDirectionPage(): void {
    this._validatorApiService
      .searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_GOVERNMENT_SUB_DIRECTIONS)
      .subscribe({
        next: (response) => {
          if (response.inError) return;
          this.subDirectionFormValidation.set(response.responseData);
        },
      });
  }

  private _initAuxDirectionPage(): void {
    this._validatorApiService
      .searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_GOVERNMENT_AUX_DIRECTIONS)
      .subscribe({
        next: (response) => {
          if (response.inError) return;
          this.auxDirectionFormValidation.set(response.responseData);
        },
      });
  }
}
