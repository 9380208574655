import { Component, EventEmitter, inject, input, OnInit, Output, signal } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Constants } from "../../../constants/constants";
import { PageMode } from "../../../constants/enums";
import { SubMilestone, TableButtonClicked, ValidatorModelType, ValueText } from "../../../models";
import { LanguageService, ValidatorApiService } from "../../../services";
import { ConfirmationModalComponent } from "../../general";
import { DataTableComponent } from "../../table/data-table/data-table.component";
import { SubMilestoneModalComponent } from "../sub-milestone-modal/sub-milestone-modal.component";

@Component({
  selector: "adaa-sub-milestone-list",
  standalone: true,
  imports: [DataTableComponent],
  templateUrl: "./sub-milestone-list.component.html",
  styleUrl: "./sub-milestone-list.component.scss",
})
export class SubMilestoneListComponent implements OnInit {
  private _modalService = inject(NgbModal);
  private _languageService = inject(LanguageService);
  private _validatorApiService = inject(ValidatorApiService);

  subMilestones = input.required<SubMilestone[]>();
  entities = input.required<ValueText[]>();
  otherEntities = input.required<ValueText[]>();
  parentEntityId = input.required<number | undefined>();
  parentStartDate = input.required<number | undefined>();
  parentEndDate = input.required<number | undefined>();

  @Output() subMilestonesChanged = new EventEmitter<SubMilestone[]>();

  constants = Constants;
  formValidation = signal<ValidatorModelType>({
    id: 0,
    status: 0,
    searchKey: "",
    type: 0,
    parameterCatalogs: [],
    nameAE: "",
    nameEN: "",
  });

  public get subMilestonesData(): SubMilestone[] {
    return this.subMilestones()
      .filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE)
      .map((e, index) => ({
        number: `#${index + 1}`,
        ...e,
      }));
  }

  public ngOnInit(): void {
    this._validatorApiService.searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_SUB_PROJECT_MILESTONE).subscribe({
      next: (response) => {
        if (response.inError) return;

        this.formValidation.set(response.responseData);
      },
    });
  }

  public addNewClicked(): void {
    const subMilestone: SubMilestone = {
      id: 0,
      startDate: this.parentStartDate() ?? 0,
      endDate: this.parentEndDate() ?? 0,
      completed: false,
      contributingEntities: [],
      teamMembers: [],
      nameAE: "",
      nameEN: "",
      fakeId: Math.floor(Math.random() * 217 * 1000),
    };

    this._openSubMilestoneModal(subMilestone, PageMode.create);
  }

  public actionClicked(action: TableButtonClicked): void {
    switch (action.event) {
      case "delete":
        this._deleteSubMilestone(action.data);
        break;
      case "edit":
        this._openSubMilestoneModal(action.data, PageMode.edit);
        break;
      default:
        break;
    }
  }

  private _deleteSubMilestone(data: SubMilestone): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = "national_projects.national_projects_milestone.sub_milestone";
    modal.componentInstance.title = "national_projects.national_projects_milestone.sub_milestone_remove";

    modal.result.then((e) => {
      if (e) this._deleteSubMilestoneConfirmed(data);
    });
  }

  private _deleteSubMilestoneConfirmed(data: SubMilestone): void {
    if (data.fakeId) {
      const subMilestoneIndex = this.subMilestones().findIndex((e) => e.fakeId === data.fakeId);
      if (subMilestoneIndex > -1) this.subMilestones().splice(subMilestoneIndex, 1);
    } else {
      const subMilestone = this.subMilestones().find((e) => e.id === data.id);
      if (subMilestone) subMilestone.status = Constants.OBJECT_STATUS.REMOVE;
    }
    this.subMilestonesChanged.emit(this.subMilestones());
  }

  private _openSubMilestoneModal(subMilestone: SubMilestone, pageMode: PageMode): void {
    const modal = this._modalService.open(SubMilestoneModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.subMilestone = subMilestone;
    modal.componentInstance.pageMode = pageMode;
    modal.componentInstance.formValidation = this.formValidation();
    modal.componentInstance.entities = this.entities();
    modal.componentInstance.otherEntities = this.otherEntities();
    modal.componentInstance.parentEntityId = this.parentEntityId();

    modal.result.then((e) => {
      if (!e) return;
      if (pageMode === PageMode.create) this.subMilestones().push(e);
      else {
        const subMilestoneIndex = this.subMilestones().findIndex((subMilestone) =>
          e.fakeId ? e.fakeId === subMilestone.fakeId : e.id === subMilestone.id
        );
        if (subMilestoneIndex > -1) this.subMilestones().splice(subMilestoneIndex, 1, e);
      }

      this.subMilestonesChanged.emit(this.subMilestones());
    });
  }
}
