import { inject, Injectable } from "@angular/core";

import { AdaaHelper } from "../../core/utils";
import { MainResponseModel, TpCycle, TransformationalTarget } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class TransformationalTargetsApiService {
  private _mainService = inject(MainService);

  public getTTargetById(tpCycleId: number) {
    const url = `transformational-target/getbyid/${tpCycleId}`;

    return this._mainService.sendRequest<MainResponseModel<TpCycle>>({
      method: "GET",
      url,
    });
  }

  public createTTarget(tpCycle: Partial<TpCycle>) {
    const url = `transformational-target/create`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data: tpCycle,
    });
  }

  public updateTTarget(data: Partial<TpCycle>) {
    const url = `transformational-target/update`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "PUT",
      url,
      data,
    });
  }

  public deleteTTarget(tpCycleId: number) {
    const url = `transformational-target/delete/${tpCycleId}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "DELETE",
      url,
    });
  }

  public getAll() {
    return this._mainService.sendRequest<MainResponseModel<TransformationalTarget[]>>({
      method: "POST",
      url: "transformational-target/getall",
      data: {},
    });
  }

  public getAllByNationalProjectId({
    kpiId,
    transformationalProjectId,
  }: {
    kpiId?: number;
    transformationalProjectId: number;
  }) {
    const url = `transformational-target/getByNationalProjectId`;

    const qs = new URLSearchParams();
    if (AdaaHelper.isDefined(kpiId)) qs.set("kpiId", `${kpiId}`);

    return this._mainService.sendRequest<MainResponseModel<TransformationalTarget[]>>({
      method: "GET",
      url: `${url}/${transformationalProjectId}?${qs.toString()}`,
    });
  }
}
