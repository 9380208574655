<div class="sdg-circle">
    <img src="assets/images/SDG/logoTrans.png" alt="" style="display: block" />
    <div class="shadow-circle"></div>

    @for (sdgIcon of sdgModel()?.subGovDirections; track $index) {
        <div
            class="sdg-img-container"
            [style.right]="sdgIcon.config.right"
            [style.top]="sdgIcon.config.top"
            [style.bottom]="sdgIcon.config.bottom"
            [routerLink]="'/sdg/' + sdgIcon.id"
            (mouseover)="tooltip = sdgIcon.id"
            (mouseleave)="tooltip = undefined"
        >
            @if (tooltip === sdgIcon.id && !adaaHelper.isMobileDevice()) {
                <div class="tooltip-sdg">
                    <span>{{ sdgIcon | translateToken: "name" }}</span>
                </div>
            }

            @if (sdgIcon.config) {
                <img
                    class="pointer"
                    [src]="sdgIcon.config.iconUrl"
                    [style.width]="sdgIcon.config.iconWidth"
                    [alt]="sdgIcon | translateToken: 'name'"
                />
            }
        </div>
    }
    <adaa-doughnut
        [label]="'sdg.goals_achievement' | translate"
        [colorId]="constants.CONSTANT_COLORS.EVALUATION_GOLD"
        [amount]="sdgModel()?.visionAchievement!"
        [size]="'lg'"
    />
</div>
