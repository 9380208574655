@switch (data()?.dataStatus) {
    <!-- data-status: approved -->
    @case ("A") {
        <span class="text-white m-1 px-3 py-1 rounded-1 shadow-sm is-draft data-status-badge">
            <fa-icon size="lg" [icon]="icon.ok" [ngClass]="['text-white', _languageService.direction() === 'rtl' ? 'ms-1' : 'me-1']" />
            {{ "common.state.data_approved" | translate }}
        </span>
    }

    <!-- data-status: new -->
    @case ("N") {
        <span class="text-white m-1 px-2 py-1 rounded-1 shadow-sm bg-warning data-status-badge">
            {{ "common.state.new" | translate }}
        </span>
    }

    <!-- data-status: deleted -->
    @case ("D") {
        <span class="text-white m-1 px-2 py-1 rounded-1 shadow-sm bg-danger data-status-badge">
            {{ "common.state.deleted" | translate }}
        </span>
    }

    <!-- data-status: in-review -->
    @case ("O") {
        <span class="text-white m-1 px-2 py-1 rounded-1 text-dark shadow-sm bg-warning data-status-badge">
            {{ "common.state.data_onreview" | translate }}
        </span>
    }

    <!-- data-status: null -->
    @default {
        <span class="bg-white m-1 px-3 py-2 rounded-1 shadow-sm data-status-badge">
            <fa-icon size="lg" [icon]="icon.warn" [ngClass]="['text-warning', _languageService.direction() === 'rtl' ? 'ms-2' : 'me-2']" />
            {{ "common.state.data_missing" | translate }}
        </span>
    }
}

<!-- object-info-status: (active|draft) -->
<span
    class="text-white m-1 px-3 py-1 rounded-1 shadow-sm data-status-badge"
    [class.is-draft]="infoStatus() === 'draft'"
    [class.is-active]="infoStatus() === 'active'"
    [class.bg-secondary]="!infoStatus()"
    [class.text-dark]="!infoStatus()"
>
    @if (infoStatus() === "active") {
        <fa-icon size="lg" [icon]="icon.ok" [ngClass]="['fa-regular', 'text-white', _languageService.direction() === 'rtl' ? 'ms-1' : 'me-1']" />
    } @else if (infoStatus() === "draft") {
        <fa-icon size="lg" [icon]="icon.pending" [ngClass]="['fa-regular', 'text-white', _languageService.direction() === 'rtl' ? 'ms-1' : 'me-1']" />
    }

    {{ workflowStatus() | translate }}
</span>

<!-- audited-status: Specific for KPI object types -->
@let hideLabel = true;
@if (!!auditedStatus() && !hideLabel) {
    <span
        class="text-white m-1 px-3 py-1 rounded-1 shadow-sm data-status-badge"
        [class.bg-danger]="!auditedStatus()?.isAudited"
        [class.is-active]="auditedStatus()?.isAudited"
    >
        @if (auditedStatus()?.isAudited) {
            <fa-icon size="lg" [icon]="icon.audited" [ngClass]="['text-white', _languageService.direction() === 'rtl' ? 'ms-1' : 'me-1']" />
        } @else {
            <fa-icon size="lg" [icon]="icon.notAudited" [ngClass]="['text-white', _languageService.direction() === 'rtl' ? 'ms-1' : 'me-1']" />
        }

        {{ auditedStatus()!.label | translate }}
    </span>
}
