<div class="modal-header">
    <h4 class="modal-title">
        {{ pageMode === PageMode.create ? ("directions.add_new_subDirection" | translate) : ("directions.sub_government" | translate) }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <form class="col-12" [formGroup]="subDirectionForm">
        <adaa-form-steps [tabs]="tabs()" [showActions]="false" (currentTab)="activeTab.set($event)">
            <div class="tab-pane fade bg-white shadow-md rounded p-2" id="tab-0" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 0 }">
                @if (pageMode !== PageMode.create) {
                    <div class="row">
                        <div class="col-12">
                            <adaa-form-input
                                [label]="'common.form.label.refcode' | translate"
                                [setValidator]="directionsService.subDirectionFormValidation()!"
                                [invalid]="submitted && subDirectionForm.get('refCode')?.errors?.['required']"
                                formControlName="refCode"
                                controlName="refCode"
                            ></adaa-form-input>
                        </div>
                    </div>
                }
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.nameAE' | translate"
                            [setValidator]="directionsService.subDirectionFormValidation()!"
                            [invalid]="submitted && subDirectionForm.get('nameAE')?.errors?.['required']"
                            formControlName="nameAE"
                            controlName="nameAE"
                        ></adaa-form-input>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.nameEN' | translate"
                            [setValidator]="directionsService.subDirectionFormValidation()!"
                            [invalid]="submitted && subDirectionForm.get('nameEN')?.errors?.['required']"
                            formControlName="nameEN"
                            controlName="nameEN"
                        ></adaa-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.descriptionAE' | translate"
                            [setValidator]="directionsService.subDirectionFormValidation()!"
                            [invalid]="submitted && subDirectionForm.get('dscAE')?.errors?.['required']"
                            formControlName="dscAE"
                            controlName="dscAE"
                            [isTextArea]="true"
                        ></adaa-form-input>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.descriptionEN' | translate"
                            [setValidator]="directionsService.subDirectionFormValidation()!"
                            [invalid]="submitted && subDirectionForm.get('dscEN')?.errors?.['required']"
                            formControlName="dscEN"
                            controlName="dscEN"
                            [isTextArea]="true"
                        ></adaa-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-dropdown
                            appendTo=".modal-content"
                            [label]="'directions.team_owner' | translate"
                            [setValidator]="directionsService.subDirectionFormValidation()!"
                            [invalid]="submitted && subDirectionForm.get('owner')?.errors?.['required']"
                            formControlName="owner"
                            controlName="owner"
                            [options]="directionsService.subStrategyOwnerGroups()"
                            [searchable]="true"
                        />
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 d-flex align-items-center mt-4">
                        <adaa-form-checkbox
                            [label]="'directions.is_main' | translate"
                            controlName="isMain"
                            formControlName="isMain"
                        ></adaa-form-checkbox>
                    </div>
                    <div class="col-12">
                        <adaa-form-input-file
                            label="directions.subgov_icon"
                            [allowedFilesMessage]="pageMode === PageMode.view ? '' : 'help.faqs.upload_tool_tip'"
                            [onDeleteRemoveFile]="false"
                            [isDisabled]="pageMode === PageMode.view"
                            [allowedFileSize]="100024"
                            [allowedFiles]="allowedFiles()"
                            [file]="subDirectionIcon"
                            (inputChanges)="onFilechange($event)"
                        ></adaa-form-input-file>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="tab-1" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 1 }">
                <div class="bg-white shadow-md rounded p-2">
                    <adaa-data-table
                        #auxGovernmentDirectionsTable
                        titleHeader="directions.aux_government"
                        configKey="aux_gov_dir_conf_list"
                        [isLocalData]="true"
                        [enableExport]="false"
                        [localData]="getAuxDirections"
                        [enableViewAction]="false"
                        [enableAdd]="pageMode !== PageMode.view"
                        [enableEditAction]="pageMode !== PageMode.view"
                        [enableDeleteAction]="pageMode !== PageMode.view"
                        (addNewClicked)="addAuxDirectionClicked()"
                        (actionClicked)="manageAuxDirectionClicked($event)"
                    ></adaa-data-table>
                </div>
            </div>
        </adaa-form-steps>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ "common.form.button.cancel" | translate }}
    </button>
    @if (pageMode !== PageMode.view) {
        <button type="button" class="btn btn-primary" (click)="save()">
            {{ "common.form.button.save" | translate }}
        </button>
    }
</div>
