import { AdaaHelper } from "../../../../../../../core/utils";
import { SharedFields, textField } from "../../configs";
import { AccordionSectionConfigType, ListSectionConfigType, ScreenSectionConfigType } from "../../wf-difference.types";

const kpiTypeField: ScreenSectionConfigType = {
  field: "kpiType",
  label: "report.kpi_type",
  view: "normal",
  format: (data: unknown) => AdaaHelper.getItemValueByToken(data, "kpiTypeName"),
};

const govScoresScopeSubKpiListField: ListSectionConfigType = {
  field: "govScoresScopeSubKpiList",
  label: "government_scores.scopes.sub_kpis",
  view: "list",
  idKey: "id",
  labelledItem: (data: unknown) => AdaaHelper.getItemValueByToken(data, "kpiTypeName"),
  derivedList: (data: { id?: number }[]) => data.filter(($i) => AdaaHelper.isDefined($i?.id)),
};

const govMainKpiProjectsListField: ListSectionConfigType = {
  field: "govMainKpiProjectsList",
  label: "national_projects.projects",
  view: "list",
  idKey: "id",
  labelledItem: (data: unknown) => AdaaHelper.getItemValueByToken(data, "typeName"),
  derivedList: (data: { id?: number }[]) => data.filter(($i) => AdaaHelper.isDefined($i?.id)),
};

const govMainKpiFormulaMetricsField: ListSectionConfigType = {
  field: "metrics",
  label: "formulas.formula_metrics",
  view: "list",
  idKey: "id",
  format: ($o: unknown) => {
    const name = AdaaHelper.getItemValueByToken($o, "name");
    const dsc = AdaaHelper.getItemValueByToken($o, "dsc");

    return `[${name}] - ${dsc}`;
  },
  derivedList: (data: { metricId?: number }[]) => data.filter(($i) => AdaaHelper.isDefined($i?.metricId)),
};

const govMainKpiMetricsField: ListSectionConfigType = {
  field: "govScoreScopeMetricsList",
  label: "data_entry.metrics",
  view: "list",
  idKey: "metricId",
};

const parentMetricNameField: () => ScreenSectionConfigType[] = () => {
  const fields = textField("name", { use: "both" }) as ScreenSectionConfigType[];

  AdaaHelper.getFieldLanguage;
  return fields.map((field) => ({
    ...field,
    label: `metrics.parent_metric_name_${field.field === "nameAE" ? "ar" : "en"}`,
  }));
};

const dimensionsValuesField: ListSectionConfigType = {
  field: "dimensionValues",
  label: "common.form.label.values",
  view: "list",
  idKey: "id",
};

export const dimensionsValuesConfig: ScreenSectionConfigType[] = [...SharedFields.name, dimensionsValuesField];

const valuesField: AccordionSectionConfigType = {
  view: "accordion",
  field: "values",
  title: ($o: unknown) => AdaaHelper.getItemValueByToken($o, "name"),
  idKey: "id",
  config: dimensionsValuesConfig,
  label: "metrics.dimension_type",
};

export const dimensionsConfig: ScreenSectionConfigType[] = [...parentMetricNameField(), valuesField];

const dimensionsField: AccordionSectionConfigType = {
  view: "accordion",
  field: "dimensions",
  title: ($o: unknown) => AdaaHelper.getItemValueByToken($o, "name"),
  idKey: "id",
  config: dimensionsConfig,
  label: "dimension.dimension",
};

export const measurementScopeMainKpisConfig: ScreenSectionConfigType[] = [
  kpiTypeField,
  ...SharedFields.name,
  govMainKpiMetricsField,
  govScoresScopeSubKpiListField,
  govMainKpiProjectsListField,
  govMainKpiFormulaMetricsField,
  dimensionsField,
];
