import { Component, EventEmitter, input, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { TpAgmProject } from "../../../models";
import { PictureComponent, ProgressIndicatorComponent } from "../../general";

@Component({
  selector: "adaa-tp-project-card",
  standalone: true,
  imports: [TranslateModule, TranslateTokenPipe, ProgressIndicatorComponent, PictureComponent],
  templateUrl: "./tp-project-card.component.html",
  styleUrl: "./tp-project-card.component.scss",
})
export class TpProjectCardComponent {
  project = input.required<TpAgmProject>();
  showTargetsProgressBar = input<boolean>(true);

  @Output() cardClicked = new EventEmitter<number>();
}
