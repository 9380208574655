<div
    class="progress my-1 w-100 d-flex align-items-center position-relative"
    role="progressbar"
    aria-valuenow="50"
    aria-valuemin="0"
    aria-valuemax="100"
    style="border-radius: 3px"
    [style.background-color]="background()"
    [style.height]="height() + 'px'"
    [attr.aria-label]="label() ? (label()! | translate) : ''"
>
    <div
        class="progress-bar"
        style="border-radius: 3px"
        [style.height]="height() + 'px'"
        [style.background-color]="colorString()"
        [style.width]="width() | percentageValue"
    ></div>

    <span class="position-absolute fw-bold mx-2 text-white" [class.text-center]="showValue() && !label()" [style.font-size]="fontSize()">
        @if (label()) {
            {{ label() }}
        }
        @if (showValue()) {
            {{ value() | percentageValue }}
        }
    </span>
</div>
