import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import { CardEntryInfoType, DataEntryDetails, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class DataentryApiService {
  private readonly _mainService = inject(MainService);

  public getInfov2({ itemId, periodId, itemType }: { itemId: number; itemType: number; periodId: number }) {
    const url = "dataentry/getInfov2";
    const qs = new URLSearchParams({
      itemId: `${itemId}`,
      itemType: `${itemType}`,
      periodId: `${periodId}`,
    });

    return this._mainService
      .sendRequest<MainResponseModel<CardEntryInfoType>>({
        method: "GET",
        url: `${url}?${qs}`,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getByKpiId(kpiId: number) {
    const url = `dataentry/getByKpiIdv3/${kpiId}`;

    return this._mainService.sendRequest<MainResponseModel<DataEntryDetails>>({
      method: "GET",
      url: `${url}`,
    });
  }

  public getByKpiIdv2<T>(kpiId: number) {
    const url = `dataentry/getByKpiIdv2/${kpiId}`;

    return this._mainService.sendRequest<MainResponseModel<T>>({
      method: "GET",
      url: `${url}`,
    });
  }

  public getAnalysisDifferencesInfo(itemId: number, periodId: number, itemType: number) {
    const url = `dataentry/getInfov2?itemId=${itemId}&periodId=${periodId}&itemType=${itemType}&includeFile=false`;

    return this._mainService.sendRequest<MainResponseModel<CardEntryInfoType>>({
      method: "GET",
      url: `${url}`,
    });
  }

  public updateCardDataEntryInfo({
    itemId,
    periodId,
    itemType,
    data,
  }: {
    itemId: number;
    itemType: number;
    periodId: number;
    data: CardEntryInfoType;
  }) {
    const url = "dataentry/updateInfo";
    const qs = new URLSearchParams({
      itemId: `${itemId}`,
      itemType: `${itemType}`,
      periodId: `${periodId}`,
    });

    return this._mainService
      .sendRequest<MainResponseModel>({
        method: "POST",
        url: `${url}?${qs}`,
        data: data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public saveDataEntry(dataEntry: DataEntryDetails) {
    const url = `dataentry/updateKpiEntryV3`;

    return this._mainService.sendRequest<MainResponseModel>({
      method: "PUT",
      url: `${url}`,
      data: dataEntry,
    });
  }
}
