<div class="m-1" [class.invalid]="invalid">
    @if (AdaaHelper.isDefinedAndNotEmpty(label)) {
        <label [for]="controlId" class="form-label"
            >{{ label }}
            @if (required) {
                <span class="form-required text-danger fw-bolder">*</span>
            }
        </label>
    }
    <div class="mb-2" ngbDropdown #ddl="ngbDropdown" container="body">
        <button
            type="button"
            class="dropdown-button text-truncate"
            #btn
            [id]="controlId"
            ngbDropdownToggle
            [disabled]="isDisabled"
            [class.border]="invalid"
            [class.border-danger]="invalid"
            [class.ae]="AdaaHelper.getCurrentLang() === Language.Arabic"
        >
            @if (isMultiple) {
                {{ selectedValue.length }} {{ "common.ddl_items_selected" | translate }}
            } @else {
                {{ selectedObjects && selectedObjects.length > 0 ? selectedObjects[0].text : "" }}
            }
        </button>
        <div ngbDropdownMenu [matchWidth]="btn">
            <input
                class="form-control shadow-sm"
                [placeholder]="'common.form.label.search' | translate"
                #searchInput
                (keyup)="search(searchInput.value)"
            />
            <ul class="tree-ddl">
                @for (item of options; track item) {
                    <adaa-form-dropdown-tree-node
                        [isMultiple]="isMultiple"
                        [option]="item"
                        [selectedValue]="selectedValue"
                        (valueSelected)="valueChanged($event)"
                    />
                }
            </ul>
        </div>
    </div>
    @if (isMultiple) {
        @for (item of selectedObjects; track item) {
            <div class="alert alert-primary mb-2">
                {{ item.text }}
                @if (!isDisabled) {
                    <button type="button" class="btn-close float-end" (click)="removeSelection(item.value)"></button>
                }
            </div>
        }
    }
</div>
