<div class="modal-header bg-white">
    <h4 class="modal-title">
        {{ isLink ? ("government_scores.scopes.delete_and_link" | translate) : ("government_scores.scopes.main_kpi" | translate) }}
    </h4>
</div>
<div class="modal-body">
    @if (isLink) {
        <adaa-form-dropdown
            controlName="scope"
            appendTo=".modal-content"
            [searchable]="true"
            [label]="'government_scores.scopes.single' | translate"
            [options]="scopes()"
            [setDefaultValue]="scope"
            [searchable]="true"
            [required]="true"
            (inputChanges)="scope = $event"
        />
    } @else {
        <div class="text-center">
            <h5>{{ "government_scores.scopes.main_kpi_remove" | translate }}</h5>
            <h6>{{ "common.form.label.are_you_sure" | translate }}</h6>
        </div>
    }
</div>
<div class="modal-footer d-flex justify-content-center">
    @if (isLink) {
        <button type="button" class="btn btn-secondary px-4 mx-2" (click)="modal.close()">
            {{ "common.form.button.cancel" | translate }}
        </button>

        <button type="button" class="btn btn-primary px-4 mx-2" (click)="saveLink()">
            {{ "common.form.button.save" | translate }}
        </button>
    } @else {
        <button type="button" class="btn btn-danger px-4 mx-2" (click)="modal.close({ setToDraft: true })">
            {{ "government_scores.scopes.delete_set_draft" | translate }}
        </button>

        <button type="button" class="btn btn-outline-primary px-4 mx-2" (click)="linkToScope()">
            {{ "government_scores.scopes.delete_and_link" | translate }}
        </button>
    }
</div>
