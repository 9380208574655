import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import type { MainResponseModel, UserGroup } from "../models";
import {
  CreateHelpdeskItem,
  HelpdeskAttachment,
  HelpdeskComment,
  HelpdeskIssuePerStatus,
  HelpdeskItem,
  HelpdeskUnresolvedIssuePerPriority,
} from "../models/helpdesk.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class SupportCenterApiService {
  private _mainService = inject(MainService);

  public saveItem(data: CreateHelpdeskItem, isUpdate: boolean) {
    const url = `helpdesk/${isUpdate ? "update" : "create"}?isFinish=false`;

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "POST",
        url: url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getById(helpdeskId: number) {
    const url = `helpdesk/getById/${helpdeskId}`;

    return this._mainService
      .sendRequest<MainResponseModel<HelpdeskItem>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getHelpdeskAttachments(helpdeskId: number) {
    const url = `helpdesk/getAttachmentsByHelpDeskId/${helpdeskId}`;

    return this._mainService
      .sendRequest<MainResponseModel<HelpdeskAttachment[]>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getHelpdeskComments(helpdeskId: number) {
    const url = `helpdesk/getCommentsByHelpDeskId/${helpdeskId}`;

    return this._mainService
      .sendRequest<MainResponseModel<HelpdeskComment[]>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getIssuesPerStatus() {
    const url = `helpdesk/getIssuesPerStatus`;

    return this._mainService
      .sendRequest<MainResponseModel<HelpdeskIssuePerStatus[]>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getUnresolvedIssuesPerPriority() {
    const url = `helpdesk/getUnresolvedIssuesPerPriority`;

    return this._mainService
      .sendRequest<MainResponseModel<HelpdeskUnresolvedIssuePerPriority[]>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getUserHelpdeskGroup() {
    const url = `helpdesk/getUserHelpdeskGroup`;

    return this._mainService
      .sendRequest<MainResponseModel<UserGroup>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }
}
