import { inject, Injectable } from "@angular/core";

import { AgmMilestone, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class AgmMilestoneApiService {
  private _mainService = inject(MainService);

  public getMilestone(id: number) {
    const url = `annual-meeting-project-milestone/getById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<AgmMilestone>>({
      method: "GET",
      url,
    });
  }

  public updateMilestone(isFinish: boolean = true, data: AgmMilestone) {
    const url = `annual-meeting-project-milestone/update?isFinish=${isFinish}`;

    return this._mainService.sendRequest<MainResponseModel>({
      method: "PUT",
      url,
      data: data,
    });
  }

  public getMilestonesByProjectId(id: number) {
    const url = `annual-meeting-project-milestone/getMilestoneValuesByProjectId/${id}`;

    return this._mainService.sendRequest<MainResponseModel<AgmMilestone[]>>({
      method: "GET",
      url,
    });
  }
}
