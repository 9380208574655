<adaa-item-slider class="my-3" [showPerformance]="true">
    <h5 title class="py-2 fw-bold">
        {{ govVisionData() | translateToken: "name" }}
    </h5>

    <section performance>
        @let govVision = govVisionData();
        @let govVisionName = govVisionData() | translateToken: "name";
        @if (govVision) {
            <adaa-doughnut
                [size]="'sm'"
                [amount]="govVision.visionAchievement"
                [label]="govVisionData() | translateToken: 'name'"
                [labelAsImage]="true"
                [labelImage]="{ imageId: '/assets/images/uae-vision-2021.png', imageSize: 'xxs' }"
                [colorId]="overallPerfColor"
                [showGraphIcon]="true"
                (onclick)="$event === 'graphClicked' && overallGovDirKpiVision({ govDirId: govVision.id, periodId: period(), name: govVisionName })"
            />

            <div class="d-flex w-100 flex-wrap px-2 pb-2">
                <div class="d-block w-100">
                    <adaa-progress-indicator [value]="govVision.performance" [color]="govVision.performanceColor" [label]="'nkpi.ytp' | translate" />
                </div>

                <div class="d-block w-100 mt-3">
                    <adaa-progress-indicator
                        [value]="govVision.initiativeProgress"
                        [color]="govVision.initiativeProgressColor"
                        [label]="'common.tables.progress' | translate"
                    />
                </div>
            </div>
        }
    </section>

    @for (item of subGovVisionData(); track item.id) {
        @let subGovVisionName = item | translateToken: "name";
        <adaa-sub-gov-vision-card
            [data]="item"
            (graphClicked)="subGovDirKpiVision({ govDirId: govVisionData()!.id, periodId: period(), subGovDirId: item.id, name: subGovVisionName })"
        />
    }
</adaa-item-slider>
