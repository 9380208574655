<div
    class="position-relative d-flex flex-column justify-content-between align-items-center border border-1 card-type"
    role="link"
    [style.border-bottom-color]="'#' + data().color + ' !important'"
    (click)="$event.stopPropagation(); router.navigateByUrl('/console/kpi/nkpi?sub_gov_direction_id=' + data().id)"
>
    <div class="d-flex flex-column align-items-center performance--title">
        <img
            class="performance--image p-2"
            role="img"
            [src]="image()"
            [attr.alt]="data() | translateToken: 'name'"
            [style.background-color]="'#' + data().color"
        />
    </div>

    <div class="d-flex align-content-center align-items-center px-4 justify-content-between gap-1 w-100 flex-grow-0 performance--score">
        <h3 class="fw-bold" [style.color]="'#' + data().color">
            {{ data().visionAchievement | percentageValue: 2 : "" }}
        </h3>

        <button
            class="btn performance--graph-btn"
            role="button"
            [title]="'common.form.label.performance_comparison' | translate"
            (click)="$event.stopPropagation(); graphClicked.emit()"
        >
            <fa-icon [icon]="graphIcon" size="xl" />
        </button>
    </div>

    <div class="w-100 px-2 text-center fw-bold performance--name flex-grow-0">
        <p class="p-0 m-0">{{ data() | translateToken: "name" }}</p>
    </div>
</div>
