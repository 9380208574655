import {
  Component,
  computed,
  inject,
  input,
  type OnInit,
  output,
  signal,
  type TemplateRef,
  viewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  type FormControl,
  type FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { type NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { filter } from "rxjs";

import { TranslateTokenPipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../constants/constants";
import {
  type PropTypeModelType,
  type ValueText,
  type WorkflowDecisionModelType,
  WorkflowMonitoringItemModelType,
} from "../../../../models";
import { LanguageService, PropertiesService } from "../../../../services";
import { FormDropdownComponent, FormInputComponent } from "../../../form";

@Component({
  selector: "adaa-wf-override-decision",
  standalone: true,
  templateUrl: "./wf-override-decision.component.html",
  styleUrl: "./wf-override-decision.component.scss",
  imports: [
    TranslateModule,
    TranslateTokenPipe,
    ReactiveFormsModule,
    FormDropdownComponent,
    FontAwesomeModule,
    FormInputComponent,
  ],
})
export class WfOverrideDecisionComponent implements OnInit {
  readonly languageService = inject(LanguageService);
  private readonly _propertiesService = inject(PropertiesService);
  private readonly _fb = inject(FormBuilder);

  content = viewChild.required<TemplateRef<unknown>>("content");

  data = input<WorkflowMonitoringItemModelType | undefined>(undefined);
  label = input<string>();
  categories = input<PropTypeModelType[] | undefined>(undefined);

  cacheActionCategories = signal<PropTypeModelType[]>([]);
  actionCategories = signal<PropTypeModelType[]>([]);
  decision = signal<Partial<WorkflowDecisionModelType> | undefined>(undefined);
  emitDecision = output<Partial<WorkflowDecisionModelType | undefined>>();

  actionCategoryOptions = computed(() =>
    this.actionCategories().map<ValueText>((item) => ({
      text: AdaaHelper.getItemValueByToken(item, "name"),
      value: item.id,
    }))
  );

  readonly removeIcon = faTrash;

  wfActionType = Constants.WF_ACTION_DECISION;
  modalRef: NgbModalRef;
  commentForm: FormGroup<{ comment: FormControl<string | undefined>; categoryId: FormControl<number | undefined> }>;

  public ngOnInit() {
    //If we pass the action categories to the component no need to load it
    if (this.categories()) {
      this._initDecision();
      this.actionCategories.set(this.categories() ?? []);
      this.cacheActionCategories.set(this.categories() ?? []);
    } else this._getActionCategories();

    this.commentForm = this._fb.group({
      categoryId: this._fb.control<number | undefined>(undefined, {
        validators: [Validators.required],
        nonNullable: true,
        updateOn: "submit",
      }),
      comment: this._fb.control<string | undefined>(undefined, {
        validators: [Validators.required, this._minLengthValidator(3)],
        nonNullable: true,
        updateOn: "submit",
      }),
    });
  }

  private _minLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value?.trim() || "";
      return value.length >= minLength ? null : { minLength: true };
    };
  }

  public getCommentCategory(categoryId: number) {
    return this.cacheActionCategories().find(({ id }) => id === categoryId);
  }

  public addComment() {
    this.commentForm.markAsDirty();
    if (this.commentForm.invalid) {
      return;
    }

    this.decision.update((decision) => ({
      ...decision,
      categorizedComments: [
        ...(decision?.categorizedComments ?? []),
        {
          categoryId: this.commentForm.controls["categoryId"].value as number,
          comments: this.commentForm.controls["comment"].value as string,
        },
      ],
    }));

    const categoryId = this.commentForm.controls["categoryId"].value as number;
    this.actionCategories.update((data) => {
      return data.filter(({ id }) => id !== categoryId);
    });

    this.commentForm.reset({
      comment: undefined,
      categoryId: undefined,
    });

    this.emitDecision.emit(this.decision());
  }

  public removeComment(index: number) {
    const found = (this.decision()?.categorizedComments ?? []).find((_, idx: number) => idx === index);
    if (found) {
      const categoryId = found.categoryId as number;
      const category = this.cacheActionCategories().find(({ id }) => id === categoryId);
      if (category) {
        this.actionCategories.update((data) => [category, ...data]);
      }
    }
    this.decision.update((decision) => ({
      ...decision,
      categorizedComments: (decision?.categorizedComments ?? []).filter((_, idx: number) => idx !== index),
    }));

    this.emitDecision.emit(this.decision());
  }

  private _getActionCategories() {
    this._propertiesService
      .getPropById(Constants.CONSTANT_WF_ACTION_CATEGORY)
      .pipe(filter((res) => !res.inError))
      .subscribe({
        next: (res) => {
          this._initDecision();
          this.actionCategories.set(res.responseData ?? []);
          this.cacheActionCategories.set(res.responseData ?? []);
        },
      });
  }

  private _initDecision() {
    const userId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "prop",
      property: "id",
    });

    this.decision.set({
      wfProcessCtlId: this.data()?.wfProcessctlId as number,
      wfProcessCtlStepId: this.data()?.wfProcessctlStepId as number,
      threadId: this.data()?.threadId as string,
      itemId: this.data()?.itemId as number,
      itemTypeId: this.data()?.itemTypeId as number,
      userId: Number(userId),
      categorizedComments: [],
    });
  }
}
