<div class="modal-header">
    <h4 class="modal-title">
        {{ pageMode === PageMode.create ? ("directions.add_new_auxDirection" | translate) : ("directions.aux_government" | translate) }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <form class="col-12" [formGroup]="auxDirectionForm">
        <div class="bg-white shadow-md rounded p-2">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        [setValidator]="directionsService.auxDirectionFormValidation()!"
                        [invalid]="submitted && auxDirectionForm.get('nameAE')?.errors?.['required']"
                        formControlName="nameAE"
                        controlName="nameAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        [setValidator]="directionsService.auxDirectionFormValidation()!"
                        [invalid]="submitted && auxDirectionForm.get('nameEN')?.errors?.['required']"
                        formControlName="nameEN"
                        controlName="nameEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionAE' | translate"
                        [setValidator]="directionsService.auxDirectionFormValidation()!"
                        [invalid]="submitted && auxDirectionForm.get('dscAE')?.errors?.['required']"
                        formControlName="dscAE"
                        controlName="dscAE"
                        [isTextArea]="true"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionEN' | translate"
                        [setValidator]="directionsService.auxDirectionFormValidation()!"
                        [invalid]="submitted && auxDirectionForm.get('dscEN')?.errors?.['required']"
                        formControlName="dscEN"
                        controlName="dscEN"
                        [isTextArea]="true"
                    ></adaa-form-input>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ "common.form.button.cancel" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        {{ "common.form.button.save" | translate }}
    </button>
</div>
