import { Component, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { PageMode } from "../../../constants/enums";
import { ParameterCatalog, SubGovDirection, ValueText } from "../../../models";
import { FormCheckboxComponent, FormDropdownComponent, FormInputComponent } from "../../form";

@Component({
  selector: "adaa-objective-enabler-modal",
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, FormInputComponent, FormCheckboxComponent, FormDropdownComponent],
  templateUrl: "./objective-enabler-modal.component.html",
  styleUrl: "./objective-enabler-modal.component.scss",
})
export class ObjectiveEnablerModalComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  modal: NgbActiveModal = inject(NgbActiveModal);

  @Input() subDirection: SubGovDirection;
  @Input() pageMode: PageMode = PageMode.view;
  @Input() validations: ParameterCatalog[] = [];
  @Input() ownerGroups: ValueText[] = [];

  subDirectionForm: FormGroup;
  PageMode = PageMode;
  isFieldRequired = AdaaHelper.isFieldRequired.bind(AdaaHelper);
  submitted: boolean = false;

  public ngOnInit(): void {
    this.subDirectionForm = this._prepareForm();
    this.subDirectionForm.patchValue(this.subDirection);
  }

  public save(): void {
    this.submitted = true;
    if (!this.subDirectionForm.valid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const owner = this.subDirectionForm.get("owner")?.value as number;
    const ownerNameAE = this.ownerGroups.find((e) => e.value === owner)?.text ?? "";
    const ownerNameEN = this.ownerGroups.find((e) => e.value === owner)?.text ?? "";

    this.subDirection.nameAE = this.subDirectionForm.get("nameAE")?.value;
    this.subDirection.nameEN = this.subDirectionForm.get("nameEN")?.value;
    this.subDirection.dscAE = this.subDirectionForm.get("dscAE")?.value;
    this.subDirection.dscEN = this.subDirectionForm.get("dscEN")?.value;
    this.subDirection.isMain = this.subDirectionForm.get("isMain")?.value;
    this.subDirection.owner = owner;
    this.subDirection.ownerNameAE = ownerNameAE;
    this.subDirection.ownerNameEN = ownerNameEN;

    this.modal.close(this.subDirection);
  }

  private _prepareForm() {
    const isViewMode = this.pageMode === PageMode.view;

    return this._formBuilder.group({
      id: { value: null, disabled: isViewMode },
      refCode: { value: null, disabled: true },
      govDirectionId: { value: null, disabled: isViewMode },
      nameAE: { value: null, disabled: isViewMode },
      nameEN: { value: null, disabled: isViewMode },
      dscAE: { value: null, disabled: isViewMode },
      dscEN: { value: null, disabled: isViewMode },
      owner: { value: 0, disabled: isViewMode },
      logo: { value: null, disabled: isViewMode },
      isMain: { value: false, disabled: isViewMode },
    });
  }
}
