import { Component, input, output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "adaa-form-action-buttons",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./form-action-buttons.component.html",
  styleUrl: "./form-action-buttons.component.scss",
})
export class FormActionButtonsComponent {
  cancelButtonTitle = input<string>("common.form.button.cancel");
  saveButtonTitle = input<string>("common.form.button.save");
  saveButtonDisabled = input<boolean>(false);

  cancelClicked = output<void>();
  saveClicked = output<void>();

  showSaveButton = input<boolean>(true);
}
