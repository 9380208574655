import { Component, EventEmitter, inject, input, OnInit, Output, signal } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import moment from "moment";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { LoginResponse, TableButtonClicked, TeamMemberType, ValidatorModelType, ValueText } from "../../../models";
import { LanguageService, ValidatorApiService } from "../../../services";
import { ConfirmationModalComponent } from "../../general";
import { DataTableComponent } from "../../table/data-table/data-table.component";
import { TeamMembersModalComponent } from "../team-members-modal/team-members-modal.component";

@Component({
  selector: "adaa-team-members-list",
  standalone: true,
  imports: [TranslateModule, DataTableComponent],
  templateUrl: "./team-members-list.component.html",
  styleUrl: "./team-members-list.component.scss",
})
export class TeamMembersListComponent implements OnInit {
  private _validatorApiService = inject(ValidatorApiService);
  private _modalService = inject(NgbModal);
  private _languageService = inject(LanguageService);

  teamMembers = input.required<TeamMemberType[]>();
  tableConf = input.required<string>();
  entities = input.required<ValueText[]>();
  otherEntities = input.required<ValueText[]>();
  isDisabled = input<boolean>(false);
  tableTitle = input<string>("");
  enableProjectMembers = input<boolean>(false);
  projectMembers = input<TeamMemberType[]>([]);
  onlySystemUsers = input<boolean>(false);

  @Output() teamMembersChanged = new EventEmitter<TeamMemberType[]>();

  formValidation = signal<ValidatorModelType>({
    id: 0,
    status: 0,
    searchKey: "",
    type: 0,
    parameterCatalogs: [],
    nameAE: "",
    nameEN: "",
  });

  public get teamMembersData(): TeamMemberType[] {
    return this.teamMembers()
      .filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE)
      .map((e, index) => ({
        ...e,
        number: `#${index + 1}`,
      }));
  }

  public ngOnInit(): void {
    this._validatorApiService.searchByKey(Constants.VALIDATORS_CONF_KEY.NATIONAL_PROJECT_MEMBER).subscribe({
      next: (response) => {
        if (response.inError) return;

        this.formValidation.set(response.responseData);
      },
    });
  }

  public addNewClicked(event: Event | undefined, isSystemUser: boolean): void {
    if (event) {
      event.stopPropagation();
    }

    const currentUser = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "json",
    }) as LoginResponse;

    const teamMember: TeamMemberType = {
      id: 0,
      status: Constants.OBJECT_STATUS.DRAFT,
      nameEN: "",
      nameAE: "",
      createdBy: currentUser.id,
      updatedBy: currentUser.id,
      createdByUserNameAE: currentUser.nameAE,
      createdByUserNameEN: currentUser.nameEN,
      creationDate: moment().utcOffset(Constants.uaeTimezone).valueOf(),
      fakeId: Math.floor(Math.random() * 217 * 1000),
    };
    this._openTeamMemberModal(teamMember, isSystemUser);
  }

  public actionClicked(action: TableButtonClicked): void {
    if (action.event === "delete") this._deleteTeamMember(action.data);
  }

  private _deleteTeamMember(data: TeamMemberType): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = "national_projects.project_team_members.singular_commmon_text";
    modal.componentInstance.title = "national_projects.project_team_members.remove_member";

    modal.result.then((e) => {
      if (e) this._deleteTeamMemberConfirmed(data);
    });
  }

  private _deleteTeamMemberConfirmed(data: TeamMemberType) {
    if (data.fakeId) {
      const attachmentIndex = this.teamMembers().findIndex((e) => e.fakeId === data.fakeId);
      if (attachmentIndex > -1) this.teamMembers().splice(attachmentIndex, 1);
    } else {
      const attachment = this.teamMembers().find((e) => e.id === data.id);
      if (attachment) attachment.status = Constants.OBJECT_STATUS.REMOVE;
    }
    this.teamMembersChanged.emit(this.teamMembers());
  }

  private _openTeamMemberModal(teamMember: TeamMemberType, isSystemUser: boolean): void {
    const modal = this._modalService.open(TeamMembersModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.teamMember = teamMember;
    modal.componentInstance.isSystemUser = isSystemUser;
    modal.componentInstance.formValidation = this.formValidation();
    modal.componentInstance.entities = this.entities();
    modal.componentInstance.otherEntities = this.otherEntities();
    modal.componentInstance.enableProjectMembers = this.enableProjectMembers();
    modal.componentInstance.projectMembers = this.projectMembers();

    modal.result.then((e) => {
      if (!e) return;
      this.teamMembers().push(e);
      this.teamMembersChanged.emit(this.teamMembers());
    });
  }
}
