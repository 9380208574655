<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title w-100 text-center">{{ "workflow.workflow_history" | translate }}</h4>

        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>

    <div class="modal-body pb-3 pt-2 px-2">
        <adaa-data-table
            [configKey]="tableConfList"
            [enableAdd]="false"
            [enablePrint]="false"
            [enableActionsCell]="false"
            [localData]="tableData()"
            [isLocalData]="true"
        ></adaa-data-table>
    </div>
</ng-template>
