import { inject, Injectable } from "@angular/core";

import { MainResponseModel, SubMilestone, TpMilestone } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class TpMilestoneApiService {
  private _mainService = inject(MainService);

  public getProjectMilestones(id: number) {
    const url = `national-project-milestone/getMilestoneValuesByNationalProjectId/${id}`;

    return this._mainService.sendRequest<MainResponseModel<TpMilestone[]>>({
      method: "GET",
      url,
    });
  }

  public getMilestone(id: number) {
    const url = `national-project-milestone/getById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<TpMilestone>>({
      method: "GET",
      url,
    });
  }

  public getSubMilestones(id: number) {
    const url = `national-project-milestone/getSubMilestones?milestoneId=${id}`;

    return this._mainService.sendRequest<MainResponseModel<SubMilestone[]>>({
      method: "GET",
      url,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateSubMilestone(isFinish: boolean = true, data: any) {
    const url = `national-project-milestone/update?isFinish=${isFinish}`;

    return this._mainService.sendRequest<MainResponseModel>({
      method: "PUT",
      url,
      data: data,
    });
  }

  public getMilestonesByEntityId(entityId: number) {
    const url = `national-project-milestone/getByEntityId?entityId=${entityId}`;

    return this._mainService.sendRequest<MainResponseModel<TpMilestone[]>>({
      method: "GET",
      url,
    });
  }
}
