import { inject, Injectable, signal } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { TpCommentsModalComponent, TpTeamMembersModalComponent } from "../components";
import { genericFloatButtons } from "../components/general/float-action";
import { Constants } from "../constants/constants";
import {
  AttachmentModelType,
  CardNote,
  MainResponseModel,
  TableButtonClicked,
  TpMilestone,
  TpProjectDetails,
} from "../models";
import { CardNoteApiService } from "./card-note-api.service";
import { FilesApiService } from "./files-api.service";
import { LanguageService } from "./language.service";
import { TpApiService } from "./tp-api.service";
import { TpMilestoneApiService } from "./tp-milestone-api.service";

@Injectable({
  providedIn: "root",
})
export class TpDetailsService {
  private _tpApiService = inject(TpApiService);
  private _tpMilestoneApiService = inject(TpMilestoneApiService);
  private _cardNoteApiServide = inject(CardNoteApiService);
  private _modalService = inject(NgbModal);
  private _languageService = inject(LanguageService);
  private _filesApiService = inject(FilesApiService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _router = inject(Router);

  private readonly _genericFloatButtons = genericFloatButtons();
  project = signal<TpProjectDetails | undefined>(undefined);
  milestones = signal<TpMilestone[]>([]);
  notes = signal<CardNote[]>([]);
  readonly fetchProject = (id: number) => {
    return forkJoin([
      this._tpApiService.getProjectValues(id),
      this._tpMilestoneApiService.getProjectMilestones(id),
      this._cardNoteApiServide.getAllByItemType(id, Constants.CONSTANT_NATIONAL_PROJECTS),
    ]);
  };

  public initDetails([project, milestones, notes]: [
    MainResponseModel<TpProjectDetails>,
    MainResponseModel<TpMilestone[]>,
    MainResponseModel<CardNote[]>,
  ]) {
    if (project.inError) {
      this._toastrService.error(this._translateService.instant("notification.error.unknown_object"));
      this._router.navigateByUrl("national-projects-dashboard");
    }
    this.project.set(this._formatProject(project.responseData));
    this.milestones.set(this._formatMilestones(milestones.responseData));
    this.notes.set(this._formatNotes(notes.responseData));

    this._floatActions(project.responseData);
  }

  public openTeamMembers() {
    const modal = this._modalService.open(TpTeamMembersModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.teamMembers = this.project()?.projectTeamMembers;
    modal.componentInstance.configKey = "national_project_member_conf_list";
    modal.componentInstance.title = "national_projects.project_team_members.popup_title";
  }

  public downloadAttachment(attachment: AttachmentModelType) {
    this._filesApiService.save(attachment.id, attachment.filename, true);
  }

  public showComments(milestoneId: number) {
    this._tpMilestoneApiService.getMilestone(milestoneId).subscribe((response) => {
      if (response.inError) return;

      if (!response.responseData?.comments || response.responseData.comments!.length === 0) {
        this._toastrService.warning(this._translateService.instant("search.noresults"));
        return;
      }

      const modal = this._modalService.open(TpCommentsModalComponent, {
        centered: true,
        size: "lg",
        modalDialogClass: this._languageService.modalDirection(),
      });

      modal.componentInstance.comments = response.responseData.comments;
    });
  }

  public actionClickedMokpi(event: TableButtonClicked): void {
    this._router.navigateByUrl(`/console/kpi/mokpi/${event.data.id}`);
  }

  public goToMilestone(event: number): void {
    this._router.navigateByUrl(`national-projects-dashboard/milestones/${event}`);
  }

  private _formatProject(project: TpProjectDetails): TpProjectDetails {
    const otherOptionId = 102904;

    project.projectStatusId = this._getColorId(project.projectStatus);
    if (AdaaHelper.isDefined(project.decisions)) {
      project.decisions = project.decisions
        .filter((e) => e.status !== Constants.OBJECT_STATUS.DRAFT || e.status !== Constants.OBJECT_STATUS.REMOVE)
        .map((e) => {
          return {
            ...e,
            textAE: e.optionId === otherOptionId ? `${e.optionAE} (${e.comment})` : e.optionAE,
            textEN: e.optionId === otherOptionId ? `${e.optionEN} (${e.comment})` : e.optionEN,
          };
        });
    }

    if (AdaaHelper.isDefined(project.projectTeamMembers)) {
      project.projectTeamMembers = project.projectTeamMembers.map((e, index) => ({
        ...e,
        number: `#${index + 1}`,
      }));
    }
    return project;
  }

  private _getColorId(status: string): number {
    switch (status) {
      case "COMPLETED":
        return 33;
      case "ON_GOING":
        return 35;
      case "DELAYED":
        return 34;
      default: //CANCELED
        return 72;
    }
  }

  private _formatMilestones(milestones: TpMilestone[]): TpMilestone[] {
    return milestones.sort((a, b) => {
      if (a.milestoneOrder < b.milestoneOrder) return -1;
      if (a.milestoneOrder > b.milestoneOrder) return 1;
      return 0;
    });
  }

  private _formatNotes(notes: CardNote[]): CardNote[] {
    return notes.sort((a, b) => {
      if (a.createTS < b.createTS) return 1;
      else if (a.createTS > b.createTS) return -1;
      else return 0;
    });
  }

  private _floatActions(project: TpProjectDetails): void {
    this._genericFloatButtons([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "print",
        data: undefined,
      },
      {
        key: "connection",
        data: undefined,
      },
      {
        key: "addNote",
        data: {
          itemId: project.id,
          itemTypeId: Constants.CONSTANT_NATIONAL_PROJECTS,
        },
      },
      {
        key: "workflowHistory",
        data: {
          itemId: project.id,
          itemTypeId: Constants.CONSTANT_WORKFLOW.NATIONAL_PROJECTS,
        },
      },
      {
        key: "email",
        data: {
          objectName: AdaaHelper.getItemValueByToken(project, "name"),
        },
      },
      {
        key: "systemLog",
        data: {
          itemId: project.id,
          itemTypeId: [Constants.CONSTANT_NATIONAL_PROJECTS],
        },
      },
    ]);
  }
}
