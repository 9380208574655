import { Component, inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { FormInputComponent } from "../../../form";

@Component({
  selector: "adaa-comments-editor-modal",
  standalone: true,
  imports: [TranslateModule, FormInputComponent],
  templateUrl: "./comments-editor-modal.component.html",
  styleUrl: "./comments-editor-modal.component.scss",
})
export class CommentsEditorModalComponent {
  modal: NgbActiveModal = inject(NgbActiveModal);

  @Input() cardTitle: string = "";
  comment: string = "";
}
