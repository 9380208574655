import { inject, Injectable, signal } from "@angular/core";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { genericFloatButtons } from "../components/general/float-action";
import { Constants } from "../constants/constants";
import { AgmMilestone, AttachmentModelType, CardNote, MainResponseModel, MilestoneSignOffAttachment } from "../models";
import { AgmMilestoneApiService } from "./agm-milestone-api.service";
import { CardNoteApiService } from "./card-note-api.service";
import { FilesApiService } from "./files-api.service";

@Injectable({
  providedIn: "root",
})
export class AgmMilestoneDetailsService {
  private _agmMilestoneApiService = inject(AgmMilestoneApiService);
  private _cardNoteApiServide = inject(CardNoteApiService);
  private _filesApiService = inject(FilesApiService);

  private readonly _genericFloatButtons = genericFloatButtons();
  milestone = signal<AgmMilestone | undefined>(undefined);
  notes = signal<CardNote[]>([]);

  readonly fetchMilestone = (id: number) => {
    return forkJoin([
      this._agmMilestoneApiService.getMilestone(id),
      this._cardNoteApiServide.getAllByItemType(id, Constants.CONSTANT_ANNUAL_MEETINGS_MILESTONE),
    ]);
  };

  public init([milestone, notes]: [MainResponseModel<AgmMilestone>, MainResponseModel<CardNote[]>]) {
    this.milestone.set(milestone.responseData);
    this.notes.set(this._formatNotes(notes.responseData));

    this._floatActions(milestone.responseData);
  }

  public downloadAttachment(attachment: AttachmentModelType | MilestoneSignOffAttachment) {
    this._filesApiService.save(attachment.id, attachment.filename, true);
  }

  public getNotes(): void {
    if (this.milestone()?.id) {
      this._cardNoteApiServide
        .getAllByItemType(this.milestone()!.id, Constants.CONSTANT_ANNUAL_MEETINGS_MILESTONE)
        .subscribe({
          next: (response) => {
            if (response.inError) return;

            this.notes.set(this._formatNotes(response.responseData));
          },
        });
    }
  }

  private _formatNotes(notes: CardNote[]): CardNote[] {
    return notes.sort((a, b) => {
      if (a.createTS < b.createTS) return 1;
      else if (a.createTS > b.createTS) return -1;
      else return 0;
    });
  }

  private _floatActions(milestone: AgmMilestone): void {
    this._genericFloatButtons([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "edit",
        data: {
          url: `/console/annual-meetings-projects/milestones/edit/${milestone.id}`,
        },
      },
      {
        key: "print",
        data: undefined,
      },
      {
        key: "connection",
        data: undefined,
      },
      {
        key: "addNote",
        data: {
          itemId: milestone.id,
          itemTypeId: Constants.CONSTANT_ANNUAL_MEETINGS_MILESTONE,
        },
      },
      {
        key: "workflowHistory",
        data: {
          itemId: milestone.id,
          itemTypeId: Constants.CONSTANT_ANNUAL_MEETINGS_MILESTONE,
        },
      },
      {
        key: "email",
        data: {
          objectName: AdaaHelper.getItemValueByToken(milestone, "name"),
        },
      },
      {
        key: "systemLog",
        data: {
          itemId: milestone.id,
          itemTypeId: [Constants.CONSTANT_ANNUAL_MEETINGS_MILESTONE],
        },
      },
    ]);
  }
}
