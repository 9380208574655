export const environment = {
  production: true,
  staging: false,
  newbe: false,
  sandbox: false,
  metaTags: false,
  baseUrl: "https://www.adaa.ae",
  apiUrl: "https://api.adaa.ae/adaa-webapp-web-rest-api/",
  khadamatiApiUrl: "https://api.adaa.ae/adaa-ws-khadamati-soap-client/",
  google_captcha_key: "6Le1aqwUAAAAAH8JOTJROTGxdrFpp9WK8usPxvCh",
  google_maps: "AIzaSyAXfHV9wm7UL6DX7ocX7aYbjYffzrAaXMo",
  handsontable_key: "non-commercial-and-evaluation",
  glitchDns: "https://989cbbaadfb5446cac9b41d2619a875a@error.kalvad.com/33",
  reportingUrl: "https://api.adaa.ae/adaa-webapp-reporting/",
  expressionToolsUrl: "https://api.adaa.ae/adaa-webapp-expression-tools/",
  uaePass: {
    enable: true,
    loginUrl: "https://www.adaa.ae/login",
    url: "https://id.uaepass.ae/idshub/authorize",
    clientId: "pmo_adaa_web_prod",
    scope: "urn:uae:digitalid:profile:general",
    logoutUrl: "https://id.uaepass.ae/idshub/logout",
  },
  autoTestEmails: [
    "adaa.strategy.object.creator@gmail.com",
    "adaa.data.entry@gmail.com",
    "adaa.kpi.level1.approver@gmail.com",
    "adaa.kpi.level2.approver@gmail.com",
    "adaa.pmo.level1.approver@gmail.com",
    "adaa.pmo.level2.approver@gmail.com",
  ],
};
