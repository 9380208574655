import { Component, inject, input, OnInit, output, signal, TemplateRef, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { Constants } from "../../../constants/constants";
import { FileInputType, Language, PageMode } from "../../../constants/enums";
import { AttachFile } from "../../../models";
import { HelpdeskAttachment } from "../../../models/helpdesk.model";
import { AppService } from "../../../services";
import { SupportCenterAttachmentEditorService } from "../../../services/support-center-attachment.editor.service";
import {
  FormActionButtonsComponent,
  FormInputComponent,
  FormInputDateComponent,
  FormInputFileComponent,
} from "../../form";

@Component({
  selector: "adaa-support-center-attachment",
  standalone: true,
  imports: [
    FormInputComponent,
    FormInputFileComponent,
    FormInputDateComponent,
    FormActionButtonsComponent,
    TranslateModule,
    ReactiveFormsModule,
  ],
  templateUrl: "./support-center-attachment.component.html",
  styleUrl: "./support-center-attachment.component.scss",
})
export class SupportCenterAttachmentComponent implements OnInit {
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _formBuilder = inject(FormBuilder);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  supportCenterAttachmentEditorService = inject(SupportCenterAttachmentEditorService);

  modal = viewChild.required<TemplateRef<unknown>>("content");
  submitted = signal<boolean>(false);
  attachmentForm: FormGroup = new FormGroup({});
  pageMode = input.required<string>();
  attachment = output<HelpdeskAttachment>();
  PageMode = PageMode;
  allowedFiles: FileInputType[] = [
    FileInputType.doc,
    FileInputType.docx,
    FileInputType.pdf,
    FileInputType.txt,
    FileInputType.zip,
    FileInputType.xls,
    FileInputType.xlsx,
  ];

  public get message() {
    return `${this._translateService.instant("attachment.file_types_allowed")} <br> ${this._translateService.instant("attachment.max_file_size")}`;
  }

  public getFile(): AttachFile {
    const attach = this.attachmentForm.get("file")?.value;
    return {
      id: attach?.id ?? 0,
      filename: attach?.filename,
    };
  }

  public onFilechange(event: AttachFile | null) {
    if (event) {
      this.attachmentForm.get("file")?.setValue({
        id: event.id,
        filename: event.filename,
      });
    } else this.attachmentForm.get("file")?.setValue(null);
  }

  public ngOnInit(): void {
    this.supportCenterAttachmentEditorService.formValidator();
    this._prepareForm();
  }

  public open() {
    const modalRef: NgbModalRef = this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modalRef.dismissed.subscribe(() => this._resetForm());
    modalRef.closed.subscribe(() => this._resetForm());
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.attachmentForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      attachDate: this._formBuilder.control<string | number>(
        { value: Date.now(), disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      file: this._formBuilder.control<{ id: number; filename: string } | undefined>(
        { value: undefined, disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
    });
  }

  private _resetForm() {
    this.attachmentForm.reset();
    this.attachmentForm.markAsPristine();
    this.attachmentForm.markAsUntouched();
    this.submitted.set(false);
  }

  public checkInvalid(control: string) {
    return (
      this.attachmentForm.controls[control]?.errors?.["required"] &&
      (this.attachmentForm.get(control)?.dirty || this.submitted())
    );
  }

  public submit() {
    this.submitted.set(true);
    if (this.attachmentForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    const result = {
      ...this.attachmentForm.value,
      id: this.attachmentForm.get("file")?.value?.id,
      filename: this.attachmentForm.get("file")?.value?.filename,
      status: Constants.OBJECT_STATUS.ACTIVE,
    };

    delete result.file;
    this.attachment.emit(result);
    this._modalService.dismissAll();
  }
}
