<div class="modal-header bg-white">
    <h4 class="modal-title">{{ header | translate }}</h4>
</div>
<div class="modal-body text-center">
    <h5>{{ title | translate }}</h5>
    <h6>{{ subTitle | translate }}</h6>
</div>
<div class="modal-footer d-flex justify-content-center">
    <button type="button" class="btn btn-danger px-4 mx-2" (click)="modal.close(true)">
        {{ yesLabel | translate }}
    </button>

    <button type="button" class="btn btn-outline-primary px-4 mx-2" (click)="modal.close(false)">
        {{ noLabel | translate }}
    </button>
</div>
