import { inject, Injectable } from "@angular/core";

import type { PermissionModelType } from "../../../adaa-types";
import { AdaaBoolean } from "../constants/enums";
import type { MainResponseModel, UserRole, UserRolesPermission } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class RolesApiService {
  private _mainService = inject(MainService);

  public getAllMergedUserPermissions() {
    const url = "userroles/getAllMergedUserPermissions";

    return this._mainService.sendRequest<MainResponseModel<PermissionModelType[]>>({
      url,
      method: "GET",
      loader: true,
    });
  }

  public getAllPermissions() {
    const url = "userroles/getAllPermissions";

    return this._mainService.sendRequest<MainResponseModel<UserRolesPermission[]>>({
      url,
      method: "GET",
      loader: true,
    });
  }

  public getAllMergedUserPermissionsEntity() {
    const url = "userroles/getAllMergedUserPermissionsEntity";

    return this._mainService.sendRequest<MainResponseModel<{ [entityId: number]: PermissionModelType[] }>>({
      url,
      method: "GET",
    });
  }

  public getAllRoles(includePMO: boolean) {
    let url = "userroles/getall?includePmo=";
    url += includePMO ? AdaaBoolean.Y : AdaaBoolean.N;

    return this._mainService.sendRequest<MainResponseModel<UserRole[]>>({
      url,
      method: "POST",
      data: null,
    });
  }
}
