import { useNewDS } from "../../core/utils";
import { Constants } from "../../shared/constants/constants";
import type { SearchTermTypes } from "../../shared/models";

export const BAD_MYSQL_WORDS = [
  "a",
  "about",
  "an",
  "are",
  "as",
  "at",
  "be",
  "by",
  "com",
  "de",
  "en",
  "for",
  "from",
  "how",
  "i",
  "in",
  "is",
  "it",
  "la",
  "of",
  "on",
  "or",
  "that",
  "the",
  "this",
  "to",
  "was",
  "what",
  "when",
  "where",
  "who",
  "will",
  "with",
  "und",
  "the",
  "www",
];

export const hasBadWord = (str: string) => {
  const badWordArray = str.split(" ");
  if (badWordArray.length > 1) {
    return;
  }

  const index = BAD_MYSQL_WORDS.findIndex((val) => val === badWordArray[0] || val.includes(badWordArray[0]));

  return index > -1;
};

type CategoryTypes = {
  type: SearchTermTypes;
  icon: string;
  text: string;
  active: boolean;
  subCategories?: {
    type: "NKPI" | "SKPI" | "EKPI" | "UAE" | "OPM" | "SRVKPI" | "MOKPI" | "MTKPI" | "NTKPI" | "DTKPI";
    icon: string;
    text: string;
    active: boolean;
  }[];
};
export const getSearchCategories = (): CategoryTypes[] => [
  {
    type: "GOVERNMENT_DIRECTION",
    text: "directions.government_directions",
    icon: "adaa-icon-console_govDirections",
    active: true,
  },
  {
    type: "SUB_GOVERNMENT_DIRECTION",
    text: "directions.sub_government",
    icon: "fa-solid fa-repeat",
    active: true,
  },
  {
    type: "INITIATIVE",
    text: useNewDS() ? "national_projects.projects" : "initiatives.initiatives",
    icon: "adaa-icon-console_initiatives",
    active: true,
  },
  {
    type: "KPI",
    text: "kpi.kpis",
    icon: "adaa-icon-console_kpis",
    active: true,
    subCategories: [
      {
        type: "DTKPI",
        text: "dtkpi.dtkpi",
        icon: "adaa-icon-console_dkpi",
        active: true,
      },
      {
        type: "NTKPI",
        text: "ntkpi.ntkpi",
        icon: "adaa-icon-console_nkpi",
        active: true,
      },
      {
        type: "MOKPI",
        text: "mokpi.mokpi",
        icon: "adaa-icon-console_mokpi",
        active: true,
      },
      {
        type: "MTKPI",
        text: "mtkpi.mtkpi",
        icon: "adaa-icon-console_mtkpi",
        active: true,
      },
      {
        type: "SKPI",
        text: "skpi.skpi",
        icon: "adaa-icon-console_skpi",
        active: true,
      },
      {
        type: "EKPI",
        text: "ekpi.ekpi",
        icon: "adaa-icon-console_ekpi",
        active: true,
      },
      {
        type: "SRVKPI",
        text: "srvkpi.srvkpi",
        icon: "adaa-icon-console_srvkpi",
        active: true,
      },
      {
        type: "NKPI",
        text: "nkpi.nkpi",
        icon: "adaa-icon-console_nkpi",
        active: true,
      },
      {
        type: "UAE",
        text: "uae.uae",
        icon: "adaa-icon-console_dkpi",
        active: true,
      },
      {
        type: "OPM",
        text: "opm.opm_measure",
        icon: "adaa-icon-console_opm",
        active: true,
      },
    ],
  },
  {
    type: "OBJECTIVE",
    text: "objectives.objective",
    icon: "adaa-icon-console_objectives",
    active: true,
  },
  {
    type: "TASK",
    text: "tasks.tasks",
    icon: "fa-solid fa-file-circle-check",
    active: true,
  },
  {
    type: "SERVICE",
    text: "search.services",
    icon: "adaa-icon-console_services",
    active: true,
  },
  {
    type: "ATTACHMENT",
    text: "services.attachments",
    icon: "fa-solid fa-paperclip",
    active: true,
  },
  {
    type: "TAG",
    text: "plusbutton.tags",
    icon: "fa-solid fa-tag",
    active: true,
  },
  {
    type: "FORMULA",
    text: "formulas.formula_console_icon",
    icon: "adaa-icon-console_formulas",
    active: true,
  },
  {
    type: "ACTIVITY",
    text: "tasks.activity",
    icon: "adaa-icon-console_activities",
    active: true,
  },
  {
    type: "USER",
    text: "user.console",
    icon: "adaa-icon-console_users",
    active: true,
  },
];

export const getServiceName = (itemType: number, id: number) => {
  switch (itemType) {
    case Constants.CONSTANT_AUXSERVICE:
      return {
        text: "services.auxiliary_service",
        url: ["/console", "services", "aux-service", id],
      };
    case Constants.CONSTANT_VARSERVICE:
      return {
        text: "services.variation_service",
        url: ["/console", "services", "var-service", id],
      };
    default:
      return {
        text: "services.main_service",
        url: ["/console", "services", "main-service", id],
      };
  }
};

export const isSubKPIType = (t: string) =>
  t?.toLowerCase() === "dtkpi" ||
  t?.toLowerCase() === "ekpi" ||
  t?.toLowerCase() === "mokpi" ||
  t?.toLowerCase() === "mtkpi" ||
  t?.toLowerCase() === "nkpi" ||
  t?.toLowerCase() === "ntkpi" ||
  t?.toLowerCase() === "opm" ||
  t?.toLowerCase() === "skpi" ||
  t?.toLowerCase() === "srvkpi" ||
  t?.toLowerCase() === "uae";

export const getKpiName = (itemType: number) => {
  switch (itemType) {
    case Constants.CONSTANT_NKPITYPE:
      return "nkpi.nkpi";
    case Constants.CONSTANT_SKPITYPE:
      return "skpi.skpi";
    case Constants.CONSTANT_EKPI:
      return "ekpi.ekpi";
    case Constants.CONSTANT_OPM:
      return "opm.opm";
    case Constants.CONSTANT_UAEKPI:
      return "uae.uae";
    case Constants.CONSTANT_SRVKPI:
      return "srvkpi.srvkpi";
    case Constants.CONSTANT_MOKPITYPE:
      return "mokpi.mokpi";
    case Constants.CONSTANT_MTKPITYPE:
      return "mtkpi.mtkpi";
    case Constants.CONSTANT_NTKPITYPE:
      return "ntkpi.ntkpi";
    case Constants.CONSTANT_DTKPITYPE:
      return "dtkpi.dtkpi";
    default:
      return "";
  }
};

export const getKpiUrl = (itemType: number, itemId: number): string[] => {
  const url: string[] = ["/console", "kpi"];
  const id = `${itemId}`;
  switch (itemType) {
    case Constants.CONSTANT_NKPITYPE:
      url.push("nkpi", id);
      break;
    case Constants.CONSTANT_SKPITYPE:
      url.push("skpi", id);
      break;
    case Constants.CONSTANT_EKPI:
      url.push("ekpi", id);
      break;
    case Constants.CONSTANT_OPM:
      url.push("opm", id);
      break;
    case Constants.CONSTANT_UAEKPI:
      url.push("uae", id);
      break;
    case Constants.CONSTANT_SRVKPI:
      url.push("srvkpi", id);
      break;
    case Constants.CONSTANT_MOKPITYPE:
      url.push("mokpi", id);
      break;
    case Constants.CONSTANT_MTKPITYPE:
      url.push("mtkpi", id);
      break;
    case Constants.CONSTANT_NTKPITYPE:
      url.push("ntkpi", id);
      break;
    case Constants.CONSTANT_DTKPITYPE:
      url.push("dtkpi", id);
      break;
    default:
      break;
  }
  return url;
};

export const getActivityUrl = (itemId: number, subType: number | undefined) => {
  const url: string[] = ["/console", "activities"];
  if (!useNewDS()) url.push(`${itemId}`);
  else url.push(subType === Constants.CONSTANT_STRATEGIC_ACTIVITYTYPE ? "strategic" : "operational", `${itemId}`);
  return url;
};

export const getProjectsUrl = (itemId: number, subType: number | undefined) => {
  const url: string[] = ["/console", "initiatives"];
  if (!useNewDS()) url.push(`${itemId}`);
  else
    url.push(
      subType === Constants.CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE ? "strategic-projects" : "regular-tasks",
      `${itemId}`
    );

  return url;
};
