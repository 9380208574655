import { Component, inject, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import {
  BreadcrumbComponent as XngBreadcrumbComponent,
  BreadcrumbItemDirective,
  BreadcrumbService,
} from "xng-breadcrumb";

import { AdaaHelper, useNewDS } from "../../../../../core/utils";
import { SystemLayoutService } from "../../../../services";

@Component({
  selector: "adaa-breadcrumb",
  standalone: true,
  imports: [TranslateModule, XngBreadcrumbComponent, BreadcrumbItemDirective],
  templateUrl: "./breadcrumb.component.html",
  styleUrl: "./breadcrumb.component.scss",
})
export class BreadcrumbComponent implements OnInit {
  private readonly _breadcrumbService = inject(BreadcrumbService);
  private readonly _systemLayoutService = inject(SystemLayoutService);

  #untilDestroyed = AdaaHelper.untilDestroyed();

  readonly #setBreadcrumb = () =>
    this._breadcrumbService.set(
      "@initiatives",
      useNewDS() ? "national_projects.projects" : "breadcrumbs.initiative_module.list"
    );

  public ngOnInit() {
    this.#setBreadcrumb();

    this._systemLayoutService.hasCycleChanged$.pipe(this.#untilDestroyed()).subscribe({
      next: () => this.#setBreadcrumb(),
    });
  }
}
