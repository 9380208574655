import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import { type AssociateTagType, MainResponseModel, type TagModelType, type TagType } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class TagsApiService {
  private _mainService = inject(MainService);

  public getAssociatedTags({ itemType, itemId }: { itemType?: number; itemId?: number }) {
    const url = `tag/getAssociatedTags`;

    const qs = new URLSearchParams();
    if (itemId) qs.set("itemId", `${itemId}`);
    if (itemType) qs.set("itemType", `${itemType}`);

    return this._mainService
      .sendRequest<MainResponseModel<TagType[]>>({
        method: "GET",
        url: `${url}?${qs.toString()}`,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getAll({ itemType, itemId }: { itemType?: number; itemId?: number }) {
    const url = `tag/getall`;

    const qs = new URLSearchParams();
    if (itemId) qs.set("itemId", `${itemId}`);
    if (itemType) qs.set("itemType", `${itemType}`);

    return this._mainService
      .sendRequest<MainResponseModel<TagType[]>>({
        method: "GET",
        url: `${url}?${qs.toString()}`,
      })
      .pipe(filter((res) => !res.inError));
  }

  public associateTag(data: AssociateTagType, { itemType, itemId }: { itemType?: number; itemId?: number }) {
    const url = `tag/associateTag`;

    const qs = new URLSearchParams();
    if (itemId) qs.set("itemId", `${itemId}`);
    if (itemType) qs.set("itemType", `${itemType}`);

    return this._mainService
      .sendRequest<MainResponseModel<unknown>>({
        method: "POST",
        url: `${url}?${qs.toString()}`,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public createTag(data: TagModelType, { itemType, itemId }: { itemType?: number; itemId?: number }) {
    const url = `tag/create`;

    const qs = new URLSearchParams();
    if (itemId) qs.set("itemId", `${itemId}`);
    if (itemType) qs.set("itemType", `${itemType}`);

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "POST",
        url: `${url}?${qs.toString()}`,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public deleteTag({ itemType, itemId, id }: { id: number; itemType?: number; itemId?: number }) {
    const url = `tag/deleteAssociation`;

    const qs = new URLSearchParams();
    if (itemId) qs.set("itemId", `${itemId}`);
    if (itemType) qs.set("itemType", `${itemType}`);

    return this._mainService
      .sendRequest<MainResponseModel<unknown>>({
        method: "POST",
        url: `${url}?${qs.toString()}`,
        data: { id },
      })
      .pipe(filter((res) => !res.inError));
  }
}
