import { inject, Injectable } from "@angular/core";
import { map, shareReplay } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { AttributesApiService } from "./attributes-api.service";

@Injectable({
  providedIn: "root",
})
export class ExtendedFieldsService {
  private readonly _attributeApiService = inject(AttributesApiService);

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  getAvailableFields = (itemType: number, itemId?: number) =>
    this._attributeApiService
      .getByItemTypeAvailable({
        id: itemId,
        type: itemType,
      })
      .pipe(shareReplay(1), this.#untilDestroy());

  hasExtendedFields = (itemType: number, itemId?: number) =>
    this.getAvailableFields(itemType, itemId).pipe(
      map(({ responseData }) => Array.isArray(responseData) && responseData.length > 0)
    );
}
