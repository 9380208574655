import { inject, Injectable } from "@angular/core";

import { environment } from "../../core/environments/environment";
import { Constants } from "../constants/constants";
import { UserLoginStorage } from "../models";
import { LoginService } from "./login.service";

@Injectable({
  providedIn: "root",
})
export class LogoutService {
  //NOTE: Don't Use AppService it will break the service
  private _loginService = inject(LoginService);

  private _unRemovableStorageItem = [
    Constants.localStorageKeys.lastUser,
    Constants.localStorageKeys.firstVisit,
    Constants.localStorageKeys.lastUrlVisited,
    Constants.localStorageKeys.language,
    Constants.localStorageKeys.hardwareUuid,
  ];
  private _unRemovableStorageValues: UserLoginStorage[] = [];

  public logout(skipBERequest: boolean = false): void {
    if (skipBERequest) {
      const isUaePassLogin = localStorage.getItem(Constants.localStorageKeys.uaePassLogin) === "true";
      this._successLogout(isUaePassLogin);
    } else
      this._loginService.logout().subscribe((response) => {
        if (response.inError) return;

        const isUaePassLogin = localStorage.getItem(Constants.localStorageKeys.uaePassLogin) === "true";
        this._successLogout(isUaePassLogin);
      });
  }

  public setLastUrlVisited(url: string): void {
    localStorage.setItem(Constants.localStorageKeys.lastUrlVisited, url);
  }

  private _successLogout(isUaePassLogin: boolean): void {
    this._unRemovableStorageItem.forEach((item) => {
      this._unRemovableStorageValues.push({
        key: item,
        value: localStorage.getItem(item),
      });
    });

    localStorage.clear();

    this._unRemovableStorageValues.forEach((storage: UserLoginStorage) => {
      if (storage.value) {
        localStorage.setItem(storage.key, storage.value);
      }
    });

    if (isUaePassLogin) {
      const url = `${environment.uaePass.logoutUrl}/?redirect_uri=${environment.uaePass.loginUrl}`;
      window.location.href = url;
    } else {
      window.location.replace("/login");
    }
  }
}
