import { Component, inject, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import * as fileSaver from "file-saver";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { Language } from "../../../constants/enums";
import { RelatedObjectDetails, TableActionEvent } from "../../../models";
import { AgreementDetails } from "../../../models/tp-agreements.model";
import { FilesApiService, UsersApiService } from "../../../services";
import { DataTableComponent } from "../../table/data-table/data-table.component";

@Component({
  selector: "adaa-related-objects-modal",
  standalone: true,
  imports: [TranslateModule, DataTableComponent],
  templateUrl: "./related-objects-modal.component.html",
  styleUrl: "./related-objects-modal.component.scss",
})
export class RelatedObjectsModalComponent implements OnInit {
  @Input() itemTypeId: number;
  @Input() entityId: number;
  @Input() orgUnitId: number;
  @Input() agreementData: AgreementDetails;

  private _usersApiService = inject(UsersApiService);
  private _router = inject(Router);
  private _filesApiService = inject(FilesApiService);
  modal: NgbActiveModal = inject(NgbActiveModal);

  userPermissions: RelatedObjectDetails[] = [];

  public ngOnInit(): void {
    this._usersApiService.getPermissionDetail(this.itemTypeId, this.entityId, this.orgUnitId).subscribe({
      next: (response) => {
        if (response.inError) return;

        this.userPermissions = response.responseData;
      },
    });
  }

  public actionClicked(event: TableActionEvent): void {
    const url = Constants.ITEM_TYPE_ROUTES.find((e) => e.itemTypeId === event.data.itemTypeId);
    if (url?.itemTypeId === Constants.CONSTANT_PERMISSIONS.TRANSFORMATIONAL_PROJECT_AGREEMENT) {
      this._downloadFile(event.data);
      return;
    }
    if (url) {
      this.modal.close();
      if (
        url.itemTypeId === Constants.CONSTANT_PERMISSIONS.ORG_UNIT ||
        url.itemTypeId === Constants.CONSTANT_PERMISSIONS.ENTITY_ADDRESSES
      ) {
        this._router.navigateByUrl(`${url.route}`);
      } else this._router.navigateByUrl(`${url.route}${event.data.objectId}`);
    }
  }

  private _downloadFile(data: RelatedObjectDetails) {
    this._filesApiService.download(data.objectId).subscribe({
      next: (blob) => {
        fileSaver.saveAs(
          blob,
          (AdaaHelper.getCurrentLang() === Language.Arabic ? data.itemTypeNameAE : data.itemTypeNameEN) as string
        );
      },
    });
  }
}
