{
  "add": "Add",
  "waiting_translation": "Waiting for Translation",
  "configuration": "Workflow configuration",
  "premissions_error": "Permission Error",
  "sdg": {
    "breadcrumb": "View SDG Goal",
    "goals_achievement": "SDG Achievement",
    "goal_achievement": "Goal Achievement",
    "initiatives_expenditure": "SDG Expenses",
    "goal_expenses": "Goal Expenses",
    "goal_budget": "Goal Budget",
    "allocated_budget": "SDG Budget",
    "total_initiatives": "Total Initiatives",
    "sdg_target_achievement": "SDG Achievement",
    "goal_targets": "Goal Targets",
    "goal_initiatives": "Goal Initiatives",
    "title": "Sustainable Development Goals"
  },
  "new_password": {
    "error": {
      "error_message": "Your password must:",
      "check_english_letters": "Be part of the English Alphabet [A-Z] [a-z]",
      "check_numbers": "Have numbers [0-9]",
      "check_password_length": "Have at least 8 characters"
    }
  },
  "monitoring": {
    "email": "Email",
    "name": "Name",
    "username": "Username"
  },
  "access_denied": {
    "title": "Access denied",
    "body": "You don't have permission to access the page you requested"
  },
  "calculation": {
    "calculation_management": "Calculation Management",
    "calculation_entity": "Entity Performance Configuration",
    "calculation_government": "Government Performance Configuration",
    "calculation_engine_status": "Calculation Engine Status",
    "calculation_queue_mng": "Calculation Management",
    "calculation_queue": "Calculation Queue",
    "last_run": "Last Run",
    "restart": "Restart",
    "object_type": "Object Type",
    "calculation_date": "Calculation Date",
    "result_set": "Result Set",
    "launch": "Launch"
  },
  "helpdesk": {
    "edit": "Edit Helpdesk Ticket",
    "new_ticket": "New Ticket",
    "attachment": "Attachment",
    "service_now_status": "Support Request Status",
    "changes_justifications": "Changes justification",
    "comments_log": "Comments Log",
    "comment_add": "Add comment",
    "attachments": "Attachments",
    "attachment_add": "Add attachment",
    "on": "- ",
    "comment": "Comment Details",
    "comments": "Comments Identifier",
    "expected_resolution_date": "Expected resolution date",
    "attachment_name": "Attachment name",
    "service_now_reference": "Help Desk Reference",
    "status": "Status",
    "assigned_to": "Assigned to",
    "creation_date": "Creation date",
    "created_by": "Created by",
    "url": "URL",
    "object": "Item",
    "section": "Section",
    "priority": "Priority",
    "create": "New Helpdesk Ticket",
    "type": "Type",
    "ticket": {
      "priority": {
        "critical": "Critical",
        "high": "High",
        "medium": "Medium",
        "low": "Low"
      },
      "type": {
        "clarification": "Clarification",
        "error": "Error",
        "change": "Change",
        "other": "Other"
      },
      "status": {
        "new": "New",
        "in_progress": "In progress",
        "resolved": "Resolved",
        "reopen": "Reopen",
        "closed": "Closed"
      }
    },
    "console": "Support Center",
    "title": "Support Center",
    "chart": {
      "unresolved_issues_per_priority": "Unresolved issues per priority",
      "issues_per_status": "Issues per status"
    }
  },
  "language": {
    "forms": {
      "arabic": "Arabic",
      "english": "English"
    },
    "change_lang": "العربية"
  },
  "report": {
    "org_unit": "KPI Organization Unit",
    "owner": "KPI Owner",
    "select_strategic_year": "Select Year",
    "pre_generated_report_alert": "If available, a pre-generated report will be downloaded",
    "info_message_pmo": "Please select Kpi Type and at least one entity",
    "info_message_entity": "Please select Kpi Type",
    "title": "Reports",
    "output_format": "Output Format",
    "kpi_type": "KPI Type",
    "show_benchmark": "Include Benchmark",
    "loading_report": "Loading report...",
    "nkpi_list": "NKPI List",
    "dkpi_list": "DKPI List",
    "entities_list": "Entities List",
    "ekpi_list": "EKPI List",
    "initiative_list": "Initaitives List",
    "empty_report": "Empty Report",
    "pdf": "PDF",
    "excel": "EXCEL",
    "word": "WORD",
    "language": "Language",
    "pillar_list": "Pillars",
    "expiration_date": "End Date",
    "please_select": "Please Select",
    "background_message": "Note: The report is being generated, if you wish to navigate to other screens, you can retrieve your downloaded report from Downloaded Report module in the Console.",
    "strategic_cycle_list": "Please choose the strategic cycle you wish to compare with:",
    "generated_reports": {
      "title": "Downloaded Reports",
      "console_menu_item": "Downloaded Reports"
    },
    "messages": {
      "more_than_10_reports": "You have selected more than 10 reports.  Please reduce your selection to 10 or less",
      "more_than_10_user_msg": "A maximum of 10 reports can be selected"
    }
  },
  "notification": {
    "error": {
      "code_17_2": "Exceeded maximum number of login attempts",
      "negative_expenses": "Expenses cannot be less than zero",
      "negative_budget": "Budget cannot be less than zero",
      "dm_resources_date_task_range": "Start and End Date do not match the Task Start and End Date",
      "dm_resources_date_activity_range": "Start and End Date do not match the Activity Start and End Date",
      "ad_user_not_exists": "The user is not available, please contact the system administrator",
      "password_requirements_failed": "Password does not meet length, complexity or history requirement of the domain.",
      "action_date_filter_error": "Custom Range only allows a range of 3 months",
      "error_uploading": "There was an error while uploading the file",
      "save": "There was an error saving information",
      "generic": "There was an error. Please Try again",
      "code_generic": "There was an error. Please Try again",
      "dates_difference": "Please select another date onwards",
      "code_50": "Username already in use ",
      "code_55": "The email is already in use",
      "code_57": "The user does not exist. Please contact the system administrator",
      "code_14": "Vision already exists",
      "code_2": "DataBase is Busy. Please Try again. If the problem persists please contact the administrator.",
      "code_26": "DataBase is Busy. Please Try again. If the problem persists please contact the administrator.",
      "valuesMinMaxDifference": "Upper Limit cannot be higher that Lower Limit",
      "rule_exists": "Already exists a rule with inserted frequency and item type",
      "dates_span_initiative": "The activity dates aren't within the selected Initiative Dates",
      "dates_span_activity": "The task dates aren't within the selected Activity Dates",
      "session_expired": "Your session has expired. Please log back in again",
      "object_edited_by_another_user": "This object is being edited by another user",
      "object_in_approval": "This object is under approval process and cannot be edited",
      "file_type_invalid": "Invalid File Type",
      "notmatch": "Old password doesn't match",
      "no_periods": "No periods for selected dates",
      "workload_not_100": "Workload of Tasks must be 100",
      "workload": "Weights must be a number between 0 and 100",
      "progress": "Progress must be a number between 0 and 100",
      "code_21": "There can only be one Plan for one interval of time",
      "code_56": "Old password doesn't match",
      "code_22": "The minimum duration for a cycle must be one month",
      "code_29": "Invalid Captcha",
      "measurement_unit_required": "Measurement Unit sould be selected!",
      "removeUser": "It's not possible to delete your own user",
      "twoTasks": "Each activity should have at least two tasks",
      "task_same_name": "Already exist a task for this activity with this name",
      "dependency_exists": "Dependency Exists",
      "removeLookup": "An error occurred while deleting the lookup item",
      "code_8": "Invalid Login",
      "integrity_system": "Integrity System",
      "somethingWrong_tryAgain": "Ups! Something went wrong, please try again !",
      "upload_error": "There was an error",
      "org_unit_in_use": "The Organization Unit you tried to delete is currently in use",
      "risk_same_name": "Already exist a risk for this activity with this name",
      "object_in_use": "This object is being used!",
      "activities_workload_not_100": "Activities weight must be 100",
      "wrong_place": "Action not allowed",
      "initiative_under_objective": "Initiatives only under Objectives",
      "activity_under_initiative": "Activities only under Initiatives",
      "opm_under_activity": "Operational KPI's only under Activities",
      "skpi_under_objective": "Strategic KPI's only under Objectives",
      "cycle_remove_entities": "You cannot remove entities from Active Plans",
      "code_23": "Forbidden",
      "atributtes_add_error_4001": "The attribute cannot be deleted as it’s marked as “Added”",
      "download_file": "There was an error downloading the file. Please try again later.",
      "atributtes_add_error_4000": "The attribute is mandatory"
    },
    "warning": {
      "user_group_must_have_users": "The User Group must have at least 1 user",
      "no_selection": "Please select at least one item",
      "missing_info": "Please check form information",
      "missing_map_id": "There's no plan for this cycle",
      "missing_connections": "Please select at least one item to connect",
      "data_updated": "Data has been updated",
      "missing_data": "Missing Data",
      "required_field": "Please fill out this field",
      "fileSize": "The file is too large. Please select another file",
      "sponsor_other_entity": "Other Sponsor Entity is missing",
      "password_must_field": "All password fields must be filled in",
      "passwords_must_match": "Passwords must match",
      "passwordMinimum": "The password must have at least 7 characters",
      "required_field_user_profile": "Please fill mobile phone and/or security questions",
      "no_data_to_modify": "No changes since last edition!",
      "invalid_email": "Invalid Email!",
      "will_affect": "This change will affect the new items, though old items will not be affected",
      "draft_subkpis": "Sub-KPIS must be activated",
      "date_change_check_actuals": "The dates have been modified, please check the actual values",
      "repeated_usergroup": "User group is already selected for this configuration",
      "no_usergroups": "Please select a user group for this configuration",
      "only_numbers": "This field only accept numbers",
      "formula_validation": "The Formula needs to be validated",
      "formula_activated": "Formula is now active",
      "formula_diactivated": "Formula is now inactive",
      "formula_statuschanged": "The Formula Status was changed with success",
      "text_filter_length": "The text fields must at least have 3 letters",
      "text_search_length": "The search text box fields must at least have 3 letters",
      "n_a": "N/A"
    },
    "success": {
      "deactivate": "Deactivated successfuly",
      "activate": "Activated successfuly",
      "save": "Information saved",
      "remove": "Information deleted",
      "last": "You have deleted the last OPM related to an activity!",
      "upload_complete": "Upload complete"
    },
    "no_objectives": {
      "missing_objectives": "There's no objectives for the selected plan and entity"
    },
    "no_changes": "No data changed"
  },
  "userBar": {
    "profile": "Profile",
    "contacts": "Contacts",
    "mailbox": "Mailbox",
    "logout": "Logout"
  },
  "navigation": {
    "dashboard": "Dashboard STG",
    "menu": "Plan Navigation",
    "home": "Homepage",
    "console": "Console",
    "tree_view": "Tree View",
    "list_view": "List View",
    "plan_wizard": "Plan Wizard",
    "auditing": "Audit Module",
    "reporting": "Reports",
    "messages": "Notifications Center",
    "help": "Support Center",
    "send_email": "Send Email",
    "plan_navigation": "Plan Navigation",
    "menu_name": "Notifications Center"
  },
  "targets": {
    "periods": {
      "quarters": {
        "1": "Q1",
        "2": "Q2",
        "3": "Q3",
        "4": "Q4"
      },
      "semestral": {
        "1": "SM1",
        "2": "SM2"
      }
    },
    "quarter": "Q",
    "semiannual": "Semestral",
    "annual": "Year",
    "every_two_years": "List View",
    "every_three_years": "Plan Editor",
    "every_four_years": "Auditing",
    "every_five_years": "Reporting"
  },
  "data_entry": {
    "has_audit_issues": "Has Audit Issue",
    "audited_data_entry_title": "Insert Audited Actuals",
    "annual_actual": "Annual Actual",
    "annual_target": "Annual Target",
    "audited_annual_actual": "Audited Annual Actual",
    "calc_target": "Calculated Target",
    "calc_actual": "Calculated Actual",
    "multiple_files": "Attachments",
    "kpi_type_name": "Object Type",
    "metric_description": "Metric Description",
    "metric_name": "Metric Name",
    "kpi_name": "KPI Name",
    "kpi_type": "KPI Type",
    "linked_to": "Linked to",
    "period": "Date",
    "frequency": "Frequency",
    "percentage": "Percentage of Completion ",
    "performance": "Performance",
    "target": "Target",
    "value": "Value",
    "formula": "Formula",
    "objective_name": "Objective Name",
    "sponsor": "Sponsor",
    "score": "Score",
    "areas_of_improvement": "Areas Of Improvement",
    "recommendations": "Recommendations",
    "analysis": "Analysis",
    "metrics": "Metrics",
    "kpi_actuals": "KPI Actuals",
    "edit_actuals": "Edit Actuals",
    "description": "Description",
    "activity_name": "Activity Name",
    "initiative_name": "Initiative Name",
    "previous": "Previous",
    "actual": "Actual",
    "status": "Status",
    "analysisEN": "Analysis (EN)",
    "analysisAE": "Analysis (AE)",
    "recomendationsEN": "Recomendations (EN)",
    "recomendationsAE": "Recomendations (AE)",
    "areasOfImprovementsEN": "Areas of Improvement (English)",
    "areasOfImprovementsAE": "Areas of Improvements (Arabic)",
    "approved": "Approved",
    "due": "Due",
    "toBeApproved": "To Be Approved",
    "actuals": "Actuals",
    "cannotAddMessage": "You have already a value to that period",
    "metric_value_delete_yes_no_title": "Delete Value",
    "metric_value_delete_yes_no_information": "Value will be deleted!",
    "metric_add_edit_title": "Warning!",
    "metric_add_edit_information": "You are editing data for a metric that is shared by multiple KPIs.  This action will cause the data for those KPIs to be updated accordingly and the performance recalculated. Do you want to proceed ?",
    "edit_value": "Edit Value",
    "enter_value": "Value",
    "overdue": "Overdue",
    "pillar_name": "Pillar",
    "no_periods": "No periods available for data entry",
    "metrics_title": "Metrics",
    "govdir": "Sub-Government Direction Name",
    "subService_name": "Sub Service Name",
    "mainService_name": "Main Service Name",
    "parent_KPI": "Parent KPI",
    "sponsor_other": "Other Sponsor",
    "max_textarea_chars": "The maximum number of characters you can insert is 3000",
    "attachment_data_entry": "Data Entry",
    "value_empty": "Value is empty",
    "notifications": {
      "CODE_27": "Request sent to Approval",
      "DONE_OK_01": "Success",
      "DONE_OK_02": "Success",
      "DONE_ERR_01": "No Actual information",
      "DONE_ERR_02": "No Target information",
      "DONE_ERR_03": "No Actual and Target information",
      "NOT_PROCESSED_01": "Not Processed Yet",
      "NOT_PROCESSED_02": "Not Processed Yet",
      "ERR_01": "Error Processing",
      "NO_PROCESS_INFO": "Not Processed",
      "no_actual": "No Value",
      "no_target": "No Target"
    },
    "q1": "First Quarter",
    "q2": "Second Quarter",
    "q3": "Third Quarter",
    "q4": "Forth Quarter",
    "calc": "Calc",
    "actual_target": "Actuals & Targets",
    "target_upper_limit": "Target Upper Limit",
    "target_lower_limit": "Target Lower Limit",
    "edit_title": "Insert Actuals",
    "show_dimension": "Options",
    "show_targets": "Targets",
    "show_calculation": "Calculation",
    "metric_approved": "Approved",
    "metric_under_approval": "In review",
    "no_dimensions": "There are no dimensions for this KPI"
  },
  "breadcrumbs": {
    "audit_issue": "Audit Issues",
    "rule_management": {
      "view": "View Ruleset",
      "edit": "Edit Ruleset",
      "add": "Add Ruleset"
    },
    "contextual_help": {
      "view": "View Contextual Help",
      "edit": "Edit Contextual Help",
      "add": "New Contextual Help",
      "list": "Contextual Help"
    },
    "faqs_category": {
      "list": "FAQs Category",
      "view": "View FAQ Category",
      "edit": "Edit FAQ Category",
      "new": "New FAQ Category"
    },
    "faqs": {
      "list": "FAQs",
      "view": "View FAQ",
      "edit": "Edit FAQ",
      "new": "New FAQ",
      "faqs-search": "FAQs"
    },
    "smart-tool-tips": {
      "list": "Smart Tooltips",
      "edit": "Edit Smart Tooltip"
    },
    "formulas": {
      "view": "View Formula Template",
      "edit": "Edit Formula Template",
      "new": "New Formula Template"
    },
    "exec_team": {
      "view": "View Executive Team",
      "list": "Executive Teams",
      "edit": "Edit Executive Team",
      "new": "New Executive Team"
    },
    "ekpi_module": {
      "view": "View Enabler KPI",
      "list": "Enabler KPI",
      "edit": "Edit Enabler KPI",
      "new": "New Enabler KPI"
    },
    "srvkpi_module": {
      "view": "View Service KPI",
      "list": "Service KPI",
      "edit": "Edit Service KPI",
      "new": "New Service KPI"
    },
    "opm_module": {
      "view": "View Operational Management KPI",
      "list": "Operational Management KPI",
      "edit": "Edit Operational Management KPI",
      "new": "New Operational Management KPI"
    },
    "dkpi_module": {
      "view": "View Directional KPI",
      "list": "Directional KPI",
      "edit": "Edit Directional KPI",
      "new": "New Directional KPI"
    },
    "nkpi_module": {
      "view": "View National KPI",
      "list": "National KPI",
      "new": "New National KPI",
      "edit": "Edit National KPI"
    },
    "skpi_module": {
      "view": "View Strategic KPI",
      "list": "Strategic KPI",
      "edit": "Edit Strategic KPI",
      "new": "New Strategic KPI"
    },
    "activity": {
      "view": "View Activity",
      "list": "Activities",
      "new": "New Activity",
      "edit": "Edit Activity"
    },
    "initiative_module": {
      "view": "View Initiative",
      "list": "Initiatives",
      "new": "New Initiative",
      "edit": "Edit Initiative"
    },
    "help_desk_module": {
      "view": "View Support Ticket",
      "list": "Support Center",
      "new": "New Support Ticket",
      "edit": "Edit Support Ticket"
    },
    "attributes_module": {
      "view": "View Attribute",
      "list": "Attributes",
      "new": "New Attribute",
      "edit": "Edit Attribute"
    },
    "dimensions_module": {
      "view": "View Dimension",
      "list": "Dimensions",
      "new": "New Dimension",
      "edit": "Edit Dimension"
    },
    "directions_module": {
      "view": "View Government Direction",
      "list": "Government Directions",
      "new": "New Government Direction",
      "edit": "Edit Government Direction"
    },
    "entity_module": {
      "view": "View Entity",
      "list": "Entities",
      "new": "New Entity",
      "edit": "Edit Entity"
    },
    "cycle_module": {
      "view": "View Cycle",
      "list": "Cycles",
      "new": "New Cycle",
      "edit": "Edit Cycle"
    },
    "pillar_module": {
      "view": "View Pillar",
      "list": "Pillars",
      "new": "New Pillar",
      "edit": "Edit Pillar"
    },
    "wf_monitoring_module": {
      "list": "Workflow Monitoring"
    },
    "wf_trigger_module": {
      "view": "View Workflow Trigger",
      "list": "Workflow Triggers",
      "new": "New Workflow Trigger",
      "edit": "Edit Workflow Trigger"
    },
    "plan_review_module": {
      "list": "Review and Approve Plan"
    },
    "translation_module": {
      "view": "Translations"
    },
    "audit_trail_module": {
      "list": "System Logs"
    },
    "audit_data_module": {
      "kpi_actuals": "Audit KPI Actuals",
      "sub_menu": "Audit"
    },
    "reporting": "Reports",
    "user_manual": "User Manual",
    "user_profile": "User Profile",
    "console": "Console",
    "users": "Users",
    "access_profiles": "Access Profiles",
    "access-profiles": "Access Profiles",
    "access_profiles_new": "New Profile",
    "access_profiles_edit": "Edit Profile",
    "entities": "Entities",
    "metrics": "Metrics",
    "dimensions": "Dimensions",
    "attributes": "Attributes",
    "enablerobjectives": {
      "view": "View Enabler Objective",
      "list": "Enabler Objectives",
      "new": "New Enabler Objective",
      "edit": "Edit Enabler Objective"
    },
    "objectives": {
      "edit": "Edit Objective",
      "objectives": "Objectives",
      "detail": "Objectives",
      "create": "New Objective",
      "list": "Objectives List",
      "key_success_factors": "Key Success Factors"
    },
    "objective": "Objective",
    "enablerobjective": "Enabler Objectives",
    "initiatives": "Initiatives",
    "initiative": "Initiative",
    "new_initiative": "New Initiative",
    "activities": "Activities",
    "kpi": "KPIs",
    "nkpi": "National KPI",
    "new_nkpi": "New National KPI",
    "skpi": "Strategic KPI",
    "ekpi": "Enabler KPI",
    "uae": "Directional KPI",
    "opm": "Operational Management KPI",
    "directions": "Government Directions",
    "services": {
      "expenditures": "Expenditures",
      "edit_variation_service": "Edit Variation Service",
      "edit_auxiliary_service": "Edit Auxiliary Service",
      "edit_main_service": "Edit Main Service",
      "add_variation_service": "Add Variation Service",
      "add_auxiliary_service": "Add Auxiliary Service",
      "add_main_service": "Add Main Service",
      "variation_service": "Variation Service",
      "auxiliary_service": "Auxiliary Service",
      "main_service": "Main Service",
      "list": "Services"
    },
    "cycles": "Cycles",
    "pillars": "Pillars",
    "mandatory": "Mandatory Fields",
    "data-entry": "Data Entry",
    "executive-teams": "Executive Teams",
    "provisioning": "Entity Profile",
    "dashboard": "Dashboard",
    "communications": "Notifications Center",
    "detail": "Detail",
    "kpis": {
      "kpi": "KPI",
      "national": "National KPI",
      "create_national": "Create National KPI",
      "strategic": "Strategic KPI",
      "create_strategic": "Create Strategic KPI"
    },
    "mandatory_fields": "Mandatory Fields",
    "create_strategic": "Create Strategic KPI",
    "short_opm": "OPM KPI",
    "enabler": "Enabler",
    "workflow_profiles": "Workflow Profiles",
    "create": "Create",
    "Formulas": "Formulas",
    "help_modal": "Help Modal",
    "search": "Search",
    "srvkpi": "Service KPI",
    "list": "List",
    "edit": "Edit",
    "new": "New",
    "khadamati": {
      "title": "Khadamati Integration",
      "list": "Synchronizations List",
      "schedule": "Synchronizations Schedule"
    },
    "metric_edit": "Edit Metric",
    "edit_service": "Edit Service",
    "edit_user": "Edit User",
    "user_related_objects": "Related Objects",
    "edit_user_roles": "Edit User Role",
    "edit_user_groups": "Edit User Group",
    "edit_help_modal": "Edit Help Modal",
    "new_user": "New User",
    "new_user_role": "New User Role",
    "new_user_group": "New user group",
    "new_dkpi": "New Directional KPI",
    "edit_dkpi": "Edit Directional KPI",
    "view_dimension": "View Dimension",
    "view_metric": "View Metric",
    "view_entity": "View Entity",
    "view_attribute": "View Attribute",
    "view_initiative": "View Initiative",
    "view_enabler_objectives": "View Enabler Objective",
    "view_objective": "View Objective",
    "view_activity": "View Activity",
    "view_nkpi": "View National KPI",
    "view_skpi": "View Strategic KPI",
    "view_ekpi": "View Enabler KPI",
    "view_opm": "View Operational Management KPI",
    "view_dkpi": "View Directional KPI",
    "view_srvkpi": "View Service KPI",
    "view_direction": "View Government Direction",
    "view_var_service": "View Variation Service",
    "view_aux_service": "View Auxiliary Service",
    "view_main_service": "View Main Service",
    "view_service": "View Service",
    "view_cycle": "View Cycle",
    "view_pillar": "View Pillar",
    "view_exec_team": "View Executive Team",
    "view_user_group": "View User Group",
    "view_user_role": "View User Role",
    "view_user": "View User",
    "view_helpdesk": "View Helpdesk",
    "notifications": "Notifications Management",
    "user": {
      "user": "User",
      "profile": "Profile"
    },
    "delivery_module": {
      "title": "Delivery Module",
      "view": "View Delivery Module",
      "edit": "Edit Delivery Module"
    },
    "text-mapping": "Text Mappings",
    "text_mappings_create": "New Text Mapping",
    "text_mappings_edit": "Edit Text Mapping",
    "text_mappings_view": "View Text Mapping",
    "helpdesk_add": "Add Helpdesk Ticket",
    "edit_mandatory_fields": "Edit Mandatory Fields",
    "plan_import_export": "Plan Import / Export",
    "workflow": {
      "edit_assignment": "Edit Workflow Configuration",
      "add_assignment": "New Workflow Configuration",
      "view_assignment": "View Workflow Configuration",
      "edit_trigger": "Edit Workflow Trigger",
      "add_trigger": "New Workflow Trigger",
      "view_trigger": "View Workflow Trigger"
    },
    "import_export": "Import / Export",
    "kpi_annual_weights": "KPI Annual Weights"
  },
  "leftbar": {
    "copy_right": "All rights reserved",
    "dashboard": "Dashboard",
    "quick_links": "Quick Links",
    "objectives": "Objectives",
    "reporting": "Reporting",
    "model": "Model",
    "messages": "Messages",
    "help": "Help",
    "hello": "Hello",
    "search": "Search",
    "pmo": "PMO",
    "administrator": "Administrator",
    "metrics": "Metrics",
    "datasources": "Data Sources",
    "milestones": "Milestones",
    "kpitypes": "KPI Types"
  },
  "help": {
    "faqs": {
      "title": "FAQs",
      "answerAE": "Answer (Arabic)",
      "answerEN": "Answer (English)",
      "questionAE": "Question (Arabic)",
      "questionEN": "Question (English)",
      "icon": "Image",
      "onlineHelpLinkAE": "User Manual Link (Arabic)",
      "onlineHelpLinkEN": "User Manual Link (English)",
      "videoLinkAE": "Video Link (Arabic)",
      "videoLinkEN": "Video Link (English)",
      "published": "Published",
      "delete_yes_no_title": "FAQ Delete Confirmation",
      "delete_yes_no_information": "FAQ is about to be deleted",
      "upload_tool_tip": "Choose an image with  'svg' type "
    },
    "faqs-category": {
      "title": "FAQs Category",
      "edit_category": "Edit FAQ Category",
      "add_new_category": "New FAQ Category",
      "delete_yes_no_title": "FAQ Category Delete Confirmation",
      "delete_yes_no_information": "FAQ Category is about to be deleted",
      "cancel_yes_no_title": "Cancel Confirmation",
      "cancel_yes_no_information": "You are about to cancel"
    },
    "help_modal": {
      "delete": {
        "delete_yes_no_information": "Contextual Help entry is about to be deleted",
        "delete_yes_no_title": "Contextual Help Delete Confirmation"
      },
      "title": "Contextual Help",
      "page_content_ae": "Page Content (Arabic)",
      "page_content_en": "Page Content (English)",
      "section_dscAE": "Page Content (AE)",
      "section_dscEN": "Page Content (EN)",
      "section_titleEN": "Page Title (English)",
      "section_titleAE": "Page Title (Arabic)",
      "section_publish": "Publish Page",
      "imageAE": "Image Path (Arabic)",
      "imageEN": "Image Path (English)",
      "image_only": "Image Only",
      "page_title": "Page Title",
      "page_description": "Page Description",
      "image_path": "Image Path",
      "contextual_published": "Publish Contextual Help",
      "video_en_url": "Video URL (EN)",
      "video_ae_url": "Video URL (AE)",
      "page_name": "Page Name",
      "add_step": "Add Content",
      "edit_step": "Edit Content"
    },
    "contextual_help": "Contextual Help",
    "help": "Online Help",
    "smart-tool-tips": "Smart Tooltips",
    "user-manual": "User Manual"
  },
  "topbar": {
    "help": {
      "context": "Contextual Help",
      "guided": "Guided Tour",
      "faq": "FAQs",
      "entitymanual": "User Manual",
      "usermanual": "User Manual - PDF"
    },
    "pmo": "PMO",
    "control_panel": "Control Panel"
  },
  "activities": {
    "cancel_yes_no_title": "Activity Cancellation Confirmation",
    "cancel_yes_no_information": "Are you sure you want to cancel the activity?",
    "title": "Activities",
    "activity": "Activity",
    "department": "Organization Unit",
    "risks": "Risks",
    "risk": "Risk",
    "addRisk": "New Risk",
    "impact": "Impact",
    "name": "Name",
    "delete_yes_no_title": "Activity Delete Confirmation",
    "delete_yes_no_information": "The activity is about to be deleted",
    "expenses": "Expenses",
    "variances": "Variances",
    "workload": "Activity Weight",
    "hyperiondRefCode": "Hyperion System Reference",
    "budget": "Budget",
    "objective": "Objective",
    "activity_canceled": "The Activity was canceled!",
    "cancel_activity": "Cancel Activity"
  },
  "dashboard": {
    "strategies_directions_performance": "National Strategies Performance",
    "initiatives_progress": "Initiative progress",
    "investment_per_objective": "Investment Per Objective",
    "updates": "Updates",
    "pillars": {
      "min": "Min",
      "max": "Max",
      "last": "Last",
      "sum": "Sum"
    }
  },
  "attributes": {
    "lov": "Value",
    "add": "Add",
    "add_new_lov": "Add New Value",
    "create_new": "New Attribute",
    "attributes": "Attributes",
    "create": {
      "information": "information",
      "add_button_list": "New Attribute",
      "list": "Attributes",
      "title": "Attributes",
      "list_of_values": "List of Values",
      "attribute": "Attribute",
      "item_type": "Limit to Item Type",
      "data_type": "Data Type",
      "delete_yes_no_title": "Attribute Delete Confirmation",
      "delete_yes_no_information": "The attribute is about to be deleted"
    }
  },
  "entity": {
    "max_file_size": "Max File size allowed: 2048 KB",
    "files_allowed": "File Types Allowed: PNG, GIF, JPEG, SVG, BMP",
    "strategic_manager": "Strategic Manager",
    "nkpi_focal_point": "NKPI Focal Point",
    "ekpi_focal_point": "EKPI Focal Point",
    "services_focal_point": "Services Focal Point",
    "dkpi_focal_point": "DKPI Focal Point",
    "skpiopm_focal_point": "SKPI / OPM Focal Point",
    "opm_focal_point": "OPM Focal Point",
    "strategic_focal_point": "Strategic Focal Point",
    "strategic_team": "Strategic Performance Team",
    "nkpi_team": "National Agenda Team",
    "dkpi_team": "Government Directional  Team",
    "ekpi_team": "Government  Enabler  Team",
    "services_team": "Government Service Team",
    "opm_team": "OPM Team",
    "create": {
      "title": "New Entity",
      "pillar_entity": "Status & Pillar",
      "information": "Information",
      "list": "Entities",
      "department_unit": "Department Unit",
      "people": "People",
      "is_pillar": "Is a Pillar Entity",
      "add_button_list": "New Entity",
      "type": "Type",
      "entity": "Entity",
      "geo_location": "Geo Location",
      "shortNameAE": "Short Name (Arabic)",
      "shortNameEN": "Short Name (English)",
      "add_new_address": "New Location",
      "federal": "Federal",
      "enabler": "Enabler",
      "add_entity_logo": "New Entity Logo",
      "address_arabic": "Address (Arabic)",
      "address_english": "Address (English)",
      "zip_code_arabic": "P.O Box",
      "zip_code_english": "P.O Box",
      "city_arabic": "City (Arabic)",
      "city_english": "City (English)",
      "geo_x": "Longitude",
      "geo-y": "Latitude",
      "entity_offices": "Entity Offices",
      "pillars": "Pillars",
      "hq": "Headquarters",
      "delete_yes_no_title": "Entity Delete Confirmation",
      "delete_yes_no_information": "The entity is about to be deleted",
      "add_new_user": "New User",
      "add_new_org_unit": "New Organization Unit",
      "org_unit": "Organization Unit",
      "nameAE": "Name (Arabic)",
      "nameEN": "Name (English)",
      "part_of": "Part Of",
      "level": "Organization Level",
      "manager": "Manager",
      "entity_type": "Entity",
      "logo_horizontal": "Horizontal Logo",
      "logo_vertical": "Vertical Logo",
      "shortName": "Short Name",
      "horizontal_logo_guidelines": "Logo size should be 820 pixels width and 129 pixels height",
      "vertical_logo_guidelines": "Logo size should be 129 pixels width and 820 pixels height",
      "edit_org_unit": "Edit Organization Unit"
    }
  },
  "lov": {
    "value_delete_yes_no_information": "The value is about to be deleted",
    "value_delete_yes_no_title": "Value Delete Confirmation"
  },
  "user": {
    "create": {
      "title": "Users",
      "add_button_list": "New User",
      "delete_yes_no_title": "User Delete Confirmation",
      "delete_yes_no_information": "The User is about to be deleted",
      "add_role": "Add Role",
      "add_group": "Add Group",
      "roles": "Roles",
      "entity": "Entity",
      "groups": "Groups",
      "modal": {
        "role": {
          "title": "User Role",
          "organizationunit": "Organization Unit",
          "avaliableroles": "Avaliable Roles",
          "userroles": "Users Roles"
        },
        "group": {
          "title": "User Group"
        },
        "responsible": {
          "title": "Scope of responsibility (Entities)"
        }
      },
      "responsibleFor": "Scope of responsibility (Entities)",
      "add_responsibleFor": "Associate Entities"
    },
    "pmo_privileges": {
      "label": "PMO Privileges"
    },
    "access_profiles": {
      "access_profile": "Access Profile",
      "modal_title": "New Access Profile",
      "add_more": "New Access Profile"
    },
    "console": "Users"
  },
  "people": {
    "title": "People",
    "add_button_list": "New Person"
  },
  "department_unit": {
    "title": "Department Unit",
    "add_button_list": "New Unit"
  },
  "dimension": {
    "label_name_exists": "Label Name already exists",
    "show_more": "Show More",
    "no_formula": "No Formula",
    "dimension": "Dimensions",
    "available": "Available",
    "create": {
      "title": "Dimensions Library",
      "information": "Information",
      "list": "Dimensions",
      "dimensions_name_labels": "Labels",
      "add_more": "Add",
      "add_button_list": "New Dimension",
      "type": "Type",
      "entity": "Entity Name",
      "geo_location": "Geo Location",
      "delete_yes_no_title": "Dimension Delete Confirmation",
      "delete_yes_no_information": "The Dimension is about to be deleted",
      "value_type": "Value Type",
      "applicable_to": "Applicable to",
      "new_dimension_value": "New Dimension Value"
    },
    "success": {
      "remove": "Dimension deleted"
    },
    "navigation": "Dimensions Navigation"
  },
  "metrics": {
    "metrics_not_available": "No Metrics Available",
    "metrics_information_missing": "Metric information missing",
    "metrics_selections": "Metrics Selection",
    "showError": {
      "dots": "Metric name cannot contain special characters"
    },
    "local": " Local Metric (Entity)",
    "global": "(Government)Shared Metric",
    "type": {
      "public": "Shared (Government)",
      "local": "Shared (Entity)"
    },
    "metrics": "Metrics",
    "title": "Metrics",
    "information": "Information",
    "list": "Metrics",
    "add_button_list": "New Metric",
    "total_found": "Metrics found",
    "total_found_singular": "Metric found",
    "local_var": "Library Metric",
    "global_var": "Shared Metric",
    "global_local_var": "Shared / Library Metric",
    "custom_var": "Custom Metric",
    "label_name": "Name",
    "label_source": "Source",
    "label_description": "Description",
    "label_entity": "Entity",
    "label_type": "Metric Type",
    "label_data_type": "Data Type",
    "delete_yes_no_title": "Metric Delete Confirmation",
    "delete_yes_no_information": "The metric is about to be deleted",
    "values_and_targets": "Values & Metrics",
    "values_and_targets_dates": "Please choose a frequency and a start and end date",
    "no_information": "Periods missing information",
    "frequency": "Frequency",
    "available": "Available",
    "create": {
      "add_button_list": "New Metric"
    },
    "data_source": "Data Source",
    "creation_date": "Creation Date",
    "metrics_used": "The current metric is being used by other items: ",
    "metrics_save_modal": "The Data Type, Metric Type and Entities of this Metric cannot be changed because it has the following KPI's associated to it:",
    "metrics_save_modal_title": "Metrics Save Confirmation",
    "metrics_delete_modal": "This metric needs to be unlinked from the following KPI's to be able to be deleted",
    "created_by": "Created by",
    "dots": "Metric name cannot contain special characters"
  },
  "workflow_profile": {
    "title": "Workflow Profile",
    "add_button_list": "New Workflow",
    "details": "Details",
    "operations": "Operations",
    "grants": "Grants",
    "levels": "Levels",
    "approval_level": "Approval Levels",
    "user": "User",
    "item_type": "Item Type"
  },
  "access_profiles": {
    "title": "Access Profiles",
    "information": "Information",
    "list": "Access Profiles",
    "add_button_list": "New Profile",
    "allow_vision_view": "Allow Vision View",
    "allow_item_management": "Allow Item Management",
    "allow_data_management": "Allow Data Management",
    "allow_data_visualization": "Allow Data Visualization",
    "label_entity": "Entity",
    "label_global_view": "Global View",
    "label_provisioning": "Entity Profile",
    "label_plan_view": "Plan View",
    "label_data_management": "Data Management",
    "label_plan_management": "Plan Management",
    "label_item_type": "Item Type",
    "label_item": "Item",
    "delete_yes_no_title": "Access Profile Delete Confirmation",
    "delete_yes_no_information": "The Access Profile is about to be deleted",
    "access_exceptions": {
      "title": "Access Exceptions",
      "delete_yes_no_title": "Access Exception Delete Confirmation",
      "delete_yes_no_information": "The Access Exception is about to be deleted"
    }
  },
  "initiatives": {
    "budget_expenditures": "Budget Expenditures",
    "cancel_yes_no_title": "Initiative Cancellation Confirmation",
    "cancel_yes_no_information": "Are you sure you want to cancel the initiative?",
    "variance": "Variance",
    "expenses": "Expenses",
    "initiatives": "Initiatives",
    "general_information": "General Information",
    "title": "Initiative",
    "initiative": "Initiative",
    "expected_result": "Expected Results",
    "current_progress": "Current Progress",
    "current_expected_result": "Current and Expected Progress",
    "organization_unit": "Organization Unit",
    "objective": "Objective",
    "linking_and_owner": "Linking and Owner",
    "dates": "Dates",
    "directions": "Directions",
    "managers": "Managers",
    "financial": "Financial",
    "assign_managers": "Assign Managers",
    "initiative_manager": "Initiative Manager",
    "progress_manager": "Progress Manager",
    "initiative_manager_name_ae": "Initiative Manager (Arabic)",
    "initiative_manager_name_en": "Initiative Manager (English)",
    "progress_manager_name_ae": "Progress Manager (Arabic)",
    "progress_manager_name_en": "Progress Manager (English)",
    "initiative_budget": "Initiative Budget",
    "hyperion_reference": "Hyperion Reference",
    "hyper": "Hyper",
    "value": "Value",
    "is_this_main_subgov": "Is this the main Sub Government Direction",
    "main_subgovernment_direction": "Main SubGovernment Direction",
    "entity": "Entity",
    "owner": "Owner",
    "manager": "Manager",
    "budget": "Budget",
    "monitored": "Monitored",
    "high_priority_justification": "High Priority Justification",
    "monitored_kpi": "Monitored KPI",
    "KPIS": "KPI",
    "attachments": "Attachments",
    "available_fields": "Available Fields",
    "added_fields": "Added Fields",
    "high_priority": "High Priority",
    "government_directions": "Government Directions",
    "government_direction": "Government Direction",
    "sub_government_directions": "Sub Government Directions",
    "add_subgovernment_direction": "Please Choose Sub Government Direction",
    "create_initiative": "Create Initiative",
    "view_initiative": "View Initiative",
    "manual_input": "Manual Input of Data",
    "delete_yes_no_title": "Initiative Delete Confirmation",
    "delete_yes_no_information": "The Initiative is about to be deleted",
    "main": "Main",
    "current": "Current",
    "year_to_period": "Year to Period",
    "gov_direction_error": "Please choose at least one Government Direction and one Sub Government Direction",
    "main_sub_gov_error": "Please choose one Sub Government Direction as Main",
    "subgov_direction_modal_title": "Select a Main Sub Government Direction Owner",
    "cancel_initiative": "The Initiative was canceled",
    "cancel_initiative_button": "Cancel Initiative",
    "initiative_canceled": "Initiative  Canceled",
    "activity_workload": "Edit Activity Weight",
    "activity_workload_title": "Activities Weight"
  },
  "directions": {
    "subgov_icon": "Sub Government Direction Image",
    "subgov_mandatory": "Sub Gov Direction is\nMandatory",
    "government_directions": "Government Directions",
    "government_direction": "Government Direction",
    "team_owner": "Team Owner",
    "attachments": "Attachments",
    "add_new_attachment": "New Attachment",
    "add_new_direction": "New Government Direction",
    "add_new_subDirection": "New Sub Government Direction",
    "save_and_add_sub_directions": "Save & Add Sub Directions",
    "add_new_kpi": "New NKPI or EKPI",
    "sub_government": "Sub Government Direction",
    "subDirectionRemove": "This Sub Direction will be deleted",
    "directionRemove": "This Government Direction will be deleted",
    "attachmentsRemove": "This attachment will be deleted",
    "edit_weight": "Edit Weight",
    "overweight_warning": "The total can not exceed 100",
    "vision": "Vision",
    "identical_title": "Create Sub Government Direction",
    "main": "Main",
    "identical_text": "Do you want to create an identical Sub\nGovernment Direction, with same name and description of the main government direction?\n",
    "cancel_initiative": "Initiative Cancel",
    "initiative_canceled": "The Initative was canceled!",
    "uploading_tooltip": "Choose an image with  'svg' type "
  },
  "subDirection": {
    "subDirection": "Sub Government Direction",
    "no_kpi_weight_found": "No linked KPI with Sub Government Direction",
    "no_attachments": "There are no attachments available"
  },
  "tasks": {
    "tasks": "Tasks",
    "evidences": "Evidences",
    "linking_and_owner": "Linking & Owner",
    "activity": "Activity",
    "evidence_id": "Evidence ID"
  },
  "services": {
    "title": "Services",
    "create_title": "Main Service",
    "service_kpi": "Service KPI",
    "metrics_target": "Metrics & Target",
    "benchmarking": "Benchmarking",
    "attachments": "Attachments",
    "extended_fields": "Extended Fields",
    "is_high_priority": "Is high priority",
    "parent_service": "Parent Service",
    "service_type": "Service Type",
    "auxiliary_service:": "Auxiliary Service",
    "auxiliary_service": "Auxiliary Service",
    "variation_service": "Variation Service",
    "variation_services": "Variation Services",
    "auxiliary_services": "Auxiliary Services",
    "khadamati_id": "Khadamati ID",
    "last_sync_date": "Last Sync Date",
    "sub_service_type": "Sub Service Type",
    "service_classification": "Service Classification",
    "service_channels": "Service Channels",
    "variations": "Variations",
    "expenditures": "Expenditures",
    "classification": "Classification",
    "type": "Type",
    "value": "Value",
    "expenses": "Approved Budget",
    "variance": "Actual Expenses",
    "total": "Variance",
    "service": "Service",
    "aux_var_service": "Auxiliary / Variation Services",
    "main_service": "Main Service"
  },
  "kpi": {
    "card": {
      "initiatives": "Initiatives",
      "card_recalculate": "Recalculate"
    },
    "error": {
      "activity_date": "Start and end date are not within the activity start and end date",
      "cycle_date": "Start and End Date do not match the Cycle Start and End Date"
    },
    "baseline_attachment_view": "View",
    "baseline_attachment_title": "Baseline attachment",
    "attachment": "Base Line Attachment",
    "linked_kpi_type": "Linked KPI Type",
    "general_information": "General Information",
    "kpi": "KPI",
    "kpis": "KPI",
    "subkpi": "Sub-KPI",
    "metrics_target": "Metrics & Target",
    "benchmarking": "Benchmarking",
    "attachments": "Attachments",
    "extended_fields": "Extended Fields",
    "dates": "Dates",
    "linking_and_owner": "Linking & Owner",
    "contributing_entities": "Contributing Entities",
    "contributing_entities_en": "Other Contributing Entity (English)",
    "contributing_entities_ae": "Other Contributing Entity (Arabic)",
    "guideline_measurement": "Guidelines Measurement",
    "has_dimension": "Has Dimension",
    "parent_kpi": "Parent KPI",
    "legacy_kpi": "Legacy KPI",
    "behaviour": "Behaviour",
    "measurement_type": "Measurement Type",
    "frequency": "Frequency",
    "unit": "Unit",
    "year_to_period": "Year to Period",
    "hasTarget": "Has Target",
    "trend": "Trend",
    "formula_metrics": "Formula & Metrics",
    "validate": "Validate",
    "valid": "Valid",
    "validate_formula": "Validate Formula",
    "formula": "Formula",
    "baselineYear": "Baseline Year",
    "entity": "Entity",
    "measurement_unit": "Measurement Unit",
    "view_kpis": "View KPI",
    "file": "File",
    "kpis_list": "List of KPI",
    "translations": "Translations",
    "subkpis_alertMessage": "You should select at least one Kpi",
    "subkpis_empty": "There are no Sub-KPI available",
    "delete_yes_no_title": "KPI Delete Confirmation",
    "are_you_sure_ekpi": "Are you sure you want to delete that EKPI?",
    "delete_yes_no_information": "The KPI is about to be deleted",
    "delete_yes_no_information_ekpi": "The affected / enabler entities copy will be deleted.",
    "targets_in_review": "Targets or Actuals in review",
    "plusbutton_add_national_kpi": "New National KPI",
    "plusbutton_add_strategic_kpi": "New Strategic KPI",
    "plusbutton_add_uae_KPI": "New Directional KPI",
    "plusbutton_add_enabler_KPI": "New Enabler KPI",
    "plusbutton_add_opm_KPI": "New OPM KPI",
    "plusbutton_add_service_KPI": "New Service KPI",
    "year1": "1 year",
    "year2": "2 years",
    "cycle": "Cycle",
    "full_trend": "Full Trend",
    "value_type": "Value Type",
    "formula_modal_title": "Formula Selection Modal",
    "extended_fields_modal": {
      "title": "Extended Fields Data Input"
    },
    "formula_validate": "Validating Formula",
    "no_formula": "There’s no formula to be validated",
    "benchmark_country_exists": "This country already exists in the chosen year!",
    "bench_download_reports": "Download Reports",
    "file_size": "File Size:",
    "max_file_size": "Max File Size:",
    "calculation_type": "Calculation Type",
    "status_analysis": "Status Analysis",
    "areas_of_improvement": "Areas of Improvement",
    "recommendations": "Recommendations",
    "pmo_notes": "PMO Notes",
    "govKpi": "GOV KPI",
    "progress_bar_label_actual": "Actual",
    "benchmark_name_exists": "Already exists a benchmark with that name",
    "optimal_target": "Vision Target",
    "dkpi_target": "Optimal Target",
    "nkpi_target": "Vision Target",
    "subkpis_moreThenHundred": "Sub KPI's Weight Sum is Over 100%",
    "subkpis_belowHundred": "Sub KPI's Weight Sum is Below 100%",
    "subkpis_generic_error": "Please check your Sub Kpi Information",
    "subkpis_error": "Sub KPI's Error",
    "subkpis_repeatedName": "There are already a Sub KPI with the same name",
    "data_table": "KPI Details",
    "lower_limit": "Lower Limit",
    "targets_subkpis": "Targets on Sub KPIS",
    "upper_limit": "Upper Limit",
    "targets_limits": "Lower limit cannot be higher than the upper limit",
    "affected_information": "One or more affected entity was removed",
    "different_entity_title": "Entity Change",
    "different_entity": "You will be redirect to another entity",
    "formula_changed": "Formula",
    "formula_changed_decision": "<b>Changing the formula will delete previous values, or will recalculate the KPI (if needed data available).</b></br>Kindly take the necessary action based on the formula change, or close the request by clicking X sign",
    "formula_recalculate": "Recalculate",
    "benchmark": "Benchmark",
    "formula_create_new": "Create New KPI",
    "table_data_title": "KPI details",
    "NOFORMULAV": "No Formula"
  },
  "nkpi": {
    "create_national_kpi": "Create National KPI",
    "general_information": "General Information",
    "nkpi": "National KPI",
    "sponsorEntity": "Sponsor",
    "nonFederalEntityName": "Non Federal Entity Name",
    "contributingEntities": "Contributing Entities",
    "owner": "Owner",
    "baseline": "Baseline",
    "scopeMeasurement": "Scope of Measurement",
    "scopeMeasurementAE": "Scope of Measurement",
    "scopeMeasurementEN": "Scope of Measurement",
    "visionTarget": "Vision Target",
    "targetValue": "Target Values",
    "aggChildren": "Aggregate from Child KPIS",
    "dontAggr": "Don't Aggregate",
    "onlyYearlyTarget": "Only Year Target",
    "baselineValue": "Baseline Value",
    "baselineYear": "Baseline Year",
    "baselineReport": "Baseline Report File",
    "dimensions": "Dimensions",
    "metrics": "Measurement Details",
    "targets": "Targets",
    "target": "Target",
    "add_new_benchmarks": "New Benchmarking",
    "add_new_benchmark": "New Benchmark",
    "available_fields": "Available Fields",
    "added_fields": "Added Fields",
    "aggDimensions": "Agregate from Dimensions",
    "targetFormula": "Target(s) On Formula",
    "targetValues": "Target Values",
    "source": "Source",
    "sourceAE": "Source",
    "sourceEN": "Source",
    "benchType": "Type",
    "local": "Local",
    "international": "International",
    "add_new_attachment": "New Attachment",
    "attachmentType": "Type",
    "attachment": "Attachment",
    "attachmentDate": "Date",
    "operand": "Operand",
    "measurement_unit": "Measurement Unit",
    "measurement_unit_dsc": "Measurement Unit Description",
    "measurement_unit_dsc_ae": "Measurement Unit Description",
    "measurement_unit_dsc_en": "Measurement Unit Description",
    "no_formula_info": "Formula information missing",
    "published": "Published",
    "other_entity": "Other Entity",
    "other_entity_ae": "Other Entity",
    "other_entity_en": "Other Entity",
    "benchYear": "Year",
    "benchValue": "Value",
    "executive_Team": "Executive Team",
    "role": "Role",
    "entity": "Entity",
    "weight": "Weight",
    "subkpi": "Sub KPI",
    "lowerLimit": "Lower Limit",
    "upperLimit": "Upper Limit",
    "upload_file": "Upload File",
    "other": "Other",
    "executive_team": "Executive Team",
    "executiveteamAE": "Executive Team",
    "executiveteamEN": "Executive Team",
    "data_source_entity": "Data Input",
    "data_source_entity_source": "Data Source",
    "deleteWeightInformation": "All Sub-KPI will be deleted",
    "deleteWeightQuestion": "Are you sure?",
    "trend": "Trend",
    "last_update": "Last Update",
    "performance_ytp": "Annual Performance",
    "actual_target": "Performance level",
    "details": "Details",
    "amount": "Amount",
    "sub_kpi": "Sub-KPI",
    "actual": "Actual",
    "pending_kpi": "Pending KPI Values",
    "frequency": "Frequency",
    "vision_target": "Vision Achievement",
    "legacy_kpi": "Legacy KPI",
    "yearly": "Yearly",
    "monthly": "Montly",
    "percentage": "Percentage",
    "ytp": "Annual Performance",
    "performance": "Performance",
    "attachmentName": "Attachment Name",
    "invalid_formula": "Formula must be validated",
    "country": "Country",
    "no_data_recorded": "No Data Recorded",
    "edit_benchmark_value": "Edit value",
    "benchmark_delete_yes_no_title": "Delete Benchmark",
    "select_textmap_id": "Select Text Mapping",
    "max_rank": "No. of Countries",
    "benchmark_delete_yes_no_information": "The benchmark is about to be deleted",
    "is_subkpi": "Cannot have Sub-KPIS because is already a Sub-KPI of another KPI!",
    "baselineKpi": "Baseline KPI",
    "linked_to_object": "Cannot add Sub-Kpis because this object is linked to another KPI",
    "benchmark_delete_attachment_yes_no_information": "Are you sure you want to delete this attachment?",
    "benchmark_delete_attachment_yes_no_title": "Delete Attachment"
  },
  "skpi": {
    "create_strategic_kpi": "Create Strategic KPI",
    "no_formula": "No Formula",
    "skpi": "Strategic KPI",
    "relatedDkpi": "Related DKPI",
    "objective": "Objectives",
    "relatedNkpi": "Related NKPI"
  },
  "ekpi": {
    "ekpi": "Enabler KPI",
    "create_enabler_kpi": "Create Enabler KPI",
    "general_information": "General Information",
    "enablerEntity": "Enabler Entity",
    "nonFederalEntityName": "Non Federal Entity Name",
    "contributingEntities": "Contributing Entities",
    "owner": "Owner",
    "objective": "Objectives",
    "piller": "Pillar",
    "baseline": "Baseline",
    "scopeMeasurement": "Scope of Measurement",
    "visionTarget": "Vision Target",
    "targetValue": "Target Values",
    "aggChildren": "Aggregate from Children",
    "dontAggr": "Don't Aggregate",
    "onlyYearlyTarget": "Only Yearly Target",
    "baselineValue": "Baseline Value",
    "baselineYear": "Baseline Year",
    "baselineReport": "Baseline Report File",
    "dimensions": "Dimensions",
    "metrics": "Behaviour",
    "targets": "Targets",
    "target": "Target",
    "add_new_benchmarks": "New Benchmarking",
    "add_new_benchmark": "New Benchmark",
    "available_fields": "Available Fields",
    "added_fields": "Added Fields",
    "aggDimensions": "Agregate from Dimensions",
    "targetFormula": "Target(s) On Formula",
    "targetValues": "Target Values",
    "source": "Source",
    "benchType": "Type",
    "local": "Local",
    "international": "International",
    "add_new_attachment": "New Attachment",
    "attachmentType": "Type",
    "attachmentDate": "Date",
    "operand": "Operand",
    "measurement_unit": "Measurement Unit",
    "measurement_unit_dsc": "Measurement Unit Description",
    "no_formula_info": "Formula information missing",
    "published": "Published",
    "other_entity": "Other Entity",
    "benchYear": "Period",
    "benchValue": "Value",
    "executive_Team": "Executive Team",
    "role": "Role",
    "entity": "Entity",
    "weight": "Weight",
    "subkpi": "Sub KPI",
    "lowerLimit": "Lower Limit",
    "upperLimit": "Upper Limit",
    "upload_file": "Upload File",
    "other": "Other",
    "data_source_entity": "Data Input",
    "data_source_entity_source": "Data Source",
    "deleteWeightInformation": "All sub-KPI will be deleted",
    "deleteWeightQuestion": "Are you sure?",
    "trend": "Trend",
    "last_update": "Last Update",
    "performance_ytp": "Annual Performance",
    "actual_target": "Performance level",
    "details": "Details",
    "amount": "Amount",
    "sub_kpi": "Sub-KPI",
    "actual": "Actual",
    "pending_kpi": "Pending KPI Values",
    "frequency": "Frequency",
    "vision_target": "Vision Target",
    "legacy_kpi": "Legacy KPI",
    "yearly": "Yearly",
    "monthly": "Montly",
    "percentage": "Percentage",
    "ytp": "Annual Performance",
    "performance": "Performance",
    "affectedEntities": "Available Entities",
    "delete_yes_no_title": "KPI Delete Confirmation",
    "delete_yes_no_information": "The KPI is about to be deleted",
    "selected_entities": "Selected Entities",
    "select_all_entities": "Select All Entities",
    "remove_all_entities": "Remove all Entities",
    "data_input": "Data Input",
    "input_actuals": "Input Actuals",
    "input_targets": "Input Targets",
    "targets_approval": "Targets Approval",
    "actuals_approval": "Actuals Approval",
    "missing_affected_entities": "Missing data - affected Entities",
    "affected_entities_selection": "Affected Entities Data Input Selection",
    "pmo_org_unit": "PMO Organization Unit",
    "pmo_owner": "PMO Owner",
    "card_view_all_data_button": "All data"
  },
  "opm": {
    "opm": "Operational Management KPI",
    "create_opm": "Create OPM",
    "entities": "Entities",
    "initiative": "Initiative",
    "activity": "Activity"
  },
  "srvkpi": {
    "reason_veryImportant": "Reason",
    "veryImportant": "Very Important",
    "perspective": "Perspective",
    "actualValues": "Actual Values Verified",
    "importValues": "Import Values",
    "classification": "Service Classification",
    "org_unit": "Organization Unit",
    "auxVarService": "Aux/Var Service",
    "serviceObjective": "Objectives",
    "srvkpi": "Service KPI",
    "mainService": "Main Service",
    "create_srvkpi": "Create Service KPI",
    "service": " Main Service"
  },
  "uae": {
    "direction_target": "Direction Target",
    "uae": "Directional KPI",
    "create_uae": "Create Directional KPI",
    "entities": "Entities",
    "initiative": "Initiative",
    "activity": "Activity",
    "ultimateVisionTarget": "Ultimate Vision Target"
  },
  "objectives": {
    "notes": {
      "delete_message": "Note Delete Confirmation",
      "delete_confirmation": "The note is about to be deleted"
    },
    "add_notes": "Add Notes",
    "title": "Objectives",
    "linking_and_owner": "Linking & Owner",
    "agg_children": "Is manual calculated",
    "has_performance": "Has performance",
    "key_success_factors": "Success Factors",
    "add_success_factors": "New Success Factors",
    "ytp_calc": "Ytp Calc",
    "ytp_calc_aux": "Ytp Calc Aux",
    "extended_fields": "Extended Fields",
    "performance": "Performance",
    "agregate": "Agregate",
    "manual_input": "Manual Input Of Data",
    "dont_calculate": "Do Not Calculated",
    "add_extended_fields": "New Extended Fields",
    "enabler_objective": "Enabler Objective",
    "objective": "Objective",
    "delete_yes_no_title": "Objective Delete Confirmation",
    "delete_enabler_yes_no_title": "Enabler Objective Delete Confirmation",
    "delete_yes_no_information": "The Objective is about to be deleted",
    "delete_enabler_yes_no_information": "The Enabler Objective is about to be deleted"
  },
  "strategic_map": {
    "title": "Strategic Map",
    "start_date": "Start date:",
    "end_date": "End date:",
    "approval": "On Approval",
    "plan_navig": "Plan Navigation"
  },
  "communication": {
    "file_download": "Download File",
    "subject": "Subject",
    "title_en": "Title (EN)",
    "title_ae": "Title (AE)",
    "title": "Notifications Center",
    "message": "Message",
    "minutes_ago": "minutes ago",
    "hours_ago": "hours ago",
    "show_more_messages": "Show more messages",
    "hide_more_messages": "Hide more messages",
    "action_needed": "Action Needed",
    "no_notifications": "No Notifications",
    "send": "Send",
    "reply": "Reply",
    "reply_and_attachments": "Reply & Attachments",
    "save_as_draft": "Save as Draft",
    "notifications_title": "Notifications",
    "new_message": "New Message",
    "show_previous_messages": "Show previous messages",
    "view_differences": "View Differences",
    "data_entry": "Data Entry - View Value",
    "action_delete": "Deleted - View Object",
    "action_create": "Created - View Object",
    "hide_previous_messages": "Hide previous messages",
    "notification_templates": "Notifications Management",
    "difference": {
      "title": "View Differences",
      "new_value": "Current Data",
      "old_value": "Previous Data",
      "key": "Field",
      "view_object": "View Object",
      "value": "Data"
    }
  },
  "progress_readings": {
    "title": "Progress Readings",
    "next_steps": "Next Steps",
    "main_challenges": "Main Challenges"
  },
  "executive_teams": {
    "edit_members_label": "Edit Members",
    "members_label": "Members",
    "title_edit": "Edit Executive Team",
    "add_member": "Add Executive Team Member",
    "title": "Executive Teams",
    "add_button": "New Executive Team",
    "delete_yes_no_title": "Executive Team Delete Confirmation",
    "submit": "Submit",
    "delete_yes_no_information": "The Executive Team is about to be deleted",
    "members": {
      "modal_title_edit": "Edit Executive Team Member",
      "modal_title": "New Executive Team Member",
      "title": "Executive Team Members",
      "title_within_team": "Member Role",
      "delete_yes_no_title": "Executive Team Member Delete Confirmation",
      "delete_yes_no_information": "The Executive Team Member is about to be deleted",
      "adda3_user": "Adaa 3.0 User"
    },
    "nkpi_table": {
      "title": "NKPIs/DKPIs Assigned to Executive Team"
    }
  },
  "common": {
    "form": {
      "label": {
        "select": "Select",
        "actual_comparison": "Actual Comparison",
        "performance_comparison": "Performance Comparison",
        "highPriorityJustification": "High Priority Justification",
        "initiativeManagerName": "Initiative Manager Name",
        "progressManagerName": "Progress Manager Name",
        "by": "By",
        "grid_column_english": "Data grid in English",
        "grid_column_arabic": "  Data grid in Arabic",
        "answer": "Answer",
        "security_question": "Security Question",
        "newPasswordConfirmation": "New Password Confirmation",
        "newPassword": "New Password",
        "currentPassword": "Current Password",
        "refcode": "ID",
        "name": "Name",
        "nameAE": "Name (Arabic)",
        "nameEN": "Name (English)",
        "description": "Description",
        "descriptionAE": "Description (Arabic)",
        "descriptionEN": "Description (English)",
        "errorMessageEN": "Error Message (English)",
        "errorMessageAE": "Error Message (Arabic)",
        "status": "Status",
        "yes": "Yes",
        "no": "No",
        "username": "Username",
        "password": "Password",
        "confirmPassword": "Confirm Password",
        "email": "Email",
        "active": "Active",
        "inactive": "Inactive",
        "available": "Available",
        "search": "Search",
        "filter": "Filter",
        "manager": "Manager",
        "information": "Information",
        "entity": "Entity",
        "comment": "Comment",
        "comments": "Comments",
        "commentsAE": "Comments",
        "commentsEN": "Comments",
        "monitored": "Monitored on Task Level",
        "high_priority": "High Priority",
        "start_date": "Start Date",
        "end_date": "End Date",
        "date": "Date",
        "owner": "Owner",
        "object_type": "Object Type",
        "organization_unit": "Organization Unit",
        "add_comment": "New Comment",
        "add_percentage": "New Percentage",
        "attachment_document": "Attachment Document",
        "file": "File",
        "dimensions": "Dimensions",
        "dimension": "Dimension",
        "sub_dimension": "Sub Dimension",
        "weight": "weight",
        "weight_kpi": "KPI & Weights",
        "please_select": "Please Select",
        "checkbox_yes_no_information": "All values will be deleted",
        "checkbox_yes_no_information_one": "Values will be deleted",
        "are_you_sure": "Are you sure?",
        "geo_location_confirmation": "Geo Location Confirmation",
        "geo_location": "Geo Location",
        "title": "Title",
        "titleAE": "Title (Arabic)",
        "titleEN": "Title (English)",
        "total": "Total",
        "value": "Value",
        "x": "Longitude",
        "y": "Latitude",
        "coordinates": "Coordinates",
        "enter_your_message": "Enter Your Message",
        "attachments": "Attachments",
        "extended_fields": "Extended Fields",
        "enable_objective": "Enable Objective",
        "drag_zone": "Drag Extended Field to Add New",
        "all": "All",
        "search_results_for": "Search results",
        "risk": "Risk",
        "label": "Label",
        "english": "English",
        "arabic": "Arabic",
        "mandatory": "Mandatory",
        "field": "Field",
        "order": "Order",
        "average": "Average",
        "dgdp_noitems": "No items left to drag and drop",
        "weight_average": "Weight Average",
        "min": "min",
        "max": "max",
        "last": "last",
        "sum": "sum",
        "loading": "Loading...",
        "mitigation": "Mitigation Plan",
        "mitigationAE": "Mitigation (Arabic)",
        "mitigationEN": "Mitigation (English)",
        "jobTitleAE": "Job Title (Arabic)",
        "jobTitleEN": "Job Title (English)",
        "jobTitle": "Job Title",
        "directPhone": "Direct Phone",
        "mobilePhone": "Mobile Phone",
        "default_value": "Default value",
        "mission_ae": "Mission (Arabic)",
        "mission": "Mission (English)",
        "vision_ae": "Vision (Arabic)",
        "vision": "Vision (English)",
        "strength_ae": "Strength (Arabic)",
        "strength": "Strength (English)",
        "weakness_ae": "Weakness (Arabic)",
        "weakness": "Weakness (English)",
        "opportunities_ae": "Opportunities (Arabic)",
        "opportunities": "Opportunities (English)",
        "values_ae": "Values (Arabic)",
        "values_en": "Values (English)",
        "threats_ae": "Threats (Arabic)",
        "threats": "Threats (English)",
        "add_value": "New Value",
        "drag_drop_no_items_to_add": "No items to add",
        "drag_drop_no_items_to_remove": "No items to remove",
        "none": "None",
        "add_link": "Add Link",
        "reject": "Reject",
        "reject_amend": "Reject Amend",
        "approve": "approve",
        "metric": "Metric",
        "position": "Organization Unit",
        "drop_file": "Drop the file here",
        "search_message": "Search Message",
        "new_dimension": "New Dimension Value",
        "channel": "Channel",
        "strategyManager": "Strategy Manager",
        "nkiFocalPoint": "NKI Focal Point",
        "ekpiFocalPoint": "EKPI Focal Point",
        "servicesFocalPoint": "Services Focal Point",
        "upload_or": "or",
        "browse_file": "browse file",
        "no_files_uploaded": "No files available",
        "veryImportantReason": "Reason for Very Important",
        "applyretro": "Apply Retroactives?",
        "missing_information": "Please fill all the fields",
        "pmo_only": "PMO Use",
        "pmo_role": "PMO Role",
        "captcha_select_the": "Select the",
        "pencil": "pencil",
        "bell": "bell",
        "book": "book",
        "eye": "eye",
        "clock": "clock",
        "paper_clip": "paper clip",
        "select_all": "Select All",
        "linked": "Linked",
        "templateAE": "Template Content (Arabic)",
        "reply_attachment": "Reply & Attachments",
        "message": "Message",
        "adaa": "ADAA",
        "templateEN": "Template Content (English)",
        "entities": "Entities",
        "responsible": "Owner",
        "objective": "Objective",
        "using_item_delete": "It is possible that this item is associated with one or more objects. Are you sure?",
        "questionMark": "Question mark",
        "cross": "Cross",
        "star": "Star",
        "magnifier": "Magnifier",
        "graph": "Graph",
        "values": "Values",
        "expand": "Expand",
        "assignLandingPages": "Assign Landing Pages",
        "reporting": "Reports",
        "generate": "Generate",
        "attachment_date": "Date",
        "refCode": "ID",
        "kpi_list": "KPI List"
      },
      "validations": {
        "required": "* Required",
        "all_fields_required": "All fields are required",
        "weight_mandatory": "Weight is required",
        "subkpi_fields_required": "Trend, Frequency and Measurement Unit needed to display Sub-KPI information"
      },
      "modals": {
        "all_data_lost": "You will be re-directed\n to the home page and all unsaved data will be lost?",
        "cancel_yes_no_title": "Cancel Confirmation",
        "cancel_yes_no_information": "You are about to cancel",
        "input_default_data": "Input default data",
        "remove_all_data_information": "Do you want to delete all previous data? Number of affected records:",
        "input_default_data_information": "Do you want input an default data? Number of affected records:",
        "tag": "Tag",
        "title_list_of_values": "List of values",
        "delete_list_of_values_confirmation_title": "Delete values confirmation",
        "change_activity": "Change Task from Activity",
        "will_change_activity": "You will change this task for another Activity",
        "title_workflow_delete": "Workflow delete confirmation",
        "remove_workflow_data_information": "The Workflow is about to be deleted",
        "remove_data_information": "Do you want to delete all previous data?",
        "delete_confirmation": "Delete Confirmation",
        "delete_confirmation_tasks": "You will remove this task. Are you sure?"
      },
      "modals_leave": {
        "cancel_yes_no_title": "Leaving Main Form",
        "cancel_yes_no_information": "You are about to leave the form"
      },
      "button": {
        "apply": "Apply",
        "change_password": "Change Password",
        "save": "Save",
        "cancel": "Cancel",
        "edit": "Edit",
        "delete": "Delete",
        "upload": "Upload File",
        "close": "Close",
        "save_as_draft": "Save As Draft",
        "send": "Send",
        "reject_amend": "Reject Amend",
        "reject": "Reject",
        "approve": "Approve",
        "update": "Update",
        "back": "Back",
        "next": "Next",
        "previous": "Previous",
        "finish": "Finish",
        "view_all": "View All",
        "read_more": "Read more",
        "send_to_pre_approval": "Send to pre appoval",
        "terminate": "Terminate",
        "ignore": "Ignore",
        "review": "Review",
        "view_related_objects": "View related objects",
        "confirm": "Confirm",
        "print": "Print",
        "send_to_entities": "Send to Entities",
        "simulate": "Simulate",
        "validate": "Validate",
        "reset": "Reset"
      },
      "attachments": {
        "same_name": "Attachment Name already exists"
      },
      "user": {
        "user_information": "Information"
      }
    },
    "tables": {
      "location": "Location",
      "type": "Type",
      "name": "Name",
      "actual": "Actual",
      "score": "Score",
      "progress": "Progress",
      "information": "Information",
      "info": "Info",
      "trend": "Trend",
      "total": "Total",
      "weight": "Weight",
      "team_owner": "Team Owner",
      "performance": "Period Performance",
      "date": "Date",
      "value": "Value",
      "label": "Label",
      "ytp": "Annual Performance",
      "actions": "Actions",
      "address": "Address",
      "gps": "GPS",
      "email": "Email",
      "phone": "Phone",
      "reports_to": "Reports To",
      "manager": "Manager",
      "send_to_pre_approval": "Send to Pre-Approval",
      "terminate": "Terminate",
      "period": "Period",
      "favorites": "Favorites",
      "no_info": "Information not found",
      "form": "Form",
      "last_calculation": "Last Calculations",
      "usage_on": "Usage On",
      "check_all": "Check all",
      "use_weight": "Use weights?",
      "uncheck_all": "Uncheck all",
      "yes": "Yes",
      "no": "No",
      "manage": "Manage",
      "object_actions": "Object/Action",
      "create": "Create",
      "view": "View",
      "execute": "Execute",
      "entity_name": "Entity Name",
      "organization_unit": "Oganization Unit",
      "notification": "Notification",
      "category": "Category",
      "active": "Active",
      "start": "Start",
      "end": "End",
      "last-edit": "Last modification Date",
      "budget": "Budget",
      "workload": "Activity Weight",
      "sms": "SMS",
      "open_cycle": "Open Cycle",
      "formula": "Formula",
      "frequency": "Frequency",
      "missing_cycle": "Required Actuals Entry Period",
      "last_modification": "Last Modification",
      "modified_by": "Modified by",
      "filter_modal": "Filter",
      "view_all_data": "View all data",
      "description": "Description",
      "range": {
        "from": "From",
        "to": "To"
      }
    },
    "state": {
      "workloads_missing": "Please enter all activity weight to calculate the initiative progress",
      "on_approval": "On Approval",
      "draft": "Draft",
      "approved": "Approved",
      "deleted": "Deleted",
      "new": "New",
      "data_approved": "Actual Values Approved",
      "data_missing": "Actual Values not Available",
      "data_onreview": "Actual Values Under Review"
    }
  },
  "team_owner": {
    "strategy_and_policy_sector": "Strategy and Policy sector",
    "public_diplomacy": "Public Diplomacy",
    "government_performance_department": "Government Performance Department",
    "other": "Other",
    "team_owner": "Team Owner"
  },
  "trend": {
    "increase": "Increasing Better",
    "decrease": "Decreasing Better",
    "bounded": "Bounded",
    "on_target": "As target"
  },
  "attachment_type": {
    "report": "Report"
  },
  "sidebar_tables": {
    "executive_team": "Executive Team",
    "contributing_entities": "Contributing Entities",
    "scope_measurement": "Scope of Measurement",
    "baseline": "Baseline",
    "benchmarks": "Benchmarks",
    "attachments": "Attachment",
    "communication_centre": "Notifications Center",
    "last_periods": "Last 12 Periods",
    "notes": "Notes",
    "evolution_graph": "Results Trend"
  },
  "khadamati": {
    "service_channels": {
      "canApply": "Can Apply ?",
      "canDeliver": "Can Deliver ?",
      "canPay": "Can Pay ?"
    },
    "comment": {
      "SERVICES": "Services",
      "SUBSERVICES": "Sub Services",
      "SERVICECENTERS": "Service Center"
    },
    "sync_status": {
      "failed": "Failed",
      "finished": "Finished",
      "in_progress": "In Progress",
      "stopped": "Stopped"
    },
    "last_sync_date": "Last Sync Date:",
    "sync_details": "Sync Details",
    "title_integration": "Khadamati Integration",
    "sync_log": "Sync Log",
    "title": "Khadamati Integration",
    "list": "Synchronizations List",
    "manual_sync": {
      "title": "Manual Synchronization"
    },
    "schedule": {
      "schedule_sync": "Save",
      "title": "Synchronizations Schedule",
      "integration": "Integration",
      "configuration": "Configuration",
      "monthly": "Monthly",
      "dayOfMonth": "Day of the month",
      "weekly": "Weekly",
      "daily": "Daily",
      "inactive": "Inactive",
      "start_sync": "Start Sync",
      "stop_sync": "Stop Sync",
      "insert_day": "Insert day",
      "week": {
        "choose": "Choose day of week",
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday"
      }
    }
  },
  "login": {
    "password_incorrect": "The password does not match or is empty",
    "send": "send",
    "error_answers": "Invalid answers",
    "user_not_found_instructions": "You must fill a valid email",
    "user_not_found": "User not found",
    "error_email": "Please insert a valid email",
    "select_channel": "Select the channel",
    "error_user": "Invalid User",
    "select_the": "Select the",
    "select": "Select the",
    "welcome": "Welcome!",
    "email": "Email",
    "password": "Password",
    "login": "Login",
    "forgot": "Forgot Password?",
    "forgot_password_title": "Forgot your password?",
    "forgot_text": "An e-mail is sent which will direct you to create a new password for your ADAA 3.0 account",
    "reset_password": "Reset Password",
    "two_steps_title": "2 - step verification",
    "two_steps_text": "Please select how you want to receive a 6-digit verification code",
    "more_options": "More options",
    "confirm": "Confirm",
    "confirmation_code": "Confirmation code",
    "text_message": "Text message to",
    "channel": "Please select how you want to receive a 6-digit verification code",
    "dont_ask": "Do not ask again on this computer",
    "go_back": "Go Back",
    "send_reset_email_text": "We just sent an e-mail to",
    "send_reset_email_instructions": "Click on the secure link we sent you to reset your password",
    "text_select_identity": "Please select how do you want to verify your identify",
    "text_questions": "Answer to 2 security questions",
    "answer": "Answer",
    "create_password": "Please create a new password",
    "captcha_error": "Selection not verified",
    "password_confirm": "Confirm password",
    "resendCode": "Resend Code",
    "sentCodeSms": "The verification code is sent",
    "sentCodeEmail": "Code sent to your email",
    "logToContin": "Please Login to Continue",
    "request_password": "You request a new password. Click follow link",
    "error_code": "Invalid Code",
    "betaversion": "Beta version",
    "error_code_15": "Invalid Code"
  },
  "mandatory": {
    "title": "Mandatory Fields",
    "information": "Information",
    "update_fields": "Update fields",
    "update_changes": "Do you want save the changes?"
  },
  "graphic": {
    "risk_management": {
      "title": "Risk Matrix",
      "x_axis_impact": "Impact",
      "y_axis_risk": "Priority",
      "delete_yes_no_title": "Risk Delete Confirmation",
      "delete_yes_no_information": "The Risk is about to be deleted",
      "association": "Risk Source"
    },
    "gantt": {
      "title": "Gantt Chart",
      "no_attachments": "'No attachment to display"
    },
    "kpi": {
      "actual": "Actual",
      "actuals": "Actuals",
      "year_to_period": "Year to Period"
    }
  },
  "provisioning": {
    "addresses": "Addresses",
    "organization_structure": "Organization Structure",
    "organization_unit": "Organization Structure",
    "personnel": "Personnel",
    "title": "Entity Profile",
    "swot_title": "SWOT Analysis",
    "directives": "Strategic Document",
    "address": {
      "delete_yes_no_title": "Address Delete Confirmation",
      "delete_yes_no_information": "The Address is about to be deleted"
    },
    "orgunit": {
      "delete_yes_no_title": "Organization Unit Delete Confirmation",
      "delete_yes_no_information": "The Organization Unit is about to be deleted",
      "title": "Org Unit"
    },
    "orgpeople": {
      "delete_yes_no_title": "User Delete Confirmation",
      "delete_yes_no_information": "The User is about to be deleted",
      "title": "User"
    }
  },
  "cycle": {
    "full_cycle": "Full Cycle",
    "title": "Strategic Cycles",
    "create": "New Cycle",
    "delete_yes_no_title": "Cycle Delete Confirmation",
    "delete_yes_no_information": "This cycle is about to be deleted",
    "send_to_entities": "Entities will be informed to start plan management",
    "title_send": "Submit for Plan Management",
    "available_entities": "Available Entities",
    "move_to_available": "Add All",
    "move_to_connected": "Remove All",
    "connected_entities": "Involved Entities",
    "no_data_row": "All entities were already notified",
    "notify_entities": "Notify Entities",
    "entity_name": "Entity name",
    "last_update": "Last update",
    "no_entities_selected": "No entities selected",
    "notify_entities_confirm": "The following entities will be notified. Confirmed?",
    "data_entry_check": "There are KPIs without completed values",
    "data_entry_closing": "Closing Cycle Confirmation",
    "activate_cycle": "Activate Cycle",
    "reopen_cycle": "Reopen Cycle",
    "close_cycle": "Close Cycle"
  },
  "pillar": {
    "title": "Pillars",
    "create": "New Pillar",
    "enabling_entities": "Enabling Entities",
    "delete_yes_no_title": "Pillar Delete Confirmation",
    "delete_yes_no_information": "This pillar is about to be deleted",
    "manage": "Pillars",
    "contribution": "Contribution",
    "performance": {
      "pillar_performance": "Pillar Performance",
      "actual_value": "Actual Value",
      "kpi_performance": "KPI Performance"
    }
  },
  "audit_trace": {
    "title": "System Logs"
  },
  "connections": {
    "double_click_delete": "Double Click to Delete a Connection Visually",
    "focus": "Focus",
    "view_soft_connections": "View Soft Connections",
    "view_hard_connections": "View Hard Connections",
    "title": "Connections",
    "unlinked": "Unlinked Items",
    "linked": "Linked Items",
    "hard_connection": "Hard Connections",
    "soft_connections": "Soft Connections",
    "reload": "Reload",
    "show_all": "Show All",
    "Export": "Export",
    "kpi": "KPI",
    "objective": "Objective",
    "initiative": "Initiative",
    "service": "Service",
    "activity": "Activity",
    "print": "Print",
    "gov_direction": "Government Directions"
  },
  "plusbutton": {
    "audit_trail": "View Audit Trail",
    "delivery_module": "Go To Delivery Module",
    "newhelpdeskticket": "New Ticket",
    "initiatives": "New Initiative",
    "objectives": "New Objective",
    "enablerobjective": "New Enabler Objective",
    "nkpi": "New Service KPI",
    "print": "Print",
    "ekpi": "New EKPI",
    "comment": "New Comment",
    "info": "Request Information",
    "tags": "Tags",
    "connect": "Connections",
    "dtinput": "Data Input",
    "activities": "New Activity",
    "sendmessage": "Send Message",
    "export": "Export to PDF",
    "workflow_history": "Workflow History",
    "showvision": "Show Vision"
  },
  "wf_status": {
    "D": "Deleted",
    "E": "Edited",
    "A": "Active",
    "O": "On Approval",
    "R": "Rejected"
  },
  "status": {
    "1": "Active",
    "2": "Delete",
    "3": "Draft",
    "4": "Closed",
    "5": "Archived",
    "6": "Planning",
    "I": "Delete",
    "A": "Active",
    "M": "Merged",
    "L4": "L4",
    "L3": "L3",
    "L2": "L2",
    "L1": "L1",
    "level": "L",
    "D": "Draft",
    "kpi1": "KPI Approved",
    "kpi3": "Draft KPI",
    "kpi4": "KPI Under Review",
    "objective1": "Draft Objective",
    "objective2": "Objective Under Review",
    "objective3": "Objective Approved",
    "in_review": "In Review"
  },
  "communications_status": {
    "A": "Actions",
    "R": "Requests",
    "I": "Information"
  },
  "communications_tatus": {
    "D": "Delete",
    "R": "Request",
    "I": "Info"
  },
  "tooltips": {
    "audit-trail": "Audit",
    "connections": "Connections",
    "related_objects": "Related Objects",
    "edit_connections": "Edit Connections",
    "view": "View",
    "edit": "Edit",
    "delete": "Delete",
    "plans": "Plans",
    "add": "Add",
    "delete_disabled": "You can't delete the current item",
    "edit_disabled": "You can't edit the current item",
    "new": "New",
    "export": "Export",
    "Pending": "Draft",
    "notification_center": "Notification Center",
    "help_center": "Help Center",
    "import": "Import",
    "tree_view": "Tree View",
    "list_view": "List View",
    "settings": "Settings",
    "print": "Print",
    "download_excel": "Download Excel",
    "filter": "Filter",
    "chart": "Chart",
    "download": "Download",
    "download_image": "Download Image",
    "open": "Open"
  },
  "main_types": {
    "G": "Shared (Government)",
    "L": "Local (Entity)",
    "LA": "Local All"
  },
  "time": {
    "ago": "ago",
    "minute": "minute",
    "minutes": "minutes",
    "hour": "hour",
    "hours": "hours",
    "day": "day",
    "days": "days",
    "time": "Time"
  },
  "library": {
    "metrics": "Metrics",
    "dimensions": "Dimensions",
    "attributes": "Attributes",
    "formulas": "Formulas"
  },
  "context_menu": {
    "create": {
      "initiative": "Create New Initiative",
      "skpi": "Create New SKPI",
      "activity": "Create New Activity",
      "opm": "Create New OPM",
      "servicekpi": "Create New Service KPI"
    },
    "add": {
      "service": "Add Service",
      "kpi": "Add  KPI"
    }
  },
  "search": {
    "loading": "Loading results...",
    "noresults": "No results found",
    "searchingfor": "You are searching for",
    "found": "found",
    "of": "of",
    "documents": "Documents",
    "services": "Services",
    "attachments": "Attachments",
    "activities": "Activities",
    "resultsfound": "result(s) found"
  },
  "help_modal": {
    "list": "Help Modal",
    "watch_video": "Watch Video",
    "video": "Video",
    "preview": "Preview",
    "content1AE": "Content 1 (Arabic)",
    "content1EN": "Content 1 (English)",
    "content2AE": "Content 2 (Arabic)",
    "content2EN": "Content 2 (English)",
    "content3AE": "Content 3 (Arabic)",
    "content3EN": "Content 3 (English)",
    "delete_yes_no_title": "Help Context Delete Confirmation",
    "delete_yes_no_information": "Please Confirm"
  },
  "plan_editor": {
    "added_kpis": "Added Kpis",
    "unlinked_kpis": "Unlinked Kpis",
    "add_kpis": "Add Kpis",
    "add_services": "Add Services",
    "added_services": "Added Services",
    "unlinked_services": "Unlinked services",
    "title": "Future Vision",
    "manage": "Manage Plan",
    "weight_review": "Weight Review",
    "submit_review": "Submit Review",
    "add_objective": "Add Objective",
    "intro_message": "Start by Adding your first objective",
    "temporary": "Waiting for Implementation",
    "add_enabler_objective": "Add Enabler Objective"
  },
  "user_roles": {
    "grid": "User Roles",
    "title": "User Roles",
    "add_button_list": "New Role",
    "modals": {
      "refresh_pmo_permissions": {
        "title": "Refresh permissions",
        "info": "Selected permissions will be removed"
      },
      "delete_yes_no": {
        "title": "User Role Delete Confirmation",
        "info": "The user role is about to be deleted"
      }
    }
  },
  "user_groups": {
    "title": "Users Groups",
    "add_button_list": "New Group",
    "add_button_user": "Add User",
    "modals": {
      "add_user": {
        "title": "Add User"
      },
      "delete_yes_no": {
        "title": "User Group Delete Confirmation",
        "info": "The user group is about to be deleted"
      }
    }
  },
  "data_cycle_automation": {
    "title": "Data Entry Timelines",
    "add_rule": "New rule",
    "all_metrics": "All Metrics",
    "frequency": "Frequency",
    "days_before": "Days before EOP",
    "days_after": "Days after EOP",
    "affected": "Affected",
    "delete_yes_no_title": "Delete Rule",
    "delete_yes_no_information": "The Rule is about to be deleted"
  },
  "user_profile": {
    "channels": "Notifications channels",
    "wizard": {
      "responsible_for": "Scope of Responsibility (Entities)",
      "preferred_language": "Preferred Language",
      "preferred_channel": "Preferred Channel",
      "notification_settings": "Notification settings",
      "title": "User Profile",
      "personalInformation": "Personal Information",
      "security": "Security",
      "owned_objects": "Responsibilities",
      "informationSaved": "Information saved successfully",
      "error": "You must choose different questions",
      "allQuestions": "Please answer all questions"
    }
  },
  "landingPages": {
    "entity_team": "Government Entity ",
    "directional_team": "Government Directional  Team",
    "enabler_team": "Government  Enabler  Team",
    "service_kpi_team": "Government Service Team",
    "national_team": "National Agenda Team",
    "skpi_opm_team": "Strategic Performance Team"
  },
  "translation_module": {
    "title": "Translation Module",
    "arabic": "Arabic",
    "english": "English"
  },
  "landingPage": {
    "mytasks": {
      "title": "My Tasks",
      "workflowRequest": "Workflow request",
      "numberDestinationWorkflow": "Number of destinations workflow",
      "numberOfRequests": "Number of requests for",
      "NamesOfEntities": "Names of entities"
    },
    "subgovdirection": {
      "title": "Sub-Government Directions"
    },
    "pillars": {
      "title": "Pillars",
      "ekpis_list": "EKPIS",
      "no_pillars": "Pillars not found!"
    },
    "kpiTable": {
      "dkpi": "Directional KPI",
      "nkpi": "National KPI",
      "kpiName": "KPI Name",
      "entity": "Entity",
      "dataAvailability": "Data Availability",
      "trend": "Trend",
      "lastUpdate": "Last Update"
    },
    "menu": {
      "get": "Government  Enabler Team",
      "nat": "National Agenda Team",
      "gspd": "Government Service Team",
      "dkpi": "Government Directional Team",
      "entity": "Government Entity ",
      "skpi-opm": "Strategic Performance Team"
    },
    "activeNkpis": "Active NKPIS",
    "activeDkpi": "Active DKPIs",
    "executiveTeam": {
      "title": "Executive Teams",
      "name": "Name"
    },
    "audit": {
      "title": "Audits",
      "first": "Audit",
      "operational": "Operational"
    },
    "favorites": {
      "title": "Favorites",
      "item": "Item",
      "type": "Type",
      "entity": "Entity",
      "direction": "Direction",
      "view": "View"
    },
    "highpriority": {
      "title": "High Priority Initiatives"
    },
    "initiatives": {
      "name": "Name",
      "percentage": "Percentage",
      "action": "Action"
    },
    "entity_tasks": {
      "title": "Entity Tasks"
    },
    "entity_page": {
      "enabler_performance": "Enabler Performance",
      "government_enabler_performance": "Government Enabler Performance",
      "government_overall_performance": "UAE Government Overall Performance",
      "entities_update": "Entities Updates",
      "last_update_khadamati": "Last update in Khadamati",
      "kpi_rate_update": "Data Entry Completion",
      "government_enabler_pillars": "Government Enabler Pillars",
      "entity_landing_page": "Entity Landing Page",
      "perfomance": "Strategic Performance",
      "entity_overall_performance": "Entity Overall Performance",
      "overall_performance": "Overall Performance",
      "select_period": "Period",
      "performance_on": "Performance On",
      "annual_performance": "Annual Performance",
      "kpi_issues": "There are variations between the entered results and the audit results"
    },
    "entityUp": {
      "name": "Name",
      "oneQ": "First Quarter",
      "twoQ": "Second Quarter",
      "threeQ": "Third Quarter",
      "fourQ": "Fourth Quarter",
      "average": "Average",
      "last_update": "Last Update",
      "communication": "Communication"
    },
    "entityupdate": {
      "title": "Entity Update",
      "type": "Type",
      "objectname": "Object Name",
      "entity": "Entity"
    },
    "activeNkpi": "Active NKPI",
    "enablingEntity": "Enabling Entities",
    "integration_settings": "Integration Settings",
    "entity_landing_page": "Entity",
    "kpi_updates": "KPI Updates",
    "error_message": "There is no landing page assigned to your user account, please contact the system administrator",
    "entities_tasks": "Actions list"
  },
  "workflow": {
    "review": "Entity Approval",
    "approval": "PMO Approval",
    "configuration": "Configuration",
    "default": "Default Workflow",
    "changetodefault": "There is another workflow configuration that is currently set to Default. By clicking Yes, this workflow configuration will become the one and only Default.",
    "changedefault": "The created workflow will be selected as the default workflow",
    "create_rule_trigger": "Create Rule Trigger",
    "workflow_template": "Workflow Template",
    "rule_type": "Rule Type",
    "field": "Field",
    "operator": "Operator",
    "value": "Value",
    "rule_parameter": "Rule Parameters",
    "title": "Workflow Management",
    "workflow_history": "Workflow History",
    "workflow_assignment": "Workflow Configuration",
    "workflow_trigger": "Workflow Trigger",
    "workflowconfiguration": "Workflow Management",
    "workflowprofiles": "Workflow Profiles",
    "workflow_process_id": "Workflow Process ID",
    "workflowtype": "Workflow Type",
    "workflowactiontype": "Action Type",
    "addusergroup": "Add User Groups",
    "inputgrantlevels": "Input Grant Levels",
    "grantlevels": "Levels",
    "workflowlevels": "Workflow Levels",
    "workflowprofilelevels": "Action Takers",
    "aprovallevel": "Approval Levels",
    "availableusers": "Available Users",
    "grantedusers": "Granted Users",
    "workflowedit": "Edit Workflow",
    "grant_users": "Grant Users",
    "close": "Close",
    "itemTypeId": "Item Type Id",
    "itemType": "Item Type",
    "action": "Action",
    "workflow_triggers": "Workflow Triggers",
    "workflow_monitoring": "Workflow Monitoring",
    "trigger_rules": "Trigger Rules Configuration",
    "workflow": "Workflow",
    "savetrigger": "Save",
    "override_monitored": "Override Decision",
    "override_monitored_for": "for ",
    "pending_users": "Pending Users",
    "override_message": "Override Justification",
    "override_button": "Approve",
    "pending_action_users": "Workflow Action is pending with",
    "same_name_triggers ": "Trigger with the same name. Please verify.",
    "override_monitored_decision": "Workflow Override Decision",
    "level_one": "Level 1",
    "level_two": "Level 2",
    "level_three": "Level 3",
    "level": "Level",
    "level_four": "Level 4",
    "level_org": "L",
    "bulk_approval": {
      "approve": "Bulk Approve",
      "reject": "Bulk Reject",
      "select_all": "Select All",
      "all_selected": "All Selected",
      "title": "Bulk Approval",
      "bulk_approval_button": "Perform Bulk Approval"
    },
    "workflow_pending_user_warn": "This list takes time to initialize. If you do not see a user you expect, please close this window and open it again after 10 seconds."
  },
  "chart": {
    "png": "Download PNG",
    "svf": "Download SVF",
    "svg": "Download SVG",
    "pdf": "Download PDF",
    "print_chart": "Print Chart",
    "jpg": "Download JPG",
    "periods": "Period"
  },
  "notification_config": {
    "templates": {
      "modal": {
        "placeholders_title": "Available Fields",
        "title": "Template Configuration"
      },
      "edit_buttons": {
        "app": "Edit Application Template",
        "sms": "Edit SMS Template",
        "email": "Edit Email Template"
      }
    }
  },
  "importExport": {
    "upload_file": "Upload File",
    "operation_type": {
      "export": "Export",
      "import": "Import"
    },
    "file_status": {
      "fail": "Fail",
      "pending": "In Progress",
      "done": "Done",
      "success": "Success"
    },
    "console": "Plan Import/Export ",
    "data_entry": "Actuals Import/Export",
    "kpi": "KPI Import/Export",
    "title_kpi": "KPI Import/Export",
    "title_plan": "Plan Import/Export ",
    "title_kpidata": "Actuals Import / Export ",
    "choose_file": "Choose File to Upload"
  },
  "faqs": {
    "faqs": "Frequently Asked Questions",
    "no_results": "Information not found"
  },
  "delivery_module": {
    "risk_management": "Risk Matrix",
    "prog_status": {
      "green": "Green",
      "lime": "Lime",
      "ambar": "Ambar",
      "red": "Red"
    },
    "title": "Delivery Module",
    "task": "Task",
    "tasks": "Tasks",
    "add_task": "Add Task",
    "contributingentities": "Stakeholders",
    "associate_with": "Risk Source",
    "priority": "Priority",
    "impact": "Impact",
    "last-edit": "Last modification Date",
    "risk_status": "Risk Status",
    "workload": "Weight",
    "stakeholders": "Stakeholders",
    "challange": "Challenges",
    "addchallanges": "Add New Challenge",
    "nextsteps": "Next Steps / Actions",
    "progress_report_freq": "Progress Report Frequency",
    "team_members": "Team Members",
    "actions": "Actions",
    "add_action": "Add Action",
    "progress_status": "Progress Status",
    "add_resource": "Add New Resource",
    "add_members": "Add Team Member",
    "linked_kpi": "Linked KPI's",
    "resources": "Resources",
    "input_duration": "Input Duration",
    "duration": "Duration",
    "description": "Initiative Description",
    "highpriority": "High Priority Initiative",
    "govs_subgovs": "Government and Sub-Government Directions",
    "monitored": "Monitored Initiative",
    "competences": "Competencies / Resource",
    "linked": "Linked KPI's",
    "risks": "Risks",
    "progressManager": "Progress Manager",
    "initiativeManager": "Initiative Manager",
    "warningActivity": "You must select a task in the activity",
    "pleaseReviewInformation": "Please review your information",
    "person_company": "Person/Company",
    "start_date": "Start Date",
    "continous": "Continous",
    "other": "Other",
    "resources_name_en": "Resources Name (English) ",
    "resources_name_ae": "Resources Name (Arabic) ",
    "warning_no_activities": "There are no activities associated with this Initiative",
    "difference": "Difference",
    "probability": "Probability",
    "progress_warning": "Progress must be updated and saved!",
    "progress_missing": "Missing Progress",
    "progress_updated": "Progress Updated",
    "initiative": "Initiative",
    "is_task_completed": "Task Completed"
  },
  "kpis": {
    "subkpis_selectFromExist": "Select from existing",
    "subkpis_weight": "Weight",
    "subkpis_name": "Name",
    "subkpis_listSelected": "Selected kpis",
    "subkpis_createNew": "Create New Sub-KPI",
    "subkpis_compatible": "Compatible",
    "subkpis_createKpi": "Create New Sub-KPI",
    "subkpis_buttonAdd": "Confirm",
    "subkpis_nameEN": "Name EN",
    "subkpis_nameAE": "Name AE",
    "subkpis_modalQuestion": "Are you sure?",
    "subkpis_aggreModalContent": "If you continue you will delete formula",
    "subkpis_titleModal": "Caution!",
    "subkpis_cannotBeChanged": "Be aware, after you submit you cannot change this option",
    "subkpis_selectSub": "Select KPI",
    "subkpis_loseInformation": "You will change the subkpis also",
    "subkpis_compatible_N": "No Compatible",
    "subkpis_compatible_Y": "Compatible",
    "subkpis_errorDate": "Start or end date is not compatible",
    "subkpis_errorTrend": "Trend is not compatible",
    "subkpis_errorMeasures": "Measurement unit is not compatible",
    "subkpis_errorFrequency": "Frequency is not compatible",
    "subkpis_errorTitle": "Sub Kpi Error",
    "subkpis_fill_info": "You must fill the information for the created Sub Kpi"
  },
  "smart_tool_tips": {
    "title": "Smart ToolTips",
    "edit": "Edit Smart Tooltip",
    "show": "Show Smart Tooltip ",
    "field_name_ae": "Name (Arabic)",
    "field_name_en": "Name (English)",
    "descriptionEN": "Smart Toolip Message (English)",
    "descriptionAE": "Smart Toolip Message (Arabic)",
    "buttonLabel": "Done",
    "invalid_text": "Invalid Tool Tip Text",
    "notool_tips": "No tool tips for this component"
  },
  "text_mappings": {
    "delete_message": "This Text Mapping is about to be deleted",
    "delete_confirmation": "Text Mapping Delete Confirmation",
    "title": "Text Mappings",
    "create": {
      "title": "New Text Mapping"
    }
  },
  "import_export": {
    "console": "Import Export",
    "title": "Import Export",
    "status": "Plan Import Status"
  },
  "formulas": {
    "formula_list": "Formula List",
    "formula_tooltip_kpi": "Insert entity metrics using the # sign and shared metrics using the @ sign",
    "formula_tooltip": "You can use these symbols to create the formula template (), /, *, +, -, ^",
    "no_formulas_available": "No Formulas Available",
    "delete_yes_no_title": "Formula Delete Confirmation",
    "delete_yes_no_message": "This Formula is about to be deleted",
    "title_manage": " Formulas Template",
    "formula_pick_name": "Formula Name",
    "formula_pick_structure": "Formula Structure",
    "formula_console_icon": "Formulas Template Management",
    "formulas_template": "Formulas Template Management"
  },
  "info": {
    "reporting": "Reporting in Progress",
    "pmo_manual_progtess": "PMO User Manual in Progress",
    "manual_progress": "English User Manual in Progress"
  },
  "lookup_manager": {
    "title": "Lookup Manager",
    "enabled_active": "Active",
    "enabled_deactive": "Inactive"
  },
  "rules_management": {
    "rule": {
      "delete_yes_no_title": "Rule Delete Confirmation",
      "delete_yes_no_information": "Rule is about to be deleted"
    },
    "rules_management": "Rules Management",
    "title": "Rules Management",
    "add_ruleset": "Add Ruleset",
    "edit_ruleset": "Edit Ruleset",
    "ruleset_type": "Ruleset Type",
    "ruleset_severity": "Ruleset Severity",
    "ruleset_combination": "Activation logic",
    "snooze": "Deactivate",
    "scope": "Scope of Execution",
    "entities": "Entities",
    "selected_entities": "Selected Entities",
    "ruleType": "Rule Type",
    "table": "Table",
    "field": "Field",
    "operator": "Operator",
    "value": "Value",
    "create": "Create",
    "select_schedule": "Select Schedule",
    "start_hour": "Start Hour",
    "rules": "Rules",
    "is_not_null": "Is not null",
    "conditional": "Conditional",
    "complex_file": "Complex File Check",
    "available_objects": "Available Objects",
    "trigger_actions": "Action",
    "trigger_already_Exists": "This trigger already exists",
    "add_rule": "Add Rule",
    "show_log": "Object Log",
    "delete_yes_no_title": "Ruleset Delete Confirmation",
    "delete_yes_no_information": "Ruleset is about to be deleted",
    "simulation": "Simulation"
  },
  "guided_tour": {
    "question": "Want a help, start the guided tour.",
    "modal_title": "ADAA 3.0 Onboarding Tour"
  },
  "lookup": {
    "management": {
      "title": "Lookup Management",
      "mes_unit": "Measurement unit"
    },
    "content": {
      "title": "Lookup Content",
      "delete_yes_no_title": "Lookup Delete Confirmation",
      "delete_yes_no_information": "The lookup item is about to be deleted"
    }
  },
  "box_status": {
    "annual_performance": "Annual Performance",
    "activity_progress": "Progress to Date",
    "current": "Current Performance",
    "initiative_progress": "Initiatives Progress",
    "year_to_period": "Year to Period"
  },
  "auditrail": {
    "title": "System Logs",
    "audittrail_detail_info": "Details",
    "username": "Username",
    "page": "Page",
    "date": "Date",
    "object": "Object",
    "entities": "Entities",
    "kpi_type": "Kpi Type"
  },
  "entity_migration": {
    "title": "Entity Data Management"
  },
  "perform_analysis": {
    "console_icon": "Perform Analysis",
    "title": "Analysis",
    "card_title": "Perform Analysis",
    "modal_title": "Perform Analysis",
    "action_date": "Date",
    "actions": "Actions",
    "new_action": "New Action",
    "pending": "Pending",
    "work_in_progress": "Work In Progress",
    "done": "Done",
    "users_comments": "Users Comments",
    "request_status": "Request Status",
    "view_card": "View Card",
    "button_done": "Update Request Status",
    "performStatus_pending": "Request Status need to be changed"
  },
  "attachment": {
    "max_file_size": "Max File size allowed: 25 MB",
    "import_file_types_allowed": "File Types Allowed: XLS, XLSX, XLSM",
    "file_types_allowed": "File Types Allowed: PDF,ZIP,TXT,Microsoft Office (Word,Excel,PowerPoint)"
  },
  "plan_wizard": {
    "title": {
      "review_approve": "Review and Approve Plan"
    },
    "console_icon": {
      "review_approve": "Review And Approve Plan"
    },
    "labels": {
      "missionAE": "Mission (Arabic)",
      "missionEN": "Mission (English)",
      "visionAE": "Vision (Arabic)",
      "visionEN": "Vision (English)",
      "strengthAE": "Strength (Arabic)",
      "strengthEN": "Strength (English)",
      "opportunityAE": "Opportunities (Arabic)",
      "opportunityEN": "Opportunities (English)",
      "weaknessAE": "Weakness (Arabic)",
      "weaknessEN": "Weakness (English)",
      "threatAE": "Threats (Arabic)",
      "threatEN": "Threats (English)",
      "challenges": "Challenges"
    },
    "steps": {
      "strategic_plan": "Strategic Plan",
      "mission_vision": "Mission and Vision"
    },
    "status_button": {
      "send_to_review": "Send To Review",
      "send_to_approval": "Send To Approval"
    },
    "plan_status": {
      "approved": "Approved",
      "pmo_approval": "PMO Approval",
      "reviewed": "Reviewed",
      "pmo_review": "PMO Review",
      "not_reviewed": "Not Reviewed"
    }
  },
  "documents": {
    "title": "Entity Reports",
    "files_allowed": "File Types Allowed: PDF, DOC, DOCX, TXT",
    "new": "Upload new Document",
    "remove_file_title": "Documents Delete Information",
    "remove_file": "You are about to remove this document.",
    "attachdate": "Date"
  },
  "workflow_rejection_scheduler": {
    "title": "Workflow Rejection Scheduler",
    "pending_days": "Number of Pending Days",
    "buttons": {
      "submit": "Submit",
      "submit_config": "Submit Configuration"
    },
    "form_titles": {
      "scheduler_configuration": "Workflow Scheduler Configuration"
    }
  },
  "audit_data": {
    "tooltips": {
      "clone": "Clone"
    },
    "add_audit_criteria": "Create Audit Criteria",
    "add_audit_cycle": "Create a new Audit Cycle",
    "add_evaluation_group": "Add evaluation group",
    "audit_criteria": "Audit Criteria",
    "audit_criteria_delete_yes_no_information": "The audit criteria is about to be deleted, are you sure?",
    "audit_criteria_delete_yes_no_title": "Audit Criteria Delete Confirmation",
    "audit_criteria_item_delete_yes_no_information": "Are you sure you want to delete this group/item?",
    "audit_criteria_item_delete_yes_no_title": "Deleting evaluation group/item",
    "audit_criteria_set": "Audit Criteria Set",
    "audit_criterias": "Audit Criteria Sets",
    "audit_cycle": "Audit Cycle",
    "audit_cycle_delete_yes_no_information": "The audit cycle is about to be deleted, are you sure?",
    "audit_cycle_delete_yes_no_title": "Audit Cycle Delete Confirmation",
    "audit_cycles": "Audit Cycles",
    "audit_issues": "KPIs Which Need to be Rectified Based on Audit Results",
    "audit_year": "Audit Year",
    "available_entities": "Selected Entities",
    "available_kpis": "Available KPI Types",
    "clone_criteria_set": "Clone Criteria Set",
    "cloned_name_ae": "Cloned set name (Arabic)",
    "cloned_name_en": "Cloned set name (English)",
    "connected_entities": "Selected Entities",
    "connected_kpis": "Connected KPI Types",
    "create": "create",
    "create_custom_evaluation_group": " Create a custom evaluation group",
    "create_custom_evaluation_item": "Create an evaluation Item",
    "update_custom_evaluation_group": " Update a custom evaluation group",
    "update_custom_evaluation_item": "Update an evaluation Item",
    "define_target_issues_statement": "The targets must be defined",
    "entities": "Entities",
    "errors": {
      "invalidGroup": "Duplicate group",
      "invalidSet": "Duplicate item inside a group",
      "oneEvaluationItemAtLeast": "There should one evaulation item for each group at least",
      "oneGroupAtLeast": "There should one evaluation group at least.",
      "duplicate_record": "Duplicate record",
      "invalid_id": "Invalid ID",
      "connected_cycles": "Can not delete, there already connected cycles to this set."
    },
    "evaluation_group": "Evaluation Group",
    "evaluation_items": "Evaluation Items",
    "existing_audit_criteria_set": "Existing criteria set",
    "kpi_actuals": "Audit KPI Actuals",
    "kpi_audit_issues_statement": "This KPI has an audit issue in the following year(s)",
    "kpi_types": "KPI Types",
    "part_of": "Part of",
    "sponsor_entity": "Sponsor Entity",
    "title": "Audit",
    "update_audit_criteria": "Update Audit Criteria",
    "update_audit_cycle": "Update Audit Cycle",
    "view_audit_criteria": "View Audit Criteria",
    "view_audit_cycle": "View Audit Cycle"
  },
  "kpi_annual_weights": {
    "conf": "KPI Annual Weights Configuration",
    "strategic_kpi": "Strategic KPI Weighted Averages",
    "enabler_kpi": "Enabler KPI Weighted Averages",
    "operational_kpi": "Operational KPI Weighted Averages",
    "service_kpi": "Service KPI Weighted Averages",
    "prev_page": "Previous Page",
    "next_page": "Next Page",
    "num_of_pages": "Total Number of Pages",
    "enabled": "Enabled",
    "disabled": "Disabled",
    "next": "Next Page",
    "previous": "Previous Page",
    "start": "Start",
    "end": "End",
    "sort": "Sort",
    "prev": "Previous",
    "search": "Search by KPI Name",
    "curr": "Current",
    "tooltips": {
      "period_kpi_issue": "This year has an issue with KPI Annual Weights. Please click on this year to see which performance value has a problem",
      "kpi_annual_weights_issue_homepage": "This value cannot be calculated because the total weight of all involved KPIs does not add up to exactly 100%.  Please go to KPI Weights page to rectify",
      "equal_distribution": "Click to distribute Weights Equally",
      "conf_disabled_warn_msg": "KPI weights for this year have been disabled"
    },
    "msg": {
      "no_weight_distribution": "Equal Weights Distribution Not Supported",
      "kpi_created": "Highlighted KPI has been created",
      "kpi_deleted": "KPI has been deleted",
      "kpi_updated": "Highlighted KPI has been updated",
      "wfconfig_not_default": "KPI Weights cannot be enabled unless the KPI Weights workflow is configured.  Please contact your system administrator"
    },
    "modal": {
      "equal_distribution": {
        "title": "Are you sure ?",
        "msg": "This action will edit all the weights for the year"
      }
    }
  }
}
