<div class="bg-white shadow-md rounded text-center pointer" (click)="cardClicked.emit(project().id)">
    <div class="project-card row align-items-center">
        <div class="col-12 mb-2">
            <h6 class="fw-bold">{{ project() | translateToken: "name" }}</h6>
        </div>

        <div class="col-12 mb-2">
            <h6 class="entity-name fw-bold text-primary" [title]="project() | translateToken: 'entityName'">
                {{ project() | translateToken: "entityName" }}
            </h6>
        </div>

        <div class="col-12 mb-2">
            <adaa-progress-indicator
                [label]="('national_projects.project_progress' | translate) + ': '"
                [color]="project().onTimeProgressColor"
                [value]="project().progress"
            >
            </adaa-progress-indicator>
        </div>
        @if (showTargetsProgressBar()) {
            <div class="col-12 mb-3">
                <adaa-progress-indicator
                    [label]="('national_projects.target_achieved' | translate) + ': '"
                    [color]="project().visionScoreColor"
                    [value]="project().visionScore"
                >
                </adaa-progress-indicator>
            </div>
        }
        <div class="col-3">
            <adaa-picture [pictureId]="project().ownerProfilePictureId" [width]="'50px'"></adaa-picture>
        </div>
        <div class="col-9">
            <h6>{{ project() | translateToken: "ownerName" }}</h6>
        </div>
    </div>
    <div [class]="'rounded-bottom text-white col-12 bg-' + project().projectStatusId">
        <strong>{{ project() | translateToken: "projectStatus" }}</strong>
    </div>
</div>
