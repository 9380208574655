import { Injectable, signal } from "@angular/core";

import type { BaseButtonUiOption } from "../components/general/float-action/types";

export type FloatActionType = BaseButtonUiOption & {
  key: string;
  data: unknown;
};

@Injectable({
  providedIn: "root",
})
export class FloatActionService {
  actions = signal<FloatActionType[]>([]);

  public add(action: FloatActionType) {
    this.actions.update((actions) => [...actions, action]);
  }

  public clear() {
    this.actions.set([]);
  }
}
