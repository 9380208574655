<div class="d-flex justify-content-end gap-2">
    @if (enableDownloadImage()) {
        <button
            type="button"
            class="btn btn-sm bg-white text-primary rounded-circle shadow-sm border"
            [title]="'tooltips.download_image' | translate"
            (click)="exportImage()"
        >
            <i class="fa fa-lg fa-file-image"></i>
        </button>
    }
    @if (enableDownloadCSV()) {
        <button
            type="button"
            class="btn btn-sm bg-white text-primary rounded-circle shadow-sm border"
            [title]="'tooltips.download_excel' | translate"
            (click)="exportCSV()"
        >
            <i class="fa fa-lg fa-file-excel"></i>
        </button>
    }
    @if (enableDownloadPDF()) {
        <button
            type="button"
            class="btn btn-sm bg-white text-primary rounded-circle shadow-sm border"
            [title]="'plusbutton.export' | translate"
            (click)="exportPDF()"
        >
            <i class="fa fa-lg fa-file-pdf"></i>
        </button>
    }
</div>
