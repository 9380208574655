import { inject, Injectable } from "@angular/core";

import { AdaaHelper } from "../../core/utils";
import type {
  AverageEntityPerformanceModelType,
  EntityPerformanceModelType,
  GetPopupGraphArgs,
  GraphByAggregationTypeModelType,
  MainResponseModel,
  MasterEkpiActuals,
  PillarPerformanceModelType,
} from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class GraphApiService {
  private _mainService = inject(MainService);

  public getGraphByByAggregationType({
    itemTypeId,
    graph,
    frequency,
    entityId,
    periodId,
  }: {
    itemTypeId: number;
    graph: string;
    frequency: string;
    entityId: number;
    periodId: number;
  }) {
    const url = `calculationreading/getCalculationReadingsGraphCardByByAggregationType`;
    const qs = new URLSearchParams();

    qs.append("entityId", `${entityId}`);

    if (periodId) {
      qs.append("periodId", `${periodId}`);
    }

    return this._mainService.sendRequest<MainResponseModel<GraphByAggregationTypeModelType[]>>({
      url: `${url}/${itemTypeId}/${graph}/${frequency}?${qs.toString()}`,
      method: "POST",
      data: {},
    });
  }

  public getPerformanceGraphItems(params: GetPopupGraphArgs) {
    let url = "";
    const qs = new URLSearchParams();

    if (!params.isGov && params.isEnabler) {
      url = "calculationreadingannual/pillarEntities";
    } else {
      if (params.isAnnual) {
        url = "calculationreadingannual/entities";
      } else {
        url = "calculationreading/entities";
      }

      if (!params.isAnnual && params.isEnabler) {
        qs.append("periodId", `${params.periodId}`);
        qs.append("pillarId", `${params.id}`);
      } else if (params.isAnnual && params.isEnabler) {
        qs.append("year", `${params.periodId}`);
        qs.append("pillarId", `${params.id}`);
      } else if (!params.isAnnual && !params.isEnabler) {
        qs.append("periodId", `${params.periodId}`);
        qs.append(params.isGov ? "governmentAggregationType" : "entityAggregationType", `${params.id}`);
      } else {
        qs.append("year", `${params.periodId}`);
        qs.append(params.isGov ? "governmentAggregationType" : "entityAggregationType", `${params.id}`);
      }
    }

    if (params.entitySize && params.entitySize !== 0) {
      qs.append("entitySize", `${params.entitySize}`);
    }

    if (params.entityType && params.entityType !== 0) {
      qs.append("entityType", `${params.entityType}`);
    }

    return this._mainService.sendRequest<MainResponseModel<EntityPerformanceModelType[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getPerformanceGraphData(params: GetPopupGraphArgs) {
    let url = "";
    const qs = new URLSearchParams();

    if (!params.isGov && params.isEnabler) {
      url = `${params.isAnnual ? "calculationreadingannual/govPillars" : "calculationreading/govPillars"}`;
      url += `/${params.id}/${params.periodId}`;
    } else {
      if (params.isAnnual) {
        url = "calculationreadingannual/governments";
      } else {
        url = "calculationreading/governments";
      }

      if (!params.isAnnual && params.isEnabler) {
        qs.append("periodId", `${params.periodId}`);
        qs.append("pillarId", `${params.id}`);
      } else if (params.isAnnual && params.isEnabler) {
        qs.append("year", `${params.periodId}`);
        qs.append("pillarId", `${params.id}`);
      } else if (!params.isAnnual && !params.isEnabler) {
        qs.append("periodId", `${params.periodId}`);
        qs.append(params.isGov ? "governmentAggregationType" : "entityAggregationType", `${params.id}`);
      } else {
        qs.append("year", `${params.periodId}`);
        qs.append(params.isGov ? "governmentAggregationType" : "entityAggregationType", `${params.id}`);
      }
    }

    if (params.entitySize && params.entitySize !== 0) {
      qs.append("entitySize", `${params.entitySize}`);
    }

    if (params.entityType && params.entityType !== 0) {
      qs.append("entityType", `${params.entityType}`);
    }

    return this._mainService.sendRequest<MainResponseModel<AverageEntityPerformanceModelType>>({
      method: "GET",
      url: `${url}${qs.toString() && qs.toString().length > 0 ? "?" + qs.toString() : ""}`,
    });
  }

  public getEkpiActualsByPeriod(ekpiId: number, periodId: number) {
    const url = `calculationreading/ekpi-actuals?ekpiId=${ekpiId}&periodId=${periodId}`;

    return this._mainService.sendRequest<MainResponseModel<MasterEkpiActuals>>({
      method: "GET",
      url: `${url}`,
    });
  }

  public getAveragePerformanceData(
    aggType: number,
    year: number,
    params: Partial<{ entityType: number; entitySize: number }> = {}
  ) {
    const url = "calculationreadingannual/calculateGovPerformance";
    const qs = new URLSearchParams();

    if (params.entityType) {
      qs.append("entityType", `${params.entityType}`);
    }
    if (params.entitySize) {
      qs.append("entitySize", `${params.entitySize}`);
    }

    return this._mainService.sendRequest<MainResponseModel<{ score: number }>>({
      method: "GET",
      url: `${url}/${aggType}/${year}?${qs.toString()}`,
    });
  }

  public getPillarPerformance(periodId: number, entityId: number, isQuarter: boolean) {
    const url = `pillar/getPillarsPerformanceByEntity`;
    const qs = new URLSearchParams();
    qs.append("entityId", `${entityId}`);
    qs.append(isQuarter ? "periodId" : "year", `${periodId}`);

    return this._mainService.sendRequest<MainResponseModel<PillarPerformanceModelType[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getEkpiActuals(ekpiId: number, year: number) {
    const url = `calculationreadingannual/ekpi-actuals`;
    const qs = new URLSearchParams();

    AdaaHelper.isPMOEntity() ? qs.append("masterEkpiId", `${ekpiId}`) : qs.append("ekpiId", `${ekpiId}`);
    qs.append("year", `${year}`);

    return this._mainService.sendRequest<MainResponseModel<MasterEkpiActuals>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }
}
