import { Component, inject, output, signal, type TemplateRef, viewChild } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbModal, type NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { CardNoteApiService, LanguageService } from "../../../services";
import { FormInputComponent } from "../../form";

@Component({
  selector: "adaa-notes-popup",
  standalone: true,
  imports: [ReactiveFormsModule, FormInputComponent, TranslateModule],
  template: `
    <ng-template #modal let-modal>
      <div class="modal-header bg-white">
        <div class="w-100 d-flex justify-content-center">
          <h4 class="fw-bold w-100 text-center py-1">{{ "objectives.add_notes" | translate }}</h4>

          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
        </div>
      </div>

      <div class="modal-body">
        <form [formGroup]="model" class="w-100 p2" (ngSubmit)="submit()">
          <div class="d-block py-1">
            <adaa-form-input
              controlName="noteAE"
              formControlName="noteAE"
              [invalid]="model.get('noteAE')?.dirty! && model.get('noteAE')?.invalid!"
              [label]="'common.form.label.descriptionAE' | translate"
              [isTextArea]="true"
            />
          </div>

          <div class="d-block py-1">
            <adaa-form-input
              controlName="noteEN"
              formControlName="noteEN"
              [invalid]="model.get('noteEN')?.dirty! && model.get('noteEN')?.invalid!"
              [label]="'common.form.label.descriptionEN' | translate"
              [isTextArea]="true"
            />
          </div>

          <div class="d-flex w-100 justify-content-center py-2">
            <button type="reset" class="btn btn-outline-danger p-2 mx-2" (click)="modal.dismiss()">
              <span>{{ "common.form.button.cancel" | translate }}</span>
            </button>

            <button type="submit" class="btn btn-primary px-4 py-2 mx-2">
              <span>{{ "common.form.button.save" | translate }}</span>
            </button>
          </div>
        </form>
      </div>
    </ng-template>
  `,
})
export class NotesPopupComponent {
  private readonly _modalService = inject(NgbModal);
  private readonly _languageService = inject(LanguageService);
  private readonly _cardNoteService = inject(CardNoteApiService);

  id = signal<number | undefined>(undefined);
  type = signal<number | undefined>(undefined);

  isSaved = output<void>();

  formModal = viewChild.required<TemplateRef<unknown>>("modal");

  modalRef: NgbModalRef;

  model = new FormGroup({
    noteAE: new FormControl<string | undefined>(undefined, [Validators.minLength(3)]),
    noteEN: new FormControl<string | undefined>(undefined, [Validators.minLength(3)]),
  });

  public submit() {
    this._cardNoteService
      .create({
        userId: Number(
          AdaaHelper.getLocalStorage("user", {
            property: "id",
            type: "prop",
          })
        ),
        itemId: this.id()!,
        itemType: this.type()!,
        noteAE: this.model.value?.noteAE as string,
        noteEN: this.model.value?.noteEN as string,
      })
      .subscribe({
        next: () => {
          this.isSaved.emit();
          this.id.set(undefined);
          this.type.set(undefined);
          this.model.reset({
            noteAE: undefined,
            noteEN: undefined,
          });
        },
      });
  }

  public open(id: number, typeId: number) {
    this.id.set(id);
    this.type.set(typeId);

    this.modalRef = this._modalService.open(this.formModal(), {
      animation: true,
      scrollable: false,
      centered: true,
      size: "lg",
      modalDialogClass: this._languageService.modalDirection(),
    });
  }
}
