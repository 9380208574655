<div class="modal-header">
    @if (!showMetricsList()) {
        <h4 class="modal-title">
            {{ "government_scores.scopes.main_kpi" | translate }}
            <span class="fw-light mx-1">
                @if (displayLabel) {
                    - {{ displayLabel }}
                }
            </span>
        </h4>
        <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
    } @else {
        <h4 class="modal-title">
            {{ "metrics.metrics_selections" | translate }}
        </h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="selectedMetric.set(undefined); showMetricsList.set(false)"
        ></button>
    }
</div>
<div class="modal-body">
    @if (!showMetricsList()) {
        <adaa-form-steps [formGroup]="kpiForm" [tabs]="tabs()" [activeTab]="activeTab()" [showActions]="false" (currentTab)="activeTab.set($event)">
            <div class="tab-pane fade" id="tab-0" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 0 }">
                <div class="bg-white shadow-md rounded p-2 mb-2">
                    @if (pageMode === PageMode.create) {
                        <adaa-form-dropdown
                            controlName="mainKpiType"
                            formControlName="mainKpiType"
                            [searchable]="true"
                            [label]="'data_entry.kpi_type' | translate"
                            [options]="kpiTypes()"
                            [invalid]="kpiForm.get('mainKpiType')?.touched! && kpiForm.get('mainKpiType')?.invalid!"
                            [required]="true"
                            (inputChanges)="mainKpiTypeChanged($event)"
                        />
                        @if (!isCrossCycleKpi) {
                            <adaa-form-dropdown
                                controlName="mainPlanId"
                                formControlName="mainPlanId"
                                [searchable]="true"
                                [label]="'cycle.title' | translate"
                                [options]="cycles()"
                                [invalid]="kpiForm.get('mainPlanId')?.touched! && kpiForm.get('mainPlanId')?.invalid!"
                                [required]="true"
                                (inputChanges)="mainPlanIdChanged($event)"
                            />
                        }
                    }
                    <adaa-form-dropdown
                        controlName="id"
                        formControlName="id"
                        inputPlaceholder="government_scores.scopes.search_for_more"
                        [searchable]="true"
                        [clearable]="true"
                        [label]="'government_scores.scopes.main_kpi' | translate"
                        [options]="kpis()"
                        [invalid]="kpiForm.get('id')?.touched! && kpiForm.get('id')?.invalid!"
                        [required]="true"
                        (inputChanges)="onKpiChange($event)"
                        (onSearch)="onSearch($event)"
                    />
                </div>
            </div>

            <div class="tab-pane fade" id="tab-1" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 1 }">
                <div class="bg-white shadow-md rounded p-2 mb-2">
                    <div class="row d-flex align-content-center justify-content-between align-items-center">
                        <div class="col-4">
                            <h5 class="text-center text-primary fw-bold">
                                {{ "metrics.label_name" | translate }}
                            </h5>
                        </div>
                        <div [class]="showYTP ? 'col-4' : 'col-8'">
                            <h5 class="text-center text-primary fw-bold">
                                {{ "metrics.label_description" | translate }}
                            </h5>
                        </div>
                        @if (showYTP) {
                            <div class="col-4">
                                <h5 class="text-center text-primary fw-bold">
                                    {{ "kpi.year_to_period" | translate }}
                                </h5>
                            </div>
                        }
                    </div>
                    @for (metric of kpiForm.get("metrics")?.value; track metric) {
                        <div class="row d-flex align-content-center justify-content-between align-items-center">
                            <div class="col-4 text-center">
                                <button
                                    class="fw-bold btn btn-outline-info"
                                    type="button"
                                    [disabled]="isViewMode"
                                    (click)="selectedMetric.set(metric); showMetricsList.set(true)"
                                >
                                    {{ metric.nameAE.toLocaleUpperCase() }}
                                    @switch (metric.metricType) {
                                        @case (constants.METRICS_TYPE.GLOBAL) {
                                            <span>[{{ "metrics.global_var" | translate }}]</span>
                                        }
                                        @case (constants.METRICS_TYPE.LOCAL) {
                                            <span>[{{ "metrics.local_var" | translate }}]</span>
                                        }
                                        @case (constants.METRICS_TYPE.DIMENSION) {
                                            <span>[{{ "main_types.M" | translate }}]</span>
                                        }
                                        @default {
                                            <span>[{{ "metrics.custom_var" | translate }}]</span>
                                        }
                                    }
                                </button>
                            </div>
                            <div [class]="showYTP ? 'col-4' : 'col-8'">
                                <adaa-form-input [setDefaultValue]="adaaHelper.getItemValueByToken(metric, 'dsc')" [isDisabled]="true" />
                            </div>
                            @if (showYTP) {
                                <div class="col-4">
                                    <adaa-form-dropdown
                                        [setDefaultValue]="metric.ytpCalc"
                                        [isDisabled]="isViewMode"
                                        [options]="ytps()"
                                        (inputChanges)="onYtpChange($event, metric.id)"
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

            <div class="tab-pane fade" id="tab-2" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 2 }">
                <adaa-dimensions-editor
                    [pageMode]="pageMode"
                    [dimensionsSet]="kpi && kpi.dimensions ? kpi.dimensions : []"
                    (formChanged)="kpiForm.get('dimensions')?.setValue($event)"
                />
            </div>

            <div class="tab-pane fade" id="tab-3" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 3 }">
                <adaa-links-tab [entityId]="entityId" [kpi]="kpi" [pageMode]="pageMode" (formChanged)="kpiForm.patchValue($event)" />
            </div>
        </adaa-form-steps>
    } @else {
        <adaa-data-table
            [configKey]="constants.MAIN_TABLE_LIST_CONF_KEY.METRICS_MONITORING"
            apiName="getAllMonitoring"
            paramsString="available=Y"
            [enableAdd]="false"
            [enableExport]="false"
            [enableSelection]="true"
            [enableActionsCell]="false"
            (getRowData)="metricSelected($event)"
        />
    }
</div>
<div class="modal-footer">
    @if (!showMetricsList()) {
        <button type="button" class="btn btn-secondary" (click)="modal.close()">
            {{ "common.form.button.close" | translate }}
        </button>
        @if (pageMode !== PageMode.view) {
            <button type="button" class="btn btn-primary" (click)="save()">
                {{ "common.form.button.save" | translate }}
            </button>
        }
    } @else {
        <button type="button" class="btn btn-secondary" (click)="selectedMetric.set(undefined); showMetricsList.set(false)">
            {{ "common.form.button.cancel" | translate }}
        </button>
    }
</div>
