import { Component, computed, EventEmitter, input, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../core/utils";
import { Constants } from "../../constants/constants";
import { ValidatorModelType, ValueText } from "../../models";
import { FormTwoWaySelectComponent } from "../form";

@Component({
  selector: "adaa-contributing-entities",
  standalone: true,
  imports: [TranslateModule, FormTwoWaySelectComponent],
  templateUrl: "./contributing-entities.component.html",
  styleUrl: "./contributing-entities.component.scss",
})
export class ContributingEntitiesComponent {
  id = input.required<number>();
  itemType = input.required<number>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contributingEntities = input.required<any[]>();

  entities = input.required<ValueText[]>();
  entitiesControlName = input.required<string>();

  otherEntitiesControlName = input.required<string>();
  otherEntities = input.required<ValueText[]>();

  formValidation = input<ValidatorModelType>({
    id: 0,
    status: 0,
    searchKey: "",
    type: 0,
    parameterCatalogs: [],
    nameAE: "",
    nameEN: "",
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() contributingEntitiesChanged = new EventEmitter<any[]>();

  selectedEntities = computed<number[]>(() => {
    const entities = (this.contributingEntities() || [])
      .filter(
        (e: { otherEntityId: number | undefined; status: number }) =>
          !e.otherEntityId && (!e.status || e.status !== Constants.OBJECT_STATUS.REMOVE)
      )
      .map((e: { entityId: number }) => e.entityId);

    return entities;
  });

  selectedOtherEntities = computed<number[]>(() => {
    const otherEntities = (this.contributingEntities() || [])
      .filter(
        (e: { otherEntityId: number | undefined; status: number }) =>
          e.otherEntityId && (!e.status || e.status !== Constants.OBJECT_STATUS.REMOVE)
      )
      .map((e: { otherEntityId: number }) => e.otherEntityId);

    return otherEntities;
  });

  public setEntites(event: number[]): void {
    const contributingEntities = this.contributingEntities() ?? [];

    event.forEach((id) => {
      const item = contributingEntities
        .filter((e: { otherEntityId: number | undefined }) => !e.otherEntityId)
        .find((e: { entityId: number }) => e.entityId === id);
      if (!item) {
        const ce = {
          itemId: this.id(),
          itemType: this.itemType(),
          entityId: id,
        };
        contributingEntities.push(ce);
      } else {
        item.status = Constants.OBJECT_STATUS.ACTIVE;
      }
    });

    for (let i = contributingEntities.length - 1; i >= 0; i--) {
      if (contributingEntities[i].otherEntityId) continue;
      if (!event.includes(contributingEntities[i].entityId)) {
        if ("id" in contributingEntities[i]) {
          contributingEntities[i].status = Constants.OBJECT_STATUS.REMOVE;
        } else {
          contributingEntities.splice(i, 1);
        }
      }
    }

    this.contributingEntitiesChanged.emit(contributingEntities);
  }

  public setOtherEntites(event: number[]): void {
    const contributingEntities = this.contributingEntities() ?? [];

    event.forEach((id) => {
      const item = contributingEntities
        .filter((e: { otherEntityId: number | undefined }) => e.otherEntityId)
        .find((e: { otherEntityId: number }) => e.otherEntityId === id);
      if (!item) {
        const ce = {
          itemId: this.id(),
          itemType: this.itemType(),
          entityId: AdaaHelper.entity.id,
          otherEntityId: id,
        };
        contributingEntities.push(ce);
      } else {
        item.status = Constants.OBJECT_STATUS.ACTIVE;
      }
    });

    for (let i = contributingEntities.length - 1; i >= 0; i--) {
      if (!contributingEntities[i].otherEntityId) continue;
      if (!event.includes(contributingEntities[i].otherEntityId)) {
        if ("id" in contributingEntities[i]) {
          contributingEntities[i].status = Constants.OBJECT_STATUS.REMOVE;
        } else {
          contributingEntities.splice(i, 1);
        }
      }
    }

    this.contributingEntitiesChanged.emit(contributingEntities);
  }
}
