<section class="row d-block mt-2 formula-table-container">
    <table class="w-100 table">
        <thead>
            <tr>
                <td class="data-entry-col thead period-col" rowspan="3"></td>
                <td class="data-entry-col thead evidence-view" rowspan="3"></td>
                <td
                    class="data-entry-col thead"
                    [attr.colspan]="(metricList() ?? []).length * (this.isBounded() ? 4 : 3) + (!this.isBounded() ? 2 : 0)"
                >
                    {{ "data_entry.actual_target" | translate }}
                </td>
            </tr>

            <tr>
                @for (metric of metricList(); track $index) {
                    <td class="data-entry-col thead" [attr.colspan]="this.isBounded() ? 4 : 3">{{ metric.metricNameAE }}</td>
                }

                @if (!isBounded()) {
                    <td class="data-entry-col thead" [attr.rowspan]="2">
                        {{ "data_entry.calc_actual" | translate }}
                    </td>
                    <td class="data-entry-col thead" [attr.rowspan]="2">
                        {{ "data_entry.calc_target" | translate }}
                    </td>
                }
            </tr>

            <tr class="background-grey-blue">
                @for (metric of metricList(); track $index) {
                    <td class="data-entry-col thead">{{ "communication.difference.new_value" | translate }}</td>
                    <td class="data-entry-col thead">{{ "communication.difference.old_value" | translate }}</td>

                    @if (!isBounded()) {
                        <td class="data-entry-col thead">{{ "data_entry.target" | translate }}</td>
                    } @else {
                        <td class="data-entry-col thead">{{ "kpi.lower_limit" | translate }}</td>
                        <td class="data-entry-col thead">{{ "kpi.upper_limit" | translate }}</td>
                    }
                }
            </tr>
        </thead>

        <tbody>
            @for (request of newDataentryRequests(); let requestIndex = $index; track requestIndex) {
                @if (!isBounded()) {
                    <tr>
                        <td class="py-2 data-entry-col period-col fw-bold">{{ getPeriodLabel(request) }}</td>
                        <td class="py-2 data-entry-col">
                            <i class="adaa-icon-view1 fs-3 cursor-pointer" role="button" (click)="viewDifferences(request)"></i>
                        </td>

                        @for (metric of metricList(); let metricIndex = $index; track metricIndex) {
                            <td
                                class="py-2 data-entry-col"
                                dir="ltr"
                                [class.has-diff]="showOnlyNew() || request.metrics?.[+metricIndex]?.wfProcessCtlId"
                            >
                                {{ showNAFlag(+requestIndex, +metricIndex, "new") ? "N/A" : request.metrics?.[+metricIndex]?.actual }} &nbsp;
                            </td>

                            <td class="py-2 data-entry-col" dir="ltr" [class.has-diff]="request.metrics?.[+metricIndex]?.wfProcessCtlId">
                                {{ showNAFlag(+requestIndex, +metricIndex, "old") ? "N/A" : request.metrics?.[+metricIndex]?.previous }} &nbsp;
                            </td>

                            <td class="py-2 data-entry-col" dir="ltr">{{ request.metrics?.[+metricIndex]?.target }} &nbsp;</td>
                        }

                        <td class="py-2 data-entry-col" dir="ltr">{{ getCalculation(+requestIndex) }} &nbsp;</td>
                        <td class="py-2 data-entry-col" dir="ltr">{{ getCalculationTarget(+requestIndex) }} &nbsp;</td>
                    </tr>
                } @else {
                    <tr>
                        <td class="py-2 data-entry-col period-col fw-bold">{{ getPeriodLabel(request) }} &nbsp;</td>
                        <td class="py-2 data-entry-col">
                            <i class="adaa-icon-view1 fs-3 cursor-pointer" role="button"></i>
                        </td>

                        @for (metric of metricList(); let metricIndex = $index; track metricIndex) {
                            <td
                                class="py-2 data-entry-col"
                                dir="ltr"
                                [class.has-diff]="showOnlyNew() || request.metrics?.[+metricIndex]?.wfProcessCtlId"
                            >
                                {{ showNAFlag(+requestIndex, +metricIndex, "new") ? "N/A" : request.metrics?.[+metricIndex]?.actual }} &nbsp;
                            </td>

                            <td class="py-2 data-entry-col" dir="ltr" [class.has-diff]="request.metrics?.[+metricIndex]?.wfProcessCtlId">
                                {{ showNAFlag(+requestIndex, +metricIndex, "old") ? "N/A" : request.metrics?.[+metricIndex]?.previous }} &nbsp;
                            </td>

                            <td class="py-2 data-entry-col" dir="ltr">{{ getBoundedValue("lower", request) }} &nbsp;</td>

                            <td class="py-2 data-entry-col" dir="ltr">{{ getBoundedValue("high", request) }} &nbsp;</td>
                        }
                    </tr>
                }
            }
        </tbody>
    </table>
</section>
