<div class="row d-flex flex-wrap justify-content-center">
    <div class="sector-card col-12">
        <img height="70px" [src]="'assets/images/sectors/0.png'" width="70px" />
        <h4 class="text-primary fw-bold">{{ sectors().totalCount }}</h4>
        <p class="fw-bold">{{ "national_projects.transformation_project_that_achieves_qualitative" | translate }}</p>
    </div>

    @for (item of sectors().counts; track $index) {
        <div class="sector-card col-md-2 col-sm-12 col-xs-12" (click)="cardClicked.emit(item.id)">
            <img height="70px" [src]="'assets/images/sectors/' + item.id + '.png'" width="70px" />
            <h4 class="text-primary fw-bold">{{ item.count }}</h4>
            <p class="fw-bold">{{ item | translateToken: "name" }}</p>
        </div>
    }
</div>
