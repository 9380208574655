import { Component, computed, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { useNewDS } from "../../../../../../../core/utils";
import {
  NationalStrategyGovDirType,
  NationalStrategySubGovDirType,
} from "../../../../../general/national-strategies-selector/types";
import type { ScreenSectionConfigType } from "../../wf-difference.types";
import { LinkedNationalStrategiesCardViewComponent } from "./_cardview.component";

type ScreenData = {
  govDirections: (NationalStrategyGovDirType & { subGovs: NationalStrategySubGovDirType[] })[];
  govSubDirections: NationalStrategySubGovDirType[];
};

@Component({
  selector: "adaa-linked-national-strategies-diff-view",
  standalone: true,
  imports: [TranslateModule, LinkedNationalStrategiesCardViewComponent],
  styleUrl: "../styles.scss",
  templateUrl: "./linked-national-strategies-diff-view.component.html",
})
export class LinkedNationalStrategiesDiffViewComponent {
  planId = input<number>();
  itemTypeId = input<number>();
  newData = input<ScreenData>();
  oldData = input<ScreenData>();
  config = input<ScreenSectionConfigType>();
  showOnlyNew = input<boolean>();

  useNewDS = computed(() => useNewDS(this.planId()));
  showView = computed(() => {
    const newGovDirs = this.newData()?.govDirections;
    const oldGovDirs = this.oldData()?.govDirections;
    const hasData = (newGovDirs && newGovDirs.length > 0) || (oldGovDirs && oldGovDirs.length > 0);
    const canView = this.config()!.visibleIf!;

    if (!canView) return hasData;
    return canView({ itemTypeId: this.itemTypeId() } as never) && hasData;
  });
}
