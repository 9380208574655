<div class="form-check m-1" [class.form-switch]="useSwitch()" [dir]="languageService.direction()">
    <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        [formControl]="inputControl"
        [name]="controlName"
        [id]="controlId"
        [dir]="languageService.direction()"
        (change)="valueChanged($event)"
    />
    <label class="form-check-label" [class.fw-bold]="boldLabel" [for]="controlName">
        {{ label }}
    </label>
</div>
