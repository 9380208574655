<div class="table-responsive">
    <table
        class="table"
        [class]="data()['expanded'] ? 'expanded' : isEven() ? 'even' : 'odd'"
        [style.margin-left.px]="AdaaHelper.getCurrentLang() === Language.English ? 10 * level() : 0"
        [style.margin-right.px]="AdaaHelper.getCurrentLang() === Language.Arabic ? 10 * level() : 0"
    >
        <tbody>
            <tr>
                <td [style.width]="'2%'">
                    @if (data()[hasChildKey()]) {
                        @if (!data()["expanded"]) {
                            <fa-icon
                                class="pointer m-1"
                                [icon]="AdaaHelper.getCurrentLang() === Language.Arabic ? ['fas', 'angle-left'] : ['fas', 'angle-right']"
                                (click)="expandCollapseChild(true)"
                            ></fa-icon>
                        } @else {
                            <fa-icon class="pointer m-1" [icon]="['fas', 'angle-down']" (click)="expandCollapseChild(false)"></fa-icon>
                        }
                    }
                </td>

                @for (col of columns(); track col) {
                    <td [style.width]="col.width" [class]="structureViewService.getAlignment(col.alignment)">
                        @switch (col.type) {
                            @case ("text") {
                                {{ col.valueKey ? data()[col.valueKey] : "" }}
                                @if (col.showTags) {
                                    @if (data()["priorityHigh"] === AdaaBoolean.Y) {
                                        <span class="badge text-dark bg-white rounded-pill shadow-sm border border-1 border-secondary-subtle">
                                            {{ "initiatives.high_priority" | translate }}
                                        </span>
                                    }
                                    @if (data()["isLinked"] && data()["linkedKpiTypeId"]) {
                                        <span class="badge text-dark bg-white rounded-pill shadow-sm border border-1 border-secondary-subtle">
                                            {{ structureViewService.itemTranslationById(data(), "linkedKpiTypeId") }}
                                        </span>
                                    }
                                }
                            }
                            @case ("type") {
                                {{ structureViewService.itemTranslationById(data(), "type") }}
                            }
                            @case ("percentage") {
                                <span class="badge rounded-pill" [style.background]="structureViewService.getColor(data(), col.colorKey)">
                                    @if (col.valueKey && AdaaHelper.isDefined(data()[col.valueKey])) {
                                        {{ data()[col.valueKey] | percentageValue: 2 : "" }}
                                    }
                                </span>
                            }
                            @case ("status") {
                                @if (col.valueKey && AdaaHelper.isDefined(data()[col.valueKey])) {
                                    <span class="badge text-dark bg-white rounded-pill shadow-sm border border-1 border-secondary-subtle">
                                        @switch (data()[col.valueKey]) {
                                            @case (Constants.OBJECT_STATUS.DRAFT) {
                                                {{ "common.state.draft" | translate }}
                                            }
                                            @case (Constants.OBJECT_STATUS.ACTIVE) {
                                                {{ "common.state.approved" | translate }}
                                            }
                                            @case (Constants.OBJECT_STATUS.DRAFT) {
                                                {{ "status.in_review" | translate }}
                                            }
                                        }
                                    </span>
                                }
                            }
                            @case ("trend") {
                                @if (col.valueKey && AdaaHelper.isDefined(data()[col.valueKey])) {
                                    @switch (data()[col.valueKey]) {
                                        @case (Constants.TREND.INCREASING) {
                                            <span class="adaa-icon-increasing trend"> </span>
                                        }
                                        @case (Constants.TREND.DECREASING) {
                                            <span class="adaa-icon-decreasing trend"></span>
                                        }
                                        @case (Constants.TREND.ONTARGET) {
                                            <span class="adaa-icon-ontarget trend"></span>
                                        }
                                        @case (Constants.TREND.BOUNDED) {
                                            <span class="adaa-icon-bounded trend"></span>
                                        }
                                    }
                                }
                            }
                            @case ("actions") {
                                @if (!col.hasOwnProperty("onlyIf") || (col.onlyIf && col.onlyIf(data()))) {
                                    @if ((useGovScoresConfig() && level() !== 5) || !useGovScoresConfig()) {
                                        <span
                                            class="icon adaa-icon-view1 pointer"
                                            [title]="'tooltips.view' | translate"
                                            [style.margin-right.px]="AdaaHelper.getCurrentLang() === Language.English ? 10 * level() : 0"
                                            [style.margin-left.px]="AdaaHelper.getCurrentLang() === Language.Arabic ? 10 * level() : 0"
                                            (click)="structureViewService.viewClicked(data(), col.valueKey)"
                                        ></span>
                                    }
                                }
                            }
                            @default {}
                        }
                    </td>
                }
            </tr>
        </tbody>
    </table>
</div>

@if (this.data()["expanded"] && this.data()["childs"] && this.data()["childs"].length > 0) {
    @for (child of this.data()["childs"]; track child; let i = $index) {
        <adaa-nested-table-row
            [data]="child"
            [columns]="columns()"
            [isEven]="i % 2 === 0"
            [level]="level() + 1"
            [periodId]="periodId()"
            [useGovScoresConfig]="useGovScoresConfig()"
        />
    }
}
