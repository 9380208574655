import { AfterViewInit, Component, computed, ElementRef, inject, input, output, viewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import Handsontable from "handsontable";
import { CellChange } from "handsontable/common";
import { ColumnSettings, GridSettings } from "handsontable/settings";

import { environment } from "../../../../core/environments/environment";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { Language } from "../../../constants/enums";
import { AuditItem, DataEntry, DataEntryAuditEntry, DataEntryDetails } from "../../../models";
import { DataentryService } from "../../../services";
import { TableLegendDataEntryComponent } from "../../data-entry";

@Component({
  selector: "adaa-kpi-audit",
  standalone: true,
  imports: [TableLegendDataEntryComponent],
  templateUrl: "./kpi-audit.component.html",
  styleUrl: "./kpi-audit.component.scss",
})
export class KpiAuditComponent implements AfterViewInit {
  private _dataEntryService = inject(DataentryService);
  private _translateService = inject(TranslateService);

  dataEntryDetails = input.required<DataEntryDetails>();
  kpiId = input.required<number>();
  kpiType = input.required<number>();
  valueChanged = output<AuditItem[]>();

  hotTableContainer = viewChild.required<ElementRef>("hotTableContainer");

  hotInstance: Handsontable;
  auditValuesList: AuditItem[] = [];

  isBounded = computed<boolean>(() => this.dataEntryDetails().trend === Constants.TREND.BOUNDED);
  isFormula = computed<boolean>(() => this.dataEntryDetails().formula !== Constants.FORMULA_STRING.NOFORMULA);
  dataEntriesGroupedByPeriod = computed<Map<number, DataEntry[]>>(() =>
    this.dataEntryDetails().dataEntries
      ? AdaaHelper.groupBy(this.dataEntryDetails().dataEntries!, (e: { periodId: number }) => e.periodId)
      : new Map<number, DataEntry[]>([[0, []]])
  );

  public ngAfterViewInit(): void {
    const container = this.hotTableContainer().nativeElement;
    const options = this._getOptions();
    this.hotInstance = new Handsontable(container, options);
  }

  private _getOptions(): GridSettings {
    const currentLang = AdaaHelper.getCurrentLang();
    const { columnWidths, columns } = this._getColumns();

    return {
      data: this.isFormula() ? this._getFormulaData() : this._getNoFormulaData(),
      rowHeaders: this._getRowHeaders(),
      rowHeaderWidth: 150,
      colHeaders: this._getColHeaders(),
      columns: columns,
      colWidths: columnWidths,
      cells: (row, column, _prop) =>
        this._dataEntryService.setCellColorKpiAudit(row, column, this.dataEntryDetails(), this.auditValuesList),
      afterChange: (changes, _source) => this._handleCellValueChanged(changes),
      mergeCells: this._dataEntryService.getMergedCells(this.dataEntryDetails(), 2),
      //Same Conf
      stretchH: "none",
      height: "auto",
      viewportColumnRenderingOffset: 999999,
      viewportRowRenderingOffset: 999999,
      hiddenColumns: true,
      layoutDirection: currentLang === Language.Arabic ? "rtl" : "ltr",
      language: currentLang === Language.Arabic ? "ar-AR" : "en-US",
      licenseKey: environment.handsontable_key,
    };
  }

  private _getRowHeaders(): string[] {
    const headers: string[] = [];
    this.dataEntriesGroupedByPeriod()?.forEach((e) => {
      if (!e[0]) return;
      headers.push(this._dataEntryService.getLabelForPeriodColumn(e[0], this.dataEntryDetails().frequency!));
    });
    return headers;
  }

  private _getColHeaders(): string[] {
    const headers = [];

    if (this.isBounded()) {
      headers.push(
        this._translateService.instant("data_entry.target_lower_limit"),
        this._translateService.instant("data_entry.target_upper_limit")
      );
    } else {
      headers.push(this._translateService.instant("data_entry.target"));
    }

    headers.push(this._translateService.instant("data_entry.actual"));

    headers.push(
      this._translateService.instant("data_entry.annual_actual"),
      this._translateService.instant("data_entry.audited_annual_actual"),
      this._translateService.instant("common.form.label.comments")
    );

    return headers;
  }

  private _getColumns() {
    const columnWidths = this.isBounded() ? [150, 150, 150, 150, 150, 200] : [150, 150, 150, 150, 200];
    const columns: Array<ColumnSettings> = [];

    //Targets
    if (this.isBounded()) {
      columns.push(
        { readOnly: true, className: "disabledCell" }, // * Lower Limit
        { readOnly: true, className: "disabledCell" } // * Upper Limit
      );
    } else {
      columns.push({ readOnly: true, className: "disabledCell" });
    }

    //Actuals - Supports text map and normal input - textmapId
    if (this.dataEntryDetails().textmapId) {
      columns.push({
        type: "dropdown",
        source: [],
        readOnly: true,
        className: "disabledCell",
      });
    } else {
      columns.push({
        readOnly: true,
        className: "disabledCell",
      });
    }

    //Audit
    columns.push(
      { readOnly: true, className: "disabledCell" }, //annual actual
      {}, //audited annual actual
      {} //comment column
    );

    return { columnWidths, columns };
  }

  private _getNoFormulaData() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let innerData: any[] = [];

    if (!this.dataEntryDetails().dataEntries) return data;

    this.dataEntryDetails().dataEntries?.forEach((dataEntry) => {
      innerData = [];

      //TARGETS
      if (this.isBounded()) {
        const dataArray = dataEntry.isBaseLineYear
          ? [this._translateService.instant("kpi.baselineYear"), this._translateService.instant("kpi.baselineYear")] //baseline
          : this.dataEntryDetails().textmapId
            ? ["", ""] //textmapid
            : [dataEntry.lowerLimit, dataEntry.highLimit]; //default;

        innerData.push(...dataArray);
      } else {
        const dataArray = dataEntry.isBaseLineYear
          ? [this._translateService.instant("kpi.baselineYear")] //baseline
          : this.dataEntryDetails().textmapId
            ? [""] //textmapid
            : [dataEntry.target]; //default;

        innerData.push(...dataArray);
      }

      //ACTUALS
      if (dataEntry.ignored) innerData.push("N/A");
      else if (this.dataEntryDetails().textmapId) innerData.push("");
      else innerData.push(dataEntry.actual);

      //AUDITED VALUES
      const auditEntityByPeriod = this.dataEntryDetails().auditEntries!.find((e) => e.year === dataEntry.year);

      if (auditEntityByPeriod) {
        //Annual Actual Column
        innerData.push(auditEntityByPeriod.annualActualIgnored ? "N/A" : auditEntityByPeriod.annualActual);

        //Audited Annual Actual Column
        innerData.push(
          AdaaHelper.isDefined(auditEntityByPeriod.wfProcessCtlId)
            ? auditEntityByPeriod.proposedAuditIgnored
              ? "N/A"
              : auditEntityByPeriod.proposedAuditActual
            : auditEntityByPeriod.auditedActualIgnored
              ? "N/A"
              : auditEntityByPeriod.auditedActual
        );

        //Comments Column
        innerData.push(
          AdaaHelper.isDefined(auditEntityByPeriod.wfProcessCtlId)
            ? auditEntityByPeriod.proposedComment
            : auditEntityByPeriod.comment
        );
      } else {
        innerData.push("", "", "");
      }
      data.push(innerData);
    });

    return data;
  }

  private _getFormulaData() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let innerData: any[] = [];

    if (!this.dataEntriesGroupedByPeriod()) return data;

    this.dataEntriesGroupedByPeriod().forEach((dataEntry, _key) => {
      innerData = [];

      this.dataEntryDetails().metricDetails?.forEach((metric) => {
        const entry = dataEntry.find((e) => e.metricNameEN === metric.metricNameEN);
        if (!entry) return;

        //TARGETS
        if (this.isBounded()) {
          const dataArray = entry.isBaseLineYear
            ? [this._translateService.instant("kpi.baselineYear"), this._translateService.instant("kpi.baselineYear")] //baseline
            : this.dataEntryDetails().textmapId
              ? ["", ""] //textmapid
              : [entry.lowerLimit, entry.highLimit]; //default;

          innerData.push(...dataArray);
        } else {
          const dataArray = entry.isBaseLineYear
            ? [this._translateService.instant("kpi.baselineYear")] //baseline
            : this.dataEntryDetails().textmapId
              ? [""] //textmapid
              : [entry.target]; //default;

          innerData.push(...dataArray);
        }

        //Calc ACTUAL
        const isNA = dataEntry.some((e) => e.ignored);
        innerData.push(
          isNA
            ? "N/A"
            : this._dataEntryService.calcFormula(
                dataEntry,
                this.dataEntryDetails().metricDetails,
                this.dataEntryDetails().formula,
                true
              )
        );

        //AUDITED VALUES
        const auditEntityByPeriod = this.dataEntryDetails().auditEntries!.find((e) => e.year === entry.year);

        if (auditEntityByPeriod) {
          //Annual Actual Column
          innerData.push(auditEntityByPeriod.annualActualIgnored ? "N/A" : auditEntityByPeriod.annualActual);

          //Audited Annual Actual Column
          innerData.push(
            AdaaHelper.isDefined(auditEntityByPeriod.wfProcessCtlId)
              ? auditEntityByPeriod.proposedAuditIgnored
                ? "N/A"
                : auditEntityByPeriod.proposedAuditActual
              : auditEntityByPeriod.auditedActualIgnored
                ? "N/A"
                : auditEntityByPeriod.auditedActual
          );

          //Comments Column
          innerData.push(
            AdaaHelper.isDefined(auditEntityByPeriod.wfProcessCtlId)
              ? auditEntityByPeriod.proposedComment
              : auditEntityByPeriod.comment
          );
        } else {
          innerData.push("", "", "");
        }
      });
      data.push(innerData);
    });

    return data;
  }

  private _handleCellValueChanged = (changes: CellChange[] | null) => {
    if (!changes || !this.hotInstance || this.hotInstance.isDestroyed) return;

    changes.forEach((change) => {
      let [row, col, prev, next] = change;
      row = +row;
      col = +col;

      if (!AdaaHelper.isDefinedAndNotEmpty(prev)) prev = undefined;
      if (!AdaaHelper.isDefinedAndNotEmpty(next)) next = undefined;

      //Avoid saving changes on empty cells
      if (!AdaaHelper.isDefined(prev) && !AdaaHelper.isDefined(next)) return;

      //Avoid saving if metric does not exists
      if (!AdaaHelper.isDefined(this.dataEntryDetails()?.dataEntries![row])) return;

      if ((!this.isBounded() && col === 3) || (this.isBounded() && col === 4))
        this._handleAuditAnnualActual(row, col, next);
      if ((!this.isBounded() && col === 4) || (this.isBounded() && col === 5)) this._handleComment(row, next);
    });

    this.hotInstance?.render();
  };

  private _handleAuditAnnualActual(row: number, col: number, auditedActual: string | undefined) {
    const dataEntry = Array.from(this.dataEntriesGroupedByPeriod().values())[row][0];
    if (!dataEntry) return;

    const audit = this.dataEntryDetails().auditEntries?.find((e) => e.year === dataEntry.year);
    if (!audit) return;

    const isNA = typeof auditedActual === "string" && auditedActual.toUpperCase() === "N/A";

    let invalidCell = false;
    if (isNA || !auditedActual) {
      this.hotInstance.removeCellMeta(row, col, "valid");
      invalidCell = false;
    } else if (isNaN(+auditedActual)) {
      this.hotInstance.setCellMeta(row, col, "valid", false);
      invalidCell = true;
    } else {
      this.hotInstance.removeCellMeta(row, col, "valid");
      invalidCell = false;
    }
    this.hotInstance.render();

    this._handleAuditArrayChange(audit, auditedActual, isNA, null, invalidCell);
  }

  private _handleComment(row: number, comment: string | undefined) {
    const dataEntry = Array.from(this.dataEntriesGroupedByPeriod().values())[row][0];
    if (!dataEntry) return;

    const audit = this.dataEntryDetails().auditEntries?.find((e) => e.year === dataEntry.year);
    if (!audit) return;

    this._handleAuditArrayChange(audit, null, null, comment, null);
  }

  //If auditedActual/ignored/comment is null ignore updating the value
  private _handleAuditArrayChange(
    audit: DataEntryAuditEntry,
    auditedActual: string | undefined | null,
    ignored: boolean | null,
    comment: string | undefined | null,
    invalidCell: boolean | null
  ) {
    const value = this.auditValuesList.find((e) => e.year === audit.year);

    if (value) {
      if (auditedActual !== null) value.auditedActual = auditedActual == null || ignored ? undefined : +auditedActual;
      if (ignored !== null) value.ignored = ignored;
      if (comment !== null) value.comment = comment;
      if (invalidCell !== null) value.invalidCell = invalidCell;

      //If the changes are identical the remove it from the array
      if (
        value.ignored == audit.annualActualIgnored &&
        value.auditedActual == audit.auditedActual &&
        value.comment == audit.comment
      ) {
        this.auditValuesList = this.auditValuesList.filter((e) => e.year !== value.year);
      }
    } else {
      this.auditValuesList.push({
        id: audit.auditId ?? null,
        year: audit.year ?? 0,
        itemId: this.kpiId(),
        itemType: this.kpiType(),
        entityId: audit.entityId ?? 0,
        updateTS: Date.now(),
        auditedActual: this._getAuditedActualValue(audit, ignored, auditedActual),
        comment: comment === null ? audit.comment : comment ? comment : undefined,
        ignored: ignored ?? false,
        changed: true,
        invalidCell: invalidCell === null ? false : invalidCell,
      });
    }

    this.valueChanged.emit(this.auditValuesList);
  }

  private _getAuditedActualValue(
    audit: DataEntryAuditEntry,
    ignored: boolean | null,
    auditedActual: string | undefined | null
  ) {
    if (ignored) return undefined;

    return auditedActual === null ? audit.auditedActual : auditedActual ? +auditedActual : undefined;
  }
}
