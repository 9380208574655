<h6 class="fw-bold">{{ "national_projects.pmo_decisions" | translate }}</h6>

<div class="row">
    @for (desision of pmoDesisions(); track desision; let i = $index) {
        <div class="col-sm-2 col-xs-6">
            <adaa-form-checkbox
                checkboxMode="trueFalse"
                [label]="desision.text"
                [controlName]="desision.text"
                [setDefaultValue]="isDesisionChecked(desision)"
                (inputChanges)="checkboxChanged($event, desision)"
            />
        </div>
    }
</div>
<!-- is other checkbox checked -->
@if (isDesisionChecked(otherOption())) {
    <div class="row">
        <div class="col-12">
            <adaa-form-input
                controlName="othersText"
                [required]="true"
                [label]="'common.others' | translate"
                [setDefaultValue]="otherOptionComment()"
                (inputChanges)="otherOptionChanged($event)"
            />
        </div>
    </div>
}
