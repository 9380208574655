import { NgClass } from "@angular/common";
import { Component, computed, effect, inject, input, signal } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { FavoritesApiService } from "../../../services";

@Component({
  selector: "adaa-favorite-button",
  styleUrl: "../common.scss",
  standalone: true,
  imports: [NgClass, FontAwesomeModule, TranslateModule, NgbTooltip],
  template: `
    <button
      class="btn border-0 btn-fav rounded-circle m-0 p-0"
      placement="top"
      [ngbTooltip]="'common.tables.favorites' | translate"
      [class.active]="isFavorite()"
      (click)="toggleFavorite()"
    >
      <fa-icon size="lg" [icon]="icon" [ngClass]="isFavorite() ? ['fa-solid'] : ['fa-regular']" />
    </button>
  `,
})
export class FavoriteButtonComponent {
  private readonly _favoritesApiService = inject(FavoritesApiService);

  itemId = input.required<number>();
  objectType = input.required<number>();

  status = signal<
    | {
        userId: number;
        itemId: number;
        itemTypeId: number;
        id: number;
      }
    | undefined
  >(undefined);

  isFavorite = computed(() => {
    if (!this.status()) return false;
    return AdaaHelper.isDefinedAndNotEmpty(this.status());
  });

  readonly icon = faStar;

  #getFavoriteStatus = effect(() => {
    this._getFavoriteStatus();
  });

  public toggleFavorite() {
    if (this.isFavorite()) {
      this._favoritesApiService.remove(this.status()!.id).subscribe({
        next: () => this.status.set(undefined),
      });
    } else {
      this._favoritesApiService
        .create({
          objectType: this.objectType(),
          itemId: this.itemId(),
        })
        .subscribe({
          next: () => {
            this._getFavoriteStatus();
          },
        });
    }
  }

  private _getFavoriteStatus() {
    this._favoritesApiService
      .getUserFavoritePerItem({
        itemId: this.itemId(),
        objectType: this.objectType(),
      })
      .subscribe({
        next: (res) => {
          this.status.set(res.responseData ?? undefined);
        },
      });
  }
}
