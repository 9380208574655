import { Pipe, PipeTransform } from "@angular/core";

import { AdaaHelper } from "../utils";

@Pipe({
  name: "percentageValue",
  pure: false,
  standalone: true,
})
export class PercentageValuePipe implements PipeTransform {
  public transform(
    value: string | number | undefined | null,
    toFixedValue: number = 2,
    nullSymbol: string = "-",
    percentageSymbol: string = "%",
    isPercentage: boolean = true,
    alwaysShowComma: boolean = false
  ): string {
    return AdaaHelper.percentageValue(
      value,
      toFixedValue,
      nullSymbol,
      isPercentage ? percentageSymbol : "",
      alwaysShowComma
    );
  }
}
