<div class="m-1" [class.invalid]="invalid">
    <label *ngIf="adaaHelper.isDefinedAndNotEmpty(label)" for="{{ controlId }}" class="form-label">
        {{ label }}
        <span *ngIf="required" class="form-required text-danger fw-bolder">*</span></label
    >

    <div class="input-group">
        <input
            #dateInput
            [id]="controlId"
            class="form-control shadow-sm"
            [placeholder]="placeholder"
            [startDate]="fromDate!"
            [autoClose]="'outside'"
            [displayMonths]="2"
            [dayTemplate]="dateTemplate"
            [footerTemplate]="footerTemplate"
            [formControl]="inputControl"
            [value]="formatedDate"
            [class.is-invalid]="invalid"
            [disabled]="isDisabled"
            ngbDatepicker
            [maxDate]="$any(maxDate)"
            [minDate]="$any(minDate)"
            #picker="ngbDatepicker"
            (dateSelect)="valueChanged($event)"
            readonly="true"
        />
        <button class="btn btn-outline-secondary" (click)="picker.toggle()" type="button" [disabled]="isDisabled">
            <fa-icon [icon]="['fas', 'calendar']"></fa-icon>
        </button>
        <ng-template #dateTemplate let-date let-focused="focused">
            <span
                class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
            >
                {{ date.day }}
            </span>
        </ng-template>
        <ng-template #footerTemplate>
            <hr class="my-0" />
            <button class="btn btn-secondary btn-sm m-2 float-end" (click)="resetInput()">{{ "common.form.button.reset" | translate }}</button>
        </ng-template>
    </div>
</div>
