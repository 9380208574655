<div
    class="row d-flex align-items-center justify-content-center fw-bold text-white m-1"
    [style.font-size]="fontSize()"
    [style.background-color]="constants.COLOR_CODES[color()]"
>
    <div class="col-3 d-flex align-items-center justify-content-center text-center">
        {{ value() }}
    </div>
    <div class="divider"></div>
    <div class="col-9 d-flex align-items-center">
        {{ label() | translate }}
        @if (subLabel()) {
            <br />
            {{ subLabel()! | translate }}
        }
    </div>
</div>
