<div class="position-relative d-flex justify-content-start align-items-center bg-primary topbar" [dir]="direction()">
    <div class="right-side">
        <!--
            TODO:
                - on mobile reduce the width of selectors
         -->
        <!-- open-sidebar -->

        <button
            type="button"
            class="btn bg-white text-primary shawdow-sm rounded p-0 text-center right-side-opts"
            id="toggleSidebarBtn"
            [dir]="direction()"
            (click)="toggleSidebar.emit()"
        >
            <span><fa-icon [icon]="icon.menu" /></span>
        </button>

        <!-- period-selector -->
        <adaa-period-selector
            [activeEntity]="systemLayoutService.activeEntity()!"
            [activeCycle]="systemLayoutService.activeCycle()!"
            [cycles]="systemLayoutService.cycles()"
            (periodSelected)="systemLayoutService.selectCycle($event)"
        />

        <!-- entity-selector -->
        <adaa-entity-selector
            [entities]="systemLayoutService.entities()"
            [activeEntity]="systemLayoutService.activeEntity()!"
            (entitySelected)="systemLayoutService.selectEntity($event)"
        />

        <!-- open-options -->
        <div ngbDropdown class="d-inline-flex justify-content-end userOptions">
            <button
                type="button"
                class="btn bg-secondary text-white shawdow rounded-circle text-center right-side-opts d-flex justify-content-center align-items-center"
                id="userOptionsBtn"
                [dir]="direction()"
                ngbDropdownToggle
            >
                <span><fa-icon [icon]="icon.options" /></span>
            </button>

            <div aria-labelledby="userMenu" role="menu" [dir]="direction()" ngbDropdownMenu>
                <div
                    class="d-flex w-100 justify-content-center align-items-center align-content-center flex-nowrap mini-activity-bar"
                    [dir]="direction()"
                >
                    <adaa-activity-bar type="mini" [direction]="direction()" [hasNotifications]="hasNotifications()" [count]="count()" />
                </div>

                <div class="d-flex w-100 justify-content-center" [dir]="direction()">
                    <div class="vr" style="height: 0.625rem" [dir]="direction()"></div>
                </div>

                <button
                    class="d-flex"
                    [dir]="direction()"
                    [routerLink]="['/', 'user-profile']"
                    routerLinkActive="active"
                    role="menuitem"
                    ngbDropdownItem
                >
                    <span class="mx-2"><fa-icon [icon]="icon.user" /></span>
                    <span class="ms-3">{{ "userBar.profile" | translate }}</span>
                </button>

                <button class="d-flex" [dir]="direction()" role="menuitem" ngbDropdownItem (click)="switchLanguage()">
                    <span class="mx-2"> <fa-icon [icon]="icon.switchLang" /> </span>
                    <span class="ms-3">{{ "language.change_lang" | translate }}</span>
                </button>

                <button class="d-flex" [dir]="direction()" role="menuitem" ngbDropdownItem (click)="logoutService.logout()">
                    <span class="mx-2" [dir]="direction()"> <fa-icon [icon]="icon.logout" /> </span>
                    <span class="ms-3" [dir]="direction()">{{ "userBar.logout" | translate }}</span>
                </button>
            </div>
        </div>
    </div>

    <!-- entity-selector--bar -->

    <!-- activity-bar -->
    <!--
        TODO
            - make search input to a button for tablet view
            - add the notification bell and help buttons
            - add the user profile dropdown (text must be ellipsis if it's too long)
     -->
    <div class="d-flex justify-content-end left-side">
        <!-- SearchBar -->
        <div class="input-group input-group-sm w-auto topbar--search mx-3 d-flex hide-search-input" (click)="searchInputEl?.focus()">
            <input
                #searchInputEl
                type="search"
                class="form-control"
                autocomplete="on"
                inputmode="search"
                aria-label="'breadcrumbs.search' | translate"
                [dir]="direction()"
                [value]="systemLayoutService.searchTerm()"
                (change)="autoUpdateSearchTerm($event)"
                (keydown.enter)="performSearch($event)"
            />

            <div class="input-group-text" (click)="searchButtonClicked(searchInputEl)">
                <fa-icon [icon]="icon.search" [title]="'breadcrumbs.search' | translate"></fa-icon>
            </div>
        </div>

        <!-- UserBar -->
        <div class="topbar--user-menu d-flex justify-content-between px-2">
            <button
                role="link"
                id="notificationCenter"
                class="btn btn-sm bg-white text-primary rounded-circle shadow-sm position-relative activity-bar-opts mx-3"
                [class.text-primary]="!hasNotifications()"
                [class.text-info]="hasNotifications()"
                [routerLink]="['communications']"
            >
                <fa-icon [icon]="icon.notification" [title]="'tooltips.notification_center' | translate"></fa-icon>

                <!-- badge -->
                @if (hasNotifications()) {
                    <span data-sms-count class="position-absolute translate-middle start-0 px-1 text-white bg-info border border-light rounded-pill">
                        {{ !count() ? "" : count() }}
                        <span class="visually-hidden">
                            {{ "communication.title" | translate }}
                        </span>
                    </span>
                }
            </button>

            <!-- ActivityBar -->
            <adaa-activity-bar type="full" [direction]="direction()" [hasNotifications]="hasNotifications()" [count]="count()" />

            <div ngbDropdown class="d-inline-flex justify-content-end">
                <button
                    type="button"
                    class="btn btn-success d-inline-flex align-content-center justify-content-between align-items-center flex-nowrap"
                    id="userMenu"
                    [class.ms-3]="direction() === 'ltr'"
                    [class.me-3]="direction() === 'rtl'"
                    ngbDropdownToggle
                >
                    <span class="w-100 full">
                        {{ currentUser()! | translateToken: "name" }}
                    </span>
                    <span class="mini"><fa-icon [icon]="icon.user" /></span>
                </button>

                <div aria-labelledby="userMenu" role="menu" [dir]="direction()" ngbDropdownMenu>
                    <div
                        class="d-flex w-100 justify-content-center align-items-center align-content-center flex-nowrap mini-activity-bar"
                        [dir]="direction()"
                    >
                        <adaa-activity-bar type="mini" [direction]="direction()" [hasNotifications]="hasNotifications()" [count]="count()" />
                    </div>

                    <div class="d-flex w-100 justify-content-center" [dir]="direction()">
                        <div class="vr" style="height: 0.625rem" [dir]="direction()"></div>
                    </div>

                    <button [dir]="direction()" [routerLink]="['/', 'user-profile']" routerLinkActive="active" role="menuitem" ngbDropdownItem>
                        <span><fa-icon [icon]="icon.user" /></span>
                        <span>{{ "userBar.profile" | translate }}</span>
                    </button>

                    <button [dir]="direction()" role="menuitem" ngbDropdownItem (click)="switchLanguage()">
                        <span> <fa-icon [icon]="icon.switchLang" /> </span>
                        <span>{{ "language.change_lang" | translate }}</span>
                    </button>

                    <button [dir]="direction()" role="menuitem" ngbDropdownItem (click)="logoutService.logout()">
                        <span [dir]="direction()"> <fa-icon [icon]="icon.logout" /> </span>
                        <span [dir]="direction()">{{ "userBar.logout" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
