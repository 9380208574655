import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { NonSystemTeamMemberType } from "../../../constants/enums";
import { RadioOption, TeamMemberType, UserModelType, ValidatorModelType, ValueText } from "../../../models";
import { UsersApiService } from "../../../services";
import { FormDropdownComponent, FormInputComponent, FormRadioComponent } from "../../form";

@Component({
  selector: "adaa-team-members-modal",
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, FormInputComponent, FormDropdownComponent, FormRadioComponent],
  templateUrl: "./team-members-modal.component.html",
  styleUrl: "./team-members-modal.component.scss",
})
export class TeamMembersModalComponent implements OnInit {
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _formBuilder = inject(FormBuilder);
  private _usersApiService = inject(UsersApiService);
  modal = inject(NgbActiveModal);

  @Input() teamMember: TeamMemberType;
  @Input() isSystemUser: boolean;
  @Input() formValidation: ValidatorModelType;
  @Input() entities: ValueText[];
  @Input() otherEntities: ValueText[];
  @Input() enableProjectMembers: boolean = false;
  @Input() projectMembers: TeamMemberType[];

  private _users = signal<UserModelType[]>([]);
  nonSystemTeamMemberType = NonSystemTeamMemberType;
  owners = signal<ValueText[]>([]);
  projectTeamMembers = signal<ValueText[]>([]);
  entitySourceOptions = signal<RadioOption[]>([]);
  teamMemberForm: FormGroup;
  submitted: boolean = false;
  adaaHelper = AdaaHelper;

  public ngOnInit(): void {
    if (this.isSystemUser) this._getEntitiesOwners();

    this.teamMemberForm = this._prepareForm();
    this.teamMemberForm.patchValue(this.teamMember);

    this._setEntitySourceOptions();

    if (this.enableProjectMembers && this.projectMembers) {
      //MUST BE NONE SYSTEM USER
      this.projectMembers = this.projectMembers.filter((e) => !e.userId);

      this.projectTeamMembers.set(
        AdaaHelper.setDropdownArray(this.projectMembers, "id", AdaaHelper.getFieldLanguage("name"))
      );
    }
  }

  public entityChanged(value: number): void {
    if (this.isSystemUser) {
      const users = this._users().filter((e) => e.entityId === value);
      this.owners.set(AdaaHelper.setDropdownArray(users, "id", AdaaHelper.getFieldLanguage("name")));

      const user = +this.teamMemberForm.get("userId")?.value;
      const index = users.findIndex((e) => e.id === user);
      if (index === -1) this.teamMemberForm.get("userId")?.setValue(null);
    }

    const entity = this.entities.find((e) => e.value === value);
    if (!entity) return;
    this.teamMemberForm.get("entityNameAE")?.setValue(entity.text);
    this.teamMemberForm.get("entityNameEN")?.setValue(entity.text);
  }

  public otherEntityChanged(value: number): void {
    const entity = this.otherEntities.find((e) => e.value === value);
    if (!entity) return;
    this.teamMemberForm.get("otherEntityNameAE")?.setValue(entity.text);
    this.teamMemberForm.get("otherEntityNameEN")?.setValue(entity.text);
  }

  public userChanged(value: number): void {
    const user = this._users().find((e) => e.id === value);
    if (!user) return;
    this.teamMemberForm.get("entityId")?.setValue(user.entityId);
    this.teamMemberForm.get("nameAE")?.setValue(user.nameAE);
    this.teamMemberForm.get("nameEN")?.setValue(user.nameEN);
    this.teamMemberForm.get("jobTitleAE")?.setValue(user.jobTitleAE);
    this.teamMemberForm.get("jobTitleEN")?.setValue(user.jobTitleEN);
    this.teamMemberForm.get("email")?.setValue(user.email);
    this.teamMemberForm.get("mobilePhone")?.setValue(user.mobilePhone);
  }

  public entitySourceChanged(value: number): void {
    this.teamMemberForm.get("entitySource")?.setValue(value);
    this._resetForm();
  }

  public tpTeamMemberChanged(value: number): void {
    const teamMember = this.projectMembers.find((e) => e.id === value);
    if (!teamMember) return;
    this.teamMemberForm.get("entityId")?.setValue(teamMember.entityId);
    this.teamMemberForm.get("entityNameAE")?.setValue(teamMember.entityNameAE);
    this.teamMemberForm.get("entityNameEN")?.setValue(teamMember.entityNameEN);
    this.teamMemberForm.get("otherEntityId")?.setValue(teamMember.otherEntityId);
    this.teamMemberForm.get("otherEntityNameAE")?.setValue(teamMember.otherEntityNameAE);
    this.teamMemberForm.get("otherEntityNameEN")?.setValue(teamMember.otherEntityNameEN);
    this.teamMemberForm.get("nameAE")?.setValue(teamMember.nameAE);
    this.teamMemberForm.get("nameEN")?.setValue(teamMember.nameEN);
    this.teamMemberForm.get("jobTitleAE")?.setValue(teamMember.jobTitleAE);
    this.teamMemberForm.get("jobTitleEN")?.setValue(teamMember.jobTitleEN);
    this.teamMemberForm.get("email")?.setValue(teamMember.email);
    this.teamMemberForm.get("mobilePhone")?.setValue(teamMember.mobilePhone);
    this.teamMemberForm.get("memberRoleAE")?.setValue(teamMember.memberRoleAE);
    this.teamMemberForm.get("memberRoleEN")?.setValue(teamMember.memberRoleEN);
  }

  public save(): void {
    this.submitted = true;

    if (!this.teamMemberForm.valid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    this.modal.close(this.teamMemberForm.getRawValue());
  }

  private _getEntitiesOwners(): void {
    if (!this.entities) return;

    const ids = this.entities.map((e) => +e.value);

    this._usersApiService.getAll(ids).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._users.set(response.responseData);
        this.owners.set(AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name")));
      },
    });
  }

  private _setEntitySourceOptions(): void {
    const entitySource: RadioOption[] = [
      {
        value: NonSystemTeamMemberType.ADAA_ENTITY,
        text: this._translateService.instant("national_projects.adaa_entity"),
      },
      {
        value: NonSystemTeamMemberType.OTHER_ENTITY,
        text: this._translateService.instant("other_entities.singular"),
      },
    ];

    if (this.enableProjectMembers)
      entitySource.push({
        value: NonSystemTeamMemberType.PARENT_PROJECT,
        text: this._translateService.instant("national_projects.national_projects_milestone.project_team_members"),
      });

    this.entitySourceOptions.set(entitySource);
  }

  private _prepareForm() {
    return this._formBuilder.group({
      id: { value: null, disabled: false },
      fakeId: { value: null, disabled: false },
      entityId: { value: null, disabled: false },
      entityNameAE: { value: null, disabled: true },
      entityNameEN: { value: null, disabled: true },
      otherEntityId: { value: null, disabled: false },
      otherEntityNameAE: { value: null, disabled: true },
      otherEntityNameEN: { value: null, disabled: true },
      userId: { value: null, disabled: false },
      nameAE: [{ value: null, disabled: this.isSystemUser }, Validators.required],
      nameEN: [{ value: null, disabled: this.isSystemUser }, Validators.required],
      jobTitleAE: [{ value: null, disabled: this.isSystemUser }, Validators.required],
      jobTitleEN: [{ value: null, disabled: this.isSystemUser }, Validators.required],
      email: [{ value: null, disabled: this.isSystemUser }, Validators.required],
      mobilePhone: [{ value: null, disabled: this.isSystemUser }, Validators.required],
      memberRoleAE: [{ value: null, disabled: false }, Validators.required],
      memberRoleEN: [{ value: null, disabled: false }, Validators.required],
      createdBy: { value: null, disabled: false },
      updatedBy: { value: null, disabled: false },
      createdByUserNameAE: { value: null, disabled: false },
      createdByUserNameEN: { value: null, disabled: false },
      creationDate: { value: null, disabled: false },
      status: { value: null, disabled: false },

      //ONLY FE
      entitySource: { value: NonSystemTeamMemberType.ADAA_ENTITY, disabled: false },
      tpTeamMember: { value: null, disabled: false },
    });
  }

  private _resetForm(): void {
    const formKeys = [
      "entityId",
      "entityNameAE",
      "entityNameEN",
      "otherEntityId",
      "otherEntityNameAE",
      "otherEntityNameEN",
      "userId",
      "nameAE",
      "nameEN",
      "jobTitleAE",
      "jobTitleEN",
      "email",
      "mobilePhone",
      "memberRoleAE",
      "memberRoleEN",
      "tpTeamMember",
    ];

    formKeys.forEach((e) => this.teamMemberForm.get(e)?.setValue(null));

    const disableFormKeys = [
      "entityId",
      "entityNameAE",
      "entityNameEN",
      "otherEntityId",
      "otherEntityNameAE",
      "otherEntityNameEN",
      "userId",
      "nameAE",
      "nameEN",
      "jobTitleAE",
      "jobTitleEN",
      "email",
      "mobilePhone",
    ];
    if (this.teamMemberForm.get("entitySource")?.value === NonSystemTeamMemberType.PARENT_PROJECT)
      disableFormKeys.forEach((e) => this.teamMemberForm.get(e)?.disable());
    else disableFormKeys.forEach((e) => this.teamMemberForm.get(e)?.enable());
  }
}
