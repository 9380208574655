<div ngbAccordion [closeOthers]="true">
    @for (set of dimensionsSet(); track set) {
        <div ngbAccordionItem [collapsed]="set.id ? set.id !== activeTab : set.fakeId !== activeTab" (show)="activeTab = set.id ?? set.fakeId">
            <h2 ngbAccordionHeader>
                <button ngbAccordionButton class="bg-white shadow-md p-2">{{ AdaaHelper.getItemValueByToken(set, "name") }}</button>
            </h2>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody class="bg-white shadow-md rounded p-2">
                    @if (pageMode() !== PageMode.view) {
                        <div class="w-100 d-flex justify-content-end position-relative top">
                            <button
                                class="btn btn-sm btn-danger rounded-circle border border-1 text-white position-absolute"
                                (click)="removeSet(set)"
                            >
                                <i class="fa fa-trash-alt"></i>
                            </button>
                            <button
                                class="btn btn-sm btn-primary rounded-circle border border-1 text-white position-absolute"
                                (click)="orderSet(set)"
                            >
                                <i class="adaa-icon-sort"></i>
                            </button>
                        </div>
                    }
                    <div class="row">
                        <div class="col-12">
                            <adaa-form-dropdown
                                controlName="dimensionType"
                                appendTo=".modal-content"
                                classes="text-primary"
                                [setDefaultValue]="set.parentMetricId"
                                [searchable]="true"
                                [clearable]="true"
                                [isDisabled]="pageMode() === PageMode.view"
                                [label]="'metrics.parent_metric' | translate"
                                [options]="parentMetrics()"
                                (inputChanges)="parentMetricsChanged($event, set)"
                            />
                        </div>
                    </div>
                    @if (set.parentMetricId) {
                        @for (item of set.values; track item) {
                            <div class="row">
                                <div class="col-12 d-flex justify-content-between align-items-center">
                                    <adaa-form-dropdown-multi
                                        class="w-100"
                                        appendTo=".modal-content"
                                        [setDefaultValue]="item.dimensionValueIds"
                                        [label]="AdaaHelper.getItemValueByToken(item, 'name')"
                                        [searchable]="true"
                                        [clearable]="true"
                                        [isDisabled]="pageMode() === PageMode.view"
                                        [options]="item.options"
                                        [style]="
                                            AdaaHelper.getCurrentLang() === Language.Arabic
                                                ? 'margin-right:' + item.level * 8 + 'px'
                                                : 'margin-left:' + item.level * 8 + 'px'
                                        "
                                        (inputChanges)="dimensionValueChanged($event, item)"
                                    />
                                    <button
                                        class="btn btn-sm btn-warning rounded-circle border border-1 text-white mt-4"
                                        (click)="removeDimensionType(set, item.dimensionId)"
                                    >
                                        <i class="fa fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                        }
                        @if (set.dimensionTypeOptions && set.dimensionTypeOptions.length > 0) {
                            <div class="row">
                                <div class="col-12">
                                    <adaa-form-dropdown
                                        appendTo=".modal-content"
                                        classes="text-info"
                                        [searchable]="true"
                                        [isDisabled]="pageMode() === PageMode.view"
                                        [label]="'metrics.dimension_type' | translate"
                                        [options]="set.dimensionTypeOptions"
                                        (inputChanges)="dimensionTypeChanged($event, set)"
                                    />
                                </div>
                            </div>
                        }
                    }
                </div>
            </div>
        </div>
    }
</div>

@if (pageMode() !== PageMode.view) {
    <div class="bg-white shadow-md rounded p-2 mt-2">
        <div class="row">
            <div class="col-12 d-flex justify-content-center align-items-center">
                <button type="button" class="btn btn-primary" (click)="addSet()">{{ "dimension.add_dimensions_set" | translate }}</button>
            </div>
        </div>
    </div>
}
