import { Component, computed, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../../../../core/pipes";
import { AdaaHelper } from "../../../../../../../core/utils";
import { ExtendedFieldAttributeModelType } from "../../../../../../models";
import * as AttributeHelpers from "../../../../../general/extension-fields/utils";

@Component({
  selector: "adaa-extended-fields-diff",
  standalone: true,
  imports: [TranslateTokenPipe, TranslateModule],
  templateUrl: "./extended-fields-diff.component.html",
  styleUrl: "../styles.scss",
})
export class ExtendedFieldsDiffComponent {
  showOnlyNew = input<boolean>(false);
  newData = input<ExtendedFieldAttributeModelType[]>([]);
  oldData = input<ExtendedFieldAttributeModelType[]>([]);
  showDIffOnly = input<boolean>(false);

  data = signal<{ new?: ExtendedFieldAttributeModelType; old?: ExtendedFieldAttributeModelType }[]>([]);

  hasAnyDiff = computed(() => {
    if (this.showOnlyNew()) return true;

    const data = this.data();
    return data.some(({ new: $new, old }) => this.hasDiffValue($new, old));
  });

  public ngOnInit() {
    this._processAttributes();
  }

  public hasDiffValue(newAttr?: ExtendedFieldAttributeModelType, oldAttr?: ExtendedFieldAttributeModelType) {
    if (!newAttr && oldAttr) return true;
    if (!oldAttr && newAttr) return true;

    const newValue = this.getValue(newAttr);
    const oldValue = this.getValue(oldAttr);

    return newValue !== oldValue;
  }

  public getValue(attr?: ExtendedFieldAttributeModelType) {
    if (!attr) return;

    let value: unknown;
    if (AttributeHelpers.isDatePickerInputType(attr.dataType)) {
      value = AdaaHelper.formatDate(attr.valueDate!, true);
    }

    if (AttributeHelpers.isTextAreaInputType(attr.dataType) || AttributeHelpers.isInputSimpleType(attr.dataType)) {
      value = attr.valueTextAE || attr.valueTextEN;
    }

    if (
      AttributeHelpers.isOrgUnitType(attr.dataType) ||
      AttributeHelpers.isTreeInputType(attr.dataType) ||
      AttributeHelpers.isDropDownInputType(attr.dataType)
    ) {
      value = attr.valueNumber;
    }

    return value;
  }

  private _processAttributes() {
    for (const item of this.newData()) {
      const oldItem = this.oldData().find((data) => data.attrId === item.attrId);
      if (!oldItem) {
        //* is added
        this.data.update((items) => [...items, { new: { ...item } }]);
      } else {
        this.data.update((items) => [...items, { new: { ...item }, old: { ...oldItem } }]);
      }
    }

    for (const item of this.oldData()) {
      const newItem = this.newData().find((data) => data.attrId === item.attrId);
      if (!newItem) {
        //* is removed
        this.data.update((items) => [...items, { old: { ...item } }]);
      }
    }
  }
}
