import { NgClass } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { FilterType } from "../../../constants/enums";
import { ParameterCatalog, SearchFields } from "../../../models";
import { DataTableService } from "../../../services";
import {
  FormDropdownComponent,
  FormDropdownMultiComponent,
  FormDropdownTreeComponent,
  FormInputComponent,
  FormInputDateRangeComponent,
  FormInputRangeComponent,
} from "../../form";

@Component({
  selector: "adaa-data-table-filters",
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    ReactiveFormsModule,
    FormInputComponent,
    FormDropdownComponent,
    FormDropdownMultiComponent,
    FormInputDateRangeComponent,
    FormInputRangeComponent,
    FormDropdownTreeComponent,
  ],
  templateUrl: "./data-table-filters.component.html",
  styleUrl: "./data-table-filters.component.scss",
})
export class DataTableFiltersComponent implements OnInit {
  @Input() tableColumns: ParameterCatalog[] = [];
  @Input() filterValues: SearchFields;
  @Input() configKey: string;

  modal: NgbActiveModal = inject(NgbActiveModal);
  private _formBuilder: FormBuilder = inject(FormBuilder);
  private _dataTableService: DataTableService = inject(DataTableService);

  filterForm: FormGroup;
  adaaHelper = AdaaHelper;
  filterType = FilterType;
  readonly #untilDestroy = this.adaaHelper.untilDestroyed();

  public ngOnInit(): void {
    this._prepareForm();
  }

  public apply() {
    this.modal.close(this.filterForm.value);
  }

  public reset() {
    this.modal.close(true);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public dropdownChanged(event: any, item: ParameterCatalog): void {
    //If the dropdown don't have effect return
    if (this._dataTableService.filtersHaveEffect.findIndex((e) => e === item.fieldName) === -1) return;

    this._dataTableService
      .updateTableColumns(event, { item, configKey: this.configKey }, this.tableColumns)
      .pipe(this.#untilDestroy())
      .subscribe((response) => (this.tableColumns = response));
  }

  private _prepareForm() {
    this.filterForm = this._formBuilder.group({});
    if (this.tableColumns) {
      this.tableColumns = this.tableColumns.filter((e) => e.filterType !== this.filterType.ignored);
      this.tableColumns.forEach((param: ParameterCatalog) => {
        this.filterForm.addControl(param.fieldName, new FormControl(null, []));
      });
    }

    this._setFormValue();
  }

  private _setFormValue() {
    if (this.filterValues) {
      this.tableColumns.forEach((e) => {
        if (this.filterValues.searchFields[e.dbFieldName]) {
          this.filterForm.get(e.fieldName)?.setValue(this._getFilterValue(e.filterType, e.dbFieldName));
        }
      });
    }
  }

  private _getFilterValue(filterType: FilterType, dbFieldName: string): string | object[] | undefined {
    if (filterType === FilterType.dropdownMulti) {
      return this.filterValues.searchFields[dbFieldName]?.lov as object[];
    } else if (filterType === FilterType.dateRange || filterType === FilterType.valueRange) {
      let date = "";
      if (this.filterValues.searchFields[dbFieldName]?.range) {
        if (this.filterValues.searchFields[dbFieldName]?.range?.startNumeric != null)
          date += `${this.filterValues.searchFields[dbFieldName]?.range?.startNumeric} - `;

        if (this.filterValues.searchFields[dbFieldName]?.range?.endNumeric != null)
          date += `${this.filterValues.searchFields[dbFieldName]?.range?.endNumeric}`;
      }
      return date;
    } else {
      return this.filterValues.searchFields[dbFieldName]?.value as never;
    }
  }
}
