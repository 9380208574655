import { CommonModule } from "@angular/common";
import { Component, EventEmitter, forwardRef, Input, input, OnInit, Output } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";

import { AdaaHelper } from "../../../../core/utils";
import { Language } from "../../../constants/enums";
import { PhoneNumberDirective } from "../../../directives";
import type { ParameterCatalog } from "../../../models";

@Component({
  selector: "adaa-form-input",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PhoneNumberDirective],
  templateUrl: "./form-input.component.html",
  styleUrl: "./form-input.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormInputComponent),
    },
  ],
})
export class FormInputComponent implements OnInit, ControlValueAccessor {
  inputControl: FormControl = new FormControl();
  adaaHelper = AdaaHelper;
  controlType: string = "text";

  private _onValidatorChange: () => void;
  private _onTouched: () => void;
  private _onChange: (value: string) => void;
  private _isDisabled = false;

  showAsRequired = input<boolean>(false);

  @Input() required: boolean = false;
  @Input() invalid: boolean = false;
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() inputType: "text" | "number" | "positiveNumber" | "tel" | "email" | "password" = "text";
  @Input() isTextArea: boolean = false;
  @Input() maxLength: number | null = null;
  @Input() controlName: string | null = null;
  @Input() controlId: string | null = null;
  @Input() rows: number = 5;
  @Input() classes: string = "form-control shadow-sm";
  @Input() textAlignment: "default" | "left" | "right" | "center" =
    this.adaaHelper.getCurrentLang() === Language.Arabic ? "right" : "left";

  @Input()
  public set isDisabled(value: boolean) {
    this._isDisabled = value;
    if (value) {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
  }

  @Input()
  public set setDefaultValue(value: unknown) {
    if (value) {
      this.writeValue(value as string);
    } else if (!value && this.inputControl) {
      this.inputControl.reset();
    }
  }

  @Input()
  public set setValidator(validatorList: { parameterCatalogs: ParameterCatalog[] }) {
    if (validatorList) {
      if (validatorList.parameterCatalogs) {
        const field = validatorList.parameterCatalogs.find((e) => e.fieldName === this.controlName);

        if (field) {
          this.required = field.mandatory === "Y";
          this._updateValidation();
        }
      }
    }
  }

  @Output() inputChanges = new EventEmitter();

  public get isRequired() {
    return this.required || this.inputControl.hasValidator(Validators.required);
  }

  public ngOnInit(): void {
    if (!this.adaaHelper.isDefined(this.controlId)) this.controlId = this.controlName;
    this.controlType = this.inputType === "positiveNumber" ? "number" : this.inputType;
    this._updateValidation();
  }

  public writeValue(obj: string | number): void {
    if (obj === null) {
      this.inputControl.reset();
    }

    if (this.inputType === "positiveNumber" && typeof obj === "number") {
      obj = isNaN(Number(obj)) ? 0 : Math.abs(+obj);
    }

    this.inputControl.setValue(obj);
  }

  public registerOnChange(fn: (value: string) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this._onValidatorChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public validate(value: FormControl): false | { required: boolean } {
    const isRequired =
      (this.required && this.inputControl.value === null) || (this.required && this.inputControl.value === "");
    return (
      isRequired && {
        required: true,
      }
    );
  }

  private _updateValidation(): void {
    const validations = [];

    switch (this.inputType) {
      case "number":
      case "positiveNumber":
        validations.push(Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$"));
        break;
      case "email":
        validations.push(Validators.email);
        break;
      default:
        break;
    }

    this.inputControl.setValidators([...validations]);
    this.inputControl.updateValueAndValidity();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public valueChanged(event: any): void {
    if (this._onChange) this._onChange(event);

    this.inputChanges.emit(event);
  }
}
