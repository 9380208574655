import { NgClass } from "@angular/common";
import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { ActionsEvents, FileInputType, Language, PageMode } from "../../../constants/enums";
import { AttachFile, AuxGovDirection, FormTab, SubGovDirection, TableButtonClicked } from "../../../models";
import { AppService, GovernmentDirectionsEditorService, LanguageService } from "../../../services";
import {
  FormCheckboxComponent,
  FormDropdownComponent,
  FormInputComponent,
  FormInputFileComponent,
  FormStepsComponent,
} from "../../form";
import { ConfirmationModalComponent } from "../../general";
import { DataTableComponent } from "../../table/data-table/data-table.component";
import { AuxDirectionModalComponent } from "../aux-direction-modal/aux-direction-modal.component";

@Component({
  selector: "adaa-sub-direction-modal",
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    NgClass,
    FormStepsComponent,
    FormInputComponent,
    FormDropdownComponent,
    FormCheckboxComponent,
    FormInputFileComponent,
    DataTableComponent,
  ],
  templateUrl: "./sub-direction-modal.component.html",
  styleUrl: "./sub-direction-modal.component.scss",
})
export class SubDirectionModalComponent implements OnInit {
  private _modalService = inject(NgbModal);
  private _translateService = inject(TranslateService);
  private _languageService = inject(LanguageService);
  private _toastrService = inject(ToastrService);
  private _appService = inject(AppService);
  modal: NgbActiveModal = inject(NgbActiveModal);
  directionsService = inject(GovernmentDirectionsEditorService);

  @Input() subDirection: SubGovDirection;
  @Input() pageMode: PageMode = PageMode.view;

  submitted: boolean = false;
  PageMode = PageMode;
  subDirectionForm: FormGroup;
  allowedFiles = signal<FileInputType[]>([FileInputType.svg]);
  activeTab = signal<number>(0);
  tabs = signal<FormTab[]>([
    {
      title: this._translateService.instant("common.form.label.information"),
    },
    {
      title: this._translateService.instant("directions.aux_government"),
    },
  ]);

  public get subDirectionIcon(): AttachFile {
    return {
      id: this.subDirectionForm.get("subGovDirectionType")?.value,
      filename: this.subDirectionForm.get("subGovDirectionType")?.value ? "logo.svg" : "",
    };
  }

  public get getAuxDirections(): AuxGovDirection[] {
    return this.subDirection
      ? (this.subDirection.auxiliaryDirectionRequests?.filter((e) => e.status != Constants.OBJECT_STATUS.REMOVE) ?? [])
      : [];
  }

  public ngOnInit(): void {
    this.subDirectionForm = this.directionsService.prepareSubDirectionForm(this.pageMode);
    this.subDirectionForm.patchValue(this.subDirection);
  }

  public onFilechange(event: AttachFile | null) {
    this.subDirectionForm.get("subGovDirectionType")?.setValue(event ? event.id : null);
  }

  public save(): void {
    this.submitted = true;
    if (!this.subDirectionForm.valid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const owner = this.subDirectionForm.get("owner")?.value as number;
    const ownerNameAE = this.directionsService.subStrategyOwnerGroups().find((e) => e.value === owner)?.text ?? "";
    const ownerNameEN = this.directionsService.subStrategyOwnerGroups().find((e) => e.value === owner)?.text ?? "";

    this.subDirection.nameAE = this.subDirectionForm.get("nameAE")?.value;
    this.subDirection.nameEN = this.subDirectionForm.get("nameEN")?.value;
    this.subDirection.dscAE = this.subDirectionForm.get("dscAE")?.value;
    this.subDirection.dscEN = this.subDirectionForm.get("dscEN")?.value;
    this.subDirection.isMain = this.subDirectionForm.get("isMain")?.value;
    this.subDirection.subGovDirectionType = this.subDirectionForm.get("subGovDirectionType")?.value;
    this.subDirection.owner = owner;
    this.subDirection.ownerNameAE = ownerNameAE;
    this.subDirection.ownerNameEN = ownerNameEN;

    this.modal.close(this.subDirection);
  }

  public addAuxDirectionClicked(): void {
    const auxDirection: AuxGovDirection = {
      govSubDirectionId: undefined,
      refCode: undefined,
      nameAE: "",
      nameEN: "",
      dscAE: undefined,
      dscEN: undefined,
      id: 0,
      status: Constants.OBJECT_STATUS.ACTIVE,
    };

    this._openAuxDirectionModal(auxDirection, PageMode.create);
  }

  public manageAuxDirectionClicked({ event, data }: TableButtonClicked): void {
    if (!this.subDirection.auxiliaryDirectionRequests) this.subDirection.auxiliaryDirectionRequests = [];
    switch (event) {
      case ActionsEvents.edit:
      case ActionsEvents.view: {
        const auxDirection = this.subDirection.auxiliaryDirectionRequests.find((e) =>
          data.id ? e.id === data.id : data.fakeId ? e.fakeId === data.fakeId : false
        );
        if (auxDirection) this._openAuxDirectionModal(auxDirection, PageMode.edit);
        break;
      }
      case ActionsEvents.delete: {
        this._deleteAuxDirectionConfirmation(data);
        break;
      }
      default:
        break;
    }
  }

  private _openAuxDirectionModal(auxDirection: AuxGovDirection, pageMode: PageMode) {
    const modal = this._modalService.open(AuxDirectionModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: `modal-${this._languageService.direction()}`,
    });

    modal.componentInstance.auxDirection = auxDirection;
    modal.componentInstance.pageMode = pageMode;

    modal.result.then((event) => {
      if (!this.subDirection.auxiliaryDirectionRequests) this.subDirection.auxiliaryDirectionRequests = [];

      if (!AdaaHelper.isDefined(event)) return;

      if (this._checkForDuplicateName(event)) {
        this._toastrService.error(this._translateService.instant("directions.aux_name_exists"));
        return;
      }

      const auxDirectionIndex = this.subDirection.auxiliaryDirectionRequests.findIndex((e) =>
        event.id ? event.id === e.id : event.fakeId ? event.fakeId === e.fakeId : false
      );

      if (auxDirectionIndex > -1) {
        this.subDirection.auxiliaryDirectionRequests.splice(auxDirectionIndex, 1, event);
      } else {
        delete event.id;
        event.fakeId = Math.floor(Math.random() * 217 * 1000);
        this.subDirection.auxiliaryDirectionRequests.push(event);
      }

      this.subDirection.auxiliaryDirectionRequests = [...this.subDirection.auxiliaryDirectionRequests];
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _deleteAuxDirectionConfirmation(data: any): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "directions.aux_government";
    modal.componentInstance.title = "directions.auxDirectionRemove";

    modal.result.then((e) => {
      if (e) this._deleteAuxDirectionConfirmed(data);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _deleteAuxDirectionConfirmed(data: any): void {
    if (!this.subDirection.auxiliaryDirectionRequests) return;

    if (data.id) {
      const auxDirection = this.subDirection.auxiliaryDirectionRequests.find((e) => e.id === data.id);
      if (auxDirection) auxDirection.status = Constants.OBJECT_STATUS.REMOVE;
    } else if (data.fakeId) {
      const auxDirectionIndex = this.subDirection.auxiliaryDirectionRequests.findIndex((e) => e.fakeId === data.fakeId);

      if (auxDirectionIndex && auxDirectionIndex > -1)
        this.subDirection.auxiliaryDirectionRequests.splice(auxDirectionIndex, 1);
    }

    this.subDirection.auxiliaryDirectionRequests = [...this.subDirection.auxiliaryDirectionRequests];
  }

  private _checkForDuplicateName({ nameAE, nameEN, id, fakeId }: AuxGovDirection): boolean {
    if (id) {
      return (
        this.subDirection.auxiliaryDirectionRequests!.findIndex(
          (e) => (e.nameAE === nameAE || e.nameEN === nameEN) && e.id !== id
        ) > -1
      );
    } else if (fakeId) {
      return (
        this.subDirection.auxiliaryDirectionRequests!.findIndex(
          (e) => (e.nameAE === nameAE || e.nameEN === nameEN) && e.fakeId !== fakeId
        ) > -1
      );
    }
    return false;
  }
}
