<div class="row">
    <div class="col-12 d-flex align-items-center">
        @if (showActions()) {
            <adaa-form-checkbox
                controlName="showDimension"
                [setDefaultValue]="true"
                [boldLabel]="true"
                [label]="'data_entry.show_dimension' | translate"
                (inputChanges)="checkboxClicked.emit({ checkbox: 'options', checked: $event })"
            />

            <adaa-form-checkbox
                controlName="showTargets"
                [setDefaultValue]="true"
                [boldLabel]="true"
                [label]="'data_entry.show_targets' | translate"
                (inputChanges)="checkboxClicked.emit({ checkbox: 'targets', checked: $event })"
            />
        }

        @if (showCalculation()) {
            <adaa-form-checkbox
                controlName="showCalculation"
                [setDefaultValue]="true"
                [boldLabel]="true"
                [label]="'data_entry.show_calculation' | translate"
                (inputChanges)="checkboxClicked.emit({ checkbox: 'calculation', checked: $event })"
            />
        }

        <div class="m-1 align-items-end">
            <fa-icon class="approved" [icon]="['fas', 'square']" size="lg"></fa-icon>
            <label class="form-check-label px-2 fw-bold">{{ "data_entry.approved" | translate }}</label>
        </div>

        <div class="m-1 align-items-end">
            <fa-icon class="inReview" [icon]="['fas', 'square']" size="lg"></fa-icon>
            <label class="form-check-label px-2 fw-bold">{{ "status.in_review" | translate }}</label>
        </div>

        @if (hasAuditIssue()) {
            <div class="m-1 d-flex align-items-end">
                <fa-icon class="hasAuditIssue" [icon]="['fas', 'square']" size="lg"></fa-icon>
                <label class="form-check-label px-2 fw-bold">{{ "data_entry.has_audit_issues" | translate }}</label>
            </div>
        }
    </div>
</div>
