import { inject, Pipe, PipeTransform } from "@angular/core";

import { Language } from "../../shared/constants/enums";
import { LanguageService } from "../../shared/services";
import { AdaaHelper } from "../utils";

@Pipe({
  name: "translateToken",
  pure: false,
  standalone: true,
})
export class TranslateTokenPipe implements PipeTransform {
  private _languageService = inject(LanguageService);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public transform(value: any, token: string, nonNullable = false): string {
    const lang = this._languageService.current();

    if (!nonNullable) return AdaaHelper.getItemValueByToken(value, token, lang);

    const str = AdaaHelper.getItemValueByToken(value, token, lang);
    if (!AdaaHelper.isDefinedAndNotEmpty(str)) {
      return AdaaHelper.getItemValueByToken(value, token, lang === "en" ? Language.Arabic : Language.English);
    }
    return str;
  }
}
