import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import { AuditForm, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class AuditApiService {
  private _mainService = inject(MainService);

  public updateAudit(data: AuditForm) {
    return this._mainService
      .sendRequest<MainResponseModel>({
        method: "POST",
        url: `audit/updateAudit`,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public updateEkpiAudit(data: AuditForm) {
    return this._mainService
      .sendRequest<MainResponseModel>({
        method: "POST",
        url: `audit/updateEKPIAudit`,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }
}
