import { Directive, ElementRef, HostListener, inject } from "@angular/core";

@Directive({
  selector: "[phone-number]",
  standalone: true,
})
export class PhoneNumberDirective {
  private _elementRef = inject(ElementRef);

  @HostListener("keydown", ["$event"])
  public onKeyDown() {
    this._run(this._elementRef.nativeElement.value);
  }

  @HostListener("paste", ["$event"])
  public onPaste() {
    this._run(this._elementRef.nativeElement.value);
  }

  private _check(value: string) {
    return String(value).match(new RegExp(/^[+]?[0-9]*$/));
  }

  private _run(oldValue: string) {
    setTimeout(() => {
      const currentValue: string = this._elementRef.nativeElement.value;
      if (currentValue !== "" && !this._check(currentValue)) {
        this._elementRef.nativeElement.value = oldValue;
      }
    });
  }
}
