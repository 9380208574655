<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body" [formGroup]="attachmentForm">
    <div class="row">
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="nameAE"
                formControlName="nameAE"
                [invalid]="submitted && attachmentForm.get('nameAE')?.invalid!"
                [label]="'common.form.label.nameAE' | translate"
                [setValidator]="formValidation"
            />
        </div>
        <div class="col-sm-6 col-xs-12">
            <adaa-form-input
                controlName="nameEN"
                formControlName="nameEN"
                [invalid]="submitted && attachmentForm.get('nameEN')?.invalid!"
                [label]="'common.form.label.nameEN' | translate"
                [setValidator]="formValidation"
            />
        </div>
    </div>
    <div class="row">
        @if (enableType) {
            <div class="col-sm-6 col-xs-12">
                <adaa-form-dropdown
                    controlName="attachType"
                    formControlName="attachType"
                    appendTo=".modal-content"
                    [invalid]="submitted && attachmentForm.get('attachType')?.invalid!"
                    [label]="'nkpi.attachmentType' | translate"
                    [setValidator]="formValidation"
                    [options]="attachmentTypes"
                    (inputChanges)="attachmentTypeChanged($event)"
                />
            </div>
        }
        @if (enableDate) {
            <div class="col-sm-6 col-xs-12">
                <adaa-form-input-date
                    controlName="attachDate"
                    formControlName="attachDate"
                    [setDefaultValue]="defaultDate()"
                    [invalid]="submitted && attachmentForm.get('attachDate')?.invalid!"
                    [label]="'common.form.label.attachment_date' | translate"
                    [setValidator]="formValidation"
                />
            </div>
        }
    </div>
    <div class="row">
        <div class="col-12">
            <adaa-form-input-file
                label="kpi.file"
                controlName="attachFile"
                [onDeleteRemoveFile]="false"
                [isDisabled]="pageMode === PageMode.view"
                [allowedFileSize]="26214400"
                [allowedFiles]="allowedFiles"
                [allowedFilesMessage]="allowedFilesMessage"
                [file]="attachmentFile"
                [setValidator]="formValidation"
                (inputChanges)="onFileChange($event)"
            ></adaa-form-input-file>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ (showSubmit ? "common.form.button.cancel" : "common.form.button.close") | translate }}
    </button>
    @if (showSubmit && pageMode !== PageMode.view) {
        <button type="button" class="btn btn-primary" (click)="save()">
            {{ "common.form.button.save" | translate }}
        </button>
    }
</div>
