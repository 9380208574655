import { AdaaHelper } from "../../../../../../../core/utils";
import { SharedFields, teamMembersSectionConfig, textField } from "../../configs";
import {
  AccordionSectionConfigType,
  ListSectionConfigType,
  ScreenSectionConfigType,
  ScreenType,
} from "../../wf-difference.types";
import { milestonesSectionConfig } from "../tp-screen/milestones.config";

const nationalProjectIdField: ScreenSectionConfigType = {
  ...(textField("nationalProjectName", {
    use: "current",
    label: "national_projects.project_name",
  }) as ScreenSectionConfigType),
  field: "nationalProjectId",
  format: (data: Record<string, string>) => AdaaHelper.getItemValueByToken(data, "nationalProjectName"),
};

const actualStartDateField: ScreenSectionConfigType = {
  field: "actualStartDate",
  label: "national_projects.actual_start_date",
  view: "normal",
  format: (data: { actualStartDate: number }) => {
    if (data?.actualStartDate) {
      return AdaaHelper.formatDate(data?.actualStartDate, false);
    }
    return "";
  },
};

const actualEndDateField: ScreenSectionConfigType = {
  field: "actualEndDate",
  label: "national_projects.actual_end_date",
  view: "normal",
  format: (data: { actualEndDate: number }) => {
    if (data?.actualEndDate) {
      return AdaaHelper.formatDate(data?.actualEndDate, false);
    }
    return "";
  },
};

const budgetField: ScreenSectionConfigType = {
  field: "budget",
  label: "initiatives.budget",
  view: "normal",
};

const expensesField: ScreenSectionConfigType = {
  field: "expense",
  label: "initiatives.expenses",
  view: "normal",
};

const progressField: ScreenSectionConfigType = {
  field: "progress",
  label: "box_status.activity_progress",
  view: "normal",
};

const signOffField: ScreenSectionConfigType = {
  field: "signOff",
  label: "national_projects.sign_off.justification",
  view: "normal",
};

const contributingEntitiesField: ListSectionConfigType = {
  field: "contributingEntities",
  label: "kpi.contributing_entities",
  view: "list",
  idKey: "entityId",
  derivedList: (data: { otherEntityId?: number }[]) => data.filter(($i) => !AdaaHelper.isDefined($i?.otherEntityId)),
  format: ($o) => AdaaHelper.getItemValueByToken($o, "entityName"),
};

const otherContributingEntitiesField: ListSectionConfigType = {
  field: "otherContributingEntities",
  label: "other_entities.contrib_entities",
  view: "list",
  idKey: "otherEntityId",
  derivedList: (data: { otherEntityId?: number }[]) => data.filter(($i) => AdaaHelper.isDefined($i?.otherEntityId)),
  format: ($o) => AdaaHelper.getItemValueByToken($o, "otherEntityName"),
};

const commentsField: ListSectionConfigType = {
  field: "comments",
  label: "national_projects.comments",
  view: "list",
  idKey: "id",
  format: ($o) => AdaaHelper.getItemValueByToken($o, "comments"),
};

const signOffAttachmentField: ScreenSectionConfigType = {
  field: "signOffAttachment",
  label: "national_projects.sign_off.evidence_wf_cs",
  view: "normal",
  isClickable: true,
  hasDiff: ($n: { id: number }, $o: { id: number }) => $n?.id !== $o?.id,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format: ($o) => ($o as any).signOffAttachment?.filename,
};

const projectTeamMembersField: AccordionSectionConfigType = {
  view: "accordion",
  field: "teamMembers",
  label: "national_projects.national_projects_milestone.team_members",
  title: ($o) => AdaaHelper.getItemValueByToken($o, "name"),
  idKey: "id",
  config: teamMembersSectionConfig,
};

const subMilestonesField: AccordionSectionConfigType = {
  view: "accordion",
  field: "subMilestones",
  label: "national_projects.national_projects_milestone.sub_milestones",
  title: ($o) => AdaaHelper.getItemValueByToken($o, "name"),
  idKey: "id",
  config: [
    ...milestonesSectionConfig,
    {
      ...projectTeamMembersField,
      label: "national_projects.national_projects_milestone.sub_milestone_team_members",
    },
  ],
};

export const commonTpMilestoneScreenConfig = {
  type: "kpi" as ScreenType,
  sections: [
    nationalProjectIdField,
    ...SharedFields.name,
    ...SharedFields.description,
    {
      ...SharedFields.startDate,
      label: "national_projects.planned_start_date",
    },
    {
      ...SharedFields.endDate,
      label: "national_projects.planned_end_date",
    },
    actualStartDateField,
    actualEndDateField,
    {
      ...SharedFields.entityIdField,
      label: "national_projects.accountable_entity",
    },
    SharedFields.ownerField,
    contributingEntitiesField,
    otherContributingEntitiesField,
    budgetField,
    expensesField,
    progressField,
    signOffField,
    signOffAttachmentField,
    SharedFields.nationalSectorsField,
    commentsField,
    subMilestonesField,
    projectTeamMembersField,
    SharedFields.attachmentsField,
  ],
};
