import { inject, Injectable } from "@angular/core";

import type {
  MainResponseModel,
  NationalAgendaEntityModelType,
  NationalAgendaKpiModelType,
  NationalAgendaModelType,
} from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class NationalAgendaApiService {
  private _mainService = inject(MainService);

  public getDashboardData(year: number) {
    const url = `calculationreadingannual/national-agenda/${year}`;

    return this._mainService.sendRequest<MainResponseModel<NationalAgendaModelType>>({
      url,
      method: "GET",
    });
  }

  public getKpis({ year, govDirId, subGovDirId }: { year: number; govDirId: number; subGovDirId?: number }) {
    const url = `national-agenda/ntkpis`;
    const qs = new URLSearchParams();
    qs.append("year", `${year}`);
    qs.append("govDirId", `${govDirId}`);

    if (subGovDirId) {
      qs.append("subGovDirId", `${subGovDirId}`);
    }

    return this._mainService.sendRequest<MainResponseModel<NationalAgendaKpiModelType[]>>({
      url: `${url}?${qs.toString()}`,
      method: "GET",
    });
  }

  public getEntities({ year, govDirId, subGovDirId }: { year: number; govDirId: number; subGovDirId?: number }) {
    const url = `national-agenda/entities`;
    const qs = new URLSearchParams();
    qs.append("year", `${year}`);
    qs.append("govDirId", `${govDirId}`);

    if (subGovDirId) {
      qs.append("subGovDirId", `${subGovDirId}`);
    }

    return this._mainService.sendRequest<MainResponseModel<NationalAgendaEntityModelType[]>>({
      url: `${url}?${qs.toString()}`,
      method: "GET",
    });
  }
}
