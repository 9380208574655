import { Component, inject, Input, signal } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { ValueText } from "../../../models";
import { GovScoresScopesApiService } from "../../../services";
import { FormDropdownComponent } from "../../form";

@Component({
  selector: "adaa-scope-kpi-delete-modal",
  standalone: true,
  imports: [TranslateModule, FormDropdownComponent],
  templateUrl: "./scope-kpi-delete-modal.component.html",
  styleUrl: "./scope-kpi-delete-modal.component.scss",
})
export class ScopeKpiDeleteModalComponent {
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _govScoresScopesApiService = inject(GovScoresScopesApiService);
  modal = inject(NgbActiveModal);

  @Input() entityId: number;
  @Input() scopeId: number | undefined;

  isLink: boolean = false;
  scopes = signal<ValueText[]>([]);
  scope: number | null = null;

  public linkToScope(): void {
    this.isLink = true;

    if (!this.entityId) return;

    this._govScoresScopesApiService.getScopesByEntityId(this.entityId).subscribe({
      next: (response) => {
        if (response.inError) return;

        //Remove this scope from ddl
        if (this.scopeId) response.responseData = response.responseData.filter((e) => e.id !== this.scopeId);

        this.scopes.set(AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name")));
      },
    });
  }

  public saveLink(): void {
    if (!this.scope) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    this.modal.close({ setToDraft: false, scope: this.scope });
  }
}
