import { Component, computed, inject, input, signal } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChartConfiguration } from "chart.js";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { Language } from "../../../constants/enums";
import type {
  EkpiPerformanceModelType,
  MasterEkpiActuals,
  PillarPerformanceModelType,
  SelectedPeriodModelType,
} from "../../../models";
import { GraphApiService, LanguageService, PillarEkpiPerformanceService } from "../../../services";
import { ChartActionButtonsComponent } from "../../chart-action-buttons/chart-action-buttons.component";
import { EntityComparisonComponent } from "../../modals/entity-comparison/entity-comparison.component";
import { EkpiInfoComponent } from "./_ekpi-info.component";
import { PillarPerformanceGraphComponent } from "./_pillar-performance-graph.component";

@Component({
  selector: "adaa-government-enabler-pillars",
  standalone: true,
  templateUrl: "./government-enabler-pillars.component.html",
  styleUrl: "./government-enabler-pillars.component.scss",
  imports: [TranslateTokenPipe, EkpiInfoComponent, PillarPerformanceGraphComponent, ChartActionButtonsComponent],
})
export class GovernmentEnablerPillarsComponent {
  private readonly _pillarEkpiPerformanceService = inject(PillarEkpiPerformanceService);
  readonly languageService = inject(LanguageService);
  private _graphApiService = inject(GraphApiService);
  private _modalService = inject(NgbModal);

  period = input.required<SelectedPeriodModelType>();

  data = signal<MasterEkpiActuals | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartData = signal<ChartConfiguration<"bar">["data"] | any | undefined>(undefined);

  pillars = computed(() => this._pillarEkpiPerformanceService.ekpiPillarPerformance());
  elementIds = computed(() => {
    const count = this._pillarEkpiPerformanceService.ekpiPillarPerformance().length;
    const ids: string[] = [];
    for (let index = 0; index < count; index++) {
      ids.push("enablerChart" + index);
    }
    return ids;
  });
  chartTitles = computed(() => {
    const field = AdaaHelper.getFieldLanguage("pillarName");
    const pillars = this._pillarEkpiPerformanceService.ekpiPillarPerformance();
    return pillars.map((pillar) => pillar[field as keyof PillarPerformanceModelType] as string);
  });

  extraContent = signal<string[]>([]);

  public setContent(content: string) {
    this.extraContent().push(content);
  }

  public labelClicked(content: PillarPerformanceGraphComponent, item: EkpiPerformanceModelType) {
    const data = content.data();
    const index = data.ekpiList.findIndex(({ id }) => id === item.id);
    if (!AdaaHelper.isDefined(index) || index === -1) return;

    const chart = content.chart()?.chart;
    const tooltip = chart?.tooltip;
    const chartArea = chart?.chartArea;
    tooltip?.setActiveElements(
      [
        {
          datasetIndex: 0,
          index,
        },
      ],
      {
        x: ((chartArea?.left ?? 0) + (chartArea?.right ?? 0)) / 2,
        y: ((chartArea?.top ?? 0) + (chartArea?.bottom ?? 0)) / 2,
      }
    );

    chart?.update();
  }

  public graphClicked(item: EkpiPerformanceModelType) {
    this._graphApiService.getEkpiActualsByPeriod(item.id, this.period().period.id).subscribe({
      next: (response) => {
        this.data.set(response.responseData ?? undefined);
        this.chartData.set({
          labels: response.responseData.ekpis.map((e) => {
            const lang = localStorage.getItem("language") as Language;
            if (lang === Language.Arabic) return e.entityNameAR ?? " ";
            return e.ekpiNameEN ?? " ";
          }),
          datasets: [
            {
              data: response.responseData.ekpis.map((e) => e.actual),
              backgroundColor: response.responseData.ekpis.map((e) => {
                const hasLabel = AdaaHelper.isDefined(e.entityNameAR) || AdaaHelper.isDefined(e.ekpiNameEN);
                if (!hasLabel) {
                  return Constants.COLOR_CODES[Constants.CONSTANT_COLORS.EVALUATION_GRAY];
                }
                return Constants.COLOR_CODES[e.performanceColor];
              }),
              extraData: response.responseData.ekpis.map((e) => e.performance),
              ignoredList: response.responseData.ekpis.map((e) => e.ignored),
            },
          ],
        });
      },
      complete: () => {
        const modal = this._modalService.open(EntityComparisonComponent, {
          centered: true,
          size: "xl",
          modalDialogClass: this.languageService.modalDirection(),
        });

        modal.componentInstance.usePercentage =
          this.data()?.measurementUnitId === Constants.MEASUREMENT.CONSTANT_MEASUREMENT_PERCENTAGE;
        modal.componentInstance.datasets = this.chartData()?.datasets;
        modal.componentInstance.labels = this.chartData()?.labels;
        modal.componentInstance.chartId = item.id;
        modal.componentInstance.usePerformance =
          this.data()?.trendId === Constants.TREND.ONTARGET || this.data()?.trendId === Constants.TREND.BOUNDED;
        modal.componentInstance.title = `Q${this.period().period.quarter} ${this.period().period.year} ${AdaaHelper.getItemValueByToken(item, "ekpiName")}`;
      },
    });
  }
}
