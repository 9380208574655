<div class="modal-header">
    <h4 class="modal-title">{{ "notification.error.integrity_system" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <adaa-data-table
        [configKey]="'integrity_dependencies_conf_list'"
        [isLocalData]="true"
        [enableAdd]="false"
        [enableActionsCell]="false"
        [enableFilter]="false"
        [enableSettings]="false"
        [localData]="errorMessages"
    >
    </adaa-data-table>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close()">
        {{ "common.form.button.close" | translate }}
    </button>
</div>
