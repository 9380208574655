import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "numberComma",
  standalone: true,
})
export class NumberCommaPipe implements PipeTransform {
  public transform(value: number | string): string {
    if (!value && value !== 0) return "";

    const numberValue = typeof value === "string" ? parseFloat(value) : value;
    return new Intl.NumberFormat("en-US").format(numberValue);
  }
}
