import { inject, Injectable } from "@angular/core";

import { environment } from "../../core/environments/environment";
import { MainResponseModel } from "../models";
import { Formula } from "../models/formulas.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class FormulasApiService {
  private _mainService = inject(MainService);

  public createFormula(data: Formula) {
    const url = `formulas/create?isFinish=false`;

    return this._mainService.sendRequest<MainResponseModel<Formula>>({
      method: "POST",
      url,
      data,
    });
  }

  public updateFormula(data: Partial<Formula>) {
    const url = `formulas/update`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public validateFormula(data: { formula: string }) {
    const url = `formula/validate`;

    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "POST",
      url,
      apiUrl: environment.expressionToolsUrl,
      data,
    });
  }

  public activateFormula(formulaId: number | string) {
    const url = `formulas/activate/${formulaId}`;

    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "GET",
      url,
    });
  }

  public getFormula(formulaId: number | string) {
    const url = `formulas/getById/${formulaId}`;

    return this._mainService.sendRequest<MainResponseModel<Formula>>({
      method: "GET",
      url,
    });
  }

  public getAll() {
    const url = `formulas/getall`;

    return this._mainService.sendRequest<MainResponseModel<Formula[]>>({
      method: "GET",
      url,
    });
  }
}
