import { NgClass } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  viewChild,
} from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faArrowCircleLeft, faArrowCircleRight, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import {
  DndDraggableDirective,
  DndDropEvent,
  DndDropzoneDirective,
  DndModule,
  DndPlaceholderRefDirective,
} from "ngx-drag-drop";

import { AdaaHelper } from "../../../../core/utils";
import { Language } from "../../../constants/enums";
import { ParameterCatalog, ValueText } from "../../../models";
import { LanguageService } from "../../../services";

@Component({
  selector: "adaa-form-two-way-select",
  standalone: true,
  imports: [
    TranslateModule,
    FontAwesomeModule,
    NgClass,
    DndModule,
    DndDropzoneDirective,
    DndDraggableDirective,
    DndPlaceholderRefDirective,
  ],
  templateUrl: "./form-two-way-select.component.html",
  styleUrl: "./form-two-way-select.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormTwoWaySelectComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormTwoWaySelectComponent),
    },
  ],
})
export class FormTwoWaySelectComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() required = false;
  @Input() label: string = "";
  @Input() selectedLabel: string = "";
  @Input() notSelectedLabel: string = "";
  @Input() options: ValueText[] = [];
  @Input() controlName: string | null = null;
  @Input() controlId: string | null = null;
  @Input() emptyNotSelectedListLabel: string = "common.form.label.drag_drop_no_items_to_add";
  @Input() emptySelectedListLabel: string = "common.form.label.drag_drop_no_items_to_remove";
  @Input() isDisabled: boolean;
  @Input() allowSort: boolean = false;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public set setDefaultValue(value: any) {
    if (value) {
      this.writeValue(value);
    }
  }

  @Input()
  public set setValidator(validatorList: { parameterCatalogs: ParameterCatalog[] }) {
    if (validatorList) {
      if (validatorList.parameterCatalogs) {
        const field = validatorList.parameterCatalogs.find((e) => e.fieldName === this.controlName);

        if (field) {
          this.required = field.mandatory === "Y";
          this.inputControl.updateValueAndValidity();
        }
      }
    }
  }

  @Output() inputChanges = new EventEmitter();

  inputControl: FormControl = new FormControl();
  notSelectedList: ValueText[] = [];
  selectedList: ValueText[] = [];
  notSelectedListCopy: ValueText[] = [];
  selectedListCopy: ValueText[] = [];
  searchResults: boolean = false;
  adaaHelper = AdaaHelper;
  language = Language;

  private _searchNotSelected = viewChild<ElementRef>("searchNotSelected");
  private _searchSelected = viewChild<ElementRef>("searchSelected");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedHighlight: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notSelectedHighlight: any[] = [];

  private _onValidatorChange: () => void;
  private _onTouched: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _onChange: (value: any) => void;

  private _iconLibrary: FaIconLibrary = inject(FaIconLibrary);
  languageService: LanguageService = inject(LanguageService);

  constructor() {}

  public ngOnInit(): void {
    if (!this.adaaHelper.isDefined(this.controlId)) this.controlId = this.controlName;
    this._iconLibrary.addIcons(faArrowLeft, faArrowRight, faArrowCircleRight, faArrowCircleLeft);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["options"] &&
      !this._optionsArraysAreEqual(changes["options"].previousValue ?? [], changes["options"].currentValue ?? [])
    ) {
      //reset options
      this.notSelectedList = changes["options"].currentValue;
      this.selectedList = [];
      this.writeValue(this.inputControl.value);
    }
  }

  public search(event: string, isAvailable: boolean = false): void {
    const lowerCaseSearch = event.toLowerCase();

    if (isAvailable) {
      if (!this.notSelectedListCopy.length) this.notSelectedListCopy = this.notSelectedList;
      const targetList = this.notSelectedListCopy?.length ? this.notSelectedListCopy : this.options;

      this.notSelectedList = targetList?.filter((a) => a.text.toLowerCase().includes(lowerCaseSearch));
      this.searchResults = this.notSelectedList?.length > 0;

      if (event.length === 0) this.notSelectedList = this.notSelectedListCopy;
    } else {
      if (!this.selectedListCopy.length) this.selectedListCopy = this.selectedList;
      const targetList = this.selectedListCopy?.length ? this.selectedListCopy : this.options;

      this.selectedList = targetList?.filter((a) => a.text.toLowerCase().includes(lowerCaseSearch));
      this.searchResults = this.selectedList?.length > 0;

      if (event.length === 0) this.selectedList = this.selectedListCopy;
    }
    if (event.length === 0) this.searchResults = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selectItem(event: any, isAvailable: boolean = false): void {
    if (isAvailable) {
      const index = this.selectedHighlight.findIndex((e) => e === event);
      if (index === -1) this.selectedHighlight.push(event);
      else this.selectedHighlight.splice(index, 1);
    } else {
      const index = this.notSelectedHighlight.findIndex((e) => e === event);
      if (index === -1) this.notSelectedHighlight.push(event);
      else this.notSelectedHighlight.splice(index, 1);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public hasItem(event: any, isAvailable: boolean = false): boolean {
    if (isAvailable) {
      return this.selectedHighlight.findIndex((e) => e === event) !== -1;
    } else {
      return this.notSelectedHighlight.findIndex((e) => e === event) !== -1;
    }
  }

  public selectAll(isAvailable: boolean = false): void {
    if (this.searchResults) {
      if (isAvailable) {
        this.notSelectedList.forEach((result) => {
          if (!this.selectedList.find((option) => option.value === result.value)) {
            this.selectedList.push(result);
          }
        });
        this.selectedListCopy = this.selectedList;
        this.notSelectedList = this.options.filter((e) => !this.selectedList.includes(e));
        this.notSelectedListCopy = this.notSelectedList;
      } else {
        this.selectedList.forEach((result) => {
          if (!this.notSelectedList.find((option) => option.value === result.value)) this.notSelectedList.push(result);
        });
        this.selectedList = this.options.filter((e) => !this.notSelectedList.includes(e));
        this.selectedListCopy = this.selectedList;
      }
    } else {
      if (isAvailable) {
        this.selectedList = this.adaaHelper.clone(this.options);
        this.selectedListCopy = this.selectedList;
        this.notSelectedList = [];
      } else {
        this.notSelectedList = this.options;
        this.selectedList = [];
        this.selectedListCopy = [];
      }
    }

    this.searchResults = false;
    this._valueChanged(this.selectedList.map((e) => e.value));
    this.selectedHighlight = [];
    this.notSelectedHighlight = [];

    this.doSearch();
  }

  public select(isAvailable: boolean = false): void {
    if (isAvailable) {
      if (!this.adaaHelper.isDefined(this.selectedHighlight) || this.selectedHighlight.length === 0) return;

      this.selectedHighlight.forEach((highlight) => {
        const option = this.options.find((option) => option.value === highlight);
        if (option) {
          if (!this.selectedListCopy.find((option) => option.value === highlight)) this.selectedListCopy.push(option);
        }
      });
      this.notSelectedList = this.options.filter((e) => !this.selectedListCopy.includes(e));
      this.notSelectedListCopy = this.notSelectedList;
    } else {
      if (!this.adaaHelper.isDefined(this.notSelectedHighlight) || this.notSelectedHighlight.length === 0) return;

      this.notSelectedHighlight.forEach((highlight) => {
        const option = this.options.find((option) => option.value === highlight);
        if (option) {
          if (!this.notSelectedListCopy.find((option) => option.value === highlight))
            this.notSelectedListCopy.push(option);
        }
      });
      this.selectedList = this.options.filter((e) => !this.notSelectedListCopy.includes(e));
      this.selectedListCopy = this.selectedList;
    }
    this._valueChanged(this.selectedList.map((e) => e.value));
    this.selectedHighlight = [];
    this.notSelectedHighlight = [];

    this.doSearch();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public writeValue(obj: any): void {
    if (obj === null) {
      this.inputControl.reset();
    }

    if (this.adaaHelper.isDefined(obj)) {
      this.selectedList = this.options?.filter((option) =>
        obj.some((item: { value: unknown }) => item === option.value)
      );
      this.notSelectedList = this.options?.filter(
        (option) => !obj.some((item: { value: unknown }) => item === option.value)
      );
    }

    this.doSearch();
    this.inputControl.setValue(obj);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this._onValidatorChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public validate(value: FormControl): false | { required: boolean } {
    const isRequired =
      (this.required && this.inputControl.value === null) || (this.required && this.inputControl.value === "");
    return (
      isRequired && {
        required: true,
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public _valueChanged(event: any): void {
    if (this._onChange) this._onChange(event);
    this.inputChanges.emit(event);
  }

  public doSearch() {
    //reset copy
    this.notSelectedListCopy = [];
    this.selectedListCopy = [];

    //do search
    this.search(this._searchNotSelected()!.nativeElement.value, true);
    this.search(this._searchSelected()!.nativeElement.value);
  }

  public onDrop(event: DndDropEvent, isSelected?: boolean): void {
    if (event.index == undefined) return;
    if (isSelected) {
      if (!this.selectedList.find((e) => e.value === event.data.value)) {
        this.selectedList.splice(event.index, 0, event.data);
        this.selectedListCopy = this.selectedList;

        this.notSelectedList = this.options.filter((e) => !this.selectedList.map((e) => e.value).includes(e.value));
        this.notSelectedListCopy = this.notSelectedList;
      } else {
        const index = this.selectedList.findIndex((e) => e.value === event.data.value);
        if (index != -1) {
          this.selectedList = AdaaHelper.moveItemInArray(this.selectedList, index, event.index);
          this.selectedListCopy = this.selectedList;
        }
      }
    } else {
      if (!this.notSelectedList.find((e) => e.value === event.data.value)) {
        this.notSelectedList.splice(event.index, 0, event.data);
        this.notSelectedListCopy = this.notSelectedList;

        this.selectedList = this.options.filter((e) => !this.notSelectedList.map((e) => e.value).includes(e.value));
        this.selectedListCopy = this.selectedList;
      } else {
        const index = this.notSelectedList.findIndex((e) => e.value === event.data.value);
        if (index != -1) {
          this.notSelectedList = AdaaHelper.moveItemInArray(this.notSelectedList, index, event.index);
          this.notSelectedListCopy = this.notSelectedList;
        }
      }
    }

    this._valueChanged(this.selectedList.map((e) => e.value));
  }

  private _optionsArraysAreEqual(previousValue: ValueText[], currentValue: ValueText[]): boolean {
    if (previousValue.length !== currentValue.length) return false;

    for (let i = 0; i < previousValue.length; i++) {
      if (JSON.stringify(previousValue[i]) !== JSON.stringify(currentValue[i])) return false;
    }

    return true;
  }
}
