import { inject } from "@angular/core";
import { shareReplay, Subscriber } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import { AdaaBoolean } from "../constants/enums";
import { EntityModelType, GovDirection, OrgUnit, ServiceModel, SubGovDirection, ValueText } from "../models";
import { AgmCycleApiService } from "./agm-cycle-api.service";
import { AuditTrailApiService } from "./audit-trail-api.service";
import { CyclesApiService } from "./cycles-api.service";
import { EntitiesApiService } from "./entities-api.service";
import { GovDirectionApiService } from "./gov-direction-api.service";
import { GovScoresApiService } from "./gov-scores-api.service";
import { NotificationsApiService } from "./notifications-api.service";
import { ObjectivesApiService } from "./objectives-api.service";
import { OrgUnitApiService } from "./org-unit-api.service";
import { OtherEntitiesApiService } from "./other-entities-api.service";
import { PropertiesService } from "./properties.service";
import { ServicesApiService } from "./services-api.service";
import { SubGovDirectionApiService } from "./sub-gov-direction-api.service";
import { TpApiService } from "./tp-api.service";
import { TpCyclesApiService } from "./tp-cycles-api.service";
import { UserGroupsApiService } from "./user-groups-api.service";

type DataCallback<T> = (data: T) => void;

/**
 * Populates the cycles dropdown-list
 */
export const cyclesDropdownList = () => {
  const cyclesApiService = inject(CyclesApiService);

  return (subscriber: Subscriber<ValueText[]>, { fieldName }: { fieldName: string }) => {
    if (fieldName.includes("planName")) {
      cyclesApiService.getAll().subscribe({
        next: (response) => {
          if (response.inError) return;
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the objectives dropdown-list
 */
export const objectivesDropdownList = () => {
  const objectivesApiService = inject(ObjectivesApiService);

  return (subscriber: Subscriber<ValueText[]>, { fieldName }: { fieldName: string }) => {
    if (fieldName.includes("objectiveName")) {
      objectivesApiService
        .getObjectivesByEntityAndPlan({
          entityId: Number(AdaaHelper.entity?.id),
          planId: Number(AdaaHelper.plan?.id),
        })
        .subscribe({
          next: (response) => {
            if (response.inError) return;
            subscriber.next(
              AdaaHelper.setDropdownArray(
                response.responseData,
                AdaaHelper.getFieldLanguage("name"),
                AdaaHelper.getFieldLanguage("name")
              )
            );
          },
        });
    }
  };
};

/**
 * Populates the tp dropdown-list
 */
export const tpDropdownList = () => {
  const tpApiService = inject(TpApiService);

  return (subscriber: Subscriber<ValueText[]>, { fieldName }: { fieldName: string }) => {
    if (fieldName.includes("nationalProjectName")) {
      tpApiService.getAllRelatedByEntity(AdaaHelper.entity.id).subscribe({
        next: (response) => {
          if (response.inError) return;
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the other entities dropdown-list
 */
export const otherEntitiesDropdownList = () => {
  const otherEntitiesApiService = inject(OtherEntitiesApiService);

  return (subscriber: Subscriber<ValueText[]>, { fieldName }: { fieldName: string }) => {
    if (fieldName.includes("otherEntityName")) {
      otherEntitiesApiService.getAll().subscribe({
        next: (response) => {
          if (response.inError) return;
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the entities dropdown-list
 */
export const entitiesDropdownList = () => {
  const entitiesApiService = inject(EntitiesApiService);

  return (
    subscriber: Subscriber<ValueText[]>,
    { fieldName, configKey, cb }: { fieldName: string; configKey: string; cb: DataCallback<EntityModelType[]> }
  ) => {
    const excludeExternalEntity = fieldName.includes("decidingEntityName") || fieldName.includes("userGroupEntityName");

    const shouldRun =
      (fieldName.includes("entityName") ||
        fieldName.includes("sponsorEntityName") ||
        fieldName.includes("decidingEntityName") ||
        fieldName.includes("userGroupEntityName") ||
        fieldName.includes("responsibleEntityName") ||
        fieldName.includes("enablerEntityName")) &&
      configKey !== "project_agreement_conf_list";

    if (shouldRun) {
      entitiesApiService.getAll(excludeExternalEntity ? AdaaBoolean.N : AdaaBoolean.Y).subscribe({
        next: (response) => {
          if (response.inError) return;

          cb(response.responseData);

          //NOTE: If the column is enabler entity only show the entities with enabler flag
          const data = fieldName.includes("enablerEntityName")
            ? response.responseData.filter((e) => e.enabler === AdaaBoolean.Y)
            : response.responseData;

          subscriber.next(
            AdaaHelper.setDropdownArray(data, AdaaHelper.getFieldLanguage("name"), AdaaHelper.getFieldLanguage("name"))
          );
        },
      });
    }
  };
};

/**
 * Populates the orgUnit dropdown-list
 */
export const orgUnitDropdownList = () => {
  const orgUnitApiService = inject(OrgUnitApiService);

  return (
    subscriber: Subscriber<ValueText[]>,
    { fieldName, entityId, cb }: { fieldName: string; entityId?: number; cb: DataCallback<OrgUnit[]> }
  ) => {
    if (fieldName.includes("orgUnitName")) {
      orgUnitApiService.getAllByEntity(Number(entityId ?? AdaaHelper.entity?.id)).subscribe({
        next: (response) => {
          if (response.inError) return;

          cb(response.responseData);

          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("parentOrgUnitName")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the subGovDirection dropdown-list
 */
export const subGovDirectionDropdownList = () => {
  const subGovDirectionApiService = inject(SubGovDirectionApiService);

  return (
    subscriber: Subscriber<ValueText[]>,
    { fieldName, cb }: { fieldName: string; cb: DataCallback<SubGovDirection[]> }
  ) => {
    /*
     * All sub gov-dir
     * */
    if (fieldName.includes("subGovDirectionName")) {
      subGovDirectionApiService.getAll().subscribe({
        next: (response) => {
          if (response.inError) return;
          cb(response.responseData);
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });

      /*
       * Sub gov-dif by gov-dir ID
       * */
    } else if (fieldName.includes("subGovernmentDirectionName")) {
      subGovDirectionApiService.getAllbyGovDirection(Constants.CONSTANT_WE_THE_UAE_2031_SUB_GOV_ID).subscribe({
        next: (response) => {
          if (response.inError) return;
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the govDirection dropdown-list
 */
export const govDirectionDropdownList = () => {
  const govDirectionApiService = inject(GovDirectionApiService);

  return (
    subscriber: Subscriber<ValueText[]>,
    { fieldName, cb }: { fieldName: string; cb: DataCallback<GovDirection[]> }
  ) => {
    /*
     * Gov-dirs by vision
     * */
    if (fieldName.includes("govDirectionName")) {
      govDirectionApiService.getAllByVision(AdaaBoolean.Y).subscribe({
        next: (response) => {
          if (response.inError) return;
          cb(response.responseData);
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });

      /*
       * Auxillary gov-dirs
       * */
    } else if (fieldName.includes("auxDirectionName")) {
      govDirectionApiService.getAuxiliaryDirection(Constants.CONSTANT_WE_THE_UAE_2031_SUB_GOV_ID).subscribe({
        next: (response) => {
          if (response.inError) return;
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the audit-trails dropdown-list
 */
export const auditTrailDropdownList = () => {
  const auditTrailApiService = inject(AuditTrailApiService);

  return (subscriber: Subscriber<ValueText[]>, { fieldName, configKey }: { fieldName: string; configKey: string }) => {
    const canGetObjectTypes =
      (fieldName === "objectTypeEN" ||
        fieldName === "objectTypeAE" ||
        fieldName === "itemTypeEN" ||
        fieldName === "itemTypeAE") &&
      (configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.AUDIT_TRAIL ||
        configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.USERS_PROFILE_OWNED_OBJECT);

    /*
     * Audit trail object types
     * */
    if (canGetObjectTypes) {
      auditTrailApiService.getObjectTypes().subscribe({
        next: (response) => {
          if (response.inError) return;
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });

      /*
       * Audit trail action types
       * */
    } else if (fieldName.includes("actionName")) {
      auditTrailApiService.getActionTypes().subscribe({
        next: (response) => {
          if (response.inError) return;
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the itemTypes dropdown-list
 */
export const itemTypesDropdownList = () => {
  const propertiesService = inject(PropertiesService);
  const untilDestroyed = AdaaHelper.untilDestroyed();

  // Share the api response to avoid duplicate api calls
  const source$ = propertiesService.getAllItemTypes().pipe(
    untilDestroyed(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  return (subscriber: Subscriber<ValueText[]>, { fieldName }: { fieldName: string }) => {
    /*
     * Linked KPIs
     * */
    if (fieldName.includes("linkedKpiType")) {
      source$.subscribe({
        next: (response) => {
          if (response.inError) return;
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData.filter(
                ({ id }) => id === Constants.CONSTANT_NKPITYPE || id === Constants.CONSTANT_UAEKPI
              ),
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });

      /*
       * Filtered item types
       * */
    } else if (fieldName.includes("itemName")) {
      source$.subscribe({
        next: (response) => {
          if (response.inError) return;

          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData.filter(
                ({ id, propType }) =>
                  propType !== Constants.CONSTANT_SERVICETYPES_TYPE || id !== Constants.CONSTANT_SUBGOVDIRECTION
              ),
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });

      /*
       * Data Entry Page
       * */
    } else if (fieldName.includes("kpiType")) {
      source$.subscribe({
        next: (response) => {
          if (response.inError) return;

          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData.filter(({ propType }) => propType === Constants.CONSTANT_KPITYPE_PROP),
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });

      /*
       * Main Outcome Type
       * */
    } else if (fieldName.includes("mainOutcomeTypeName")) {
      source$.subscribe({
        next: (response) => {
          if (response.inError) return;

          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData.filter(({ propType }) => propType === Constants.CONSTANT_MAIN_OUTCOME_TYPE_PROP),
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the User Groups dropdown-list
 */
export const userGroupsDropdownList = () => {
  const userGroupsApiService = inject(UserGroupsApiService);

  return (subscriber: Subscriber<ValueText[]>, { fieldName }: { fieldName: string }) => {
    //Notification Group
    if (fieldName.includes("notificationGroup")) {
      userGroupsApiService.getallWithoutPagination().subscribe({
        next: (response) => {
          if (response.inError) return;

          subscriber.next(
            AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );
        },
      });
    }
  };
};

/**
 * Populates the Notification Category dropdown-list
 */
export const notificationCategoryDropdownList = () => {
  const notificationsApiService = inject(NotificationsApiService);

  return (subscriber: Subscriber<ValueText[]>, { fieldName, configKey }: { fieldName: string; configKey: string }) => {
    const isNotificationPage =
      configKey === "notification_configuration_entity_conf_list" ||
      configKey === "notification_configuration_pmo_conf_list";

    //Notification Category
    if (fieldName.includes("categoryName") && isNotificationPage) {
      notificationsApiService.getCategories().subscribe({
        next: (response) => {
          if (response.inError) return;

          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the TP Cycles dropdown-list
 */
export const tpCyclesDropdownList = () => {
  const tpCyclesApiService = inject(TpCyclesApiService);

  return (subscriber: Subscriber<ValueText[]>, { fieldName }: { fieldName: string }) => {
    //TP Cycles
    if (fieldName.includes("nationalProjectCycleName")) {
      tpCyclesApiService.getAllCycles().subscribe({
        next: (response) => {
          if (response.inError) return;

          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the Main Services dropdown-list
 */
export const mainServicesDropdownList = () => {
  const serviceApiService = inject(ServicesApiService);

  return (
    subscriber: Subscriber<ValueText[]>,
    { fieldName, cb }: { fieldName: string; cb: DataCallback<ServiceModel[]> }
  ) => {
    if (fieldName.includes("mainServiceName")) {
      serviceApiService.getServicesByMap().subscribe({
        next: (response) => {
          if (response.inError) return;
          cb(response.responseData);
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the Aux/Var Services dropdown-list
 */
export const auxVarServicesDropdownList = () => {
  const serviceApiService = inject(ServicesApiService);

  return (
    subscriber: Subscriber<ValueText[]>,
    { fieldName, cb }: { fieldName: string; cb: DataCallback<ServiceModel[]> }
  ) => {
    //TP Cycles
    if (fieldName.includes("auxVarServiceName")) {
      serviceApiService.getSubServicesByMap().subscribe({
        next: (response) => {
          if (response.inError) return;
          cb(response.responseData);
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the published/created by tp agreement dropdown-list
 */
export const tpAgreementUsersDropdownList = () => {
  const tpApiService = inject(TpApiService);

  return (subscriber: Subscriber<ValueText[]>, { fieldName }: { fieldName: string }) => {
    if (fieldName.includes("createUserName") || fieldName.includes("publishUserName")) {
      tpApiService.getAgreementFilterUsers().subscribe({
        next: (response) => {
          if (response.inError) return;
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the entities tp agreement dropdown-list
 */
export const tpAgreementEntitiesDropdownList = () => {
  const tpApiService = inject(TpApiService);

  return (subscriber: Subscriber<ValueText[]>, { fieldName, configKey }: { fieldName: string; configKey: string }) => {
    if (fieldName.includes("entityName") && configKey === "project_agreement_conf_list") {
      tpApiService.getReadyPublishEntities().subscribe({
        next: (response) => {
          if (response.inError) return;
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Populates the pillar type dropdown-list
 */
export const pillarTypeDropdownList = () => {
  const govScoresApiService = inject(GovScoresApiService);

  return (subscriber: Subscriber<ValueText[]>, { fieldName }: { fieldName: string }) => {
    if (fieldName.includes("pillarTypeName")) {
      govScoresApiService.getAvailablePillarTypes().subscribe({
        next: (response) => {
          if (response.inError) return;
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * AGM cycle list dropdown-list
 */
export const agmCyclesDropdownList = () => {
  const agmCycleApiService = inject(AgmCycleApiService);

  return (subscriber: Subscriber<ValueText[]>, { fieldName }: { fieldName: string }) => {
    if (fieldName.includes("annualMeetingProjectCycleName")) {
      agmCycleApiService.getCycles().subscribe({
        next: (response) => {
          if (response.inError) return;
          subscriber.next(
            AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};

/**
 * Assigned To HelpDesk list dropdown-list
 */
export const userGroupsHelpDesk = () => {
  const userGroupsApiService = inject(UserGroupsApiService);
  return (subscriber: Subscriber<ValueText[]>, { fieldName, entityId }: { fieldName: string; entityId?: number }) => {
    if (fieldName.includes("assigned")) {
      userGroupsApiService.getallHelpdesk().subscribe({
        next: (response) => {
          if (response.inError) return;

          const filteredData = entityId
            ? response.responseData
                .filter((item) => item.entityId === entityId)
                .map((e) => ({
                  ...e,
                  nameEN: e.nameEN.split(" - ")[1],
                  nameAE: e.nameAE.split(" - ")[1],
                }))
            : [];

          subscriber.next(
            AdaaHelper.setDropdownArray(
              filteredData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        },
      });
    }
  };
};
