<div class="bg-white shadow-md rounded p-2 mb-3">
    <!--KPI Name-->
    <div class="row mb-1">
        <div class="col-sm-4 col-xs-12 text-primary fw-bold">
            {{ "data_entry.kpi_name" | translate }}
        </div>
        <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "name" }}</div>
    </div>

    <!--KPI DSC-->
    @if (dataEntryDetails().dscAE || dataEntryDetails().dscEN) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "data_entry.description" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "dsc" }}</div>
        </div>
    }

    <!--KPI TYPE-->
    <div class="row mb-1">
        <div class="col-sm-4 col-xs-12 text-primary fw-bold">
            {{ "data_entry.kpi_type" | translate }}
        </div>
        <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "kpiType" }}</div>
    </div>

    <!--KPI LINKED TO-->
    @if (dataEntryDetails().linkedKpiTypeAE || dataEntryDetails().linkedKpiTypeEN) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "data_entry.linked_to" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "linkedKpiType" }}</div>
        </div>
    }

    <!--KPI FREQUENCY-->
    @if (dataEntryDetails().frequencyAE || dataEntryDetails().frequencyEN) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "data_entry.frequency" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "frequency" }}</div>
        </div>
    }

    <!--KPI FORMULA-->
    @if (dataEntryDetails().formula) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "data_entry.formula" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12" [innerHTML]="formula()"></div>
        </div>
    }

    <!--PILLAR NAME-->
    @if (dataEntryDetails().pillarNameAE || dataEntryDetails().pillarNameEN) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "data_entry.pillar_name" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "pillarName" }}</div>
        </div>
    }

    <!--MILESTONE PROJECT-->
    @if (dataEntryDetails().nationalProjectMilestoneNameAE || dataEntryDetails().nationalProjectMilestoneNameEN) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "mtkpi.projectMilestoneId" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "nationalProjectMilestoneName" }}</div>
        </div>
    }

    <!--OBJECTIVE NAME-->
    @if (dataEntryDetails().objectiveNameAE || dataEntryDetails().objectiveNameEN) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "data_entry.objective_name" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "objectiveName" }}</div>
        </div>
    }

    <!--INITATIVE NAME-->
    @if (dataEntryDetails().initiativeNameAE || dataEntryDetails().initiativeNameEN) {
        @if (useNewDS()) {
            <div class="row mb-1">
                <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                    {{ initiativeTypeText(dataEntryDetails().initiativeType)! | translate }}
                </div>
                <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "initiativeName" }}</div>
            </div>
        } @else {
            <div class="row mb-1">
                <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                    {{ "data_entry.initiative_name" | translate }}
                </div>
                <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "initiativeName" }}</div>
            </div>
        }
    }

    <!--ACTIVITY NAME-->
    @if (dataEntryDetails().activityNameAE || dataEntryDetails().activityNameEN) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "data_entry.activity_name" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "activityName" }}</div>
        </div>
    }

    <!--SUB GOV DIRECTION NAME-->
    @if (dataEntryDetails().subGovDirectionNameAE || dataEntryDetails().subGovDirectionNameEN) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "data_entry.govdir" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "subGovDirectionName" }}</div>
        </div>
    }

    <!--MAIN SERVICE NAME-->
    @if (dataEntryDetails().mainServiceNameAE || dataEntryDetails().mainServiceNameEN) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "data_entry.mainService_name" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "mainServiceName" }}</div>
        </div>
    }

    <!--SUB SERVICE NAME-->
    @if (dataEntryDetails().parentKpiNameAE || dataEntryDetails().parentKpiNameEN) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "data_entry.parent_KPI" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "parentKpiName" }}</div>
        </div>
    }

    <!--SPONSOR NAME-->
    @if (dataEntryDetails().sponsorAE || dataEntryDetails().sponsorEN) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "data_entry.sponsor" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "sponsor" }}</div>
        </div>
    }

    <!--SPONSOR OTHER NAME-->
    @if (dataEntryDetails().sponsorOtherAE || dataEntryDetails().sponsorOtherEN) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "data_entry.sponsor_other" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "sponsorOther" }}</div>
        </div>
    }

    <!--TP NAME-->
    @if (
        (dataEntryDetails().nationalProjectNameEN || dataEntryDetails().nationalProjectNameAE) &&
        dataEntryDetails().kpiType === constants.KPI_TYPE.MOKPI
    ) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "national_projects.singular_title" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "nationalProjectName" }}</div>
        </div>
    }

    <!--AUX DIRECTION-->
    @if (dataEntryDetails().kpiType === constants.KPI_TYPE.NTKPI) {
        <div class="row mb-1">
            <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                {{ "directions.sub_pillar" | translate }}
            </div>
            <div class="col-sm-8 col-xs-12">{{ dataEntryDetails() | translateToken: "auxDirectionName" }}</div>
        </div>
    }
</div>
