/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslatePipe } from "@ngx-translate/core";
import { BaseChartDirective } from "ng2-charts";
import { map } from "rxjs";

import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../constants/constants";
import { GovKpiVisionModelType } from "../../../../models";
import { CalculationEngineApiService, ChartsService } from "../../../../services";
import { ChartActionButtonsComponent } from "../../../chart-action-buttons/chart-action-buttons.component";

@Component({
  selector: "adaa-kpi-vision-chart",
  imports: [BaseChartDirective, TranslatePipe, ChartActionButtonsComponent],
  templateUrl: "./kpi-vision-chart.component.html",
  styleUrl: "./kpi-vision-chart.component.scss",
})
export class KpiVisionChartComponent implements OnInit {
  readonly modal = inject(NgbActiveModal);
  private readonly _chartService = inject(ChartsService);
  private readonly _calcEngineApiService = inject(CalculationEngineApiService);

  readonly periodId = signal<number | undefined>(undefined);
  readonly govDirId = signal<number | undefined>(undefined);
  readonly kpiType = signal<number | undefined>(undefined);
  readonly subGovDirId = signal<number | undefined>(undefined);
  readonly chartName = signal<string | undefined>(undefined);
  readonly data = signal<GovKpiVisionModelType[]>([]);

  readonly chartLabels = computed(() =>
    this.data().map((data) => {
      const label = AdaaHelper.getItemValueByToken(data, "name");
      if (!label) return "";
      return label;
    })
  );
  chartDataset = computed(() => {
    const barChartData = this.data().map((data) => data.score ?? 0);
    const barChartColors = this.data().map((data) => {
      const label = AdaaHelper.getItemValueByToken(data, "name");
      if (!label) {
        return this.chartDataColor(Constants.CONSTANT_COLORS.EVALUATION_GRAY);
      }
      return this.chartDataColor(data.scoreColor);
    });

    return [
      {
        data: barChartData,
        backgroundColor: barChartColors,
        order: 1,
        tension: 0.5,
      },
    ];
  });
  readonly chartOptions = computed(() => {
    this._chartService.initBarChartOptions({
      title: "",
      cutlabels: true,
    });

    return {
      ...this._chartService.barChartOptions,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        datalabels: {
          align: "end",
          anchor: "center",
          textAlign: "center",
          color: "#000",
          font: () => {
            return {
              family: `Open Sans, NotoKufiArabic`,
              size: 12,
            };
          },
          formatter: (value: number, context: any) => {
            if (value === 0) return "";
            if (context.dataset.type === "line") {
              return context.dataIndex + 1 === context.dataset.data.length ? AdaaHelper.roundValue(value, 0) + "%" : "";
            }
            return AdaaHelper.roundValue(value, 0) + "%";
          },
        } as any,
      },
    };
  });

  readonly chartType = "bar";

  readonly chartDataColor = (code: number) => {
    return Constants.COLOR_CODES[code];
  };

  readonly #fetchData = () => {
    const periodId = this.periodId()!;
    const govDirId = this.govDirId()!;
    const kpiType = this.kpiType()!;
    const subGovDirId = this.subGovDirId();

    this._calcEngineApiService
      .getKpiVisionData({
        periodId,
        govDirId,
        kpiType,
        subGovDirId,
      })
      .pipe(map((res) => res.responseData))
      .subscribe({
        next: (data) => {
          this.data.set(data ?? []);
        },
      });
  };

  public ngOnInit() {
    this.#fetchData();
  }
}
