import { AdaaHelper } from "../../../../../../../core/utils";
import { DataEntryMetricResponse } from "../../../../../../models";
import { SharedFields } from "../../configs";
import { ScreenSectionConfigType, ScreenType } from "../../wf-difference.types";
import { metricTypeOptions } from "../../wf-difference.utils";

const metricTypeField: ScreenSectionConfigType = {
  view: "normal",
  field: "metricType",
  label: "metrics.label_type",
  format: (data: { metricType: string }) => {
    if (!AdaaHelper.isDefined(data?.metricType)) return "";
    const item = metricTypeOptions.find(({ value }) => value === data?.metricType);
    return item?.text;
  },
};

const metricActualField: ScreenSectionConfigType = {
  view: "normal",
  field: "actual",
  overrideLabelDir: true,
  label: (data: { newData: DataEntryMetricResponse }) => `${data?.newData?.month} / ${data?.newData?.year}`,
};

export const commonMetricValueScreenConfig = {
  type: "metric-actual-value" as ScreenType,
  sections: [...SharedFields.name, ...SharedFields.description, metricTypeField, metricActualField],
};
