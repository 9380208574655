import { NgClass } from "@angular/common";
import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { AdaaBoolean, PageMode } from "../../../constants/enums";
import {
  FormTab,
  MetricsDetailModelType,
  MonitoredKpiModelType,
  ScopeMainKpi,
  TableSelectedRow,
  ValueText,
} from "../../../models";
import { CyclesApiService, GovScoresScopesApiService, KpisApiService, PropertiesService } from "../../../services";
import { DimensionsEditorComponent } from "../../dimensions-editor/dimensions-editor.component";
import { FormDropdownComponent, FormInputComponent, FormStepsComponent } from "../../form";
import { DataTableComponent } from "../../table/data-table/data-table.component";
import { LinksTabComponent } from "./links-tab/links-tab.component";

@Component({
  selector: "adaa-scope-kpi-modal",
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    ReactiveFormsModule,
    FormStepsComponent,
    FormDropdownComponent,
    FormInputComponent,
    DataTableComponent,
    LinksTabComponent,
    DimensionsEditorComponent,
  ],
  templateUrl: "./scope-kpi-modal.component.html",
  styleUrl: "./scope-kpi-modal.component.scss",
})
export class ScopeKpiModalComponent implements OnInit {
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _formBuilder = inject(FormBuilder);
  private _govScoresScopesApiService = inject(GovScoresScopesApiService);
  private _kpisApiService = inject(KpisApiService);
  private _cyclesApiService = inject(CyclesApiService);
  private _propertiesService = inject(PropertiesService);

  modal = inject(NgbActiveModal);

  @Input() kpi: ScopeMainKpi;
  @Input() entityId: number;
  @Input() pageMode: PageMode;
  @Input() ytpOptions: ValueText[] = [];

  readonly CONSTANT_SUM = 28;
  private _kpisCopy = signal<MonitoredKpiModelType[]>([]);

  kpiTypes = signal<ValueText[]>([]);
  cycles = signal<ValueText[]>([]);
  kpis = signal<ValueText[]>([]);
  ytps = signal<ValueText[]>([]);
  activeTab = signal<number>(0);
  selectedMetric = signal<MetricsDetailModelType | undefined>(undefined);
  showMetricsList = signal<boolean>(false);
  kpiForm: FormGroup;
  PageMode = PageMode;
  constants = Constants;
  adaaHelper = AdaaHelper;
  tabs = signal<FormTab[]>([
    {
      title: "common.form.label.information",
    },
    {
      title: "kpi.formula",
    },
    {
      title: "breadcrumbs.dimensions",
    },
    {
      title: "common.links",
    },
  ]);

  private get _isMaxRankOrTextMapping(): boolean {
    const unit = this.kpiForm?.get("measurementUnit")?.value;
    return (
      unit === Constants.MEASUREMENT.CONSTANT_MEASUREMENT_RANK ||
      unit === Constants.MEASUREMENT.CONSTANT_MEASUREMENT_TEXT_MAPPING
    );
  }

  private get _metricsValid(): boolean {
    let isValid = true;

    const metrics = this.kpiForm.get("metrics")?.value;

    isValid =
      metrics.findIndex((e: { metricType: string }) => e.metricType !== Constants.METRICS_TYPE.DIMENSION) === -1;

    return isValid;
  }

  public get displayLabel(): string {
    let nameEN = this.kpiForm.get("nameEN")?.value;
    let nameAE = this.kpiForm.get("nameAE")?.value;

    if (!AdaaHelper.isDefinedAndNotEmpty(nameAE) && !AdaaHelper.isDefinedAndNotEmpty(nameAE)) return "";

    const kpiTypeNameAE = this.kpiForm.get("kpiTypeNameAE")?.value;
    const kpiTypeNameEN = this.kpiForm.get("kpiTypeNameEN")?.value;
    const refCode = this.kpiForm.get("refCode")?.value;

    nameEN = `${nameEN} [${refCode} | ${kpiTypeNameEN}]`;
    nameAE = `${nameAE} [${refCode} | ${kpiTypeNameAE}]`;

    return AdaaHelper.getPageLabel(nameAE, nameEN);
  }

  public get showYTP(): boolean {
    return this.kpiForm.get("frequency")?.value < Constants.FREQUENCY.ANNUAL;
  }

  public get isViewMode(): boolean {
    return this.pageMode === PageMode.view;
  }

  public get isCrossCycleKpi(): boolean {
    const kpiType = this.kpiForm.get("mainKpiType")?.value;
    return (
      kpiType === Constants.KPI_TYPE.NTKPI ||
      kpiType === Constants.KPI_TYPE.DTKPI ||
      kpiType === Constants.KPI_TYPE.MTKPI ||
      kpiType === Constants.KPI_TYPE.MOKPI
    );
  }

  public ngOnInit(): void {
    this.kpiForm = this._prepareForm();

    this.kpiForm.patchValue(this.kpi);

    this._initPage();
    this.fetchKpis();

    if (this.pageMode === PageMode.edit && this.kpi?.id) {
      this._getMetricsReading(this.kpi.id);
      this._setYtps();
    }
  }

  public onKpiChange(event: number): void {
    const kpi = this._kpisCopy().find((e) => e.id === event);

    this.kpiForm.get("nameAE")?.setValue(kpi ? kpi.nameAE : null);
    this.kpiForm.get("nameEN")?.setValue(kpi ? kpi.nameEN : null);
    this.kpiForm.get("kpiType")?.setValue(kpi ? kpi.kpiType : null);
    this.kpiForm.get("kpiTypeNameAE")?.setValue(kpi ? kpi.kpiTypeAE : null);
    this.kpiForm.get("kpiTypeNameEN")?.setValue(kpi ? kpi.kpiTypeEN : null);
    this.kpiForm.get("frequency")?.setValue(kpi ? kpi.frequency : null);
    this.kpiForm.get("measurementUnit")?.setValue(kpi ? kpi.measurementUnit : null);
    this.kpiForm.get("refCode")?.setValue(kpi ? kpi.refCode : null);

    //If Kpi Selected move to next tab
    if (kpi) {
      this.activeTab.set(1);
      this._getKpiMetrics(kpi.id);
      this._setYtps();
    }
  }

  public onYtpChange(event: number, metricId: number): void {
    const metrics = this.kpiForm.get("metrics")?.value;
    if (!metrics) return;

    const metric = metrics.find((e: { id: number }) => e.id === metricId);
    if (metric) metric.ytpCalc = event;

    this.kpiForm.get("metrics")?.setValue(metrics);
  }

  public onSearch(term: string | undefined): void {
    if (typeof term === "string" && !term.length) term = undefined;
    this.fetchKpis(term);
  }

  public metricSelected(event: TableSelectedRow): void {
    const id = this.kpiForm.get("id")?.value;
    const metrics = this.kpiForm.get("metrics")?.value;
    const metricsCopy = this.kpiForm.get("metricsCopy")?.value;

    if (!metrics) return;

    const metric = metrics.find((e: { id: number }) => e.id === this.selectedMetric()?.id);
    if (metric) {
      metric.dscAE = event.item.dscAE;
      metric.dscEN = event.item.dscEN;
      metric.metricType = event.item.type;
      metric.metricId = event.item.id;
      metric.dataType = event.item.dataType;

      if (id) {
        const isChanged = metricsCopy.some((e: { metricId: number }) => e.metricId !== metric.metricId);
        this.kpiForm.get("hasChanged")?.setValue(isChanged ? AdaaBoolean.Y : AdaaBoolean.N);
      }
    }

    this.kpiForm.get("metrics")?.setValue(metrics);

    this.selectedMetric.set(undefined);
    this.showMetricsList.set(false);
  }

  public mainKpiTypeChanged(_event: number): void {
    this.isCrossCycleKpi
      ? this.kpiForm.get("mainPlanId")?.setValue(undefined)
      : this.kpiForm.get("mainPlanId")?.setValue(237); //2023-2026

    this.kpiForm.get("id")?.setValue(null);

    this.fetchKpis();
  }

  public mainPlanIdChanged(_event: number): void {
    this.kpiForm.get("id")?.setValue(null);

    this.fetchKpis();
  }

  public fetchKpis(searchTerm?: string): void {
    if (!AdaaHelper.isDefined(this.entityId)) return;

    const ids = [];
    if (this.kpiForm.get("id")?.value) ids.push(this.kpiForm.get("id")?.value);

    this._govScoresScopesApiService
      .getKpiByEntityId({
        entityId: this.entityId,
        limit: 30,
        searchTerm,
        ids: ids,
        planId: this.kpiForm.get("mainPlanId")?.value,
        kpiType: this.kpiForm.get("mainKpiType")?.value,
      })
      .subscribe({
        next: (response) => {
          if (response.inError) {
            this.kpis.set([]);
            this._kpisCopy.set([]);
            return;
          }

          this._kpisCopy.set(AdaaHelper.clone(response.responseData));

          response.responseData.forEach((e) => {
            e.nameEN = `[${e.refCode}] ${e.nameEN}`;
            e.nameAE = `[${e.refCode}] ${e.nameAE}`;
          });
          this.kpis.set(AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name")));
        },
      });
  }

  public save(): void {
    this.tabs.update((tabs) => {
      tabs.forEach((e) => (e.hasError = false));
      return tabs;
    });

    if (!this.kpiForm.valid) {
      this.tabs.update((tabs) => {
        tabs[0].hasError = true;
        return tabs;
      });
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    //All metrics must be MONITORING
    if (!this._metricsValid) {
      this.tabs.update((tabs) => {
        tabs[1].hasError = true;
        return tabs;
      });
      this._toastrService.warning(this._translateService.instant("government_scores.scopes.monitoring_metrics_error"));
      return;
    }

    this.modal.close(this.kpiForm.getRawValue());
  }

  private _prepareForm() {
    const isViewMode = this.pageMode === PageMode.view;
    const isEditMode = this.pageMode === PageMode.edit;

    return this._formBuilder.group({
      kpiType: { value: null, disabled: isViewMode },
      kpiTypeNameAE: { value: null, disabled: isViewMode },
      kpiTypeNameEN: { value: null, disabled: isViewMode },
      nameEN: { value: null, disabled: isViewMode },
      nameAE: { value: null, disabled: isViewMode },
      refCode: { value: null, disabled: isViewMode },
      status: { value: null, disabled: isViewMode },
      planId: { value: null, disabled: isViewMode },
      setToDraft: { value: false, disabled: isViewMode },
      newScopeId: { value: null, disabled: isViewMode },
      govScoresScopeSubKpiList: { value: null, disabled: isViewMode },
      govScoreScopeMetricsList: { value: null, disabled: isViewMode },
      govScoresScopeDimensionList: { value: null, disabled: isViewMode },
      govMainKpiProjectsList: { value: null, disabled: isViewMode },
      metrics: { value: null, disabled: isViewMode },
      frequency: { value: null, disabled: isViewMode },
      measurementUnit: { value: null, disabled: isViewMode },
      id: [{ value: null, disabled: isViewMode || isEditMode }, Validators.required],
      dimensions: { value: null, disabled: isViewMode },
      mainKpiType: { value: Constants.KPI_TYPE.SKPI, disabled: isViewMode || isEditMode },
      mainPlanId: { value: 237, disabled: isViewMode || isEditMode }, //2023-2026
      hasChanged: { value: null, disabled: isViewMode },

      //ONLY FE
      fakeId: { value: null, disabled: isViewMode },
      metricsCopy: { value: null, disabled: isViewMode },
    });
  }

  private _getKpiMetrics(id: number): void {
    this._kpisApiService.getById({ id: id }).subscribe({
      next: (response) => {
        if (response.inError) return;
        this.kpiForm.get("metrics")?.setValue(response.responseData.metrics);
      },
    });
  }

  //On Edit Mode get the metrics reading and update the metrics value
  private _getMetricsReading(id: number): void {
    this._kpisApiService.getById({ id: id }).subscribe({
      next: (response) => {
        if (response.inError) return;
        const kpiMetrics = this.kpiForm.get("metrics")?.value ?? [];

        kpiMetrics.forEach((m: { id: number; metricReadings: unknown }) => {
          const metric = response.responseData.metrics.find((e) => e.id === m.id);
          if (metric) m.metricReadings = metric.metricReadings;
        });

        this.kpiForm.get("metrics")?.setValue(kpiMetrics);
      },
    });
  }

  private _setYtps(): void {
    if (this._isMaxRankOrTextMapping) this.ytps.set(this.ytpOptions.filter((e) => e.value !== this.CONSTANT_SUM));
    else this.ytps.set(this.ytpOptions);
  }

  private _initPage(): void {
    const itemIds: number[] = [
      Constants.KPI_TYPE.DTKPI,
      Constants.KPI_TYPE.MOKPI,
      Constants.KPI_TYPE.MTKPI,
      Constants.KPI_TYPE.NTKPI,
      Constants.KPI_TYPE.OPM,
      Constants.KPI_TYPE.SKPI,
      Constants.KPI_TYPE.SRVKPI,
    ];
    forkJoin({
      cycles: this._cyclesApiService.getAll(),
      itemTypes: this._propertiesService.getAllItemTypes(),
    }).subscribe({
      next: (response) => {
        if (!response.cycles.inError)
          this.cycles.set(
            AdaaHelper.setDropdownArray(response.cycles.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!response.itemTypes.inError)
          this.kpiTypes.set(
            AdaaHelper.setDropdownArray(
              response.itemTypes.responseData.filter((e) => itemIds.includes(e.id)),
              "id",
              AdaaHelper.getFieldLanguage("name")
            )
          );
      },
    });
  }
}
