import { inject, Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { filter, map, type Observable, switchMap } from "rxjs";

import { AdaaHelper } from "../../core/utils";

@Injectable({
  providedIn: "root",
})
export class AppTitleService {
  private _translateService = inject(TranslateService);
  private _activatedRoute = inject(ActivatedRoute);
  private _titleService = inject(Title);

  public changeTitle(trigger$: Observable<unknown>): void {
    trigger$
      .pipe(
        map(this._getRoute.bind(this)),
        filter((route) => route.outlet === "primary"),
        switchMap(this._getPageTitle.bind(this))
      )
      .subscribe(this._updatePageTitle.bind(this));
  }

  public setTitle(title: string, translate = false): void {
    if (!translate) {
      this._titleService.setTitle(`Adaa 3.0 - ${title}`);
      return;
    }

    const text = this._translateService.instant(title);
    this._titleService.setTitle(`Adaa 3.0 - ${text}`);
  }

  private _getRoute() {
    let route = this._activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  private _updatePageTitle(title: string) {
    if (!AdaaHelper.isDefinedAndNotEmpty(title)) {
      return this._titleService.setTitle("Adaa 3.0");
    }

    this._translateService.get(title).subscribe({
      next: this.setTitle.bind(this),
    });
  }

  private _getPageTitle(route: ActivatedRoute) {
    return route.data.pipe(
      filter((data) => "title" in data),
      map((data) => {
        if (data["title"]) {
          return data["title"];
        } else {
          const url = new URL(window.location.href);
          url.pathname = ["/", route.snapshot.url.join("/")].join("");

          return url.pathname.split("/").reduce((acc, frag) => {
            if (acc && frag) {
              acc += " - ";
            }
            return acc + this._ucFirst(frag);
          });
        }
      })
    );
  }

  private _ucFirst(value: string): string {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
