import { NgClass } from "@angular/common";
import { Component, computed, effect, EventEmitter, inject, input, Output, signal } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faCheckCircle,
  faClock,
  faFileCircleCheck,
  faFileCircleExclamation,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { AdaaBoolean } from "../../../constants/enums";
import { ItemApiService, LanguageService } from "../../../services";
import { isOpm, isSrvkpi } from "../../modals";

type ObjectDataStatusModelType = {
  wfProcessCtlId?: number;
  audited: AdaaBoolean;
  dataStatus?: string;
  entityId: number;
  status: number;
  type: number;
  info: number;
  id: number;
};

@Component({
  selector: "adaa-object-data-status",
  styleUrl: "../common.scss",
  templateUrl: "./object-data-status.component.html",
  standalone: true,
  imports: [NgClass, FontAwesomeModule, TranslateModule],
})
export class ObjectDataStatusComponent {
  readonly _languageService = inject(LanguageService);
  private readonly _itemApiService = inject(ItemApiService);

  periodId = input<number>();
  entityId = input<number>();
  objectType = input.required<number>();
  itemId = input.required<number>();

  @Output() getObjectDataStatus = new EventEmitter<never>();

  data = signal<ObjectDataStatusModelType | undefined>(undefined);

  auditedStatus = computed(() => {
    const canShowAuditedStatus =
      AdaaHelper.isObjectKPI(this.objectType()) &&
      this.data()?.audited &&
      !isOpm(this.objectType()) &&
      !isSrvkpi(this.objectType());

    if (!canShowAuditedStatus) return;

    const isAudited = this.data()?.audited === AdaaBoolean.Y;

    return {
      isAudited,
      label: `kpi.card.${!isAudited ? "not_" : ""}audited`,
    };
  });
  infoStatus = computed(() => {
    if (this.data()?.info === Constants.OBJECT_STATUS.ACTIVE) return "active";
    if (this.data()?.info === Constants.OBJECT_STATUS.DRAFT) return "draft";
    return;
  });
  workflowStatus = computed(() => {
    const isObjectKpi = AdaaHelper.isObjectKPI(this.objectType());

    if (AdaaHelper.isDefined(this.data()?.wfProcessCtlId)) return `status.${isObjectKpi ? "kpi4" : "in_review"}`;
    if (this.data()?.status === Constants.OBJECT_STATUS.ACTIVE) return `status.${isObjectKpi ? "kpi1" : "A"}`;
    return `status.${isObjectKpi ? "kpi3" : "D"}`;
  });

  readonly icon = {
    warn: faWarning,
    pending: faClock,
    ok: faCheckCircle,
    audited: faFileCircleCheck,
    notAudited: faFileCircleExclamation,
  };

  #fetchDataStatus = effect(() => {
    const useV2 = this.objectType() === Constants.CONSTANT_OBJECTIVETYPE;
    let kpiType: number | undefined;
    if (AdaaHelper.isObjectKPI(this.objectType())) {
      kpiType = this.objectType();
    }

    this._itemApiService
      .getItemByIdAndTypeAndPeriod<ObjectDataStatusModelType>({
        useV2,
        kpiType,
        periodId: this.periodId(),
        itemId: this.itemId(),
        itemType: this.objectType(),
      })
      .subscribe({
        next: (res) => {
          this.data.set(res.responseData);
          this.getObjectDataStatus.emit(res.responseData as never);
        },
      });
  });
}
