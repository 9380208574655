import { CommonModule } from "@angular/common";
import { Component, EventEmitter, input, Output } from "@angular/core";
import { NgbPopover, NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { Constants } from "../../../constants/constants";
import { TpAgmProject } from "../../../models";
import { PictureComponent, ProgressIndicatorComponent } from "../../general";

@Component({
  selector: "adaa-tp-bars",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgbPopoverModule,
    TranslateTokenPipe,
    ProgressIndicatorComponent,
    PictureComponent,
  ],
  templateUrl: "./tp-bars.component.html",
  styleUrl: "./tp-bars.component.scss",
})
export class TpBarsComponent {
  projects = input.required<TpAgmProject[]>();
  showTargets = input<boolean>(true);
  @Output() barClicked = new EventEmitter<number>();

  constants = Constants;
  tooltipIndex: number = 0;

  public onBarHover(popover: NgbPopover, project: TpAgmProject) {
    this.tooltipIndex = project.id;

    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({ project });
    }
  }

  public onBarLeave(popover: NgbPopover) {
    this.tooltipIndex = 0;
    if (popover.isOpen()) {
      popover.close();
    }
  }
}
