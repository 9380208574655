import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import { type MainResponseModel, type PeriodModelType } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class PeriodApiService {
  private _mainService = inject(MainService);

  public getDateRange(args: { startTS: number; endTS: number; frequency: number; useLoader?: boolean }) {
    const url = `period/getbyDateInterval/${args?.startTS}/${args?.endTS}/${args?.frequency}`;

    return this._mainService
      .sendRequest<MainResponseModel<PeriodModelType[]>>({
        method: "GET",
        url,
        loader: args?.useLoader,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getActualPeriod({ useLoader }: { useLoader?: boolean }) {
    const url = "period/getActualPeriod";

    return this._mainService
      .sendRequest<MainResponseModel<PeriodModelType>>({
        method: "GET",
        url,
        loader: useLoader,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getById(id: number) {
    const url = `period/getbyid/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<PeriodModelType>>({
        method: "GET",
        url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getAll({ useLoader }: { useLoader: boolean }) {
    const url = "period/getall";

    return this._mainService
      .sendRequest<MainResponseModel<PeriodModelType[]>>({
        method: "GET",
        url,
        loader: useLoader,
      })
      .pipe(filter((res) => !res.inError));
  }
}
