import { inject, Injectable } from "@angular/core";

import { AdaaHelper } from "../../core/utils";
import { TpAgmStatus } from "../constants/enums";
import {
  EntityModelType,
  GovSectorEntity,
  type InitiativeModelType,
  ItemModel,
  MainResponseModel,
  TpAgmDashboardFilter,
  TpAgmYearResponse,
  TpDashboardResponse,
  TpDetailedSectorsResponse,
  TpMainSectorsResponse,
  TpOwnerResponse,
  TpProjectDetails,
  UserModelType,
} from "../models";
import { AgreementDetails, GenerateAgreement, PublishedProjects } from "../models/tp-agreements.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class TpApiService {
  private _mainService = inject(MainService);

  public getTpDashboard(filter: TpAgmDashboardFilter) {
    const url = `national-project/values`;

    const qs = new URLSearchParams();
    if (filter.year) qs.append("year", filter.year.toString());
    if (filter.cycleId) qs.append("cycleId", filter.cycleId.toString());
    if (filter.entityId) qs.append("entityId", filter.entityId.toString());
    if (filter.sectorId) qs.append("nationalSector", filter.sectorId.toString());
    if (filter.mainSectorId) qs.append("mainNationalSector", filter.mainSectorId.toString());
    if (filter.ownerId) filter.ownerId.forEach((e) => qs.append("ownerId", e.toString()));
    if (filter.onlyProjectsOwnedByEntity) qs.append("useOwnerEntity", "true");

    if (filter.status === TpAgmStatus.COMPLETED) {
      qs.append("status", "COMPLETED");
      qs.append("sortKey", "progress");
      qs.append("sortDirection", "desc");
      qs.append("groupedSort", "true");
    } else if (filter.status === TpAgmStatus.ON_TIME) {
      qs.append("status", "ON_GOING");
      qs.append("sortKey", "progress");
      qs.append("sortDirection", "desc");
      qs.append("groupedSort", "true");
    } else if (filter.status === TpAgmStatus.DELAYED) {
      qs.append("status", "DELAYED");
      qs.append("sortKey", "progress");
      qs.append("sortDirection", "desc");
      qs.append("groupedSort", "true");
    } else if (filter.status === TpAgmStatus.MOST_COMPLETED) {
      qs.append("status", "COMPLETED");
      qs.append("status", "ON_GOING");
      qs.append("sortKey", "progress");
      qs.append("sortDirection", "desc");
      qs.append("groupedSort", "false");
    } else if (filter.status === TpAgmStatus.MOST_DELAYED) {
      qs.append("status", "DELAYED");
      qs.append("sortKey", "progress");
      qs.append("sortDirection", "asc");
      qs.append("groupedSort", "false");
    } else if (filter.status === TpAgmStatus.BEST_VISION) {
      qs.append("sortKey", "visionScore");
      qs.append("sortDirection", "desc");
      qs.append("groupedSort", "false");
      qs.append("visionLowLimit", "65.00000001");
      qs.append("visionHighLimit", "100");
    } else if (filter.status === TpAgmStatus.LOWEST_VISION) {
      qs.append("sortKey", "visionScore");
      qs.append("sortDirection", "asc");
      qs.append("groupedSort", "false");
      qs.append("visionLowLimit", "0");
      qs.append("visionHighLimit", "65.00000001");
    } else if (filter.filterBy === "progerss") {
      qs.append("sortKey", "progress");
      qs.append("groupedSort", "false");
      qs.append("sortDirection", "desc");
    } else if (filter.filterBy === "achievement") {
      qs.append("sortKey", "visionScore");
      qs.append("sortDirection", "desc");
      qs.append("groupedSort", "false");
    }

    return this._mainService.sendRequest<MainResponseModel<TpDashboardResponse>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getTpMainSectors(cycleId: number | undefined) {
    const url = `national-project/count-by-main-sector`;

    const qs = new URLSearchParams();
    if (cycleId) qs.append("cycleId", cycleId.toString());

    return this._mainService.sendRequest<MainResponseModel<TpMainSectorsResponse>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getTpSectors(cycleId: number | undefined) {
    const url = `national-project/count-by-sector`;

    const qs = new URLSearchParams();
    if (cycleId) qs.append("cycleId", cycleId.toString());

    return this._mainService.sendRequest<MainResponseModel<TpDetailedSectorsResponse>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getTpOwners(cycleId: number | undefined) {
    const url = `national-project/values/owners`;

    const qs = new URLSearchParams();
    if (cycleId) qs.append("cycleId", cycleId.toString());

    return this._mainService.sendRequest<MainResponseModel<TpOwnerResponse[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getProjectEntities() {
    const url = `national-project/entities`;

    return this._mainService.sendRequest<MainResponseModel<EntityModelType[]>>({
      method: "GET",
      url,
    });
  }

  public getNationalProjectsByEntity(entityId?: number) {
    const url = `national-project/getAllByEntity`;
    const qs = new URLSearchParams();
    if (entityId) qs.append("entityId", `${entityId}`);

    return this._mainService.sendRequest<MainResponseModel<TpProjectDetails[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getProjectYears() {
    const url = `national-project/years`;

    return this._mainService.sendRequest<MainResponseModel<TpAgmYearResponse>>({
      method: "GET",
      url,
    });
  }

  public getProjectValues(id: number) {
    const url = `national-project/values/${id}`;

    return this._mainService.sendRequest<MainResponseModel<TpProjectDetails>>({
      method: "GET",
      url,
    });
  }

  public getStrategicProjects(entityId: number) {
    const url = `national-project/getStrategicProjects?entityId=${entityId}`;

    return this._mainService.sendRequest<MainResponseModel<InitiativeModelType[]>>({
      method: "GET",
      url,
    });
  }

  public getAllByTransformationalTarget(targetId: number) {
    const url = `national-project/getAllByTransformationalTarget?transformationalTargetId=${targetId}`;

    return this._mainService.sendRequest<MainResponseModel<Partial<ItemModel>[]>>({
      method: "POST",
      url,
      data: {},
    });
  }

  public deleteTp(id: number) {
    const url = `national-project/deleteById/${id}`;

    return this._mainService.sendRequest<MainResponseModel>({
      method: "DELETE",
      url,
    });
  }

  public readyToPublish(id: number, readyPublish: boolean) {
    const url = `national-project/ready-publish/${id}`;

    return this._mainService.sendRequest<MainResponseModel>({
      method: "POST",
      url,
      data: {
        readyPublish: readyPublish,
      },
    });
  }

  public cancelUncancelProject(id: number, status: "cancel" | "uncancel") {
    const url = `national-project/${status}/${id}`;

    return this._mainService.sendRequest<MainResponseModel>({
      method: "POST",
      url,
      data: {},
    });
  }

  public getAllRelatedByEntity(entityId?: number) {
    const url = `national-project/getAllRelatedByEntity`;
    const qs = new URLSearchParams();
    if (entityId) qs.append("entityId", `${entityId}`);

    return this._mainService.sendRequest<MainResponseModel<TpProjectDetails[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getProject(id: number) {
    const url = `national-project/getById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<TpProjectDetails>>({
      method: "GET",
      url,
    });
  }

  public generateAgreement(data: GenerateAgreement) {
    const url = `national-project/generateAgreement`;

    return this._mainService.sendRequest<MainResponseModel<AgreementDetails>>({
      method: "POST",
      url,
      data,
    });
  }

  public getReadyPublishEntities() {
    const url = `national-project/getReadyPublishEntities`;

    return this._mainService.sendRequest<MainResponseModel<GovSectorEntity[]>>({
      method: "GET",
      url,
    });
  }

  public getReadyPublishProjects(entityId: number) {
    const url = `national-project/getReadyPublishProjects?entityId=${entityId}`;

    return this._mainService.sendRequest<MainResponseModel<PublishedProjects[]>>({
      method: "GET",
      url,
    });
  }

  public publishAgreement(id: number) {
    const url = `national-project/publishAgreement/${id}`;

    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "POST",
      url,
      data: {},
    });
  }

  public deleteAgreement(id: number) {
    const url = `national-project/deleteAgreement/${id}`;

    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "DELETE",
      url,
    });
  }

  public saveProject(data: TpProjectDetails, isFinish: boolean) {
    let url = data.id ? "national-project/update" : "national-project/create";
    url += `?isFinish=${isFinish}`;

    const method = data.id ? "PUT" : "POST";

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method,
      url,
      data,
    });
  }

  public getAgreementFilterUsers() {
    const url = `national-project/getAgreementFilterUsers`;

    return this._mainService.sendRequest<MainResponseModel<UserModelType[]>>({
      method: "GET",
      url,
    });
  }

  public getAllForMainOutcomes({ kpiId, entityId }: { kpiId?: number; entityId: number }) {
    const url = `national-project/getAllForMainOutcomes`;

    const qs = new URLSearchParams();
    qs.set("entityId", `${entityId}`);
    if (AdaaHelper.isDefined(kpiId)) qs.set("kpiId", `${kpiId}`);

    return this._mainService.sendRequest<MainResponseModel<TpProjectDetails[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }
}
