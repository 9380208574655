import { inject, Injectable } from "@angular/core";

import type {
  ExtendedUserModelType,
  KpiAnnualWeightsModelType,
  MainResponseModel,
  SearchFields,
  WorkflowConfig,
  WorkflowDecisionModelType,
  WorkflowDiffInputType,
  WorkflowHistoryModelType,
  WorkflowItemDiffModelType,
  WorkflowMonitoringItemModelType,
  WorkflowProcessCtl,
  WorkflowTrigger,
  WorkflowTriggerItemType,
  WorkflowTriggerRuleConfigurationData,
  WorklowTriggerFieldType,
  WorklowTriggerRuleTemplate,
  WorklowTriggerRuleType,
} from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class WorkflowsApiService {
  private _mainService = inject(MainService);

  public getAllMonitoring(limit = 10, offset = 0, filters?: SearchFields) {
    const url = `wftrigger/getAllMonitoring?limit=${limit}&offset=${offset}`;

    return this._mainService.sendRequest<MainResponseModel<WorkflowMonitoringItemModelType[]>>({
      method: "POST",
      url,
      data: filters,
    });
  }

  public getAllWorkflowPendingApprovers(wfProcessctlId: number) {
    const url = `wftrigger/getPendingById/${wfProcessctlId}`;

    return this._mainService.sendRequest<MainResponseModel<ExtendedUserModelType[]>>({
      method: "GET",
      url,
    });
  }

  public getWorkflowHistory(itemId: number, itemTypeId: number) {
    const url = "workflow/getWorkflowHistory";

    const qs = new URLSearchParams();
    qs.append("itemId", `${itemId}`);
    qs.append("itemType", `${itemTypeId}`);

    return this._mainService.sendRequest<MainResponseModel<WorkflowHistoryModelType[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getWorkflowDifferences(input: WorkflowDiffInputType) {
    const url = "workflow/getItemForReview";

    const qs = new URLSearchParams();
    qs.append("itemId", `${input.itemId}`);
    qs.append("itemType", `${input.itemTypeId}`);

    if (input.readingType) qs.append("readingType", `${input.readingType}`);
    if (input.wfActionType) qs.append("wfActionType", `${input.wfActionType}`);
    if (input.wfProcessCtlId) qs.append("wfProcessCtlId", `${input.wfProcessCtlId}`);

    return this._mainService.sendRequest<MainResponseModel<WorkflowItemDiffModelType>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public submitWorkflowDecision(data: WorkflowDecisionModelType) {
    const url = "workflow/decision";

    return this._mainService.sendRequest<MainResponseModel<string>>({
      method: "POST",
      url,
      data,
    });
  }

  public createWorkflowConfig(data: WorkflowConfig) {
    const url = `wfconfig/create?isFinish=false`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public updateWorkflowConfig(data: WorkflowConfig) {
    const url = `wfconfig/update?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public getWorkflowConfig(id: number) {
    const url = `wfconfig/getById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<WorkflowConfig>>({
      method: "GET",
      url,
    });
  }

  public deleteWorkflowConfig(id: number) {
    const url = `wfconfig/deleteById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "DELETE",
      url,
    });
  }

  public createWorkflowTrigger(data: WorkflowTrigger) {
    const url = `wftrigger/create?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public updateWorkflowTrigger(data: WorkflowTrigger) {
    const url = `wftrigger/update?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public getWorkflowTrigger(id: number) {
    const url = `wftrigger/getById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<WorkflowTrigger>>({
      method: "GET",
      url,
    });
  }

  public getWorkflowTriggerRuleTypes() {
    const url = `wftrigger/getRuleTypes`;

    return this._mainService.sendRequest<MainResponseModel<WorklowTriggerRuleType[]>>({
      method: "GET",
      url,
    });
  }

  public getWorkflowTriggerTemplates() {
    const url = `wftrigger/getalltemplates`;

    return this._mainService.sendRequest<MainResponseModel<WorklowTriggerRuleTemplate[]>>({
      method: "GET",
      url,
    });
  }

  public getWorkflowTriggerConfiguration(entityId: number, actionTypeId: number, itemTypeId: number) {
    const url = `wfconfig/getAllByEntityId/?entityId=${entityId}&actionType=${actionTypeId}&itemType=${itemTypeId}`;

    return this._mainService.sendRequest<MainResponseModel<WorkflowTriggerRuleConfigurationData[]>>({
      method: "GET",
      url,
    });
  }

  public getWorkflowTriggerFieldypes(itemType: number = 0) {
    const url = `wftrigger/getFieldsByItemType/?itemType=${itemType}`;

    return this._mainService.sendRequest<MainResponseModel<WorklowTriggerFieldType[]>>({
      method: "GET",
      url,
    });
  }

  public getWorkflowTriggerItemTypes() {
    const url = `wftrigger/getItemTypes`;

    return this._mainService.sendRequest<MainResponseModel<WorkflowTriggerItemType[]>>({
      method: "GET",
      url,
    });
  }

  public deleteWorkflowTrigger(id: number) {
    const url = `wftrigger/deleteById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "GET",
      url,
    });
  }

  public getKpiAnnualWeightsDifferences(wfProcessCtlId: number) {
    const url = `workflow/getKpiAnnualWeightForReview?wfProcessCtlId=${wfProcessCtlId}`;

    return this._mainService.sendRequest<MainResponseModel<{ weights: KpiAnnualWeightsModelType[] }>>({
      method: "GET",
      url,
    });
  }

  public getPreviousWfProcessCtlSteps(wfProcessCtlId: number) {
    const url = `workflow/previousWfProcessCtlSteps?wfProcessCtlId=${wfProcessCtlId}`;

    return this._mainService.sendRequest<MainResponseModel<WorkflowProcessCtl[]>>({
      method: "GET",
      url,
    });
  }

  public getItemForReview(itemType: number, itemId: number, wfActionType: number, wfProcessCtlId: number) {
    const url = `workflow/getItemForReview?itemType=${itemType}&itemId=${itemId}&wfActionType=${wfActionType}&wfProcessCtlId=${wfProcessCtlId}`;

    return this._mainService.sendRequest<MainResponseModel<WorkflowItemDiffModelType>>({
      method: "GET",
      url,
    });
  }
}
