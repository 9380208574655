<div class="row">
    <div class="col-12 mb-5">
        <adaa-form-two-way-select
            [label]="'kpi.contributing_entities' | translate"
            [notSelectedLabel]="'rules_management.entities' | translate"
            [selectedLabel]="'rules_management.selected_entities' | translate"
            [setValidator]="formValidation()"
            [options]="entities()"
            [setDefaultValue]="selectedEntities()"
            [controlName]="entitiesControlName()"
            (inputChanges)="setEntites($event)"
        />
    </div>
    <div class="col-12">
        <adaa-form-two-way-select
            [label]="'other_entities.plural' | translate"
            [notSelectedLabel]="'rules_management.entities' | translate"
            [selectedLabel]="'rules_management.selected_entities' | translate"
            [setValidator]="formValidation()"
            [options]="otherEntities()"
            [setDefaultValue]="selectedOtherEntities()"
            [controlName]="otherEntitiesControlName()"
            (inputChanges)="setOtherEntites($event)"
        />
    </div>
</div>
