@if (actionCategories().length) {
    <div class="w-100 position-relative d-flex justify-content-between action-categories mb-2 z-1">
        <form class="d-block card shadow-none border bg-white rounded px-2" [formGroup]="commentForm" (ngSubmit)="addComment()">
            <h6 class="w-100 fw-bold text-center pt-2 pb-3 d-block text-center">
                {{ label() ?? ("workflow.override_monitored" | translate) }}
            </h6>

            <adaa-form-dropdown
                classes="position-relative mt-1 mb-4"
                controlName="categoryId"
                formControlName="categoryId"
                [label]="'workflow.action_category' | translate"
                [required]="true"
                [clearable]="true"
                [options]="actionCategoryOptions()"
                [invalid]="commentForm.controls['categoryId'].dirty && commentForm.controls['categoryId'].invalid"
            />

            <adaa-form-input
                controlName="comment"
                formControlName="comment"
                [required]="true"
                [isTextArea]="true"
                [rows]="3"
                [label]="'common.form.label.comment' | translate"
                [invalid]="commentForm.controls['comment'].dirty && commentForm.controls['comment'].invalid"
            />

            <div class="d-flex w-100 justify-content-center align-items-center">
                <button type="submit" role="button" class="btn btn-sm btn-primary w-auto my-2">
                    {{ "helpdesk.comment_add" | translate }}
                </button>
            </div>
        </form>

        <div class="d-block py-0">
            <div class="d-block w-100 border rounded category-comments">
                <div class="w-100 d-block bg-primary text-white rounded-top">
                    <span class="fs-6 d-block w-100 text-center py-1">
                        {{ "common.form.label.comments" | translate }}
                    </span>
                </div>

                <div class="w-100 d-flex justify-content-center align-items-start flex-wrap pt-2 pb-1 px-3">
                    @for (comment of decision()?.categorizedComments; track $index) {
                        <div class="card p-2 rounded-1 d-flex align-content-start mb-2 shadow comments">
                            <button
                                class="text-white bg-danger shadow-sm"
                                role="button"
                                type="button"
                                [attr.title]="'tooltips.delete' | translate"
                                [dir]="languageService.direction()"
                                (click)="removeComment($index)"
                            >
                                <fa-icon [icon]="removeIcon" />
                            </button>

                            @if (getCommentCategory(comment.categoryId)) {
                                <span class="badge text-white bg-primary fw-bold d-inline-block">
                                    {{ getCommentCategory(comment.categoryId) | translateToken: "name" }}
                                </span>
                            }

                            <span class="d-block"> {{ comment.comments }} </span>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
} @else {
    <div class="container w-100 d-flex justify-content-center py-3">
        <div class="zsk__loader zsk__loader--sm"></div>
    </div>
}
