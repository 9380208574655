/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePipe } from "@angular/common";
import { Component, EventEmitter, inject, input, OnInit, Output } from "@angular/core";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { Constants } from "../../../constants/constants";
import { LanguageService } from "../../../services";
import { NoteComponent } from "../note/note.component";
import { CommentsEditorModalComponent } from "./comments-editor-modal/comments-editor-modal.component";

@Component({
  selector: "adaa-comments",
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule, NoteComponent, DatePipe, TranslateTokenPipe],
  templateUrl: "./comments.component.html",
  styleUrl: "./comments.component.scss",
})
export class CommentsComponent implements OnInit {
  private _iconLibrary: FaIconLibrary = inject(FaIconLibrary);
  private _modalService = inject(NgbModal);
  private _languageService = inject(LanguageService);

  comments = input.required<any[] | undefined>();
  titleToken = input.required<string>();
  dateToken = input.required<string>();
  messageToken = input.required<string>();
  showNumber = input<boolean>(false);
  cardTitle = input<string | undefined>(undefined);
  classes = input<string>("fw-bold");
  isEditable = input<boolean>(false);
  editCardTitle = input<string>("");
  noCommentsMessage = input<string>("");

  @Output() commentAdded = new EventEmitter<string>();
  @Output() commentRemoved = new EventEmitter<any>();

  constants = Constants;

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faPlus);
  }

  public openAddModal(): void {
    const modal = this._modalService.open(CommentsEditorModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.cardTitle = this.editCardTitle();

    modal.result.then((e) => {
      if (!e) return;

      this.commentAdded.emit(e);
    });
  }
}
