import { Component, inject, input, output, type TemplateRef, viewChild } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbModal, type NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { LanguageService } from "../../../services";
import { FormInputComponent } from "../../form";

@Component({
  selector: "adaa-single-input-form",
  standalone: true,
  imports: [ReactiveFormsModule, FormInputComponent, TranslateModule],
  styleUrl: "./single-input-form.component.scss",
  template: `
    <ng-template #formModal let-modal>
      <div class="modal-header bg-white">
        <div class="w-100 d-flex justify-content-center">
          <h4 class="fw-bold w-100 text-center py-1">{{ title() }}</h4>

          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
        </div>
      </div>

      <div class="modal-body">
        <form [formGroup]="model" class="w-100 p2">
          <adaa-form-input
            controlName="text"
            formControlName="text"
            [invalid]="model.get('text')?.dirty! && model.get('text')?.invalid!"
            [label]="label()"
            [isTextArea]="useTextarea()"
          />
        </form>
      </div>

      <div class="modal-footer d-flex justify-content-center">
        <button
          type="submit"
          class="btn btn-primary px-3"
          [disabled]="!model.get('text')?.value || (model.get('text')?.dirty && model.get('text')?.invalid)"
          (click)="clicked.emit(model.get('text')?.value!); modal.dismiss()"
        >
          <span>{{ "common.form.button.save" | translate }}</span>
        </button>
      </div>
    </ng-template>
  `,
})
export class SingleInputFormComponent {
  private readonly _modalService = inject(NgbModal);
  private readonly _languageService = inject(LanguageService);

  title = input.required<string>();
  label = input<string>("");
  useTextarea = input<boolean>(false);

  clicked = output<string>();

  formModal = viewChild.required<TemplateRef<unknown>>("formModal");

  modalRef: NgbModalRef;

  model = new FormGroup({
    text: new FormControl<string | undefined>(undefined, [Validators.minLength(3)]),
  });

  public open(prefillText?: string) {
    if (prefillText) {
      this.model.get("text")?.setValue(prefillText);
    }

    this.modalRef = this._modalService.open(this.formModal(), {
      animation: true,
      scrollable: false,
      centered: true,
      size: "lg",
      modalDialogClass: this._languageService.modalDirection(),
      beforeDismiss: () => {
        this.model.reset({
          text: undefined,
        });

        return true;
      },
    });
  }
}
