<div class="position-relative sys-layout" [dir]="languageService.direction()">
    <div class="position-relative row d-flex justify-content-between align-items-center bg-white sys-layout--topbar" dir="ltr">
        <div class="col-6 d-flex justify-content-start">
            @if (systemLayoutService.activeEntityLogo()) {
                <img
                    alt="Entity logo"
                    loading="lazy"
                    class="d-block my-0 entity-logo"
                    [dir]="languageService.direction()"
                    [attr.data-img-type]="systemLayoutService.activeEntity()?.logoHorizontalAttach ? 'horizontal' : 'vertical'"
                    [src]="systemLayoutService.activeEntityLogo()"
                />
            }
        </div>

        <div class="col-6 d-flex justify-content-end">
            <img
                class="d-block my-0 adaa-logo"
                alt="logo"
                [src]="languageService.direction() === 'ltr' ? 'assets/images/adaa_logo_sys_layout.png' : 'assets/images/adaa_logo_ae.png'"
                [dir]="languageService.direction()"
            />
        </div>
    </div>

    <adaa-topbar [direction]="languageService.direction()" (toggleSidebar)="toggleSidebar()" />

    <div class="sys-layout--content" [dir]="languageService.direction()">
        <div
            class="sidebar console-heartbeat-slide-right console-heartbeat-slide-right-out"
            [class.full]="sidebarType() === 'full'"
            [class.mini]="sidebarType() === 'mini'"
            [dir]="languageService.direction()"
        >
            <div class="sidebar-header" [dir]="languageService.direction()">
                <button
                    class="btn btn-sm bg-transparent border-0 text-primary position-relative"
                    [dir]="languageService.direction()"
                    (click)="toggleSidebar()"
                >
                    <span [dir]="languageService.direction()"><fa-icon [icon]="icon.menu" size="lg" /></span>
                </button>
            </div>

            <div class="sidebar-navitems">
                @for (item of consoleService.navConsoleItems(); track item) {
                    <a
                        class="sidebar-item d-flex align-items-center"
                        role="menuitem"
                        [dir]="languageService.direction()"
                        [routerLink]="'/' + item.urlRoute"
                        [routerLinkActive]="'active'"
                        [routerLinkActiveOptions]="{ exact: true }"
                        *adaaHasPermission="getPermission(item.permissions)"
                        (click)="sidebarType() === 'full' && !isDesktopDevice() && toggleSidebar()"
                    >
                        <div class="sidebar-item-icon" [class.full]="sidebarType() === 'full'" [dir]="languageService.direction()">
                            @if (
                                item.fieldName === "nationalStrategiesDashboard" ||
                                item.fieldName === "employeesPulse" ||
                                item.fieldName === "executiveDashboard"
                            ) {
                                <i style="font-size: 1.25rem" [class]="'adaa-icon-' + item.iconKey"></i>
                            } @else {
                                <i [class]="'adaa-icon-' + item.iconKey"></i>
                            }
                        </div>

                        @if (sidebarType() === "full") {
                            <div class="sidebar-item-text" [dir]="languageService.direction()">{{ item | translateToken: "name" }}</div>
                        }
                    </a>
                }
            </div>

            <div class="sidebar-footer">
                <adaa-color-legend />
                <p class="text-center text-white">
                    {{ "leftbar.copy_right" | translate }} <br />
                    ADAA © {{ currentYear() }}
                </p>
            </div>
        </div>

        <div class="page" [class.full]="sidebarType() === 'full'" [class.mini]="sidebarType() === 'mini'" [dir]="languageService.direction()">
            <adaa-breadcrumb></adaa-breadcrumb>
            <div class="page-content" [dir]="languageService.direction()">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>

<adaa-spinner></adaa-spinner>
