<div class="modal-header">
    <h4 class="modal-title">
        {{ "helpdesk.changes_justifications" | translate }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body overflow-x-hidden p-3">
    <div class="row">
        @if (priorityChanged) {
            <div class="col-12">
                <adaa-form-input
                    [label]="'helpdesk.priority' | translate"
                    [isTextArea]="true"
                    [required]="true"
                    (inputChanges)="priorityChangeReason.set($event)"
                />
            </div>
        }
        @if (assignedChanged) {
            <div class="col-12">
                <adaa-form-input
                    [label]="'helpdesk.assigned_to' | translate"
                    [isTextArea]="true"
                    [required]="true"
                    (inputChanges)="assignChangeReason.set($event)"
                />
            </div>
        }
        @if (expectedCompletionDateChanged) {
            <div class="col-12">
                <adaa-form-input
                    [label]="'helpdesk.expected_resolution_date' | translate"
                    [isTextArea]="true"
                    [required]="true"
                    (inputChanges)="expectedCompletionDateChangeReason.set($event)"
                />
            </div>
        }
        @if (expectedCompletionDateChanged) {
            <div class="col-12">
                <adaa-form-input
                    [label]="'helpdesk.status' | translate"
                    [isTextArea]="true"
                    [required]="true"
                    (inputChanges)="resolvedChangeReason.set($event)"
                />
            </div>
        }
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ "common.form.button.close" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="submit()">
        {{ "common.form.button.save" | translate }}
    </button>
</div>
