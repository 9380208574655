<div class="card text-bg-light mb-2">
    <div class="card-header">
        <div class="row">
            <div class="col-11">
                @if (number()) {
                    <span class="badge rounded-pill bg-primary text-white shadow-sm mx-1">{{ number() }}</span>
                }
                <strong>{{ title() }}</strong>
                @if (subTitle()) {
                    {{ subTitle() }}
                }
            </div>
            <div class="col-1 d-flex flex-row-reverse">
                @if (isEditable()) {
                    <button type="button" class="btn btn-sm text-primary" (click)="removeClicked.emit()">
                        <fa-icon [icon]="['fas', 'xmark']"></fa-icon>
                    </button>
                }
            </div>
        </div>
    </div>
    <div class="card-body">
        <p class="mb-0">{{ message() }}</p>
    </div>
</div>
