<section class="row d-flex align-content-center">
    <!-- Project milestone -->
    @if (newDataentryObject()?.nationalProjectMilestoneId && getKpitypeName(newDataentryObject()!.kpiType) === "mtkpi") {
        <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
            {{ "mtkpi.projectMilestoneId" | translate }}
        </div>

        <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-center rounded-end-1">
            {{ newDataentryObject() | translateToken: "nationalProjectMilestoneName" }}
        </div>
    }

    <!-- National project -->
    @if (newDataentryObject()?.nationalProjectId && getKpitypeName(newDataentryObject()!.kpiType) === "mokpi") {
        <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
            {{ "national_projects.singular_title" | translate }}
        </div>

        <div class="col-8 p-2 border border-white tbl-col d-flex align-items-center rounded-end-1">
            {{ newDataentryObject() | translateToken: "nationalProjectName" }}
        </div>
    }

    <!-- Kpi name -->
    <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
        {{ "data_entry.kpi_name" | translate }}
    </div>

    <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-center rounded-end-1">
        <a
            class="kpi-link"
            target="_blank"
            [routerLink]="['/', 'console', 'kpi', getKpitypeName(newDataentryObject()!.kpiType), newDataentryObject()?.kpiId]"
        >
            {{ newDataentryObject() | translateToken: "name" }}
        </a>
    </div>

    <!-- Kpi type -->
    @if (isDefinedAndNotEmpty(newDataentryObject()!.kpiType)) {
        <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
            {{ "data_entry.kpi_type_name" | translate }}
        </div>

        <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-center rounded-end-1">
            {{ newDataentryObject() | translateToken: "kpiType" }}
        </div>
    }

    <!-- Main service -->
    @if (isDefinedAndNotEmpty(newDataentryObject()!.mainServiceNameAE) || isDefinedAndNotEmpty(newDataentryObject()!.mainServiceNameEN)) {
        <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
            {{ "data_entry.mainService_name" | translate }}
        </div>

        <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-center rounded-end-1">
            {{ newDataentryObject() | translateToken: "mainServiceName" }}
        </div>
    }

    <!-- sub-service -->
    @if (isDefinedAndNotEmpty(newDataentryObject()!.subServiceNameAE) || isDefinedAndNotEmpty(newDataentryObject()!.subServiceNameEN)) {
        <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
            {{ "data_entry.subService_name" | translate }}
        </div>

        <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-center rounded-end-1">
            {{ newDataentryObject() | translateToken: "subServiceName" }}
        </div>
    }

    <!-- Linked to -->
    @if (isDefinedAndNotEmpty(newDataentryObject()!.linkedKpiTypeAE) || isDefinedAndNotEmpty(newDataentryObject()!.linkedKpiTypeEN)) {
        <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
            {{ "data_entry.linked_to" | translate }}
        </div>

        <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-center rounded-end-1">
            {{ newDataentryObject() | translateToken: "linkedKpiType" }}
        </div>
    }

    <!-- High priority -->
    @if (
        (getKpitypeName(newDataentryObject()!.kpiType) === "srvkpi" || getKpitypeName(newDataentryObject()!.kpiType) === "opm") &&
        isDefinedAndNotEmpty(newDataentryObject()!.highPriority)
    ) {
        <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
            {{ "initiatives.high_priority" | translate }}
        </div>

        <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-center rounded-end-1">
            {{ formatAdaaBoolean(newDataentryObject()!.highPriority) | translate }}
        </div>
    }

    <!-- Measurement unit -->
    <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
        {{ "kpi.measurement_unit" | translate }}
    </div>

    <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-center rounded-end-1">
        {{ formatFromConstants("measurement", newDataentryObject()!.measurementUnit) }}
    </div>

    <!-- Trend -->
    <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
        {{ "kpi.trend" | translate }}
    </div>

    <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-center rounded-end-1">
        {{ formatFromConstants("trend", newDataentryObject()!.trend) }}
    </div>

    <!-- Year To Period -->
    @if (newDataentryObject()!.formula === "NOFORMULAV" || newDataentryObject()!.hasTarget === "N") {
        <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
            {{ "kpi.year_to_period" | translate }}
        </div>

        <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-center rounded-end-1">
            {{ formatFromConstants("ytp", newDataentryObject()!.ytpCalc) }}
        </div>
    }

    <!-- Formula -->
    <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
        {{ "kpi.formula" | translate }}
    </div>

    <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-start align-content-start rounded-end-1 flex-wrap">
        <div
            class="d-block w-100"
            [class.mb-2]="isFormula()"
            [class.text-end]="isFormula() && languageService.direction() === 'rtl'"
            [dir]="isFormula() ? 'ltr' : languageService.direction()"
        >
            {{ getFormula(newDataentryObject()!.formula) | translate }}
        </div>

        @if (isFormula()) {
            <div class="d-block metric-details w-75 px-1">
                <div class="row d-flex w-100 flex-nowrap">
                    @for (detail of metricDetails(); track detail.id) {
                        <div class="col flex-shrink-0">
                            @if (detail.metricType) {
                                <i class="fa fa-pie-chart icon" (mouseover)="getInfo(detail.metricType)" [title]="title()"></i>
                            }
                            {{ detail | translateToken: "name" }}
                        </div>
                    }
                </div>

                <div class="row d-flex w-100 flex-nowrap">
                    @for (detail of metricDetails(); track detail.id) {
                        <div class="col flex-shrink-0">
                            {{ detail | translateToken: "dsc" }}
                        </div>
                    }
                </div>

                <div class="row d-flex w-100 flex-nowrap">
                    @for (detail of metricDetails(); track detail.id) {
                        <div class="col flex-shrink-0">
                            {{ formatFromConstants("ytp", detail.ytpCalc) }}
                        </div>
                    }
                </div>
            </div>
        }
    </div>
</section>

@if (isNoFormula()) {
    <adaa-no-formula-view
        [isBounded]="isBounded()"
        [showOnlyNew]="showOnlyNew()"
        [isBaselineKpi]="isBaselineKpi()"
        [baselineYear]="newDataentryObject()?.baselineYear"
        [newMetrics]="newDataentryObject()?.metrics!"
        [oldMetrics]="oldDataentryObject()?.metrics!"
    />
}

@if (isFormula() && !hasDetailedTargets()) {
    <adaa-formula-view
        [isBounded]="isBounded()"
        [showOnlyNew]="showOnlyNew()"
        [isBaselineKpi]="isBaselineKpi()"
        [formula]="newDataentryObject()?.formula"
        [baselineYear]="newDataentryObject()?.baselineYear"
        [newDataentryRequests]="newDataentryObject()?.dataEntryRequests!"
        [oldDataentryRequests]="oldDataentryObject()?.dataEntryRequests!"
    />
}

@if (isFormula() && hasDetailedTargets()) {
    <adaa-targets-formula-view
        [isBounded]="isBounded()"
        [showOnlyNew]="showOnlyNew()"
        [isBaselineKpi]="isBaselineKpi()"
        [formula]="newDataentryObject()?.formula"
        [baselineYear]="newDataentryObject()?.baselineYear"
        [newDataentryRequests]="newDataentryObject()?.dataEntryRequests!"
        [oldDataentryRequests]="oldDataentryObject()?.dataEntryRequests!"
    />
}
