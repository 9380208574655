import { inject, Injectable } from "@angular/core";
import { FileSaverService } from "ngx-filesaver";

import { ImportExportObjectType } from "../constants/enums";
import { MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class ImportExportApiService {
  private _mainService = inject(MainService);
  private _fileSaverService = inject(FileSaverService);

  public download(ids: number[], fileName: string) {
    const url = `impexts/direct/download`;
    const qs = new URLSearchParams();

    qs.append("ids", ids.join(","));

    this._mainService
      .sendRequest<Blob>({
        url: `${url}?${qs.toString()}`,
        method: "GET",
        responseType: "blob",
      })
      .subscribe({
        next: (response) => {
          this._fileSaverService.save(response, fileName);
        },
      });
  }

  public export(type: ImportExportObjectType) {
    return this._mainService.sendRequest<MainResponseModel<number>>({
      url: `impexts/export?type=${type}`,
      method: "POST",
      data: {},
    });
  }

  public downloadFile(id: number, fileName: string) {
    this._mainService
      .sendRequest<Blob>({
        url: `impexts/${id}/download`,
        method: "GET",
        responseType: "blob",
      })
      .subscribe({
        next: (response) => {
          this._fileSaverService.save(response, fileName);
        },
      });
  }

  public getDetails(id: number) {
    return this._mainService.sendRequest<MainResponseModel<string[]>>({
      url: `impexts/details/${id}`,
      method: "GET",
    });
  }
}
