import { Directive, inject, Input, TemplateRef, ViewContainerRef } from "@angular/core";

import type { PermissionActionDescriptorType } from "../../../adaa-types";
import { AdaaHelper } from "../../core/utils";
import { PermissionsService } from "../services";

/**
 * IMPORTANT HOW TO USE THIS DIRECTIVE
 * DON'T USE [] BRACKTES
 * Make sure to add * (star) befroe it
 * example *adaaHasPermission=""
 */
@Directive({
  selector: "[adaaHasPermission]",
  standalone: true,
})
export class HasPermissionDirective {
  private _templateRef = inject(TemplateRef);
  private _viewContainer = inject(ViewContainerRef);
  private _permissionsService = inject(PermissionsService);

  readonly #untilDestroy = AdaaHelper.untilDestroyed();
  private _permission: PermissionActionDescriptorType | undefined = undefined;
  private _checked: boolean = false;

  @Input() public set adaaHasPermission(permission: PermissionActionDescriptorType | undefined) {
    this._permission = permission;

    const isLoaded = this._permissionsService.hasPermissionsLoaded();
    this._checkPermissions(isLoaded);
  }

  constructor() {
    if (!this._permissionsService.hasPermissionsLoaded()) {
      this._permissionsService.loadUserPermissions();
    }

    this._permissionsService.isPermissionsLoaded.pipe(this.#untilDestroy()).subscribe((loaded: boolean) => {
      if (!loaded) return;

      this._checkPermissions(loaded);
    });
  }

  private _checkPermissions(isLoaded: boolean) {
    if (!this._permission) return;
    if (this._checked) return;
    if (!isLoaded) return;

    const actor = this._permission.actor;
    const hasPermission = this._permissionsService.hasPermission(actor.permissions, actor.modifier);
    hasPermission ? this._viewContainer.createEmbeddedView(this._templateRef) : this._viewContainer.clear();
    this._checked = true;
  }
}
