import { HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";

import { AdaaBoolean } from "../constants/enums";
import { ItemModel, ItemTableValueModel, MainResponseModel, SearchFields } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class ItemApiService {
  private _mainService = inject(MainService);

  public getAllV2(itemType: number, enabler = AdaaBoolean.N, searchFields?: SearchFields, entityId?: number) {
    const url = `item/getallv2`;
    let headers = new HttpHeaders();
    const qs = new URLSearchParams();

    qs.append("enabler", enabler);
    qs.append("itemType", `${itemType}`);

    if (entityId) {
      headers = headers.append("entityId", entityId.toString());
    }

    return this._mainService.sendRequest<MainResponseModel<ItemModel[]>>({
      headers,
      method: "POST",
      url: `${url}?${qs.toString()}`,
      data: searchFields,
    });
  }

  public getByIdAndTypeAndPeriod({
    id,
    periodId,
    itemType,
    frequency,
    kpiType,
    includeLegacyKpi = false,
  }: {
    id: number;
    periodId: number;
    itemType: number;
    frequency?: string;
    kpiType?: number;
    includeLegacyKpi?: boolean;
  }) {
    const url = `item/getByIdAndTypeAndPeriod/${id}/${itemType}`;
    const qs = new URLSearchParams({
      periodId: `${periodId}`,
    });

    if (kpiType) qs.set("kpitype", `${kpiType}`);
    if (frequency) qs.set("frequency", `${frequency}`);
    if (includeLegacyKpi) qs.set("includeLegacyKpi", `${includeLegacyKpi}`);

    return this._mainService.sendRequest<MainResponseModel<ItemTableValueModel[] | ItemModel>>({
      method: "GET",
      url: `${url}?${qs}`,
    });
  }

  public getItemDataByTypeAndId<ItemModelType = unknown>({
    id,
    periodId,
    itemType,
    frequency,
    kpiType,
    includeLegacyKpi = false,
  }: {
    id: number;
    periodId?: number;
    itemType: number;
    frequency: string;
    kpiType?: number;
    includeLegacyKpi?: boolean;
  }) {
    const url = `item/getItemDataByTypeAndId/${id}/${itemType}`;
    const qs = new URLSearchParams({
      frequency: `${frequency}`,
      includeLegacy: `${includeLegacyKpi}`,
    });

    if (periodId) qs.set("periodId", `${periodId}`);
    if (kpiType) qs.set("kpitype", `${kpiType}`);

    return this._mainService.sendRequest<MainResponseModel<ItemModelType>>({
      method: "GET",
      url: `${url}?${qs}`,
    });
  }

  public getItemByIdAndTypeAndPeriod<ItemModelType = unknown>({
    useV2 = true /* toggles between version 1 and 2 of the API */,
    itemId,
    itemType,
    periodId,
    kpiType,
    entityId,
  }: {
    itemId: number;
    itemType: number;
    periodId?: number;
    kpiType?: number;
    entityId?: number;
    useV2?: boolean;
  }) {
    const url = `item/getByIdAndTypeAndPeriod${useV2 ? "v2" : ""}/${itemId}/${itemType}`;
    const qs = new URLSearchParams();

    if (kpiType) qs.append("kpitype", `${kpiType}`);
    if (periodId) qs.append("periodId", `${periodId}`);
    if (entityId) qs.append("entityId", `${entityId}`);

    return this._mainService.sendRequest<MainResponseModel<ItemModelType>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getAllByParentIdAndType<ItemModelType = unknown>({
    itemId,
    currentType,
    sourceType,
    periodId,
  }: {
    itemId: number;
    currentType: number;
    sourceType: number;
    periodId?: number;
  }) {
    const url = `item/getAllByParentIdAndType/${itemId}/${currentType}`;
    const qs = new URLSearchParams();
    qs.append("sourceType", `${sourceType}`);

    if (periodId) qs.append("periodId", `${periodId}`);

    return this._mainService.sendRequest<MainResponseModel<ItemModelType[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getAllChildItems<ItemModelType = object>({
    itemId,
    currentType,
    periodId,
    useV2 = false,
  }: {
    itemId: number;
    currentType: number;
    periodId?: number;
    useV2?: boolean;
  }) {
    const baseUrl = useV2 ? "getAllChildItemsv2" : "getAllChildItems";
    const url = `item/${baseUrl}/${itemId}/${currentType}`;
    const qs = new URLSearchParams();

    if (periodId) qs.append("periodId", `${periodId}`);

    return this._mainService.sendRequest<MainResponseModel<ItemModelType[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }
}
