import { AdaaHelper } from "../../../../../../core/utils";
import { ScreenSectionConfigType } from "../wf-difference.types";
import { SharedFields } from "./common";

const jobRoleAEField: ScreenSectionConfigType = {
  view: "normal",
  field: "jobTitleAE",
  label: "common.form.label.jobTitleAE",
};

const jobRoleENField: ScreenSectionConfigType = {
  view: "normal",
  field: "jobTitleEN",
  label: "common.form.label.jobTitleEN",
};

const memberRoleAEField: ScreenSectionConfigType = {
  view: "normal",
  field: "memberRoleAE",
  label: "national_projects.project_team_members.member_role_ae",
};

const memberRoleENField: ScreenSectionConfigType = {
  view: "normal",
  field: "memberRoleEN",
  label: "national_projects.project_team_members.member_role_en",
};

const emailField: ScreenSectionConfigType = {
  view: "normal",
  field: "email",
  label: "common.form.label.email",
};

const mobilePhoneField: ScreenSectionConfigType = {
  view: "normal",
  field: "email",
  label: "common.form.label.mobilePhone",
  format: (data: { mobilePhone: string }) => {
    let str = data?.mobilePhone;
    if (AdaaHelper.getCurrentLang() === "en") return str;

    str = AdaaHelper.isDefined(str) ? str.toString() : "";
    const index = str.indexOf("+");
    if (index === -1) return str;

    return str
      .split("")
      .filter((v) => v !== "+")
      .join("");
  },
};

export const teamMembersSectionConfig = [
  {
    ...SharedFields.entityIdField,
    label: "national_projects.adaa_entity",
  },
  ...SharedFields.name,
  jobRoleAEField,
  jobRoleENField,
  emailField,
  mobilePhoneField,
  memberRoleAEField,
  memberRoleENField,
];

export const milestoneTeamMembersSectionConfig = [
  ...SharedFields.name,
  jobRoleAEField,
  jobRoleENField,
  emailField,
  mobilePhoneField,
  memberRoleAEField,
  memberRoleENField,
];
