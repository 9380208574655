import {
  Component,
  computed,
  EventEmitter,
  HostBinding,
  inject,
  input,
  Output,
  signal,
  viewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight, faChartSimple, faXmark } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { PercentageValuePipe } from "../../../../core/pipes";
import { TranslateTokenPipe } from "../../../../core/pipes/translate-token.pipe";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import type { AggregationPerfModelType, SelectedPeriodModelType } from "../../../models";
import { LanguageService } from "../../../services";
import { CountUpComponent } from "../../count-up.component";
import { KpiScoresByAggregationTypeComponent } from "../../modals";

const DISABLE_KPI_LIST = [
  102576, 102779, 102668, 102781, 102667, 102581, 102641, 102639, 102640, 102560, 102778, 102669, 102780, 102670,
  102565, 102638, 102636, 102637, 102570, 102566, 102582, 102586,
];

@Component({
  selector: "adaa-aggregation-perf-card",
  standalone: true,
  templateUrl: "./aggregation-perf-card.component.html",
  styleUrl: "./aggregation-perf-card.component.scss",
  imports: [
    FontAwesomeModule,
    TranslateTokenPipe,
    PercentageValuePipe,
    CountUpComponent,
    TranslateModule,
    KpiScoresByAggregationTypeComponent,
  ],
})
export class AggregationPerfCardComponent {
  readonly languageService = inject(LanguageService);
  readonly modalService = inject(NgbModal);
  private _router = inject(Router);

  data = input.required<AggregationPerfModelType>();
  period = input<SelectedPeriodModelType>();

  showMore = signal<boolean>(false);

  color = computed(() => Constants.COLOR_CODES[this.data().color]);
  amount = computed(() => this.data().value);
  image = computed(() => {
    if (!this.data()) return null;

    const imageId = this.data()?.id;

    return `/assets/images/aggregation_types/${imageId}.png`;
  });
  children = computed(() => {
    if (!this.data()?.childPerformances) {
      return [];
    }
    return this.data().childPerformances;
  });

  kpiScoresByAggregationType = viewChild.required<KpiScoresByAggregationTypeComponent>("kpiScoresByAggregationType");

  @Output() performanceGraphClicked = new EventEmitter<AggregationPerfModelType>();

  @HostBinding("class.is-open") public get isMoreOpen() {
    return this.showMore();
  }

  readonly defaultColor = Constants.COLOR_CODES[Constants.CONSTANT_COLORS.EVALUATION_GRAY];
  readonly isDefined = AdaaHelper.isDefined;
  readonly graphIcon = faChartSimple;
  readonly closeIcon = faXmark;
  readonly moreIcon = {
    rtl: faAngleDoubleLeft,
    ltr: faAngleDoubleRight,
  };

  public getColor(code: number) {
    return Constants.COLOR_CODES[code];
  }

  hideKpiListButton = (id: number) => !DISABLE_KPI_LIST.find((item) => item === id);

  public openUri(uri: string) {
    const uriObj = JSON.parse(uri);
    this._router.navigateByUrl(uriObj.uri);
  }

  public buttonClicked(event: Event, type: "kpi" | "graph") {
    event.stopPropagation();
    switch (type) {
      case "kpi": {
        return this.kpiScoresByAggregationType().open();
      }

      case "graph": {
        return this.performanceGraphClicked.emit(this.data());
      }
    }
  }
}
