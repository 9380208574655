import { inject, Injectable } from "@angular/core";

import { MainResponseModel, NtkpiChallenge, NtkpiSuccessStory } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class NationalTargetCardApiService {
  private _mainService = inject(MainService);

  public getChallenge(ntkpiId: number, periodId: number) {
    const url = `national-target-card/getChallenge?ntkpiId=${ntkpiId}&periodId=${periodId}`;

    return this._mainService.sendRequest<MainResponseModel<NtkpiChallenge>>({
      method: "GET",
      url,
    });
  }

  public getSuccessStory(ntkpiId: number, periodId: number) {
    const url = `national-target-card/getSuccessStory?ntkpiId=${ntkpiId}&periodId=${periodId}`;

    return this._mainService.sendRequest<MainResponseModel<NtkpiSuccessStory>>({
      method: "GET",
      url,
    });
  }

  public saveChallenge(data: NtkpiChallenge, isUpdate: boolean) {
    const url = isUpdate ? `national-target-card/updateChallenge` : `national-target-card/createChallenge`;

    return this._mainService.sendRequest<MainResponseModel>({
      method: "POST",
      url,
      data: data,
    });
  }

  public saveSuccessStory(data: NtkpiSuccessStory, isUpdate: boolean) {
    const url = isUpdate ? `national-target-card/updateSuccessStory` : `national-target-card/createSuccessStory`;

    return this._mainService.sendRequest<MainResponseModel>({
      method: "POST",
      url,
      data: data,
    });
  }
}
