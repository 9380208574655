@if (wfData()) {
    <div class="mb-2" ngbAccordion [class]="type()">
        <div ngbAccordionItem [collapsed]="!isExpanded()">
            <h2 ngbAccordionHeader>
                <button ngbAccordionButton type="button" class="accordion-button fw-normal justify-content-between">
                    <span
                        class="fs-6 fw-bold"
                        style="flex-grow: 2"
                        [class.text-start]="languageService.current() === Language.English"
                        [class.text-end]="languageService.current() === Language.Arabic"
                    >
                        {{ cardTitle() }}
                    </span>
                </button>
            </h2>
            <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                    <ng-template>
                        <ul class="list-group px-1">
                            @for (comment of wfData()?.categorizedComments; track comment) {
                                <li class="list-group-item">
                                    <div class="d-flex">
                                        <div class="w-100">{{ comment.comments }}</div>
                                        <div class="flex-shrink-1">
                                            <span class="badge rounded-1">{{ comment | translateToken: "categoryName" }}</span>
                                        </div>
                                    </div>
                                </li>
                            }
                        </ul>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
}
