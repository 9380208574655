import { Component, inject, input, OnInit, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import * as fileSaver from "file-saver";
import { filter } from "rxjs";

import { AdaaHelper } from "../../../../../../../core/utils";
import { Constants } from "../../../../../../constants/constants";
import type { PropTypeModelType } from "../../../../../../models";
import { FilesApiService, PropertiesService } from "../../../../../../services";
import {
  AccordionDiffViewComponent,
  ActivityWorkloadDiffViewComponent,
  AttachmentDiffViewComponent,
  ExtendedFieldsDiffComponent,
  LinkedNationalStrategiesDiffViewComponent,
  ListDiffViewComponent,
  NormalDiffViewComponent,
} from "../../_lib";
import type {
  AccordionSectionConfigType,
  CompositeSectionConfigType,
  ListSectionConfigType,
  ScreenSectionConfigType,
  ScreenType,
} from "../../wf-difference.types";
import { isDtkpi, isNtkpi, isSrvkpi } from "../../wf-difference.utils";
import { agmProjectMilestoneScreenConfig, agmProjectScreenConfig } from "../agm-screen/agm-screen.config";
import { entityPillarsAuditScreenConfig, govSectorsAuditScreenConfig, simpleScreenConfig } from "./config";
import { measurementScopeScreenConfig } from "./measurement-scope.config";

@Component({
  selector: "adaa-simple-screen",
  standalone: true,
  imports: [
    TranslateModule,
    NormalDiffViewComponent,
    AttachmentDiffViewComponent,
    ExtendedFieldsDiffComponent,
    ActivityWorkloadDiffViewComponent,
    LinkedNationalStrategiesDiffViewComponent,
    ListDiffViewComponent,
    AccordionDiffViewComponent,
  ],
  templateUrl: "./simple-screen.component.html",
  styleUrl: "./simple-screen.component.scss",
})
export class SimpleScreenComponent implements OnInit {
  private readonly _propertiesService = inject(PropertiesService);
  private readonly _filesApiService = inject(FilesApiService);

  showOnlyNew = input.required<boolean>();
  itemType = input<number>();
  newObject = input<Record<string, never>>();
  oldObject = input<Record<string, never>>();
  isNew = input<boolean>(false);
  viewOnlyDifferences = input<boolean>(false);

  isScreenReady = signal<boolean>(false);
  props = signal<PropTypeModelType[]>([]);

  readonly $asListConfig = ($s: CompositeSectionConfigType) => $s as ListSectionConfigType;
  readonly $asAccordionConfig = ($s: CompositeSectionConfigType) => $s as AccordionSectionConfigType;

  config: {
    type: ScreenType;
    sections: (ScreenSectionConfigType | AccordionSectionConfigType)[];
  };

  public ngOnInit() {
    this._initScreen();
    this._fetchConstants();
  }

  private _initScreen() {
    switch (this.itemType()) {
      case Constants.CONSTANT_ANNUAL_MEETINGS_MILESTONE:
        this.config = agmProjectMilestoneScreenConfig;
        break;
      case Constants.CONSTANT_ANNUAL_MEETINGS_PROJECT:
        this.config = agmProjectScreenConfig;
        break;
      case Constants.GOV_SCORES_SECTOR:
        this.config = govSectorsAuditScreenConfig;
        break;
      case Constants.GOV_SCORES_PILLAR:
        this.config = entityPillarsAuditScreenConfig;
        break;

      case Constants.GOV_SCORES_SCOPE:
        this.config = {
          type: "simple",
          sections: measurementScopeScreenConfig,
        };
        break;

      default:
        this.config = {
          type: "simple",
          sections: simpleScreenConfig,
        };
        break;
    }
  }

  private _fetchConstants() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const constantIds = [
      Constants.CONSTANT_MEASUREMENT_UNIT,
      Constants.CONSTANT_FREQUENCY,
      Constants.CONSTANT_TREND,
      Constants.CONSTANT_YTP,
    ];

    if (isNtkpi(this.newObject()!.kpiType) || isDtkpi(this.newObject()!.kpiType)) {
      constantIds.push(Constants.CONSTANT_KPI_TARGET_TYPE);
    }
    if (isNtkpi(this.newObject()!.kpiType)) {
      constantIds.push(
        ...[Constants.CONSTANT_TARGET_CATEGORY, Constants.CONSTANT_PUBLISHED_STATUS, Constants.CONSTANT_MONTHS]
      );
    }
    if (isSrvkpi(this.newObject()!.kpiType)) {
      constantIds.push(...[Constants.CONSTANT_PERSPECTIVE_TYPE, Constants.CONSTANT_CLASSIFICATION_TYPE]);
    }

    this._propertiesService
      .getPropByIdList(constantIds)
      .pipe(filter((res) => !res.inError))
      .subscribe({
        next: (res) => {
          this.props.set(res.responseData ?? []);

          for (const section of this.config.sections) {
            if (section.view !== "normal" && section.view !== "list") continue;

            switch ((section as ScreenSectionConfigType).field) {
              case "targetCategory":
                (section as ScreenSectionConfigType).format = (data: { targetCategory: number }) =>
                  that._getTextFromProp(data.targetCategory, res.responseData);
                break;

              case "targetTypeId":
                (section as ScreenSectionConfigType).format = (data: { targetTypeId: number }) =>
                  that._getTextFromProp(data.targetTypeId, res.responseData);
                break;

              case "publishedState":
                (section as ScreenSectionConfigType).format = (data: { publishedState: number }) =>
                  that._getTextFromProp(data.publishedState, res.responseData);
                break;

              case "actualDataAvailabilityPeriod":
                (section as ScreenSectionConfigType).format = (data: { actualDataAvailabilityPeriod: number }) =>
                  that._getTextFromProp(data.actualDataAvailabilityPeriod, res.responseData);
                break;

              case "ytpCalc":
                (section as ScreenSectionConfigType).format = (data: { ytpCalc: number }) =>
                  that._getTextFromProp(data.ytpCalc, res.responseData);
                break;

              case "trend":
                (section as ScreenSectionConfigType).format = (data: { trend: number }) =>
                  that._getTextFromProp(data.trend, res.responseData);
                break;

              case "dataType":
                (section as ScreenSectionConfigType).format = (data: { dataType: number }) =>
                  that._getTextFromProp(data.dataType, that.props() || []);
                break;

              case "measurementUnit":
                (section as ScreenSectionConfigType).format = (data: { measurementUnit: number }) =>
                  that._getTextFromProp(data.measurementUnit, res.responseData);
                break;

              case "frequency":
                (section as ScreenSectionConfigType).format = (data: { frequency: number }) =>
                  that._getTextFromProp(data.frequency, res.responseData);
                break;

              case "perspective":
                (section as ScreenSectionConfigType).format = (data: { perspective: number }) =>
                  that._getTextFromProp(data.perspective, res.responseData);
                break;

              case "classification":
                (section as ScreenSectionConfigType).format = (data: { classification: number }) =>
                  that._getTextFromProp(data.classification, res.responseData);
                break;

              default:
                break;
            }
          }

          this.isScreenReady.set(true);
        },
      });
  }

  private _getTextFromProp(id: number, list: { id: number }[]) {
    const data = list.find((e) => e.id === id);
    if (data) {
      return AdaaHelper.getItemValueByToken(data, "name");
    }
    return "";
  }

  public download(data: Record<string, unknown>) {
    data.downloading = true;
    this._filesApiService.download(data.id as number, true).subscribe({
      next: (blob) => {
        fileSaver.saveAs(blob, data.filename as string);
      },
      error: () => {
        data.downloading = false;
      },
      complete: () => {
        data.downloading = false;
      },
    });
  }
}
