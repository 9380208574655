<div class="kpi-annual-weights-diff">
    <div class="page-header">
        <div class="period-row">
            @for (year of planYears(); track $index) {
                <div class="period-col" [attr.dir]="languageService.current() === language.English ? 'ltr' : 'rtl'">
                    <span>{{ year }}</span>
                    <div class="options">
                        <span>
                            {{ "kpi_annual_weights.curr" | translate }}
                        </span>
                        <span>
                            {{ "kpi_annual_weights.prev" | translate }}
                        </span>
                    </div>
                </div>
            }
        </div>
    </div>

    <div class="page-datatable">
        <div class="page-kpi-list">
            @for (refCode of datalist; track $index) {
                <div
                    class="kpi-list-item"
                    [attr.title]="
                        languageService.current() === language.English
                            ? getWeightProperty(refCode, 'kpiNameEN')
                            : getWeightProperty(refCode, 'kpiNameAE')
                    "
                    [attr.tabindex]="-1"
                >
                    <small>{{ getWeightProperty(refCode, "refCode") }}</small>
                    <span>{{
                        languageService.current() === language.English
                            ? getWeightProperty(refCode, "kpiNameEN")
                            : getWeightProperty(refCode, "kpiNameAE")
                    }}</span>
                </div>
            }
        </div>

        <div class="annual-weights-table">
            @for (refCode of datalist; track $index) {
                <div class="table-row">
                    @for (year of planYears(); track $index) {
                        <div class="table-col" [attr.dir]="languageService.current() === language.English ? 'ltr' : 'rtl'">
                            <div
                                class="options"
                                [ngClass]="{
                                    diff:
                                        (!!getWeightProperty(refCode, 'wfProcessCtlId', year) &&
                                            getWeightProperty(refCode, 'wfProcessCtlId', year) === wfProcessCtlId()) ||
                                        getWeightProperty(refCode, 'weight', year) !== getWeightProperty(refCode, 'weight', year, true),
                                }"
                            >
                                <input
                                    type="number"
                                    class="form-control readonly"
                                    [readonly]="true"
                                    [attr.title]="
                                        getWeightProperty(refCode, 'year', year) +
                                        ' - ' +
                                        (languageService.current() === language.English
                                            ? getWeightProperty(refCode, 'kpiNameEN')
                                            : getWeightProperty(refCode, 'kpiNameAE'))
                                    "
                                    [readonly]="true"
                                    [attr.min]="0"
                                    [attr.max]="100"
                                    [attr.step]="0.01"
                                    [attr.pattern]="'([0-9]{0,3})(\.\d{0,2})'"
                                    [value]="getWeightProperty(refCode, 'weight', year)"
                                />

                                <input
                                    type="number"
                                    class="form-control readonly"
                                    [readonly]="true"
                                    [attr.title]="
                                        getWeightProperty(refCode, 'year', year, true) +
                                        ' - ' +
                                        (languageService.current() === language.English
                                            ? getWeightProperty(refCode, 'kpiNameEN')
                                            : getWeightProperty(refCode, 'kpiNameAE'))
                                    "
                                    [readonly]="true"
                                    [attr.min]="0"
                                    [attr.max]="100"
                                    [attr.step]="0.01"
                                    [attr.pattern]="'([0-9]{0,3})(\.\d{0,2})'"
                                    [value]="getWeightProperty(refCode, 'weight', year, true)"
                                />
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</div>
