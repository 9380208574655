<div class="row">
    <div class="col-6">
        <h6 class="text-primary">{{ governmentDirectionsTitle() | translate }}</h6>
    </div>
    <div class="col-6">
        <h6 class="text-primary">{{ governmentSubDirectionsTitle() | translate }}</h6>
    </div>
    @for (direction of govDirectionsList(); track $index) {
        <div class="col-6">
            {{ direction | translateToken: "name" }}
        </div>
        <div class="col-6">
            @for (item of direction.subGovs; track $index) {
                {{ item | translateToken: "name" }}
            }
        </div>
    }
</div>
