<div class="d-flex justify-content-between align-items-start row">
    <div class="col-12 col-md-3">
        <div class="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
            @for (item of tabs; let index = $index; track index) {
                <button
                    class="d-flex nav-link"
                    id="tab-{{ index }}"
                    data-bs-toggle="pill"
                    [attr.data-bs-target]="'tab-' + index"
                    type="button"
                    role="tab"
                    [class.error]="item.hasError"
                    [class.disabled]="item.disabled"
                    [class.active]="activeTab === index"
                    (click)="tabSelected(index)"
                >
                    @if (showTabIndex) {
                        <div class="tab-number">{{ index + 1 }}</div>
                    }
                    <div class="tab-text" [dir]="languageService.direction()">{{ item.title | translate }}</div>
                </button>
            }
        </div>
    </div>

    <div class="col-12 col-md-9">
        <div class="tab-content mb-2" #contentWrapper>
            <ng-content />
        </div>
    </div>
</div>

@if (showActions) {
    <div class="row d-flex justify-content-center align-items-center align-content-center">
        <div class="col text-center action-row">
            <div class="mt-3 text-center">
                <button
                    [title]="'common.form.button.back' | translate"
                    class="btn action-buttons btn-primary btn-icon text-white pl-2"
                    (click)="moveBack()"
                    [disabled]="activeTab === 0"
                >
                    @if (appService.language() === language.English) {
                        <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                    } @else {
                        <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                    }
                </button>
                <button class="btn action-buttons btn-primary text-white ml-2" (click)="cancelClicked.emit(true)">
                    {{ cancelTitle | translate }}
                </button>
                @if (showSaveButton) {
                    <button (click)="submitClicked.emit(false)" class="btn action-buttons btn-primary text-white ml-2">
                        {{ saveTitle | translate }}
                    </button>
                }

                <button
                    [title]="'common.form.button.next' | translate"
                    class="btn action-buttons btn-primary btn-icon text-white ml-2 pr-1"
                    (click)="moveNext()"
                    [disabled]="activeTab >= lastTab"
                >
                    @if (appService.language() === language.English) {
                        <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                    } @else {
                        <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                    }
                </button>
                @if (activeTab >= lastTab && showFinishButton) {
                    <button (click)="submitClicked.emit(true)" class="btn btn-primary text-white ml-2">
                        {{ finishTitle | translate }}
                    </button>
                }
            </div>
        </div>
    </div>
}
