import { CommonModule } from "@angular/common";
import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { Language } from "../../../constants/enums";
import { FormTab } from "../../../models";
import { AppService, LanguageService } from "../../../services";

@Component({
  selector: "adaa-form-steps",
  standalone: true,
  imports: [TranslateModule, CommonModule, FontAwesomeModule],
  templateUrl: "./form-steps.component.html",
  styleUrl: "./form-steps.component.scss",
})
export class FormStepsComponent implements OnInit, OnChanges {
  @Input() tabs: FormTab[] = [];
  @Input() activeTab: number = 0;
  @Input() showSaveButton: boolean = true;
  @Input() showFinishButton: boolean = false;
  @Input() showActions: boolean = true;
  @Input() showTabIndex: boolean = true;
  @Input() cancelTitle: string = "common.form.button.cancel";
  @Input() saveTitle: string = "common.form.button.save";
  @Input() finishTitle: string = "common.form.button.finish";
  @Output() currentTab = new EventEmitter<number>();
  @Output() cancelClicked = new EventEmitter<boolean>();
  @Output() submitClicked = new EventEmitter<boolean>(); //TRUE finish FALSE save

  appService = inject(AppService);
  languageService = inject(LanguageService);
  private _iconLibrary: FaIconLibrary = inject(FaIconLibrary);

  lastTab: number;
  language = Language;

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faArrowLeft, faArrowRight);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["tabs"] && changes["tabs"].previousValue != changes["tabs"].currentValue) {
      const notDisabledSteps = this.tabs.filter((e) => !e.disabled);
      this.lastTab = notDisabledSteps ? notDisabledSteps.length - 1 : 0;
    }
  }

  public tabSelected(index: number): void {
    this.activeTab = index;
    this.currentTab.emit(this.activeTab);
  }

  public moveBack(): void {
    const stepIndex = this._getFirstLastNotDisabledTab(true);
    if (stepIndex > -1) this.tabSelected(stepIndex);
  }

  public moveNext(): void {
    const stepIndex = this._getFirstLastNotDisabledTab(false);
    if (stepIndex > -1) this.tabSelected(stepIndex);
  }

  private _getFirstLastNotDisabledTab(isFirst: boolean) {
    const steps = this.tabs.filter(
      (e, index) => (isFirst ? index < this.activeTab : index > this.activeTab) && !e.disabled
    );

    if (steps) {
      return isFirst ? steps.length - 1 : this.tabs.findIndex((e) => e.title === steps[0].title);
    }
    return -1;
  }
}
