@if (isReady()) {
    <section class="row d-flex align-content-center mt-2">
        <div
            class="p-2 border border-white tbl-col rounded-start-1 bg-white text-primary fw-bold text-center"
            [class.col-6]="showOnlyNew()"
            [class.col-4]="!showOnlyNew()"
        >
            {{ "communication.difference.key" | translate }}
        </div>

        <div
            class="p-2 border border-white tbl-col bg-white text-primary fw-bold text-center"
            [class.rounded-end-1]="showOnlyNew()"
            [class.col-6]="showOnlyNew()"
            [class.col-4]="!showOnlyNew()"
        >
            {{ !showOnlyNew() ? ("communication.difference.new_value" | translate) : ("communication.difference.value" | translate) }}
        </div>

        @if (!showOnlyNew()) {
            <div class="col-4 p-2 border border-white tbl-col rounded-end-1 bg-white text-primary fw-bold text-center">
                {{ "communication.difference.old_value" | translate }}
            </div>
        }
    </section>

    @for (section of config.sections; track $index) {
        @switch (section.view) {
            @case ("normal") {
                <adaa-normal-diff-view
                    [isNew]="isNew()"
                    [config]="section"
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newData()!"
                    [oldData]="oldData()!"
                    [showDIffOnly]="viewOnlyDifferences()"
                />
            }
        }
    }
} @else {
    <div class="w-100 d-flex justify-content-center py-3">
        <div class="zsk__loader zsk__loader--sm"></div>
    </div>
}
