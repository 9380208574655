import { Component, EventEmitter, inject, input, OnInit, Output } from "@angular/core";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "adaa-note",
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: "./note.component.html",
  styleUrl: "./note.component.scss",
})
export class NoteComponent implements OnInit {
  private _iconLibrary: FaIconLibrary = inject(FaIconLibrary);

  title = input.required<string>();
  message = input.required<string>();
  number = input<number | undefined | null>(undefined);
  subTitle = input<string | undefined | null>(undefined);
  isEditable = input<boolean>(false);

  @Output() removeClicked = new EventEmitter<void>();

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faXmark);
  }
}
