import { inject, Injectable } from "@angular/core";

import { Constants } from "../constants/constants";
import { MainResponseModel } from "../models";
import { Pillar } from "../models/pillar.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class PillarsApiService {
  private _mainService = inject(MainService);

  public getPillarsList() {
    const url = `pillar/getallv2?itemType=${Constants.CONSTANT_PILLARS_TYPE}&limit=10&offset=0`;

    return this._mainService.sendRequest<MainResponseModel<Pillar[]>>({
      method: "GET",
      url,
    });
  }

  public getPillarById(pillarId: number) {
    const url = `pillar/getbyid/${pillarId}`;

    return this._mainService.sendRequest<MainResponseModel<Pillar>>({
      method: "GET",
      url,
    });
  }

  public createPillar(pillar: Partial<Pillar>) {
    const url = `pillar/create?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data: pillar,
    });
  }

  public updatePillar(data: Partial<Pillar>) {
    const url = `pillar/update?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public deletePillar(pillarId: number) {
    const url = `pillar/deleteById/${pillarId}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "DELETE",
      url,
    });
  }

  public getPillars(noDraft: boolean = false) {
    let url = "pillar/getall";
    if (noDraft) url += "?noDraft=true";

    return this._mainService.sendRequest<MainResponseModel<Pillar[]>>({
      method: "GET",
      url,
    });
  }
}
