import { Component, computed, inject, input, OnInit, signal } from "@angular/core";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";
import { filter } from "rxjs";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { Language } from "../../../constants/enums";
import { WorkflowHistoryModelType } from "../../../models";
import { LanguageService, WorkflowsApiService } from "../../../services";

@Component({
  selector: "adaa-wf-alert-decision",
  imports: [NgbAccordionModule, TranslateTokenPipe],
  templateUrl: "./wf-alert-decision.component.html",
  styleUrl: "./wf-alert-decision.component.scss",
})
export class WfAlertDecisionComponent implements OnInit {
  private _workflowsApiService = inject(WorkflowsApiService);
  languageService = inject(LanguageService);

  itemId = input.required<number>();
  //NOTE: THIS SHOULD BE THE ITEM TYPE NOT WF TYPE
  itemType = input.required<number>();
  type = input<"info" | "danger" | "success" | "warning">("danger");
  isExpanded = input<boolean>(true);

  Language = Language;
  wfData = signal<WorkflowHistoryModelType | undefined>(undefined);

  wfItemType = computed<number>(() => AdaaHelper.getWorkflowItemId(this.itemType()));
  cardTitle = computed<string>(() => {
    let title = this.languageService.translate("workflow.rejection_statement");
    title = title.replace("{{rejection_user}}", AdaaHelper.getItemValueByToken(this.wfData(), "decidingUserName"));

    const date = this.wfData()?.lastActionDate;
    if (date) {
      title = title.replace(
        "{{date_time}}",
        AdaaHelper.formatDate(AdaaHelper.getDubaiTime(date), true, {
          day: "2-digit",
          year: "numeric",
          month: "short",
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
      );
    }

    return title;
  });

  public ngOnInit(): void {
    this._workflowsApiService
      .getWorkflowHistory(this.itemId(), this.wfItemType())
      .pipe(filter((response) => !response.inError))
      .pipe(filter((response) => response.responseData && response.responseData.length > 0))
      .subscribe({
        next: (response) => {
          const lastItem = response.responseData[response.responseData.length - 1];
          if (lastItem && lastItem.wfStatus === Constants.WF_ACTION_DECISION.REJECTED) this.wfData.set(lastItem);
        },
      });
  }
}
