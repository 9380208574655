/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpContextToken } from "@angular/common/http";
import { DestroyRef, inject } from "@angular/core";
import { Validators } from "@angular/forms";
import moment from "moment";
import { Subject, takeUntil } from "rxjs";

import { Constants } from "../../shared/constants/constants";
import { AdaaBoolean, Language } from "../../shared/constants/enums";
import {
  CycleModelType,
  EntityModelType,
  type ParameterCatalog,
  TokenOptionType,
  ValueText,
} from "../../shared/models";

type LocalStorageItemType = { type: "string" } | { type: "json" } | { type: "prop"; property: string };

export const AdaaHelper = {
  timestampFromString: (datetime: string) => new Date(datetime).getTime(),

  suppressNotificationToken: new HttpContextToken<boolean>(() => true),

  get entity() {
    return AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "json",
    }) as EntityModelType;
  },

  get plan() {
    return AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, {
      type: "json",
    }) as CycleModelType;
  },
  /**
   * @description
   *  This helper function is important for managing Observable subscriptions.
   *  Its a more subtle approach that will help avoid use of `ngOnDestroy` or
   *  `OnDestroy` lifecycle.
   *
   * @usage
   *  export class FooComponent {
   *      readonly #untilDestroyed = untilDestroyed();
   *
   *      ngOnInit() {
   *       interval(1000)
   *          .pipe(
   *              this.#untilDestroyed(),
   *              ...You're other rxjs operators
   *           )
   *          .subscribe(console.log);
   *     }
   *  }
   */
  untilDestroyed: () => {
    const subject = new Subject();

    inject(DestroyRef).onDestroy(() => {
      subject.next(true);
      subject.complete();
    });

    return <T>() => takeUntil<T>(subject.asObservable());
  },

  isLoggedIn: () =>
    !!localStorage.getItem(Constants.localStorageKeys.sessionId) &&
    !!localStorage.getItem(Constants.localStorageKeys.user),

  getCurrentLang(uppercase: boolean = false) {
    const lang = localStorage.getItem("language") as Language;

    if (uppercase) lang?.toUpperCase() ?? "AE";
    return lang ?? "ae";
  },

  /* @private : used to get latest userAgent when called */
  _userAgent: () => window.navigator.userAgent,

  /**
   * @note Checks if the device is a desktop/laptop computer
   */
  isDesktopDevice(): boolean {
    return !AdaaHelper.isMobileDevice() && !AdaaHelper.isTabletDevice();
  },

  /**
   * @note Checks if the device is a tablet based on userAgent
   */
  isTabletDevice(): boolean {
    const regexp =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;

    return regexp.test(AdaaHelper._userAgent().toLowerCase());
  },

  /**
   * @note Checks if the device is a mobile based on userAgent
   */
  isMobileDevice(): boolean {
    const regexp = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i];

    return regexp.some((b) => AdaaHelper._userAgent().match(b));
  },

  /**
   * @description To check if the value defined e.g. if the value is 0 then it's defined
   * and we can avoid the scenario that consider 0 as null or false
   * @param value
   * @return boolean
   */
  isDefined<T = any | null | undefined>(value: T): value is NonNullable<T> {
    return typeof value !== "undefined" && value !== null && value !== undefined;
  },

  /**
   * @description To check if the value defined e.g. if the value is 0 then it's defined
   * and we can avoid the scenario that consider 0 as null or false
   * and it will check if the value is string it shouldn't empty
   * @param value
   * @return boolean
   */
  isDefinedAndNotEmpty<T = any | null | undefined>(value: T): value is NonNullable<T> {
    return (
      (typeof value !== "undefined" && value !== null && value !== undefined && value !== "") ||
      (typeof value === "string" && value.trim().length !== 0)
    );
  },

  /**
   * @description It helps if you want to clone an object without keep affecting on the
   * original object, usually we use this when we have filter and you want to go back
   * to the original object.
   * @param obj
   * @param array
   */
  clone(obj: any, array = false) {
    if (this.isDefined(obj)) {
      return JSON.parse(JSON.stringify(obj));
    }
    return array ? [] : {};
  },

  isPlanArchived: () => {
    const planStatus =
      AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, { type: "prop", property: "status" }) ?? 0;

    return planStatus === Constants.OBJECT_STATUS.ARCHIVED || planStatus === Constants.OBJECT_STATUS.CLOSED;
  },

  /**
   * gets object property value by token
   *
   * @param info the object from where we want to get the information
   * @param token the property we want to get
   * @param lang the property language we want
   *
   * @return String
   */
  getItemValueByToken(info: any, token: string, lang?: Language) {
    let returnInfo = "";

    token = this.getFieldLanguage(token, lang);

    if (
      info != null &&
      typeof info === "object" &&
      typeof token !== "undefined" &&
      typeof info[token] !== "undefined"
    ) {
      returnInfo = info[token];
    }

    return returnInfo;
  },

  /**
   * @description gets the value in the local storage
   * @param key
   * @param {LocalStorageItemType} item
   */
  getLocalStorage(
    key: string,
    item: LocalStorageItemType = { type: "string" }
  ): { [key: string]: any } | string | void {
    if (!this.isDefinedAndNotEmpty(key)) return;

    const result = localStorage.getItem(key);
    if (!result) return;
    if (item.type === "string") return result;

    let value: { [key: string]: any };
    try {
      value = JSON.parse(result);
    } catch (_e) {
      return;
    }

    if (item.type === "json") return value;
    if (item.type === "prop") return value[item.property];
  },

  /**
   * @description stores the value in the local storage
   * @param key
   * @param value
   * @param storeAs
   */
  setLocalStorage(key: string, value: any, storeAs: "json" | "string"): void {
    if (!this.isDefinedAndNotEmpty(key)) return;

    if (storeAs === "json") value = JSON.stringify(value);

    localStorage.setItem(key, value);
  },

  /**
   * @description deletes the value in the local storage
   * @param key
   */
  deleteLocalStorage(key: string): void {
    if (!this.isDefinedAndNotEmpty(key)) return;
    localStorage.removeItem(key);
  },

  /**
   * @description set the array for the dropdown
   * @param array
   * @param valueKey
   * @param textKey
   */

  setDropdownArray(array: any[], valueKey: string, textKey: string, parentKey?: string): ValueText[] {
    if (parentKey) {
      return this.buildTree(array, valueKey, textKey, parentKey);
    } else {
      const dropdown: ValueText[] = [];
      array.forEach((e) =>
        dropdown.push({
          value: e[valueKey] ? e[valueKey] : e,
          text: e[textKey] ? e[textKey] : e,
        })
      );
      return dropdown;
    }
  },

  /**
   * @description get field name by language
   * @param field
   * @param lang
   */
  getFieldLanguage(field: string, lang?: Language): string {
    lang = lang ? lang : (localStorage.getItem("language") as Language);

    if (lang !== undefined && lang?.trim().length !== 0) {
      return field + lang?.toUpperCase();
    } else {
      return field;
    }
  },

  /**
   * @description To round a value based on defined fixed number
   * @param value
   * @param toFixedValue: 12.55555.toFixed(2) === 12.55
   * @param nullSymbol
   * @param alwaysShowComma
   */
  roundValue(
    value: string | number | undefined | null,
    toFixedValue = 2,
    nullSymbol: any = "-",
    alwaysShowComma: boolean = false
  ) {
    if (!AdaaHelper.isDefined(value)) {
      return nullSymbol;
    }
    if (typeof value !== "number") value = Number(value);
    return AdaaHelper.isFloat(value) || alwaysShowComma ? value.toFixed(toFixedValue) : value;
  },
  /**
   * @description To get a performance value properly otherwise to get '-' or defaultSymbol as defined
   * @param percentage
   * @param toFixedValue: 12.55555.toFixed(2) === 12.55
   * @param nullSymbol
   * @param percentageSymbol
   * @param alwaysShowComma
   */
  percentageValue(
    value: string | number | null | undefined,
    toFixedValue = 2,
    nullSymbol: any = "-",
    percentageSymbol = "%",
    alwaysShowComma: boolean = false
  ) {
    if (!AdaaHelper.isDefined(value)) {
      return nullSymbol;
    }
    if (typeof value !== "number") value = Number(value);
    value = AdaaHelper.isFloat(value) || alwaysShowComma ? value.toFixed(toFixedValue) : value;
    return `${value}${percentageSymbol}`;
  },

  /**
   * Checks if value is a float number
   * @param num
   * @returns
   */
  isFloat: (num: string | number): num is number => {
    if (typeof num === "string") {
      num = parseFloat(num);
    }
    return num === +num && num !== (num | 0);
  },

  /**
   * to check if the entity is PMO
   * @param id
   * @returns
   */
  isPMOEntity(id: number | undefined = undefined) {
    const entityId = this.isDefined(id)
      ? id
      : this.getLocalStorage(Constants.localStorageKeys.currentEntity, {
          type: "prop",
          property: "id",
        });

    return Number(entityId) === Constants.CONSTANT_PMO_ID;
  },

  /**
   * get the value for currency after fromat
   * @param amount
   * @returns number | undefined
   */
  amountToString(amount: number | undefined): number | undefined {
    if (!amount) return undefined;

    const amountString = amount.toString();
    const firstPart = amountString.split(".")[0];
    const decimal = firstPart.length - 1;
    let math: number | null = null;
    if (decimal === 4 || decimal === 5) {
      math = +(amount / Math.pow(10, 3)).toFixed(1);
      if (math % 1 === 0) {
        math = +math.toFixed(0);
      }
    } else if (decimal >= 6 && decimal < 9) {
      math = +(amount / Math.pow(10, 6)).toFixed(1);
      if (math % 1 === 0) {
        math = +math.toFixed(0);
      }
    } else if (decimal >= 9 && decimal < 12) {
      math = +(amount / Math.pow(10, 9)).toFixed(1);
      if (math % 1 === 0) {
        math = +math.toFixed(0);
      }
    } else if (decimal >= 12) {
      math = +(amount / Math.pow(10, 9)).toFixed(1);
      if (math % 1 === 0) {
        math = +math.toFixed(0);
      }
    }

    return math ?? amount;
  },

  /**
   * get the currency symbole
   * @param amount
   * @returns string | null
   */
  getCurrencySymbol(amount: number | undefined, shortcut: boolean, emptySymbol = ""): string | undefined {
    if (!amount) {
      return emptySymbol !== "" ? emptySymbol : undefined;
    }

    const amountString = amount.toString();
    const firstPart = amountString.split(".")[0];
    const decimal = firstPart.toString().length - 1;
    const isEnglish = (localStorage.getItem("language") as Language) === Language.English;

    if (amount < 0) {
      return amount.toString();
    }

    if (decimal === 4) {
      const math = (amount / Math.pow(10, 3)).toFixed(1);
      const context = math[1] !== "0" ? "آلاف" : "ألف";
      const enString = shortcut ? "Thousand" : "Thousand AED";
      const aeString = shortcut ? context : ` ${context} درهم `;
      return isEnglish ? `${enString}` : `${aeString}`;
    }

    if (decimal === 5) {
      const math = (amount / Math.pow(10, 3)).toFixed(1);
      const context = math[2] !== "0" && math[1] === "0" ? "آلاف" : "ألف";
      const enString = shortcut ? "Thousand" : "Thousand AED";
      const aeString = shortcut ? context : ` ${context} درهم `;
      return isEnglish ? `${enString}` : `${aeString}`;
    }

    if (decimal >= 6 && decimal < 9) {
      const enString = shortcut ? "Million" : "Million AED";
      const aeString = shortcut ? "مليون" : "مليون درهم";
      return isEnglish ? `${enString}` : `${aeString}`;
    }

    if (decimal >= 9 && decimal < 12) {
      const enString = shortcut ? "Billion" : "Billion AED";
      const aeString = shortcut ? "مليار" : "مليار درهم";
      return isEnglish ? `${enString}` : `${aeString}`;
    }

    if (decimal >= 12) {
      const enString = shortcut ? "Trillion" : "Trillion AED";
      const aeString = shortcut ? "تريليون" : "تريليون درهم";
      return isEnglish ? `${enString}` : `${aeString}`;
    }

    return emptySymbol;
  },

  hexToRgba(value: string, alpha: string = "0.35") {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(value)) {
      c = value.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + "," + alpha + ")";
    }
    return "rgb(255 255 255)";
  },

  formatDate(epoch: number, showTime = true, opt?: Intl.DateTimeFormatOptions) {
    try {
      epoch = AdaaHelper.getDubaiTime(epoch);

      let options: Record<string, string | boolean> = {
        day: "2-digit",
        year: "numeric",
        month: "2-digit",
      };

      if (showTime) {
        options = {
          ...options,
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          timeZone: Constants.uaeTimezoneName,
        };
      }

      const dateFormatter = new Intl.DateTimeFormat(
        this.getCurrentLang() === Language.Arabic ? "ar" : "en-GB",
        opt ?? options
      );
      return dateFormatter.format(epoch).replace(",", "");
    } catch (_e) {
      return epoch;
    }
  },

  buildTree(
    items: any[],
    valueKey: string,
    textKey: string,
    parentKey: string,
    value: any = null,
    visited = new Set()
  ): ValueText[] {
    if (visited.has(value)) {
      return []; // Stop infinite recursion for circular references
    }

    visited.add(value);

    return items
      .filter((item) => item[parentKey] === value)
      .map((item) => ({
        value: item[valueKey],
        text: item[textKey],
        children: this.buildTree(items, valueKey, textKey, parentKey, item[valueKey], visited),
      }));
  },

  isObjectKPI: (objectType: number) => {
    switch (objectType) {
      case Constants.CONSTANT_NKPITYPE:
      case Constants.CONSTANT_SRVKPI:
      case Constants.CONSTANT_UAEKPI:
      case Constants.CONSTANT_EKPI:
      case Constants.CONSTANT_OPM:
      case Constants.CONSTANT_SKPITYPE:
      case Constants.CONSTANT_MOKPITYPE:
      case Constants.CONSTANT_MTKPITYPE:
      case Constants.CONSTANT_NTKPITYPE:
      case Constants.CONSTANT_DTKPITYPE:
        return true;
      default:
        return false;
    }
  },

  isFieldRequired: (catalog: ParameterCatalog[], field: string) => {
    return !!catalog.find(({ fieldName, mandatory }) => field === fieldName && mandatory === AdaaBoolean.Y);
  },
  getDubaiTime: (epoch: number) => moment(epoch).utcOffset(Constants.uaeTimezone).valueOf(),
  getDubaiTimeAsObject: (epoch: number) => moment(epoch).utcOffset(Constants.uaeTimezone).toObject(),
  getMap(entityId?: number) {
    const entityMaps = AdaaHelper.plan?.entityMaps as Record<string, unknown>[];
    return entityMaps?.find(
      (map: Record<string, unknown>) => map.entityId === (entityId ?? Number(AdaaHelper.entity?.id))
    );
  },

  /**
   * Maps an object type id (i.e SKPI) to a workflow object type id
   * This is useful when dealing with workflows or system log
   *
   * @param { number } itemTypeId
   * @returns { number}
   */
  getWorkflowItemId: (itemTypeId: number): number => {
    switch (itemTypeId) {
      case Constants.CONSTANT_OBJECTIVETYPE:
        return Constants.CONSTANT_WORKFLOW.OBJECTIVE;
      case Constants.CONSTANT_INITIATIVE:
        return Constants.CONSTANT_WORKFLOW.INITIATIVE;
      case Constants.CONSTANT_ACTIVITYTYPE:
        return Constants.CONSTANT_WORKFLOW.ACTIVITY;
      case Constants.CONSTANT_NKPITYPE:
        return Constants.CONSTANT_WORKFLOW.NKPI;
      case Constants.CONSTANT_UAEKPI:
        return Constants.CONSTANT_WORKFLOW.DKPI;
      case Constants.CONSTANT_SKPITYPE:
        return Constants.CONSTANT_WORKFLOW.SKPI;
      case Constants.CONSTANT_OPM:
        return Constants.CONSTANT_WORKFLOW.OKPI;
      case Constants.CONSTANT_EKPI:
        return Constants.CONSTANT_WORKFLOW.EKPI;
      case Constants.CONSTANT_SRVKPI:
        return Constants.CONSTANT_WORKFLOW.SEKPI;
      case Constants.CONSTANT_NATIONAL_PROJECTS:
        return Constants.CONSTANT_WORKFLOW.NATIONAL_PROJECTS;
      case Constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE:
        return Constants.CONSTANT_WORKFLOW.PROJECT_MILESTONES;
      case Constants.CONSTANT_MOKPITYPE:
        return Constants.CONSTANT_WORKFLOW.MOKPI;
      case Constants.CONSTANT_MTKPITYPE:
        return Constants.CONSTANT_WORKFLOW.MILESTONE_TARGETS;
      case Constants.CONSTANT_NTKPITYPE:
        return Constants.CONSTANT_WORKFLOW.NATIONAL_TARGET;
      case Constants.CONSTANT_DTKPITYPE:
        return Constants.CONSTANT_WORKFLOW.DIRECTIONAL_TARGET;
      case Constants.CONSTANT_MAINSERVICE:
      case Constants.CONSTANT_AUXSERVICE:
      case Constants.CONSTANT_VARSERVICE:
        return Constants.CONSTANT_WORKFLOW.SERVICE;
      case Constants.CONSTANT_ANNUAL_MEETINGS_PROJECT:
        return Constants.CONSTANT_WORKFLOW.ANNUAL_PROJECT_PROJECTS;
      case Constants.CONSTANT_ANNUAL_MEETINGS_MILESTONE:
        return Constants.CONSTANT_WORKFLOW.ANNUAL_PROJECT_MILESTONES;
      default:
        return 0;
    }
  },

  getPageLabel(nameAE: any, nameEN: any): string {
    if (this.getCurrentLang() === Language.English && this.isDefinedAndNotEmpty(nameEN)) {
      return nameEN;
    }
    if (this.getCurrentLang() === Language.English && !this.isDefinedAndNotEmpty(nameEN)) {
      return nameAE;
    }
    if (this.getCurrentLang() === Language.Arabic && this.isDefinedAndNotEmpty(nameAE)) {
      return nameAE;
    }
    if (this.getCurrentLang() === Language.Arabic && !this.isDefinedAndNotEmpty(nameAE)) {
      return nameEN;
    }
    return "";
  },

  /**
   * @description groupList of objects by prop key
   * @param list
   * @param keyGetter
   */
  groupBy(list: any[], keyGetter: any): Map<any, any> {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  },

  /**
   * @description Get month name by passing number
   * @param month
   * @param language
   * @param shortName
   */
  getMonthName(month: any, language: Language, shortName = true) {
    const shortMonths = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const fullMonths = [
      "",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const months = {
      en: shortName ? shortMonths : fullMonths,
      ae: [
        "",
        "يناير",
        "فبراير",
        "مارس",
        "أبريل",
        "مايو",
        "يونيو",
        "يوليو",
        "أغسطس",
        "سبتمبر",
        "أكتوبر",
        "نوفمبر",
        "ديسمبر",
      ],
    };

    if (language === Language.English) {
      return months.en[month];
    } else {
      return months.ae[month];
    }
  },

  yearRangeCalc: (startYearBack = 7, numberOfYears = Constants.YEAR_RANGE) => {
    const currentTS = AdaaHelper.getDubaiTime(Date.now());
    const currentYear = new Date(currentTS).getFullYear() - startYearBack;
    const options: ValueText[] = [];

    for (let i = 0; i < numberOfYears; i++) {
      options.push({
        value: currentYear + i,
        text: `${currentYear + i}`,
      });
    }

    return options;
  },

  moveItemInArray(arr: any[], fromIndex: number, toIndex: number): any[] {
    if (fromIndex < 0 || fromIndex >= arr.length || toIndex < 0 || toIndex >= arr.length) {
      return arr;
    }

    const item = arr.splice(fromIndex, 1)[0];
    arr.splice(toIndex, 0, item);

    return arr;
  },
  isNaNEvaluator: (value: string | number) => (isNaN(Number(value)) ? null : Number(value)),
  getRequiredValidator: (fields: ParameterCatalog[], field: string) =>
    AdaaHelper.isFieldRequired(fields, field) ? [Validators.required] : [],
  tableValuesEvaluator: (value: unknown): number | null => {
    switch (typeof value) {
      case "number": {
        return AdaaHelper.isNaNEvaluator(value);
      }
      case "string": {
        return value.replace(",", "").length < 1 ? null : AdaaHelper.isNaNEvaluator(value);
      }
      default:
        return null;
    }
  },

  replaceTextWithToken: {
    _fn: (text: string, { str, unit, as }: TokenOptionType) => {
      let suffixTxt: string;
      switch (as) {
        case "percent":
          suffixTxt = "%";
          break;

        default:
          suffixTxt = "";
      }
      const expression = new RegExp(`([<]{2})(${str})([>]{2})`, "gui");
      return text.replace(expression, `${unit}${suffixTxt}`);
    },
    iterator: (text: string, tokens: TokenOptionType[]) => {
      tokens.forEach((t) => {
        text = AdaaHelper.replaceTextWithToken._fn(text, t);
      });
      return text;
    },
  },
  isObject: ($t: unknown) => AdaaHelper.isDefined($t) && !Array.isArray($t) && typeof $t === "object",
};
