/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";

import { environment } from "../../core/environments/environment";
import { AdaaHelper } from "../../core/utils";
import { Language } from "../constants/enums";

@Injectable({
  providedIn: "root",
})
export class RecaptchaService {
  /**
   * Getter for the Google reCAPTCHA object.
   * @returns {any} The reCAPTCHA object from the global window object.
   */
  public get reCAPTCHA(): any {
    return (window as any).grecaptcha;
  }

  /**
   * Checks if the reCAPTCHA object and its ready method are defined.
   * @returns {boolean} True if reCAPTCHA and its ready method are defined, false otherwise.
   */
  public get isReady(): boolean {
    return AdaaHelper.isDefined(this.reCAPTCHA) && AdaaHelper.isDefined(this.reCAPTCHA.ready);
  }

  /**
   * Appends the Google reCAPTCHA script to the document head.
   * This method dynamically creates a script element and sets its attributes to load the reCAPTCHA API.
   * The script is configured to use the appropriate language based on the current application language.
   */
  public appendReCAPTCHA(): void {
    const script = document.createElement("script");
    script.innerHTML = "";
    script.src = `https://www.google.com/recaptcha/api.js?&render=${environment.google_captcha_key}&hl=${AdaaHelper.getCurrentLang() === Language.Arabic ? "ar" : "en"}`;
    script.async = true;
    script.defer = true;

    document.head.appendChild(script);
  }

  /**
   * Retrieves the reCAPTCHA token for a specified action.
   * If the reCAPTCHA object is not defined or not ready, logs an error message to the console.
   * @param action - The action for which the token is being requested (e.g., "login").
   * @param fn - A callback function to handle the token once it is retrieved.
   */
  public getToken(action: "login", fn: (token: string) => void) {
    if (!AdaaHelper.isDefined(this.reCAPTCHA)) {
      // eslint-disable-next-line no-console
      console.log(
        "%c It seems like you are trying to get token from reCAPTCHA without appending it to the page. Please use appendReCAPTCHA function inside ngOnInit",
        "background: #222; color: red; font-size: 1.5em"
      );
      return;
    } else if (!this.isReady) {
      // eslint-disable-next-line no-console
      console.log(
        "%c It seems like you are trying to get token from reCAPTCHA but it's not ready yet",
        "background: #222; color: red; font-size: 1.5em"
      );
      return;
    }

    this.reCAPTCHA.ready(() => {
      this.reCAPTCHA
        .execute(environment.google_captcha_key, {
          action: action,
        })
        .then((token: string) => fn(token));
    });
  }
}
