import { NgClass } from "@angular/common";
import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbActiveModal, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { dateRangeValidator } from "../../../../core/validators";
import { Constants } from "../../../constants/constants";
import { PageMode } from "../../../constants/enums";
import { FormTab, TpMilestone, ValidatorModelType, ValueText } from "../../../models";
import { UsersApiService } from "../../../services";
import { ContributingEntitiesComponent } from "../../contributing-entities/contributing-entities.component";
import {
  FormDropdownComponent,
  FormDropdownMultiComponent,
  FormInputComponent,
  FormInputDateComponent,
  FormStepsComponent,
} from "../../form";
import { TeamMembersListComponent } from "../team-members-list/team-members-list.component";

@Component({
  selector: "adaa-milestone-modal",
  standalone: true,
  imports: [
    TranslateModule,
    NgClass,
    ReactiveFormsModule,
    ContributingEntitiesComponent,
    FormDropdownComponent,
    FormDropdownMultiComponent,
    FormInputComponent,
    FormInputDateComponent,
    FormStepsComponent,
    TeamMembersListComponent,
  ],
  templateUrl: "./milestone-modal.component.html",
  styleUrl: "./milestone-modal.component.scss",
})
export class MilestoneModalComponent implements OnInit {
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _formBuilder = inject(FormBuilder);
  private _usersApiService = inject(UsersApiService);
  modal = inject(NgbActiveModal);

  @Input() milestone: TpMilestone;
  @Input() pageMode: PageMode;
  @Input() formValidation: ValidatorModelType;
  @Input() entities: ValueText[];
  @Input() otherEntities: ValueText[];
  @Input() parentEntities: ValueText[];
  @Input() parentOtherEntities: ValueText[];
  @Input() parentEntityId: number;
  @Input() parentSectors: ValueText[];
  @Input() enableTeamMembers: boolean = true;
  //NOTE: This to add a validation for start/end date for the milestone dates
  @Input() projectStartDate: number | undefined;
  @Input() projectEndDate: number | undefined;

  constants = Constants;
  milestoneForm: FormGroup;
  submitted: boolean = false;
  activeTab = signal<number>(0);
  owners = signal<ValueText[]>([]);
  tabs: FormTab[] = [];

  public get milestoneLimits() {
    const minDate = this.projectStartDate ? new Date(this.projectStartDate) : undefined;
    const maxDate = this.projectEndDate ? new Date(this.projectEndDate) : undefined;

    return {
      min: minDate
        ? ({
            year: minDate.getFullYear(),
            month: minDate.getMonth() + 1,
            day: minDate.getDate(),
          } as NgbDateStruct)
        : null,
      max: maxDate
        ? ({
            year: maxDate.getFullYear(),
            month: maxDate.getMonth() + 1,
            day: maxDate.getDate(),
          } as NgbDateStruct)
        : null,
    };
  }

  public get filteredEntites(): ValueText[] {
    const entities: number[] = [];

    const entityId = this.milestoneForm.get("entityId")?.value;
    if (entityId) entities.push(+entityId);

    let contributingEntities = this.milestoneForm.get("contributingEntities")?.value;
    if (contributingEntities) {
      contributingEntities = contributingEntities
        .filter((e: { otherEntityId: number | undefined }) => !e.otherEntityId)
        .map((e: { entityId: number }) => e.entityId);

      if (contributingEntities) entities.push(...contributingEntities);
    }

    return this.entities?.filter((e) => entities.includes(e.value));
  }

  public get filteredOtherEntites(): ValueText[] {
    const entities: number[] = [];

    let contributingEntities = this.milestoneForm.get("contributingEntities")?.value;
    if (contributingEntities) {
      contributingEntities = contributingEntities
        .filter((e: { otherEntityId: number | undefined }) => e.otherEntityId)
        .map((e: { otherEntityId: number }) => e.otherEntityId);

      if (contributingEntities) entities.push(...contributingEntities);
    }

    return this.otherEntities?.filter((e) => entities.includes(e.value));
  }

  public ngOnInit(): void {
    this.tabs = this.enableTeamMembers
      ? [
          {
            title: "common.form.label.information",
          },
          {
            title: "kpi.contributing_entities",
          },
          {
            title: "national_projects.project_team_members.common_text",
          },
        ]
      : [
          {
            title: "common.form.label.information",
          },
          {
            title: "kpi.contributing_entities",
          },
        ];

    this.milestoneForm = this._prepareForm();
    this.milestoneForm.patchValue(this.milestone);

    this.milestoneForm.get("weight")?.setValue(this.milestone?.workload?.workload);
    this.milestoneForm.get("sectors")?.setValue(this.milestone?.nationalSectors?.map((e) => e.nationalSectorId));
    this.entityChanged(this.milestone?.entityId);
  }

  public entityChanged(value: number | undefined): void {
    if (
      AdaaHelper.isDefined(this.parentEntityId) &&
      this.milestoneForm.get("entityId")?.value === this.parentEntityId
    ) {
      this.milestoneForm.get("otherEntityId")?.enable();
    } else {
      this.milestoneForm.get("otherEntityId")?.setValue(null);
      this.milestoneForm.get("otherEntityId")?.disable();
    }

    if (!value) {
      //Reset Inputs
      this.milestoneForm.get("owner")?.setValue(null);

      //Reset ddl
      this.owners.set([]);
    } else {
      this._getOwners(value);

      const entity = this.entities.find((e) => e.value === value);
      if (entity) {
        this.milestoneForm.get("entityNameAE")?.setValue(entity.text);
        this.milestoneForm.get("entityNameEN")?.setValue(entity.text);
      }
    }
  }

  public otherEntityChanged(value: number | undefined): void {
    if (!value) return;
    const entity = this.otherEntities.find((e) => e.value === value);
    if (entity) {
      this.milestoneForm.get("otherEntityNameAE")?.setValue(entity.text);
      this.milestoneForm.get("otherEntityNameEN")?.setValue(entity.text);
    }
  }

  public weightChanged(value: number | undefined): void {
    this.milestoneForm.get("workload")?.setValue({ workload: value });
  }

  public sectorsChanged(event: number[]): void {
    const nationalSectors = this.milestoneForm.get("nationalSectors")?.value ?? [];

    event.forEach((id) => {
      const item = nationalSectors.find((e: { nationalSectorId: number }) => e.nationalSectorId === id);
      if (!item) {
        const ns = {
          nationalSectorId: id,
          nameEN: "",
          nameAE: "",
          status: Constants.OBJECT_STATUS.ACTIVE,
        };
        nationalSectors.push(ns);
      } else {
        item.status = Constants.OBJECT_STATUS.ACTIVE;
      }
    });

    for (let i = nationalSectors.length - 1; i >= 0; i--) {
      if (!event.includes(nationalSectors[i].nationalSectorId)) {
        if ("id" in nationalSectors[i]) {
          nationalSectors[i].status = Constants.OBJECT_STATUS.REMOVE;
        } else {
          nationalSectors.splice(i, 1);
        }
      }
    }

    this.milestoneForm.get("nationalSectors")?.setValue(nationalSectors);
  }

  public save(): void {
    this.submitted = true;

    if (!this.milestoneForm.valid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const data = this.milestoneForm.getRawValue();

    if (!AdaaHelper.isDefined(data.workload)) data.workload = {};

    if (AdaaHelper.isDefined(data.weight)) data.workload.workload = data.weight ? +data.weight : undefined;

    this.modal.close(data);
  }

  private _prepareForm() {
    return this._formBuilder.group(
      {
        budget: { value: null, disabled: false },
        contributingEntities: { value: null, disabled: false },
        dscAE: [{ value: null, disabled: false }, Validators.required],
        dscEN: [{ value: null, disabled: false }, Validators.required],
        endDate: [{ value: null, disabled: false }, Validators.required],
        entityId: [{ value: null, disabled: false }, Validators.required],
        entityNameAE: { value: null, disabled: false },
        entityNameEN: { value: null, disabled: false },
        otherEntityId: { value: null, disabled: false },
        otherEntityNameAE: { value: null, disabled: false },
        otherEntityNameEN: { value: null, disabled: false },
        id: { value: null, disabled: false },
        milestoneOrder: { value: null, disabled: false },
        nameAE: [{ value: null, disabled: false }, Validators.required],
        nameEN: [{ value: null, disabled: false }, Validators.required],
        nationalProjectId: { value: null, disabled: false },
        nationalProjectNameAE: { value: null, disabled: false },
        nationalProjectNameEN: { value: null, disabled: false },
        nationalSectors: { value: null, disabled: false },
        onTimeProgress: { value: null, disabled: false },
        onTimeProgressColor: { value: null, disabled: false },
        owner: [{ value: null, disabled: false }, Validators.required],
        ownerNameAE: { value: null, disabled: false },
        ownerNameEN: { value: null, disabled: false },
        refCode: { value: null, disabled: false },
        startDate: { value: null, disabled: false },
        status: { value: null, disabled: false },
        teamMembers: { value: null, disabled: false },
        updateTS: { value: null, disabled: false },
        updateUser: { value: null, disabled: false },
        weight: { value: null, disabled: false },
        workload: { value: null, disabled: false },
        progress: { value: null, disabled: false },
        progressColor: { value: null, disabled: false },

        //ONLY FE
        fakeId: { value: null, disabled: false },
        sectors: { value: null, disabled: false },
      },
      {
        validators: Validators.compose([dateRangeValidator("startDate", "endDate")]),
      }
    );
  }

  private _getOwners(entityId: number): void {
    this._usersApiService.getOwnersPerEntity(entityId).subscribe({
      next: (response) => {
        if (response.inError) return;

        this.owners.set(
          AdaaHelper.setDropdownArray(response.responseData ?? [], "id", AdaaHelper.getFieldLanguage("name"))
        );

        const isOwnerAvailable = this.owners()?.find((e) => e.value === this.milestoneForm.get("owner")?.value);
        if (!isOwnerAvailable) this.milestoneForm.get("owner")?.setValue(null);
      },
    });
  }
}
