import { Component, computed, input } from "@angular/core";
import { TranslatePipe } from "@ngx-translate/core";

import { AdaaHelper, useNewDS } from "../../../../../../../core/utils";
import type { ListSectionConfigType } from "../../wf-difference.types";

@Component({
  selector: "adaa-list-diff-view",
  standalone: true,
  imports: [TranslatePipe],
  styleUrl: "../styles.scss",
  template: `
    @if (!showDIffOnly() || hasAnyDiff()) {
      @if (isVisible()) {
        <section class="row d-flex align-content-center">
          <div
            class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6"
            [class.col-6]="showOnlyNew()"
            [class.col-4]="!showOnlyNew()"
          >
            {{ label() | translate }}
          </div>

          <div
            class="p-2 border border-white tbl-col d-flex align-items-center flex-wrap"
            [class.rounded-end-1]="showOnlyNew()"
            [class.col-6]="showOnlyNew()"
            [class.col-4]="!showOnlyNew()"
            [class.tbl-diff]="showOnlyNew()"
          >
            @let list = newDataList();

            @for (item of list; track $index) {
              @if (!showDIffOnly() || item.hasDiff) {
                <span
                  role="listitem"
                  class="d-block w-100 my-1 p-2"
                  [class.has-diff]="item.hasDiff"
                  [class.no-data]="item.noData"
                >
                  @if (helpers.isDefinedAndNotEmpty(item.label)) {
                    <div class="d-block w-100 mb-1">
                      <span class="badge text-white bg-primary">
                        {{ item.label }}
                      </span>
                    </div>
                  }

                  {{ item.text }} &nbsp;
                </span>
              }
            }
          </div>

          @if (!showOnlyNew()) {
            <div class="col-4 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-center flex-wrap">
              @let list = oldDataList();

              @for (item of list; track $index) {
                @if (!showDIffOnly() || item.hasDiff) {
                  <span
                    role="listitem"
                    class="d-block w-100 my-1 p-2"
                    [class.has-diff]="item.hasDiff"
                    [class.no-data]="item.noData"
                  >
                    @if (helpers.isDefinedAndNotEmpty(item.label)) {
                      <div class="d-block w-100 mb-1">
                        <span class="badge text-white bg-primary">
                          {{ item.label }}
                        </span>
                      </div>
                    }

                    {{ item.text }} &nbsp;
                  </span>
                }
              }
            </div>
          }
        </section>
      }
    }
  `,
})
export class ListDiffViewComponent {
  showOnlyNew = input.required<boolean>();
  config = input.required<ListSectionConfigType>();
  newData = input<Record<string, never>>();
  oldData = input<Record<string, never>>();
  isAuditTrail = input<boolean>();
  showDIffOnly = input<boolean>(false);

  newList = computed(() => {
    const conf = this.config();
    const data = this.newData();

    if (!data || !conf || !AdaaHelper.isDefined(data?.[conf.field])) return [];
    const list = data?.[conf.field];
    if (!Array.isArray(list)) return [];
    return list;
  });
  oldList = computed(() => {
    const conf = this.config();
    const data = this.oldData();

    if (!data || !conf || !AdaaHelper.isDefined(data?.[conf.field])) return [];
    const list = data?.[conf.field];
    if (!Array.isArray(list)) return [];
    return list;
  });
  label = computed(() => {
    const { label } = this.config();

    if (!label) return "";
    if (typeof label === "string") return label;

    return label({ useNewDS: this.useNewDS() } as never);
  });
  useNewDS = computed(() => {
    if (this.newData()) return useNewDS(this.newData()?.planId);
    if (this.oldData()) return useNewDS(this.oldData()?.planId);
    return false;
  });
  computedData = computed(() => {
    const newData = this.newList();
    const oldData = this.oldList();

    const processedOldData = this._processList(oldData, newData);
    const processedNewData = this._processList(newData, oldData);

    return {
      newDataList: this._alignNewDataToOldDataOrder(processedOldData, processedNewData),
      oldDataList: processedOldData,
    };
  });

  newDataList = computed(() => this.computedData().newDataList);
  oldDataList = computed(() => this.computedData().oldDataList);

  isVisible = computed(() => {
    const config = this.config();
    if (!config.visibleIf) return true;
    if (typeof config.visibleIf !== "function") return true;
    const data = this.newData();
    return config.visibleIf(data as never, [this.isAuditTrail()] as never);
  });
  hasAnyDiff = computed(() => {
    const hasNewDiff = this.newDataList().some(({ hasDiff }) => hasDiff);
    const hasOldDiff = this.oldDataList().some(({ hasDiff }) => hasDiff);
    return hasNewDiff || hasOldDiff;
  });

  readonly helpers = AdaaHelper;

  private _processList($new: Record<string, never>[], $old: Record<string, never>[]) {
    const conf = this.config();
    const id = conf.idKey;
    const output = [];

    if (conf.derivedList && typeof conf.derivedList === "function") {
      $new = conf.derivedList($new as never) as Record<string, never>[];
      $old = conf.derivedList($old as never) as Record<string, never>[];
    }

    $new.sort((a, b) => (a[id] > b[id] ? 1 : -1));
    $old.sort((a, b) => (a[id] > b[id] ? 1 : -1));

    for (const item of $new) {
      const index = $old.map((obj) => obj[id]).indexOf(item[id]);

      if (index === -1) {
        let text = AdaaHelper.getItemValueByToken(item, "name");
        if (conf.format) {
          text = conf.format(item as never) as string;
        }

        let label: unknown;
        if (AdaaHelper.isDefined(conf.labelledItem)) {
          label = typeof conf.labelledItem === "function" ? conf.labelledItem(item as never) : conf.labelledItem;
        }

        output.push({
          label,
          text,
          hasDiff: true,
          id: item[id],
          isNew: true,
          isRemoved: false,
        });
      } else {
        let hasDiff = false;
        if (conf.hasDiff) {
          hasDiff = conf.hasDiff(item as never, $old[index] as never);
        }

        let text = AdaaHelper.getItemValueByToken(item, "name");
        if (conf.format && typeof conf.format === "function") {
          text = conf.format(item as never) as string;
        }

        let label: unknown;
        if (AdaaHelper.isDefined(conf.labelledItem)) {
          label = typeof conf.labelledItem === "function" ? conf.labelledItem(item as never) : conf.labelledItem;
        }

        output.push({
          label,
          text,
          hasDiff: this.showOnlyNew() || hasDiff,
          id: item[id],
          isNew: false,
          isRemoved: false,
        });
      }
    }

    for (const item of $old) {
      const index = $new.map((obj) => obj[id]).indexOf(item[id]);

      let text = AdaaHelper.getItemValueByToken(item, "name");
      if (conf.format && typeof conf.format === "function") {
        text = conf.format(item as never) as string;
      }

      let label: unknown;
      if (AdaaHelper.isDefined(conf.labelledItem)) {
        label = typeof conf.labelledItem === "function" ? conf.labelledItem(item as never) : conf.labelledItem;
      }

      if (index === -1) {
        output.push({
          label,
          text,
          hasDiff: true,
          id: item[id],
          noData: true,
          isNew: false,
          isRemoved: true,
        });
      }
    }

    return output;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _alignNewDataToOldDataOrder(oldData: any[], newData: any[]) {
    const newMap = new Map(newData.map((item) => [item.text, item]));
    const alignedData = [];

    for (const oldItem of oldData) {
      const newItem = newMap.get(oldItem.text) || {
        text: newData.find((item) => item.id === oldItem.id).text,
        hasDiff: true,
        isNew: true,
        isRemoved: false,
      };
      alignedData.push(newItem);
    }

    return alignedData;
  }
}
