import { computed, effect, inject, Injectable, signal } from "@angular/core";
import { BehaviorSubject, forkJoin, tap } from "rxjs";

import type { PermissionModelType } from "../../../adaa-types";
import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import { AdaaBoolean, PermissionEntityFlag } from "../constants/enums";
import type { PermissionActionModelType } from "../models";
import { RolesApiService } from "./roles-api.service";
import { UsersApiService } from "./users-api.service";

type PermissionMapStructType = Map<number, PermissionModelType>;
type EntityPermissionMapStructType = { [entityId: number]: PermissionMapStructType };

@Injectable({
  providedIn: "root",
})
export class PermissionsService {
  private _rolesApiService = inject(RolesApiService);
  private _usersApiService = inject(UsersApiService);

  isAdmin = signal<boolean>(false);

  loadingPermissionComplete = signal<boolean>(false);
  entityPermissions = signal<EntityPermissionMapStructType>({});
  userPermissions = signal<PermissionMapStructType>(new Map(), {
    equal: (a, b) => a.size === b.size,
  });

  /**
   * This property to make sure we are loading the permission in the right way
   * It will be true when the permission are fully loaded
   * VERY IMPORTANT always use .unsubscribe or .pipe(AdaaHelper.untilDestroyed()) when using it
   */
  isPermissionsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  hasPermissionsLoaded = computed(() => {
    const userPermissions = this.userPermissions();
    const entityPermissions = this.entityPermissions();

    if (Object.keys(entityPermissions).length === 0 || userPermissions.size === 0) {
      this.isPermissionsLoaded.next(false);
      return false;
    }

    this.isPermissionsLoaded.next(true);
    return true;
  });

  constructor() {
    effect(() => {
      const userPermissions = this.userPermissions();
      const entityPermissions = this.entityPermissions();
      if (Object.keys(entityPermissions).length === 0 || userPermissions.size === 0) {
        this.loadingPermissionComplete.set(false);
      } else {
        this.loadingPermissionComplete.set(true);
      }
    });
  }

  private get _entityId() {
    const entityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "prop",
      property: "id",
    });

    return Number(entityId as string);
  }

  public hasPermission(action: PermissionActionModelType[], modifier: "or" | "and" = "and") {
    if (this.isAdmin()) {
      return true;
    }

    let map = this.entityPermissions()[this._entityId];
    map ||= this.userPermissions();

    let hasPermission: boolean;
    if (modifier === "or") {
      hasPermission = action.some((item) => {
        const permission = map.get(item.objectTypeId);

        return permission?.[item.permissionAction] === AdaaBoolean.Y;
      });
    } else {
      hasPermission = action.every((item) => {
        const permission = map.get(item.objectTypeId);
        const flag = this._checkEntityFlag(item);
        return permission?.[item.permissionAction] === AdaaBoolean.Y && flag;
      });
    }

    return hasPermission;
  }

  /**
   * Load user permissions
   * @note Called by pages that require user permissions
   */
  public loadUserPermissions() {
    this._resetSignals();

    const request$ = [
      this._rolesApiService.getAllMergedUserPermissionsEntity(),
      this._rolesApiService.getAllMergedUserPermissions(),
      this._usersApiService.hasSysAdminRole(),
    ];

    return forkJoin(request$).pipe(
      tap({
        next: ([resEntityRoles, resUserRoles, resIsAdmin]) => {
          if (resIsAdmin.responseData) {
            this.isAdmin.update(() => resIsAdmin.responseData as boolean);
          }

          if (resUserRoles.responseData) {
            this._updateUserPermissionMap(resUserRoles.responseData as PermissionModelType[]);
          }

          if (resEntityRoles.responseData) {
            this._updateEntityPermissionMap(
              resEntityRoles.responseData as { [entityId: number]: PermissionModelType[] }
            );
          }
        },
      })
    );
  }

  private _checkEntityFlag({ entityFlag }: PermissionActionModelType) {
    if (entityFlag === PermissionEntityFlag.pmo) {
      return this._entityId === Constants.CONSTANT_PMO_ID;
    }

    if (entityFlag === PermissionEntityFlag.notPmo) {
      return this._entityId !== Constants.CONSTANT_PMO_ID;
    }

    return true;
  }

  private _updateUserPermissionMap(data: PermissionModelType[]) {
    const map = new Map<number, PermissionModelType>();

    data.forEach((item) => {
      map.set(item.objectTypeId, item);
    });

    this.userPermissions.update(() => map);
  }

  private _updateEntityPermissionMap(data: { [entityId: number]: PermissionModelType[] }) {
    const map: EntityPermissionMapStructType = {};

    for (const entityId in data) {
      map[entityId] = new Map<number, PermissionModelType>();

      data[entityId].forEach((item) => {
        map[entityId].set(item.objectTypeId, item);
      });
    }

    this.entityPermissions.update(() => map);
  }

  private _resetSignals() {
    this.isAdmin.update(() => false);
    this.userPermissions.update(() => new Map());
    this.entityPermissions.update(() => ({}));
  }
}
