<div class="modal-header">
    <h4 class="modal-title">{{ "tooltips.settings" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="settingsForm" class="row">
        <div class="col-12">
            <adaa-form-checkbox
                controlName="selectALL"
                formControlName="selectALL"
                [label]="'common.form.label.select_all' | translate"
                (inputChanges)="selectALL($event)"
                class="fw-bold justify-content-center"
            />
        </div>
        @for (item of tableColumns; track item) {
            <div class="col-sm-12 col-xs-12" [ngClass]="tableColumns.length === 1 ? 'col-md-12' : 'col-md-6'">
                <adaa-form-checkbox
                    [label]="AdaaHelper.getItemValueByToken(item, 'name')"
                    [controlName]="item.fieldName"
                    [formControlName]="item.fieldName"
                />
            </div>
        }
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close()">
        {{ "common.form.button.cancel" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="apply()">
        {{ "common.form.button.apply" | translate }}
    </button>
</div>
