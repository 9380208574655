<div class="modal-header">
    <h4 class="modal-title">{{ title | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    @if (configKey) {
        <adaa-data-table
            [configKey]="configKey"
            [isLocalData]="true"
            [enableAdd]="false"
            [enableActionsCell]="false"
            [enableFilter]="false"
            [enableSettings]="false"
            [localData]="teamMembers"
        >
        </adaa-data-table>
    }
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close()">
        {{ "common.form.button.close" | translate }}
    </button>
</div>
