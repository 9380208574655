import { CommonModule } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { Constants } from "../../../constants/constants";
import { TpComment } from "../../../models";
import { CommentsComponent } from "../../general";

@Component({
  selector: "adaa-tp-comments-modal",
  standalone: true,
  imports: [TranslateModule, CommonModule, CommentsComponent],
  templateUrl: "./tp-comments-modal.component.html",
  styleUrl: "./tp-comments-modal.component.scss",
})
export class TpCommentsModalComponent {
  modal: NgbActiveModal = inject(NgbActiveModal);

  @Input() comments: TpComment[];

  constants = Constants;
}
