import { inject, Injectable, signal } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ChartConfiguration } from "chart.js";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import { AggregationPerfModelType, SelectedPeriodModelType, ValueText } from "../models";
import { CalculationEngineApiService } from "./calculation-engine-api.service";
import { ChartsService } from "./charts.service";
import { PropertiesService } from "./properties.service";
import { SystemLayoutService } from "./system-layout.service";

@Injectable({
  providedIn: "root",
})
export class ExecutiveDashboardService {
  private _calculationEngineApiService = inject(CalculationEngineApiService);
  private _systemLayoutService = inject(SystemLayoutService);
  private _chartsService = inject(ChartsService);
  private _propertiesService = inject(PropertiesService);
  private _translateService = inject(TranslateService);

  performances = signal<AggregationPerfModelType[]>([]);
  selectedPerformance = signal<AggregationPerfModelType | undefined>(undefined);
  performanceChartsData = signal<ChartConfiguration<"bar">["data"] | undefined>(undefined);
  rankChartsData = signal<ChartConfiguration<"line">["data"] | undefined>(undefined);

  entityTypes: ValueText[] = [];
  entitySizes: ValueText[] = [];

  public getEntitesTypes(): void {
    this._translateService.get(["national_projects.all_entities"]).subscribe({
      next: (value) => {
        const allEntitiesOption: ValueText = {
          value: 0,
          text: value["national_projects.all_entities"],
        };

        this._propertiesService
          .getPropByIdList([Constants.CONSTANT_ENTITY_TYPE, Constants.CONSTANT_ENTITY_SIZE])
          .subscribe((response) => {
            if (response.inError) return;
            const entitySizes = response.responseData.filter((e) => e.propType == Constants.CONSTANT_ENTITY_SIZE);
            this.entitySizes = AdaaHelper.setDropdownArray(entitySizes, "id", AdaaHelper.getFieldLanguage("name"));

            const entityTypes = response.responseData.filter((e) => e.propType == Constants.CONSTANT_ENTITY_TYPE);
            this.entityTypes = AdaaHelper.setDropdownArray(entityTypes, "id", AdaaHelper.getFieldLanguage("name"));

            this.entitySizes.unshift(allEntitiesOption);
            this.entityTypes.unshift(allEntitiesOption);
          });
      },
    });
  }

  public getPerformance(selectedPeriod: SelectedPeriodModelType | undefined, entitySize: number, entityType: number) {
    if (!selectedPeriod) return;

    if (AdaaHelper.isPMOEntity())
      this._calculationEngineApiService
        .getPMOPerformance({
          period: selectedPeriod.type === "year" ? selectedPeriod.period.year : selectedPeriod.period.id,
          isAnnual: selectedPeriod.type === "year",
          overallPerformanceContributing: true,
          entitySize: entitySize,
          entityType: entityType,
        })
        .subscribe((response) => {
          if (response.inError) return;
          this.performances.set(response.responseData);
          this.selectedPerformance.set(response.responseData[0]);
          this.getPerformanceGraph(selectedPeriod, entitySize, entityType);
          this.getPreformanceOverYears(entitySize, entityType);
        });
    else
      this._calculationEngineApiService
        .getEntityPerformance({
          entityId: this._systemLayoutService.entity!.id,
          period: selectedPeriod.type === "year" ? selectedPeriod.period.year : selectedPeriod.period.id,
          isAnnual: selectedPeriod.type === "year",
          overallPerformanceContributing: true,
        })
        .subscribe((response) => {
          if (response.inError) return;
          this.performances.set(response.responseData);
          this.selectedPerformance.set(response.responseData[0]);
          this.getPerformanceGraph(selectedPeriod, entitySize, entityType);
          this.getPreformanceOverYears(entitySize, entityType);
        });
  }

  public getPerformanceGraph(
    selectedPeriod: SelectedPeriodModelType | undefined,
    entitySize: number,
    entityType: number
  ) {
    if (!selectedPeriod) return;

    const isPMO = AdaaHelper.isPMOEntity();

    let subTitle = "";
    if (entityType) subTitle = this.entityTypes.find((e) => e.value === entityType)!.text;
    if (entitySize) {
      subTitle += AdaaHelper.isDefinedAndNotEmpty(subTitle) ? " - " : "";
      subTitle += this.entitySizes.find((e) => e.value === entitySize)!.text;
    }

    this._chartsService.initBarChartOptions({
      title: `${AdaaHelper.getItemValueByToken(this.selectedPerformance(), "label")} ${selectedPeriod?.period.year}`,
      subtitle: subTitle,
    });

    this._calculationEngineApiService
      .getPerformanceGraph({
        isAnnual: selectedPeriod.type === "year",
        isEnabler: false,
        aggregationType: this.selectedPerformance()?.aggregationType,
        isGovernment: isPMO,
        period: selectedPeriod.type === "year" ? selectedPeriod.period.year : selectedPeriod.period.id,
        entityId: isPMO ? undefined : this._systemLayoutService.entity!.id,
        entitySize: entitySize,
        entityType: entityType,
      })
      .subscribe((response) => {
        if (response.inError) return;
        this.performanceChartsData.set({
          labels: response.responseData.map((e) => AdaaHelper.getItemValueByToken(e, "entityName") ?? " "),
          datasets: [
            {
              data: response.responseData.map((e) => e.score),
              backgroundColor: response.responseData.map((e) =>
                isPMO
                  ? Constants.COLOR_CODES[e.scoreColor]
                  : e.entityId === this._systemLayoutService.entity!.id
                    ? Constants.COLOR_CODES[Constants.CONSTANT_COLORS.EVALUATION_GOLD]
                    : Constants.COLOR_CODES[Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY]
              ),
            },
          ],
        });
      });
  }

  public getPreformanceOverYears(entitySize: number, entityType: number) {
    const isPMO = AdaaHelper.isPMOEntity();

    let title = "";
    if (isPMO)
      title = `${AdaaHelper.getItemValueByToken(this.selectedPerformance(), "label")} ${this._translateService.instant("executive_dashboard.for_previous_years")}`;
    else
      title = `${AdaaHelper.getItemValueByToken(this.selectedPerformance(), "label")} ${this._translateService.instant("executive_dashboard.rank")} ${this._translateService.instant("executive_dashboard.for_previous_years")}`;

    this._chartsService.initLineChartOptions({ title: title, isRank: !isPMO });

    this._calculationEngineApiService
      .getPreformanceOverYears({
        aggregationType: this.selectedPerformance()!.aggregationType,
        entityId: isPMO ? undefined : this._systemLayoutService.entity?.id,
        entitySize: entitySize,
        entityType: entityType,
      })
      .subscribe((response) => {
        if (response.inError) return;
        this.rankChartsData.set({
          labels: isPMO
            ? response.responseData.scores.map((e) => e.year)
            : response.responseData.ranks.map((e) => e.year),
          datasets: [
            {
              data: isPMO
                ? response.responseData.scores.map((e) => e.score)
                : response.responseData.ranks.map((e) => e.rank),
              backgroundColor: Constants.COLOR_CODES[Constants.CONSTANT_COLORS.EVALUATION_GOLD],
              borderColor: Constants.COLOR_CODES[Constants.CONSTANT_COLORS.EVALUATION_GOLD],
            },
          ],
        });
      });
  }
}
