import { inject, Injectable } from "@angular/core";

import {
  DataEntryMetricModel,
  DimensionValue,
  EntityModelType,
  MainResponseModel,
  MetricDimension,
  MonitoredKpiModelType,
  ParentMetric,
  PropTypeModelType,
  SearchFields,
} from "../models";
import { DataEntryMetricResponse } from "../models/metrics.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class MetricApiService {
  private readonly _mainService = inject(MainService);

  public createMetric(data: Partial<DataEntryMetricModel>) {
    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url: `libmetric/create?isFinish=true`,
      data,
    });
  }

  public updateMetric(data: Partial<DataEntryMetricModel>) {
    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url: `libmetric/update?isFinish=true`,
      data,
    });
  }

  public updateDataEntryMetrics(data: Partial<DataEntryMetricResponse>[]) {
    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "POST",
      url: `dataentry/updateMetricEntry`,
      data,
    });
  }

  public deleteMetric(id: number) {
    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "DELETE",
      url: `libmetric/deleteById/${id}`,
    });
  }

  public deleteDataEntry(id: number) {
    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "DELETE",
      url: `dataentry/deleteById/${id}`,
    });
  }

  public getAllMetrics() {
    return this._mainService.sendRequest<MainResponseModel<DataEntryMetricModel[]>>({
      method: "POST",
      url: `libmetric/getallv2`,
      data: {},
    });
  }

  public getRelatedKpis(id: number) {
    return this._mainService.sendRequest<MainResponseModel<MonitoredKpiModelType[]>>({
      method: "GET",
      url: `libmetric/getRelatedKpis?metricId=${id}`,
    });
  }

  public getByEntityId(metricType: string, entityId: number) {
    return this._mainService.sendRequest<MainResponseModel<DataEntryMetricModel[]>>({
      method: "GET",
      url: `libmetric/getbyentityid/${metricType}/${entityId}`,
    });
  }

  public getDataEntryByMetricId(id: number) {
    return this._mainService.sendRequest<MainResponseModel<DataEntryMetricResponse[]>>({
      method: "GET",
      url: `dataentry/getByMetricId/${id}`,
    });
  }

  public getById(id: number) {
    return this._mainService.sendRequest<MainResponseModel<DataEntryMetricModel>>({
      method: "GET",
      url: `libmetric/getbyid/${id}`,
    });
  }

  public getAllMonitoring(limit?: number, offset?: number, searchFields: SearchFields = { searchFields: {} }) {
    const maxValue = 9_999_999;

    const qs = new URLSearchParams();
    qs.set("limit", `${limit ?? maxValue}`);
    qs.set("offset", `${offset ?? 0}`);

    return this._mainService.sendRequest<MainResponseModel<DataEntryMetricModel[]>>({
      method: "POST",
      url: `libmetric/getAllMonitoring?${qs}`,
      data: searchFields,
    });
  }

  public getAllSharedAndLocal(limit = 10, offset = 0, searchFields: SearchFields = { searchFields: {} }) {
    const qs = new URLSearchParams();
    qs.set("limit", `${limit}`);
    qs.set("offset", `${offset}`);

    return this._mainService.sendRequest<MainResponseModel<DataEntryMetricModel[]>>({
      method: "POST",
      url: `libmetric/getAllSharedAndLocal?${qs}`,
      data: searchFields,
    });
  }

  public getAvailableDashboardTypes() {
    return this._mainService.sendRequest<MainResponseModel<PropTypeModelType[]>>({
      method: "GET",
      url: `libmetric/availableDashboardType`,
    });
  }

  public getParentMetrics(entityId?: number) {
    return this._mainService.sendRequest<MainResponseModel<ParentMetric[]>>({
      method: "GET",
      url: `libmetric/getParentMetrics${entityId ? "?entityId=" + entityId.toString() : ""}`,
    });
  }

  public getDimensionsByParentMetric(parentMetricId: number) {
    return this._mainService.sendRequest<MainResponseModel<MetricDimension[]>>({
      method: "GET",
      url: `libmetric/getDimensionsByParentMetric?parentMetricId=${parentMetricId}`,
    });
  }

  public getDimensionsValues(dimensionsValues: number[]) {
    const qs = new URLSearchParams();

    dimensionsValues.forEach((e) => qs.append("dimensionId", e.toString()));

    return this._mainService.sendRequest<MainResponseModel<DimensionValue[]>>({
      method: "GET",
      url: `libmetric/getDimensionsValues?${qs}`,
    });
  }

  public getParentMetricEntities() {
    return this._mainService.sendRequest<MainResponseModel<EntityModelType[]>>({
      method: "GET",
      url: `libmetric/getParentMetricEntities`,
    });
  }
}
