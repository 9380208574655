import { inject, Injectable } from "@angular/core";

import { MainResponseModel, TpCycle } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class TpCyclesApiService {
  private _mainService = inject(MainService);

  public getAllCycles() {
    const url = `national-project-cycle/getall`;

    return this._mainService.sendRequest<MainResponseModel<TpCycle[]>>({
      method: "POST",
      url,
      data: {},
    });
  }

  public getTPCycleById(tpCycleId: number) {
    const url = `national-project-cycle/getbyid/${tpCycleId}`;

    return this._mainService.sendRequest<MainResponseModel<TpCycle>>({
      method: "GET",
      url,
    });
  }

  public createTPCycle(tpCycle: Partial<TpCycle>) {
    const url = `national-project-cycle/create`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data: tpCycle,
    });
  }

  public updateTPCycle(data: Partial<TpCycle>) {
    const url = `national-project-cycle/update`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public deleteTPCycle(tpCycleId: number) {
    const url = `national-project-cycle/delete/${tpCycleId}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "DELETE",
      url,
    });
  }
}
