<div class="modal-header">
    <h4 class="modal-title">
        {{ cardTitle | translate }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <adaa-form-input [isTextArea]="true" (inputChanges)="comment = $event" />
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ "common.form.button.cancel" | translate }}
    </button>
    <button
        type="button"
        class="btn btn-primary"
        [disabled]="!comment || comment.length < 3"
        (click)="comment && comment.length >= 3 && modal.close(comment)"
    >
        {{ "common.form.button.save" | translate }}
    </button>
</div>
