import {
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { Subscription } from "rxjs";

import { AdaaHelper } from "../../../../core/utils/adaa-helper";
import { AdaaBoolean } from "../../../constants/enums";
import type { ParameterCatalog } from "../../../models";
import { LanguageService } from "../../../services";

type CheckboxValueType = "Y" | "N" | boolean | null | undefined;

@Component({
  selector: "adaa-form-checkbox",
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: "./form-checkbox.component.html",
  styleUrl: "./form-checkbox.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormCheckboxComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormCheckboxComponent),
    },
  ],
})
export class FormCheckboxComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  readonly languageService = inject(LanguageService);

  inputControl: FormControl = new FormControl();
  adaaHelper = AdaaHelper;
  direction: "ltr" | "rtl" = "ltr";

  private _onValidatorChange: () => void;
  private _onTouched: () => void;
  private _onChange: (value: CheckboxValueType) => void;
  private _valueChanges$: Subscription;

  useSwitch = input<boolean>(false);

  @Input() required: boolean = false;
  @Input() label: string = "";
  @Input() isDisabled: boolean = false;
  @Input() clear: boolean = false;
  @Input() checkboxMode: "yesNo" | "trueFalse" = "trueFalse";
  @Input() controlName: string | null = null;
  @Input() controlId: string | null = null;
  @Input() boldLabel = false;

  @Input()
  public set setDefaultValue(value: CheckboxValueType) {
    if (AdaaHelper.isDefined(value)) this.writeValue(value);
  }

  @Input()
  public set setValidator(validatorList: { parameterCatalogs: ParameterCatalog[] }) {
    if (validatorList) {
      if (validatorList.parameterCatalogs) {
        const field = validatorList.parameterCatalogs.find((e) => e.fieldName === this.controlName);

        if (field) {
          this.required = field.mandatory === AdaaBoolean.Y;
          this._updateValidation();
        }
      }
    }
  }

  @Output() inputChanges = new EventEmitter();

  public ngOnInit(): void {
    if (!this.adaaHelper.isDefined(this.controlId)) this.controlId = this.controlName;
    this._updateValidation();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDisabled && this.setDisabledState) this.setDisabledState(this.isDisabled);
    if (changes.clear) this.inputControl.reset();
  }

  public ngOnDestroy(): void {
    if (this.adaaHelper.isDefined(this._valueChanges$)) this._valueChanges$.unsubscribe();
  }

  public writeValue(obj: CheckboxValueType): void {
    if (obj === null) {
      this.inputControl.reset();
    }

    if (this.checkboxMode === "yesNo") {
      this.inputControl.setValue(obj === AdaaBoolean.Y);
    } else {
      this.inputControl.setValue(obj);
    }
  }

  public registerOnChange(fn: (value: CheckboxValueType) => void): void {
    this._onChange = fn;

    this._valueChanges$ = this.inputControl.valueChanges.subscribe((value) => {
      if (this.checkboxMode === "trueFalse") this._onChange(value);
      else this._onChange(value ? AdaaBoolean.Y : AdaaBoolean.N);
    });
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this._onValidatorChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public validate(value: FormControl): false | { required: boolean } {
    const isRequired =
      (this.required && this.inputControl.value === null) || (this.required && this.inputControl.value === "");
    return (
      isRequired && {
        required: true,
      }
    );
  }

  public valueChanged(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (this.checkboxMode === "trueFalse") {
      this.inputChanges.emit(target.checked);
    } else {
      this.inputChanges.emit(target.checked ? AdaaBoolean.Y : AdaaBoolean.N);
    }
  }

  private _updateValidation(): void {
    this.inputControl.updateValueAndValidity();
  }
}
