<adaa-item-slider class="my-3" [showPerformance]="(overallAggregationPerf() && overallAggregationPerf()?.enabled) ?? false">
    <h5 title class="py-2">
        <ng-content></ng-content>
    </h5>

    @if (overallAggregationPerf() && overallAggregationPerf()?.enabled) {
        <adaa-doughnut
            performance
            [size]="'md'"
            [amount]="overallAggregationPerf()?.value!"
            [label]="overallAggregationPerf()! | translateToken: 'label'"
            [colorId]="overallAggregationPerf()?.color!"
            [showGraphIcon]="true"
            (onclick)="selectedAggregationType.set(overallAggregationPerf()); openPerformanceGraph(performanceGraphModal)"
        />
    }

    @for (aggType of aggregationPerf(); track aggType.id) {
        @if (aggType.enabled) {
            <adaa-aggregation-perf-card
                [data]="aggType"
                [period]="selectedPeriod()"
                (performanceGraphClicked)="selectedAggregationType.set($event); openPerformanceGraph(performanceGraphModal)"
            />
        }
    }
</adaa-item-slider>

<ng-template #performanceGraphModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title w-100 text-center fw-bold">{{ chartTitle }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="entityInfo.set({}); modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden">
        @if (isPmo() && selectedPeriod()?.type === "year") {
            <adaa-entity-type-size-form
                (selected)="updateEntityInfo($event.type, $event.data)"
                (entitySizes)="entitySizes.set($event)"
                (entityTypes)="entityTypes.set($event)"
            />
        }

        @if (entitiesPerformance().length > 0 || averagePerformance()) {
            <adaa-chart-action-buttons [elementId]="'aggPerformanceChart'" [enableDownloadCSV]="false"></adaa-chart-action-buttons>
            <canvas
                class="performance-chart"
                id="aggPerformanceChart"
                baseChart
                type="bar"
                [labels]="performanceChartLabels()"
                [datasets]="performanceChartDataset()"
                [options]="chartService.barChartOptions"
            ></canvas>
        }
    </div>
</ng-template>
