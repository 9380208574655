/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Chart, ChartConfiguration, TooltipItem } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { AdaaHelper } from "../../core/utils";
import { CountryFlags, CountryFlagsArabic } from "../constants/countries";
import { Language } from "../constants/enums";
import { LanguageService } from "./language.service";

@Injectable({
  providedIn: "root",
})
export class ChartsService {
  private _translateService = inject(TranslateService);
  private _languageService = inject(LanguageService);

  barChartOptions: ChartConfiguration<"bar" | "line">["options"];
  lineChartOptions: ChartConfiguration<"line">["options"];
  multiSeriesChartOptions: ChartConfiguration<"pie">["options"];

  public initBarChartOptions({
    title = "",
    subtitle = undefined,
    cutlabels = true,
    padding = 20,
  }: {
    title: string;
    subtitle?: string;
    cutlabels?: boolean;
    padding?: number;
  }): void {
    Chart.register(ChartDataLabels);

    const translateService = this._translateService;
    const languageService = this._languageService;
    const isRTL = languageService.current() === Language.Arabic;

    this.barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          reverse: isRTL,
          ticks: {
            maxRotation: 90,
            minRotation: 90,
            font: {
              family: '"Open Sans", "NotoKufiArabic"',
            },
            callback: function (value) {
              const label = this.getLabelForValue(value as number);
              if (cutlabels && typeof label === "string" && label.length > 25) {
                return `${label.substring(0, 25)}...`;
              }
              return label;
            },
          },
        },
        y: {
          min: 0,
          max: 100,
          position: isRTL ? "right" : "left",
          ticks: {
            stepSize: 20,
            font: {
              family: '"Open Sans", "NotoKufiArabic"',
            },
            callback: function (value) {
              return value + " %";
            },
          },
        },
      },
      plugins: {
        tooltip: {
          rtl: isRTL,
          titleFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          bodyFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          intersect: false,
          callbacks: {
            label: function (context) {
              let performance = context.dataset.label || "";
              let rank = context.dataset.label || "";
              if (context.parsed.y !== null) {
                performance += `${translateService.instant("data_entry.performance")}: ${AdaaHelper.roundValue(context.parsed.y)}%`;
                rank += `${translateService.instant("executive_dashboard.rank")}: ${AdaaHelper.roundValue(context.dataIndex + 1)}`;
              }
              return [performance, rank];
            },
          },
        },
        title: {
          display: AdaaHelper.isDefinedAndNotEmpty(title),
          text: title,
          font: {
            family: '"Open Sans", "NotoKufiArabic"',
            size: 15,
          },
          padding: {
            bottom: AdaaHelper.isDefinedAndNotEmpty(subtitle) ? 0 : 20,
          },
        },
        subtitle: {
          display: AdaaHelper.isDefinedAndNotEmpty(subtitle),
          text: subtitle,
          font: {
            family: '"Open Sans", "NotoKufiArabic"',
            size: 14,
          },
          padding: { bottom: 20 },
        },
        datalabels: {
          align: "end",
          anchor: "end",
          textAlign: "center",
          color: "#000",
          font: function () {
            return {
              family: '"Open Sans", "NotoKufiArabic"',
              size: 12,
            };
          },
          formatter: function (value, context) {
            if (value === 0) return "";
            if (context.dataset.type === "line") {
              return context.dataIndex + 1 === context.dataset.data.length ? AdaaHelper.roundValue(value, 0) + "%" : "";
            }
            return AdaaHelper.roundValue(value, 0) + "%";
          },
        },
        legend: {
          display: false,
        },
      },
      layout: {
        padding: padding,
      },
    };
  }

  public initLineChartOptions({
    title = "",
    subtitle = undefined,
    isRank = false,
  }: {
    title?: string;
    subtitle?: string;
    isRank: boolean;
  }): void {
    Chart.register(ChartDataLabels);

    const labelForLineChart = this._getLabelForLineChart;
    const translateService = this._translateService;
    const languageService = this._languageService;
    const isRTL = languageService.current() === Language.Arabic;

    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          reverse: isRTL,
          ticks: {
            font: {
              family: '"Open Sans", "NotoKufiArabic"',
            },
            callback: function (value) {
              const label = this.getLabelForValue(value as number);
              if (typeof label === "string" && label.length > 10) {
                return `${label.substring(0, 15)}...`;
              }
              return label;
            },
          },
        },
        y: {
          position: isRTL ? "right" : "left",
          reverse: isRank,
          min: isRank ? undefined : 0,
          max: isRank ? undefined : 100,
          ticks: {
            stepSize: 20,
            font: {
              family: '"Open Sans", "NotoKufiArabic"',
            },
            callback: function (value) {
              return isRank ? value : `${value}%`;
            },
          },
        },
      },
      plugins: {
        tooltip: {
          rtl: isRTL,
          titleFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          bodyFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          intersect: false,
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (context.parsed.y !== null) {
                label += labelForLineChart(translateService, context, isRank);
              }
              return [label];
            },
          },
        },
        title: {
          display: AdaaHelper.isDefinedAndNotEmpty(title),
          text: title,
          font: {
            family: '"Open Sans", "NotoKufiArabic"',
            size: 15,
          },
          padding: {
            bottom: AdaaHelper.isDefinedAndNotEmpty(subtitle) ? 0 : 20,
          },
        },
        subtitle: {
          display: AdaaHelper.isDefinedAndNotEmpty(subtitle),
          text: subtitle,
          font: {
            family: '"Open Sans", "NotoKufiArabic"',
            size: 14,
          },
          padding: { bottom: 20 },
        },
        datalabels: {
          formatter: (value) => (isRank ? value : value.toFixed(2) + "%"),
        },
      },
      layout: {
        padding: 20,
      },
    };
  }

  public initMultiSeriesChartOptions() {
    Chart.register(ChartDataLabels);

    this.multiSeriesChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          labels: {
            generateLabels: function (chart: any) {
              // Get the default label list
              const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
              const labelsOriginal = original.call(this, chart);

              // Build an array of colors used in the datasets of the chart
              let datasetColors = chart.data.datasets.map(function (e: Record<string, unknown>) {
                return e.backgroundColor;
              });
              datasetColors = datasetColors.flat();

              // Modify the color and hide state of each label
              labelsOriginal.forEach((label: any) => {
                // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                label.datasetIndex = (label.index - (label.index % 2)) / 2;

                // The hidden state must match the dataset's hidden state
                label.hidden = !chart.isDatasetVisible(label.datasetIndex);

                // Change the color to match the dataset
                label.fillStyle = datasetColors[label.index];
              });

              return labelsOriginal;
            },
          },
          onClick: function (_mouseEvent: any, legendItem: any, legend: any) {
            // toggle the visibility of the dataset from what it currently is
            legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden = legend.chart.isDatasetVisible(
              legendItem.datasetIndex
            );
            legend.chart.update();
          },
        },
        tooltip: {
          callbacks: {
            label: function (context: any) {
              const labelIndex = context.datasetIndex * 2 + context.dataIndex;
              return context.chart.data.labels[labelIndex] + ": " + context.formattedValue;
            },
          },
        },
      },
    };
  }

  public getPerformanceChartOptions({
    showLegend,
    padding = 20,
  }: {
    showLegend: boolean;
    padding: number;
  }): ChartConfiguration<"bar" | "line">["options"] {
    Chart.register(ChartDataLabels);

    const languageService = this._languageService;
    const isRTL = languageService.current() === Language.Arabic;

    return {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "nearest",
        intersect: true,
      },
      scales: {
        x: {
          reverse: isRTL,
          ticks: {
            font: {
              family: '"Open Sans", "NotoKufiArabic"',
            },
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          max: 100,
          position: isRTL ? "right" : "left",
          ticks: {
            stepSize: 20,
            font: {
              family: '"Open Sans", "NotoKufiArabic"',
            },
            callback: function (value) {
              value = +value;
              if (value >= 1e12)
                value = (value / 1e12).toFixed(1) + "T"; // Trillion
              else if (value >= 1e9)
                value = (value / 1e9).toFixed(1) + "B"; // Billion
              else if (value >= 1e6)
                value = (value / 1e6).toFixed(1) + "M"; // Million
              else if (value >= 1e3) value = (value / 1e3).toFixed(1) + "K"; // Thousand

              return value + "%";
            },
          },
        },
      },
      plugins: {
        tooltip: {
          rtl: isRTL,
          titleFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          bodyFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          intersect: false,
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (context.parsed.y !== null) {
                label += `: ${AdaaHelper.roundValue(context.parsed.y)}%`;
              }
              return label;
            },
          },
        },
        datalabels: {
          display: false,
          align: "end",
          anchor: "end",
          textAlign: "center",
          color: "#000",
          font: function () {
            return {
              family: '"Open Sans", "NotoKufiArabic"',
              size: 12,
            };
          },
          formatter: function (value, context) {
            if (value === 0 || value == null) return "";
            if (context.dataset.type === "line") {
              return context.dataIndex + 1 === context.dataset.data.length ? AdaaHelper.roundValue(value, 0) + "%" : "";
            }
            return AdaaHelper.roundValue(value, 0) + "%";
          },
        },
        legend: {
          display: showLegend,
          position: "bottom",
        },
      },
      layout: {
        padding,
      },
    };
  }

  public getBudgetChartOptions(): ChartConfiguration<"pie">["options"] {
    Chart.register(ChartDataLabels);

    const languageService = this._languageService;
    const isRTL = languageService.current() === Language.Arabic;

    return {
      responsive: true,
      plugins: {
        tooltip: {
          rtl: isRTL,
          titleFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          bodyFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          callbacks: {
            label: function (context: any) {
              const labelIndex = context.datasetIndex * 2 + context.dataIndex;
              return `${context.chart.data.labels[labelIndex]}: ${context.formattedValue}%`;
            },
          },
        },
        datalabels: {
          color: "#fff",
          font: function () {
            return {
              family: '"Open Sans", "NotoKufiArabic"',
              size: 12,
            };
          },
          formatter: function (value) {
            if (value === 0) return "";
            return AdaaHelper.roundValue(value, 0) + "%";
          },
        },
      },
    };
  }

  public initTpProgressChartOptions(isProgress: boolean): void {
    Chart.register(ChartDataLabels);

    const translateService = this._translateService;
    const languageService = this._languageService;
    const isRTL = languageService.current() === Language.Arabic;

    this.barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          reverse: isRTL,
          ticks: {
            maxRotation: 90,
            minRotation: 90,
            font: {
              family: '"Open Sans", "NotoKufiArabic"',
            },
            callback: function (value) {
              const label = this.getLabelForValue(value as number);
              if (typeof label === "string" && label.length > 25) {
                return `${label.substring(0, 25)}...`;
              }
              return label;
            },
          },
        },
        y: {
          min: 0,
          max: 100,
          position: isRTL ? "right" : "left",
          ticks: {
            stepSize: 20,
            font: {
              family: '"Open Sans", "NotoKufiArabic"',
            },
            callback: function (value) {
              value = +value;
              if (value >= 1e12)
                value = (value / 1e12).toFixed(1) + "T"; // Trillion
              else if (value >= 1e9)
                value = (value / 1e9).toFixed(1) + "B"; // Billion
              else if (value >= 1e6)
                value = (value / 1e6).toFixed(1) + "M"; // Million
              else if (value >= 1e3) value = (value / 1e3).toFixed(1) + "K"; // Thousand

              return value + "%";
            },
          },
        },
      },
      plugins: {
        tooltip: {
          rtl: isRTL,
          titleFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          bodyFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          intersect: false,
          callbacks: {
            label: function (context) {
              let progress = context.dataset.label || "";
              let vision = "";
              if (context.parsed.y !== null) {
                progress += `${translateService.instant("common.tables.progress")}: ${AdaaHelper.roundValue((context.dataset.data[context.dataIndex] as any).progress)}%`;

                if (context.dataset.data[context.dataIndex])
                  vision += `${translateService.instant("national_projects.target")}: ${AdaaHelper.roundValue((context.dataset.data[context.dataIndex] as any).visionScore)}%`;
              }

              const entity = `${translateService.instant("national_projects.entity")}: ${AdaaHelper.getItemValueByToken(context.dataset.data[context.dataIndex] as any, "entityName")}`;

              return isProgress ? [progress, vision, entity] : [vision, progress, entity];
            },
          },
        },
        datalabels: {
          align: "end",
          anchor: "end",
          textAlign: "center",
          color: "#000",
          font: function () {
            return {
              family: '"Open Sans", "NotoKufiArabic"',
              size: 12,
            };
          },
          formatter: function (value, context) {
            if (value === 0) return "";

            if (context.dataset.type === "line") {
              return context.dataIndex + 1 === context.dataset.data.length
                ? AdaaHelper.roundValue(isProgress ? value.progress : value.visionScore, 0) + "%"
                : "";
            }
            return AdaaHelper.roundValue(isProgress ? value.progress : value.visionScore, 0) + "%";
          },
        },
        legend: {
          display: false,
        },
      },
      layout: {
        padding: 23,
      },
      parsing: {
        yAxisKey: isProgress ? "progress" : "visionScore",
        xAxisKey: AdaaHelper.getFieldLanguage("name"),
      },
    };
  }

  public initEntityComparisonBarChartOptions({
    usePerformance = false,
    title = "",
    subtitle = undefined,
    usePercentage = true,
    showActual = true,
  }: {
    usePerformance: boolean;
    usePercentage?: boolean;
    title?: string;
    subtitle?: string;
    showActual?: boolean;
  }): void {
    Chart.register(ChartDataLabels);

    const translateService = this._translateService;
    const languageService = this._languageService;
    const isRTL = languageService.current() === Language.Arabic;

    this.barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          reverse: isRTL,
          ticks: {
            maxRotation: 90,
            minRotation: 90,
            font: {
              family: '"Open Sans", "NotoKufiArabic"',
            },
            callback: function (value) {
              const label = this.getLabelForValue(value as number);
              if (typeof label === "string" && label.length > 25) {
                return `${label.substring(0, 25)}...`;
              }
              return label;
            },
          },
        },
        y: {
          position: isRTL ? "right" : "left",
          ticks: {
            stepSize: 20,
            font: {
              family: '"Open Sans", "NotoKufiArabic"',
            },
            callback: function (value) {
              return `${value}${usePercentage ? "%" : ""}`;
            },
          },
          title: {
            display: true,
            text: usePerformance
              ? translateService.instant("data_entry.performance")
              : translateService.instant("data_entry.actual"),
            font: {
              family: '"Open Sans", "NotoKufiArabic"',
              size: 15,
            },
          },
        },
      },
      plugins: {
        tooltip: {
          rtl: isRTL,
          intersect: false,
          mode: "index",
          enabled: (context) => {
            const isArray = Array.isArray(context.tooltip?.title) && context.tooltip?.title.length > 0;
            if (isArray) {
              const title = context.tooltip?.title[0].trim();
              return AdaaHelper.isDefinedAndNotEmpty(title);
            }
            return true;
          },
          titleFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          bodyFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          callbacks: {
            label: function (context: any) {
              const extraData = context.dataset.extraData;
              const ignoredList = context.dataset.ignoredList;

              let performance = "";
              let actual = context.dataset.label || "";
              let rank = context.dataset.label || "";

              let performanceValue = usePerformance
                ? `${AdaaHelper.roundValue(context.parsed.y)}`
                : extraData?.[context.dataIndex]
                  ? `${AdaaHelper.roundValue(extraData[context.dataIndex])}`
                  : "-";
              let actualValue = usePerformance
                ? extraData?.[context.dataIndex]
                  ? `${AdaaHelper.roundValue(extraData[context.dataIndex])}`
                  : "-"
                : `${AdaaHelper.roundValue(context.parsed.y)}`;

              if (performanceValue && performanceValue != "-" && usePercentage) performanceValue += "%";
              if (actualValue && actualValue != "-" && usePercentage) actualValue += "%";

              if (ignoredList && ignoredList[context.dataIndex] === true) performanceValue = "N/A";
              if (ignoredList && ignoredList[context.dataIndex] === true) actualValue = "N/A";

              performance += `${translateService.instant("data_entry.performance")}: ${performanceValue}`;
              actual += `${translateService.instant("graphic.kpi.actual")}: ${actualValue}`;
              rank += `${translateService.instant("executive_dashboard.rank")}: ${AdaaHelper.roundValue(context.dataIndex + 1)}`;

              return showActual ? [rank, performance, actual] : [rank, performance];
            },
          },
          filter: function (tooltipItem) {
            return tooltipItem.dataset.type !== "line";
          },
        },
        datalabels: {
          align: "end",
          anchor: "end",
          textAlign: "center",
          color: "#000",
          font: function () {
            return {
              family: '"Open Sans", "NotoKufiArabic"',
              size: 12,
            };
          },
          formatter: function (value, context) {
            const label = ((context.chart.data.labels ?? [])[context.dataIndex] as string).trim();

            if (!AdaaHelper.isDefined(value)) return "";

            const data = AdaaHelper.isDefinedAndNotEmpty(label)
              ? `${AdaaHelper.roundValue(value, 0)}${usePercentage ? "%" : ""}`
              : "";

            if (context.dataset.type === "line")
              return context.dataIndex + 1 === context.dataset.data.length ? data : "";
            return data;
          },
        },
        legend: {
          display: false,
        },
        title: {
          display: AdaaHelper.isDefinedAndNotEmpty(title),
          text: title,
          font: {
            family: '"Open Sans", "NotoKufiArabic"',
            size: 15,
          },
          padding: {
            bottom: AdaaHelper.isDefinedAndNotEmpty(subtitle) ? 0 : 20,
          },
        },
        subtitle: {
          display: AdaaHelper.isDefinedAndNotEmpty(subtitle),
          text: subtitle,
          font: {
            family: '"Open Sans", "NotoKufiArabic"',
            size: 14,
          },
          padding: {
            bottom: 20,
          },
        },
      },
      layout: {
        padding: 30,
      },
    };
  }

  public initBenchmarkChartOptions({
    title = "",
    subtitle = undefined,
    padding = 0,
    max = 100,
  }: {
    title?: string;
    subtitle?: string;
    padding?: number;
    min?: number;
    max?: number;
  }) {
    Chart.register(ChartDataLabels);
    const languageService = this._languageService;
    const isRTL = languageService.current() === Language.Arabic;

    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          reverse: isRTL,
          offset: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: '"Open Sans", "NotoKufiArabic"',
            },
            padding: 30,
          },
        },
        y: {
          position: isRTL ? "right" : "left",
          ticks: {
            stepSize: max > 1000 ? 100 : max > 500 ? 50 : max >= 100 ? 20 : max <= 80 && max > 10 ? 10 : 1,
            font: {
              family: '"Open Sans", "NotoKufiArabic"',
            },
          },
        },
      },
      plugins: {
        tooltip: {
          rtl: isRTL,
          titleFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          bodyFont: {
            family: '"Open Sans", "NotoKufiArabic"',
          },
          intersect: false,
          enabled: false,
          external: this._customTooltipFunction,
          mode: "nearest",
        },
        title: {
          display: AdaaHelper.isDefinedAndNotEmpty(title),
          text: title,
          font: {
            family: '"Open Sans", "NotoKufiArabic"',
            size: 15,
          },
          padding: {
            bottom: AdaaHelper.isDefinedAndNotEmpty(subtitle) ? 0 : 20,
          },
        },
        subtitle: {
          display: AdaaHelper.isDefinedAndNotEmpty(subtitle),
          text: subtitle,
          font: {
            family: '"Open Sans", "NotoKufiArabic"',
            size: 14,
          },
          padding: {
            bottom: 20,
          },
        },
        autocolors: {
          offset: 5,
          enabled: true,
          mode: "label",
        },
        datalabels: {
          display: false,
        },
        legend: {
          display: false,
        },
      },
      layout: {
        padding: padding,
      },
    };
  }

  //Passing the translate service will fix a JS error
  private _getLabelForLineChart(
    translateService: TranslateService,
    context: TooltipItem<"line">,
    isRank: boolean
  ): string {
    if (isRank) return `${translateService.instant("executive_dashboard.rank")}: ${context.parsed.y}`;
    else return `${translateService.instant("data_entry.performance")}: ${AdaaHelper.roundValue(context.parsed.y)}%`;
  }

  _customTooltipFunction = (context: any) => {
    const { tooltip } = context;

    // Hide tooltip if not active
    if (!tooltip || tooltip.opacity === 0) {
      const existingTooltip = document.getElementById("chartjs-tooltip");
      if (existingTooltip) existingTooltip.style.opacity = "0";
      return;
    }

    // Create tooltip container if it doesn't exist
    let tooltipEl = document.getElementById("chartjs-tooltip");
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.background = "white";
      tooltipEl.style.padding = "8px";
      tooltipEl.style.borderRadius = "5px";
      tooltipEl.style.border = "1px solid #ccc";
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.transition = "opacity 0.2s ease";
      document.body.appendChild(tooltipEl);
    }

    // Extract data
    const tooltipData = tooltip.dataPoints[0];
    if (!tooltipData) return;

    const dataset = tooltipData.dataset;
    let label = dataset.label || "";
    const countryCode = this.getCountryFlagCode(label);
    const year = tooltipData.label;
    label += ": " + (tooltipData.parsed.y ?? "0");

    // Set tooltip content with flag icon
    tooltipEl.innerHTML = `
      <p class="text-center fw-bold mb-0">${year}</p>
      <p class="text-center fw-bold mb-0">${label}</p>
      <div class="d-flex justify-content-center my-1"><span class="fi fi-${countryCode}"></span></div>
    `;

    // Position tooltip
    const chartRect = context.chart.canvas.getBoundingClientRect();
    tooltipEl.style.left = `${chartRect.left + tooltip.caretX}px`;
    tooltipEl.style.top = `${chartRect.top + tooltip.caretY}px`;
    tooltipEl.style.opacity = "1";
  };

  public getCountryFlagCode(label: string) {
    const language = AdaaHelper.getCurrentLang();
    const countryCode = label.toUpperCase().trim().replaceAll(" ", "_");
    if (language === Language.Arabic) {
      return CountryFlagsArabic[countryCode as keyof typeof CountryFlagsArabic];
    }
    if (language === Language.English) {
      return CountryFlags[countryCode as keyof typeof CountryFlags];
    }
    return "";
  }
}
