import { inject, Injectable } from "@angular/core";

import { AdaaHelper } from "../../core/utils";
import { AgmCycle, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class AgmCycleApiService {
  private _mainService = inject(MainService);

  public getCycles(limit?: number, offset?: number) {
    let url = "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `annual-meeting-project-cycle/getall${url}`;

    return this._mainService.sendRequest<MainResponseModel<AgmCycle[]>>({
      method: "POST",
      url,
      data: {},
    });
  }

  public createAgmCycle(data: Partial<AgmCycle>) {
    const url = `annual-meeting-project-cycle/create`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public updateAgmCycle(data: Partial<AgmCycle>) {
    const url = `annual-meeting-project-cycle/update`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public getAgmCycle(id: number) {
    const url = `annual-meeting-project-cycle/getbyid/${id}`;

    return this._mainService.sendRequest<MainResponseModel<AgmCycle>>({
      method: "GET",
      url,
    });
  }

  public deleteAgmCycle(id: number) {
    const url = `annual-meeting-project-cycle/delete/${id}`;

    return this._mainService.sendRequest<MainResponseModel>({
      method: "DELETE",
      url,
    });
  }
}
