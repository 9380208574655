import { inject, Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { CardNote, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class CardNoteApiService {
  private readonly _mainService = inject(MainService);

  refreshNotes = new Subject<{ id: number; type: number }>();
  refreshNotesListener = this.refreshNotes.asObservable();

  public getAllByItemType(itemId: number, itemType: number) {
    const url = `cardnote/getAllByItemType/${itemId}/${itemType}`;

    return this._mainService.sendRequest<MainResponseModel<CardNote[]>>({
      url,
      method: "GET",
    });
  }

  public create(note: { itemType: number; itemId: number; userId: number; noteAE: string; noteEN: string }) {
    const url = `cardnote/create`;

    return this._mainService.sendRequest<MainResponseModel<CardNote[]>>({
      url,
      method: "POST",
      data: note,
    });
  }

  public deleteById(id: number) {
    const url = `cardnote/deleteById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<CardNote[]>>({
      url,
      method: "DELETE",
    });
  }
}
