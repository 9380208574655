import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { Constants } from "../../../constants/constants";

@Component({
  selector: "adaa-info-bar",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./info-bar.component.html",
  styleUrl: "./info-bar.component.scss",
})
export class InfoBarComponent {
  value = input.required<string>();
  color = input.required<number>();
  label = input.required<string>();

  subLabel = input<string | undefined>(undefined);
  fontSize = input<string>("0.75rem");

  constants = Constants;
}
