<div class="pointer owner-card text-center" (click)="cardClicked.emit(owner().ownerIds)">
    <div class="row align-items-center">
        <div class="col-3">
            <adaa-picture [pictureId]="owner().ownerProfilePictureId" [height]="'70px'" [width]="'auto'"></adaa-picture>
        </div>
        <div class="col-9">
            <h6>{{ owner() | translateToken: "ownerName" }}</h6>
        </div>
        <div class="col-12 mt-3 mb-1" style="min-height: 40px">
            <h6 class="fw-bold text-primary">{{ owner() | translateToken: "entityName" }}</h6>
        </div>
        <div class="col-12 mb-3">
            <adaa-progress-indicator
                [label]="('sdg.initiatives_progress' | translate) + ': '"
                [color]="owner().onTimeProgressColor"
                [value]="owner().progress"
            ></adaa-progress-indicator>
        </div>
        <div class="col-12">
            <span>
                {{ "sdg.total_initiatives" | translate }}:
                <strong>{{ owner().nationalProjectCount > 0 ? owner().nationalProjectCount : "-" }}</strong>
            </span>
        </div>
    </div>
</div>
