import { Component, inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "adaa-confirmation-modal",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./confirmation-modal.component.html",
  styleUrl: "./confirmation-modal.component.scss",
})
export class ConfirmationModalComponent {
  @Input() header: string;
  @Input() title: string;
  @Input() subTitle: string = "common.form.label.are_you_sure";
  @Input() yesLabel: string = "common.form.label.yes";
  @Input() noLabel: string = "common.form.label.no";

  modal: NgbActiveModal = inject(NgbActiveModal);
}
