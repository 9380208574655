import type { AdaaBooleanType } from "../../../../../../../adaa-types";
import { AdaaHelper, useNewDS } from "../../../../../../core/utils";
import { Constants } from "../../../../../constants/constants";
import { AdaaBoolean, Language } from "../../../../../constants/enums";
import type { ListSectionConfigType, ScreenSectionConfigType, TextFieldOptionType } from "../wf-difference.types";
import { isActivity } from "../wf-difference.utils";

export const commonFormatter = {
  matchYesNo: (bool: AdaaBoolean | AdaaBooleanType | undefined) => {
    switch (bool) {
      case AdaaBoolean.Y:
        return "common.form.label.yes";
      case AdaaBoolean.N:
        return "common.form.label.no";
      default:
        return "";
    }
  },
  monthly: (epoch: number) => {
    const dt = new Intl.DateTimeFormat(AdaaHelper.getCurrentLang() === Language.Arabic ? "ar-AR" : "en-GB", {
      month: "long",
      localeMatcher: "best fit",
      timeZone: "Asia/Dubai",
    });

    return dt.format(epoch);
  },
};

export const textField = (field: string, opts: TextFieldOptionType) => {
  const { label } = opts as { label: string };
  const lang = AdaaHelper.getCurrentLang();

  switch (opts.use) {
    case "current":
      return {
        label,
        field: `${field}${lang.toUpperCase()}`,
        view: "normal",
      } as ScreenSectionConfigType;
    case "both":
      return [
        { field: `${field}AE`, view: "normal" },
        { field: `${field}EN`, view: "normal" },
      ] as ScreenSectionConfigType[];
  }
};

export const SharedFields = {
  get extendedFields(): ScreenSectionConfigType {
    return { view: "ext-fields", field: "attrs", label: "common.form.label.extended_fields" };
  },
  get nationalSectorsField(): ListSectionConfigType {
    return {
      field: "nationalSectors",
      label: "national_projects.national_sector.title",
      view: "list",
      idKey: "nationalSectorId",
      format: ($o) => AdaaHelper.getItemValueByToken($o, "name"),
    };
  },
  get orgUnitIdField(): ScreenSectionConfigType {
    return {
      field: "orgUnitId",
      label: "srvkpi.org_unit",
      view: "normal",
      format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "orgUnitName"),
    };
  },
  get ownerField(): ScreenSectionConfigType {
    return {
      field: "owner",
      label: "nkpi.owner",
      view: "normal",
      format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "ownerName"),
    };
  },
  get entityIdField(): ScreenSectionConfigType {
    return {
      field: "entityId",
      view: "normal",
      label: "common.tables.entity_name",
      format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "entityName"),
    };
  },
  get objectiveIdField(): ScreenSectionConfigType {
    return {
      field: "objectiveId",
      view: "normal",
      label: "skpi.objective",
    };
  },
  get name(): ScreenSectionConfigType[] {
    const fields = textField("name", { use: "both" }) as ScreenSectionConfigType[];

    return fields.map((field) => ({
      ...field,
      label: `common.form.label.${field.field}`,
    }));
  },
  get description(): ScreenSectionConfigType[] {
    const fields = textField("dsc", { use: "both" }) as ScreenSectionConfigType[];

    return fields.map((field) => ({
      ...field,
      label: `${(field.field === "dscAE" && "common.form.label.descriptionAE") || "common.form.label.descriptionEN"}`,
    }));
  },
  get startDate(): ScreenSectionConfigType {
    return {
      field: "startDate",
      label: "common.form.label.start_date",
      view: "normal",
      format: (data: { startDate: number }) => {
        if (!AdaaHelper.isDefined(data?.startDate)) return "";
        return AdaaHelper.formatDate(data?.startDate, false);
      },
    };
  },
  get endDate(): ScreenSectionConfigType {
    return {
      field: "endDate",
      label: "common.form.label.end_date",
      view: "normal",
      format: (data: { endDate: number }) => {
        if (!AdaaHelper.isDefined(data?.endDate)) return "";
        return AdaaHelper.formatDate(data?.endDate, false);
      },
    };
  },
  get highPriority(): ScreenSectionConfigType {
    return {
      field: "highPriority",
      view: "normal",
      label: "initiatives.high_priority",
      format: (data: { highPriority: AdaaBoolean }) => commonFormatter.matchYesNo(data?.highPriority),
    };
  },
  get nationalAgendaField(): ScreenSectionConfigType {
    return {
      field: "ntkpi",
      view: "normal",
      label: "breadcrumbs.ntkpi_module.list",
      format: (value: { ntKpi: { nameAE: string; nameEN: string; id: number } }) => {
        if (!value) return;
        if (!value?.ntKpi) return;
        return AdaaHelper.getItemValueByToken(value?.ntKpi, "name");
      },
    };
  },
  get attachmentsField(): ScreenSectionConfigType {
    return {
      field: "attachments",
      label: "kpi.attachments",
      view: "attachment",
      visibleIf: (data: { attachments: unknown[] }) => data.attachments.length > 0,
    };
  },
  get benchmarksField(): ScreenSectionConfigType {
    return {
      field: "benchmarks",
      label: "sidebar_tables.benchmarks",
      view: "benchmark",
      visibleIf: (data: { benchmarks: unknown[] }) => data.benchmarks.length > 0,
    };
  },
  get serviceField(): ScreenSectionConfigType {
    return {
      field: "typeId",
      label: "services.service_type",
      view: "normal",
      format: (data: { typeId: number }) => {
        switch (data.typeId) {
          case Constants.CONSTANT_AUXSERVICE:
            return "services.auxiliary_service";
          case Constants.CONSTANT_VARSERVICE:
            return "services.variation_service";
          default:
            return "services.main_service";
        }
      },
    };
  },
  get initiativeNameField(): ScreenSectionConfigType {
    const label = useNewDS() ? "initiatives.project" : "initiatives.initiative";
    return {
      field: "initiativeId",
      label,
      view: "normal",
      format: (data: { initiativeNameAE: string; initiativeNameEN: string }) =>
        AdaaHelper.getItemValueByToken(data, "initiativeName"),
    };
  },
  get projectTypeField(): ScreenSectionConfigType {
    return {
      field: "initiativeType",
      label: "initiatives.project_type",
      view: "normal",
      visibleIf: (data: { planId: number; itemTypeId: number }) =>
        useNewDS(data?.planId ?? undefined) && isActivity(data.itemTypeId),
      format: (data: { initiativeType: number }) => {
        if (data.initiativeType === Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE) {
          return "initiatives.regular_task";
        }
        return "initiatives.strategic_project";
      },
    };
  },
  get commentField(): ScreenSectionConfigType {
    return {
      field: "commentsAE",
      label: "common.form.label.commentsAE",
      view: "normal",
      hasDiff: ($n: string, $o: string) => {
        const newComments = AdaaHelper.isDefinedAndNotEmpty($n) ? $n : "";
        const oldComments = AdaaHelper.isDefinedAndNotEmpty($o) ? $o : "";

        return newComments !== oldComments;
      },
    };
  },
};
