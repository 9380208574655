import { inject, Injectable } from "@angular/core";
import { filter, map, switchMap } from "rxjs";

import { Constants } from "../constants/constants";
import type { InitiativeFormType, InitiativeModelType, MainResponseModel, SearchFields } from "../models";
import { DataTableDataService } from "./data-table-data.service";
import { ItemApiService } from "./item-api.service";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class InitiativeApiService {
  private _mainService = inject(MainService);
  private readonly _itemApiService = inject(ItemApiService);
  private readonly _dataTableDataService = inject(DataTableDataService);

  public create(isFinished: boolean, data: InitiativeFormType) {
    const url = `initiative/create?isFinish=${isFinished}&type=${data.initiativeType}`;

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "POST",
        url: url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public update(isFinished: boolean, data: InitiativeFormType & { id: number; status: number; wfStatus: number }) {
    const url = `initiative/update?isFinish=${isFinished}&type=${data.initiativeType}`;

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "POST",
        url: url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getByIdV2({ id, type }: { id: number; type: number }) {
    const url = `initiative/getbyidv2/${id}?type=${type}`;

    return this._mainService
      .sendRequest<MainResponseModel<InitiativeModelType>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public deleteById(id: number) {
    const url = `initiative/deleteById/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<unknown>>({
        method: "DELETE",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public cancelById(id: number) {
    const url = `initiative/cancel/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<unknown>>({
        method: "POST",
        url: url,
        data: {},
      })
      .pipe(filter((res) => !res.inError));
  }

  public getCardById({ id, type }: { id: number; type: number }) {
    const url = `initiative/getCardById/${id}?type=${type}`;

    return this._mainService
      .sendRequest<MainResponseModel<InitiativeModelType>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getAllByEntityIdWithPagination({
    itemType,
    offset,
    searchFields,
  }: {
    itemType?: number;
    offset: number;
    searchFields: SearchFields;
  }) {
    const url = `initiative/getAllByEntityIdWithPagination`;
    const qs = new URLSearchParams();

    qs.set("offset", `${offset}`);
    if (itemType) qs.set("type", `${itemType}`);

    return this._mainService
      .sendRequest<MainResponseModel<InitiativeModelType[]>>({
        method: "POST",
        url: `${url}?${qs}`,
        data: searchFields,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getActiveOpms(data: { refCode: string; periodId: number; id: number }) {
    return this._dataTableDataService
      .getAllV2(Number.MAX_SAFE_INTEGER, 0, "item", `itemType=${Constants.CONSTANT_INITIATIVE}`, true, {
        searchFields: {
          ref_code: {
            value: data.refCode,
            lov: [],
            order: undefined,
          },
        },
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData),
        switchMap((res) => {
          if (Array.isArray(res) && res.length > 0) {
            return this._itemApiService.getAllByParentIdAndType({
              periodId: res[0].periodId,
              currentType: Constants.CONSTANT_INITIATIVE,
              itemId: data.id,
              sourceType: Constants.CONSTANT_OPM,
            });
          }

          return this._itemApiService.getAllByParentIdAndType({
            periodId: data.periodId,
            currentType: Constants.CONSTANT_INITIATIVE,
            itemId: data.id,
            sourceType: Constants.CONSTANT_OPM,
          });
        }),
        filter((res) => !res.inError)
      );
  }
}
