import { Component, computed, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../../../../core/pipes";
import type { ScreenSectionConfigType } from "../../wf-difference.types";

type WorkloadType = {
  id: number;
  activityId: number;
  activityRefCode: string;
  activityNameEN: string;
  activityNameAE: string;
  initiativeId: number;
  workload: number;
  progress: number | null;
  progressColor: number;
  status: number;
};

@Component({
  selector: "adaa-activity-workload-diff-view",
  standalone: true,
  imports: [TranslateTokenPipe, TranslateModule],
  styleUrl: "../styles.scss",
  template: `
    @if (showView()) {
      <h4 class="my-3">{{ "initiatives.activity_workload_title" | translate }}</h4>

      <section class="row d-flex align-content-center mt-2">
        <div
          class="p-2 border border-white tbl-col rounded-start-1 bg-white text-primary fw-bold text-center"
          [class.col-8]="showOnlyNew()"
          [class.col-6]="!showOnlyNew()"
        >
          {{ "communication.difference.key" | translate }}
        </div>

        <div
          class="p-2 border border-white tbl-col bg-white text-primary fw-bold text-center"
          [class.rounded-end-1]="showOnlyNew()"
          [class.col-4]="showOnlyNew()"
          [class.col-3]="!showOnlyNew()"
        >
          {{
            !showOnlyNew()
              ? ("communication.difference.new_value" | translate)
              : ("communication.difference.value" | translate)
          }}
        </div>

        @if (!showOnlyNew()) {
          <div class="col-3 p-2 border border-white tbl-col rounded-end-1 bg-white text-primary fw-bold text-center">
            {{ "communication.difference.old_value" | translate }}
          </div>
        }

        @for (data of newData(); track data.id) {
          <section class="row d-flex align-content-center">
            <div
              class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6"
              [class.col-8]="showOnlyNew()"
              [class.col-6]="!showOnlyNew()"
            >
              {{ data | translateToken: "activityName" }}
            </div>

            <div
              class="p-2 border border-white tbl-col d-flex align-items-center text-center"
              [class.rounded-end-1]="showOnlyNew()"
              [class.col-4]="showOnlyNew()"
              [class.col-3]="!showOnlyNew()"
              [class.tbl-diff]="showOnlyNew() || hasDiff(data)"
            >
              {{ data.workload }}
            </div>

            @if (!showOnlyNew()) {
              <div
                class="col-3 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-center text-center"
                [class.tbl-diff]="hasDiff(data)"
              >
                {{ getOldWorkload(data.id) }}
              </div>
            }
          </section>
        }
      </section>
    }
  `,
})
export class ActivityWorkloadDiffViewComponent {
  itemTypeId = input<number>();
  showOnlyNew = input<boolean>(false);
  newData = input<WorkloadType[]>([]);
  oldData = input<WorkloadType[]>([]);
  config = input<ScreenSectionConfigType>();

  showView = computed(() => {
    const canView = this.config()!.visibleIf!;

    return canView({ itemTypeId: this.itemTypeId() } as never) && this.newData().length !== 0;
  });

  readonly getOldWorkload = (id: number) => {
    const item = this.oldData().find((c) => c.id === id);
    if (!item) return;
    return item.workload;
  };

  readonly hasDiff = (data: WorkloadType) => {
    const item = this.oldData().find(({ id }) => id === data.id);
    if (!item) return true;
    return item.workload !== data.workload;
  };
}
