import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  inject,
  input,
  Output,
  Renderer2,
  viewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import Handsontable from "handsontable";
import { CellChange } from "handsontable/common";
import Core from "handsontable/core";
import { DetailedSettings } from "handsontable/plugins/nestedHeaders";
import { ColumnSettings, GridSettings } from "handsontable/settings";

import { environment } from "../../../../core/environments/environment";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { AdaaBoolean, Language } from "../../../constants/enums";
import { ColumnsIndices, DataEntry, DataEntryDetails, DataEntryOptionClicked } from "../../../models";
import { DataentryService } from "../../../services";
import { TableLegendDataEntryComponent } from "../table-legend-data-entry/table-legend-data-entry.component";

@Component({
  selector: "adaa-formula-ekpi",
  standalone: true,
  imports: [TableLegendDataEntryComponent],
  templateUrl: "./formula-ekpi.component.html",
  styleUrl: "./formula-ekpi.component.scss",
})
export class FormulaEkpiComponent implements AfterViewInit {
  private _translateService = inject(TranslateService);
  private _dataEntryService = inject(DataentryService);
  private _elementRef = inject(ElementRef);
  private _renderer = inject(Renderer2);

  dataEntryDetails = input.required<DataEntryDetails>();
  dataEntrySave = input.required<DataEntryDetails>();

  @Output() tableOptionsClicked = new EventEmitter<DataEntryOptionClicked>();
  @Output() valueChanged = new EventEmitter<DataEntryDetails>();

  hotTableContainer = viewChild.required<ElementRef>("hotTableContainer");

  private _columnsIndices: ColumnsIndices = {
    optionsColumns: [],
    targetsColumns: [],
    actualColumns: [],
    auditAnnualActualColumns: [],
    calcColumns: [],
  };
  hotInstance: Handsontable;

  hasTarget = computed<boolean>(() => this.dataEntryDetails().hasTarget === AdaaBoolean.Y);
  isBounded = computed<boolean>(() => this.dataEntryDetails().trend === Constants.TREND.BOUNDED);
  hasAuditIssue = computed<boolean>(() =>
    this.dataEntryDetails().ekpiAuditEntries
      ? this.dataEntryDetails().ekpiAuditEntries!.some((e) => e.audits?.some((a) => a.hasAuditIssue))
      : false
  );
  dataEntriesGroupedByPeriod = computed<Map<number, DataEntry[]>>(() =>
    this.dataEntryDetails().dataEntries
      ? AdaaHelper.groupBy(this.dataEntryDetails().dataEntries!, (e: { periodId: number }) => e.periodId)
      : new Map<number, DataEntry[]>([[0, []]])
  );
  metricsLength = computed<number>(() =>
    this.dataEntryDetails().metricDetails ? this.dataEntryDetails().metricDetails!.length : 0
  );

  constructor() {
    this._renderer.listen(this._elementRef.nativeElement, "click", (event) => this._handleOnCellMouseDown(event));
  }

  public ngAfterViewInit(): void {
    const container = this.hotTableContainer().nativeElement;
    const options = this._getOptions();
    this.hotInstance = new Handsontable(container, options);
  }

  public legendClicked(event: { checkbox: "options" | "targets" | "calculation"; checked: boolean }): void {
    if (!this.hotInstance || this.hotInstance.isDestroyed) return;

    switch (event.checkbox) {
      case "options":
        event.checked
          ? this.hotInstance
              .getPlugin("hiddenColumns")
              .showColumns(this._columnsIndices.optionsColumns.map((e) => e.index))
          : this.hotInstance
              .getPlugin("hiddenColumns")
              .hideColumns(this._columnsIndices.optionsColumns.map((e) => e.index));
        this.hotInstance.render();
        break;
      case "targets":
        event.checked
          ? this.hotInstance.getPlugin("hiddenColumns").showColumns(this._columnsIndices.targetsColumns)
          : this.hotInstance.getPlugin("hiddenColumns").hideColumns(this._columnsIndices.targetsColumns);
        this.hotInstance.render();
        break;
      case "calculation":
        event.checked
          ? this.hotInstance
              .getPlugin("hiddenColumns")
              .showColumns(this._columnsIndices.calcColumns.map((e) => e.index))
          : this.hotInstance
              .getPlugin("hiddenColumns")
              .hideColumns(this._columnsIndices.calcColumns.map((e) => e.index));
        this.hotInstance.render();
        break;
    }
  }

  private _getOptions(): GridSettings {
    const currentLang = AdaaHelper.getCurrentLang();
    const { columnWidths, columns } = this._getColumns();

    return {
      data: this._getData(),
      rowHeaders: this._getRowHeaders(),
      rowHeaderWidth: 400,
      nestedHeaders: this._getColHeaders(),
      columns: columns,
      colWidths: columnWidths,
      cells: (row, column, _prop) =>
        this._dataEntryService.setCellColorEKpi(row, column, this.dataEntryDetails(), this._columnsIndices),
      afterChange: (changes, _source) => this._handleCellValueChanged(changes),
      //Same Conf
      stretchH: "none",
      height: "auto",
      viewportColumnRenderingOffset: 999999,
      viewportRowRenderingOffset: 999999,
      hiddenColumns: true,
      layoutDirection: currentLang === Language.Arabic ? "rtl" : "ltr",
      language: currentLang === Language.Arabic ? "ar-AR" : "en-US",
      licenseKey: environment.handsontable_key,
    };
  }

  private _getColHeaders() {
    const headerColSpan = 2 + this.metricsLength() + (this.isBounded() ? 2 : 1);

    const firstHeader: Array<string | DetailedSettings> = [];
    const seconedHeader: Array<string | DetailedSettings> = [];
    const thirdHeader: Array<string | DetailedSettings> = [];

    this.dataEntriesGroupedByPeriod().forEach((dataEntry, _key) => {
      firstHeader.push({
        label: dataEntry[0]
          ? this._dataEntryService.getLabelForPeriodColumn(dataEntry[0]!, this.dataEntryDetails().frequency!)
          : "",
        colspan: headerColSpan,
      });

      //options
      seconedHeader.push("");
      thirdHeader.push("");

      seconedHeader.push({
        label: this._translateService.instant("data_entry.actuals"),
        colspan: this.metricsLength(),
      });
      seconedHeader.push({
        label: this._translateService.instant("data_entry.target"),
        colspan: this.isBounded() ? 2 : 1,
      });

      this.dataEntryDetails().metricDetails?.forEach((metric) => {
        thirdHeader.push(AdaaHelper.getItemValueByToken(metric, "metricName"));
      });

      //Targets - For detailed targets
      if (this.isBounded())
        thirdHeader.push(
          this._translateService.instant("kpi.lower_limit"),
          this._translateService.instant("kpi.upper_limit")
        );
      else thirdHeader.push("");

      //Calc
      seconedHeader.push(this._translateService.instant("data_entry.calc"));
      thirdHeader.push("");

      //Audit
      if (this.hasAuditIssue() && dataEntry[0]?.month === 12) {
        firstHeader.push({ label: dataEntry[0].year.toString(), colspan: 3 });
        seconedHeader.push(
          this._translateService.instant("data_entry.annual_actual"),
          this._translateService.instant("data_entry.audited_annual_actual"),
          this._translateService.instant("common.form.label.comments")
        );
        thirdHeader.push("", "", "");
      }
    });

    return [firstHeader, seconedHeader, thirdHeader];
  }

  private _getRowHeaders(): string[] {
    const headers: string[] = [];

    //GOV KPI
    headers.push(this._translateService.instant("kpi.govKpi"));

    //Entities
    this.dataEntryDetails().entityDataEntries?.forEach((e) => {
      headers.push(AdaaHelper.getItemValueByToken(e, "entityName"));
    });

    return headers;
  }

  private _getData() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let innerData: any[] = [];

    if (!this.dataEntryDetails().entityDataEntries || !this.dataEntryDetails().frequency) return data;

    //GOV KPI
    this.dataEntriesGroupedByPeriod().forEach((dataEntry, _key) => {
      //OPTIONS
      innerData.push("");

      dataEntry.forEach((entry) => {
        //ACTUALS
        if (entry.ignored) innerData.push("N/A");
        else if (this.dataEntryDetails().textmapId) innerData.push("");
        else innerData.push(entry.actual);

        //TARGETS - For detailed targets
        if (this.hasTarget()) {
          if (this.isBounded()) {
            const dataArray = entry.isBaseLineYear
              ? [this._translateService.instant("kpi.baselineYear"), this._translateService.instant("kpi.baselineYear")] //baseline
              : this.dataEntryDetails().textmapId
                ? ["", ""] //textmapid
                : [entry.lowerLimit, entry.highLimit]; //default;

            innerData.push(...dataArray);
          } else {
            const dataArray = entry.isBaseLineYear
              ? [this._translateService.instant("kpi.baselineYear")] //baseline
              : this.dataEntryDetails().textmapId
                ? [""] //textmapid
                : [entry.target]; //default;
            innerData.push(...dataArray);
          }
        }
      });

      //TARGETS - Not detailed targets
      if (!this.hasTarget()) {
        if (this.isBounded()) {
          const dataArray = dataEntry[0].isBaseLineYear
            ? [this._translateService.instant("kpi.baselineYear"), this._translateService.instant("kpi.baselineYear")] //baseline
            : this.dataEntryDetails().textmapId
              ? ["", ""] //textmapid
              : [dataEntry[0].lowerLimit, dataEntry[0].highLimit]; //default;

          innerData.push(...dataArray);
        } else {
          const dataArray = dataEntry[0].isBaseLineYear
            ? [this._translateService.instant("kpi.baselineYear")] //baseline
            : this.dataEntryDetails().textmapId
              ? [""] //textmapid
              : [dataEntry[0].target]; //default

          innerData.push(...dataArray);
        }
      }

      //CALC
      innerData.push(
        this._dataEntryService.calcFormula(
          dataEntry,
          this.dataEntryDetails().metricDetails,
          this.dataEntryDetails().formula
        )
      );

      //AUDITED VALUES
      if (this.hasAuditIssue() && dataEntry[0]?.month === 12) {
        const auditEntityByPeriod = this.dataEntryDetails().auditEntries?.find((e) => e.year === dataEntry[0].year);
        if (auditEntityByPeriod) {
          //Annual Actual Column
          innerData.push(auditEntityByPeriod.annualActualIgnored ? "N/A" : auditEntityByPeriod.annualActual);
          //Audited Annual Actual Column
          innerData.push(auditEntityByPeriod.auditedActualIgnored ? "N/A" : auditEntityByPeriod.auditedActual);
          //Audited Annual Actual Column
          innerData.push(auditEntityByPeriod.comment);
        }
      }
    });
    data.push(innerData);

    this.dataEntryDetails().entityDataEntries?.forEach((entity) => {
      innerData = [];

      if (!entity.dataEntries) return;

      const entityEntriesGroupedByPeriod = AdaaHelper.groupBy(
        entity.dataEntries!,
        (e: { periodId: number }) => e.periodId
      ) as Map<number, DataEntry[]>;

      entityEntriesGroupedByPeriod.forEach((dataEntry, _key) => {
        //options
        innerData.push("");

        dataEntry.forEach((entry) => {
          //ACTUALS
          if (entry.ignored) innerData.push("N/A");
          else if (this.dataEntryDetails().textmapId) innerData.push("");
          else innerData.push(entry.actual);

          //TARGETS - For detailed targets
          if (this.hasTarget()) {
            if (this.isBounded()) {
              const dataArray = entry.isBaseLineYear
                ? [
                    this._translateService.instant("kpi.baselineYear"),
                    this._translateService.instant("kpi.baselineYear"),
                  ] //baseline
                : this.dataEntryDetails().textmapId
                  ? ["", ""] //textmapid
                  : [entry.lowerLimit, entry.highLimit]; //default;

              innerData.push(...dataArray);
            } else {
              const dataArray = entry.isBaseLineYear
                ? [this._translateService.instant("kpi.baselineYear")] //baseline
                : this.dataEntryDetails().textmapId
                  ? [""] //textmapid
                  : [entry.target]; //default;
              innerData.push(...dataArray);
            }
          }
        });

        //TARGETS - For not detailed targets
        if (!this.hasTarget()) {
          if (this.isBounded()) {
            const dataArray = dataEntry[0].isBaseLineYear
              ? [this._translateService.instant("kpi.baselineYear"), this._translateService.instant("kpi.baselineYear")] //baseline
              : this.dataEntryDetails().textmapId
                ? ["", ""] //textmapid
                : [dataEntry[0].lowerLimit, dataEntry[0].highLimit]; //default;

            innerData.push(...dataArray);
          } else {
            const dataArray = dataEntry[0].isBaseLineYear
              ? [this._translateService.instant("kpi.baselineYear")] //baseline
              : this.dataEntryDetails().textmapId
                ? [""] //textmapid
                : [dataEntry[0].target]; //default;
            innerData.push(...dataArray);
          }
        }

        //CALC
        innerData.push(
          this._dataEntryService.calcFormula(
            dataEntry,
            this.dataEntryDetails().metricDetails,
            this.dataEntryDetails().formula
          )
        );

        //AUDITED VALUES
        if (this.hasAuditIssue() && dataEntry[0]?.month === 12) {
          const auditEntity = this.dataEntryDetails().ekpiAuditEntries?.find((e) => e.entityId === entity.entityId);

          if (auditEntity) {
            const auditEntityByPeriod = auditEntity.audits?.find((e) => e.year === dataEntry[0].year);
            if (auditEntityByPeriod) {
              //Annual Actual Column
              innerData.push(auditEntityByPeriod.annualActualIgnored ? "N/A" : auditEntityByPeriod.annualActual);
              //Audited Annual Actual Column
              innerData.push(auditEntityByPeriod.auditedActualIgnored ? "N/A" : auditEntityByPeriod.auditedActual);
              //Audited Annual Actual Column
              innerData.push(auditEntityByPeriod.comment);
            }
          }
        }
      });

      data.push(innerData);
    });

    return data;
  }

  private _getColumns() {
    let colIndex = 0;
    const columnWidths: number[] = [];
    const columns: Array<ColumnSettings> = [];

    this.dataEntriesGroupedByPeriod().forEach((dataEntry, key) => {
      //options
      columnWidths.push(100);
      columns.push({ readOnly: true, renderer: this._renderOptionsCell });

      this._columnsIndices.optionsColumns.push({ index: colIndex, periodId: key });
      colIndex += 1;

      this.dataEntryDetails().metricDetails?.forEach((metric) => {
        //actuals
        columnWidths.push(150);
        if (this.dataEntryDetails().textmapId)
          columns.push({
            type: "dropdown",
            source: [],
          });
        else columns.push({});

        this._columnsIndices.actualColumns.push({
          index: colIndex,
          metricNameEN: metric.metricNameEN,
          metricType: metric.metricType,
          periodId: key,
        });
        colIndex += 1;

        //targets - For not detailed targets
        if (this.hasTarget()) {
          if (this.isBounded()) {
            //lower and upper width
            columnWidths.push(150, 150);

            //lower and upper
            columns.push({ readOnly: true, className: "disabledCell" }, { readOnly: true, className: "disabledCell" });

            colIndex += 2;
            this._columnsIndices.targetsColumns.push(colIndex - 1, colIndex);
          } else {
            //target width
            columnWidths.push(150);

            //target
            columns.push({ readOnly: true, className: "disabledCell" });

            colIndex += 1;
            this._columnsIndices.targetsColumns.push(colIndex);
          }
        }
      });

      //targets - For not detailed targets
      if (!this.hasTarget()) {
        if (this.isBounded()) {
          //lower and upper width
          columnWidths.push(150, 150);

          //lower and upper
          columns.push({ readOnly: true, className: "disabledCell" }, { readOnly: true, className: "disabledCell" });

          this._columnsIndices.targetsColumns.push(colIndex - 1, colIndex);
          colIndex += 2;
        } else {
          //target width
          columnWidths.push(150);

          //target
          columns.push({ readOnly: true, className: "disabledCell" });

          this._columnsIndices.targetsColumns.push(colIndex);
          colIndex += 1;
        }
      }

      //calc
      columns.push({ readOnly: true, className: "disabledCell" });
      columnWidths.push(150);
      this._columnsIndices.calcColumns.push({ index: colIndex, periodId: key });
      colIndex += 1;

      //audits
      if (this.hasAuditIssue() && dataEntry[0]?.month === 12) {
        //audit width
        columnWidths.push(150, 150, 300);

        //audit
        columns.push(
          { readOnly: true, className: "disabledCell" }, //annual actual
          { readOnly: true, className: "disabledCell" }, //audited annual actual
          { readOnly: true, className: "disabledCell" } //comment column
        );

        this._columnsIndices.auditAnnualActualColumns.push({ index: colIndex + 1, year: dataEntry[0].year });
        colIndex += 3;
      }
    });

    return { columnWidths, columns };
  }

  private _renderOptionsCell = (_instance: Core, TD: HTMLTableCellElement, row: number, column: number) => {
    TD.innerHTML = this._dataEntryService.getOptionCell(row, column);
  };

  private _handleOnCellMouseDown = (event: { target: Element }) => {
    const action = (<Element>event.target).attributes.getNamedItem("data-type");
    if (action?.value) {
      const row = (<Element>event.target).attributes.getNamedItem("data-row");
      const column = (<Element>event.target).attributes.getNamedItem("data-col");
      if (!row) return;
      if (!column) return;

      if (+row.value > 0) {
        const entity = this.dataEntryDetails().entityDataEntries![+row.value - 1];

        if (entity) {
          const optionColumn = this._columnsIndices.optionsColumns.find((e) => e.index === +column.value);
          if (optionColumn) {
            const entry = entity.dataEntries?.find((e) => e.periodId == optionColumn.periodId);
            if (entry) {
              this.tableOptionsClicked.emit({
                kpiId: entry.kpiId,
                action: action.value,
                periodId: optionColumn.periodId ?? 0,
                dateLabel: this._dataEntryService.getLabelForPeriodColumn(entry, this.dataEntryDetails().frequency!),
              });
            }
          }
        }
      } else {
        const optionColumn = this._columnsIndices.optionsColumns.find((e) => e.index === +column.value);
        if (optionColumn) {
          const entry = this.dataEntryDetails().dataEntries?.find((e) => e.periodId == optionColumn.periodId);
          if (entry) {
            this.tableOptionsClicked.emit({
              kpiId: entry.kpiId,
              action: action.value,
              periodId: optionColumn.periodId ?? 0,
              dateLabel: this._dataEntryService.getLabelForPeriodColumn(entry, this.dataEntryDetails().frequency!),
            });
          }
        }
      }
    }
  };

  private _handleCellValueChanged = (changes: CellChange[] | null) => {
    if (!changes || !this.hotInstance || this.hotInstance.isDestroyed) return;

    changes.forEach((change) => {
      let invalidCell = false;
      let [row, col, prev, next] = change;

      row = +row;
      col = +col;

      if (this._columnsIndices.calcColumns.find((e) => e.index === col)) return;

      const isNA = typeof next === "string" && next.toUpperCase() === "N/A";

      if (!AdaaHelper.isDefinedAndNotEmpty(prev)) prev = undefined;
      if (!AdaaHelper.isDefinedAndNotEmpty(next)) next = undefined;

      if (isNA) next = next.toUpperCase();

      //Avoid saving changes on empty cells
      if (!AdaaHelper.isDefined(prev) && !AdaaHelper.isDefined(next)) return;

      if (isNA || !next) {
        this.hotInstance.removeCellMeta(row, col, "valid");
        invalidCell = false;
      } else if (isNaN(next)) {
        this.hotInstance.setCellMeta(row, col, "valid", false);
        invalidCell = true;
      } else {
        this.hotInstance.removeCellMeta(row, col, "valid");
        invalidCell = false;
      }

      const colIndex = this._columnsIndices.actualColumns.find((e) => e.index === col);
      if (!colIndex) return;

      //GOV KPI
      if (row === 0) {
        const entry = this.dataEntryDetails().dataEntries?.find(
          (e) => e.metricNameEN === colIndex.metricNameEN && e.periodId === colIndex.periodId
        );

        if (entry) {
          entry.invalid = invalidCell;
          entry.ignored = isNA;
          entry.actual = isNA ? undefined : +next;
          this._updateDataEntrySave(entry);
        }

        const entries = this.dataEntryDetails().dataEntries?.filter((e) => e.periodId === colIndex.periodId);
        if (entries) {
          const value = this._dataEntryService.calcFormula(
            entries,
            this.dataEntryDetails().metricDetails,
            this.dataEntryDetails().formula!
          );

          const calcCellIndex = this._columnsIndices.calcColumns.find((e) => e.periodId === colIndex.periodId);
          if (calcCellIndex) this.hotInstance.setDataAtCell(row, calcCellIndex.index, value);
        }
      } else {
        const entity = this.dataEntryDetails().entityDataEntries![row - 1];
        if (entity) {
          const entry = entity.dataEntries?.find(
            (e) => e.metricNameEN === colIndex.metricNameEN && e.periodId === colIndex.periodId
          );

          if (entry) {
            entry.invalid = invalidCell;
            entry.ignored = isNA;
            entry.actual = isNA ? undefined : +next;
            this._updateDataEntrySave(entry);
          }

          const entries = entity.dataEntries?.filter((e) => e.periodId === colIndex.periodId);
          if (entries) {
            const value = this._dataEntryService.calcFormula(
              entries,
              this.dataEntryDetails().metricDetails,
              this.dataEntryDetails().formula!
            );

            const calcCellIndex = this._columnsIndices.calcColumns.find((e) => e.periodId === colIndex.periodId);
            if (calcCellIndex) this.hotInstance.setDataAtCell(row, calcCellIndex.index, value);
          }
        }
      }
    });
    this.hotInstance.render();
  };

  private _updateDataEntrySave(tableEntry: DataEntry) {
    const entry = this.dataEntrySave().dataEntries?.find((e) => e.id === tableEntry.id);
    if (entry) {
      entry.ignored = tableEntry.ignored;
      entry.actual = tableEntry.actual;
      entry.invalid = tableEntry.invalid;
    } else {
      this.dataEntrySave().dataEntries?.push({
        id: tableEntry.id,
        actual: tableEntry.actual,
        ignored: tableEntry.ignored,
        periodId: tableEntry.periodId,
        year: tableEntry.year,
        allowEdit: tableEntry.allowEdit,
        invalid: tableEntry.invalid,
      });
    }

    this.valueChanged.emit(this.dataEntrySave());
  }
}
