import { inject, Injectable } from "@angular/core";

import { GovernmentScore, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class GovScoresPerfrormancesApiService {
  private _mainService = inject(MainService);

  public getGovernmentScoreByPeriod(periodId: number) {
    return this._mainService.sendRequest<MainResponseModel<GovernmentScore>>({
      method: "GET",
      url: `gov-scores-performances/government?periodId=${periodId}`,
    });
  }

  public getScoresByPeriodAndItemType(periodId: number, itemType: number, parentId?: number) {
    let url = `gov-scores-performances/scores?periodId=${periodId}&itemType=${itemType}`;
    if (parentId) url += `&parentId=${parentId}`;

    return this._mainService.sendRequest<MainResponseModel<GovernmentScore[]>>({
      method: "GET",
      url,
    });
  }

  public getKpisByPeriodAndScopeId(periodId: number, scopeId: number) {
    return this._mainService.sendRequest<MainResponseModel<GovernmentScore[]>>({
      method: "GET",
      url: `gov-scores-performances/kpis?periodId=${periodId}&scopeId=${scopeId}`,
    });
  }

  public getKpiChildren(kpiId: number) {
    return this._mainService.sendRequest<MainResponseModel<GovernmentScore[]>>({
      method: "GET",
      url: `gov-scores-performances/kpiChildren?kpiId=${kpiId}`,
    });
  }
}
