import { computed, inject, Injectable, signal } from "@angular/core";
import { Subject } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import { AdaaBoolean } from "../constants/enums";
import type { CycleModelType, EntityModelType } from "../models";
import { ConsoleService } from "./console.service";
import { CyclesApiService } from "./cycles-api.service";
import { DataTableService } from "./data-table.service";
import { EntitiesApiService } from "./entities-api.service";
import { FilesApiService } from "./files-api.service";
import { PermissionsService } from "./permissions.service";
import { TabsService } from "./tabs.service";

const SYS_LAYOUT_MESSAGE_TYPE = {
  entityChange: "entity-has-changed",
  cycleChange: "cycle-has-changed",
};
export const SYS_LAYOUT_CHANNEL = new BroadcastChannel("sys-layout-service");

@Injectable({
  providedIn: "root",
})
export class SystemLayoutService {
  private readonly _entitiesApiService = inject(EntitiesApiService);
  private readonly _cyclesApiService = inject(CyclesApiService);
  private readonly _filesApiService = inject(FilesApiService);
  private readonly _permissionService = inject(PermissionsService);
  private readonly _consoleService = inject(ConsoleService);
  private readonly _dataTableService = inject(DataTableService);
  private readonly _tabsService = inject(TabsService);

  private _cycleHasChanged = new Subject<void>();
  hasCycleChanged$ = this._cycleHasChanged.asObservable();

  private _activeEntityHasChanged = new Subject<void>();
  hasActiveEntityChanged$ = this._activeEntityHasChanged.asObservable();

  cycles = signal<CycleModelType[]>([]);
  entities = signal<EntityModelType[]>([]);
  activeCycle = signal<CycleModelType | null>(null);
  activeEntity = signal<EntityModelType | null>(null);
  activeEntityLogo = signal<string | undefined>("assets/images/adaa-pmo-logo.png");
  searchTerm = signal<string | null>(null);

  isReady = computed(() => {
    const hasCycles = Array.isArray(this.cycles()) && this.cycles().length > 0;
    const hasEntities = Array.isArray(this.entities()) && this.entities().length > 0;

    if (!!this.activeEntity() && this.activeEntity()?.entityType === Constants.CONSTANT_EXTERNAL_ENTITY_TYPE) {
      return hasEntities;
    }

    return hasCycles && !!this.activeCycle() && hasEntities && !!this.activeEntity();
  });

  public get entity() {
    if (this.activeEntity()) {
      return this.activeEntity();
    }
    return AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "json",
    }) as EntityModelType;
  }

  public get cycle() {
    if (this.activeCycle()) {
      return this.activeCycle();
    }
    return AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, {
      type: "json",
    }) as CycleModelType;
  }

  public selectEntity(entity: EntityModelType, preventDefault = false) {
    const oldEntity = AdaaHelper.clone(this.entity);

    this.activeEntity.update(() => entity);
    this._downloadEntityLogo();
    this._fetchAllCycles();

    AdaaHelper.setLocalStorage(Constants.localStorageKeys.currentEntity, entity, "json");

    //Only Emit if the entity id is changed
    if (entity.id === oldEntity.id) return;
    if (entity.entityType !== Constants.CONSTANT_EXTERNAL_ENTITY_TYPE) this._initCycles();

    if (!preventDefault) {
      //Reset the tables filters
      this._dataTableService.resetAllTablesState();

      //Reload all active tabs
      this._tabsService.reloadOtherTabs();

      this._activeEntityHasChanged.next();
      SYS_LAYOUT_CHANNEL.postMessage({ type: SYS_LAYOUT_MESSAGE_TYPE.entityChange });
    }
  }

  public selectCycle(cycle: CycleModelType, preventDefault = false) {
    const oldCycle = AdaaHelper.clone(this.cycle);
    this.activeCycle.update(() => cycle);

    AdaaHelper.setLocalStorage(Constants.localStorageKeys.currentPlan, cycle, "json");

    if (cycle.id === oldCycle.id) return;

    if (!preventDefault) {
      //Reset the tables filters
      this._dataTableService.resetAllTablesState();

      //Reload all active tabs
      this._tabsService.reloadOtherTabs();

      this._cycleHasChanged.next();
      SYS_LAYOUT_CHANNEL.postMessage({ type: SYS_LAYOUT_MESSAGE_TYPE.cycleChange });
    }
  }

  /**
   * @note Called by system layout component to initialize system layout data
   */
  public init() {
    this._initCycles();
    this._initEntities();
    this._initUserPermissions();
    this._consoleService.init();
    this._broadcastChannelListener();
  }

  /**
   * @note: Handle entity and cycle changes across tabs
   */
  private _broadcastChannelListener() {
    SYS_LAYOUT_CHANNEL.addEventListener("message", (e) => {
      const { type } = e.data;
      switch (type) {
        case SYS_LAYOUT_MESSAGE_TYPE.entityChange:
        case SYS_LAYOUT_MESSAGE_TYPE.cycleChange: {
          window.location.reload();
          break;
        }

        default:
          break;
      }
    });
  }

  private _initEntities() {
    const savedActiveEntityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      property: "id",
      type: "prop",
    }) as number | undefined;
    this._fetchAllEntities(savedActiveEntityId);
  }

  private _initCycles() {
    const savedActiveCycle = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, {
      type: "json",
    });
    if (savedActiveCycle) {
      this.selectCycle(savedActiveCycle as CycleModelType);
    }
    this._fetchAllCycles();
  }

  private _fetchAllEntities(entityId?: number) {
    this._entitiesApiService.getAll(AdaaBoolean.Y).subscribe({
      next: (res) => {
        if (res.inError) {
          return;
        }

        this.entities.update(() => res.responseData ?? []);

        if (res.responseData?.length > 0) {
          const entity = res.responseData.find((e) => e.id === entityId) ?? res.responseData[0];
          this.selectEntity(entity);
          AdaaHelper.setLocalStorage(Constants.localStorageKeys.currentEntity, entity, "json");
        }
        this._consoleService.init(false);
      },
    });
  }

  private _fetchAllCycles() {
    this._cyclesApiService.getAll().subscribe({
      next: (res) => {
        if (res.inError) {
          return;
        }

        if (Array.isArray(res.responseData) && res.responseData?.length > 0) {
          this.cycles.update(() => res.responseData ?? []);
        }

        const cycle = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, {
          type: "json",
        });

        if (!cycle && res.responseData?.length > 0) {
          const lastItemIndex = res.responseData.length - 1;
          const cycle = res.responseData[lastItemIndex];
          this.selectCycle(cycle);
          AdaaHelper.setLocalStorage(Constants.localStorageKeys.currentPlan, cycle, "json");
        }
        this._consoleService.init(false);
      },
    });
  }

  private _downloadEntityLogo() {
    const entity = this.activeEntity();
    const fileId = entity?.logoHorizontalAttach?.id ?? entity?.logoVerticalAttach?.id;

    if (fileId)
      this._filesApiService.download(fileId!).subscribe({
        next: async (blob) => {
          const dataUrl = window.URL.createObjectURL(blob);
          this.activeEntityLogo.update(() => dataUrl);
        },
      });
    else this.activeEntityLogo.update(() => undefined);
  }

  private _initUserPermissions() {
    this._permissionService.loadUserPermissions().subscribe();
  }
}
