import { Component, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { Constants } from "../../../constants/constants";
import { PageMode } from "../../../constants/enums";
import { CardEntryInfoType } from "../../../models";
import { AttachmentsListComponent } from "../../attachments";
import { FormInputComponent } from "../../form";

@Component({
  selector: "adaa-edit-data-entry-modal",
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, FormInputComponent, AttachmentsListComponent],
  templateUrl: "./edit-data-entry-modal.component.html",
  styleUrl: "./edit-data-entry-modal.component.scss",
})
export class EditDataEntryModalComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  modal = inject(NgbActiveModal);

  @Input() header: string;
  @Input() dataEntry: CardEntryInfoType;
  @Input() pageMode: PageMode;
  @Input() showSubmit: boolean = true;

  submitted: boolean = false;
  constants = Constants;
  dataEntryForm: FormGroup;

  public ngOnInit(): void {
    this.dataEntryForm = this._prepareForm();
    this.dataEntryForm.patchValue(this.dataEntry);
  }

  private _prepareForm() {
    const isViewMode = this.pageMode === PageMode.view;

    return this._formBuilder.group({
      itemId: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      itemType: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      periodId: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      analysisAE: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      analysisEN: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      recommendationsAE: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      recommendationsEN: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      areasOfImprovementsAE: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      areasOfImprovementsEN: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      entryFile: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      filename: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      attachType: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      pmoNotesEN: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      pmoNotesAE: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      attachFileList: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      id: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      status: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      updateTS: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
      updateUser: this._formBuilder.control<null>({ value: null, disabled: isViewMode }),
    });
  }
}
