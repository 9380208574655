import { Component, EventEmitter, input, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { TpMainSectorsResponse } from "../../../models";

@Component({
  selector: "adaa-tp-main-sectors",
  standalone: true,
  imports: [TranslateModule, TranslateTokenPipe],
  templateUrl: "./tp-main-sectors.component.html",
  styleUrl: "./tp-main-sectors.component.scss",
})
export class TpMainSectorsComponent {
  sectors = input.required<TpMainSectorsResponse>();

  @Output() cardClicked = new EventEmitter<number>();
}
