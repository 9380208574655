import { Component, inject, type OnDestroy, output, signal, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faClose, faEllipsis, type IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { LoginResponse } from "../../../models";
import { FloatActionService, type FloatActionType, LanguageService, PrintService } from "../../../services";
import { WfHistoryComponent } from "../../modals";
import { NotesPopupComponent } from "../object-notes";
import type {
  AddNoteButtonType,
  EditButtonType,
  EmailButtonType,
  FloatButtonType,
  SystemLogButtonType,
  WorkflowHistoryButtonType,
} from "./types";

@Component({
  selector: "adaa-float-action",
  standalone: true,
  styleUrl: "./float-action.component.scss",
  templateUrl: "./float-action.component.html",
  imports: [FontAwesomeModule, TranslateModule, NotesPopupComponent, WfHistoryComponent],
})
export class FloatActionComponent implements OnDestroy {
  readonly languageService = inject(LanguageService);
  readonly floatActionService = inject(FloatActionService);
  private readonly _router = inject(Router);
  private readonly _printService = inject(PrintService);

  notesSaved = output<void>();
  onClick = output<{ action: FloatActionType["key"]; data: FloatActionType["data"] }>();

  notesPopup = viewChild.required<NotesPopupComponent>("notesPopup");
  historyRef = viewChild.required<WfHistoryComponent>("workflowHistory");

  showActions = signal<boolean>(false);

  readonly ellipsis = faEllipsis;
  readonly close = faClose;

  public ngOnDestroy() {
    this.floatActionService.clear();
  }

  public getFaIcon(action: FloatActionType["icon"]) {
    return action.value as IconDefinition;
  }

  public hasNotes() {
    return this.floatActionService.actions().find(({ key }: FloatActionType) => key === "notes");
  }

  public actionClicked(info: { action: FloatActionType["key"]; data: FloatActionType["data"] }) {
    const action = info.action as FloatButtonType;

    switch (action) {
      case "addNote": {
        const data = info.data as AddNoteButtonType["data"];
        this.notesPopup().open(data.itemId, data.itemTypeId);
        break;
      }
      case "edit":
      case "data-entry": {
        const data = info.data as EditButtonType["data"];
        this._router.navigateByUrl(data.url);
        break;
      }
      case "workflowHistory": {
        const data = info.data as WorkflowHistoryButtonType["data"];
        this.historyRef().open({
          itemId: data.itemId,
          itemTypeId: data.itemTypeId,
        });
        break;
      }
      case "systemLog": {
        const data = info.data as SystemLogButtonType["data"];
        this._router.navigate(["/console", "audit-trail"], {
          queryParams: {
            object_id: data.itemId,
            item_type_id: JSON.stringify(data.itemTypeId ?? []),
          },
        });
        break;
      }
      case "print": {
        this._printService.printPage();
        break;
      }
      case "email": {
        const data = info.data as EmailButtonType["data"];
        this._sendEmail(data.objectName);
        break;
      }
      case "helpdesk": {
        this._router.navigateByUrl(`/console/helpdesk/create?url=${window.location.href}`);
        break;
      }
      default:
        break;
    }

    this.onClick.emit(info);
    this.showActions.set(false);
  }

  private _sendEmail(objectName: string | undefined): void {
    const currentUser = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "json",
    }) as LoginResponse;

    const address = "";
    const signature = "Sent from ADAA Government Performance System";
    const bcc = "";
    const subject = objectName ?? "";
    const body = `${objectName ?? ""}%0D%0A${window.location.href}%0D%0A${signature}%0D%0A%0D%0A`;

    window.location.href = `mailto:${address}?subject=${subject}&body=${body}&cc=${currentUser.email}&bcc=${bcc}`;
  }
}
