<div [ngClass]="languageService.current() === 'ae' ? 'rtlDir' : ''" [attr.title]="'navigation.tooltips.nav_legend' | translate">
    <div class="colors-map">
        @for (item of data(); track $index) {
            <div class="item" [style.width]="data()[$index + 1] ? data()[$index + 1].legendBorder - item.legendBorder + '%' : 0">
                <span>{{ item.border }}</span>
                <div class="line"></div>
                <div class="range" [style.background]="item.color ? colors[item.color] : 'transparent'"></div>
            </div>
        }
    </div>
</div>
