import { inject, Injectable } from "@angular/core";

import type {
  EntityModelType,
  MainResponseModel,
  UserSecurityQuestionModelResponseType,
  UserSecurityQuestionModelType,
} from "../models";
import { NotificationChannel } from "../models/notification-channel,model";
import { OwnedObject } from "../models/owned-objects.model";
import { UserProfile } from "../models/user-profile.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class UserProfileApiService {
  private _mainService = inject(MainService);

  public getSecurityQuestion() {
    const url = "userprofile/getSecurityQuestionsLogin";

    return this._mainService.sendRequest<MainResponseModel<UserSecurityQuestionModelType[]>>({
      url,
      method: "GET",
      loader: true,
    });
  }

  public getUserSecurityQuestions() {
    const url = "userprofile/getMySecurityQuestions";

    return this._mainService.sendRequest<MainResponseModel<UserSecurityQuestionModelResponseType>>({
      url,
      method: "GET",
      loader: true,
    });
  }

  public getNotificationChannels(userId: number, searchFields = {}) {
    const url = `userprofile/getAllNotificationPreference/${userId}`;

    return this._mainService.sendRequest<MainResponseModel<NotificationChannel[]>>({
      url,
      method: "POST",
      loader: true,
      data: searchFields,
    });
  }

  public updateNotificationPreference(data: Partial<NotificationChannel>[]) {
    const url = `userprofile/updateNotificationPreference`;

    return this._mainService.sendRequest<MainResponseModel<NotificationChannel[]>>({
      url,
      method: "POST",
      data: data,
    });
  }

  public getNotificationPreference(userId: number) {
    const url = `userprofile/getNotificationPreference/${userId}`;

    return this._mainService.sendRequest<MainResponseModel<Partial<NotificationChannel>>>({
      url,
      method: "GET",
      loader: true,
    });
  }

  public getUserInformation() {
    const url = "users/getCurrentUser";

    return this._mainService.sendRequest<MainResponseModel<UserProfile>>({
      url,
      method: "GET",
      loader: true,
    });
  }

  public getUserEntitiesResponsibleFor(userId: number) {
    const url = `users/getEntitiesResponsibleFor/${userId}`;

    return this._mainService.sendRequest<MainResponseModel<Partial<EntityModelType[]>>>({
      url,
      method: "GET",
      loader: true,
    });
  }

  public getOwnedObjects(searchFields = {}) {
    const url = `userprofile/getOwnedObjects`;

    return this._mainService.sendRequest<MainResponseModel<OwnedObject[]>>({
      url,
      method: "POST",
      loader: true,
      data: searchFields,
    });
  }

  public changePassword(passwordFields: { email: string; oldPassword: string; newPassword: string }) {
    const url = `userprofile/changepassword`;

    return this._mainService.sendRequest<MainResponseModel<null>>({
      url,
      method: "POST",
      loader: true,
      data: passwordFields,
    });
  }

  public updateUserInformation(userInformation: Partial<UserProfile>) {
    const url = `userprofile/updateUserInfo`;

    return this._mainService.sendRequest<MainResponseModel<null>>({
      url,
      method: "POST",
      loader: true,
      data: userInformation,
    });
  }

  public saveSecurityQuestions(questions: { securityQuestions: Partial<UserSecurityQuestionModelType>[] }) {
    const url = `userprofile/saveSecurityQuestions`;

    return this._mainService.sendRequest<MainResponseModel<null>>({
      url,
      method: "POST",
      loader: true,
      data: questions,
    });
  }
}
