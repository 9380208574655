<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ "helpdesk.attachment" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <form class="col-12" [formGroup]="attachmentForm">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        formControlName="nameAE"
                        controlName="nameAE"
                        [setValidator]="supportCenterAttachmentEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameAE')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        formControlName="nameEN"
                        controlName="nameEN"
                        [setValidator]="supportCenterAttachmentEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameEN')"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <adaa-form-input-file
                        label="common.form.label.file"
                        [onDeleteRemoveFile]="false"
                        [isDisabled]="pageMode() === PageMode.view"
                        [allowedFileSize]="26214400"
                        [allowedFiles]="allowedFiles"
                        [allowedFilesMessage]="message"
                        [file]="getFile()"
                        [required]="true"
                        [attachUrl]="'helpdesk/attachmentFile/'"
                        (inputChanges)="onFilechange($event)"
                    ></adaa-form-input-file>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <adaa-form-input-date
                        controlName="attachDate"
                        formControlName="attachDate"
                        [label]="'common.form.label.attachment_date' | translate"
                        [setValidator]="supportCenterAttachmentEditorService.formValidation()!"
                        [invalid]="checkInvalid('attachDate')"
                    ></adaa-form-input-date>
                </div>
            </div>
        </form>
    </div>
    <div class="modal=footer d-flex justify-content-center mt-3">
        <adaa-form-action-buttons
            [showSaveButton]="pageMode() !== PageMode.view"
            [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
            (cancelClicked)="modal.close()"
            (saveClicked)="submit()"
        ></adaa-form-action-buttons>
    </div>
</ng-template>
