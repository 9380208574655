import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import { Constants } from "../constants/constants";
import type { ItemViewCardPerformanceType, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class ChartsApiService {
  private _mainService = inject(MainService);

  public getCalculationReadingsGraphCardByItem({
    itemId,
    itemTypeId,
    graph,
    option,
    entityId,
    periodId,
    mapId,
    includeLegacy = false,
  }: {
    itemId: number;
    option: string;
    itemTypeId: number;
    entityId: number;
    periodId: number;
    mapId: number;
    graph: "bars" | "flat";
    includeLegacy: boolean;
  }) {
    const url = `calculationreading/getCalculationReadingsGraphCardByItem/${itemId}/${itemTypeId}/${graph.toUpperCase()}/${option.toUpperCase()}`;
    const qs = new URLSearchParams();

    qs.append("entityId", `${entityId}`);

    if (periodId) {
      qs.append("periodId", `${periodId}`);
    }

    if (option === Constants.CHART_FREQUENCY_OPTION.FULL_TREND || option === Constants.CHART_FREQUENCY_OPTION.CYCLE) {
      qs.append("cycleId", `${mapId}`);
    }

    if (includeLegacy) {
      qs.append("includeLegacy", "true");
    }

    return this._mainService.sendRequest<MainResponseModel<ItemViewCardPerformanceType[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getCalculationReadingsGraphCardByEntityId() {
    const url = `calculationreading/getCalculationReadingsGraphCardByEntityId`;

    return this._mainService
      .sendRequest<MainResponseModel<unknown>>({
        method: "GET",
        url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getRisksByItemIdItemType() {
    const url = `risk/getRisksByItemIdItemType`;

    return this._mainService
      .sendRequest<MainResponseModel<unknown>>({
        method: "GET",
        url,
      })
      .pipe(filter((res) => !res.inError));
  }
}
