import { Component, EventEmitter, inject, input, OnInit, Output, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../../core/utils";
import { PageMode } from "../../../../constants/enums";
import {
  KpiMetricModelType,
  MonitoredKpiModelType,
  ScopeMainKpi,
  ScopeProjectModelType,
  ValueText,
} from "../../../../models";
import { GovScoresScopesApiService } from "../../../../services";
import { FormDropdownMultiComponent } from "../../../form";

@Component({
  selector: "adaa-links-tab",
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, FormDropdownMultiComponent],
  templateUrl: "./links-tab.component.html",
  styleUrl: "./links-tab.component.scss",
})
export class LinksTabComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _govScoresScopesApiService = inject(GovScoresScopesApiService);

  pageMode = input.required<PageMode>();
  kpi = input.required<ScopeMainKpi>();
  entityId = input.required<number>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() formChanged = new EventEmitter<any>();

  private _subKpisCopy = signal<MonitoredKpiModelType[]>([]);
  private _metricsCopy = signal<KpiMetricModelType[]>([]);
  private _projectsCopy = signal<ScopeProjectModelType[]>([]);

  linksForm: FormGroup;
  subKpis = signal<ValueText[]>([]);
  metrics = signal<ValueText[]>([]);
  projects = signal<ValueText[]>([]);

  public ngOnInit(): void {
    this.linksForm = this._prepareForm();

    this.linksForm.get("subKpisIds")?.setValue(this.kpi()?.govScoresScopeSubKpiList?.map((e) => e.id));
    this.linksForm.get("metricsIds")?.setValue(this.kpi()?.govScoreScopeMetricsList?.map((e) => e.id));
    this.linksForm.get("projectsIds")?.setValue(this.kpi()?.govMainKpiProjectsList?.map((e) => e.id));

    this._fetchSubKpis();
    this._fetchMetrics();
    this._fetchProjects();
  }

  public onSearch(term: string | undefined, type: "subKpi" | "metric"): void {
    if (typeof term === "string" && !term.length) term = undefined;
    switch (type) {
      case "subKpi":
        this._fetchSubKpis(term);
        break;
      case "metric":
        this._fetchMetrics(term);
        break;
    }
  }

  public onSubKpiChange(event: number[]): void {
    if (!event) return;

    const kpis = this._subKpisCopy()
      .filter((e) => event.includes(e.id))
      .map((e) => ({
        id: e.id,
        kpiType: e.kpiType,
      }));

    this.linksForm.get("govScoresScopeSubKpiList")?.setValue(kpis);
    this.formChanged.emit(this.linksForm.getRawValue());
  }

  public onMetricChange(event: number[]): void {
    if (!event) return;

    const kpis = this._metricsCopy()
      .filter((e) => e.id && event.includes(e.id))
      .map((e) => ({
        id: e.id,
        type: e.type,
      }));

    this.linksForm.get("govScoreScopeMetricsList")?.setValue(kpis);
    this.formChanged.emit(this.linksForm.getRawValue());
  }

  public onProjectChange(event: number[]): void {
    if (!event) return;

    const kpis = this._projectsCopy()
      .filter((e) => event.includes(e.id))
      .map((e) => ({
        id: e.id,
        typeId: e.typeId,
      }));

    this.linksForm.get("govMainKpiProjectsList")?.setValue(kpis);
    this.formChanged.emit(this.linksForm.getRawValue());
  }

  private _fetchSubKpis(searchTerm?: string): void {
    if (!AdaaHelper.isDefined(this.entityId())) return;

    this._govScoresScopesApiService
      .getSubKpiByEntityId({
        entityId: this.entityId(),
        limit: 30,
        searchTerm,
        ids: this.linksForm.get("subKpisIds")?.value,
      })
      .subscribe({
        next: (response) => {
          if (response.inError) {
            this.subKpis.set([]);
            return;
          }

          this._subKpisCopy.set(AdaaHelper.clone(response.responseData));

          response.responseData.forEach((e) => {
            e.nameEN = `[${e.refCode} | ${e.kpiTypeEN}] ${e.nameEN}`;
            e.nameAE = `[${e.refCode} | ${e.kpiTypeEN}] ${e.nameAE}`;
          });

          this.subKpis.set(
            AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );
        },
      });
  }

  private _fetchMetrics(searchTerm?: string): void {
    if (!AdaaHelper.isDefined(this.entityId())) return;

    this._govScoresScopesApiService
      .getMetricByEntityId({
        entityId: this.entityId(),
        limit: 30,
        searchTerm,
        ids: this.linksForm.get("metricsIds")?.value,
      })
      .subscribe({
        next: (response) => {
          if (response.inError) {
            this.metrics.set([]);
            this._metricsCopy.set([]);
            return;
          }

          this._metricsCopy.set(AdaaHelper.clone(response.responseData));
          this.metrics.set(
            AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );
        },
      });
  }

  private _fetchProjects(): void {
    if (!AdaaHelper.isDefined(this.entityId())) return;

    this._govScoresScopesApiService.getProjectsByEntityId(this.entityId()).subscribe({
      next: (response) => {
        if (response.inError) {
          this.projects.set([]);
          this._projectsCopy.set([]);
          return;
        }

        this._projectsCopy.set(AdaaHelper.clone(response.responseData));

        response.responseData.forEach((e) => {
          e.nameEN = `[${e.typeNameEN}] ${e.nameEN}`;
          e.nameAE = `[${e.typeNameAE}] ${e.nameAE}`;
        });

        this.projects.set(
          AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"))
        );
      },
    });
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    return this._formBuilder.group({
      govScoresScopeSubKpiList: { value: null, disabled: isViewMode },
      govScoreScopeMetricsList: { value: null, disabled: isViewMode },
      govMainKpiProjectsList: { value: null, disabled: isViewMode },

      subKpisIds: { value: null, disabled: isViewMode },
      metricsIds: { value: null, disabled: isViewMode },
      projectsIds: { value: null, disabled: isViewMode },
    });
  }
}
