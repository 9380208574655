<div class="modal-header px-4 py-3 justify-content-around align-items-center">
    <h5>{{ chartName() }}</h5>

    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>

<div class="modal-body">
    <adaa-chart-action-buttons [elementId]="'kpiVisionChart'" [csvChartInfo]="chartName()"></adaa-chart-action-buttons>
    @let kpiVisions = data();
    @if (kpiVisions.length) {
        <canvas
            id="kpiVisionChart"
            class="performance-chart"
            baseChart
            [type]="chartType"
            [labels]="chartLabels()"
            [datasets]="chartDataset()"
            [options]="chartOptions()"
        ></canvas>
    }
</div>

<div class="modal-footer d-flex justify-content-center align-items-center gap-3">
    <button type="button" class="btn btn-secondary px-2" (click)="modal.close()">
        {{ "common.form.button.close" | translate }}
    </button>
</div>
