import { Component, computed, EventEmitter, inject, input, OnInit, Output } from "@angular/core";
import { NgxSliderModule, Options } from "@angular-slider/ngx-slider";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription, switchMap } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { Language } from "../../../constants/enums";
import { PeriodModelType, SelectedPeriodModelType } from "../../../models";
import { LanguageService, PeriodSliderService, SystemLayoutService } from "../../../services";

@Component({
  selector: "adaa-period-slider",
  standalone: true,
  imports: [NgxSliderModule, TranslateModule],
  templateUrl: "./period-slider.component.html",
  styleUrl: "./period-slider.component.scss",
})
export class PeriodSliderComponent implements OnInit {
  private readonly _systemLayoutService = inject(SystemLayoutService);
  private readonly _periodSliderService = inject(PeriodSliderService);
  private readonly _languageService = inject(LanguageService);

  periodId = input<number | undefined>(undefined);
  periodType = input<"quarter" | "year" | undefined>(undefined);
  activePeriod = input.required<SelectedPeriodModelType>();
  type = input<"quarter" | "year">("quarter");
  frequency = input<number>(Constants.FREQUENCY.MONTHLY);
  startDate = input<number | undefined>(undefined);
  endDate = input<number | undefined>(undefined);
  dontShowYears = input<boolean>(false);

  @Output() periodSelect = new EventEmitter<SelectedPeriodModelType>();

  selectedPeriod = computed(() => {
    const activePeriod = this.activePeriod() ?? this._periodSliderService.actualPeriod();
    if (activePeriod) {
      if (this.type() === "quarter" && activePeriod.type === "year") return activePeriod.period.year;
      return this.type() === "quarter" ? activePeriod.period.id : activePeriod.period.year;
    }
    return;
  });
  isReady = computed(() => this._periodSliderService.isReady());

  options: Options = {
    showTicksValues: false,
    showTicks: true,
    rightToLeft: this._languageService.current() === Language.Arabic,
    stepsArray: [],
  };

  private _subscriptions = new Subscription();

  public ngOnInit(): void {
    const startYear = AdaaHelper.getDubaiTime(AdaaHelper.plan?.startYear);
    const endYear = AdaaHelper.getDubaiTime(AdaaHelper.plan?.endYear);

    this._periodSliderService.startDate.set(this.startDate() ?? startYear);
    this._periodSliderService.endDate.set(this.endDate() ?? endYear);
    this._periodSliderService.init(this.type(), this.frequency()).subscribe({
      next: () => {
        this._initialize();
      },
    });
    this._refresh();
  }

  public periodChanged(value: number): void {
    if (this.type() === "year") {
      this._yearlyPeriodChange(value);
      return;
    }
    this._quarterlyPeriodChange(value);
  }

  private _yearlyPeriodChange(value: number) {
    const period = this._periodSliderService.periodSlots().find((e) => e.year === value);
    const index = this.frequency() === Constants.FREQUENCY.ANNUAL ? 0 : 3;
    if (period) this._selectPeriod({ period: period.quarters[index], type: "year" });
  }

  private _quarterlyPeriodChange(value: number) {
    const allPeriods: PeriodModelType[] = [];
    this._periodSliderService.periodSlots().forEach((e) => allPeriods.push(...e.quarters));

    for (const item of allPeriods) {
      if (item.id === value) {
        this._selectPeriod({ period: item, type: "quarter" });
        return;
      }

      if (item.year === value && item.quarter === 4) {
        this._selectPeriod({ period: item, type: "year" });
        return;
      }
    }
  }

  private _initialize() {
    this.options.stepsArray = [];
    this._periodSliderService.periodSlots().forEach((e) => {
      if (this.type() === "quarter") {
        e.quarters.forEach((q) => {
          this.options.stepsArray?.push({
            value: q.id,
            legend: `Q${q.quarter}${this.dontShowYears() ? ` ${q.year}` : ""}`,
          });
        });
      }
      if (!this.dontShowYears()) {
        this.options.stepsArray?.push({ value: e.year, legend: e.year.toString() });
      }
    });

    const inheritedPeriod = this._getPeriod();
    if (inheritedPeriod) {
      this.periodSelect.emit({
        type: this.periodType() ?? this.type(),
        period: inheritedPeriod,
      });
    } else {
      const period = this._periodSliderService.actualPeriod();
      if (period) {
        try {
          this.periodSelect.emit(period);
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (e) {}
      }
    }
  }

  private _getPeriod() {
    const periodId = this.periodId();
    if (!periodId) return;
    const periodSlots = this._periodSliderService.periodSlots();
    for (const { quarters } of periodSlots) {
      const quarter = quarters.find(({ id }) => id === periodId);
      if (!quarter) continue;
      return quarter;
    }
    return;
  }

  private _selectPeriod(data: SelectedPeriodModelType) {
    this.periodSelect.emit(data);
  }

  private _refresh() {
    this._subscriptions.add(
      this._systemLayoutService.hasCycleChanged$
        .pipe(switchMap(() => this._periodSliderService.init(this.type(), this.frequency())))
        .subscribe()
    );

    this._subscriptions.add(
      this._periodSliderService.triggerRefresh$
        .pipe(switchMap(() => this._periodSliderService.init(this.type(), this.frequency())))
        .subscribe()
    );
  }
}
