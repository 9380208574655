import { AdaaBooleanType } from "../../../../../../../../adaa-types";
import { AdaaHelper } from "../../../../../../../core/utils";
import { Constants } from "../../../../../../constants/constants";
import { AdaaBoolean } from "../../../../../../constants/enums";
import { commonFormatter, SharedFields, textField } from "../../configs";
import { ListSectionConfigType, ScreenSectionConfigType, ScreenType } from "../../wf-difference.types";
import {
  checkVisionTargetLabel,
  isDkpi,
  isDtkpi,
  isEkpi,
  isMokpi,
  isMtkpi,
  isNkpi,
  isNtkpi,
  isOpm,
  isSkpi,
  isSrvkpi,
} from "../../wf-difference.utils";
import { improvementPlanSectionConfig } from "./improvement-plan.config";

const linkedKpiIdField: ScreenSectionConfigType = {
  field: "linkedKpiId",
  label: "data_entry.linked_to",
  view: "normal",
  format: (data: { linkedKpiType: number }) => {
    switch (data?.linkedKpiType) {
      case Constants.ITEM_TYPES_CALCULATION.NKPI:
        return "landingPage.kpiTable.nkpi";
      case Constants.ITEM_TYPES_CALCULATION.DKPI:
        return "landingPage.kpiTable.dkpi";
      default:
        return;
    }
  },
};

const nationalProjectIdField = {
  ...(textField("nationalProjectName", {
    use: "current",
    label: "national_projects.project_name",
  }) as ScreenSectionConfigType),
  field: "nationalProjectId",
  visibleIf: (data: { kpiType: number }) => isMtkpi(data?.kpiType) || isMokpi(data?.kpiType),
  format: (data: Record<string, string>) => AdaaHelper.getItemValueByToken(data, "nationalProjectName"),
};

const projectMilestoneIdField = {
  ...(textField("projectMilestoneName", {
    use: "current",
    label: "mtkpi.projectMilestoneId",
  }) as ScreenSectionConfigType),
  field: "projectMilestoneId",
  visibleIf: (data: { kpiType: number }) => isMtkpi(data?.kpiType),
  format: (data: Record<string, string>) => AdaaHelper.getItemValueByToken(data, "projectMilestoneName"),
};

const govDirectionIdField: ScreenSectionConfigType = {
  field: "govDirectionId",
  view: "normal",
  format: (data: Record<string, string>) => AdaaHelper.getItemValueByToken(data, "govDirectionName"),
  visibleIf: (data: { kpiType: number }) =>
    isNtkpi(data?.kpiType) || isDtkpi(data?.kpiType) || isNkpi(data?.kpiType) || isDkpi(data?.kpiType),
  label: (data: { newData: { kpiType: number } }) => {
    if (isNtkpi(data?.newData?.kpiType)) return "directions.national_vision";
    if (isDtkpi(data?.newData?.kpiType)) return "dtkpi.nationalStrategy";
    return "directions.government_direction";
  },
};

const subDirectionIdField: ScreenSectionConfigType = {
  field: "subDirectionId",
  view: "normal",
  format: (data: Record<string, string>) => AdaaHelper.getItemValueByToken(data, "subDirectionName"),
  visibleIf: (data: { kpiType: number }) =>
    isNtkpi(data?.kpiType) || isDtkpi(data?.kpiType) || isNkpi(data?.kpiType) || isDkpi(data?.kpiType),
  label: (data: { newData: { kpiType: number } }) => {
    if (isNtkpi(data?.newData?.kpiType)) return "directions.main_pillar";
    if (isDtkpi(data?.newData?.kpiType)) return "dtkpi.enablerObjective";
    return "directions.sub_government";
  },
};

const auxiliaryDirectionId: ScreenSectionConfigType = {
  ...(textField("auxiliaryDirectionName", {
    use: "current",
    label: "directions.sub_pillar",
  }) as ScreenSectionConfigType),
  format: (data: Record<string, string>) => AdaaHelper.getItemValueByToken(data, "auxiliaryDirectionName"),
  visibleIf: (data: { kpiType: number }) => isNtkpi(data?.kpiType),
  field: "auxiliaryDirectionId",
};

const ntkpiField: ScreenSectionConfigType = {
  ...SharedFields.nationalAgendaField,
  visibleIf: (data: { kpiType: number }) => isSkpi(data?.kpiType),
  hasDiff: (newDataField: { id: number }, oldDataField: { id: number }) => newDataField?.id !== oldDataField?.id,
};

const objectiveTypeField: ScreenSectionConfigType = {
  field: "objectiveType",
  view: "normal",
  label: "objectives.objective_type",
  visibleIf: (data: { kpiType: number }, options: { useNewDS: boolean }) => options?.useNewDS && isSkpi(data?.kpiType),
  format: (data: { objectiveType: number }) =>
    ({
      [Constants.CONSTANT_MAIN_OBJECTIVE]: "breadcrumbs.objectives.main",
      [Constants.CONSTANT_STRATEGIC_OBJECTIVE]: "breadcrumbs.objectives.strategic",
      [Constants.CONSTANT_ENABLER_OBJECTIVE]: "objectives.enabler_objective",
    })[data?.objectiveType],
};

const initiativeTypeField: ScreenSectionConfigType = {
  field: "initiativeType",
  view: "normal",
  label: "initiatives.project_type",
  visibleIf: (data: { kpiType: number }, options: { useNewDS: boolean }) => options?.useNewDS && isOpm(data?.kpiType),
  format: (data: { initiativeType: number }) =>
    ({
      [Constants.CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE]: "initiatives.strategic_project",
      [Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE]: "initiatives.regular_task",
    })[data?.initiativeType],
};

const highPriorityField: ScreenSectionConfigType = {
  ...SharedFields.highPriority,
  visibleIf: (data: { kpiType: number }) => isSrvkpi(data?.kpiType),
};

const importedValuesField: ScreenSectionConfigType = {
  field: "importedValues",
  view: "normal",
  label: "srvkpi.importValues",
  format: (data: { importedValues: AdaaBooleanType }) => commonFormatter.matchYesNo(data?.importedValues),
  visibleIf: (data: { importedValues: number; kpiType: number }) =>
    isSrvkpi(data?.kpiType) && AdaaHelper.isDefined(data?.importedValues),
};

const perspectiveField: ScreenSectionConfigType = {
  field: "perspective",
  view: "normal",
  label: "srvkpi.perspective",
  visibleIf: (data: { perspective: number; kpiType: number }) => isSrvkpi(data?.kpiType),
};

const classificationField: ScreenSectionConfigType = {
  field: "classification",
  view: "normal",
  label: "srvkpi.classification",
  visibleIf: (data: { kpiType: number }) => isSrvkpi(data?.kpiType),
};

const veryImportantField: ScreenSectionConfigType = {
  field: "veryImportant",
  label: "srvkpi.veryImportant",
  view: "normal",
  format: (data: { veryImportant: AdaaBoolean }) => commonFormatter.matchYesNo(data?.veryImportant),
  visibleIf: (data: { kpiType: number }, _options: { isPmoUser: boolean }) =>
    isSrvkpi(data?.kpiType) && _options.isPmoUser,
};

const veryImportantReasonField: ScreenSectionConfigType = {
  field: "veryImportantReasonAE",
  label: "srvkpi.reason_veryImportant",
  view: "normal",
  visibleIf: (data: { kpiType: number }, _options: { isPmoUser: boolean }) =>
    isSrvkpi(data?.kpiType) && _options.isPmoUser,
};

const mainServiceNameField = {
  ...(textField("mainServiceName", { use: "current", label: "srvkpi.service" }) as ScreenSectionConfigType),
  visibleIf: (data: { kpiType: number }) => isSrvkpi(data?.kpiType),
};

const serviceIdField: ScreenSectionConfigType = {
  field: "serviceId",
  view: "normal",
  label: "srvkpi.auxVarService",
  format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "serviceName"),
  visibleIf: (data: { kpiType: number }) => isSrvkpi(data?.kpiType),
};

const activityIdField: ScreenSectionConfigType = {
  field: "activityId",
  view: "normal",
  label: "opm.activity",
  format: (value: Record<string, string>) => value.activityNameAE || value.activityNameEN,
  visibleIf: (data: { kpiType: number }, options: { useNewDS: boolean }) => isOpm(data?.kpiType) && !options?.useNewDS,
};

const initiativeIdField: ScreenSectionConfigType = {
  field: "initiativeId",
  view: "normal",
  label: (options: { useNewDS: boolean }) => (options?.useNewDS && "initiatives.project") || "initiatives.initiative",
  format: (value: Record<string, string>) => value.initiativeNameAE || value.initiativeNameEN,
  visibleIf: (data: { kpiType: number }, options: { useNewDS: boolean }) => isOpm(data?.kpiType) && options?.useNewDS,
};

const ekpiPillarIdField: ScreenSectionConfigType = {
  field: "initiativeId",
  view: "normal",
  label: "ekpi.piller",
  format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "ekpiPillarName"),
  visibleIf: (data: { kpiType: number }) => isEkpi(data?.kpiType),
};

const enablerEntityIdField: ScreenSectionConfigType = {
  field: "enablerEntityId",
  view: "normal",
  label: "ekpi.enablerEntity",
  format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "enablerEntityName"),
  visibleIf: (data: { kpiType: number }) => isEkpi(data?.kpiType),
};

const govDirIdField: ScreenSectionConfigType = {
  ...(textField("mainServiceName", {
    use: "current",
    label: "directions.government_direction",
  }) as ScreenSectionConfigType),
  visibleIf: (data: { kpiType: number }) => isNkpi(data?.kpiType) || isDkpi(data?.kpiType),
};

const subGovDirIdField: ScreenSectionConfigType = {
  ...(textField("subDirectionName", {
    use: "current",
    label: "directions.sub_government",
  }) as ScreenSectionConfigType),
  visibleIf: (data: { kpiType: number }) => isNkpi(data?.kpiType) || isDkpi(data?.kpiType),
};

const sponsorEntityIdField: ScreenSectionConfigType = {
  field: "sponsorEntityId",
  view: "normal",
  label: "nkpi.sponsorEntity",
  format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "sponsorEntityName"),
  visibleIf: (data: { kpiType: number }) =>
    isNkpi(data?.kpiType) || isDkpi(data?.kpiType) || isNtkpi(data?.kpiType) || isDtkpi(data?.kpiType),
};

const execTeamNameField: ScreenSectionConfigType = {
  ...(textField("execTeamName", {
    use: "current",
    label: "nkpi.executive_team",
  }) as ScreenSectionConfigType),
  visibleIf: (data: { kpiType: number }) => isNkpi(data?.kpiType) || isDkpi(data?.kpiType),
};

const dataSourceField: ScreenSectionConfigType = {
  field: "dataSource",
  label: "nkpi.data_source_entity_source",
  view: "normal",
};

const mainOutcomeTypeField: ScreenSectionConfigType = {
  field: "mainOutcomeType",
  label: "mokpi_type",
  view: "normal",
  format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "mainOutcomeTypeName"),
  visibleIf: (data: { kpiType: number }) => isMokpi(data?.kpiType),
};

const transformationalTargetField: ScreenSectionConfigType = {
  field: "transformationalTarget",
  label: "transformational_target",
  view: "normal",
  visibleIf: (data: { kpiType: number }) => isMokpi(data?.kpiType),
  hasDiff: (newValue: Record<string, { [k: string]: string }>, oldValue: Record<string, { [k: string]: string }>) =>
    newValue?.transformationalTarget?.id !== oldValue?.transformationalTarget?.id,
  format: (value: Record<string, { [k: string]: string }>) =>
    AdaaHelper.getItemValueByToken(value.transformationalTarget, "name"),
};

const visionTargetField: ScreenSectionConfigType = {
  field: "visionTarget",
  view: "normal",
  label: (data: { newData: { kpiType: number } }) => checkVisionTargetLabel(data.newData.kpiType),
  visibleIf: (data: { kpiType: number }) =>
    isMokpi(data?.kpiType) ||
    isMtkpi(data?.kpiType) ||
    isDtkpi(data?.kpiType) ||
    isDkpi(data?.kpiType) ||
    isNtkpi(data?.kpiType) ||
    isNkpi(data?.kpiType),
};

const legacyKpiField: ScreenSectionConfigType = {
  field: "legacyKpi",
  label: "kpi.legacy_kpi",
  view: "normal",
  visibleIf: (data: { kpiType: number }) => !isNtkpi(data?.kpiType) || !isDtkpi(data?.kpiType),
  format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "legacyKpiName"),
};

const targetCategoryField: ScreenSectionConfigType = {
  field: "targetCategory",
  label: "target_category",
  view: "normal",
  visibleIf: (data: { kpiType: number }) => isNtkpi(data?.kpiType),
};

const publishedStateField: ScreenSectionConfigType = {
  field: "publishedState",
  label: "published_state",
  view: "normal",
  visibleIf: (data: { kpiType: number }) => isNtkpi(data?.kpiType),
};

const targetTypeIdField: ScreenSectionConfigType = {
  field: "targetTypeId",
  label: "ntkpi.target_type",
  view: "normal",
  visibleIf: (data: { kpiType: number }) => isNtkpi(data?.kpiType) || isDtkpi(data?.kpiType),
};

const actualDataAvailabilityPeriodField: ScreenSectionConfigType = {
  field: "actualDataAvailabilityPeriod",
  label: "ntkpi.actual_data_availability_period",
  view: "normal",
  visibleIf: (data: { kpiType: number }) => isNtkpi(data?.kpiType),
};

const measurementMechanismField: ScreenSectionConfigType = {
  field: "measurementMechanism",
  label: "ntkpi.explanation_of_the_measurement_mechanism",
  view: "normal",
  visibleIf: (data: { kpiType: number }) => isNtkpi(data?.kpiType) || isDtkpi(data?.kpiType),
};

const baselineKpiField: ScreenSectionConfigType = {
  field: "baselineKpi",
  label: "nkpi.baselineKpi",
  view: "normal",
  format: (data: { baselineKpi: AdaaBoolean }) => {
    if (!AdaaHelper.isDefined(data.baselineKpi)) return commonFormatter.matchYesNo(AdaaBoolean.N);
    return commonFormatter.matchYesNo(data?.baselineKpi);
  },
  hasDiff: (newValue: AdaaBooleanType | null, oldValue: AdaaBooleanType | null) => {
    if (newValue === AdaaBoolean.N && !AdaaHelper.isDefined(oldValue)) return false;
    if (oldValue === AdaaBoolean.N && !AdaaHelper.isDefined(newValue)) return false;
    return oldValue !== newValue;
  },
};

const maxRankField: ScreenSectionConfigType = {
  field: "maxRank",
  label: "nkpi.max_rank",
  view: "normal",
  visibleIf: (data: { measurementUnit: number }) => data.measurementUnit === Constants.CONSTANT_MEASUREMENT_RANK,
};

const contributingEntitiesField: ListSectionConfigType = {
  field: "contributingEntities",
  label: "kpi.contributing_entities",
  view: "list",
  idKey: "entityId",
  derivedList: (data: { otherEntityId?: number }[]) => data.filter(($i) => !AdaaHelper.isDefined($i?.otherEntityId)),
  format: ($o) => AdaaHelper.getItemValueByToken($o, "entityName"),
  visibleIf: (data: { kpiType: number }) => isNtkpi(data?.kpiType) || isDtkpi(data?.kpiType),
};

const otherContributingEntitiesField: ListSectionConfigType = {
  field: "contributingEntities",
  label: "other_entities.contrib_entities",
  view: "list",
  idKey: "otherEntityId",
  derivedList: (data: { otherEntityId?: number }[]) => data.filter(($i) => AdaaHelper.isDefined($i?.otherEntityId)),
  format: ($o) => AdaaHelper.getItemValueByToken($o, "otherEntityName"),
  visibleIf: (data: { kpiType: number }) => isNtkpi(data?.kpiType) || isDtkpi(data?.kpiType),
};

const affectedEntitiesField: ListSectionConfigType = {
  field: "affectedEntities",
  label: "ekpi.affectedEntities",
  view: "list",
  idKey: "entityId",
  format: ($o) => AdaaHelper.getItemValueByToken($o, "entityName"),
  visibleIf: (data: { kpiType: number }) => isEkpi(data?.kpiType),
};

const internationalOrganizationsField: ListSectionConfigType = {
  field: "internationalOrganizations",
  label: "ntkpi.international_organizations",
  view: "list",
  idKey: "entityId",
  derivedList: (data: { otherEntityId?: number }[]) => data.filter(($i) => !AdaaHelper.isDefined($i?.otherEntityId)),
  format: ($o) => AdaaHelper.getItemValueByToken($o, "organizationName"),
  visibleIf: ($o: { kpiType: number }) => {
    return $o.kpiType !== Constants.CONSTANT_SKPITYPE;
  },
};

const entityLeadOptionRequestsField: ListSectionConfigType = {
  field: "entityLeadOptionRequests",
  label: "ntkpi_card.wfcs.eld_title",
  view: "list",
  idKey: "entityId",
  derivedList: (data: { otherEntityId?: number }[]) => data.filter(($i) => !AdaaHelper.isDefined($i?.otherEntityId)),
  format: ($o) => AdaaHelper.getItemValueByToken($o, "optionName"),
  visibleIf: (data: { kpiType: number }, options) => isNtkpi(data?.kpiType) && options[0] === true,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const improvementPlanField: any = {
  view: "accordion",
  field: "nationalTargetImprovementPlans",
  title: ($o: unknown) => AdaaHelper.getItemValueByToken($o, "name"),
  idKey: "id",
  config: improvementPlanSectionConfig,
  label: "ntkpi.improvement_plan",
};

export const commonKpiScreenConfig = {
  type: "kpi" as ScreenType,
  sections: [
    linkedKpiIdField,
    nationalProjectIdField,
    projectMilestoneIdField,
    ...SharedFields.name,
    ...SharedFields.description,
    SharedFields.startDate,
    SharedFields.endDate,
    {
      ...SharedFields.objectiveIdField,
      visibleIf: (data: { kpiType: number }) => isSkpi(data?.kpiType) || isSrvkpi(data?.kpiType),
      format: (data: { objectiveNameAe: string; objectiveNameEn: string }) =>
        AdaaHelper.getCurrentLang() === "ae" ? data.objectiveNameAe : data.objectiveNameEn,
    },
    govDirectionIdField,
    subDirectionIdField,
    auxiliaryDirectionId,
    SharedFields.orgUnitIdField,
    SharedFields.ownerField,
    ntkpiField,
    objectiveTypeField,
    initiativeTypeField,
    highPriorityField,
    importedValuesField,
    perspectiveField,
    classificationField,
    veryImportantField,
    veryImportantReasonField,
    mainServiceNameField,
    serviceIdField,
    activityIdField,
    initiativeIdField,
    ekpiPillarIdField,
    {
      ...SharedFields.entityIdField,
      visibleIf: (data: { kpiType: number }) => !isNtkpi(data?.kpiType) || isDtkpi(data?.kpiType),
    },
    enablerEntityIdField,
    govDirIdField,
    subGovDirIdField,
    sponsorEntityIdField,
    execTeamNameField,
    dataSourceField,
    mainOutcomeTypeField,
    transformationalTargetField,
    {
      field: "scopeMeasureAE",
      label: "nkpi.scopeMeasurement",
      view: "normal",
    },
    visionTargetField,
    legacyKpiField,
    targetCategoryField,
    publishedStateField,
    targetTypeIdField,
    actualDataAvailabilityPeriodField,
    measurementMechanismField,
    internationalOrganizationsField,
    {
      field: "commentsAE",
      label: "common.form.label.commentsAE",
      view: "normal",
      hasDiff: (newValue: string | null, oldValue: string | null) => {
        if (!AdaaHelper.isDefinedAndNotEmpty(newValue)) newValue = null;
        if (!AdaaHelper.isDefinedAndNotEmpty(oldValue)) oldValue = null;
        return newValue !== oldValue;
      },
    },
    {
      field: "measurementUnit",
      label: "kpi.measurement_unit",
      view: "normal",
    },
    {
      field: "measurementUnitDscAE",
      label: "nkpi.measurement_unit_dsc_ae",
      view: "normal",
    },
    maxRankField,
    {
      field: "frequency",
      label: "kpi.frequency",
      view: "normal",
    },
    {
      field: "trend",
      label: "kpi.trend",
      view: "normal",
    },
    baselineKpiField,
    {
      field: "baselineYear",
      label: "kpi.baselineYear",
      view: "normal",
      visibleIf: (data: { baselineKpi: AdaaBoolean }) => data.baselineKpi === AdaaBoolean.Y,
    },
    {
      field: "ytpCalc",
      label: "kpi.year_to_period",
      view: "normal",
    },
    {
      field: "formula",
      label: "kpi.formula",
      view: "formula",
    },
    contributingEntitiesField,
    affectedEntitiesField,
    otherContributingEntitiesField,
    entityLeadOptionRequestsField,
    { view: "targets" },
    { view: "ext-fields" },
    improvementPlanField,
    SharedFields.attachmentsField,
    SharedFields.benchmarksField,
  ] as const,
};
