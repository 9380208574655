import { DatePipe } from "@angular/common";
import { Component, inject, input, OnInit, output } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NgxSliderModule, Options } from "@angular-slider/ngx-slider";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCircle, faTimeline } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { Constants } from "../../../constants/constants";
import { Language } from "../../../constants/enums";
import { AgmMilestone, AgmProject, TpMilestone, TpProjectDetails } from "../../../models";
import { LanguageService } from "../../../services";
import { DoughnutComponent } from "../../charts";

@Component({
  selector: "adaa-tp-timeline",
  standalone: true,
  imports: [RouterModule, TranslateModule, NgxSliderModule, FontAwesomeModule, TranslateTokenPipe, DoughnutComponent],
  providers: [DatePipe],
  templateUrl: "./tp-timeline.component.html",
  styleUrl: "./tp-timeline.component.scss",
})
export class TpTimelineComponent implements OnInit {
  project = input.required<TpProjectDetails | AgmProject>();
  milestones = input.required<TpMilestone[] | AgmMilestone[]>();
  mainAchievementsClicked = output<number>();
  milestoneClicked = output<number>();

  private _languageService = inject(LanguageService);
  private _translateService = inject(TranslateService);
  private _datePipe = inject(DatePipe);
  private _iconLibrary = inject(FaIconLibrary);

  sliderOption: Options | undefined = undefined;

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faTimeline, faCircle);

    const dateRange: Date[] = this._getDateRange();
    this.sliderOption = {
      rightToLeft: this._languageService.current() === Language.Arabic,
      showTicks: false,
      disabled: true,
      stepsArray: dateRange.map((date: Date) => {
        return { value: date.getTime() };
      }),
      translate: (value: number): string => {
        return this._datePipe.transform(value, Constants.monthYearDate) ?? "";
      },
    };
  }

  public getTitle(startDate?: number, endDate?: number, isActual: boolean = false): string {
    let tooltip: string = this._translateService.instant(
      isActual ? "national_projects.actual" : "national_projects.planned"
    );
    if (startDate)
      tooltip += `\n${this._translateService.instant("common.form.label.start_date")}: ${this._datePipe.transform(startDate, Constants.normalDate)}`;
    if (endDate)
      tooltip += `\n${this._translateService.instant("common.form.label.end_date")}: ${this._datePipe.transform(endDate, Constants.normalDate)}`;
    return tooltip;
  }

  private _getDateRange(): Date[] {
    const dates = [];
    const startDate = new Date(this.project().plannedStartDate);
    const endDate = new Date(this.project().plannedEndDate);
    for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
      dates.push(new Date(d));
    }

    return dates;
  }
}
