import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AdaaHelper } from "../../../core/utils";
import { Constants } from "../../constants/constants";
import { AttachFile, ParameterCatalog } from "../../models";

export const challengeAnalysisForm = (fields: ParameterCatalog[]) =>
  new FormGroup({
    id: new FormControl<number | null>(null, []),
    analysis: new FormControl<string | null>(null, [
      ...AdaaHelper.getRequiredValidator(fields, "analysis"),
      Validators.minLength(3),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
    ]),
    rootCause: new FormControl<string | null>(null, [
      ...AdaaHelper.getRequiredValidator(fields, "rootCause"),
      Validators.minLength(3),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
    ]),
    initialProposedTreatment: new FormControl<string | null>(null, [
      ...AdaaHelper.getRequiredValidator(fields, "initialProposedTreatment"),
      Validators.minLength(3),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
    ]),
    pmoRequirement: new FormControl<string | null>(null, [
      ...AdaaHelper.getRequiredValidator(fields, "pmoRequirement"),
      Validators.minLength(3),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
    ]),
    assessment: new FormControl<string | null>(null, [
      ...AdaaHelper.getRequiredValidator(fields, "assessment"),
      Validators.minLength(3),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.CHAR_MAXLENGTH),
    ]),
    supportingEvidenceAttachmentId: new FormControl<number | null>(null, []),
    supportingEvidenceAttachment: new FormControl<AttachFile | null>(null, []),
  });

export const successStoryForm = (fields: ParameterCatalog[]) =>
  new FormGroup({
    id: new FormControl<number | null>(null, []),
    achievementTitle: new FormControl<string | null>(null, [
      ...AdaaHelper.getRequiredValidator(fields, "achievementTitle"),
      Validators.minLength(3),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.CHAR_MAXLENGTH),
    ]),
    leadershipLevel: new FormControl<string | null>(null, [
      ...AdaaHelper.getRequiredValidator(fields, "leadershipLevel"),
      Validators.minLength(3),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.CHAR_MAXLENGTH),
    ]),
    achievementDsc: new FormControl<string | null>(null, [
      ...AdaaHelper.getRequiredValidator(fields, "achievementDsc"),
      Validators.minLength(3),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
    ]),
    impactAchievementTarget: new FormControl<string | null>(null, [
      ...AdaaHelper.getRequiredValidator(fields, "impactAchievementTarget"),
      Validators.minLength(3),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
    ]),
    impactAchievementSociety: new FormControl<string | null>(null, [
      ...AdaaHelper.getRequiredValidator(fields, "impactAchievementSociety"),
      Validators.minLength(3),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
    ]),
    projectLaunchDate: new FormControl<string | null>(null, [
      ...AdaaHelper.getRequiredValidator(fields, "projectLaunchDate"),
      Validators.minLength(3),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
    ]),
    beneficiaries: new FormControl<string | null>(null, [
      ...AdaaHelper.getRequiredValidator(fields, "beneficiaries"),
      Validators.minLength(3),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.CHAR_MAXLENGTH),
    ]),
    projectContinuity: new FormControl<string | null>(null, [
      ...AdaaHelper.getRequiredValidator(fields, "projectContinuity"),
      Validators.minLength(3),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
    ]),
    supportingEvidenceAttachmentId: new FormControl<number | null>(null, []),
    supportingEvidenceAttachment: new FormControl<AttachFile | null>(null, []),
  });
