<adaa-data-table
    titleHeader="national_projects.national_projects_milestone.sub_milestones"
    [configKey]="constants.MAIN_TABLE_LIST_CONF_KEY.SUB_PROJECT_MILESTONE_CONF_LIST"
    [enableExport]="false"
    [enableAdd]="true"
    [enableViewAction]="false"
    [isLocalData]="true"
    [localData]="subMilestonesData"
    (addNewClicked)="addNewClicked()"
    (actionClicked)="actionClicked($event)"
></adaa-data-table>
