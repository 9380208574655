<div class="m-1" [class.invalid]="invalid">
    <label *ngIf="adaaHelper.isDefinedAndNotEmpty(label)" class="form-label">
        {{ label }}
        <span *ngIf="required" class="form-required text-danger fw-bolder">*</span></label
    >

    <div class="input-group">
        <span class="input-group-text">{{ fromLabel }}</span>
        <input
            type="number"
            class="form-control"
            [class.is-invalid]="invalid"
            [id]="controlId + '-from'"
            [placeholder]="fromPlaceholder"
            [value]="fromValue"
            (change)="valueChanged($event, true)"
        />
        <span class="px-2"></span>

        @if (languageService.direction() === "ltr") {
            <span class="input-group-text">{{ toLabel }}</span>
            <input
                type="number"
                class="form-control"
                [class.is-invalid]="invalid"
                [id]="controlId + '-to'"
                [placeholder]="toPlaceholder"
                [value]="toValue"
                (change)="valueChanged($event, false)"
            />
        } @else {
            <span class="input-group-text rounded-end rounded-start-0">{{ toLabel }}</span>
            <input
                type="number"
                class="form-control"
                [class.is-invalid]="invalid"
                [id]="controlId + '-to'"
                [placeholder]="toPlaceholder"
                [value]="toValue"
                (change)="valueChanged($event, false)"
            />
        }
    </div>
</div>
