import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import { MainResponseModel, OrgUnit, StructuredOrgUnit } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class OrgUnitApiService {
  private _mainService = inject(MainService);

  public getAll() {
    const url = "orgunit/getall";

    return this._mainService
      .sendRequest<MainResponseModel<OrgUnit[]>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getbyEntityidAndObjectype(entityId: number, lookupId: number) {
    const url = `orgunit/getbyEntityidAndObjectype/${entityId}/${lookupId}`;

    return this._mainService
      .sendRequest<MainResponseModel<OrgUnit[]>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getAllByEntity(entityId: number) {
    const url = `orgunit/getbyentityid/${entityId}`;

    return this._mainService
      .sendRequest<MainResponseModel<OrgUnit[]>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public updateOrgUnit(data: Partial<StructuredOrgUnit>) {
    const url = `orgunit/updateCreate`;

    return this._mainService
      .sendRequest<MainResponseModel<OrgUnit[]>>({
        method: "POST",
        url: url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getByEntityIdForProfile(entityId: number) {
    const url = `orgunit/getbyentityidforprofile/${entityId}`;

    return this._mainService
      .sendRequest<MainResponseModel<OrgUnit[]>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public deleteOrgUnit(id: number) {
    const url = `orgunit/deleteById/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<null>>({
        method: "DELETE",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }
}
