import { inject, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: "safeHtml",
  pure: false,
  standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {
  private _domSanitizer = inject(DomSanitizer);

  public transform(html: string): SafeHtml {
    return this._domSanitizer.bypassSecurityTrustHtml(html);
  }
}
