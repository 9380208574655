import { inject, Injectable } from "@angular/core";

import { TpAgmStatus } from "../constants/enums";
import {
  AgmDashboardResponse,
  AgmProject,
  EntityModelType,
  MainResponseModel,
  TpAgmDashboardFilter,
  TpAgmYearResponse,
} from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class AgmProjectApiService {
  private _mainService = inject(MainService);

  public deleteProject(id: number) {
    const url = `annual-meeting-project/deleteById/${id}`;

    return this._mainService.sendRequest<MainResponseModel>({
      method: "DELETE",
      url,
    });
  }

  public getProject(id: number) {
    const url = `annual-meeting-project/getById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<AgmProject>>({
      method: "GET",
      url,
    });
  }

  public getProjectDashboard(id: number) {
    const url = `annual-meeting-project/values/${id}`;

    return this._mainService.sendRequest<MainResponseModel<AgmProject>>({
      method: "GET",
      url,
    });
  }

  public saveProject(data: AgmProject, isFinish: boolean) {
    let url = data.id ? "annual-meeting-project/update" : "annual-meeting-project/create";
    url += `?isFinish=${isFinish}`;

    const method = data.id ? "PUT" : "POST";

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method,
      url,
      data,
    });
  }

  public getAgmDashboard(filter: TpAgmDashboardFilter) {
    const url = `annual-meeting-project/values`;

    const qs = new URLSearchParams();
    if (filter.year) qs.append("year", filter.year.toString());
    if (filter.cycleId) qs.append("cycleId", filter.cycleId.toString());
    if (filter.entityId) qs.append("entityId", filter.entityId.toString());
    if (filter.sectorId) qs.append("nationalSector", filter.sectorId.toString());
    if (filter.mainSectorId) qs.append("mainNationalSector", filter.mainSectorId.toString());
    if (filter.ownerId) filter.ownerId.forEach((e) => qs.append("ownerId", e.toString()));
    if (filter.onlyProjectsOwnedByEntity) qs.append("useOwnerEntity", "true");

    if (filter.status === TpAgmStatus.COMPLETED) {
      qs.append("status", "COMPLETED");
      qs.append("sortKey", "progress");
      qs.append("sortDirection", "desc");
      qs.append("groupedSort", "true");
    } else if (filter.status === TpAgmStatus.ON_TIME) {
      qs.append("status", "ON_GOING");
      qs.append("sortKey", "progress");
      qs.append("sortDirection", "desc");
      qs.append("groupedSort", "true");
    } else if (filter.status === TpAgmStatus.DELAYED) {
      qs.append("status", "DELAYED");
      qs.append("sortKey", "progress");
      qs.append("sortDirection", "desc");
      qs.append("groupedSort", "true");
    } else if (filter.status === TpAgmStatus.MOST_COMPLETED) {
      qs.append("status", "COMPLETED");
      qs.append("status", "ON_GOING");
      qs.append("sortKey", "progress");
      qs.append("sortDirection", "desc");
      qs.append("groupedSort", "false");
    } else if (filter.status === TpAgmStatus.MOST_DELAYED) {
      qs.append("status", "DELAYED");
      qs.append("sortKey", "progress");
      qs.append("sortDirection", "asc");
      qs.append("groupedSort", "false");
    } else if (filter.status === TpAgmStatus.BEST_VISION) {
      qs.append("sortKey", "visionScore");
      qs.append("sortDirection", "desc");
      qs.append("groupedSort", "false");
      qs.append("visionLowLimit", "65.00000001");
      qs.append("visionHighLimit", "100");
    } else if (filter.status === TpAgmStatus.LOWEST_VISION) {
      qs.append("sortKey", "visionScore");
      qs.append("sortDirection", "asc");
      qs.append("groupedSort", "false");
      qs.append("visionLowLimit", "0");
      qs.append("visionHighLimit", "65.00000001");
    }

    return this._mainService.sendRequest<MainResponseModel<AgmDashboardResponse>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getProjectEntities() {
    const url = `annual-meeting-project/entities`;

    return this._mainService.sendRequest<MainResponseModel<EntityModelType[]>>({
      method: "GET",
      url,
    });
  }

  public getProjectYears() {
    const url = `national-project/years`;

    return this._mainService.sendRequest<MainResponseModel<TpAgmYearResponse>>({
      method: "GET",
      url,
    });
  }
}
