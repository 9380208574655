import { inject, Injectable } from "@angular/core";

import {
  EntityModelType,
  GovScope,
  GovScoresPillar,
  KpiDimensionModelType,
  KpiMetricModelType,
  MainResponseModel,
  MonitoredKpiModelType,
  ObjectiveModelType,
  PropTypeModelType,
  ScopeProjectModelType,
} from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class GovScoresScopesApiService {
  private _mainService = inject(MainService);

  public getAvailableLayouts() {
    return this._mainService.sendRequest<MainResponseModel<PropTypeModelType[]>>({
      method: "GET",
      url: "govScoresScopes/getAvailableLayouts",
    });
  }

  public getEntitiesLinkedToGovScoresPillar() {
    return this._mainService.sendRequest<MainResponseModel<EntityModelType[]>>({
      method: "GET",
      url: "govScoresScopes/getEntitiesLinkedToGovScoresPillar",
    });
  }

  public getPillarsByEntityId(entityId: number | undefined = undefined) {
    let url = "govScoresScopes/getPillarsByEntityId";
    if (entityId) url += `?entityId=${entityId}`;

    return this._mainService.sendRequest<MainResponseModel<GovScoresPillar[]>>({
      method: "GET",
      url,
    });
  }

  public getScope(id: number) {
    return this._mainService.sendRequest<MainResponseModel<GovScope>>({
      method: "GET",
      url: `govScoresScopes/getById/${id}`,
    });
  }

  public getObjectiveByEntityId(entityId: number) {
    return this._mainService.sendRequest<MainResponseModel<ObjectiveModelType[]>>({
      method: "GET",
      url: `govScoresScopes/getObjectiveByEntityId/${entityId}`,
    });
  }

  public getKpiByEntityId({
    entityId,
    searchTerm,
    planId,
    kpiType,
    limit = 20,
    ids = [],
  }: {
    entityId: number;
    searchTerm?: string;
    planId?: number;
    kpiType?: number;
    limit?: number;
    ids?: number[];
  }) {
    const qs = new URLSearchParams({
      limit: `${limit}`,
    });

    if (searchTerm) qs.set("text", `${searchTerm}`);
    if (planId) qs.set("planId", `${planId}`);
    if (kpiType) qs.set("kpiType", `${kpiType}`);

    if (ids && ids.length > 0) ids.forEach((e) => qs.append("ids", e.toString()));

    return this._mainService.sendRequest<MainResponseModel<MonitoredKpiModelType[]>>({
      method: "GET",
      url: `govScoresScopes/getKpiByEntityId/${entityId}?${qs}`,
    });
  }

  public getSubKpiByEntityId({
    entityId,
    searchTerm,
    limit = 20,
    ids = [],
  }: {
    entityId: number;
    searchTerm?: string;
    limit?: number;
    ids?: number[];
  }) {
    const qs = new URLSearchParams({
      limit: `${limit}`,
    });

    if (searchTerm) qs.set("searchTerm", `${searchTerm}`);

    if (ids && ids.length > 0) ids.forEach((e) => qs.append("ids", e.toString()));

    return this._mainService.sendRequest<MainResponseModel<MonitoredKpiModelType[]>>({
      method: "GET",
      url: `govScoresScopes/getSubKpiByEntityId/${entityId}?${qs.toString()}`,
    });
  }

  public getMetricByEntityId({
    entityId,
    searchTerm,
    limit = 20,
    ids = [],
  }: {
    entityId: number;
    searchTerm?: string;
    limit?: number;
    ids?: number[];
  }) {
    const qs = new URLSearchParams({
      limit: `${limit}`,
    });

    if (searchTerm) qs.set("searchTerm", `${searchTerm}`);

    if (ids && ids.length > 0) ids.forEach((e) => qs.append("ids", e.toString()));

    return this._mainService.sendRequest<MainResponseModel<KpiMetricModelType[]>>({
      method: "GET",
      url: `govScoresScopes/getMetricByEntityId/${entityId}?${qs.toString()}`,
    });
  }

  public getDimensionByEntityId({
    entityId,
    searchTerm,
    limit = 20,
    ids = [],
  }: {
    entityId: number;
    searchTerm?: string;
    limit?: number;
    ids?: number[];
  }) {
    const qs = new URLSearchParams({
      limit: `${limit}`,
    });

    if (searchTerm) qs.set("searchTerm", `${searchTerm}`);

    if (ids && ids.length > 0) ids.forEach((e) => qs.append("ids", e.toString()));

    return this._mainService.sendRequest<MainResponseModel<KpiDimensionModelType[]>>({
      method: "GET",
      url: `govScoresScopes/getDimensionByEntityId/${entityId}?${qs.toString()}`,
    });
  }

  public getProjectsByEntityId(entityId: number) {
    return this._mainService.sendRequest<MainResponseModel<ScopeProjectModelType[]>>({
      method: "GET",
      url: `govScoresScopes/getProjectsByEntityId/${entityId}`,
    });
  }

  public deleteScope(id: number) {
    const url = `govScoresScopes/deleteById/${id}`;

    return this._mainService.sendRequest<MainResponseModel>({
      method: "DELETE",
      url,
    });
  }

  public getScopesByEntityId(entityId: number) {
    return this._mainService.sendRequest<MainResponseModel<GovScope[]>>({
      method: "GET",
      url: `govScoresScopes/getByEntityId/${entityId}`,
    });
  }

  public saveScope(data: GovScope) {
    const url = data.id ? "govScoresScopes/update" : "govScoresScopes/create";

    return this._mainService.sendRequest<MainResponseModel>({
      method: "POST",
      url,
      data,
    });
  }

  public sortScopes(data: GovScope[]) {
    return this._mainService.sendRequest<MainResponseModel>({
      method: "POST",
      url: "govScoresScopes/sortScopes",
      data,
    });
  }

  public getScopesByPillarId(entityId: number) {
    return this._mainService.sendRequest<MainResponseModel<GovScope[]>>({
      method: "GET",
      url: `govScoresScopes/getByPillarId/${entityId}`,
    });
  }
}
