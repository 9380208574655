<div class="modal-header">
    <h4 class="modal-title">{{ header }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>

<div class="modal-body">
    <div class="position-relative d-flex justify-content-center w-100 mb-3 mt-2">
        <ul
            class="nav nav-pills nav-fill gap-2 small bg-primary shadow rounded-5 ntkpi-card-tabs"
            id="pillNav2"
            role="tablist"
            style="
                --bs-nav-link-color: var(--bs-white);
                --bs-nav-pills-link-active-color: var(--bs-primary);
                --bs-nav-pills-link-active-bg: var(--bs-white);
                --bs-link-hover-color: var(--bs-primary-bg-subtle);
                --bs-nav-link-font-weight: 700;
                --bs-nav-link-font-size: 14px;
            "
        >
            <li class="nav-item" role="presentation" (click)="selectedTab.set(1)">
                <a class="nav-link active rounded-5" data-bs-toggle="tab" type="button" role="tab" aria-selected="true" href="#challenge">
                    {{ "ntkpi_card.challenge" | translate }}
                </a>
            </li>
            <li class="nav-item" role="presentation" (click)="selectedTab.set(2)">
                <a class="nav-link rounded-5" data-bs-toggle="tab" type="button" role="tab" aria-selected="false" href="#successStory">
                    {{ "ntkpi_card.success_story" | translate }}
                </a>
            </li>
        </ul>
    </div>

    @let periods = periodList();
    @if (periods.length > 0) {
        <div class="row d-flex justify-content-center align-items-center px-2">
            <div class="col-md-4 col-sm-6 col-xs-12">
                <adaa-form-dropdown
                    [clearable]="false"
                    [options]="periods"
                    [setDefaultValue]="periodId"
                    [isDisabled]="disableForm()"
                    (inputChanges)="updatePeriodId($event)"
                />
            </div>
        </div>
    }

    <div class="tab-content clearfix mt-2">
        @if (challengeAnalysisForm) {
            <form
                class="tab-pane fade bg-white shadow-md rounded p-2"
                id="challenge"
                [ngClass]="{ 'show active': selectedTab() === 1 }"
                [formGroup]="challengeAnalysisForm"
            >
                <div class="row pb-2">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            formControlName="analysis"
                            controlName="analysis"
                            [isTextArea]="true"
                            [label]="'ntkpi_card.challenge_analysis' | translate"
                            [required]="isRequired(validations.challengeAnalysis(), 'analysis')"
                            [invalid]="challengeAnalysisForm.get('analysis')?.touched! && challengeAnalysisForm.get('analysis')?.invalid!"
                            [adaaInputDisabled]="disableForm()"
                        />
                    </div>

                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            formControlName="rootCause"
                            controlName="rootCause"
                            [isTextArea]="true"
                            [label]="'ntkpi_card.root_cause' | translate"
                            [required]="isRequired(validations.challengeAnalysis(), 'rootCause')"
                            [invalid]="challengeAnalysisForm.get('rootCause')?.touched! && challengeAnalysisForm.get('rootCause')?.invalid!"
                            [adaaInputDisabled]="disableForm()"
                        />
                    </div>
                </div>

                <div class="row pb-2">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            formControlName="initialProposedTreatment"
                            controlName="initialProposedTreatment"
                            [isTextArea]="true"
                            [label]="'ntkpi_card.initial_proposed_assessment' | translate"
                            [required]="isRequired(validations.challengeAnalysis(), 'initialProposedTreatment')"
                            [invalid]="
                                challengeAnalysisForm.get('initialProposedTreatment')?.touched! &&
                                challengeAnalysisForm.get('initialProposedTreatment')?.invalid!
                            "
                            [adaaInputDisabled]="disableForm()"
                        />
                    </div>

                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            formControlName="pmoRequirement"
                            controlName="pmoRequirement"
                            [isTextArea]="true"
                            [label]="'ntkpi_card.pmo_requirement' | translate"
                            [required]="isRequired(validations.challengeAnalysis(), 'pmoRequirement')"
                            [invalid]="challengeAnalysisForm.get('pmoRequirement')?.touched! && challengeAnalysisForm.get('pmoRequirement')?.invalid!"
                            [adaaInputDisabled]="disableForm()"
                        />
                    </div>
                </div>

                <div class="row pb-2">
                    <div class="col-12">
                        <adaa-form-dropdown
                            formControlName="assessment"
                            controlName="assessment"
                            [clearable]="true"
                            [options]="challengeAssessments()"
                            [label]="'ntkpi_card.challenge_assessment' | translate"
                            [required]="isRequired(validations.challengeAnalysis(), 'assessment')"
                            [invalid]="challengeAnalysisForm.get('assessment')?.touched! && challengeAnalysisForm.get('assessment')?.invalid!"
                            [adaaInputDisabled]="disableForm()"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <adaa-form-input-file
                            allowedFilesMessage=""
                            dropFileZoneMessage="ntkpi_card.supporting_evidence"
                            [onDeleteRemoveFile]="false"
                            [allowedFileSize]="26214400"
                            [allowedFiles]="allowedFiles"
                            [file]="evidenceFileChallenge"
                            [isDisabled]="disableForm()"
                            (inputChanges)="evidenceFileChallengeChanged($event)"
                        />
                    </div>
                </div>
            </form>
        }

        @if (successStoryForm) {
            <form
                class="tab-pane fade bg-white shadow-md rounded p-2"
                id="successStory"
                [ngClass]="{ 'show active': selectedTab() === 2 }"
                [formGroup]="successStoryForm"
            >
                <div class="row pb-2">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            formControlName="achievementTitle"
                            controlName="achievementTitle"
                            [label]="'ntkpi_card.achievement_title' | translate"
                            [required]="isRequired(validations.successStory(), 'achievementTitle')"
                            [invalid]="successStoryForm.get('achievementTitle')?.touched! && successStoryForm.get('achievementTitle')?.invalid!"
                            [adaaInputDisabled]="disableForm()"
                        />
                    </div>

                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-dropdown
                            formControlName="leadershipLevel"
                            controlName="leadershipLevel"
                            [clearable]="true"
                            [options]="leadershipLevels()"
                            [label]="'ntkpi_card.leadership_level' | translate"
                            [required]="isRequired(validations.successStory(), 'leadershipLevel')"
                            [invalid]="successStoryForm.get('leadershipLevel')?.touched! && successStoryForm.get('leadershipLevel')?.invalid!"
                            [adaaInputDisabled]="disableForm()"
                        />
                    </div>
                </div>

                <div class="row pb-2">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            formControlName="achievementDsc"
                            controlName="achievementDsc"
                            [isTextArea]="true"
                            [label]="'ntkpi_card.description' | translate"
                            [required]="isRequired(validations.successStory(), 'achievementDsc')"
                            [invalid]="successStoryForm.get('achievementDsc')?.touched! && successStoryForm.get('achievementDsc')?.invalid!"
                            [adaaInputDisabled]="disableForm()"
                        />
                    </div>

                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            formControlName="impactAchievementTarget"
                            controlName="impactAchievementTarget"
                            [isTextArea]="true"
                            [label]="'ntkpi_card.achievement_target' | translate"
                            [required]="isRequired(validations.successStory(), 'impactAchievementTarget')"
                            [invalid]="
                                successStoryForm.get('impactAchievementTarget')?.touched! && successStoryForm.get('impactAchievementTarget')?.invalid!
                            "
                            [adaaInputDisabled]="disableForm()"
                        />
                    </div>
                </div>

                <div class="row pb-2">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            formControlName="impactAchievementSociety"
                            controlName="impactAchievementSociety"
                            [isTextArea]="true"
                            [label]="'ntkpi_card.achievement_society' | translate"
                            [required]="isRequired(validations.successStory(), 'impactAchievementSociety')"
                            [invalid]="
                                successStoryForm.get('impactAchievementSociety')?.touched! &&
                                successStoryForm.get('impactAchievementSociety')?.invalid!
                            "
                            [adaaInputDisabled]="disableForm()"
                        />
                    </div>

                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            formControlName="beneficiaries"
                            controlName="beneficiaries"
                            [isTextArea]="true"
                            [label]="'ntkpi_card.beneficiaries' | translate"
                            [required]="isRequired(validations.successStory(), 'beneficiaries')"
                            [invalid]="successStoryForm.get('beneficiaries')?.touched! && successStoryForm.get('beneficiaries')?.invalid!"
                            [adaaInputDisabled]="disableForm()"
                        />
                    </div>
                </div>

                <div class="row pb-2">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input-date
                            formControlName="projectLaunchDate"
                            controlName="projectLaunchDate"
                            [label]="'ntkpi_card.launch_date' | translate"
                            [required]="isRequired(validations.successStory(), 'projectLaunchDate')"
                            [invalid]="successStoryForm.get('projectLaunchDate')?.touched! && successStoryForm.get('projectLaunchDate')?.invalid!"
                            [adaaInputDisabled]="disableForm()"
                        />
                    </div>

                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-dropdown
                            formControlName="projectContinuity"
                            controlName="projectContinuity"
                            [clearable]="true"
                            [options]="continuityOptions()"
                            [label]="'ntkpi_card.continuity_project' | translate"
                            [required]="isRequired(validations.successStory(), 'projectContinuity')"
                            [invalid]="successStoryForm.get('projectContinuity')?.touched! && successStoryForm.get('projectContinuity')?.invalid!"
                            [adaaInputDisabled]="disableForm()"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <adaa-form-input-file
                            allowedFilesMessage=""
                            dropFileZoneMessage="ntkpi_card.supporting_evidence"
                            [onDeleteRemoveFile]="false"
                            [allowedFileSize]="26214400"
                            [allowedFiles]="allowedFiles"
                            [file]="evidenceFileSuccessStory"
                            [isDisabled]="disableForm()"
                            (inputChanges)="evidenceFileSuccessStoryChanged($event)"
                        />
                    </div>
                </div>
            </form>
        }
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="btn btn-secondary" (click)="modal.close()">
            {{ "common.form.button.close" | translate }}
        </button>

        @if (!disableForm()) {
            <button type="button" class="btn btn-primary" (click)="selectedTab() === 1 ? saveChallenge() : saveSuccessStory()">
                {{ submitButtonContent() }}
            </button>
        }
    </div>
</div>
