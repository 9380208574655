<div
    class="position-relative d-flex flex-column justify-content-between align-items-center card-type"
    (click)="$event.stopPropagation(); router.navigateByUrl('/console/kpi/ekpi?pillar_id=' + data().id)"
>
    <div class="d-flex flex-column align-items-center performance--title">
        <img [src]="logo()" [attr.alt]="data() | translateToken: 'name'" />

        <h5 class="fw-bold">{{ data() | translateToken: "name" }}</h5>
    </div>

    <div class="performance--name d-flex flex-nowrap justify-content-between align-items-center w-100">
        <h4 class="fw-bold mb-0" [style.color]="color()">{{ this.data().performance | percentageValue }}</h4>

        <button [title]="'common.form.label.performance_comparison' | translate" (click)="clickGraphIcon($event)">
            <fa-icon [icon]="graphIcon" size="xl" />
        </button>
    </div>

    <div class="performance--progress">
        <div class="progress w-100" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar progress-bar-striped progress-bar-animated" [style.background-color]="color()" [style.width]="width()"></div>
        </div>
    </div>
</div>
