import { DatePipe } from "@angular/common";
import { Component, computed, inject, input, signal, type TemplateRef, viewChild } from "@angular/core";
import { NgbModal, type NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { filter, forkJoin } from "rxjs";

import { DubaiTimePipe, TranslateTokenPipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../constants/constants";
import {
  AuditTrailDiffModelType,
  KpiAnnualWeightsModelType,
  type WorkflowDiffInputType,
  type WorkflowItemDiffModelType,
} from "../../../../models";
import { LanguageService, WorkflowsApiService } from "../../../../services";
import { AuditTrailApiService } from "../../../../services/audit-trail-api.service";
import {
  AuditDataEntryScreenComponent,
  DataEntryScreenComponent,
  KpiAnnualWeightsScreenComponent,
  KpiScreenComponent,
  MetricActualValueScreenComponent,
  NtkpiCardScreenComponent,
  SimpleScreenComponent,
  TpMilestoneScreenComponent,
  TpScreenComponent,
  TransformationalAgreementsScreenComponent,
  TransformationalTargetsScreenComponent,
} from "./screens";
import { AgmScreenComponent } from "./screens/agm-screen/agm-screen.component";
import { ScreenType } from "./wf-difference.types";
import { isKpiWorkflow } from "./wf-difference.utils";

@Component({
  selector: "adaa-wf-difference",
  standalone: true,
  templateUrl: "./wf-difference.component.html",
  styleUrl: "./wf-difference.component.scss",
  imports: [
    TranslateModule,
    DatePipe,
    TranslateTokenPipe,
    KpiScreenComponent,
    DataEntryScreenComponent,
    AuditDataEntryScreenComponent,
    KpiAnnualWeightsScreenComponent,
    MetricActualValueScreenComponent,
    NtkpiCardScreenComponent,
    SimpleScreenComponent,
    TpMilestoneScreenComponent,
    TpScreenComponent,
    AgmScreenComponent,
    AgmScreenComponent,
    TransformationalAgreementsScreenComponent,
    TransformationalTargetsScreenComponent,
    DubaiTimePipe,
  ],
})
export class WfDifferenceComponent {
  private readonly _modalService = inject(NgbModal);
  private readonly _languageService = inject(LanguageService);
  private readonly _translateService = inject(TranslateService);
  private readonly _workflowsApiService = inject(WorkflowsApiService);
  private readonly _auditTrailApiService = inject(AuditTrailApiService);

  isAuditTrail = input.required<boolean>();
  actionItemTypes = signal<{ id: number; nameAE: string; nameEN: string }[]>([]);
  objectTypes = signal<{ id: number; nameAE: string; nameEN: string }[]>([]);
  displayData = signal<boolean>(true);
  showOnlyNew = signal<boolean>(false);
  isNew = signal<boolean>(false);
  screenType = signal<ScreenType | undefined>(undefined);
  queryInput = signal<WorkflowDiffInputType | { trailId: number } | undefined>(undefined);
  data = signal<WorkflowItemDiffModelType | AuditTrailDiffModelType | KpiAnnualWeightsModelType[] | undefined>(
    undefined
  );
  viewOnlyDifferences = signal<boolean>(false);

  showViewOnlyDiffSwitch = computed(() => {
    const actionTypeId = this.auditDiff()?.actionTypeId;
    const actionDetail = this.auditDiff()?.actionDetail;
    const isUpdateAction = Constants.NOTIFICATION_ACTION_TYPES.UPDATE === actionDetail;
    const isUpdateActionType = Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.UPDATE === actionTypeId;
    const isApprovalActionType = Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.APPROVAL === actionTypeId;
    const isRejectionActionType = Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.REJECTION === actionTypeId;

    if (isUpdateActionType) return true;
    return (isApprovalActionType || isRejectionActionType) && isUpdateAction;
  });
  itemType = computed(() => {
    return (this.data() as WorkflowItemDiffModelType).itemType ?? (this.data() as AuditTrailDiffModelType).objectType;
  });
  kpiAnnualWeights = computed(() => {
    return this.data() as KpiAnnualWeightsModelType[];
  });
  isObjectKpiAnnualWeights = computed(() => {
    if (this._isWorkflowDiffInputType(this.queryInput())) {
      return (
        Object.values(Constants.CONSTANT_KPI_ANNUAL_WEIGHTS).indexOf(
          (this.queryInput() as WorkflowDiffInputType)?.itemTypeId
        ) >= 0
      );
    }
    return false;
  });
  wfProcessCtlId = computed(() => {
    if (this._isWorkflowDiffInputType(this.queryInput())) {
      return (this.queryInput() as WorkflowDiffInputType)?.wfProcessCtlId;
    }
    return undefined;
  });
  newObject = computed(() => {
    if (this.isAuditTrail()) return this.auditDiff()?.newVersion;
    return this.wfDiff()?.newVersion;
  });
  oldObject = computed(() => {
    if (this.isAuditTrail()) return this.auditDiff()?.oldVersion;
    return this.wfDiff()?.oldVersion;
  });
  wfDiff = computed(() => this.data() as WorkflowItemDiffModelType | undefined);
  auditDiff = computed(() => this.data() as AuditTrailDiffModelType | undefined);
  auditActionTypeText = computed(() => {
    const actionTypeId = this.auditDiff()?.actionTypeId;
    return AdaaHelper.getItemValueByToken(
      this.actionItemTypes().find(({ id }) => id === actionTypeId),
      "name"
    );
  });
  auditObjectTypeText = computed(() => {
    const objectType = this.auditDiff()?.objectType;
    const actionType = this.auditDiff()?.actionTypeId;
    if (objectType) {
      return AdaaHelper.getItemValueByToken(
        this.objectTypes().find(({ id }) => id === objectType),
        "name"
      );
    }
    if (actionType) return this._translateService.instant(`audit_actions.${actionType}`);
    return "";
  });

  content = viewChild.required<TemplateRef<unknown>>("content");

  readonly constants = {
    dateFormat: Constants.dateTime,
  };

  modalRef: NgbModalRef;

  public open(input: WorkflowDiffInputType | { trailId: number }) {
    this.queryInput.set(input);
    this._getDiff();

    this.modalRef = this._modalService.open(this.content(), {
      animation: true,
      scrollable: false,
      ariaLabelledBy: this._translateService.instant("workflow.workflow_history"),
      centered: true,
      size: "xl",
      modalDialogClass: `modal-${this._languageService.direction()}`,
      beforeDismiss: () => {
        this.objectTypes.set([]);
        this.actionItemTypes.set([]);
        this.displayData.set(true);
        this.showOnlyNew.set(false);
        this.isNew.set(false);
        this.screenType.set(undefined);
        this.queryInput.set(undefined);
        this.data.set(undefined);
        this.viewOnlyDifferences.set(false);

        return true;
      },
    });

    this.modalRef.result
      .then(() => {
        this.objectTypes.set([]);
        this.actionItemTypes.set([]);
        this.displayData.set(true);
        this.showOnlyNew.set(false);
        this.isNew.set(false);
        this.screenType.set(undefined);
        this.queryInput.set(undefined);
        this.data.set(undefined);
        this.viewOnlyDifferences.set(false);
      })
      .catch(() => {
        this.objectTypes.set([]);
        this.actionItemTypes.set([]);
        this.displayData.set(true);
        this.showOnlyNew.set(false);
        this.isNew.set(false);
        this.screenType.set(undefined);
        this.queryInput.set(undefined);
        this.data.set(undefined);
        this.viewOnlyDifferences.set(false);
      });
  }

  public showWfInfo() {
    const actionTypeId = this.auditDiff()?.actionTypeId;

    switch (actionTypeId) {
      case Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.APPROVAL:
      case Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.REJECTION:
        return true;
      default:
        return false;
    }
  }

  private _setupAuditTrail() {
    const actionTypeId = this.auditDiff()?.actionTypeId;

    switch (actionTypeId) {
      case Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.CREATE:
      case Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.DELETE:
      case Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.UPDATE:
      case Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.APPROVAL:
      case Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.REJECTION:
      case Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.PUBLISH:
        this.displayData.set(true);
        this.showOnlyNew.set(actionTypeId === Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.CREATE);
        if (
          this.isAuditTrail() &&
          (actionTypeId === Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.APPROVAL ||
            actionTypeId === Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.REJECTION) &&
          this.auditDiff()?.actionDetail === Constants.NOTIFICATION_ACTION_TYPES.CREATE
        ) {
          this.showOnlyNew.set(true);
        }
        this.isNew.set(actionTypeId === Constants.CONSTANT_AUDIT_ACTIONS_OPTIONS.CREATE);
        break;
      default:
        this.displayData.set(false);
    }

    this.data.update((value) => {
      let newVersion = (value as AuditTrailDiffModelType).newVersion;
      let oldVersion = (value as AuditTrailDiffModelType).oldVersion;

      if (!AdaaHelper.isDefined(newVersion?.id) && AdaaHelper.isDefined(oldVersion?.id)) {
        newVersion = { ...oldVersion };
      } else if (AdaaHelper.isDefined(newVersion?.id) && !AdaaHelper.isDefined(oldVersion?.id)) {
        oldVersion = { ...newVersion };
      }

      return {
        ...value,
        newVersion,
        oldVersion,
      } as AuditTrailDiffModelType;
    });

    this._selectAuditScreen();
  }

  private _setupWorkflow() {
    const wfActionType = (this.queryInput() as WorkflowDiffInputType).wfActionType;
    const newVersion = this.wfDiff()!.newVersion;
    const oldVersion = this.wfDiff()!.oldVersion;
    const isKpi = AdaaHelper.isDefined(newVersion?.kpiType) || AdaaHelper.isDefined(oldVersion?.kpiType);

    this.showOnlyNew.set(
      wfActionType === Constants.NOTIFICATION_ACTION_TYPES.CREATE ||
        wfActionType === Constants.NOTIFICATION_ACTION_TYPES.DELETE
    );
    this.isNew.set(wfActionType === Constants.NOTIFICATION_ACTION_TYPES.CREATE);

    this._selectWfScreen(isKpi);
  }

  private _selectAuditScreen() {
    const isKpi = isKpiWorkflow(this.auditDiff()!.objectType!);
    const actionType = this.auditDiff()?.actionDetail;
    const objectType = this.auditDiff()?.objectType;

    if (isKpi && actionType === Constants.NOTIFICATION_ACTION_TYPES.DATA_ENTRY) {
      this.screenType.set("data-entry");
      return;
    } else if (isKpi && actionType === Constants.NOTIFICATION_ACTION_TYPES.AUDIT_DATA_ENTRY) {
      this.screenType.set("audit-data-entry");
      return;
    } else if (isKpi) {
      this.screenType.set("kpi");
      return;
    } else {
      switch (objectType) {
        case Constants.CONSTANT_NTKPI_CHALLENGE_ANALYSIS:
        case Constants.CONSTANT_NTKPI_SUCCESS_STORY:
          this.screenType.set("ntkpi-card");
          break;
        case Constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE:
          this.screenType.set("tp-milestone");
          break;
        case Constants.CONSTANT_NATIONAL_PROJECTS:
          this.screenType.set("tp");
          break;
        case Constants.CONSTANT_TRANSFORMATIONAL_TARGETS:
          this.screenType.set("transformational-targets");
          break;
        case Constants.CONSTANT_TRANSFORMATIONAL_AGREEMENT:
          this.screenType.set("transformational-agreements");
          break;
        case Constants.CONSTANT_WORKFLOW.ANNUAL_PROJECT_PROJECTS:
        case Constants.CONSTANT_WORKFLOW.ANNUAL_PROJECT_MILESTONES:
          this.screenType.set("agm");
          break;
        default:
          this.screenType.set("simple");
          break;
      }
    }
  }

  private _selectWfScreen(isKpi: boolean = true) {
    const { wfActionType, itemTypeId } = this.queryInput() as WorkflowDiffInputType;

    if (isKpi && wfActionType === Constants.NOTIFICATION_ACTION_TYPES.DATA_ENTRY) {
      this.screenType.set("data-entry");
    } else if (isKpi && wfActionType === Constants.NOTIFICATION_ACTION_TYPES.AUDIT_DATA_ENTRY) {
      this.screenType.set("audit-data-entry");
    } else if (isKpi) {
      this.screenType.set("kpi");
      return;
    } else {
      switch (itemTypeId) {
        case Constants.CONSTANT_WORKFLOW.PROJECT_MILESTONES:
          this.screenType.set("tp-milestone");
          break;
        case Constants.CONSTANT_WORKFLOW.NATIONAL_PROJECTS:
          this.screenType.set("tp");
          break;
        case Constants.CONSTANT_WORKFLOW.ANNUAL_PROJECT_PROJECTS:
        case Constants.CONSTANT_WORKFLOW.ANNUAL_PROJECT_MILESTONES:
          this.screenType.set("agm");
          break;
        case Constants.CONSTANT_WORKFLOW.METRIC_ACTUALS:
          this.screenType.set("metric-actual-value");
          break;
        case Constants.CONSTANT_WORKFLOW.OBJECTIVE:
        case Constants.CONSTANT_WORKFLOW.ACTIVITY:
        case Constants.CONSTANT_WORKFLOW.INITIATIVE:
        case Constants.CONSTANT_WORKFLOW.SERVICE:
        default:
          this.screenType.set("simple");
          break;
      }
    }

    type KpiAnnualWeightConstantTypes = keyof typeof Constants.CONSTANT_KPI_ANNUAL_WEIGHTS;
    const useKpiAnnualWeightsScreen = Object.keys(Constants.CONSTANT_KPI_ANNUAL_WEIGHTS).some(
      (key) => Constants.CONSTANT_KPI_ANNUAL_WEIGHTS[key as KpiAnnualWeightConstantTypes] === itemTypeId
    );

    if (useKpiAnnualWeightsScreen) {
      this.screenType.set("kpi-annual-weights");
    }
  }

  private _getDiff() {
    if (this.isAuditTrail()) {
      const input = this.queryInput() as { trailId: number };
      this._getAuditItemTypes();

      return this._auditTrailApiService
        .getDifferences(input.trailId)
        .pipe(filter((res) => !res.inError))
        .subscribe({
          next: (res) => this.data.update(() => res.responseData),
          complete: () => {
            this._setupAuditTrail();
          },
        });
    }
    if (this.isObjectKpiAnnualWeights()) {
      const input = this.queryInput() as WorkflowDiffInputType;
      if (input.wfProcessCtlId)
        return this._workflowsApiService
          .getKpiAnnualWeightsDifferences(input.wfProcessCtlId)
          .pipe(filter((res) => !res.inError))
          .subscribe({
            next: (res) => {
              this.data.set(res.responseData.weights);
            },
            complete: () => {
              this._setupWorkflow();
            },
          });
    }

    const input = this.queryInput() as WorkflowDiffInputType;
    return this._workflowsApiService
      .getWorkflowDifferences(input)
      .pipe(filter((res) => !res.inError))
      .subscribe({
        next: (res) => {
          this.data.set(res.responseData);
        },
        complete: () => {
          this._setupWorkflow();
        },
      });
  }

  private _getAuditItemTypes() {
    const request$ = [this._auditTrailApiService.getActionTypes(), this._auditTrailApiService.getObjectTypes()];

    forkJoin(request$).subscribe({
      next: ([actionTypesRes, objectTypesRes]) => {
        this.actionItemTypes.set([...actionTypesRes.responseData]);
        this.objectTypes.set([...objectTypesRes.responseData]);
      },
    });
  }

  private _isWorkflowDiffInputType(
    checkObj: WorkflowDiffInputType | { trailId: number } | undefined
  ): checkObj is WorkflowDiffInputType {
    const optionalObj = checkObj as WorkflowDiffInputType;
    return this._isObject(optionalObj) && optionalObj.itemId !== undefined && optionalObj.itemTypeId !== undefined;
  }

  private _isObject(obj: unknown) {
    return obj !== null && typeof obj === "object";
  }
}
