<div class="d-flex flex-wrap">
    <ng-template #popTitle let-project="project">
        <span class="fw-bold">
            {{ project | translateToken: "name" }}
        </span>
    </ng-template>

    <ng-template #popContent let-project="project">
        <div class="row">
            <div class="col-12 mb-2 text-primary">
                <div class="row d-flex align-content-center">
                    <div class="col-3">
                        <adaa-picture [pictureId]="project.ownerProfilePictureId" [width]="'50px'"></adaa-picture>
                    </div>
                    <div class="col-9">
                        {{ project | translateToken: "entityName" }}
                    </div>
                </div>
            </div>
            <div class="col-12">
                <adaa-progress-indicator
                    [label]="('national_projects.project_progress' | translate) + ': '"
                    [value]="project.progress"
                    [color]="project.onTimeProgressColor"
                ></adaa-progress-indicator>
            </div>
            @if (showTargets()) {
                <div class="col-12 mb-2">
                    <adaa-progress-indicator
                        [label]="('national_projects.target_achieved' | translate) + ': '"
                        [value]="project.visionScore"
                        [color]="project.visionScoreColor"
                    ></adaa-progress-indicator>
                </div>
            }
            <div class="col-12">
                <span>
                    {{ "common.form.label.status" | translate }}:
                    <strong [class]="'fc-' + project.projectStatusId">{{ project | translateToken: "projectStatus" }}</strong>
                </span>
            </div>
        </div>
    </ng-template>

    @for (item of projects(); track $index) {
        <div
            class="vertical-bar"
            [style.background-color]="constants.COLOR_CODES[item.projectStatusId]"
            [class.hovered]="tooltipIndex === item.id"
            [ngbPopover]="popContent"
            [popoverTitle]="popTitle"
            triggers="manual"
            #bar="ngbPopover"
            (mouseover)="onBarHover(bar, item)"
            (mouseleave)="onBarLeave(bar)"
            (click)="barClicked.emit(item.id)"
        ></div>
    }
</div>
