import { NgClass } from "@angular/common";
import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { StructureViewColumnConfig } from "../../../models";
import { NestedTableRowComponent } from "./nested-table-row/nested-table-row.component";

@Component({
  selector: "adaa-nested-table",
  standalone: true,
  imports: [TranslateModule, NgClass, NestedTableRowComponent],
  templateUrl: "./nested-table.component.html",
  styleUrl: "./nested-table.component.scss",
})
export class NestedTableComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data = input.required<any[]>();
  columns = input.required<StructureViewColumnConfig[]>();
  periodId = input.required<number>();
  useGovScoresConfig = input<boolean>(false);
}
