@if (!isDisabled()) {
    @if (!onlySystemUsers()) {
        <div class="row mb-1">
            <div class="col-12 text-center">
                <h5 class="fw-bold">{{ "national_projects.project_team_members.add_member" | translate }}</h5>
            </div>

            <div class="col-12 d-flex justify-content-center flex-wrap align-content-center" (click)="addNewClicked($event, true)">
                <button class="btn btn-primary mx-1">
                    {{ "national_projects.project_team_members.member_type.add_system_user" | translate }}
                </button>
                <button class="btn btn-primary mx-1" (click)="addNewClicked($event, false)">
                    {{ "national_projects.project_team_members.member_type.add_non_system_user" | translate }}
                </button>
            </div>
        </div>
    }
}

<adaa-data-table
    [titleHeader]="tableTitle()"
    [configKey]="tableConf()"
    [enableExport]="false"
    [enableAdd]="onlySystemUsers()"
    [enableEditAction]="false"
    [enableViewAction]="false"
    [enableDeleteAction]="!isDisabled()"
    [isLocalData]="true"
    [localData]="teamMembersData"
    (addNewClicked)="addNewClicked(undefined, true)"
    (actionClicked)="actionClicked($event)"
></adaa-data-table>
