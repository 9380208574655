import { Component, computed, inject, input, type OnInit, signal } from "@angular/core";
import { RouterLink } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs";

import type { AdaaBooleanType } from "../../../../../../../../adaa-types";
import { TranslateTokenPipe } from "../../../../../../../core/pipes";
import { AdaaHelper } from "../../../../../../../core/utils";
import { Constants } from "../../../../../../constants/constants";
import { AdaaBoolean } from "../../../../../../constants/enums";
import { MetricsDetailModelType, PropTypeModelType } from "../../../../../../models";
import { LanguageService, PropertiesService } from "../../../../../../services";
import { FormulaViewComponent, NoFormulaViewComponent, TargetsFormulaViewComponent } from "../../_lib";

@Component({
  selector: "adaa-data-entry-screen",
  standalone: true,
  imports: [
    TranslateModule,
    TranslateTokenPipe,
    RouterLink,
    NoFormulaViewComponent,
    FormulaViewComponent,
    TargetsFormulaViewComponent,
  ],
  templateUrl: "./data-entry-screen.component.html",
  styleUrl: "./data-entry-screen.component.scss",
})
export class DataEntryScreenComponent implements OnInit {
  readonly languageService = inject(LanguageService);
  private _propertiesService = inject(PropertiesService);
  private _translateService = inject(TranslateService);

  showOnlyNew = input.required<boolean>();
  newDataentryObject = input<Record<string, never>>();
  oldDataentryObject = input<Record<string, never>>();
  viewOnlyDifferences = input<boolean>(false);

  months = signal<PropTypeModelType[]>([]);
  trends = signal<PropTypeModelType[]>([]);
  ytpCalcs = signal<PropTypeModelType[]>([]);
  frequencies = signal<PropTypeModelType[]>([]);
  measurementUnits = signal<PropTypeModelType[]>([]);
  title = signal<string>("");

  isBounded = computed(() => this.newDataentryObject()?.trend === Constants.CONSTANT_TREND_BOUNDED);
  isBaselineKpi = computed(() => this.newDataentryObject()?.baselineKpi === AdaaBoolean.Y);
  isNoFormula = computed(() => {
    const metricEntities = this.newDataentryObject()?.metricEntities;
    const dataEntryRequests = this.newDataentryObject()?.dataEntryRequests;
    const formula = this.newDataentryObject()?.formula;

    return !metricEntities && !dataEntryRequests && formula === Constants.FORMULA_STRING.NOFORMULA;
  });
  isFormula = computed(() => {
    const dataEntryRequests = this.newDataentryObject()?.dataEntryRequests ?? [];

    return !this.isNoFormula() && dataEntryRequests.length > 0;
  });
  hasDetailedTargets = computed(() => {
    const hasDetailedTargets = this.newDataentryObject()?.hasTarget === AdaaBoolean.Y;
    return hasDetailedTargets;
  });
  metricDetails = computed(() => {
    const metricDetails = this.newDataentryObject()?.metricDetails;
    return metricDetails as unknown as MetricsDetailModelType[];
  });

  readonly isDefinedAndNotEmpty = AdaaHelper.isDefinedAndNotEmpty.bind(AdaaHelper);

  public ngOnInit() {
    this._fetchConstants();
  }

  public getKpitypeName(kpiType: number) {
    return {
      [Constants.CONSTANT_NKPITYPE]: "nkpi",
      [Constants.CONSTANT_SKPITYPE]: "skpi",
      [Constants.CONSTANT_MOKPITYPE]: "mokpi",
      [Constants.CONSTANT_MTKPITYPE]: "mtkpi",
      [Constants.CONSTANT_NTKPITYPE]: "ntkpi",
      [Constants.CONSTANT_DTKPITYPE]: "dtkpi",
      [Constants.CONSTANT_OPM]: "opm",
      [Constants.CONSTANT_UAEKPI]: "uae",
      [Constants.CONSTANT_EKPI]: "ekpi",
      [Constants.CONSTANT_SRVKPI]: "srvkpi",
    }[kpiType];
  }

  public getFormula(formula: string) {
    if (formula === Constants.FORMULA_STRING.NOFORMULA) return "skpi.no_formula";
    return formula;
  }

  public formatAdaaBoolean(data: AdaaBooleanType) {
    if (data === AdaaBoolean.N) return "common.form.label.no";
    if (data === AdaaBoolean.Y) return "common.form.label.yes";
    return "common.form.label.n_a";
  }

  public formatFromConstants(key: "measurement" | "freq" | "ytp" | "trend" | "month", id: number) {
    switch (key) {
      case "freq": {
        const freq = this.frequencies().find((e) => e.id === id);
        return freq ? AdaaHelper.getItemValueByToken(freq, "name") : "";
      }

      case "measurement": {
        const measurement = this.measurementUnits().find((e) => e.id === id);
        return measurement ? AdaaHelper.getItemValueByToken(measurement, "name") : "";
      }

      case "ytp": {
        const ytp = this.ytpCalcs().find((e) => e.id === id);
        return ytp ? AdaaHelper.getItemValueByToken(ytp, "name") : "";
      }

      case "trend": {
        const trend = this.trends().find((e) => e.id === id);
        return trend ? AdaaHelper.getItemValueByToken(trend, "name") : "";
      }

      case "month": {
        const month = this.months().find((e) => e.id === id);
        return month ? AdaaHelper.getItemValueByToken(month, "name") : "";
      }
    }
  }

  private _fetchConstants() {
    const constantIds = [
      Constants.CONSTANT_MEASUREMENT_UNIT,
      Constants.CONSTANT_FREQUENCY,
      Constants.CONSTANT_TREND,
      Constants.CONSTANT_YTP,
      Constants.CONSTANT_MONTHS,
    ];

    this._propertiesService
      .getPropByIdList(constantIds)
      .pipe(filter((res) => !res.inError))
      .subscribe({
        next: (res) => {
          this.ytpCalcs.set(res.responseData.filter(({ propType }) => propType === Constants.CONSTANT_YTP));
          this.trends.set(res.responseData.filter(({ propType }) => propType === Constants.CONSTANT_TREND));
          this.months.set(res.responseData.filter(({ propType }) => propType === Constants.CONSTANT_MONTHS));
          this.frequencies.set(res.responseData.filter(({ propType }) => propType === Constants.CONSTANT_FREQUENCY));
          this.measurementUnits.set(
            res.responseData.filter(({ propType }) => propType === Constants.CONSTANT_MEASUREMENT_UNIT)
          );
        },
      });
  }

  public getInfo(metricType: string) {
    this.title.set(
      this._translateService.instant(metricType === Constants.METRICS_TYPE.LOCAL ? "metrics.local" : "metrics.global")
    );
  }
}
