import { Component, inject, signal, type TemplateRef, viewChild } from "@angular/core";
import { NgbModal, type NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs";

import { Constants } from "../../../../constants/constants";
import type { ExtendedUserModelType } from "../../../../models";
import { LanguageService, WorkflowsApiService } from "../../../../services";
import { DataTableComponent } from "../../../table/data-table/data-table.component";

@Component({
  selector: "adaa-wf-pending-approvers",
  standalone: true,
  imports: [TranslateModule, DataTableComponent],
  templateUrl: "./wf-pending-approvers.component.html",
  styleUrl: "./wf-pending-approvers.component.scss",
})
export class WfPendingApproversComponent {
  private readonly _workflowsApiService = inject(WorkflowsApiService);
  private readonly _translateService = inject(TranslateService);
  private readonly _languageService = inject(LanguageService);
  private readonly _modalService = inject(NgbModal);

  content = viewChild.required<TemplateRef<unknown>>("content");

  tableData = signal<ExtendedUserModelType[]>([]);

  readonly tableConfList = Constants.MAIN_TABLE_LIST_CONF_KEY.WORKFLOW_MONITORING_PENDING_USER_LIST;

  modalRef: NgbModalRef;

  public open(wfProcessctlId: number) {
    this.modalRef = this._modalService.open(this.content(), {
      animation: true,
      scrollable: false,
      ariaLabelledBy: this._translateService.instant("workflow.pending_action_users"),
      size: "xl",
      centered: true,
      modalDialogClass: `modal-${this._languageService.direction()}`,
    });

    this._getApprovers(wfProcessctlId);
  }

  private _getApprovers(id: number) {
    this._workflowsApiService
      .getAllWorkflowPendingApprovers(id)
      .pipe(filter((res) => !res.inError))
      .subscribe({
        next: (res) => {
          this.tableData.set(res.responseData ?? []);
        },
      });
  }
}
