import { Component, effect, inject, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { Constants } from "../../../constants/constants";
import type { GovEnablerDirectionsModelType } from "../../../models";
import { FilesApiService } from "../../../services";
import { ProgressIndicatorComponent } from "../../general";

@Component({
  selector: "adaa-national-strategies-perf-card",
  standalone: true,
  templateUrl: "./national-strategies-perf-card.component.html",
  styleUrl: "./national-strategies-perf-card.component.scss",
  imports: [TranslateTokenPipe, TranslateModule, ProgressIndicatorComponent],
})
export class NationalStrategiesPerfCardComponent {
  private readonly _fileService = inject(FilesApiService);

  data = input.required<GovEnablerDirectionsModelType>();

  logo = signal<string | undefined>(undefined);

  constructor() {
    effect(() => {
      this._downloadFile();
    });
  }

  private _downloadFile() {
    if (this.data()?.entityLogo) {
      this._fileService.download(this.data().entityLogo).subscribe({
        next: async (blob) => {
          const dataUrl = window.URL.createObjectURL(blob);
          this.logo.update(() => dataUrl);
        },
      });
    }
  }

  public getColor(code: number) {
    if (code === 33) code = Constants.CONSTANT_COLORS.EVALUATION_GOLD;
    return Constants.COLOR_CODES[code];
  }
}
