import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import type { AttributeModelType, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class AttributesApiService {
  private readonly _mainService = inject(MainService);

  public getByItemTypeAvailable({ id, type }: { type: number; id?: number }) {
    const url = `libextattr/getByItemTypeAvailable/${type}?${id ? `id=${id}` : ""}`;

    return this._mainService
      .sendRequest<MainResponseModel<AttributeModelType[]>>({
        url,
        method: "GET",
      })
      .pipe(filter((res) => !res.inError));
  }

  public getByItemIdItemType({ id, type, periodId }: { id: number; type: number; periodId?: number }) {
    const url = `attr/getByItemIdItemType/${id}/${type}?${periodId ? `periodId=${periodId}` : ""}`;

    return this._mainService
      .sendRequest<MainResponseModel<AttributeModelType[]>>({
        url,
        method: "GET",
      })
      .pipe(filter((res) => !res.inError));
  }

  public createAttribute(data: AttributeModelType) {
    const url = `libextattr/create?isFinish=true`;

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "POST",
        url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getCountByType(data: number[]) {
    const url = `item/getCountByType`;

    return this._mainService
      .sendRequest<MainResponseModel<number | string>>({
        method: "POST",
        url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getAttribute(id: number) {
    const url = `libextattr/getbyid/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<AttributeModelType>>({
        method: "GET",
        url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public updateAttribute(data: AttributeModelType) {
    const url = `libextattr/update?isFinish=true`;

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "POST",
        url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public deleteAttribute(id: number) {
    const url = `libextattr/deleteById/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "DELETE",
        url,
      })
      .pipe(filter((res) => !res.inError));
  }
}
