import { Component, EventEmitter, inject, input, OnInit, Output, signal, viewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as fileSaver from "file-saver";
import moment from "moment";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { PageMode } from "../../../constants/enums";
import {
  AttachmentModelType,
  CustomButton,
  ObjectStatus,
  TableButtonClicked,
  ValidatorModelType,
  ValueText,
} from "../../../models";
import { FilesApiService, LanguageService, PropertiesService, ValidatorApiService } from "../../../services";
import { ConfirmationModalComponent } from "../../general";
import { DataTableComponent } from "../../table/data-table/data-table.component";
import { AttachmentsModalComponent } from "../attachments-modal/attachments-modal.component";

@Component({
  selector: "adaa-attachments-list",
  standalone: true,
  imports: [DataTableComponent],
  templateUrl: "./attachments-list.component.html",
  styleUrl: "./attachments-list.component.scss",
})
export class AttachmentsListComponent implements OnInit {
  private _modalService = inject(NgbModal);
  private _languageService = inject(LanguageService);
  private _validatorApiService = inject(ValidatorApiService);
  private _propertiesService = inject(PropertiesService);
  private readonly _filesApiService = inject(FilesApiService);

  attachments = input.required<AttachmentModelType[]>();
  title = input<string>("kpi.attachments");
  tableConf = input<string>(Constants.MAIN_TABLE_LIST_CONF_KEY.ATTACHMENT_GENERAL);
  isDisabled = input<boolean>(false);
  enableDate = input<boolean>(true);
  enableType = input<boolean>(true);
  showSubmit = input<boolean>(true);
  replaceViewWithDownload = input<boolean>(false);

  dataTable = viewChild.required<DataTableComponent>("dataTable");

  @Output() attachmentsChanged = new EventEmitter<AttachmentModelType[]>();

  constants = Constants;
  customButtons: CustomButton[] = [
    {
      iconName: "adaa-icon-download",
      eventName: "download",
      iconTitle: "tooltips.download",
      onlyIf: () => this.replaceViewWithDownload(),
    },
  ];

  isDirty = signal<boolean>(false);
  attachmentTypes = signal<ValueText[]>([]);
  formValidation = signal<ValidatorModelType>({
    id: 0,
    status: 0,
    searchKey: "",
    type: 0,
    parameterCatalogs: [],
    nameAE: "",
    nameEN: "",
  });

  public get attachmentsData(): AttachmentModelType[] {
    return this.attachments().filter((e) => e.status != Constants.OBJECT_STATUS.REMOVE) ?? [];
  }

  public ngOnInit(): void {
    forkJoin({
      validation: this._validatorApiService.searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_ATTACHMENTS),
      attachmentTypes: this._propertiesService.getPropById(Constants.CONSTANT_ATTACHTYPE),
    }).subscribe({
      next: (results) => {
        this.formValidation.set(results.validation.responseData);
        this.attachmentTypes.set(
          AdaaHelper.setDropdownArray(results.attachmentTypes.responseData, "id", AdaaHelper.getFieldLanguage("name"))
        );
      },
    });
  }

  public addNewClicked(): void {
    const attachment: AttachmentModelType = {
      id: 0,
      nameEN: "",
      nameAE: "",
      filename: "",
      attachFile: 0,
      attachDate: moment().utcOffset(Constants.uaeTimezone).valueOf(),
      auxAttachFile: { id: 0, filename: "" },
      status: Constants.OBJECT_STATUS.ACTIVE,
      fakeId: Math.floor(Math.random() * 217 * 1000),
    };

    this._openAttachmentModal(attachment, -1, PageMode.create);
  }

  public actionClicked(action: TableButtonClicked): void {
    switch (action.event) {
      case "delete":
        this._deleteAttachment(action.data);
        break;
      case "view":
        this._openAttachmentModal(action.data, Number(action.index), PageMode.view);
        break;
      case "edit":
        this._openAttachmentModal(action.data, Number(action.index), PageMode.edit);
        break;
      case "download":
        this._download(action.data);
        break;
      default:
        break;
    }
  }

  private _download(data: Record<string, unknown>) {
    data.downloading = true;
    this._filesApiService.download(data.id as number, true).subscribe({
      next: (blob) => {
        fileSaver.saveAs(blob, data.filename as string);
      },
      error: () => {
        data.downloading = false;
      },
      complete: () => {
        data.downloading = false;
      },
    });
  }

  private _deleteAttachment(data: AttachmentModelType): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = "directions.attachments";
    modal.componentInstance.title = "directions.attachmentsRemove";

    modal.result.then((e) => {
      if (e) this._deleteAttachmentConfirmed(data);
    });
  }

  private _deleteAttachmentConfirmed(data: AttachmentModelType): void {
    const attachments = [...this.attachments()];
    if (data.fakeId) {
      this.attachmentsChanged.emit(attachments.filter((e) => e.fakeId !== data.fakeId));
    } else {
      const attachment = attachments.find((e) => e.id === data.id);
      if (attachment) attachment.status = Constants.OBJECT_STATUS.REMOVE;
      this.attachmentsChanged.emit(attachments);
    }
    this.dataTable().loadTableData();
    this.isDirty.set(true);
  }

  private _openAttachmentModal(attachment: AttachmentModelType, index: number, pageMode: PageMode): void {
    const modal = this._modalService.open(AttachmentsModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.attachment = attachment;
    modal.componentInstance.pageMode = pageMode;
    modal.componentInstance.formValidation = this.formValidation();
    modal.componentInstance.attachmentTypes = this.attachmentTypes();
    modal.componentInstance.enableDate = this.enableDate();
    modal.componentInstance.enableType = this.enableType();
    modal.componentInstance.showSubmit = this.showSubmit();

    modal.result.then((e) => {
      if (!e) return;
      const attachments = [...this.attachments()].filter(({ status }) => status !== ObjectStatus.REMOVE);
      if (!attachments.length) {
        this.attachmentsChanged.emit(this.attachments());
        this.dataTable().loadTableData();
      }

      if (pageMode === PageMode.create) {
        attachments.push(e);
      } else {
        if (e.id === attachments[index].id) {
          attachments[index] = { ...e };
        } else if (e.fakeId === attachments[index].fakeId) {
          attachments[index] = { ...e };
        } else {
          attachments[index].status = Constants.OBJECT_STATUS.REMOVE;
          attachments.push(e);
        }
      }

      this.attachmentsChanged.emit([
        ...attachments,
        ...[...this.attachments()].filter(({ status }) => status === ObjectStatus.REMOVE),
      ]);
      this.dataTable().loadTableData();
      this.isDirty.set(true);
    });
  }
}
