import { Component, computed, inject, input, signal, type TemplateRef } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { BaseChartDirective } from "ng2-charts";
import { filter, switchMap, tap } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import type {
  EntityPerformanceModelType,
  GetPopupGraphArgs,
  GovEnablerPillarModelType,
  SelectedPeriodModelType,
  ValueText,
} from "../../../models";
import { ChartsService, GovEnablerPerfService, GraphApiService } from "../../../services";
import { ChartActionButtonsComponent } from "../../chart-action-buttons/chart-action-buttons.component";
import { DoughnutComponent } from "../../charts";
import { EntityTypeSizeFormComponent } from "../../general";
import { GovEnablerPerfCardComponent } from "../gov-enabler-perf-card/gov-enabler-perf-card.component";
import { ItemSliderComponent } from "../item-slider/item-slider.component";

@Component({
  selector: "adaa-gov-enabler-performance",
  standalone: true,
  templateUrl: "./gov-enabler-performance.component.html",
  styleUrl: "./gov-enabler-performance.component.scss",
  imports: [
    DoughnutComponent,
    TranslateModule,
    FontAwesomeModule,
    GovEnablerPerfCardComponent,
    ItemSliderComponent,
    EntityTypeSizeFormComponent,
    BaseChartDirective,
    ChartActionButtonsComponent,
  ],
})
export class GovEnablerPerformanceComponent {
  private readonly _modalService = inject(NgbModal);
  private readonly _graphApiService = inject(GraphApiService);
  private readonly _govEnablerPerfService = inject(GovEnablerPerfService);
  private readonly _translateService = inject(TranslateService);
  readonly chartService = inject(ChartsService);

  selectedPeriod = input<SelectedPeriodModelType>();

  entityTypes = signal<ValueText[]>([]);
  entitySizes = signal<ValueText[]>([]);
  entitiesPerformance = signal<EntityPerformanceModelType[]>([]);
  entityInfo = signal<{ entitySize?: number; entityType?: number }>({});
  averagePerformance = signal<number | undefined>(undefined);
  selectedGovEnablerPerf = signal<GovEnablerPillarModelType | undefined>(undefined);

  pillars = computed(() => this._govEnablerPerfService.pillars());
  overallPillar = computed(() => this._govEnablerPerfService.overallPillar());
  performanceChartLabels = computed(() =>
    this.entitiesPerformance().map((data) => {
      const label = AdaaHelper.getItemValueByToken(data, "entityName");
      if (!label) return "";
      return label;
    })
  );

  performanceChartDataset = computed(() => {
    const barChartData = this.entitiesPerformance().map((data) => data.score ?? 0);

    const barChartColors = this.entitiesPerformance().map((data) => {
      const label = AdaaHelper.getItemValueByToken(data, "entityName");
      if (!label) {
        return this.getColor(Constants.CONSTANT_COLORS.EVALUATION_GRAY);
      }
      return this.getColor(data.scoreColor);
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const datasets: any = [
      {
        data: barChartData,
        backgroundColor: barChartColors,
        order: 1,
        tension: 0.5,
      },
    ];

    if (this.averagePerformance()) {
      const lineChartData = this.entitiesPerformance().map(() => this.averagePerformance() || 0);
      const lineChartColor = this.getColor(Constants.CONSTANT_COLORS.EVALUATION_LIGHTBLUE);

      datasets.push({
        order: 0,
        tension: 0.75,
        type: "line",
        fill: false,
        showLine: true,
        data: lineChartData,
        borderColor: lineChartColor,
        pointStyle: "line",
      });
    }

    return datasets;
  });

  public get icon() {
    return {
      leftArrow: faArrowLeftLong,
      rightArrow: faArrowRightLong,
    };
  }

  public get chartTitle() {
    const isAnnual = this.selectedPeriod()?.type === "year";
    const year = this.selectedPeriod()?.period?.year;
    const quarter = this.selectedPeriod()?.period?.quarter;
    const label = this.selectedGovEnablerPerf()
      ? AdaaHelper.getItemValueByToken(this.selectedGovEnablerPerf(), "name")
      : this._translateService.instant("landingPage.entity_page.government_enabler_performance");

    return `${isAnnual ? year : `Q${quarter} ${year}`} ${label}`;
  }

  public getColor(code: number) {
    return Constants.COLOR_CODES[code];
  }

  public updateEntityInfo(type: "entitySize" | "entityType", data: number) {
    this.entityInfo.update((info) => ({ ...info, [type]: data }));

    if (!this.selectedGovEnablerPerf()) {
      this._fetchPerformanceData(false, this.overallPillar()!.govEnablerAggregationType);
    } else {
      this._fetchPerformanceData(true, this.selectedGovEnablerPerf()!.id);
    }
  }

  public openPerformanceGraph(content: TemplateRef<unknown>) {
    this._modalService.open(content, {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      fullscreen: false,
      ariaLabelledBy: this.chartTitle,
    });

    if (!this.selectedGovEnablerPerf()) {
      this._fetchPerformanceData(false, this.overallPillar()!.govEnablerAggregationType);
    } else {
      this._fetchPerformanceData(true, this.selectedGovEnablerPerf()!.id);
    }
  }

  private _fetchPerformanceData(isEnabler: boolean, id: number) {
    let periodId: number;
    if (this.selectedPeriod()?.type === "year") {
      periodId = this.selectedPeriod()?.period.year as number;
    } else {
      periodId = this.selectedPeriod()?.period.id as number;
    }

    const { entityType, entitySize } = this.entityInfo();

    const params = {
      id,
      entitySize,
      entityType,
      periodId,
      isEnabler,
      isAnnual: this.selectedPeriod()?.type === "year",
      isGov: true,
    };

    this._graphApiService
      .getPerformanceGraphItems(params)
      .pipe(
        tap((res) => {
          if (res.inError) return;
          this.entitiesPerformance.update(() => res.responseData);
        }),
        switchMap(() => this._getAveragePerformance(params)),
        filter((res) => AdaaHelper.isDefined(res)),
        tap((res) => {
          if (res.inError) return;
          this.averagePerformance.update(() => res.responseData.score);
        })
      )
      .subscribe();
  }

  private _getAveragePerformance(params: GetPopupGraphArgs) {
    if (params.isEnabler) params.isGov = false;
    this._updateChartOptions();
    return this.selectedPeriod()?.type === "quarter"
      ? this._graphApiService.getPerformanceGraphData(params)
      : this._graphApiService.getAveragePerformanceData(params.id, params.periodId, {
          entitySize: params.entitySize,
          entityType: params.entityType,
        });
  }

  private _updateChartOptions() {
    this.chartService.initEntityComparisonBarChartOptions({
      title: this.chartTitle,
      subtitle: this._getSubTitle(),
      usePerformance: true,
      showActual: false,
    });
  }

  private _getSubTitle(): string {
    let subTitle = "";
    const { entityType, entitySize } = this.entityInfo();

    if (entityType) subTitle = this.entityTypes().find((e) => e.value === entityType)!.text;
    if (entitySize) {
      subTitle += AdaaHelper.isDefinedAndNotEmpty(subTitle) ? " - " : "";
      subTitle += this.entitySizes().find((e) => e.value === entitySize)!.text;
    }

    return subTitle;
  }
}
