import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../constants/constants";

export const isService = (itemType: number) => itemType === Constants.CONSTANT_SERVICE;
export const isObjective = (itemType: number) => itemType === Constants.CONSTANT_OBJECTIVETYPE;
export const isActivity = (itemType: number) => itemType === Constants.CONSTANT_ACTIVITYTYPE;
export const isInitiative = (itemType: number) => itemType === Constants.CONSTANT_INITIATIVE;
export const isOpm = (kpiType: number) => kpiType === Constants.CONSTANT_OPM;
export const isMtkpi = (kpiType: number) => kpiType === Constants.CONSTANT_MTKPITYPE;
export const isMokpi = (kpiType: number) => kpiType === Constants.CONSTANT_MOKPITYPE;
export const isDkpi = (kpiType: number) => kpiType === Constants.CONSTANT_UAEKPI;
export const isDtkpi = (kpiType: number) => kpiType === Constants.CONSTANT_DTKPITYPE;
export const isNtkpi = (kpiType: number) => kpiType === Constants.CONSTANT_NTKPITYPE;
export const isNkpi = (kpiType: number) => kpiType === Constants.CONSTANT_NKPITYPE;
export const isEkpi = (kpiType: number) => kpiType === Constants.CONSTANT_EKPI;
export const isSkpi = (kpiType: number) => kpiType === Constants.CONSTANT_SKPITYPE;
export const isSrvkpi = (kpiType: number) => kpiType === Constants.CONSTANT_SRVKPI;
export const isTpAggreementReadyToPublish = (objectType: number) =>
  objectType === Constants.CONSTANT_TP_AGREEMENT_READY_TO_PUBLISH;

export const checkVisionTargetLabel = (itemType: number) => {
  if (isNkpi(itemType) || isNtkpi(itemType)) return "kpi.optimal_target";
  if (isDkpi(itemType) || isDtkpi(itemType) || isMokpi(itemType) || isMtkpi(itemType)) return "kpi.dkpi_target";
  else return "";
};

export const isKpiWorkflow = (itemTypeId: number) => {
  if (AdaaHelper.isObjectKPI(itemTypeId)) return true;

  switch (itemTypeId) {
    case Constants.CONSTANT_WORKFLOW.NKPI:
    case Constants.CONSTANT_WORKFLOW.SKPI:
    case Constants.CONSTANT_WORKFLOW.MOKPI:
    case Constants.CONSTANT_WORKFLOW.MTKPI:
    case Constants.CONSTANT_WORKFLOW.OKPI:
    case Constants.CONSTANT_WORKFLOW.DKPI:
    case Constants.CONSTANT_WORKFLOW.EKPI:
    case Constants.CONSTANT_WORKFLOW.SEKPI:
      return true;
    default:
      return false;
  }
};

export const metricTypeOptions = [
  { value: Constants.METRICS_TYPE.GLOBAL, text: "main_types.G" },
  { value: Constants.METRICS_TYPE.LOCAL, text: "main_types.L" },
  { value: Constants.METRICS_TYPE.DIMENSION, text: "main_types.D" },
];
