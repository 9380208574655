@if (selectedGovDirections().length) {
    <div class="container-fluid d-block card mb-2 p-3 bg-white border-0 form-section">
        <div class="row mb-2">
            <div class="col-md-5 col-sm-6 col-xs-12">
                <span class="text-primary simple-title fw-bold">
                    @if (!isTpPage) {
                        {{ useNewDS() ? ("dtkpi.nationalStrategy" | translate) : ("initiatives.government_directions" | translate) }}
                    } @else {
                        {{ "dtkpi.nationalStrategy" | translate }}
                    }
                </span>
            </div>

            <div class="col-md-7 col-sm-6 col-xs-12">
                <span class="text-primary simple-title fw-bold">
                    @if (!isTpPage) {
                        {{ useNewDS() ? ("dtkpi.enablerObjective" | translate) : ("initiatives.sub_government_directions" | translate) }}
                    } @else {
                        {{ "dtkpi.enablerObjective" | translate }}
                    }
                </span>
            </div>
        </div>

        @for (govDir of selectedGovDirections(); track govDir.id) {
            <div class="row border border-1 border-light-subtle rounded-1 position-relative mb-3 sub-gov-form shadow-sm py-3">
                <div class="w-100 d-flex justify-content-end position-relative top">
                    <button
                        class="btn btn-sm btn-danger rounded-circle border border-1 text-white position-absolute"
                        (click)="removeGovDirection(govDir.id)"
                    >
                        <i class="fa fa-trash-alt"></i>
                    </button>
                </div>

                <div class="col-md-5 col-sm-6 col-xs-12">
                    <span class="text-dark fw-bold">
                        {{ govDir | translateToken: "name" }}
                    </span>
                </div>

                <div class="col-md-7 col-sm-6 col-xs-12">
                    @for (subGovDir of filterSubGovDirections(govDir.id, selectedSubGovDirections()); track subGovDir.id) {
                        <div class="row bg-secondary p-2 w-100 mb-3 shadow-sm border border-1 border-secondary-subtle">
                            <div class="col-11 text-white text-center px-2">
                                {{ subGovDir | translateToken: "name" }}
                            </div>

                            <div class="col-1">
                                <span class="adaa-icon-delete pointer text-white" (click)="removeSubGovDirection(subGovDir.id)"></span>
                            </div>

                            @if (enableMainSelection) {
                                <div class="col-12 bg-white d-flex justify-content-center align-items-center mt-3 rounded-1">
                                    <adaa-form-checkbox
                                        #checkboxes
                                        checkboxMode="trueFalse"
                                        [setDefaultValue]="isMainGovDir(subGovDir.id)"
                                        [useSwitch]="true"
                                        [controlName]="'mainSubGovDirection' + subGovDir.id"
                                        [label]="'initiatives.main_subgovernment_direction' | translate | translate"
                                        [boldLabel]="isMainGovDir(subGovDir.id)"
                                        (inputChanges)="selectMainSubGovDirection({ id: subGovDir.id, checked: $event })"
                                    />
                                </div>
                            }
                        </div>
                    }

                    <div class="row bg-white p-2 w-100 mb-3 shadow-sm border border-1 border-secondary-subtle py-1">
                        <div class="col-12 py-2 form-group">
                            <select #subGovDropdown class="form-select sub-gov-dropdown" (change)="selectSubGovDirection($event, subGovDropdown)">
                                <option>{{ "dtkpi.pleaseChooseEnablerObjective" | translate }}</option>
                                @for (item of filterSubGovDirectionOptions(govDir.id, deselectedSubGovDirections()); track $index) {
                                    <option [value]="item.value">
                                        {{ item.text }}
                                    </option>
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
}

<div class="container-fluid d-block card mt-2 mb-3 p-3 bg-white border-0 form-section">
    <div class="row">
        <div class="col-12">
            <adaa-form-dropdown
                #govDropdown
                controlName="govDirId"
                [label]="'dtkpi.nationalStrategy' | translate"
                [options]="govDirectionOptions()"
                (inputChanges)="selectGovDirection($event, govDropdown)"
            />
        </div>
    </div>
</div>
