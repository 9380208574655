import { HttpContextToken } from "@angular/common/http";
import { computed, inject, Injectable, signal } from "@angular/core";

import { AdaaHelper } from "../../core/utils";
import { SystemLayoutService } from "./system-layout.service";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  static useLoaderToken = new HttpContextToken<boolean>(() => true);

  private readonly _systemLayoutService = inject(SystemLayoutService);

  isLoading = computed<boolean>(
    () => this._loading() || (AdaaHelper.isLoggedIn() && !this._systemLayoutService.isReady())
  );
  private _loading = signal<boolean>(false);

  public start(maxTime?: number): void {
    this._loading.set(true);

    if (maxTime) {
      setTimeout(() => {
        this.end();
      }, maxTime);
    }
  }

  public end(): void {
    this._loading.set(false);
  }
}
