import { NgClass } from "@angular/common";
import { Component, input, output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { PermissionActionDescriptorType } from "../../../../../adaa-types";
import { AdaaHelper } from "../../../../core/utils";
import { ActionsEvents } from "../../../constants/enums";
import { HasPermissionDirective } from "../../../directives";
import { CustomButton, ItemAction } from "../../../models";

@Component({
  selector: "adaa-data-table-actions",
  standalone: true,
  imports: [NgClass, TranslateModule, HasPermissionDirective],
  templateUrl: "./data-table-actions.component.html",
  styleUrl: "./data-table-actions.component.scss",
})
export class DataTableActionsComponent {
  data = input.required<Record<PropertyKey, unknown> | NonNullable<unknown>>();

  customActionButtons = input<CustomButton[]>([]);

  disableViewRules = input<ItemAction[]>([]);
  disableEditRules = input<ItemAction[]>([]);
  disableConnectionsRules = input<ItemAction[]>([]);
  disableDeleteRules = input<ItemAction[]>([]);

  enableConnectionsAction = input<boolean>(false);
  enableViewAction = input<boolean>(true);
  enableEditAction = input<boolean>(false);
  enableDeleteAction = input<boolean>(false);

  hideViewRules = input<ItemAction[]>([]);
  hideEditRules = input<ItemAction[]>([]);
  hideConnectionsRules = input<ItemAction[]>([]);
  hideDeleteRules = input<ItemAction[]>([]);

  managePermission = input.required<PermissionActionDescriptorType>();
  viewPermission = input.required<PermissionActionDescriptorType>();

  actionClicked = output<ActionsEvents>();
  customClicked = output<string>();

  actionsEvents = ActionsEvents;

  /**
   * @description This function to check if the item is (Disabled) or (Hidden)
   * @param action The action type (e.g. view)
   * @param checkType To check what you are looking for. (isDisabled) or (isHidden) and based
   * on that the rules will return true or false.
   */
  public actionRules(
    action: ActionsEvents,
    checkType: "isDisabled" | "isHidden" = "isDisabled",
    customButton?: CustomButton
  ): boolean {
    let rulesArray: ItemAction[] = [];
    switch (action) {
      case ActionsEvents.connections:
        rulesArray = checkType === "isHidden" ? this.hideConnectionsRules() : this.hideConnectionsRules();
        break;
      case ActionsEvents.edit:
        rulesArray = checkType === "isHidden" ? this.hideEditRules() : this.disableEditRules();
        break;
      case ActionsEvents.view:
        rulesArray = checkType === "isHidden" ? this.hideViewRules() : this.disableViewRules();
        break;
      case ActionsEvents.delete:
        rulesArray = checkType === "isHidden" ? this.hideDeleteRules() : this.disableDeleteRules();
        break;
      case ActionsEvents.custom:
        rulesArray = customButton?.disableRules ?? [];
        break;
    }

    if (!rulesArray || rulesArray.length === 0) return true;

    let bypass = true;

    for (let index = 0; index < rulesArray.length; index++) {
      const rule = rulesArray[index];
      const dataItem = this.data() as Record<PropertyKey, unknown>;

      /**
       * @note Disable if properties are equal
       */
      if (rule.operator === "equal") {
        if (dataItem[rule.propertyName] === rule.compareWith) {
          bypass = false;
        }
        /**
         * @note Disable if properties are not-equal
         */
      } else if (rule.operator === "not-equal") {
        bypass = false;
        if (dataItem[rule.propertyName] === rule.compareWith) {
          bypass = true;
          break;
        }
        /**
         * @note Disable if a property is NOT NULL
         */
      } else if (rule.operator === "notNull") {
        bypass = false;
        if (!AdaaHelper.isDefined(dataItem[rule.propertyName])) {
          bypass = true;
        }
        /**
         * @note Disable if a property is NULL
         */
      } else if (rule.operator === "null") {
        bypass = false;
        if (AdaaHelper.isDefined(dataItem[rule.propertyName])) {
          bypass = true;
        }
      }
    }

    return bypass;
  }
}
