/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { FileSaverService } from "ngx-filesaver";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import { AdaaBoolean, CellType, ImportExportStatus, Language, PublishedState } from "../constants/enums";
import { ObjectStatus, ParameterCatalog } from "../models";

@Injectable({
  providedIn: "root",
})
export class DataTableExportService {
  private _fileSaverService = inject(FileSaverService);
  private _translateService = inject(TranslateService);

  /**
   * Export CSV File
   * @param columns
   * @param parameterCatalogs
   * @param data
   */
  public exportTableData(columns: string[], parameterCatalogs: ParameterCatalog[], data: any[]): void {
    //remove the actions column
    columns = columns.filter((e) => e !== "actions");

    //reorder the columns in right way
    if (AdaaHelper.getCurrentLang() === Language.English) columns.reverse();

    //specify how you want to handle null values
    const replacer = (_key: string, value: any) => (value == undefined ? "" : value);

    const csv = data.map((row) =>
      columns
        .map((fieldName) =>
          JSON.stringify(this._getFormattedData(fieldName, parameterCatalogs, row[fieldName]), replacer)
        )
        .join("\t")
    );

    const header = columns.map((fieldName) => {
      const column = parameterCatalogs.find((e) => e.fieldName === fieldName);
      return column ? AdaaHelper.getItemValueByToken(column, "name") : fieldName;
    });
    csv.unshift(header.join("\t") + "\n");

    const csvArray = csv.join("\r\n");
    const currentDate = moment();

    // Add UTF-8 BOM to fix Arabic text
    const utf16Encoded = new Uint8Array([0xff, 0xfe, ...this._stringToUTF16(csvArray)]);
    const blob = new Blob([utf16Encoded], { type: "text/csv;charset=utf-16le;" });
    const fileName = `${
      AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
        type: "prop",
        property: AdaaHelper.getFieldLanguage("shortName"),
      }) as string
    }_${currentDate.format("YYYYMMDD")}_${currentDate.format("HHmmss")}`;

    this._fileSaverService.save(blob, fileName);
  }

  private _stringToUTF16(str: string): Uint8Array {
    const buffer = new ArrayBuffer(str.length * 2);
    const view = new Uint16Array(buffer);
    for (let i = 0; i < str.length; i++) {
      view[i] = str.charCodeAt(i);
    }
    return new Uint8Array(buffer);
  }

  private _getFormattedData(fieldName: string, parameterCatalogs: ParameterCatalog[], data: any) {
    const col = parameterCatalogs.find((e) => e.fieldName === fieldName);
    switch (col?.fieldType) {
      case CellType.date:
        return AdaaHelper.formatDate(data);
      case CellType.datetime:
        return AdaaHelper.formatDate(data, true);
      case CellType.trueFalse:
        return this._getYesNoLabels(data);
      case CellType.yesNo:
        return this._getYesNoLabels(data === AdaaBoolean.Y);
      case CellType.status:
        return this._getStatusLabel(data);
      case CellType.trend:
        return this._getTrendLabel(data);
      case CellType.metric:
        return this._getMetricLabel(data);
      case CellType.workflow:
        return this._getWorkflowLabel(data);
      case CellType.enabled:
        return this._getEnabledLabel(data === AdaaBoolean.Y);
      case CellType.specialCase:
        return this._getSpecialCaseLabel(col, data);
      default:
        return data;
    }
  }

  private _getYesNoLabels(value: boolean): string {
    return value
      ? this._translateService.instant("common.form.label.yes")
      : this._translateService.instant("common.form.label.no");
  }

  private _getStatusLabel(value: number): string {
    switch (value) {
      case ObjectStatus.ACTIVE:
        return this._translateService.instant("status.1");
      case ObjectStatus.REMOVE:
        return this._translateService.instant("status.2");
      case ObjectStatus.DRAFT:
        return this._translateService.instant("status.3");
      case ObjectStatus.CLOSED:
        return this._translateService.instant("status.4");
      case ObjectStatus.ARCHIVED:
        return this._translateService.instant("status.5");
      case ObjectStatus.PLANNING:
        return this._translateService.instant("status.6");
      case ObjectStatus.IN_REVIEW:
        return this._translateService.instant("status.in_review");
      default:
        return "";
    }
  }

  private _getTrendLabel(value: number): string {
    switch (value) {
      case Constants.TREND.INCREASING:
        return this._translateService.instant("trend.increase");
      case Constants.TREND.DECREASING:
        return this._translateService.instant("trend.decrease");
      case Constants.TREND.ONTARGET:
        return this._translateService.instant("trend.on_target");
      case Constants.TREND.BOUNDED:
        return this._translateService.instant("trend.bounded");
      default:
        return "";
    }
  }

  private _getMetricLabel(value: string): string {
    switch (value) {
      case Constants.METRICS_TYPE.GLOBAL:
        return this._translateService.instant("main_types.G");
      case Constants.METRICS_TYPE.LOCAL:
        return this._translateService.instant("main_types.L");
      case Constants.METRICS_TYPE.LOCAL_ALL:
        return this._translateService.instant("main_types.LA");
      case Constants.METRICS_TYPE.DIMENSION:
        return this._translateService.instant("main_types.D");
      default:
        return "";
    }
  }

  private _getWorkflowLabel(value: number): string {
    switch (value) {
      case Constants.CONSTANT_WORKFLOW_WFTYPES.APPROVAL:
        return this._translateService.instant("workflow.approval");
      case Constants.CONSTANT_WORKFLOW_WFTYPES.REVIEW:
        return this._translateService.instant("workflow.review");
      default:
        return "";
    }
  }

  private _getEnabledLabel(value: boolean): string {
    return value
      ? this._translateService.instant("common.form.label.active")
      : this._translateService.instant("common.form.label.inactive");
  }

  private _getSpecialCaseLabel(col: ParameterCatalog, value: any) {
    if (col.fieldName === "publishedState") return this._getPublishedStateLabel(value);
    if (col.fieldName === "formula") return this._getFormulaLabel(value);
    if (col.fieldName === "periodDate") return AdaaHelper.formatDate(value);
    if (col.fieldName === "impextStatus") return this._getImportExportStatus(value);
    else return value;
  }

  private _getPublishedStateLabel(value: number): string {
    return value === PublishedState.notPublished
      ? this._translateService.instant("non_published")
      : this._translateService.instant("published");
  }

  private _getFormulaLabel(value: string): string {
    return value === "NOFORMULAV" ? this._translateService.instant("kpi.NOFORMULAV") : value;
  }

  private _getImportExportStatus(value: string) {
    switch (value) {
      case ImportExportStatus.DONE:
        return this._translateService.instant("importExport.file_status.done");
      case ImportExportStatus.FAIL:
        return this._translateService.instant("importExport.file_status.fail");
      case ImportExportStatus.PENDING:
        return this._translateService.instant("importExport.file_status.pending");
      case ImportExportStatus.SUCCESS:
        return this._translateService.instant("importExport.file_status.success");
      default:
        return "";
    }
  }
}
