import { inject, Injectable } from "@angular/core";

import type { MainResponseModel } from "../models";
import {
  NotificationConfig,
  NotificationConfigPlaceholder,
  NotificationConfigTemplate,
} from "../models/notifications-config.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class NotificationsApiService {
  private _mainService = inject(MainService);

  public getCategories() {
    const url = `notificationconfig/getCategories`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._mainService.sendRequest<MainResponseModel<any>>({
      method: "GET",
      url,
    });
  }

  public getNotificationsTemplate(id: number) {
    const url = `notificationconfig/getTemplateById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<NotificationConfigTemplate>>({
      method: "GET",
      url,
    });
  }

  public updateNotificationsTemplate(data: NotificationConfigTemplate) {
    const url = `notificationconfig/updateTemplate`;

    return this._mainService.sendRequest<MainResponseModel<unknown>>({
      method: "POST",
      url: url,
      data,
    });
  }

  public getNotificationsPlaceholders() {
    const url = `notificationconfig/getAllPlaceholders`;

    return this._mainService.sendRequest<MainResponseModel<NotificationConfigPlaceholder[]>>({
      method: "GET",
      url,
    });
  }

  public updateNotifications(data: NotificationConfig[], isFinish = false) {
    const url = `notificationconfig/updateNotifications?isFinish=${isFinish}`;

    return this._mainService.sendRequest<MainResponseModel<unknown>>({
      method: "POST",
      url: url,
      data,
    });
  }
}
