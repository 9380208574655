import { inject, Injectable } from "@angular/core";

import { AdaaHelper } from "../../core/utils";
import { type KpiScoreByAggregationModelType, type MainResponseModel, SearchFields } from "../models";
import { MainService } from "./main.service";
import { MetricApiService } from "./metric-api.service";
import { WorkflowsApiService } from "./workflows-api.service";

@Injectable({
  providedIn: "root",
})
export class DataTableDataService {
  private readonly _mainService = inject(MainService);
  private readonly _workflowApisService = inject(WorkflowsApiService);
  private readonly _metricsApiService = inject(MetricApiService);

  /**
   * @param limit
   * @param offset
   * @param searchFields
   * @param prefix
   * @param paramsString
   * @param loader
   */
  public getAllV2(
    limit?: number,
    offset?: number,
    prefix: string = "item",
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `${prefix}/getallv2${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  /**
   * @param limit
   * @param offset
   * @param searchFields
   * @param prefix
   * @param paramsString
   * @param loader
   */
  public getAllV2Small(
    limit?: number,
    offset?: number,
    prefix: string = "item",
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `${prefix}/getAllv2${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  /**
   * @param limit
   * @param offset
   * @param searchFields
   * @param prefix
   * @param paramsString
   * @param loader
   */
  public getAll(
    limit?: number,
    offset?: number,
    prefix: string = "item",
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `${prefix}/getall${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  /**
   * @param limit
   * @param offset
   * @param searchFields
   * @param prefix
   * @param paramsString
   * @param loader
   */
  public getAllCamelCase(
    limit?: number,
    offset?: number,
    prefix: string = "item",
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `${prefix}/getAll${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  /**
   * @param limit
   * @param offset
   * @param searchFields
   * @param _prefix
   * @param paramsString
   * @param loader
   */
  public getSubGovKpisByPeriod(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `kpi/getSubGovKpisByPeriod${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  /**
   * @param limit
   * @param offset
   * @param searchFields
   * @param _prefix
   * @param paramsString
   * @param loader
   */
  public getInitiativesCrossCycle(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `initiative/bySubGov${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getKpiScoresByAggregationType(
    limit?: number,
    offset?: number,
    prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = `${prefix}/getKpiScoresByAggregationType?${paramsString}`;

    if (AdaaHelper.isDefined(limit)) {
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;
    }

    if (AdaaHelper.isDefined(offset)) {
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;
    }

    return this._mainService.sendRequest<MainResponseModel<KpiScoreByAggregationModelType[]>>({
      url,
      method: "POST",
      data: searchFields,
      loader,
    });
  }

  public getNationalProjectKpis(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `kpi/getNationalProjectKpisByPeriod${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getNationalProjectMilestonesKpis(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `kpi/getProjectMilestoneKpisByPeriod${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getUsersList(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `users/getall${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getOtherEntitiesList(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `other-entity/getGridList${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getLookupList(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `prop/getAllManage${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getLookupContentList(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `/${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `prop/getallbyproptypeid${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getAllPermissionsByUserId(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `/${paramsString}?` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `users/getAllPermissionsByUserId${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public queryWorkflowMonitoringList(
    limit?: number,
    offset?: number,
    _prefix?: string,
    _paramsString?: string,
    _loader: boolean = true,
    searchFields?: SearchFields
  ) {
    return this._workflowApisService.getAllMonitoring(limit, offset, searchFields);
  }

  public getUsersGroupsList(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `usergroups/getall${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public queryAuditTrail(
    limit?: number,
    offset?: number,
    _prefix: string = "item",
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `audittrail/getAll${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getEntityReportsList(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `/${paramsString}` : "";

    if (AdaaHelper.isDefined(limit)) url = `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `file/getAllByItemTypeId${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getSynchronizationHistory(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `khadamati/getSyncHistory${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getSynchronizationHistoryDetail(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `/${paramsString}` : "";

    if (AdaaHelper.isDefined(limit)) url = `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `khadamati/getSyncHistoryDetail${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getAllNationalStrategies(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `/${paramsString}` : "";

    if (AdaaHelper.isDefined(limit)) url = `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `govdirection/getAllNationalStrategies${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getMandatoryList(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `/${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `validator/getallbytype${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getAllSharedAndLocal(
    limit?: number,
    offset?: number,
    _prefix?: string,
    _paramsString?: string,
    _loader: boolean = true,
    searchFields?: SearchFields
  ) {
    return this._metricsApiService.getAllSharedAndLocal(limit, offset, searchFields);
  }

  public getAllMonitoring(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `libmetric/getAllMonitoring${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getAuditList(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `dataentry/getauditkpis${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getGovScoresPillars(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `govScoresPillars/getAllPillarsByEntity/${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getAgreements(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `national-project/getAgreements${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getAllV3(
    limit?: number,
    offset?: number,
    prefix: string = "item",
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `${prefix}/getallv3${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getInitiativeByIdAndPriorityV2(
    limit?: number,
    offset?: number,
    _prefix: string = "item",
    paramsString?: string,
    _loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = `initiative/getinitiativebyidandpriority_v2?${paramsString ?? ""}`;

    if (AdaaHelper.isDefined(limit)) {
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;
    }

    if (AdaaHelper.isDefined(offset)) {
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;
    }

    return this._mainService.sendRequest({
      method: "POST",
      url,
      data: searchFields,
    });
  }

  public getDimensionSyncLogs(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `?${paramsString}` : "";

    if (AdaaHelper.isDefined(limit))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&limit=${limit}` : `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `libmetric/getSyncLogs${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getDimensionsStructureSyncLogsDetails(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `/${paramsString}` : "";

    if (AdaaHelper.isDefined(limit)) url = `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `libmetric/getStructureSyncLogs${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }

  public getDimensionsValueSyncLogsDetails(
    limit?: number,
    offset?: number,
    _prefix?: string,
    paramsString?: string,
    loader: boolean = true,
    searchFields?: SearchFields
  ) {
    let url = AdaaHelper.isDefinedAndNotEmpty(paramsString) ? `/${paramsString}` : "";

    if (AdaaHelper.isDefined(limit)) url = `${url}?limit=${limit}`;

    if (AdaaHelper.isDefined(offset))
      url = AdaaHelper.isDefinedAndNotEmpty(url) ? `${url}&offset=${offset}` : `${url}?offset=${offset}`;

    url = `libmetric/getValueSyncLogs${url}`;

    return this._mainService.sendRequest({
      method: "POST",
      url,
      loader,
      data: searchFields,
    });
  }
}
