import { Component, effect, EventEmitter, inject, input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { AdaaHelper } from "../../../../core/utils";
import { EntitiesApiService, LanguageService, SystemLayoutService } from "../../../services";
import { ConfirmationModalComponent } from "../../general";

@Component({
  selector: "adaa-entity-change-detection",
  standalone: true,
  template: "",
  imports: [],
})
export class EntityChangeDetectionComponent {
  private readonly _router = inject(Router);
  private readonly _modalService = inject(NgbModal);
  private readonly _languageService = inject(LanguageService);
  private readonly _entitiesApiService = inject(EntitiesApiService);
  private readonly _systemLayoutService = inject(SystemLayoutService);

  entityId = input<number | null>();

  @Output() continue = new EventEmitter<void>();

  readonly currentEntityId = () => AdaaHelper.entity?.id;

  constructor() {
    effect(() => {
      if (!this.entityId()) {
        this.continue.emit();
        return;
      }
      if (this.entityId() === this.currentEntityId()) {
        this.continue.emit();
        return;
      }

      this._open();
    });
  }

  private _open() {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      keyboard: false,
      animation: true,
      size: "md",
      backdropClass: "adaa-invisible",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = "kpi.different_entity_title";
    modal.componentInstance.title = "kpi.different_entity";

    modal.result.then(
      (e) => {
        if (e) {
          this._getEntity();
        } else {
          this._router.navigateByUrl("/console");
        }
      },
      () => {
        this._router.navigateByUrl("/console");
      }
    );
  }

  private _getEntity() {
    this._entitiesApiService.getById(this.entityId()!).subscribe({
      next: (res) => {
        this._systemLayoutService.selectEntity(res.responseData, true);
        setTimeout(() => window.location.reload());
      },
    });
  }
}
