import { Pipe, PipeTransform } from "@angular/core";

import { AdaaHelper } from "../utils";

@Pipe({
  name: "roundValue",
  pure: false,
  standalone: true,
})
export class RoundValuePipe implements PipeTransform {
  public transform(
    value: string | number,
    toFixedValue: number = 2,
    nullSymbol: string = "-",
    alwaysShowComma: boolean = false
  ): string {
    return AdaaHelper.roundValue(value, toFixedValue, nullSymbol, alwaysShowComma);
  }
}
