/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { NgbDropdown, NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { Language } from "../../../constants/enums";
import { MatchWidthDirective } from "../../../directives";
import { ParameterCatalog, ValueText } from "../../../models";
import { FormDropdownTreeNodeComponent } from "./form-dropdown-tree-node.component";

@Component({
  selector: "adaa-form-dropdown-tree",
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    FormDropdownTreeNodeComponent,
    MatchWidthDirective,
  ],
  templateUrl: "./form-dropdown-tree.component.html",
  styleUrl: "./form-dropdown-tree.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormDropdownTreeComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormDropdownTreeComponent),
    },
  ],
})
export class FormDropdownTreeComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() required: boolean = false;
  @Input() invalid: boolean = false;
  @Input() label: string = "";
  @Input() isDisabled: boolean = false;
  @Input() controlName: string | null = null;
  @Input() controlId: string | null = null;
  @Input() options: ValueText[] = [];
  @Input() isMultiple: boolean = false;
  @Input() enableSearch: boolean = true;
  Language = Language;

  @Input()
  public set setDefaultValue(value: any) {
    if (value) {
      this.writeValue(value);
    }
  }

  @Input()
  public set setValidator(validatorList: { parameterCatalogs: ParameterCatalog[] }) {
    if (validatorList) {
      if (validatorList.parameterCatalogs) {
        const field = validatorList.parameterCatalogs.find((e) => e.fieldName === this.controlName);

        if (field) {
          this.required = field.mandatory === "Y";
        }
      }
    }
  }

  @Output() inputChanges = new EventEmitter();

  @ViewChild("ddl") ddl: NgbDropdown;

  private _onValidatorChange: () => void;
  private _onTouched: () => void;
  private _onChange: (value: any) => void;

  AdaaHelper = AdaaHelper;
  inputControl: FormControl = new FormControl();
  selectedValue: any[] = [];
  selectedObjects: ValueText[] = [];
  optionsCopy: ValueText[] = [];

  public ngOnInit(): void {
    if (!AdaaHelper.isDefined(this.controlId)) this.controlId = this.controlName;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["options"] &&
      JSON.stringify(changes["options"].previousValue) !== JSON.stringify(changes["options"].currentValue)
    ) {
      this._setSelectedObjects();
      this.optionsCopy = this.options;
    }
  }

  public writeValue(obj: any): void {
    if (obj === null) {
      this.inputControl.reset();
      return;
    }

    if (obj instanceof Array) this.selectedValue = obj;
    else this.selectedValue = [obj];

    this._setSelectedObjects();
    this.inputControl.setValue(this.selectedValue);
  }

  public registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this._onValidatorChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public validate(value: FormControl): false | { required: boolean } {
    const isRequired =
      (this.required && this.inputControl?.value === null) || (this.required && this.inputControl?.value === "");
    return (
      isRequired && {
        required: true,
      }
    );
  }

  public valueChanged(event: any[]): void {
    this.selectedValue = event;
    this._setSelectedObjects();

    if (this.isMultiple) {
      if (this._onChange) this._onChange(event);
      this.inputChanges.emit(event);
    } else {
      //In single mode get the first item only
      const value = event && event.length > 0 ? event[0] : null;
      if (this._onChange) this._onChange(value);
      this.inputChanges.emit(value);

      this.ddl.close();
    }
  }

  public removeSelection(event: any): void {
    this.valueChanged(this.selectedValue.filter((e) => e != event));
  }

  public search(value: string) {
    if (!AdaaHelper.isDefined(value)) this.options = AdaaHelper.clone(this.optionsCopy);
    else {
      const options = this._searchRecursive(this.optionsCopy, value.toLowerCase());
      this.options = options;
    }
  }

  private _setSelectedObjects(): void {
    this.selectedObjects = [];
    if (this.selectedValue)
      this.selectedValue.forEach((e) => {
        const item = this._findObjectByIdRecursive(this.options, e);
        if (item) this.selectedObjects.push(item);
      });
  }

  private _findObjectByIdRecursive(array: ValueText[], id: any): ValueText | undefined {
    for (const obj of array) {
      if (obj.value === id) {
        return obj;
      }
      if (obj.children) {
        const found = this._findObjectByIdRecursive(obj.children, id);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  private _searchRecursive(array: ValueText[], value: string): ValueText[] {
    const matches: ValueText[] = [];
    if (!Array.isArray(array)) return matches;

    array.forEach((e) => {
      if (e.text.toLowerCase().includes(value)) {
        matches.push(e);
      } else {
        if (e.children) {
          const result = this._searchRecursive(e.children, value);
          if (result.length) matches.push(Object.assign({}, e, { children: result }));
        }
      }
    });

    return matches;
  }
}
