import { Component, effect, inject, Injector, input, signal, untracked } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import type { ChartConfiguration, ChartType } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { filter, forkJoin, map, switchMap } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { Language } from "../../../constants/enums";
import type { ItemViewCardPerformanceType } from "../../../models";
import { ChartsApiService, ChartsService } from "../../../services";
import { ChartActionButtonsComponent } from "../../chart-action-buttons/chart-action-buttons.component";

@Component({
  selector: "adaa-view-card-performance",
  standalone: true,
  imports: [BaseChartDirective, TranslateModule, ChartActionButtonsComponent],
  styleUrl: "./view-card-performance.component.scss",
  template: `
    <div class="card-title px-2 pt-3 pb-2 mb-0 view-card">
      @if (showChart()) {
        <adaa-chart-action-buttons
          [title]="pageTitle()"
          [elementId]="'viewCardChart'"
          [csvChartInfo]="csvChartInfo()"
        ></adaa-chart-action-buttons>
      }
      <h5 class="m-0 mb-1 fw-bold">
        {{ title() | translate }}
      </h5>
    </div>

    <button
      type="button"
      class="btn btn-sm bg-white text-primary rounded-circle shadow-sm my-2"
      [title]="(showChart() ? 'tooltips.list_view' : 'tooltips.chart') | translate"
      (click)="showChart.set(!showChart())"
    >
      <i [class]="showChart() ? 'fa fa-lg fa-list' : 'fa fa-lg fa-chart-simple'"></i>
    </button>

    @if (!showChart()) {
      <div class="my-2 p-2">
        <table>
          <thead>
            <th>Date</th>
            @for (label of listData()?.labels; track $index) {
              <th>{{ label }}</th>
            }
          </thead>
          <tbody>
            @for (data of this.listData()?.data; track $index) {
              <tr>
                <td class="p-2">
                  {{ data.label }}
                </td>
                @for (item of data.data; track $index) {
                  <td class="p-2">
                    {{ item }}
                  </td>
                }
              </tr>
            }
          </tbody>
        </table>
      </div>
    }

    @if (options() && showChart()) {
      <div class="w-100 position-relative py-1 px-1 view-card" style="height: 300px">
        <canvas
          id="viewCardChart"
          baseChart
          class="chart"
          [options]="options()"
          [type]="chartType()"
          [data]="data()"
        ></canvas>
      </div>
    }
  `,
})
export class ViewCardPerformanceComponent {
  private readonly _injector = inject(Injector);
  private readonly _translateService = inject(TranslateService);
  private readonly _chartsApiService = inject(ChartsApiService);
  private readonly _chartsService = inject(ChartsService);

  itemId = input.required<number>();
  endDate = input.required<number>();
  periodId = input.required<number>();
  entityId = input.required<number>();
  startDate = input.required<number>();
  objectType = input.required<number>();
  chartType = input<ChartType>("bar");
  showPerformance = input<boolean>(true);
  showAnnualPerformance = input<boolean>(true);
  includeLegacy = input<boolean>(false);
  chartFrequency = input<string>(Constants.CHART_FREQUENCY_OPTION.CYCLE);
  showLegend = input<boolean>(true);
  title = input<string>("sidebar_tables.evolution_graph");
  entityMapId = input<number | undefined>(undefined);
  pageTitle = input<string>();
  csvChartInfo = input<string>();
  showChart = signal<boolean>(true);

  data = signal<ChartConfiguration["data"]>({
    labels: [],
    datasets: [],
  });
  listData = signal<{ labels: string[]; data: { label: string; data: number[] }[] } | undefined>(undefined);
  options = signal<ChartConfiguration["options"] | undefined>(undefined);

  constructor() {
    this._getCalculationReading();
  }

  private _getCalculationReading() {
    effect(
      () => {
        const _map = AdaaHelper.getMap(this.entityId());
        const options = {
          entityId: this.entityId(),
          graph: "bars" as const,
          includeLegacy: this.includeLegacy(),
          itemId: this.itemId(),
          itemTypeId: this.objectType(),
          option: this.chartFrequency(),
          mapId: this.entityMapId() ?? (_map?.id as number),
          periodId: this.periodId(),
        };

        untracked(() => {
          const obs$ = forkJoin([
            this._translateService.get("common.tables.performance"),
            this._translateService.get("box_status.annual_performance"),
          ]).pipe(
            switchMap((txt: [string, string]) =>
              this._chartsApiService.getCalculationReadingsGraphCardByItem(options).pipe(
                filter((res) => !res.inError),
                map((res) => ({
                  stats: res.responseData,
                  label: txt,
                }))
              )
            )
          );

          obs$.subscribe({
            next: (data) => {
              this._drawChart(data);
              this._generateChartOptions();
            },
          });
        });
      },
      { injector: this._injector }
    );
  }

  private _generateChartOptions() {
    const data = this.data()
      .datasets.flatMap(({ data }) => data)
      .filter((a) => AdaaHelper.isDefined(a))
      .sort((a, b) => {
        if (!a) return 0;
        if (!b) return 0;
        return a > b ? -1 : 1;
      }) as number[];

    let max: number;
    if (data.length === 0) {
      max = 50;
    } else {
      max = Number(data[0].toFixed(0)) - (Number(data[0].toFixed(0)) % 10);
    }

    const options = this._chartsService.getPerformanceChartOptions({
      showLegend: this.showLegend(),
      padding: 2,
    });

    this.options.set({
      ...options,
      scales: {
        x: {
          ...options?.scales?.x,
        },
        y: {
          ...options?.scales?.y,
          min: undefined,
          beginAtZero: false,
          max: max <= 0 ? 0 : max <= 100 ? 100 : max + 10,
        },
      },
    });
  }

  private _drawChart({ label, stats }: { label: [string, string]; stats: ItemViewCardPerformanceType[] }) {
    const dataset = [];
    if (this.showPerformance()) {
      dataset.push({
        data: stats.map(({ performance }) => performance),
        label: label[0],
        backgroundColor: "rgba(41, 169, 224, 0.5)",
        barThickness: 12.5,
        fill: false,
        pointStyle: "circle",
        pointRadius: 3,
        pointHoverRadius: 5,
        showLine: true,
        borderJoinStyle: "bevel",
        order: AdaaHelper.getCurrentLang() === Language.Arabic ? 2 : 1,
      });
    }

    if (this.showAnnualPerformance()) {
      dataset.push({
        data: stats.map(({ annualPerformance }) => annualPerformance),
        label: label[1],
        backgroundColor: "orange",
        barThickness: 9.5,
        order: AdaaHelper.getCurrentLang() === Language.Arabic ? 1 : 2,
      });
    }

    this.data.set({
      labels: stats.map(({ period }) =>
        new Date(period.date).toLocaleDateString("en-GB", { year: "numeric", month: "2-digit" })
      ),
      datasets: [...dataset],
    });

    const data = this.data();
    const labels = data.labels ?? [];
    const datasets = data.datasets ?? [];
    const listData: { label: string; data: number[] }[] = [];

    for (let index = 0; index < labels.length; index++) {
      const label = labels[index];
      const rowData: number[] = [];

      for (const dataset of datasets) {
        const value = dataset.data?.[index];
        rowData.push((value as number) ?? "-");
      }

      listData.push({ label: label as string, data: rowData });
    }

    this.listData.set({
      labels: data.datasets.map((dataset) => dataset.label!),
      data: listData,
    });
  }
}
