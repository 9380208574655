<label class="form-label fs-6 text-primary">
    {{ label() | translate }}
</label>

<ul class="list-group shadow-sm" dndDropzone (dndDrop)="onDrop($event)">
    <li class="dropPlaceHolder" dndPlaceholderRef></li>

    @for (item of items(); track item; let i = $index) {
        <li [dndDraggable]="item" dndEffectAllowed="move" class="list-group-item fw-bold">
            {{ showNumber() ? i + 1 + " - " + item.text : item.text }}
        </li>
    }
</ul>
