import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[toUppercase]",
})
export class UppercaseDirective {
  @HostListener("input", ["$event"]) public onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.toUpperCase();
  }
}
