<div class="modal-header">
    <h4 class="modal-title">{{ "tooltips.related_objects_details" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <adaa-data-table
        [configKey]="'user_permissions_detail'"
        [isLocalData]="true"
        [localData]="userPermissions"
        [enableAdd]="false"
        [enableFilter]="false"
        [enableSettings]="false"
        [enableDeleteAction]="false"
        [enableEditAction]="false"
        (actionClicked)="actionClicked($event)"
    />
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close()">
        {{ "common.form.button.close" | translate }}
    </button>
</div>
