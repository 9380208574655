import { Component, computed, inject, input } from "@angular/core";
import { Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { Constants } from "../../../constants/constants";
import type { SelectedPeriodModelType } from "../../../models";
import { UaeVisionPerfService } from "../../../services";
import { DoughnutComponent } from "../../charts";
import { ItemSliderComponent } from "../item-slider/item-slider.component";
import { UaeVisionPerfCardComponent } from "../uae-vision-perf-card/uae-vision-perf-card.component";

@Component({
  selector: "adaa-uae-vision-performance",
  standalone: true,
  templateUrl: "./uae-vision-performance.component.html",
  styleUrl: "./uae-vision-performance.component.scss",
  imports: [DoughnutComponent, TranslateModule, FontAwesomeModule, UaeVisionPerfCardComponent, ItemSliderComponent],
})
export class UaeVisionPerformanceComponent {
  readonly router = inject(Router);
  private readonly _uaeVisionPerfService = inject(UaeVisionPerfService);

  selectedPeriod = input<SelectedPeriodModelType>();

  visionDirections = computed(() => this._uaeVisionPerfService.visionDirections());

  subGovVisionDirections = computed(() => {
    if (this.visionDirections()?.length) {
      return this.visionDirections()[0].subGovDirections;
    }
    return [];
  });

  public get icon() {
    return {
      leftArrow: faArrowLeftLong,
      rightArrow: faArrowRightLong,
    };
  }

  public get overallPerfColor() {
    return Constants.CONSTANT_COLORS.EVALUATION_GOLD;
  }
}
