<div class="modal-header">
    <h4 class="modal-title">{{ "user_groups.title" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <adaa-data-table
        [configKey]="'user_form_user_groups_conf_list'"
        [apiName]="'getUsersGroupsList'"
        [paramsString]="paramsStringGroups()"
        [enableActionsCell]="false"
        [enableAdd]="false"
        [enableSelection]="true"
        [multiSelection]="selectedGroups"
        (getRowData)="rowSelected = $event"
    ></adaa-data-table>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ "common.form.button.close" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        {{ "common.form.button.save" | translate }}
    </button>
</div>
