import { HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";

import { environment } from "../../core/environments/environment";
import { VerificationMethod } from "../constants/enums";
import {
  LinkAccount,
  LoginRequest,
  LoginResponse,
  MainResponseModel,
  NewPassword,
  SecurityQuestionAnswerModel,
  VerificationRequestEmail,
  VerificationRequestSms,
  VerificationRequestSubmit,
} from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  private _mainService = inject(MainService);

  public login(data: LoginRequest) {
    return this._mainService.sendRequest({
      method: "POST",
      url: "authentication/login",
      data,
    });
  }

  public logout() {
    return this._mainService.sendRequest({
      method: "POST",
      url: "authentication/logout",
      data: {},
    });
  }

  public fetchVerificationCode(data: VerificationRequestEmail | VerificationRequestSms, type: VerificationMethod) {
    const url =
      type === VerificationMethod.EMAIL
        ? "authentication/sendVerificationCodeByEmail"
        : "authentication/sendVerificationCodeBySms";

    return this._mainService.sendRequest({
      method: "POST",
      url,
      data,
    });
  }

  public validateVerificationCode(data: VerificationRequestSubmit) {
    const url = "authentication/validatecode";

    return this._mainService.sendRequest({
      method: "POST",
      url,
      data,
    });
  }

  public resetPassword(data: LoginRequest) {
    const url = "authentication/forgotpassword";
    return this._mainService.sendRequest({
      method: "POST",
      url,
      data,
    });
  }

  public getUserbyToken(token: string) {
    const url = "users/getUserByToken";

    const httpHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: token,
      }),
    };

    return this._mainService.sendRequest({
      method: "GET",
      url,
      headers: httpHeader.headers,
    });
  }

  public getSecurityQuestions(token: string) {
    const url = "userprofile/getSecurityQuestionsLogin";

    const httpHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: token,
      }),
    };

    return this._mainService.sendRequest({
      method: "GET",
      url,
      headers: httpHeader.headers,
    });
  }

  public validateQuestions(token: string, data: SecurityQuestionAnswerModel) {
    const url = "userprofile/validateSecurityQuestions";
    const httpHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: token,
      }),
    };

    return this._mainService.sendRequest({
      method: "POST",
      url,
      data,
      headers: httpHeader.headers,
    });
  }

  public validateCode(token: string, data: VerificationRequestSubmit) {
    const httpHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: token,
      }),
    };

    const url = "authentication/validatecode";

    return this._mainService.sendRequest({
      method: "POST",
      url,
      data,
      headers: httpHeader.headers,
    });
  }

  public updatePassword(token: string, data: NewPassword) {
    const httpHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: token,
      }),
    };

    const url = "users/changepassword";

    return this._mainService.sendRequest({
      method: "POST",
      url,
      data,
      headers: httpHeader.headers,
    });
  }

  public uaePassLogin(token: string) {
    return this._mainService.sendRequest<MainResponseModel<LoginResponse>>({
      method: "POST",
      url: `authentication/uaepasslogin/${token}`,
      data: {},
    });
  }

  public uaePassLinkAccount(data: LinkAccount, uuid: string) {
    return this._mainService.sendRequest<MainResponseModel<LoginResponse>>({
      method: "POST",
      url: `authentication/manuallinking/${uuid}`,
      data,
    });
  }

  public uaePassLogout(timeOut: number = 10000) {
    setTimeout(() => {
      const url = `${environment.uaePass.logoutUrl}/?redirect_uri=${environment.baseUrl}`;
      window.location.href = url;
    }, timeOut);
  }
}
