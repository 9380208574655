import { Component, input, OnChanges } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";

@Component({
  selector: "adaa-read-more",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./read-more.component.html",
  styleUrl: "./read-more.component.scss",
})
export class ReadMoreComponent implements OnChanges {
  text = input.required<string | null | undefined>();
  maxLength = input<number>(100);

  isCollapsed: boolean = true;
  viewText: string;

  public ngOnChanges(): void {
    if (!AdaaHelper.isDefined(this.text()) || (this.text() ?? "").length < this.maxLength()) {
      this.viewText = this.text() ?? "";
      this.isCollapsed = false;
    } else {
      this.viewText = (this.text() ?? "").substring(0, this.maxLength()) + "...";
      this.isCollapsed = true;
    }
  }

  public toggleView(): void {
    this.isCollapsed = false;
    this.viewText = this.text() ?? "";
  }
}
