import { inject, Injectable, signal } from "@angular/core";

import type { VisionDirectionModelType } from "../models";
import { CalculationEngineApiService } from "./calculation-engine-api.service";

@Injectable({
  providedIn: "root",
})
export class UaeVisionPerfService {
  private readonly _calcEngineApiService = inject(CalculationEngineApiService);

  visionDirections = signal<VisionDirectionModelType[]>([]);

  public getQuarterlyVisionDirections(periodId: number) {
    this._calcEngineApiService
      .getVisionDirectionsV2({
        period: periodId,
        isAnnual: false,
      })
      .subscribe({
        next: (res) => {
          if (!res.inError) {
            this.visionDirections.update(() => res.responseData.filter((e) => e.visionType === "ntkpi"));
          }
        },
      });
  }

  public getAnnualVisionDirections(year: number) {
    this._calcEngineApiService
      .getVisionDirectionsV2({
        period: year,
        isAnnual: true,
      })
      .subscribe({
        next: (res) => {
          if (!res.inError) {
            this.visionDirections.update(() => res.responseData.filter((e) => e.visionType === "ntkpi"));
          }
        },
      });
  }
}
