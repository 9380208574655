@if (label) {
    <label class="form-label fs-6">{{ label }}</label>
}
<div class="{{ styles }}">
    <div *ngFor="let option of options; index as i" class="form-check m-1" [dir]="direction">
        <input
            #radioButton
            class="form-check-input fw-bold"
            type="radio"
            [value]="option.value"
            [name]="controlName"
            [id]="controlId + '-' + i"
            [checked]="option.value === setDefaultValue"
            (change)="inputChanges.emit(option.value)"
            style="cursor: pointer"
        />
        <label class="form-check-label fw-bold" [for]="controlId + '-' + i"> {{ option.text }} </label>
    </div>
</div>
