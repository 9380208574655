import { Component, inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { DataTableComponent } from "../../table/data-table/data-table.component";

@Component({
  selector: "adaa-tp-team-members-modal",
  standalone: true,
  imports: [TranslateModule, DataTableComponent],
  templateUrl: "./tp-team-members-modal.component.html",
  styleUrl: "./tp-team-members-modal.component.scss",
})
export class TpTeamMembersModalComponent {
  modal: NgbActiveModal = inject(NgbActiveModal);

  @Input() teamMembers: object[] = [];
  @Input() configKey: string = "";
  @Input() title: string = "";
}
