import { Component, computed, inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { TableSelectedRow, UserGroupModelType } from "../../../models";
import { SystemLayoutService } from "../../../services";
import { DataTableComponent } from "../../table/data-table/data-table.component";

@Component({
  selector: "adaa-user-groups-modal",
  standalone: true,
  imports: [TranslateModule, DataTableComponent],
  templateUrl: "./user-groups-modal.component.html",
  styleUrl: "./user-groups-modal.component.scss",
})
export class UserGroupsModalComponent {
  private _systemLayoutService = inject(SystemLayoutService);
  modal: NgbActiveModal = inject(NgbActiveModal);

  @Input() selectedGroups: UserGroupModelType[];

  paramsStringGroups = computed(() => `enitiyId=${this._systemLayoutService.entity?.id}`);
  rowSelected: TableSelectedRow;

  public save(): void {
    this.modal.close(this.rowSelected.allSelectedRows);
  }
}
