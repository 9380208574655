import { Component, EventEmitter, forwardRef, inject, Input, input, OnInit, Output } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { debounceTime, distinctUntilChanged, Subject } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { type ParameterCatalog, ValueText } from "../../../models";
import { LanguageService } from "../../../services";

@Component({
  selector: "adaa-form-dropdown",
  standalone: true,
  imports: [ReactiveFormsModule, NgSelectModule, TranslateModule],
  templateUrl: "./form-dropdown.component.html",
  styleUrl: "./form-dropdown.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormDropdownComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormDropdownComponent),
    },
  ],
})
export class FormDropdownComponent implements OnInit, ControlValueAccessor {
  readonly languageService = inject(LanguageService);

  readonly searchChannel$ = new Subject();

  readonly #untilDestroy = AdaaHelper.untilDestroyed();
  readonly #searchChannel = this.searchChannel$
    .asObservable()
    .pipe(debounceTime(500), distinctUntilChanged(), this.#untilDestroy());

  @Input() required: boolean = false;
  @Input() showAsRequired: boolean = false;
  @Input() invalid: boolean = false;
  @Input() label: string = "";
  @Input() controlName: string | null = null;
  @Input() controlId: string | null = null;
  @Input() options: ValueText[] = [];
  @Input() searchable: boolean = false;
  @Input() clearable: boolean = false;
  @Input() classes: string = "";
  @Input() appendTo: string = "";
  @Input() enableTooltips: boolean = false;
  @Input() inputPlaceholder: string = "common.form.label.please_select";
  @Input() isDisabled: boolean = false;
  boldLabel = input<boolean>(false);

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public set setDefaultValue(value: any) {
    if (value !== this.inputControl.value) this.writeValue(value);
  }

  @Input()
  public set setValidator(validatorList: { parameterCatalogs: ParameterCatalog[] }) {
    if (validatorList) {
      if (validatorList.parameterCatalogs) {
        const field = validatorList.parameterCatalogs.find((e) => e.fieldName === this.controlName);

        if (field) {
          this.required = field.mandatory === "Y";
        }
      }
    }
  }

  @Output() inputChanges = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() onClear = new EventEmitter();

  private _onValidatorChange: () => void;
  private _onTouched: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _onChange: (value: any) => void;

  adaaHelper = AdaaHelper;
  inputControl: FormControl = new FormControl();

  public ngOnInit(): void {
    if (!this.adaaHelper.isDefined(this.controlId)) this.controlId = this.controlName;
    if (this.isDisabled) this.inputControl.disable();
    if (this.searchable) {
      this.#searchChannel.subscribe({
        next: (term) => this.onSearch.emit(term),
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public writeValue(obj: any): void {
    if (obj === null || obj === undefined) {
      this.inputControl.reset();
    }

    this.inputControl.setValue(obj);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this._onValidatorChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  public validate(_value: FormControl): false | { required: boolean } {
    const isRequired =
      (this.required && this.inputControl.value === null) || (this.required && this.inputControl.value === "");
    return (
      isRequired && {
        required: true,
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public valueChanged(event: any): void {
    if (this._onChange) this._onChange(event);
    this.inputChanges.emit(event);
  }
}
