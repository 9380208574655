import { Component, computed, inject, input, OnInit, output, signal } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { PageMode } from "../../../constants/enums";
import {
  CustomTableButton,
  ItemAction,
  RowWithIssueType,
  TableButtonClicked,
  TpMilestone,
  ValidatorModelType,
  ValueText,
} from "../../../models";
import { LanguageService, ValidatorApiService } from "../../../services";
import { ConfirmationModalComponent } from "../../general";
import { ItemsOrderModalComponent } from "../../modals";
import { DataTableComponent } from "../../table/data-table/data-table.component";
import { MilestoneModalComponent } from "../milestone-modal/milestone-modal.component";

@Component({
  selector: "adaa-milestone-list",
  standalone: true,
  imports: [DataTableComponent],
  templateUrl: "./milestone-list.component.html",
  styleUrl: "./milestone-list.component.scss",
})
export class MilestoneListComponent implements OnInit {
  private _validatorApiService = inject(ValidatorApiService);
  private _languageService = inject(LanguageService);
  private _modalService = inject(NgbModal);

  milestones = input.required<TpMilestone[]>();
  projectStartDate = input.required<number>();
  projectEndDate = input.required<number>();
  entities = input.required<ValueText[]>();
  otherEntities = input.required<ValueText[]>();
  parentEntities = input.required<ValueText[]>();
  parentOtherEntities = input.required<ValueText[]>();
  parentEntityId = input.required<number>();
  parentSectors = input.required<ValueText[]>();
  projectId = input.required<number | undefined>();
  enableTeamMembers = input<boolean>(true);
  milestonesChanged = output<TpMilestone[]>();

  Constants = Constants;
  formValidation = signal<ValidatorModelType>({
    id: 0,
    status: 0,
    searchKey: "",
    type: 0,
    parameterCatalogs: [],
    nameAE: "",
    nameEN: "",
  });
  customTableButtons: CustomTableButton[] = [
    {
      eventName: "sort",
      iconTitle: "navigation.tooltips.order_tooltip",
      iconName: "adaa-icon-sort",
    },
  ];
  disableEditDeleteRules: ItemAction[] = [
    {
      propertyName: "status",
      compareWith: Constants.SERVICES.STATE.IN_REVIEW,
      operator: "equal",
    },
  ];

  parentEntitiesIds = computed(() => this.parentEntities().map((e) => e.value));
  parentOtherEntitiesIds = computed(() => this.parentOtherEntities().map((e) => e.value));

  public get milestonesData(): TpMilestone[] {
    return this.milestones()
      .sort((a, b) => {
        if (a.milestoneOrder < b.milestoneOrder) return -1;
        if (a.milestoneOrder > b.milestoneOrder) return 1;
        return 0;
      })
      .filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE)
      .map((e) => {
        if (!e.workload) e.workload = {};

        return {
          ...e,
          weight: e.workload.workload,
        };
      });
  }

  public get rowsIssues() {
    const milestones = this.milestonesData.filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE);

    const milestonesDateErrors: RowWithIssueType[] = milestones
      .filter(
        (e) =>
          e.startDate < this.projectStartDate() ||
          e.endDate > this.projectEndDate() ||
          (e.entityId && !this.parentEntitiesIds().includes(e.entityId)) ||
          (e.otherEntityId && !this.parentEntitiesIds().includes(e.otherEntityId))
      )
      .map((e) => {
        return {
          use: "id",
          value: e.fakeId ? e.fakeId : e.id,
          propName: e.fakeId ? "fakeId" : "id",
        };
      });

    return milestonesDateErrors;
  }

  public ngOnInit(): void {
    this._validatorApiService
      .searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_PROJECT_MILESTONE_STEP_FORM)
      .subscribe({
        next: (response) => {
          if (response.inError) return;

          this.formValidation.set(response.responseData);
        },
      });
  }

  public addNewClicked(): void {
    const milestone: TpMilestone = {
      id: 0,
      nationalProjectId: this.projectId(),
      startDate: this.projectStartDate(),
      endDate: this.projectEndDate(),
      contributingEntities: [],
      teamMembers: [],
      nameAE: "",
      nameEN: "",
      dscAE: "",
      dscEN: "",
      status: Constants.OBJECT_STATUS.DRAFT,
      milestoneOrder: this._getMaxOrder(),
      nationalSectors: [],
      fakeId: Math.floor(Math.random() * 217 * 1000),
    };
    this._openMilestoneModal(milestone, PageMode.create);
  }

  public actionClicked(action: TableButtonClicked): void {
    switch (action.event) {
      case "delete":
        this._deleteMilestone(action.data);
        break;
      case "edit":
        this._openMilestoneModal(action.data, PageMode.edit);
        break;
      default:
        break;
    }
  }

  public buttonClicked(): void {
    const modal = this._modalService.open(ItemsOrderModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    const data: ValueText[] = [];

    this.milestonesData.forEach((e) =>
      data.push({ value: e.id ? e.id : e.fakeId, text: AdaaHelper.getItemValueByToken(e, "name") })
    );

    modal.componentInstance.data = data;
    modal.componentInstance.inputLabel = "national_projects.national_projects_milestone.title";

    modal.result.then((result: number[] | undefined) => {
      if (!result) return;
      this.milestones().forEach((milestone) => {
        const index = result.findIndex((e) => e === milestone.id || e === milestone.fakeId);
        milestone.milestoneOrder = index + 1;
      });
    });
  }

  private _deleteMilestone(data: TpMilestone): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = "national_projects.confirmation_msg.delete_project_milestone_title";
    modal.componentInstance.title = "national_projects.confirmation_msg.delete_project_milestone_msg";

    modal.result.then((e) => {
      if (e) this._deleteMilestoneConfirmed(data);
    });
  }

  private _deleteMilestoneConfirmed(data: TpMilestone): void {
    if (data.fakeId) {
      const milestoneIndex = this.milestones().findIndex((e) => e.fakeId === data.fakeId);
      if (milestoneIndex > -1) this.milestones().splice(milestoneIndex, 1);
    } else {
      const milestone = this.milestones().find((e) => e.id === data.id);
      if (milestone) milestone.status = Constants.OBJECT_STATUS.REMOVE;
    }
    this.milestonesChanged.emit(this.milestones());
  }

  private _openMilestoneModal(milestone: TpMilestone, pageMode: PageMode): void {
    const modal = this._modalService.open(MilestoneModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.milestone = milestone;
    modal.componentInstance.pageMode = pageMode;
    modal.componentInstance.formValidation = this.formValidation();
    modal.componentInstance.entities = this.entities();
    modal.componentInstance.otherEntities = this.otherEntities();
    modal.componentInstance.parentEntities = this.parentEntities();
    modal.componentInstance.parentOtherEntities = this.parentOtherEntities();
    modal.componentInstance.parentEntityId = this.parentEntityId();
    modal.componentInstance.parentSectors = this.parentSectors();
    modal.componentInstance.enableTeamMembers = this.enableTeamMembers();
    modal.componentInstance.projectStartDate = this.projectStartDate();
    modal.componentInstance.projectEndDate = this.projectEndDate();

    modal.result.then((e) => {
      if (!e) return;
      if (pageMode === PageMode.create) this.milestones().push(e);
      else {
        const milestoneIndex = this.milestones().findIndex((milestone) =>
          e.fakeId ? e.fakeId === milestone.fakeId : e.id === milestone.id
        );
        if (milestoneIndex > -1) this.milestones().splice(milestoneIndex, 1, e);
      }

      this.milestonesChanged.emit(this.milestones());
    });
  }

  private _getMaxOrder(): number {
    const milestone = this.milestones().reduce(
      (max, current) => (current.milestoneOrder > max.milestoneOrder ? current : max),
      this.milestones()[0]
    );

    return milestone ? milestone.milestoneOrder + 1 : 1;
  }
}
