<div
    class="doughnut position-relative"
    [class.enable-animation]="enableAnimation()"
    [class.disable-animation]="!enableAnimation()"
    [attr.title]="description() ?? label()"
    (click)="chartClicked($event)"
    [style.width]="chartConfig.container"
    [style.height]="chartConfig.container"
    [style.margin-left.px]="size() === 'sm' ? 20 : 0"
>
    <svg class="doughnut--container">
        <circle
            class="doughnut--circle"
            [attr.cx]="chartConfig.cx"
            [attr.cy]="chartConfig.cy"
            [attr.r]="chartConfig.r"
            stroke="black"
            stroke-width="3"
            [style.stroke]="circleColor()"
        />
        @if (showProgressCircle()) {
            <circle
                class="doughnut--progress"
                [attr.cx]="chartConfig.cx"
                [attr.cy]="chartConfig.cy"
                [attr.r]="chartConfig.r"
                [style.stroke]="color"
                [style.stroke-dasharray]="graphCurve"
                [style.stroke-linecap]="'round'"
            />
        }
    </svg>

    <div
        class="doughnut--label d-flex justify-content-center flex-wrap"
        data-progress="0"
        [dir]="_languageService.direction()"
        [style.width]="chartConfig.container"
        [style.top]="chartConfig.label.position"
    >
        @let labelIsImage = labelAsImage();
        @let image = labelImage();
        @if (!labelIsImage) {
            @if (size() !== "xs" || size() !== "xxs") {
                <span class="label fw-bold d-block" [style.font-size]="chartConfig.label.labelFontSize">
                    {{ label()! | translate }}
                </span>
            }
        } @else {
            <img class="mb-2" [ngClass]="['label-image--' + image?.imageSize]" [src]="labelImageURI()" [attr.alt]="label()" />
        }

        <span
            class="number d-block w-75 mb-2"
            [class.w-100]="labelIsImage"
            [class.w-75]="!labelIsImage"
            [style.font-size]="chartConfig.label.amountFontSize"
        >
            @if (amount() && amount() > 0) {
                <adaa-count-up [total]="amount()" [symbol]="symbol()" [fontSize]="chartConfig.label.amountFontSize" />
            } @else {
                @if (symbol() === "C") {
                    {{ amount() | currencyValue: true }}
                } @else if (symbol() === "%") {
                    {{ amount() | percentageValue }}
                } @else {
                    {{ amount() }}
                }
            }
        </span>

        @if (showGraphIcon()) {
            <span class="d-block w-100">
                <fa-icon [icon]="graphIcon" size="2xl" (click)="graphClicked($event)" />
            </span>
        }
    </div>
</div>
