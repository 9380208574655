import { NgClass } from "@angular/common";
import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { Constants } from "../../../constants/constants";
import { PageMode } from "../../../constants/enums";
import { FormTab, SubMilestone, ValidatorModelType, ValueText } from "../../../models";
import {
  FormCheckboxComponent,
  FormDropdownComponent,
  FormInputComponent,
  FormInputDateComponent,
  FormStepsComponent,
} from "../../form";
import { TeamMembersListComponent } from "../team-members-list/team-members-list.component";

@Component({
  selector: "adaa-sub-milestone-modal",
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    ReactiveFormsModule,
    FormCheckboxComponent,
    FormDropdownComponent,
    FormInputComponent,
    FormInputDateComponent,
    FormStepsComponent,
    TeamMembersListComponent,
  ],
  templateUrl: "./sub-milestone-modal.component.html",
  styleUrl: "./sub-milestone-modal.component.scss",
})
export class SubMilestoneModalComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  modal = inject(NgbActiveModal);

  @Input() subMilestone: SubMilestone;
  @Input() pageMode: PageMode;
  @Input() formValidation: ValidatorModelType;
  @Input() entities: ValueText[] = [];
  @Input() otherEntities: ValueText[] = [];
  @Input() parentEntityId: number | undefined = undefined;

  subMilestoneForm: FormGroup;
  submitted: boolean = false;
  PageMode = PageMode;
  activeTab = signal<number>(0);
  constants = Constants;
  tabs: FormTab[] = [
    {
      title: "common.form.label.information",
    },
    {
      title: "national_projects.project_team_members.common_text",
    },
  ];

  public get filteredEntites(): ValueText[] {
    const entities: number[] = [];

    const entityId = this.subMilestoneForm.get("entityId")?.value;
    if (entityId) entities.push(+entityId);

    return this.entities.filter((e) => entities.includes(e.value));
  }

  public get filteredOtherEntites(): ValueText[] {
    const entities: number[] = [];

    const entityId = this.subMilestoneForm.get("otherEntityId")?.value;
    if (entityId) entities.push(+entityId);

    return this.otherEntities.filter((e) => entities.includes(e.value));
  }

  public ngOnInit(): void {
    this.subMilestoneForm = this._prepareForm();
    this.subMilestoneForm.patchValue(this.subMilestone);

    if (this.subMilestone?.entityId) this.entityIdChanged(this.subMilestone.entityId);
  }

  public entityIdChanged(value: number) {
    if (this.parentEntityId && value === this.parentEntityId) this.subMilestoneForm.get("otherEntityId")?.enable();
    else {
      this.subMilestoneForm.get("otherEntityId")?.disable();
      this.subMilestoneForm.get("otherEntityId")?.setValue(null);
      this.subMilestoneForm.get("otherEntityNameAE")?.setValue(null);
      this.subMilestoneForm.get("otherEntityNameEN")?.setValue(null);
    }

    const entity = this.entities.find((e) => e.value === value);
    if (entity) {
      this.subMilestoneForm.get("entityNameAE")?.setValue(entity.text);
      this.subMilestoneForm.get("entityNameEN")?.setValue(entity.text);
    }
  }

  public otherEntityIdChanged(value: number) {
    const entity = this.otherEntities.find((e) => e.value === value);
    if (entity) {
      this.subMilestoneForm.get("otherEntityNameAE")?.setValue(entity.text);
      this.subMilestoneForm.get("otherEntityNameEN")?.setValue(entity.text);
    }
  }

  public save(): void {
    this.submitted = true;

    if (!this.subMilestoneForm.valid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    this.modal.close(this.subMilestoneForm.getRawValue());
  }

  private _prepareForm() {
    return this._formBuilder.group({
      completed: { value: false, disabled: false },
      teamMembers: { value: null, disabled: false },
      startDate: [{ value: null, disabled: false }, Validators.required],
      endDate: [{ value: null, disabled: false }, Validators.required],
      nameAE: [{ value: null, disabled: false }, Validators.required],
      nameEN: [{ value: null, disabled: false }, Validators.required],
      dscAE: [{ value: null, disabled: false }, Validators.required],
      dscEN: [{ value: null, disabled: false }, Validators.required],
      entityId: [{ value: null, disabled: false }, Validators.required],
      entityNameAE: { value: null, disabled: false },
      entityNameEN: { value: null, disabled: false },
      otherEntityId: { value: null, disabled: true },
      otherEntityNameAE: { value: null, disabled: true },
      otherEntityNameEN: { value: null, disabled: true },
      createdBy: { value: null, disabled: false },
      updatedBy: { value: null, disabled: false },
      createdByUserNameAE: { value: null, disabled: false },
      createdByUserNameEN: { value: null, disabled: false },
      creationDate: { value: null, disabled: false },
      fakeId: { value: null, disabled: false },
      id: { value: null, disabled: false },
    });
  }
}
