<adaa-item-slider class="my-3" [showPerformance]="true">
    <h5 title class="fw-bold py-2">
        {{ "dashboard.strategies_directions_performance" | translate }}
    </h5>

    <img performance src="assets/images/national-strategies/national-strategy.png" alt="" width="200px" height="200px" class="mx-4" />

    @for (direction of directions(); track direction.id) {
        <adaa-national-strategies-perf-card [data]="direction" />
    }
</adaa-item-slider>
