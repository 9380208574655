import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function dateRangeValidator(startControlName: string, endControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const startDate = formGroup.get(startControlName)?.value;
    const endDate = formGroup.get(endControlName)?.value;

    if (!startDate || !endDate) {
      return null; // Valid if one or both dates are not provided
    }

    return startDate <= endDate
      ? null // Valid
      : { [`${startControlName}_${endControlName}_Invalid`]: true }; // Invalid
  };
}
