import { Component, computed, inject, input, signal } from "@angular/core";
import { RouterLink } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs";

import { TranslateTokenPipe } from "../../../../../../../core/pipes";
import { AdaaHelper } from "../../../../../../../core/utils";
import { Constants } from "../../../../../../constants/constants";
import { AdaaBoolean, Language } from "../../../../../../constants/enums";
import { MetricsDetailModelType, PropTypeModelType } from "../../../../../../models";
import { LanguageService, PropertiesService } from "../../../../../../services";
import { FormulaMetricType, NoFormulaMetricType } from "../../_lib/data-entry-diff/types";
import { commonFormatter } from "../../configs";

@Component({
  selector: "adaa-audit-data-entry-screen",
  standalone: true,
  imports: [TranslateModule, TranslateTokenPipe, RouterLink],
  templateUrl: "./audit-data-entry-screen.component.html",
  styleUrl: "./audit-data-entry-screen.component.scss",
})
export class AuditDataEntryScreenComponent {
  readonly languageService = inject(LanguageService);
  private _translateService = inject(TranslateService);
  private _propertiesService = inject(PropertiesService);

  showOnlyNew = input.required<boolean>();
  isAuditTrail = input.required<boolean>();
  newDataentryObject = input<Record<string, never>>();
  oldDataentryObject = input<Record<string, never>>();
  viewOnlyDifferences = input<boolean>(false);

  months = signal<PropTypeModelType[]>([]);
  trends = signal<PropTypeModelType[]>([]);
  ytpCalcs = signal<PropTypeModelType[]>([]);
  frequencies = signal<PropTypeModelType[]>([]);
  measurementUnits = signal<PropTypeModelType[]>([]);
  title = signal<string>("");

  metricDetails = computed(() => {
    const metricDetails = this.newDataentryObject()?.metricDetails;
    return metricDetails as unknown as MetricsDetailModelType[];
  });
  isBounded = computed(() => this.newDataentryObject()?.trend === Constants.CONSTANT_TREND_BOUNDED);
  isNoFormula = computed(() => {
    const metricEntities = this.newDataentryObject()?.metricEntities;
    const dataEntryRequests = this.newDataentryObject()?.dataEntryRequests;
    const formula = this.newDataentryObject()?.formula;

    return !metricEntities && !dataEntryRequests && formula === Constants.FORMULA_STRING.NOFORMULA;
  });
  isFormula = computed(() => {
    const dataEntryRequests = this.newDataentryObject()?.dataEntryRequests ?? [];
    const hasDetailedTargets = this.newDataentryObject()?.hasTarget === AdaaBoolean.N;

    return !this.isNoFormula() && dataEntryRequests.length > 0 && hasDetailedTargets;
  });
  annualActualReadings = computed(() => {
    const object = this.newDataentryObject();
    if (!object) return [];
    if (!object.annualCalculationReadings) return [];
    return object.annualCalculationReadings;
  });
  newAuditedAnnualActualReadings = computed(() => {
    const object = this.newDataentryObject();
    if (!object) return [];
    if (!object.auditedAnnualCalculationReadings) return [];
    return object.auditedAnnualCalculationReadings;
  });
  oldAuditedAnnualActualReadings = computed(() => {
    const object = this.oldDataentryObject();
    if (!object) return [];
    if (!object.auditedAnnualCalculationReadings) return [];
    return object.auditedAnnualCalculationReadings;
  });
  newDataentryRequests = computed(() => {
    if (this.isNoFormula()) {
      return {
        baselineYear: this.newDataentryObject()?.baselineYear,
        metrics: this.newDataentryObject()?.metrics ?? [],
      };
    }

    return {
      baselineYear: this.newDataentryObject()?.baselineYear,
      metrics: this.newDataentryObject()?.dataEntryRequests ?? [],
    };
  });
  oldDataentryRequests = computed(() => {
    if (this.isNoFormula()) {
      return {
        baselineYear: this.oldDataentryObject()?.baselineYear,
        metrics: this.oldDataentryObject()?.metrics ?? [],
      };
    }

    return {
      baselineYear: this.oldDataentryObject()?.baselineYear,
      metrics: this.oldDataentryObject()?.dataEntryRequests ?? [],
    };
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newMetrics = computed(() => this.newDataentryRequests().metrics as any);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  oldMetrics = computed(() => this.oldDataentryRequests().metrics as any);
  metricList = computed(() => {
    const metrics = this.newDataentryRequests().metrics as FormulaMetricType[];
    let metricList: FormulaMetricType["metrics"] = [];
    let maxSize = -1;
    for (const metric of metrics) {
      if (!metric.metrics || metric.metrics.length < 1) continue;
      if (metric.metrics.length > maxSize) {
        maxSize = metric.metrics.length;
        metricList = metric.metrics;
      }
    }

    return metricList.reduce<FormulaMetricType["metrics"]>((acc, curr) => {
      const metric = acc?.find(({ kpiMetricId }) => kpiMetricId === curr.kpiMetricId);
      if (!metric) acc?.push(curr);
      return acc;
    }, []);
  });
  frequency = computed(() => {
    if (AdaaHelper.isDefined(this.newDataentryObject()?.frequency)) {
      return this.newDataentryObject()?.frequency;
    }
    return 0;
  });
  indexesToShow = computed(() => {
    const years = this.newMetrics().map(({ year }: { year: number }) => year);
    const set = Array.from(new Set(years));
    const affected = [];
    for (const item of set) {
      const found = this.newMetrics().find(({ year }: { year: number }) => item === year);
      affected.push(found);
    }
    return affected;
  });

  readonly isDefinedAndNotEmpty = AdaaHelper.isDefinedAndNotEmpty.bind(AdaaHelper);
  readonly spanRows = (year: number) => {
    const num = this.newMetrics()?.filter((m: { year: number }) => m.year === year);
    return num.length;
  };
  readonly shouldShowRow = (date: number) => {
    const indexesToShow = this.indexesToShow();
    const found = indexesToShow.find((m) => m.date === date);
    return !!found;
  };

  public ngOnInit() {
    this._fetchConstants();
  }

  private _fetchConstants() {
    const constantIds = [
      Constants.CONSTANT_MEASUREMENT_UNIT,
      Constants.CONSTANT_FREQUENCY,
      Constants.CONSTANT_TREND,
      Constants.CONSTANT_YTP,
      Constants.CONSTANT_MONTHS,
    ];

    this._propertiesService
      .getPropByIdList(constantIds)
      .pipe(filter((res) => !res.inError))
      .subscribe({
        next: (res) => {
          this.ytpCalcs.set(res.responseData.filter(({ propType }) => propType === Constants.CONSTANT_YTP));
          this.trends.set(res.responseData.filter(({ propType }) => propType === Constants.CONSTANT_TREND));
          this.months.set(res.responseData.filter(({ propType }) => propType === Constants.CONSTANT_MONTHS));
          this.frequencies.set(res.responseData.filter(({ propType }) => propType === Constants.CONSTANT_FREQUENCY));
          this.measurementUnits.set(
            res.responseData.filter(({ propType }) => propType === Constants.CONSTANT_MEASUREMENT_UNIT)
          );
        },
      });
  }

  public getKpitypeName(kpiType: number) {
    return {
      [Constants.CONSTANT_NKPITYPE]: "nkpi",
      [Constants.CONSTANT_SKPITYPE]: "skpi",
      [Constants.CONSTANT_MOKPITYPE]: "mokpi",
      [Constants.CONSTANT_MTKPITYPE]: "mtkpi",
      [Constants.CONSTANT_NTKPITYPE]: "ntkpi",
      [Constants.CONSTANT_DTKPITYPE]: "dtkpi",
      [Constants.CONSTANT_OPM]: "opm",
      [Constants.CONSTANT_UAEKPI]: "uae",
      [Constants.CONSTANT_EKPI]: "ekpi",
      [Constants.CONSTANT_SRVKPI]: "srvkpi",
    }[kpiType];
  }

  public getAnnualActual(year: number) {
    const data = this.annualActualReadings() as Record<string, unknown>[];
    const reading = data.find((item) => item.year === year);
    if (!reading) return;
    if (reading.ignored) return "N/A";
    return reading.actual;
  }

  public showDiff(year: number, item: "comment" | "auditedActual") {
    const newData = this.getAuditedAnnualReading(year, { item, type: "new" });
    const oldData = this.getAuditedAnnualReading(year, { item, type: "old" });

    return newData !== oldData;
  }

  public getAuditedAnnualReading(year: number, opts: { type: "new" | "old"; item: "comment" | "auditedActual" }) {
    let data: Record<string, unknown>[];
    if (opts.type === "new") {
      data = this.newAuditedAnnualActualReadings();
    } else {
      data = this.oldAuditedAnnualActualReadings();
    }
    const reading = data.find((item) => item.year === year);
    if (!reading) return;
    if (opts.item === "auditedActual") {
      if (reading.ignored) return "N/A";
      return reading.auditedActual;
    }
    return reading.comment ?? "";
  }

  public getNoFormulaPeriodLabel(metric: NoFormulaMetricType) {
    if (this.frequency() === Constants.FREQUENCY_MONTHLY) {
      return commonFormatter.monthly(metric.date) + " " + metric.year;
    }

    if (this.frequency() === Constants.FREQUENCY_QUARTERLY) {
      switch (this.languageService.current()) {
        case Language.English:
          return `${this._translateService.instant(`targets.periods.quarters.${metric.quarter}`)} ${metric.year}`;
        default:
          return `${this._translateService.instant("targets.quarter")} ${metric.quarter} ${metric.year}`;
      }
    }

    if (this.frequency() === Constants.FREQUENCY_SEMIANNUAL) {
      switch (this.languageService.current()) {
        case Language.Arabic:
          return `${this._translateService.instant(`targets.periods.semestral.${metric.semester}`)} ${metric.year}`;
        default:
          return `${this._translateService.instant("targets.semiannual")} ${metric.semester} ${metric.year}`;
      }
    }

    return metric.year;
  }

  public isBaselineMetric(year: number) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.newDataentryObject()?.metrics ?? ([] as any[])).find((e) => e.year === year).isBaselineTarget;
  }

  public formatFromConstants(key: "measurement" | "freq" | "ytp" | "trend" | "month", id: number) {
    switch (key) {
      case "freq": {
        const freq = this.frequencies().find((e) => e.id === id);
        return freq ? AdaaHelper.getItemValueByToken(freq, "name") : "";
      }

      case "measurement": {
        const measurement = this.measurementUnits().find((e) => e.id === id);
        return measurement ? AdaaHelper.getItemValueByToken(measurement, "name") : "";
      }

      case "ytp": {
        const ytp = this.ytpCalcs().find((e) => e.id === id);
        return ytp ? AdaaHelper.getItemValueByToken(ytp, "name") : "";
      }

      case "trend": {
        const trend = this.trends().find((e) => e.id === id);
        return trend ? AdaaHelper.getItemValueByToken(trend, "name") : "";
      }

      case "month": {
        const month = this.months().find((e) => e.id === id);
        return month ? AdaaHelper.getItemValueByToken(month, "name") : "";
      }
    }
  }

  public getFormula(formula: string) {
    if (formula === Constants.FORMULA_STRING.NOFORMULA) return "skpi.no_formula";
    return formula;
  }

  public getInfo(metricType: string) {
    this.title.set(
      this._translateService.instant(metricType === Constants.METRICS_TYPE.LOCAL ? "metrics.local" : "metrics.global")
    );
  }
}
