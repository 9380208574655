import { HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import {
  AggregationGraphModelType,
  AggregationPerfModelType,
  GovEnablerDirectionsModelType,
  GovEnablerModelType,
  GovKpiVisionModelType,
  MainResponseModel,
  ObjectTypeAnnualPerformanceType,
  PerfColorCodeModelType,
  PerformanceConfigType,
  PerformanceOverYearModelType,
  SDGModelType,
  VisionDirectionModelType,
} from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class CalculationEngineApiService {
  private _mainService = inject(MainService);

  public getColorsLegend() {
    const url = "calculationengine/performancecolors";
    return this._mainService.sendRequest<MainResponseModel<PerfColorCodeModelType[]>>({
      url,
      method: "GET",
    });
  }

  public getGovDirections({ period, isAnnual = false }: { period: number; isAnnual?: boolean }) {
    const url = `${isAnnual ? "calculationreadingannual" : "calculationreading"}/directions/${period}`;

    return this._mainService.sendRequest<MainResponseModel<GovEnablerDirectionsModelType[]>>({
      method: "GET",
      url,
    });
  }

  public getGovEnablerPillars({ period, isAnnual = false }: { period: number; isAnnual?: boolean }) {
    const url = `${isAnnual ? "calculationreadingannual" : "calculationreading"}/pillars/${period}`;

    return this._mainService.sendRequest<MainResponseModel<GovEnablerModelType>>({
      method: "GET",
      url,
    });
  }

  public getVisionDirections({ period, isAnnual = false }: { period: number; isAnnual?: boolean }) {
    const url = `${isAnnual ? "calculationreadingannual" : "calculationreading"}/visions/${period}`;

    return this._mainService.sendRequest<MainResponseModel<VisionDirectionModelType[]>>({
      method: "GET",
      url,
    });
  }

  public getVisionDirectionsV2({ period, isAnnual = false }: { period: number; isAnnual?: boolean }) {
    const url = `${isAnnual ? "calculationreadingannual" : "calculationreading"}/visionsV2/${period}`;

    return this._mainService.sendRequest<MainResponseModel<VisionDirectionModelType[]>>({
      method: "GET",
      url,
    });
  }

  public getPMOPerformance({
    period,
    isAnnual = false,
    overallPerformanceContributing = false,
    entitySize = undefined,
    entityType = undefined,
  }: {
    period: number;
    isAnnual?: boolean;
    overallPerformanceContributing?: boolean;
    entitySize?: number;
    entityType?: number;
  }) {
    const url = `${isAnnual ? "calculationreadingannual" : "calculationreading"}/${entitySize || entityType ? "calculateGovPerformances" : "getGovernmentPerformance"}/${period}`;

    const qs = new URLSearchParams();
    if (entityType) qs.append("entityType", entityType.toString());
    if (entitySize) qs.append("entitySize", entitySize.toString());
    if (overallPerformanceContributing) qs.append("overallPerformanceContributing", "true");

    return this._mainService.sendRequest<MainResponseModel<AggregationPerfModelType[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getEntityPerformance({
    entityId,
    period,
    isAnnual = false,
    overallPerformanceContributing = false,
  }: {
    period: number;
    entityId: number;
    isAnnual?: boolean;
    overallPerformanceContributing?: boolean;
  }) {
    const url = `${isAnnual ? "calculationreadingannual" : "calculationreading"}/getEntityPerformance`;

    const qs = new URLSearchParams();
    if (overallPerformanceContributing) qs.append("overallPerformanceContributing", "true");

    return this._mainService.sendRequest<MainResponseModel<AggregationPerfModelType[]>>({
      url: `${url}/${entityId}/${period}?${qs}`,
      method: "GET",
    });
  }

  public getPerformanceGraph({
    isAnnual = false,
    period = undefined,
    isEnabler = false,
    pillarId = undefined,
    isGovernment = false,
    aggregationType = undefined,
    entitySize = undefined,
    entityType = undefined,
    entityId = undefined,
  }: {
    isAnnual?: boolean;
    period?: number;
    isEnabler?: boolean;
    pillarId?: number;
    isGovernment?: boolean;
    aggregationType?: number;
    entitySize?: number;
    entityType?: number;
    entityId?: number;
  }) {
    const headers = new HttpHeaders();
    const url = `${isAnnual || isEnabler ? "calculationreadingannual" : "calculationreading"}/${isEnabler ? "pillarEntities" : "entities"}`;
    const qs = new URLSearchParams();

    if (pillarId) qs.append("pillarId", pillarId.toString());

    if (aggregationType)
      qs.append(isGovernment ? "governmentAggregationType" : "entityAggregationType", aggregationType.toString());

    if (period) qs.append(isAnnual ? "year" : "periodId", period.toString());

    if (entityType) qs.append("entityType", entityType.toString());

    if (entitySize) qs.append("entitySize", entitySize.toString());

    if (entityId) headers.append("entityId", entityId.toString());

    return this._mainService.sendRequest<MainResponseModel<AggregationGraphModelType[]>>({
      headers: headers,
      url: `${url}?${qs}`,
      method: "GET",
    });
  }

  public getPreformanceOverYears({
    aggregationType = undefined,
    entityId = undefined,
    entitySize = undefined,
    entityType = undefined,
  }: {
    aggregationType?: number;
    entityId?: number;
    entitySize?: number;
    entityType?: number;
  }) {
    let url = `calculationreadingannual${AdaaHelper.isPMOEntity() ? "/governments/yearly" : "/entities/rank"}`;

    if (AdaaHelper.isPMOEntity() && (entitySize || entityType))
      url = `calculationreadingannual/calculateGovernments/yearly`;

    const qs = new URLSearchParams();

    if (aggregationType) qs.append("aggregationType", aggregationType.toString());
    if (entityId) qs.append("entityId", entityId.toString());
    if (entityType) qs.append("entityType", entityType.toString());
    if (entitySize) qs.append("entitySize", entitySize.toString());

    return this._mainService.sendRequest<MainResponseModel<PerformanceOverYearModelType>>({
      url: `${url}?${qs}`,
      method: "GET",
    });
  }

  public getSDG({ period, isAnnual = false }: { period: number; isAnnual?: boolean }) {
    const url = `${isAnnual ? "calculationreadingannual" : "calculationreading"}/sdg/${period}`;

    return this._mainService.sendRequest<MainResponseModel<SDGModelType>>({
      method: "GET",
      url,
    });
  }

  public getCalculationReading({
    itemId,
    objectType,
    periodId,
  }: {
    itemId: number;
    objectType: number;
    periodId: number;
  }) {
    const url = `calculationreading/get/${itemId}/${objectType}/${periodId}`;

    return this._mainService
      .sendRequest<MainResponseModel<ObjectTypeAnnualPerformanceType>>({
        method: "GET",
        url,
        ignoreNotifications: true,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getCalculationReadingAnnual({
    itemId,
    objectType,
    year,
  }: {
    itemId: number;
    objectType: number;
    year: number;
  }) {
    const url = `calculationreadingannual/get/${itemId}/${objectType}/${year}`;

    return this._mainService
      .sendRequest<MainResponseModel<ObjectTypeAnnualPerformanceType>>({
        method: "GET",
        url,
        ignoreNotifications: true,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getGovPerformanceConfig() {
    const url = `performanceconfigdetail/government`;

    return this._mainService.sendRequest<MainResponseModel<PerformanceConfigType[]>>({
      method: "GET",
      url,
    });
  }

  public getEntityPerformanceConfig(entityId: number) {
    const url = `performanceconfigdetail/entity/${entityId}`;

    return this._mainService.sendRequest<MainResponseModel<PerformanceConfigType[]>>({
      method: "GET",
      url,
    });
  }

  public performanceColors() {
    const url = `calculationengine/performancecolors`;

    return this._mainService
      .sendRequest<MainResponseModel<PerfColorCodeModelType[]>>({
        method: "GET",
        url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getKpiVisionData({
    kpiType,
    govDirId,
    periodId,
    subGovDirId,
  }: {
    kpiType: number;
    govDirId: number;
    periodId: number;
    subGovDirId?: number;
  }) {
    const url = `calculationreading/kpiVisions`;
    const qs = new URLSearchParams({
      kpiType: `${kpiType}`,
      govDirId: `${govDirId}`,
      periodId: `${periodId}`,
    });

    if (subGovDirId) qs.set("subGovDirId", `${subGovDirId}`);

    return this._mainService
      .sendRequest<MainResponseModel<GovKpiVisionModelType[]>>({
        method: "GET",
        url: `${url}?${qs.toString()}`,
      })
      .pipe(filter((res) => !res.inError));
  }
}
