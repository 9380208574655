import { inject, Injectable } from "@angular/core";

import type { MainResponseModel, PropTypeModelType } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class PropertiesService {
  private _mainService = inject(MainService);

  public getPropById(propType: number) {
    const url = `prop/getbyid/${propType}`;

    return this._mainService.sendRequest<MainResponseModel<PropTypeModelType[]>>({
      method: "GET",
      url,
    });
  }

  public getPropByIdList(propTypes: number[]) {
    const url = `prop/getPropByIdList`;

    return this._mainService.sendRequest<MainResponseModel<PropTypeModelType[]>>({
      method: "POST",
      url,
      data: propTypes,
    });
  }

  public getAllWorkflowTypes() {
    const url = `prop/getAllWorkflowTypes`;

    return this._mainService.sendRequest<MainResponseModel<PropTypeModelType[]>>({
      method: "GET",
      url,
    });
  }

  public getAllItemTypes() {
    const url = `prop/getAllItemTypesV2`;

    return this._mainService.sendRequest<MainResponseModel<PropTypeModelType[]>>({
      method: "GET",
      url,
    });
  }

  public getAllPermittedKpiTypes() {
    const url = `prop/getAllPermittedKpiTypes`;

    return this._mainService.sendRequest<MainResponseModel<PropTypeModelType[]>>({
      method: "GET",
      url,
    });
  }
}
