<section class="row d-flex align-content-center mt-2">
    <div class="col-4 p-2 border border-white tbl-col rounded-start-1 bg-white text-primary fw-bold text-center">
        {{ "communication.difference.key" | translate }}
    </div>

    <div
        class="p-2 border border-white tbl-col bg-white text-primary fw-bold text-center"
        [class.rounded-end-1]="showOnlyNew()"
        [class.col-6]="showOnlyNew()"
        [class.col-4]="!showOnlyNew()"
    >
        {{ !showOnlyNew() ? ("communication.difference.new_value" | translate) : ("communication.difference.value" | translate) }}
    </div>

    @if (!showOnlyNew()) {
        <div class="col-4 p-2 border border-white tbl-col rounded-end-1 bg-white text-primary fw-bold text-center">
            {{ "communication.difference.old_value" | translate }}
        </div>
    }

    @for (section of config.sections; track $index) {
        @switch (section.view) {
            @case ("normal") {
                <adaa-normal-diff-view
                    [isNew]="isNew()"
                    [config]="section"
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newObject()"
                    [oldData]="oldObject()"
                    [showDIffOnly]="viewOnlyDifferences()"
                    (clicked)="download($event)"
                />
            }
            @case ("attachment") {
                <adaa-attachment-diff-view
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newObject()!.attachments"
                    [oldData]="oldObject()!.attachments"
                    [showDIffOnly]="viewOnlyDifferences()"
                />
            }
            @case ("ext-fields") {
                <adaa-extended-fields-diff
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newObject()?.attrs ?? []"
                    [oldData]="oldObject()?.attrs ?? []"
                    [showDIffOnly]="viewOnlyDifferences()"
                />
            }

            @case ("list") {
                <adaa-list-diff-view
                    [config]="$asListConfig(section)"
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newObject()!"
                    [oldData]="oldObject()!"
                    [showDIffOnly]="viewOnlyDifferences()"
                />
            }

            @case ("accordion") {
                <adaa-accordion-diff-view
                    [config]="$asAccordionConfig(section)"
                    [showOnlyNew]="showOnlyNew()"
                    [isNew]="isNew()"
                    [newData]="newObject()!"
                    [oldData]="oldObject()!"
                    [props]="props()"
                    [showDIffOnly]="viewOnlyDifferences()"
                />
            }
            @case ("activity-workloads") {
                <adaa-activity-workload-diff-view
                    [config]="section"
                    [itemTypeId]="newObject()?.itemTypeId"
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="newObject()?.workloads ?? []"
                    [oldData]="oldObject()?.workloads ?? []"
                />
            }
            @case ("linked-national-strategies") {
                <adaa-linked-national-strategies-diff-view
                    [config]="section"
                    [planId]="newObject()?.planId"
                    [itemTypeId]="newObject()?.itemTypeId"
                    [showOnlyNew]="showOnlyNew()"
                    [newData]="{
                        govDirections: newObject()?.govDirections ?? [],
                        govSubDirections: newObject()?.govSubDirections ?? [],
                    }"
                    [oldData]="{
                        govDirections: oldObject()?.govDirections ?? [],
                        govSubDirections: oldObject()?.govSubDirections ?? [],
                    }"
                />
            }
        }
    }
</section>
