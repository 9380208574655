import { Component, computed, inject, input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import type { SelectedPeriodModelType } from "../../../models";
import { NationalStrategiesPerfService } from "../../../services/national-strategies-perf.service";
import { ItemSliderComponent } from "../item-slider/item-slider.component";
import { NationalStrategiesPerfCardComponent } from "../national-strategies-perf-card/national-strategies-perf-card.component";

@Component({
  selector: "adaa-national-strategies-performance",
  standalone: true,
  templateUrl: "./national-strategies-performance.component.html",
  styleUrl: "./national-strategies-performance.component.scss",
  imports: [TranslateModule, FontAwesomeModule, NationalStrategiesPerfCardComponent, ItemSliderComponent],
})
export class NationalStrategiesPerformanceComponent {
  private readonly _nationalStrategiesPerfService = inject(NationalStrategiesPerfService);

  selectedPeriod = input<SelectedPeriodModelType>();

  directions = computed(() => this._nationalStrategiesPerfService.directions());

  public get icon() {
    return {
      leftArrow: faArrowLeftLong,
      rightArrow: faArrowRightLong,
    };
  }
}
