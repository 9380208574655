import {
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  isDevMode,
  type OnDestroy,
  type OnInit,
  Output,
  signal,
} from "@angular/core";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faArrowRightArrowLeft,
  faBars,
  faBell,
  faGear,
  faPowerOff,
  faQuestion,
  faSearch,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { interval, map, Subscription, switchMap } from "rxjs";

import { TranslateTokenPipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { hasBadWord } from "../../../../../pages/search/search.utils";
import { Language } from "../../../../constants/enums";
import {
  CommunicationsApiService,
  LanguageService,
  LogoutService,
  SystemLayoutService,
  UserService,
} from "../../../../services";
import { EntitySelectorComponent, PeriodSelectorComponent } from "./selectors";
import { ActivityBarComponent } from "./ui/activity-bar.component";

@Component({
  selector: "adaa-topbar",
  standalone: true,
  templateUrl: "./topbar.component.html",
  styleUrl: "./topbar.component.scss",
  imports: [
    TranslateModule,
    FontAwesomeModule,
    NgbDropdownModule,
    TranslateTokenPipe,
    EntitySelectorComponent,
    PeriodSelectorComponent,
    ActivityBarComponent,
    RouterLink,
    RouterLinkActive,
  ],
})
export class TopbarComponent implements OnInit, OnDestroy {
  readonly systemLayoutService = inject(SystemLayoutService);
  readonly logoutService = inject(LogoutService);
  private readonly _router = inject(Router);
  private readonly _userService = inject(UserService);
  private readonly _toastrService = inject(ToastrService);
  private readonly _languageService = inject(LanguageService);
  private readonly _translateService = inject(TranslateService);
  private readonly _communicationsApiService = inject(CommunicationsApiService);

  direction = input<"ltr" | "rtl">(AdaaHelper.getCurrentLang() === Language.Arabic ? "ltr" : "rtl");

  @Output() toggleSidebar = new EventEmitter<void>();

  messages = signal<number>(0);

  hasNotifications = computed<boolean>(() => {
    const count = this.messages();
    if (!count) {
      return false;
    }
    return count > 0;
  });

  currentUser = computed(() => this._userService.currentUser());
  count = computed(() => {
    const hasMessages = this.hasNotifications();
    if (!hasMessages) return "";
    const count = this.messages();
    if (count > 100) return "99+";
    return `${count}`;
  });

  private _subscription = new Subscription();

  public get icon() {
    return {
      search: faSearch,
      helpdesk: faQuestion,
      notification: faBell,
      user: faUserLock,
      switchLang: faArrowRightArrowLeft,
      logout: faPowerOff,
      menu: faBars,
      options: faGear,
    };
  }

  public ngOnInit() {
    this._userService.fetchUser();
    this._getUnreadMessages();
  }

  public ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  public switchLanguage(): void {
    this._languageService.switchLanguage();
    location.reload();
  }

  public searchButtonClicked(el: HTMLInputElement) {
    const searchTerm = this.systemLayoutService.searchTerm();
    if (!searchTerm || searchTerm.length < 3) {
      this._toastrService.warning(this._translateService.instant("notification.warning.text_search_length"));
      el.focus();
      return;
    }
    this._router.navigate(["/console", "search"], { queryParams: { s: searchTerm } });
  }

  public autoUpdateSearchTerm($e: Event) {
    const target = $e.target as unknown;
    const value = (target as Record<string, string | null>).value;
    if (hasBadWord(value ?? "")) {
      this._toastrService.warning(this._translateService.instant("search.stop_words_message"));
      return;
    }
    this.systemLayoutService.searchTerm.set(value);
  }

  public performSearch($e: Event) {
    $e.preventDefault();
    this.autoUpdateSearchTerm($e);
    const searchTerm = this.systemLayoutService.searchTerm();
    if (!searchTerm || searchTerm.length < 3) {
      this._toastrService.warning(this._translateService.instant("notification.warning.text_search_length"));
      return;
    }
    this._router.navigate(["/console", "search"], { queryParams: { s: searchTerm } });
  }

  private _getMessageCount() {
    this._subscription.add(
      interval(isDevMode() ? 1000 * 10 * 60 : 1000 * 10 * 6)
        .pipe(
          switchMap(() => this._communicationsApiService.countUnreadMessages({ useLoader: false })),
          map((res) => {
            if (res.inError) return 0;
            return res.responseData ?? 0;
          })
        )
        .subscribe({
          next: (count) => this.messages.set(count),
        })
    );
  }

  private _getUnreadMessages() {
    this._communicationsApiService.countUnreadMessages({ useLoader: true }).subscribe({
      next: (res) => {
        const count = res.inError ? 0 : (res.responseData ?? 0);
        this.messages.set(count);
        this._getMessageCount();
      },
    });
  }
}
