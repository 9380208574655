import { inject, Injectable, signal } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import { Direction, Language } from "../constants/enums";
import { TabsService } from "./tabs.service";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private _translateService = inject(TranslateService);
  private _tabsService = inject(TabsService);

  hasChanged$ = this._translateService.onLangChange.asObservable().pipe(map((event) => event.lang as Language));

  current = signal<Language>(Language.Arabic);

  readonly translate = this._translateService.instant.bind(this._translateService);

  readonly direction = () => {
    return AdaaHelper.getLocalStorage(Constants.localStorageKeys.language) === Language.English
      ? Direction.LTR
      : Direction.RTL;
  };

  modalDirection = () => (this.current() === Language.Arabic ? "modal-rtl" : "modal-ltr");

  public initLang(language: Language): void {
    this._translateService.setDefaultLang(language);
    this.current.set(language);
  }

  public changeToEN() {
    this._translateService.setDefaultLang(Language.English);
    this.current.set(Language.English);
  }

  public changeToAE() {
    this._translateService.setDefaultLang(Language.Arabic);
    this.current.set(Language.Arabic);
  }

  public switchLanguage(): void {
    const currentLang = this.current();

    switch (currentLang) {
      case Language.Arabic: {
        this.changeToEN();
        break;
      }
      case Language.English: {
        this.changeToAE();
        break;
      }
    }

    //Reload all active tabs
    this._tabsService.reloadOtherTabs();

    AdaaHelper.setLocalStorage(Constants.localStorageKeys.language, this.current(), "string");
    this._translateService.use(this.current());
  }
}
