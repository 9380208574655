import { AdaaHelper } from "../../../../../../../core/utils";
import { SharedFields } from "../../configs";
import {
  AccordionSectionConfigType,
  ListSectionConfigType,
  ScreenSectionConfigType,
  ScreenType,
} from "../../wf-difference.types";
import { isActivity, isService, isTpAggreementReadyToPublish } from "../../wf-difference.utils";
import {
  activitiesBudgetEnabledField,
  activityWorkloadsField,
  budgetField,
  descriptionField,
  expensesField,
  highPriorityField,
  highPriorityJustificationField,
  hyperiondRefCodeField,
  initiativeManagerNameField,
  initiativesBudgetEnabledField,
  khadhamatiField,
  linkedNationalStrategiesField,
  linkedToNationalStrategiesField,
  monitoredField,
  objectiveIdField,
  progressField,
  progressManagerNameField,
  readyToPublishField,
  successFactorsField,
} from "./fields";

export const simpleScreenConfig = [
  objectiveIdField,
  ...SharedFields.name,
  ...descriptionField,
  {
    ...SharedFields.startDate,
    visibleIf: (data: { itemTypeId: number }) => !isTpAggreementReadyToPublish(data.itemTypeId),
  },
  {
    ...SharedFields.endDate,
    visibleIf: (data: { itemTypeId: number }) => !isTpAggreementReadyToPublish(data.itemTypeId),
  },
  readyToPublishField,
  {
    ...SharedFields.serviceField,
    visibleIf: (data: { typeId: number }) => isService(data.typeId),
  },
  khadhamatiField,
  {
    ...SharedFields.initiativeNameField,
    visibleIf: (data: { itemTypeId: number }) => isActivity(data.itemTypeId),
  },
  SharedFields.projectTypeField,
  {
    ...SharedFields.orgUnitIdField,
    visibleIf: (data: { itemTypeId: number }) => !isTpAggreementReadyToPublish(data.itemTypeId),
  },
  {
    ...SharedFields.ownerField,
    visibleIf: (data: { itemTypeId: number }) => !isTpAggreementReadyToPublish(data.itemTypeId),
  },
  highPriorityField,
  linkedToNationalStrategiesField,
  highPriorityJustificationField,
  monitoredField,
  initiativeManagerNameField,
  progressManagerNameField,
  initiativesBudgetEnabledField,
  activitiesBudgetEnabledField,
  budgetField,
  expensesField,
  progressField,
  hyperiondRefCodeField,
  SharedFields.commentField,
  successFactorsField,
  activityWorkloadsField,
  linkedNationalStrategiesField,
  SharedFields.extendedFields,
  SharedFields.attachmentsField,
];

const colorField: ScreenSectionConfigType = {
  field: "iconColor",
  label: "government_scores.sectors.color",
  view: "normal",
};

const iconAttachField: ScreenSectionConfigType = {
  field: "iconAttach",
  label: "government_scores.sectors.icon",
  view: "normal",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format: ($o) => ($o as any).iconAttach?.filename,
  isClickable: true,
};

const pillarTypeField: ScreenSectionConfigType = {
  field: "pillarType",
  label: "government_scores.pillars.pillar_type",
  view: "normal",
  format: ($o) => AdaaHelper.getItemValueByToken($o, "pillarTypeName"),
};

const weightField: ScreenSectionConfigType = {
  field: "weight",
  label: "nkpi.weight",
  view: "normal",
};

const executiveSummaryField: ScreenSectionConfigType = {
  field: "summary",
  label: "executive_dashboard.title",
  view: "normal",
};

const areaOfFocusSectionConfig = [
  ...SharedFields.name.map((x: ScreenSectionConfigType) => ({
    ...x,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    format: ($o: any) => AdaaHelper.getItemValueByToken($o, "areaName"),
  })),
  ...SharedFields.description.map((x: ScreenSectionConfigType) => ({
    ...x,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    format: ($o: any) => AdaaHelper.getItemValueByToken($o, "areaDsc"),
  })),
];

const areaOfFocusField: AccordionSectionConfigType = {
  view: "accordion",
  field: "areasOfFocusList",
  title: ($o) => AdaaHelper.getItemValueByToken($o, "areaName"),
  idKey: "id",
  config: areaOfFocusSectionConfig,
  label: "government_scores.pillars.area_of_focus.title",
};

const involvedEntitiesField: ListSectionConfigType = {
  field: "involvedEntities",
  label: "cycle.connected_entities",
  view: "list",
  idKey: "id",
  format: ($o) => AdaaHelper.getItemValueByToken($o, "name"),
};

export const govSectorsAuditScreenConfig = {
  type: "simple" as ScreenType,
  sections: [...SharedFields.name, ...descriptionField, colorField, iconAttachField, involvedEntitiesField],
};

export const entityPillarsAuditScreenConfig = {
  type: "simple" as ScreenType,
  sections: [
    ...SharedFields.name,
    ...descriptionField,
    SharedFields.entityIdField,
    pillarTypeField,
    weightField,
    executiveSummaryField,
    areaOfFocusField,
  ],
};
