import { Component, computed, EventEmitter, inject, Input, input, OnInit, Output, signal } from "@angular/core";
import { NgxSliderModule, Options } from "@angular-slider/ngx-slider";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { Language } from "../../../constants/enums";
import { PeriodModelType, SelectedPeriodModelType } from "../../../models";
import { LanguageService, PeriodApiService } from "../../../services";
import { CHART_FREQUENCY_CYCLES, ChartFrequencyOptionType, PeriodSlotType } from "./utils";

@Component({
  selector: "adaa-period-slider-variant",
  standalone: true,
  imports: [NgxSliderModule, TranslateModule],
  templateUrl: "./period-slider-variant.component.html",
  styleUrl: "./period-slider-variant.component.scss",
})
export class PeriodSliderVariantComponent implements OnInit {
  @Input() public set _startDate(date: number | undefined) {
    this.startDate.set(date);
  }
  @Input() public set _endDate(date: number | undefined) {
    this.endDate.set(date);
  }
  @Input() public set _frequency(freq: number) {
    this.frequency.set(freq);
  }
  @Input() public set _stepRange(stepRange: number) {
    this.stepRange.set(stepRange);
  }

  @Output() selectedChartFrequencyCycle = new EventEmitter<string>();
  @Output() selectPeriod = new EventEmitter<Pick<SelectedPeriodModelType, "period">>();

  private readonly _languageService = inject(LanguageService);
  private readonly _periodApiService = inject(PeriodApiService);

  emitOnInit = input<boolean>(true);
  activePeriod = input<Pick<SelectedPeriodModelType, "period">>();
  periodId = input<number | undefined>(undefined);
  showChartCycle = input<boolean>(true);
  kpiType = input<number>(0);
  isEKPI = input<boolean>(false);
  showMonthsAsNumbers = input<boolean>(false);
  showMonths = input<boolean>(true);
  boldYears = input<boolean>(false);

  stepRange = signal<number>(6);
  isReady = signal<boolean>(false);
  startDate = signal<number | undefined>(undefined);
  endDate = signal<number | undefined>(undefined);
  frequency = signal<number>(Constants.FREQUENCY.MONTHLY);
  disableSlider = signal<boolean>(false);
  periodList = signal<PeriodModelType[]>([]);
  actualPeriod = signal<PeriodModelType | undefined>(undefined);
  chartFrequencyCycles = signal<Pick<ChartFrequencyOptionType, "id" | "label">[]>([]);
  activeChartFrequencyCycle = signal<string>(Constants.CHART_FREQUENCY_OPTION.ONE_YEAR);
  periodSlots = signal<PeriodSlotType[]>([]);
  sliderOptions = signal<Options>({
    showTicksValues: false,
    showTicks: true,
    rightToLeft: this._languageService.current() === Language.Arabic,
    stepsArray: [],
  });

  selectedPeriod = computed(() => {
    const period = this.activePeriod()?.period ?? this.actualPeriod();
    return period?.id;
  });

  readonly isActive = (id: string) => this.activeChartFrequencyCycle() === id;

  readonly #STEPS_OFFSET = 1;

  public ngOnInit() {
    this._getChartFrequencyCycles();
    this.initPeriods(true);
  }

  public recalibrate(
    data: Partial<{
      startDate: number;
      endDate: number;
      frequency: number;
      stepRange: number;
    }>
  ) {
    if (data.startDate) this.startDate.set(data.startDate);
    if (data.endDate) this.endDate.set(data.endDate);
    if (data.frequency) this.frequency.set(data.frequency);
    if (data.stepRange) this.stepRange.set(data.stepRange);

    this.initPeriods(false);
  }

  public selectChartCycle(id: string) {
    this.activeChartFrequencyCycle.set(id);
    this.selectedChartFrequencyCycle.emit(id);
  }

  public periodChanged($event: number, recalculateSteps = true) {
    const steps = this.sliderOptions().stepsArray!;
    const period = this.periodList().find(({ id }) => id === $event);
    const stepIndex = this.sliderOptions()?.stepsArray?.findIndex(({ value }) => value === $event) as number;

    if (!period) return;

    this.selectPeriod.emit({ period });

    const lastStepIndex = steps.length - 1;
    const canLeftRecalculate = stepIndex <= this.#STEPS_OFFSET;
    const canRightRecalculate = stepIndex <= lastStepIndex && stepIndex >= steps.length - this.#STEPS_OFFSET;

    if (recalculateSteps && (canLeftRecalculate || canRightRecalculate)) {
      this._calculateSteps(period, this.periodSlots());
    }
  }

  public initPeriods(onInit: boolean) {
    this._periodApiService.getActualPeriod({ useLoader: true }).subscribe({
      next: (res) => this.actualPeriod.set(res.responseData),
      complete: () => this._getDateInterval(onInit),
    });
  }

  private _getChartFrequencyCycles() {
    const data = {
      kpiType: this.kpiType(),
      frequency: this.frequency(),
    };

    const list = CHART_FREQUENCY_CYCLES.filter(({ visibleIf }) => visibleIf(data));
    switch (list.length) {
      case 0: {
        this.disableSlider.set(true);
        return;
      }
      case 1: {
        this.selectChartCycle(list[0].id);
        break;
      }
      default: {
        this.selectChartCycle(list[list.length - 1].id);
        break;
      }
    }

    this.chartFrequencyCycles.set([...list]);
  }

  private _getDateInterval(onInit: boolean) {
    const currentPlan = AdaaHelper.plan;
    const input = {
      startTS: this.startDate() ?? currentPlan?.startYear,
      endTS: this.endDate() ?? currentPlan?.endYear,
      frequency: this.frequency(),
      useLoader: true,
    };

    this._periodApiService.getDateRange(input).subscribe({
      next: (res) => this.periodList.set([...res.responseData]),
      complete: () => {
        this._generateSlots();

        let period: PeriodModelType | undefined;
        if (onInit && this.periodId()) {
          period = this.periodList().find(({ id }) => id === this.periodId());
        }
        if (!period) {
          period = this.activePeriod()?.period ?? this.actualPeriod()!;
        }

        this._calculateSteps(period, this.periodSlots());
        this.isReady.set(true);
        if (this.emitOnInit()) this.periodChanged(period.id, false);
      },
    });
  }

  private _generateSlots() {
    switch (this.frequency()) {
      case Constants.FREQUENCY.ANNUAL: {
        this._generateAnnualSlots();
        break;
      }

      case Constants.FREQUENCY.QUARTERLY: {
        this._generateQuarterlySlots();
        break;
      }

      case Constants.FREQUENCY.SEMIANNUAL: {
        this._generateSemiAnnualSlots();
        break;
      }

      default: {
        this._generateMonthlySlots();
        break;
      }
    }
  }

  private _generateAnnualSlots() {
    const slots: PeriodSlotType[] = this.periodList()
      .filter(({ month }) => month === 12)
      .map((period) => {
        const locale = this._languageService.current() === Language.Arabic ? "ar-AE" : "en-GB";
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          timeZone: Constants.uaeTimezoneName,
        };

        if (this.showMonths()) {
          options.month = this.showMonthsAsNumbers() ? "2-digit" : "short";
        }

        const df = new Intl.DateTimeFormat(locale, options);

        return {
          periodId: period.id,
          label: df.format(AdaaHelper.getDubaiTime(period.date)),
          year: period.year,
          month: period.month,
          quarter: period.quarter,
          semester: period.semester,
        };
      });

    this.periodSlots.set([...slots]);
  }

  private _generateSemiAnnualSlots() {
    const slots: PeriodSlotType[] = this.periodList()
      .filter(({ month }) => month === 12 || month === 9 || month === 6 || month === 3)
      .map((period) => {
        const locale = this._languageService.current() === Language.Arabic ? "ar-AE" : "en-GB";
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "2-digit",
          timeZone: Constants.uaeTimezoneName,
        };

        const df = new Intl.DateTimeFormat(locale, options);

        return {
          periodId: period.id,
          label: df.format(AdaaHelper.getDubaiTime(period.date)),
          year: period.year,
          month: period.month,
          quarter: period.quarter,
          semester: period.semester,
        };
      });

    this.periodSlots.set([...slots]);
  }

  private _generateQuarterlySlots() {
    const slots: PeriodSlotType[] = this.periodList()
      .filter(({ month }) => month === 12 || month === 9 || month === 6 || month === 3)
      .map((period) => {
        const locale = this._languageService.current() === Language.Arabic ? "ar-AE" : "en-GB";
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "2-digit",
          timeZone: Constants.uaeTimezoneName,
        };

        const df = new Intl.DateTimeFormat(locale, options);

        return {
          periodId: period.id,
          label: df.format(AdaaHelper.getDubaiTime(period.date)),
          year: period.year,
          month: period.month,
          quarter: period.quarter,
          semester: period.semester,
        };
      });

    this.periodSlots.set([...slots]);
  }

  private _generateMonthlySlots() {
    const slots: PeriodSlotType[] = this.periodList().map((period) => {
      const locale = this._languageService.current() === Language.Arabic ? "ar-AE" : "en-GB";
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        timeZone: Constants.uaeTimezoneName,
      };

      if (this.showMonths()) {
        options.month = this.showMonthsAsNumbers() ? "2-digit" : "short";
      }

      const df = new Intl.DateTimeFormat(locale, options);

      return {
        periodId: period.id,
        label: df.format(AdaaHelper.getDubaiTime(period.date)),
        year: period.year,
        month: period.month,
        quarter: period.quarter,
        semester: period.semester,
      };
    });

    this.periodSlots.set([...slots]);
  }

  private _calculateSteps(activePeriod: PeriodModelType, slots: PeriodSlotType[]) {
    const totalSlots = this.stepRange() * 2;

    if (slots.length === 1) {
      this.sliderOptions.update((options) => ({
        ...options,
        stepsArray: [
          { value: slots[0].periodId, legend: slots[0].label },
          { value: slots[0].periodId, legend: slots[0].label },
        ],
      }));

      return;
    }

    if (slots.length <= totalSlots) {
      this.sliderOptions.update((options) => ({
        ...options,
        stepsArray: slots.map(({ label, periodId }) => ({ value: periodId, legend: label })),
      }));

      return;
    }

    let index = slots.findIndex(({ periodId }) => periodId === activePeriod.id);
    if (index === -1) {
      index = slots.findIndex(({ month }) => month - activePeriod.month === 1);
    }

    const isCloseToStart = index + 1 - 1 < this.stepRange() - 2;
    const isCloseToEnd = slots.length - (index + 1) < this.stepRange() - 1;

    if (isCloseToStart) {
      this.sliderOptions.update((option) => {
        return {
          ...option,
          stepsArray: slots
            .filter((_, i) => i < totalSlots - 1)
            .map((slot) => ({ value: slot.periodId, legend: slot.label })),
        };
      });

      return;
    }

    if (isCloseToEnd) {
      this.sliderOptions.update((option) => {
        const reversedSlots = [...slots].reverse();
        const steps = reversedSlots
          .filter((_, i) => i < totalSlots - 1)
          .map((slot) => ({ value: slot.periodId, legend: slot.label }));

        return {
          ...option,
          stepsArray: steps.reverse(),
        };
      });

      return;
    }

    const lowerLimitIndex = index - this.stepRange();
    const highLimitIndex = index + this.stepRange();

    this.sliderOptions.update((option) => {
      return {
        ...option,
        stepsArray: slots
          .filter((_, i) => {
            if (i === index) return true;
            return i >= lowerLimitIndex && i < highLimitIndex;
          })
          .map((slot) => ({ value: slot.periodId, legend: slot.label })),
      };
    });
  }
}
