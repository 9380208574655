import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: "root",
})
export class InputDateFormatterAdapter extends NgbDateParserFormatter {
  readonly DELIMITER = "/";

  public parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10),
      };
    }
    return null;
  }

  public format(date: NgbDateStruct | null): string {
    if (date) {
      const month = date.month.toString().length > 1 ? date.month : `0${date.month}`;
      const day = date.day.toString().length > 1 ? date.day : `0${date.day}`;

      return `${date.year}${this.DELIMITER}${month}${this.DELIMITER}${day}`;
    } else return "";
  }
}
