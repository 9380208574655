import { Directive, ElementRef, inject, Input } from "@angular/core";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";

import { AdaaHelper } from "../../core/utils";
import { Language } from "../constants/enums";

@Directive({ selector: "[ngbDropdownMenu][matchWidth]", standalone: true })
export class MatchWidthDirective {
  private _dropdown = inject(NgbDropdown);
  private _element = inject(ElementRef);

  @Input() matchWidth: HTMLElement;

  private _untilDestroyed = AdaaHelper.untilDestroyed();

  constructor() {
    this._dropdown.openChange.pipe(this._untilDestroyed()).subscribe((opened) => {
      if (opened && this.matchWidth) {
        this._element.nativeElement.style.width = `${this.matchWidth.clientWidth}px`;
        this._element.nativeElement.dir = AdaaHelper.getCurrentLang() === Language.Arabic ? "rtl" : "ltr";
      }
    });
  }
}
