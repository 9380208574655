import { Component, inject, input, output } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faArrowsRotate, faFilter } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { LanguageService } from "../../../services";

type BaseWidgetData = {
  isDuo: boolean;
};

export type SimpleWidgetData = BaseWidgetData & {
  label: string;
  data: number | string | unknown;
  labelClasses?: string;
  dataClasses?: string;
};

export type ItemListWidgetData = BaseWidgetData & {
  items: { label: string; data: number | string | unknown }[];
};

export type SymbolWidgetData = BaseWidgetData &
  SimpleWidgetData & {
    symbol: string;
  };

export type IconWidgetData = BaseWidgetData &
  SimpleWidgetData &
  SymbolWidgetData & {
    icon: string;
  };

@Component({
  selector: "adaa-ribbon",
  standalone: true,
  imports: [FontAwesomeModule, TranslateModule],
  templateUrl: "./ribbon.component.html",
  styleUrl: "./ribbon.component.scss",
})
export class RibbonComponent {
  readonly languageService = inject(LanguageService);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  widgetData = input.required<(SimpleWidgetData | ItemListWidgetData | SymbolWidgetData | IconWidgetData | any)[]>();
  showLogo = input<boolean>(false);
  showFilterIcon = input<boolean>(false);
  showRefreshIcon = input<boolean>(true);
  filterApplied = input<boolean>(false);

  refreshClicked = output();
  filterClicked = output();

  readonly refreshIcon = faArrowsRotate;
  readonly filterIcon = faFilter;
}
