<div class="row dashboard-stats--container">
    @if (showLogo()) {
        <div class="col-md-4 col-12 dashboard-stats--page-logo ms-2 mt-2">
            <ng-content select="[ribbonLogo]"></ng-content>
        </div>
    }

    <div
        class="dashboard-stats--widgets d-flex align-items-center align-content-center justify-content-evenly flex-wrap col-md-6 col-12"
        [class.full-width]="!showFilterIcon() && !showLogo() && !showRefreshIcon()"
    >
        @for (widget of widgetData(); track $index) {
            @if (!widget.isDuo) {
                <div
                    class="d-flex justify-content-around align-items-center stat-widget"
                    [class.has-icon]="widget.icon"
                    [dir]="languageService.direction()"
                >
                    <span
                        class="stat-label fw-bold py-1 {{ widget.labelClasses }}"
                        [class.d-flex]="widget.icon"
                        [class.flex-nowrap]="widget.icon"
                        [class.align-items-center]="widget.icon"
                        [class.gap-4]="widget.icon"
                        [class.two-items-only]="widgetData().length <= 2"
                    >
                        @if (widget.icon) {
                            <i class="text-primary fs-1 {{ widget.icon }}"></i>
                            <span>{{ widget.label | translate }}</span>
                        } @else {
                            {{ widget.label | translate }}
                        }
                    </span>

                    <span class="stat fw-bold {{ widget.dataClasses }}">
                        @if (!widget.symbol) {
                            {{ widget.data }}
                        } @else {
                            {{ widget.data }} <span class="stat-symbol">{{ widget.symbol }}</span>
                        }
                    </span>
                </div>
            } @else {
                <div
                    class="d-flex align-items-center justify-content-center flex-wrap stat-widget stat-widget--duo"
                    [dir]="languageService.direction()"
                >
                    @for (item of widget.items; track $index) {
                        <div
                            class="w-100 d-flex justify-content-around align-items-center gap-3"
                            [class.border-top]="$last"
                            [class.border-muted]="$last"
                        >
                            <span class="stat-label fw-bold py-1 {{ item.labelClasses }}">{{ item.label | translate }}</span>
                            <span class="stat fw-bold {{ item.dataClasses }}"> {{ item.data }} </span>
                        </div>
                    }
                </div>
            }
        }
        <ng-content select="[progressBars]"></ng-content>
    </div>

    @if (showRefreshIcon() || showFilterIcon()) {
        <div class="dashboard-stats--actions col-md-2 col-12">
            @if (showRefreshIcon()) {
                <button class="btn btn-primary btn-sm" (click)="refreshClicked.emit()">
                    <fa-icon [icon]="refreshIcon" />
                </button>
            }

            @if (showFilterIcon()) {
                <button class="btn btn-primary btn-sm mt-2" [class.btn-info]="filterApplied()" (click)="filterClicked.emit()">
                    <fa-icon [icon]="filterIcon" />
                </button>
            }
        </div>
    }
</div>
