import { Injectable, signal } from "@angular/core";

@Injectable({
  providedIn: null,
})
export class FormulaParserService {
  static readonly GLOBAL_TOKEN = "[" as const;
  static readonly LOCAL_TOKEN = "{" as const;
  static readonly operands = ["*", "/", "%", "+", "-"];
  static readonly globalsOrLocals = ["[", "]", "{", "}"];

  #formula = signal<string>("");

  readonly init = (formula: string) => {
    this.#formula.set(formula);
  };

  readonly isGlobalVar = (token: string) => {
    return token.indexOf(FormulaParserService.GLOBAL_TOKEN) !== -1;
  };

  readonly isLocalVar = (token: string) => {
    return token.indexOf(FormulaParserService.LOCAL_TOKEN) !== -1;
  };

  readonly extractFormulaMetrics = () => {
    let formula = this.#formula();
    if (!formula?.length) return [];

    const tokens: string[] = [];

    //@step[#1]: Remove whitespaces
    formula = formula.replace(/ /g, "");

    //@step[#2]: Handle left parenthesis `(` in the formula
    formula = this.#handleLeftParenthesis(formula);

    //@step[#3]: Split formula into token metrics
    tokens.push(...formula.split(/[*/%+-/^]+/g));

    //@step[#4]: Sanitize & Filter out numbers or constants metrics e.g. 5, 6, 109
    return tokens
      .map((token) => {
        if (token.indexOf("[") === -1) {
          //@note: Removes parenthesis and end spaces
          token = token.trim().replace(/\(/g, "");
          token = token.trim().replace(/\)/g, "");
          //@note: Removes ABS
          token = token.trim().replace(/ABS(?![\w\d])/g, "");

          return token;
        }
        return token;
      })
      .filter((token) => isNaN(Number(token)));
  };

  readonly #handleLeftParenthesis = (formula: string) => {
    let flagSkip = false;
    let init: number;
    let formulaString = "";

    for (init = 0; init < formula.length; init++) {
      const token = formula[init];
      const shouldCheck = init > 0 && FormulaParserService.operands.indexOf(formula[init - 1]) === -1;

      if (token == "[" || token == "{") flagSkip = true;
      if (token == "]" || token == "}") flagSkip = false;

      if (!flagSkip && FormulaParserService.globalsOrLocals.indexOf(token) === -1 && token === "(" && shouldCheck) {
        formulaString = (init - 1 >= 0 ? "*" : "") + token;

        continue;
      }

      formulaString += token;
    }

    return formulaString;
  };
}
