import { AdaaHelper } from "../../../../../../../core/utils";
import { SharedFields, teamMembersSectionConfig } from "../../configs";
import { AccordionSectionConfigType, ScreenSectionConfigType } from "../../wf-difference.types";

const weightField: ScreenSectionConfigType = {
  field: "weight",
  label: "nkpi.weight",
  view: "normal",
};

const milestoneOrderField: ScreenSectionConfigType = {
  field: "milestoneOrder",
  label: "common.form.label.order",
  view: "normal",
  visibleIf: ($d: { milestoneOrder: number }) => {
    if ($d?.milestoneOrder) return true;
    return false;
  },
};

const actualStartDateField: ScreenSectionConfigType = {
  field: "actualStartDate",
  label: "national_projects.actual_start_date",
  view: "normal",
  format: (data: { actualStartDate: number }) => {
    if (data?.actualStartDate) {
      return AdaaHelper.formatDate(data?.actualStartDate, false);
    }
    return "";
  },
};

const actualEndDateField: ScreenSectionConfigType = {
  field: "actualEndDate",
  label: "national_projects.actual_end_date",
  view: "normal",
  format: (data: { actualEndDate: number }) => {
    if (data?.actualEndDate) {
      return AdaaHelper.formatDate(data?.actualEndDate, false);
    }
    return "";
  },
};

const projectTeamMembersField: AccordionSectionConfigType = {
  view: "accordion",
  field: "teamMembers",
  title: ($o) => AdaaHelper.getItemValueByToken($o, "name"),
  idKey: "id",
  config: teamMembersSectionConfig,
  label: "national_projects.project_team_members.common_text",
};

export const improvementPlanSectionConfig = [
  ...SharedFields.name,
  ...SharedFields.description,
  {
    ...SharedFields.startDate,
    label: "national_projects.planned_start_date",
  },
  {
    ...SharedFields.endDate,
    label: "national_projects.planned_end_date",
  },
  actualStartDateField,
  actualEndDateField,
  SharedFields.orgUnitIdField,
  SharedFields.ownerField,
  {
    field: "progress",
    view: "normal",
    label: "initiatives.current_progress",
  },
  weightField,
  milestoneOrderField,
  projectTeamMembersField,
];
