import { inject, Injectable } from "@angular/core";

import { MainResponseModel } from "../models";
import { OtherEntity } from "../models/other-entity.models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class OtherEntitiesApiService {
  private _mainService = inject(MainService);

  public getOtherEntityById(otherEntityId: number) {
    const url = `other-entity/getById/${otherEntityId}`;

    return this._mainService.sendRequest<MainResponseModel<OtherEntity>>({
      method: "GET",
      url,
    });
  }

  public createOtherEntity(otherEntity: Partial<OtherEntity>) {
    const url = `other-entity/create?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data: otherEntity,
    });
  }

  public updateOtherEntity(data: Partial<OtherEntity>) {
    const url = `other-entity/update?limit=10&offset=0`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "PUT",
      url,
      data,
    });
  }

  public deleteOtherEntity(otherEntityId: number) {
    const url = `other-entity/deleteById/${otherEntityId}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "DELETE",
      url,
    });
  }

  public getAll() {
    const url = `other-entity/getAll`;

    return this._mainService.sendRequest<MainResponseModel<OtherEntity[]>>({
      method: "GET",
      url,
    });
  }
}
