import { inject, Injectable } from "@angular/core";

import { EntityReportModelType, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class EntityReportApiService {
  private _mainService = inject(MainService);

  public updateItemAttachment(EntityReport: Partial<EntityReportModelType>) {
    const url = `file/updateItemAttachmentWithoutWF`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data: EntityReport,
    });
  }

  public deleteEntityReport(reportId: number) {
    const url = `file/deleteById/${reportId}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "DELETE",
      url,
    });
  }
}
