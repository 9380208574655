import { inject, Injectable } from "@angular/core";

import { MainResponseModel, ServiceCard, ServiceModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class ServicesApiService {
  private _mainService = inject(MainService);

  public getCardById(id: number) {
    const url = `service/getCardById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<ServiceCard>>({
      url,
      method: "GET",
      loader: true,
    });
  }

  public getService(id: number) {
    const url = `service/getbyid/${id}`;

    return this._mainService.sendRequest<MainResponseModel<ServiceModel>>({
      url,
      method: "GET",
      loader: true,
    });
  }

  public getServicesByMap() {
    const url = `service/getServicesByMap`;

    return this._mainService.sendRequest<MainResponseModel<ServiceModel[]>>({
      url,
      method: "GET",
      loader: true,
    });
  }

  public getSubServicesByMap() {
    const url = `service/getSubServicesByMap`;

    return this._mainService.sendRequest<MainResponseModel<ServiceModel[]>>({
      url,
      method: "GET",
      loader: true,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateService(data: any) {
    const url = `service/update?isFinish=true`;
    return this._mainService.sendRequest<MainResponseModel>({
      method: "POST",
      data,
      url,
    });
  }
}
