import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { TranslateLoader } from "@ngx-translate/core";
import * as localForage from "localforage";
import { Observable, Subscriber } from "rxjs";

import { Language } from "../../shared/constants/enums";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CustomTranslateLoader implements TranslateLoader {
  private _httpClient = inject(HttpClient);

  httpHeader = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  private static _getTranslationLocally(lang: string, observer: Subscriber<Record<PropertyKey, unknown>>) {
    lang = lang === Language.English ? "en" : "ar";

    observer.next(require(`../../../assets/i18n/${lang}.json`));
    observer.complete();
  }

  public getTranslation(lang: string): Observable<Record<PropertyKey, unknown>> {
    const url = `file/translationsDownload/${lang}`;
    return new Observable((observer) => {
      return this._httpClient.get<Record<PropertyKey, unknown>>(environment.apiUrl + url, this.httpHeader).subscribe({
        next: (res) => {
          observer.next(res);
          observer.complete();
        },
        error: () => {
          this._getTranslationFromStorage(url, lang, observer).then(() => {});
        },
      });
    });
  }

  private _getTranslationFromStorage(url: string, lang: string, observer: Subscriber<Record<PropertyKey, unknown>>) {
    return localForage
      .getItem<Record<PropertyKey, unknown>>(`${url}`)
      .then((res) => {
        if (res) {
          observer.next(res);
          observer.complete();
        } else {
          CustomTranslateLoader._getTranslationLocally(lang, observer);
        }
      })
      .catch(() => {
        CustomTranslateLoader._getTranslationLocally(lang, observer);
      });
  }
}
