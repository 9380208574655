<adaa-data-table
    titleHeader="national_projects.national_projects_milestone.title"
    [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.PROJECT_MILESTONE_STEP_CONF_LIST"
    [enableExport]="false"
    [enableAdd]="true"
    [enableViewAction]="false"
    [isLocalData]="true"
    [localData]="milestonesData"
    [customTableButtons]="customTableButtons"
    [disableDeleteRules]="disableEditDeleteRules"
    [disableEditRules]="disableEditDeleteRules"
    [rowsWithIssues]="rowsIssues"
    (addNewClicked)="addNewClicked()"
    (buttonClicked)="buttonClicked()"
    (actionClicked)="actionClicked($event)"
></adaa-data-table>
