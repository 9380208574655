@if (column().fieldName === "email") {
    {{ data()[column().fieldName] }}
}

@if (column().fieldName === "publishedState") {
    @if (data()[column().fieldName] === PublishedState.notPublished) {
        {{ "non_published" | translate }}
    } @else {
        {{ "published" | translate }}
    }
}

@if (column().fieldName === "target") {
    @if (configKey() === Constants.MAIN_TABLE_LIST_CONF_KEY.DATA_ENTRY_METRICS_LIST) {
        {{ getNumberValue(column().fieldName) | numberComma }}
    } @else {
        @if (data()["measurementUnit"] === Constants.MEASUREMENT.CONSTANT_MEASUREMENT_PERCENTAGE) {
            @if (AdaaHelper.isDefined(data()[column().fieldName])) {
                {{ getNumberValue(column().fieldName) | percentageValue: 2 : "" : "%" : true : true }}
            }
        } @else {
            {{ getNumberValue(column().fieldName) | roundValue: 2 : "" : true }}
        }
    }
}

@if (column().fieldName === "annualTarget" || column().fieldName === "visionTarget") {
    {{ getNumberValue(column().fieldName) }}
}

@if (column().fieldName === "annualOptimalPerformance") {
    <span [class]="'badge rounded-1' + ' bg-' + data()['annualOptimalPerformanceColor']">
        @if (AdaaHelper.isDefined(data()[column().fieldName])) {
            {{ getNumberValue(column().fieldName) | percentageValue: 2 : "" }}
        }
    </span>
}

@if (column().fieldName === "comments" || column().fieldName === "categorizedComments") {
    @for (item of getArrayValue("categorizedComments"); track $index) {
        @if (!isCommentEmpty(item)) {
            <div class="card rounded-1 w-auto bg-white comment">
                {{ item.comments }}

                <span class="badge badge-sm w-auto text-white bg-primary fw-bold rounded-0" data-comment>
                    {{ item | translateToken: "categoryName" }}
                </span>
            </div>
        }
    }

    @if (!isCommentEmpty(data())) {
        <div class="card rounded-1 w-auto bg-white comment comment--other">
            {{ data().comments }}
        </div>
    }
}

@if (column().fieldName === "status") {
    @if (data().status === "FINISHED") {
        <span class="adaa-icon-active"></span>
    } @else if (data().status === "STOPPED") {
        <span class="adaa-icon-bounded"></span>
    } @else if (data().status === "INPROGRESS") {
        <i class="fa-solid fa-circle-play"></i>
    }
}

@if (column().fieldName === "attachDate") {
    {{ data()[column().fieldName] ? (getNumberValue(column().fieldName) | date: Constants.yearDate) : "" }}
}

@if (column().fieldName === "formula") {
    {{ data()[column().fieldName] === "NOFORMULAV" ? ("kpi.NOFORMULAV" | translate) : data()[column().fieldName] }}
}

<!-- Metric Edit List -->
@if (column().fieldName === "periodDate") {
    {{ data()[column().fieldName] ? (getNumberValue(column().fieldName) | dubaiTime | date: Constants.shortMonthYearDate) : "" }}
}

@if (column().fieldName === "attachmentFilename" && data().attachmentId) {
    <button class="btn btn-link" (click)="filesApiService.save($any(data().attachmentId), $any(data().attachmentFilename), true)">
        {{ data()[column().fieldName] }}
    </button>
}

@if (column().fieldName.includes("name")) {
    <p class="mb-1">
        {{ data()[column().fieldName] }}
    </p>

    @if (configKey() === Constants.MAIN_TABLE_LIST_CONF_KEY.ACTIVITY_CARD_OPMKPI && !isActivitiesViewPage()) {
        <p class="m-0">
            <span>
                {{ "data_entry.target" | translate }}
                : {{ AdaaHelper.isDefined(data().target) ? ($any(data().target) | roundValue) : "-" }}
            </span>

            <span class="mx-1">&nbsp;</span>

            <span>
                {{ "data_entry.actual" | translate }}
                : {{ AdaaHelper.isDefined(data().actual) ? ($any(data().actual) | roundValue) : "-" }}
            </span>
        </p>
    }

    @if (configKey() === Constants.MAIN_TABLE_LIST_CONF_KEY.INITIATIVE_CARD_ACTIVITIES) {
        <p class="m-0">
            <span> {{ "common.form.label.start_date" | translate }} : {{ $any(data().startDate) | date: Constants.normalDate }} </span>

            <span class="mx-1">&nbsp;</span>

            <span> {{ "common.form.label.end_date" | translate }} : {{ $any(data().endDate) | date: Constants.normalDate }} </span>
        </p>
    }
}

<!--Export/Import Plan Status-->
@if (column().fieldName === "impextStatus") {
    @if (data()[column().fieldName] === ImportExportStatus.DONE) {
        {{ "importExport.file_status.done" | translate }}
    } @else if (data()[column().fieldName] === ImportExportStatus.FAIL) {
        {{ "importExport.file_status.fail" | translate }}
    } @else if (data()[column().fieldName] === ImportExportStatus.SUCCESS) {
        {{ "importExport.file_status.success" | translate }}
    } @else if (data()[column().fieldName] === ImportExportStatus.PENDING) {
        {{ "importExport.file_status.pending" | translate }}
        <div class="mx-2 zsk__loader zsk__loader--xs"></div>
    }
}

<!--Export/Import Plan Type-->
@if (column().fieldName === "type" && configKey() === Constants.MAIN_TABLE_LIST_CONF_KEY.IMPORT_EXPORT) {
    @if (data()[column().fieldName] === ImportExportType.IMPORT) {
        {{ "importExport.operation_type.import" | translate }}
    } @else if (data()[column().fieldName] === ImportExportType.EXPORT) {
        {{ "importExport.operation_type.export" | translate }}
    }
}
