import { inject, Injectable } from "@angular/core";

import type { MainResponseModel, NationalStrategyModelType, NationalStrategyStatsModelType } from "../models";
import { MainService } from "./main.service";

type StrategiesDashboardInputType<T> = T extends true
  ? never
  : { year: number; status: "ON_GOING" | "COMPLETED" | "ALL" };

@Injectable({
  providedIn: "root",
})
export class NationalStrategiesApiService {
  private _mainService = inject(MainService);

  public getStrategiesDashboardInfo<TShowAll>(input?: StrategiesDashboardInputType<TShowAll>) {
    const url = "national-strategies/values";
    const qs = new URLSearchParams();

    if (input && input.year) qs.append("year", `${input.year}`);
    if (input && input.status && input.status !== "ALL") qs.append("status", input.status);

    return this._mainService.sendRequest<MainResponseModel<NationalStrategyStatsModelType>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getStrategyDashboardInfo(strategyId: number, year: number) {
    const url = `national-strategies/values/${strategyId}?year=${year}`;

    return this._mainService.sendRequest<MainResponseModel<NationalStrategyModelType>>({
      method: "GET",
      url,
    });
  }
}
