import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TabsService {
  private _channel = new BroadcastChannel("tab-reload");

  constructor() {
    // Listen for reload messages from other tabs
    this._channel.onmessage = (event) => {
      if (event.data === "reload") {
        window.location.reload();
      }
    };

    // Listen for storage-based reloads (for browsers that don't support BroadcastChannel)
    window.addEventListener("storage", (event) => {
      if (event.key === "reloadTabs") {
        window.location.reload();
      }
    });
  }

  /** Reload all other tabs except the current one */
  public reloadOtherTabs(): void {
    this._channel.postMessage("reload"); // Send reload signal
    localStorage.setItem("reloadTabs", Date.now().toString()); // Fallback for unsupported browsers
  }
}
