export class LocalForageStorage {
  static STORED_APIS = [
    "period/getbyDateInterval",
    "validator/getbysearchkeyv2/console_conf",
    "file/download",
    "translationsDownload",
    "formulas/getall",
    "calculationengine/performancecolors",
  ];
}
