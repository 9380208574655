import { NgClass } from "@angular/common";
import { Component, inject, OnInit, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import * as localForage from "localforage";

import { Constants } from "../../../constants/constants";
import { MainResponseModel, PerfColorCodeModelType } from "../../../models";
import { CalculationEngineApiService, LanguageService } from "../../../services";

@Component({
  selector: "adaa-color-legend",
  standalone: true,
  imports: [NgClass, TranslateModule],
  templateUrl: "./color-legend.component.html",
  styleUrl: "./color-legend.component.scss",
})
export class ColorLegendComponent implements OnInit {
  readonly languageService = inject(LanguageService);
  private readonly _calculationEngineApiService = inject(CalculationEngineApiService);

  data = signal<
    {
      color: number | null;
      border: number;
      legendBorder: number;
    }[]
  >([]);

  readonly maxValue = 100;
  readonly colors = Constants.COLOR_CODES;

  public ngOnInit() {
    const url = `calculationengine/performancecolors`;

    localForage.getItem(url).then((res) => {
      res == undefined
        ? this._getPerformanceColors()
        : this._setPerformanceColors(res as MainResponseModel<PerfColorCodeModelType[]>);
    });
  }

  private _getPerformanceColors(): void {
    this._calculationEngineApiService.performanceColors().subscribe({
      next: (res) =>
        this.data.set([...res.responseData, { color: null, border: this.maxValue, legendBorder: this.maxValue }]),
    });
  }

  private _setPerformanceColors(res: MainResponseModel<PerfColorCodeModelType[]>): void {
    this.data.set([...res.responseData, { color: null, border: this.maxValue, legendBorder: this.maxValue }]);
  }
}
