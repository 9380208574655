import { Component, effect, EventEmitter, inject, input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { AdaaHelper } from "../../../../core/utils";
import { CyclesApiService, LanguageService, SystemLayoutService } from "../../../services";
import { ConfirmationModalComponent } from "../../general";

@Component({
  selector: "adaa-plan-change-detection",
  standalone: true,
  template: "",
  imports: [],
})
export class PlanChangeDetectionComponent {
  private readonly _router = inject(Router);
  private readonly _modalService = inject(NgbModal);
  private readonly _languageService = inject(LanguageService);
  private readonly _cyclesApiService = inject(CyclesApiService);
  private readonly _systemLayoutService = inject(SystemLayoutService);

  planId = input<number | null>();

  @Output() continue = new EventEmitter<void>();

  readonly currentPlanId = () => AdaaHelper.plan?.id;

  constructor() {
    effect(() => {
      if (!this.planId()) {
        this.continue.emit();
        return;
      }
      if (this.planId() === this.currentPlanId()) {
        this.continue.emit();
        return;
      }

      this._open();
    });
  }

  private _open() {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      keyboard: false,
      animation: true,
      size: "md",
      backdropClass: "adaa-invisible",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = "initiatives.different_plan_title";
    modal.componentInstance.title = "initiatives.different_plan";

    modal.result.then(
      (e) => {
        if (e) {
          this._getCycle();
          return;
        }
        this._router.navigateByUrl("/console");
      },
      () => {
        this._router.navigateByUrl("/console");
      }
    );
  }

  private _getCycle() {
    this._cyclesApiService.getById(this.planId()!).subscribe({
      next: (res) => {
        this._systemLayoutService.selectCycle(res.responseData, true);
        setTimeout(() => window.location.reload());
      },
    });
  }
}
