import { Component, inject, input, OnInit, output, signal } from "@angular/core";
import { NgbAccordionModule, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import { Constants } from "../../constants/constants";
import { Language, PageMode } from "../../constants/enums";
import { DimensionsSet, DimenstionValue, ParentMetric, ValueText } from "../../models";
import { LanguageService, MetricApiService } from "../../services";
import { FormDropdownComponent, FormDropdownMultiComponent } from "../form";
import { ItemsOrderModalComponent } from "../modals";

@Component({
  selector: "adaa-dimensions-editor",
  imports: [TranslateModule, NgbAccordionModule, FormDropdownComponent, FormDropdownMultiComponent],
  templateUrl: "./dimensions-editor.component.html",
  styleUrl: "./dimensions-editor.component.scss",
})
export class DimensionsEditorComponent implements OnInit {
  private _metricApiService = inject(MetricApiService);
  private _languageService = inject(LanguageService);
  private _modalService = inject(NgbModal);
  private _toastrService = inject(ToastrService);

  pageMode = input.required<PageMode>();
  dimensionsSet = input.required<DimensionsSet[]>();
  formChanged = output<DimensionsSet[]>();

  private _parentMetricsCopy = signal<ParentMetric[]>([]);
  parentMetrics = signal<ValueText[]>([]);

  private get _dimensionsValid(): boolean {
    return (
      this.dimensionsSet().findIndex((e) => e.status === Constants.OBJECT_STATUS.ACTIVE && e.parentMetricId == null) ===
      -1
    );
  }

  activeTab: number | undefined = undefined;
  PageMode = PageMode;
  Language = Language;
  AdaaHelper = AdaaHelper;

  public ngOnInit(): void {
    this._getParentMetrics();
    this._prepareData();
  }

  public addSet(): void {
    if (!this._dimensionsValid) {
      this._toastrService.warning(
        this._languageService.translate("government_scores.scopes.monitoring_dimension_error")
      );
      return;
    }

    const fakeId = Math.floor(Math.random() * 217 * 1000);
    this.activeTab = fakeId;

    this.dimensionsSet().push({
      id: undefined,
      status: Constants.OBJECT_STATUS.ACTIVE,
      values: [],
      dimensionTypeOptions: [],

      fakeId: fakeId,
    });

    this.formChanged.emit(this.dimensionsSet());
  }

  public removeSet(set: DimensionsSet): void {
    const index = this.dimensionsSet().findIndex((e) => (set.id ? e.id === set.id : e.fakeId === set.fakeId));
    if (index > -1) this.dimensionsSet().splice(index, 1);
  }

  public orderSet(set: DimensionsSet): void {
    const modal = this._modalService.open(ItemsOrderModalComponent, {
      centered: true,
      size: "lg",
      modalDialogClass: this._languageService.modalDirection(),
    });

    const data: ValueText[] = [];
    set.values.forEach((e) => data.push({ value: e.dimensionId, text: AdaaHelper.getItemValueByToken(e, "name") }));

    modal.componentInstance.data = data;
    modal.componentInstance.inputLabel = "breadcrumbs.dimensions";

    modal.result.then((result: number[] | undefined) => {
      if (!result) return;
      set.values.forEach((dimension) => {
        const index = result.findIndex((e) => e === dimension.dimensionId);
        dimension.level = index + 1;
      });
      set.values.sort((a, b) => a.level - b.level);

      this.formChanged.emit(this.dimensionsSet());
    });
  }

  public removeDimensionType(set: DimensionsSet, value: number): void {
    //Remove the selected option from the dropdown
    const index = set.values.findIndex((e) => e.dimensionId === value);
    if (index > -1) {
      //Add the removed dimension to the options dropdown
      set.dimensionTypeOptions.push({ value: value, text: AdaaHelper.getItemValueByToken(set.values[index], "name") });
      set.dimensionTypeOptions = [...set.dimensionTypeOptions];

      set.values.splice(index, 1);
    }

    //Reorder the levels
    set.values.forEach((e, i) => (e.level = i));

    //Emit the changes
    this.formChanged.emit(this.dimensionsSet());
  }

  public parentMetricsChanged(value: number | undefined, set: DimensionsSet, onComplete?: () => void): void {
    if (set.parentMetricId !== value) set.values = [];

    set.parentMetricId = value;

    if (!value) {
      set.dimensionTypeOptions = [];
      return;
    }

    const metric = this._parentMetricsCopy().find((e) => e.id === value);
    if (metric) {
      set.nameAE = metric.nameAE;
      set.nameEN = metric.nameEN;
    }

    this._metricApiService.getDimensionsByParentMetric(value).subscribe({
      next: (response) => {
        if (response.inError) return;

        set.dimensionTypeOptions = AdaaHelper.setDropdownArray(
          response.responseData,
          "id",
          AdaaHelper.getFieldLanguage("name")
        );
      },
      complete: () => {
        this.formChanged.emit(this.dimensionsSet());
        if (onComplete) onComplete();
      },
    });
  }

  public dimensionTypeChanged(value: number, set: DimensionsSet): void {
    //Remove the selected option from the dropdown
    const option = set.dimensionTypeOptions.find((e) => e.value === value);
    set.dimensionTypeOptions = set.dimensionTypeOptions.filter((e) => e.value !== value);

    //Get the level
    const level =
      set.values && set.values.length > 0 ? set.values.map((e) => e.level).reduce((a, b) => Math.max(a, b), 0) + 1 : 1;

    //Get the dimension values
    this._metricApiService.getDimensionsValues([value]).subscribe({
      next: (response) => {
        if (response.inError) return;

        //Add the new dimension
        set.values.push({
          dimensionId: value,
          dimensionValueIds: [],
          options: AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name")),
          level: level,
          status: Constants.OBJECT_STATUS.ACTIVE,
          nameEN: option?.text,
          nameAE: option?.text,
        });
      },
      complete: () => this.formChanged.emit(this.dimensionsSet()),
    });
  }

  public dimensionValueChanged(value: number[] | undefined, dimenstionValue: DimenstionValue): void {
    value ? (dimenstionValue.dimensionValueIds = value) : (dimenstionValue.dimensionValueIds = []);
    this.formChanged.emit(this.dimensionsSet());
  }

  private _getParentMetrics(): void {
    this._metricApiService.getParentMetrics().subscribe({
      next: (response) => {
        if (response.inError) return;

        this._parentMetricsCopy.set(response.responseData);
        this.parentMetrics.set(
          AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"))
        );
      },
    });
  }

  private _prepareData(): void {
    this.dimensionsSet().forEach((set) => {
      this.parentMetricsChanged(set.parentMetricId, set, () => {
        const ids = set.values.map((e) => e.dimensionId);
        set.dimensionTypeOptions = set.dimensionTypeOptions.filter((e) => !ids.includes(e.value));

        set.values.forEach((value) => {
          this._metricApiService.getDimensionsValues([value.dimensionId]).subscribe({
            next: (response) => {
              if (response.inError) return;

              value.options = AdaaHelper.setDropdownArray(
                response.responseData,
                "id",
                AdaaHelper.getFieldLanguage("name")
              );
            },
          });
        });
      });
    });
  }
}
