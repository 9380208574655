<div class="modal-header">
    <h5 class="text-center fw-bold w-100">{{ title }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
</div>
<div class="modal-body overflow-hidden p-3">
    <div class="canvas">
        <adaa-chart-action-buttons
            [elementId]="'entityComparison' + chartId"
            [enableDownloadImage]="true"
            [enableDownloadCSV]="false"
        ></adaa-chart-action-buttons>
        <canvas [id]="'entityComparison' + chartId" baseChart type="bar" [datasets]="datasets" [labels]="labels" [options]="options"></canvas>
    </div>
</div>
<div class="modal-footer d-flex justify-content-center mt-3">
    <div class="my-2">
        <adaa-form-action-buttons
            [showSaveButton]="false"
            [cancelButtonTitle]="'common.form.button.close' | translate"
            (cancelClicked)="modal.close()"
        ></adaa-form-action-buttons>
    </div>
</div>
