import { inject, Injectable } from "@angular/core";
import { filter, map } from "rxjs";

import { MainResponseModel, UserGroup, UserGroupData, UserGroupModelType } from "../models";
import { UserProfile } from "../models/user-profile.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class UserGroupsApiService {
  private _mainService = inject(MainService);

  public getUserGroupList() {
    const url = `usergroups/getall`;

    return this._mainService.sendRequest<MainResponseModel<UserGroup[]>>({
      method: "POST",
      url,
      data: null,
    });
  }

  public createUserGroup(data: UserGroupData) {
    const url = `usergroups/create?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public getall(entityId: number) {
    const url = `usergroups/getall?entityId=${entityId}&isActive=true`;

    return this._mainService.sendRequest<MainResponseModel<UserGroupModelType[]>>({
      method: "POST",
      url,
      data: null,
    });
  }

  public getallHelpdesk() {
    const url = `usergroups/getAllHelpdesk`;

    return this._mainService
      .sendRequest<MainResponseModel<UserGroupModelType[]>>({
        method: "GET",
        url,
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => ({
          ...res,
          responseData: res.responseData.map((e) => ({
            ...e,
            nameEN: `${e.entityRefCode} - ${e.nameEN}`,
            nameAE: `${e.entityRefCode} - ${e.nameAE}`,
          })),
        }))
      );
  }

  public getGroupById(groupId: number) {
    const url = `usergroups/getById/${groupId}`;

    return this._mainService.sendRequest<MainResponseModel<UserGroup>>({
      method: "GET",
      url,
    });
  }

  public getallForGroupsByEntity() {
    const url = `users/getAllForGroupsByEntityId?isActive=true`;

    return this._mainService.sendRequest<MainResponseModel<UserProfile[]>>({
      method: "POST",
      url,
      data: {},
    });
  }

  public updateState(data: { id: number; enabled: string }) {
    const url = `usergroups/updateState`;

    return this._mainService.sendRequest<MainResponseModel<UserProfile[]>>({
      method: "POST",
      url,
      data,
    });
  }

  public updateGroup(data: UserGroup) {
    const url = `usergroups/update?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public deleteGroup(groupId: number) {
    const url = `usergroups/deleteById/${groupId}`;

    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "DELETE",
      url,
    });
  }

  public getallWithoutPagination() {
    return this._mainService.sendRequest<MainResponseModel<UserGroupModelType[]>>({
      method: "GET",
      url: "usergroups/getallWithoutPagination",
    });
  }
}
