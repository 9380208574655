import { computed, inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import { Language } from "../constants/enums";
import { LanguageService } from "./language.service";

@Injectable({
  providedIn: "root",
})
export class AppService {
  private _router = inject(Router);
  private _languageService = inject(LanguageService);

  language = computed(() => {
    const lang = this._languageService.current();
    if (!lang) {
      return Language.Arabic;
    }
    return lang;
  });

  public hasPlanId() {
    const planId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, {
      type: "prop",
      property: "id",
    });

    return planId && +planId !== 0;
  }

  public reloadPage(path: string): void {
    this._router.navigate(["/"], { skipLocationChange: true }).then(() => {
      this._router.navigateByUrl(path);
    });
  }
}
