<form [formGroup]="form">
    <div class="row mb-3">
        <div class="col-12">
            <div class="bg-white shadow-md rounded p-1">
                <div class="row" [dir]="languageService.direction()">
                    <div class="col-md-6 col-sm-12 col-xs-12" [dir]="languageService.direction()">
                        <adaa-form-dropdown
                            [label]="'entity.create.entity_type' | translate"
                            [options]="entityTypesOptions()"
                            [controlName]="'entityType'"
                            [formControlName]="'entityType'"
                            [classes]="'text-primary fw-bold text-center'"
                            (inputChanges)="selected.emit({ type: 'entityType', data: form.get('entityType')?.value })"
                        ></adaa-form-dropdown>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12" [dir]="languageService.direction()">
                        <adaa-form-dropdown
                            [label]="'entity.create.entity_size' | translate"
                            [options]="entitySizesOptions()"
                            [controlName]="'entitySize'"
                            [formControlName]="'entitySize'"
                            [classes]="'text-primary fw-bold text-center'"
                            (inputChanges)="selected.emit({ type: 'entitySize', data: form.get('entitySize')?.value })"
                        ></adaa-form-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
