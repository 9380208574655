@if (!showDIffOnly() || hasAnyDiff()) {
    @if (attachments() && attachments().length > 0) {
        <div class="w-100 d-block my-4">
            @if (showCustomFields()) {
                <p class="fs-5 mt-3">{{ "helpdesk.attachments" | translate }}</p>
            } @else {
                <h4 class="mb-3">{{ "kpi.attachments" | translate }}</h4>
            }
            <section class="row d-flex align-content-center">
                <div class="col-4 p-2 border border-white tbl-col bg-white text-primary fw-bold text-center fs-6">
                    {{ "communication.difference.key" | translate }}
                </div>

                <div
                    class="p-2 border border-white tbl-col bg-white text-primary fw-bold text-center fs-6"
                    [class.rounded-end-1]="showOnlyNew()"
                    [class.col-6]="showOnlyNew()"
                    [class.col-4]="!showOnlyNew()"
                >
                    {{ !showOnlyNew() ? ("communication.difference.new_value" | translate) : ("communication.difference.value" | translate) }}
                </div>

                @if (!showOnlyNew()) {
                    <div class="col-4 p-2 border border-white tbl-col bg-white text-primary fw-bold text-center fs-6">
                        {{ "communication.difference.old_value" | translate }}
                    </div>
                }
            </section>

            @for (item of attachments(); track $index) {
                <div class="attachment row">
                    <div class="col-12">
                        <!-- section:name-ae -->
                        @if (!showDIffOnly() || showOnlyNew() || !item.new || !item.old || !isEqual(item.new.nameAE!, item.old.nameAE!)) {
                            <section class="row d-flex align-content-center">
                                <div
                                    class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center txt-col"
                                    [class.col-6]="showOnlyNew()"
                                    [class.col-4]="!showOnlyNew()"
                                >
                                    {{ (showCustomFields() ? "helpdesk.attachment_name_ae" : "common.form.label.nameAE") | translate }}
                                </div>

                                <div
                                    class="p-2 border border-white tbl-col d-flex align-items-center"
                                    [class.rounded-end-1]="showOnlyNew()"
                                    [class.col-6]="showOnlyNew()"
                                    [class.col-4]="!showOnlyNew()"
                                    [class.tbl-diff]="showOnlyNew() || !item.new || !isEqual(item.new.nameAE!, item.old?.nameAE!)"
                                >
                                    @if (item.new) {
                                        @if (showCustomFields()) {
                                            <p>{{ item.new.nameAE }}</p>
                                        } @else {
                                            <button class="btn btn-link btn-info txt-col p-0 text-left" (click)="download(item.new!)">
                                                {{ item.new.nameAE }}
                                            </button>
                                        }
                                    }
                                </div>

                                @if (!showOnlyNew()) {
                                    <div
                                        class="col-4 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-center"
                                        [class.tbl-diff]="!item.old || !isEqual(item.new?.nameAE!, item.old.nameAE!)"
                                    >
                                        @if (item.old) {
                                            @if (showCustomFields()) {
                                                <p>{{ item.old.nameAE }}</p>
                                            } @else {
                                                <button class="btn btn-link btn-info txt-col p-0 text-left" (click)="download(item.old!)">
                                                    {{ item.old.nameAE }}
                                                </button>
                                            }
                                        }
                                    </div>
                                }
                            </section>
                        }

                        <!-- section:name-en -->
                        @if (!showDIffOnly() || showOnlyNew() || !item.new || !item.old || !isEqual(item.new.nameEN!, item.old.nameEN!)) {
                            <section class="row d-flex align-content-center">
                                <div
                                    class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center txt-col"
                                    [class.col-6]="showOnlyNew()"
                                    [class.col-4]="!showOnlyNew()"
                                >
                                    {{ (showCustomFields() ? "helpdesk.attachment_name_en" : "common.form.label.nameEN") | translate }}
                                </div>

                                <div
                                    class="p-2 border border-white tbl-col d-flex align-items-center"
                                    [class.rounded-end-1]="showOnlyNew()"
                                    [class.col-6]="showOnlyNew()"
                                    [class.col-4]="!showOnlyNew()"
                                    [class.tbl-diff]="showOnlyNew() || !item.new || !isEqual(item.new.nameEN!, item.old?.nameEN!)"
                                >
                                    @if (item.new) {
                                        @if (showCustomFields()) {
                                            <p>{{ item.new.nameEN }}</p>
                                        } @else {
                                            <button class="btn btn-link btn-info txt-col p-0 text-left" (click)="download(item.new!)">
                                                {{ item.new.nameEN }}
                                            </button>
                                        }
                                    }
                                </div>

                                @if (!showOnlyNew()) {
                                    <div
                                        class="col-4 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-center"
                                        [class.tbl-diff]="!item.old || !isEqual(item.new?.nameEN!, item.old.nameEN!)"
                                    >
                                        @if (item.old) {
                                            @if (showCustomFields()) {
                                                <p>{{ item.old.nameEN }}</p>
                                            } @else {
                                                <button class="btn btn-link btn-info txt-col p-0 text-left" (click)="download(item.old!)">
                                                    {{ item.old.nameEN }}
                                                </button>
                                            }
                                        }
                                    </div>
                                }
                            </section>
                        }

                        @if (showCustomFields()) {
                            @if (!showDIffOnly() || showOnlyNew() || !item.new || !item.old || !isEqual(item.new.filename!, item.old.filename!)) {
                                <section class="row d-flex align-content-center">
                                    <div
                                        class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center txt-col"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                    >
                                        {{ "helpdesk.attachment_file" | translate }}
                                    </div>

                                    <div
                                        class="p-2 border border-white tbl-col d-flex align-items-center"
                                        [class.rounded-end-1]="showOnlyNew()"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                        [class.tbl-diff]="showOnlyNew() || !item.new || !isEqual(item.new.filename!, item.old?.filename!)"
                                    >
                                        @if (item.new) {
                                            <button class="btn btn-link btn-info txt-col p-0 text-left" (click)="download(item.new!)">
                                                {{ item.new.filename }}
                                            </button>
                                        }
                                    </div>

                                    @if (!showOnlyNew()) {
                                        <div
                                            class="col-4 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-center"
                                            [class.tbl-diff]="!item.old || !isEqual(item.new?.filename!, item.old.filename!)"
                                        >
                                            @if (item.old) {
                                                <button class="btn btn-link btn-info txt-col p-0 text-left" (click)="download(item.old!)">
                                                    {{ item.old.filename }}
                                                </button>
                                            }
                                        </div>
                                    }
                                </section>
                            }
                        } @else {
                            <!-- section:attach-type -->
                            @if (!showDIffOnly() || showOnlyNew() || !item.new || !item.old || !isEqual(item.new.attachType!, item.old.attachType!)) {
                                <section class="row d-flex align-content-center">
                                    <div
                                        class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center txt-col"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                    >
                                        {{ "nkpi.attachmentType" | translate }}
                                    </div>

                                    <div
                                        class="p-2 border border-white tbl-col d-flex align-items-center"
                                        [class.rounded-end-1]="showOnlyNew()"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                        [class.tbl-diff]="showOnlyNew() || !item.new || !isEqual(item.new.attachType!, item.old?.attachType)"
                                    >
                                        {{ getAttachType(item.new?.attachType!) | translateToken: "name" }}
                                    </div>

                                    @if (!showOnlyNew()) {
                                        <div
                                            class="col-4 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-center txt-col"
                                            [class.tbl-diff]="!item.old || !isEqual(item.new?.attachType!, item.old.attachType!)"
                                        >
                                            {{ getAttachType(item.old?.attachType!) | translateToken: "name" }}
                                        </div>
                                    }
                                </section>
                            }

                            <!-- section:attach-date -->
                            @if (!showDIffOnly() || showOnlyNew() || !item.new || !item.old || !isEqual(item.new.attachDate!, item.old.attachDate!)) {
                                <section class="row d-flex align-content-center">
                                    <div
                                        class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center txt-col"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                    >
                                        {{ "common.form.label.date" | translate }}
                                    </div>

                                    <div
                                        class="p-2 border border-white tbl-col d-flex align-items-center"
                                        [class.rounded-end-1]="showOnlyNew()"
                                        [class.col-6]="showOnlyNew()"
                                        [class.col-4]="!showOnlyNew()"
                                        [class.tbl-diff]="showOnlyNew() || !item.new || !isEqual(item.new.attachDate!, item.old?.attachDate!)"
                                    >
                                        {{ item.new?.attachDate! | dubaiTime | date: "dd/MM/yyyy" }}
                                    </div>

                                    @if (!showOnlyNew()) {
                                        <div
                                            class="col-4 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-center txt-col"
                                            [class.tbl-diff]="!item.old || !isEqual(item.new?.attachDate!, item.old.attachDate!)"
                                        >
                                            {{ item.old?.attachDate! | dubaiTime | date: "dd/MM/yyyy" }}
                                        </div>
                                    }
                                </section>
                            }
                        }
                    </div>
                </div>
            }
        </div>
    }
}
