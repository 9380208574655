import { Constants } from "../../../constants/constants";
import { PageMode } from "../../../constants/enums";
import type { AttributeModelType, ExtendedFieldAttributeModelType } from "../../../models";

export const findMeasureunit = (measureId: number) => Constants.MEASUREMENT_UNITS.find(({ id }) => id === measureId);

export const isInputSimpleType = (typeId: number) =>
  typeId === Constants.CONSTANT_ATTRIBUTES_DATATYPE_FREE_TEXT ||
  typeId === Constants.CONSTANT_METRICS_NUMBER ||
  typeId === Constants.CONSTANT_METRICS_CURRENCY ||
  typeId === Constants.CONSTANT_METRICS_PERCENTAGE ||
  typeId === Constants.CONSTANT_METRICS_RANK ||
  typeId === Constants.CONSTANT_METRICS_FREE_TEXT ||
  typeId === Constants.CONSTANT_MEASUREMENT_NUMBER ||
  typeId === Constants.CONSTANT_MEASUREMENT_CURRENCY ||
  typeId === Constants.CONSTANT_MEASUREMENT_PERCENTAGE ||
  typeId === Constants.CONSTANT_MEASUREMENT_RANK;

export const isDropDownInputType = (typeId: number) =>
  typeId === Constants.CONSTANT_ATTRIBUTES_DATATYPE_TEXT_MAPPING ||
  typeId === Constants.CONSTANT_ATTRIBUTES_DATATYPE_FILL_LOV_ENT ||
  typeId === Constants.CONSTANT_METRICS_TEXT_MAPPING ||
  typeId === Constants.CONSTANT_MEASUREMENT_TEXT_MAPPING;

export const isTreeInputType = (typeId: number) => typeId === Constants.CONSTANT_ATTRIBUTES_DATATYPE_FILL_LOV_ORG;

export const isDatePickerInputType = (typeId: number) => typeId === Constants.CONSTANT_ATTRIBUTES_DATATYPE_DATE;

export const isTextAreaInputType = (typeId: number) => typeId === Constants.CONSTANT_ATTRIBUTES_DATATYPE_MULTILINE_TEXT;

export const isOrgUnitType = (typeId: number) => typeId === Constants.CONSTANT_ATTRIBUTES_DATATYPE_FILL_LOV_ORG;

export const isUsersType = (typeId: number) => typeId == Constants.CONSTANT_ATTRIBUTES_DATATYPE_FILL_LOV_ENT;

export const valueInvalidation = (object: ExtendedFieldAttributeModelType) => {
  if (isDatePickerInputType(+object.dataType)) {
    object.valueTextAE = undefined;
    object.valueTextEN = undefined;
    object.valueNumber = undefined;
  } else if (isInputSimpleType(+object.dataType)) {
    object.valueDate = undefined;
  } else if (isDropDownInputType(+object.dataType) || isTreeInputType(+object.dataType)) {
    object.valueDate = undefined;
  } else if (isTextAreaInputType(+object.dataType)) {
    object.valueDate = undefined;
    object.valueNumber = undefined;
  }
  return object;
};

export const statusChecker = ({
  data,
  mode,
  attrs = [],
}: {
  data: AttributeModelType & { value: unknown };
  mode: PageMode;
  attrs?: ExtendedFieldAttributeModelType[];
}) => {
  if (mode === PageMode.create) {
    return Constants.OBJECT_STATUS.DRAFT;
  }

  if (attrs) {
    const found = (attrs ?? []).find((item) => item.attrId === data.id);
    if (found) {
      const value = getValue(data, found);
      return value === data.value ? Constants.OBJECT_STATUS.ACTIVE : Constants.OBJECT_STATUS.DRAFT;
    }
  }
  return Constants.OBJECT_STATUS.DRAFT;
};

export const getValue = (field: AttributeModelType, attr: ExtendedFieldAttributeModelType) => {
  let value: unknown;
  if (isDatePickerInputType(field.dataType)) {
    value = attr.valueDate;
  }

  if (isTextAreaInputType(field.dataType) || isInputSimpleType(field.dataType)) {
    value = attr.valueTextAE || attr.valueTextEN;
  }

  if (isOrgUnitType(field.dataType) || isTreeInputType(field.dataType) || isDropDownInputType(field.dataType)) {
    value = attr.valueNumber;
  }

  return value;
};

export const getFieldValueObject = (dataType: number, value: unknown) => {
  if (isDropDownInputType(dataType) || isTreeInputType(dataType) || isOrgUnitType(dataType) || isUsersType(dataType)) {
    return { valueNumber: Number(value) };
  } else if (isDatePickerInputType(dataType)) {
    return { valueDate: Number(value) };
  } else {
    return { valueTextAE: `${value}` };
  }
};
