import { AdaaHelper } from "../../../../../../../core/utils";
import { Constants } from "../../../../../../constants/constants";
import { SharedFields, teamMembersSectionConfig, textField } from "../../configs";
import {
  AccordionSectionConfigType,
  ListSectionConfigType,
  ScreenSectionConfigType,
  ScreenType,
} from "../../wf-difference.types";
import { actualEndDateField, actualStartDateField, milestonesSectionConfig } from "../tp-screen/milestones.config";

const cycleField = () => {
  const field = textField("cycle", { use: "current" }) as ScreenSectionConfigType;
  field.label = "annual_meetings.cycles.single";
  return field;
};
const projectField = () => {
  const field = textField("annualMeetingProjectName", { use: "current" }) as ScreenSectionConfigType;
  field.label = "annual_meetings.single";
  return field;
};

const allocatedBudgetField: ScreenSectionConfigType = {
  field: "budget",
  label: "initiatives.budget",
  view: "normal",
};

const expensesField: ScreenSectionConfigType = {
  field: "expense",
  label: "initiatives.expenses",
  view: "normal",
};

const progressField: ScreenSectionConfigType = {
  field: "progress",
  label: "box_status.activity_progress",
  view: "normal",
};

const signOffField: ScreenSectionConfigType = {
  field: "signOff",
  label: "national_projects.sign_off.justification",
  view: "normal",
};

const signOffAttachmentField: ScreenSectionConfigType = {
  field: "signOffAttachment",
  label: "national_projects.sign_off.evidence_wf_cs",
  view: "normal",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format: ($o) => ($o as any).signOffAttachment?.filename,
  isClickable: true,
};

const contributingEntitiesField: ListSectionConfigType = {
  field: "contributingEntities",
  label: "kpi.contributing_entities",
  view: "list",
  idKey: "entityId",
  derivedList: (data: { otherEntityId?: number }[]) => data.filter(($i) => !AdaaHelper.isDefined($i?.otherEntityId)),
  format: ($o) => AdaaHelper.getItemValueByToken($o, "entityName"),
};

const otherContributingEntitiesField: ListSectionConfigType = {
  field: "contributingEntities",
  label: "other_entities.contrib_entities",
  view: "list",
  idKey: "otherEntityId",
  derivedList: (data: { otherEntityId?: number }[]) => data.filter(($i) => AdaaHelper.isDefined($i?.otherEntityId)),
  format: ($o) => AdaaHelper.getItemValueByToken($o, "otherEntityName"),
};

const decisionsField: ListSectionConfigType = {
  field: "decisions",
  label: "national_projects.pmo_decisions",
  view: "list",
  idKey: "optionId",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format: ($o: any) => {
    let value = AdaaHelper.getItemValueByToken($o, "option");
    if ($o.optionId === Constants.CONSTANT_PMO_DECISION_OTHERS_ID) {
      value += ` (${$o.comment})`;
    }
    return value;
  },
  hasDiff: ($n: { optionId?: number; comment?: string }, $o: { optionId?: number; comment?: string }) => {
    if (
      $n.optionId === Constants.CONSTANT_PMO_DECISION_OTHERS_ID &&
      $o.optionId === Constants.CONSTANT_PMO_DECISION_OTHERS_ID
    ) {
      return $n.comment !== $o.comment;
    }

    return $n.optionId !== $o.optionId;
  },
};

const targetAudienceField: ListSectionConfigType = {
  field: "targetAudience",
  label: "national_projects.target_audience",
  view: "list",
  idKey: "id",
  format: ($o) => AdaaHelper.getItemValueByToken($o, "audience"),
};

const expectedImpactField: ListSectionConfigType = {
  field: "expectedImpact",
  label: "national_projects.expected_impact",
  view: "list",
  idKey: "id",
  format: ($o) => AdaaHelper.getItemValueByToken($o, "impact"),
};

const commentsField: ListSectionConfigType = {
  field: "comments",
  label: "national_projects.comments",
  view: "list",
  idKey: "id",
  format: ($o) => AdaaHelper.getItemValueByToken($o, "comments"),
};

const projectMilestonesField: AccordionSectionConfigType = {
  view: "accordion",
  field: "milestones",
  title: ($o) => AdaaHelper.getItemValueByToken($o, "name"),
  idKey: "id",
  config: milestonesSectionConfig,
  label: "national_projects.national_projects_milestone.title",
};

const projectTeamMembersField: AccordionSectionConfigType = {
  view: "accordion",
  field: "projectTeamMembers",
  title: ($o) => AdaaHelper.getItemValueByToken($o, "name"),
  idKey: "id",
  config: teamMembersSectionConfig,
  label: "national_projects.project_team_members.popup_title",
};

export const linkedNationalStrategiesField: ScreenSectionConfigType = {
  view: "linked-national-strategies",
  field: "govDirections",
  label: "national_strategies.strategies",
};

export const agmProjectScreenConfig = {
  type: "kpi" as ScreenType,
  sections: [
    cycleField(),
    ...SharedFields.name,
    ...SharedFields.description,
    {
      ...SharedFields.startDate,
      label: "national_projects.planned_start_date",
    },
    {
      ...SharedFields.endDate,
      label: "national_projects.planned_end_date",
    },
    {
      ...SharedFields.entityIdField,
      label: "national_projects.accountable_entity",
      format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "accountableEntityName"),
    },
    {
      ...SharedFields.ownerField,
      label: "national_projects.entity_lead",
    },
    {
      ...SharedFields.ownerField,
      label: "national_projects.project_coordinator",
      field: "projectCoordinator",
      format: (value: Record<string, string>) => AdaaHelper.getItemValueByToken(value, "projectCoordinatorName"),
    },
    SharedFields.orgUnitIdField,
    contributingEntitiesField,
    otherContributingEntitiesField,
    SharedFields.nationalSectorsField,
    decisionsField,
    targetAudienceField,
    expectedImpactField,
    commentsField,
    linkedNationalStrategiesField,
    projectMilestonesField,
    projectTeamMembersField,
    SharedFields.attachmentsField,
  ],
};

export const agmProjectMilestoneScreenConfig = {
  type: "kpi" as ScreenType,
  sections: [
    projectField(),
    ...SharedFields.name,
    ...SharedFields.description,
    {
      ...SharedFields.startDate,
      label: "national_projects.planned_start_date",
    },
    {
      ...SharedFields.endDate,
      label: "national_projects.planned_end_date",
    },
    actualStartDateField,
    actualEndDateField,
    {
      ...SharedFields.entityIdField,
      label: "national_projects.accountable_entity",
    },
    SharedFields.ownerField,
    contributingEntitiesField,
    otherContributingEntitiesField,
    allocatedBudgetField,
    expensesField,
    progressField,
    signOffField,
    signOffAttachmentField,
    SharedFields.nationalSectorsField,
    commentsField,
    linkedNationalStrategiesField,
    projectMilestonesField,
    projectTeamMembersField,
    SharedFields.attachmentsField,
  ],
};
