import { Component, computed, inject, Input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper, useNewDS } from "../../../../core/utils";
import { AdaaBoolean } from "../../../constants/enums";
import type { GovDirection, SubGovDirection, ValueText } from "../../../models";
import { GovDirectionApiService, SubGovDirectionApiService } from "../../../services";
import { FormCheckboxComponent, FormDropdownComponent } from "../../form";
import { NationalStrategyGovDirType, NationalStrategySubGovDirType } from "./types";

@Component({
  selector: "adaa-national-strategies-selector",
  standalone: true,
  imports: [TranslateModule, TranslateTokenPipe, FormDropdownComponent, FormCheckboxComponent],
  styleUrl: "../common.scss",
  templateUrl: "./national-strategies-selector.component.html",
})
export class NationalStrategiesSelectorComponent {
  @Input() public set govDirectionData(list: GovDirection[]) {
    this.selectedGovDirections.set(list);
  }

  @Input() public set subGovDirectionData(list: SubGovDirection[]) {
    this.selectedSubGovDirections.set(list);
  }

  @Input() public set mainSubGovDir(govDirId: number | null | undefined) {
    this.mainSubGovDirection.set(govDirId);
  }

  @Input() enableMainSelection: boolean = true;
  @Input() isTpPage: boolean = false;

  private readonly _subGovDirectionApiService = inject(SubGovDirectionApiService);
  private readonly _govDirectionApiService = inject(GovDirectionApiService);

  mainSubGovDirection = signal<number | null | undefined>(undefined);
  selectedGovDirections = signal<GovDirection[]>([]);
  selectedSubGovDirections = signal<SubGovDirection[]>([]);
  subGovDirections = signal<SubGovDirection[]>([]);
  govDirections = signal<GovDirection[]>([]);

  deselectedGovDirections = computed(() =>
    this.govDirections().filter(({ id }) => {
      const index = this.selectedGovDirections().findIndex((data) => data.id === id);
      return index === -1;
    })
  );
  govDirectionOptions = computed<ValueText[]>(() =>
    AdaaHelper.setDropdownArray(this.deselectedGovDirections() ?? [], "id", AdaaHelper.getFieldLanguage("name"))
  );
  deselectedSubGovDirections = computed(() =>
    this.subGovDirections().filter(({ id }) => {
      const index = this.selectedSubGovDirections().findIndex((data) => data.id === id);
      return index === -1;
    })
  );

  readonly useNewDS = () => useNewDS();
  readonly isMainGovDir = (id: number) => {
    return id === this.mainSubGovDirection();
  };
  readonly filterSubGovDirectionOptions = (id: number, list: SubGovDirection[] = []): ValueText[] => [
    ...AdaaHelper.setDropdownArray(
      this.filterSubGovDirections(id, list) ?? [],
      "id",
      AdaaHelper.getFieldLanguage("name")
    ),
  ];
  readonly filterSubGovDirections = (govDirId: number, list: SubGovDirection[] = []) =>
    list.filter(({ govDirectionId }) => govDirectionId === govDirId);

  public ngOnInit() {
    this._fetchGovDirections();
    this._fetchSubGovDirections();
  }

  public submit(): {
    governmentDirections: NationalStrategyGovDirType[];
    govSubDirections: NationalStrategySubGovDirType[];
  } {
    const subGovDir = this.selectedSubGovDirections().map<NationalStrategySubGovDirType>(
      (data) =>
        ({
          govDirectionId: data.govDirectionId!,
          isMain: this.enableMainSelection ? this.mainSubGovDirection() === data.id : false,
          nameAE: data.nameAE,
          nameEN: data.nameEN,
          dscAE: data.dscAE!,
          dscEN: data.dscEN!,
          hasAttachments: data.hasAttachments!,
          entityId: data.entityId!,
          owner: data.owner,
          id: data.id,
          status: data.status,
          updateTS: data.updateTS,
          updateUser: data.updateUser,
        }) as NationalStrategySubGovDirType
    );

    const govDirs = this.selectedGovDirections().map<NationalStrategyGovDirType>(
      (data) =>
        ({
          vision: data.vision,
          available: data.available,
          displayOrder: data.displayOrder,
          createTS: data.createTS,
          isNationalStrategy: data.isNationalStrategy,
          refCode: data.refCode,
          nameAE: data.nameAE,
          nameEN: data.nameEN,
          dscAE: data.dscAE,
          dscEN: data.dscEN,
          startDate: data.startDate,
          endDate: data.endDate,
          entityId: data.entityId,
          id: data.id,
          status: data.status,
          updateTS: data.updateTS,
          updateUser: data.updateUser,
        }) as NationalStrategyGovDirType
    );

    return {
      governmentDirections: govDirs,
      govSubDirections: subGovDir,
    };
  }

  public selectMainSubGovDirection({ id, checked }: { id: number; checked: boolean }) {
    const subGovDirection = this.selectedSubGovDirections().find((data) => data.id === id);
    if (subGovDirection && checked) {
      this.mainSubGovDirection.set(subGovDirection.id);
    }
    if (!checked) {
      this.mainSubGovDirection.set(undefined);
    }
  }

  public selectGovDirection(id: number, dropdown: FormDropdownComponent) {
    const direction = this.deselectedGovDirections().find((data) => data.id === id);
    if (direction) {
      this.selectedGovDirections.update((list) => [...list, direction]);
      dropdown.inputControl.reset();
    }
  }

  public selectSubGovDirection($e: Event, subGovDropdown: HTMLSelectElement) {
    const value = ($e.target as unknown as HTMLSelectElement).value;
    if (!AdaaHelper.isDefined(value) || value === "") return;

    const direction = this.deselectedSubGovDirections().find((data) => data.id === Number(value));
    if (direction) {
      this.selectedSubGovDirections.update((list) => [...list, direction]);
    }

    subGovDropdown.selectedIndex = 0;
  }

  public removeGovDirection(id: number) {
    this.selectedGovDirections.update((list) => list.filter((data) => data.id !== id));
    this.selectedSubGovDirections.update((list) => list.filter((data) => data.govDirectionId !== id));
  }

  public removeSubGovDirection(id: number) {
    this.selectedSubGovDirections.update((list) => list.filter((data) => data.id !== id));
  }

  private _fetchGovDirections() {
    this._govDirectionApiService.getAll().subscribe({
      next: (res) => {
        const list = (res.responseData ?? []).filter(({ available }) => available === AdaaBoolean.Y);
        this.govDirections.set(list);
      },
    });
  }

  private _fetchSubGovDirections() {
    this._subGovDirectionApiService.getAll().subscribe({
      next: (res) => {
        this.subGovDirections.set(res.responseData);
      },
    });
  }
}
