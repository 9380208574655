import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import type { ActivityModelType, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class ActivitiesApiService {
  private _mainService = inject(MainService);

  public create(isFinished: boolean, data: ActivityModelType) {
    const url = `activity/create?isFinish=${isFinished}`;

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "POST",
        url: url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public update(isFinished: boolean, data: ActivityModelType) {
    const url = `activity/update?isFinish=${isFinished}`;

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "POST",
        url: url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public deleteById(id: number) {
    const url = `activity/deleteById/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<unknown>>({
        method: "DELETE",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public cancelById(id: number) {
    const url = `activity/cancelActivityById/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<unknown>>({
        method: "POST",
        url: url,
        data: {},
      })
      .pipe(filter((res) => !res.inError));
  }

  public getCardById({ id, type }: { id: number; type: number }) {
    const url = `activity/getCardById/${id}?type=${type}`;

    return this._mainService
      .sendRequest<MainResponseModel<ActivityModelType>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getById({ id, type }: { id: number; type: number }) {
    const url = `activity/getbyid/${id}?type=${type}`;

    return this._mainService
      .sendRequest<MainResponseModel<ActivityModelType>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }
}
