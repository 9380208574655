<section class="row d-flex align-content-center">
    <!-- Kpi name -->
    <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
        {{ "data_entry.kpi_name" | translate }}
    </div>

    <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-center rounded-end-1">
        <a
            class="link"
            target="_blank"
            [routerLink]="['/', 'console', 'kpi', getKpitypeName(newDataentryObject()!.kpiType), newDataentryObject()?.kpiId]"
        >
            {{ newDataentryObject() | translateToken: "name" }}
        </a>
    </div>

    <!-- Kpi type -->
    @if (isDefinedAndNotEmpty(newDataentryObject()!.kpiType)) {
        <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
            {{ "data_entry.kpi_type_name" | translate }}
        </div>

        <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-center rounded-end-1">
            {{ newDataentryObject() | translateToken: "kpiType" }}
        </div>
    }

    <!-- Formula -->
    <div class="col-4 fw-bold py-2 px-3 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6 rounded-start-1">
        {{ "kpi.formula" | translate }}
    </div>

    <div class="col-8 py-2 px-3 border border-white tbl-col d-flex align-items-start align-content-start rounded-end-1 flex-wrap">
        <div
            class="d-block w-100"
            [class.mb-2]="isFormula()"
            [class.text-end]="isFormula() && languageService.direction() === 'rtl'"
            [dir]="isFormula() ? 'ltr' : languageService.direction()"
        >
            {{ getFormula(newDataentryObject()!.formula) | translate }}
        </div>

        @if (isFormula()) {
            <div class="d-block metric-details w-75 px-1">
                <div class="row d-flex w-100 flex-nowrap">
                    @for (detail of metricDetails(); track detail.id) {
                        <div class="col flex-shrink-0">
                            @if (detail.metricType) {
                                <i class="fa fa-pie-chart icon" (mouseover)="getInfo(detail.metricType)" [title]="title()"></i>
                            }
                            {{ detail | translateToken: "name" }}
                        </div>
                    }
                </div>

                <div class="row d-flex w-100 flex-nowrap">
                    @for (detail of metricDetails(); track detail.id) {
                        <div class="col flex-shrink-0">
                            {{ detail | translateToken: "dsc" }}
                        </div>
                    }
                </div>

                <div class="row d-flex w-100 flex-nowrap">
                    @for (detail of metricDetails(); track detail.id) {
                        <div class="col flex-shrink-0">
                            {{ formatFromConstants("ytp", detail.ytpCalc) }}
                        </div>
                    }
                </div>
            </div>
        }
    </div>
</section>

<section class="row d-block mt-2 formula-table-container">
    <table class="w-100 table">
        <thead>
            <tr>
                @if (isNoFormula()) {
                    <td class="data-entry-col thead" [attr.colspan]="!isAuditTrail() ? 3 + (isBounded() ? 2 : 1) : 1"></td>
                } @else {
                    <td class="data-entry-col thead" [attr.colspan]="!isAuditTrail() ? 2 + metricList()!.length + (isBounded() ? 2 : 1) : 1"></td>
                }
                <td class="data-entry-col thead" [attr.colspan]="2">{{ "data_entry.audited_annual_actual" | translate }}</td>
                <td class="data-entry-col thead" [attr.colspan]="2">{{ "common.form.label.comments" | translate }}</td>
            </tr>

            <tr>
                <td class="data-entry-col thead" rowspan="2"></td>

                @if (!isAuditTrail()) {
                    <td class="data-entry-col thead" [attr.rowspan]="!isBounded() ? 2 : 1" [attr.colspan]="isBounded() ? 2 : 1">
                        {{ "data_entry.target" | translate }}
                    </td>

                    @if (isNoFormula()) {
                        <td rowspan="2" class="data-entry-col thead">{{ "data_entry.actual" | translate }}</td>
                    } @else {
                        <td [attr.colspan]="metricList()!.length" class="data-entry-col thead">{{ "data_entry.actual" | translate }}</td>
                    }

                    <td rowspan="2" class="data-entry-col thead">{{ "data_entry.annual_actual" | translate }}</td>
                }

                <td rowspan="2" class="data-entry-col thead">{{ "communication.difference.new_value" | translate }}</td>
                <td rowspan="2" class="data-entry-col thead">{{ "communication.difference.old_value" | translate }}</td>

                <td rowspan="2" class="data-entry-col thead">{{ "communication.difference.new_value" | translate }}</td>
                <td rowspan="2" class="data-entry-col thead">{{ "communication.difference.old_value" | translate }}</td>
            </tr>

            <tr>
                @if (!isAuditTrail()) {
                    @if (isBounded()) {
                        <td class="data-entry-col thead">
                            {{ "kpi.lower_limit" | translate }}
                        </td>
                        <td class="data-entry-col thead">
                            {{ "kpi.upper_limit" | translate }}
                        </td>
                    }

                    @for (metric of metricList(); track metric) {
                        <td class="data-entry-col thead">{{ metric.metricNameAE }}</td>
                    }
                }
            </tr>
        </thead>

        <tbody>
            @for (metric of newMetrics(); track metric) {
                <tr>
                    <td class="data-entry-col period-col fw-bold">{{ getNoFormulaPeriodLabel(metric) }}&nbsp;</td>

                    @if (!isAuditTrail()) {
                        <!-- target column -->
                        @if (!isBounded()) {
                            <td class="data-entry-col">
                                @if (!isBaselineMetric(metric.year)) {
                                    {{ metric?.target }}&nbsp;
                                } @else {
                                    {{ "kpi.baselineYear" | translate }}&nbsp;
                                }
                            </td>
                        } @else {
                            <td class="data-entry-col">
                                @if (metric.year !== newDataentryRequests().baselineYear) {
                                    {{ metric?.lowerLimit }}&nbsp;
                                } @else {
                                    {{ "kpi.baselineYear" | translate }}&nbsp;
                                }
                            </td>

                            <td class="data-entry-col">
                                @if (metric.year !== newDataentryRequests().baselineYear) {
                                    {{ metric?.highLimit }}&nbsp;
                                } @else {
                                    {{ "kpi.baselineYear" | translate }}&nbsp;
                                }
                            </td>
                        }

                        <!-- actual -->
                        @if (isFormula()) {
                            @for (metricDetail of metric.metrics; track metricDetail) {
                                <!-- actual -->
                                <td class="data-entry-col">{{ metricDetail?.ignored ? "N/A" : metricDetail?.actual }}&nbsp;</td>
                            }
                        } @else {
                            <td class="data-entry-col">{{ metric?.ignored ? "N/A" : metric?.actual }}&nbsp;</td>
                        }

                        @if (shouldShowRow(metric.date)) {
                            <!-- annual-actual -->
                            <td class="data-entry-col" [attr.rowspan]="spanRows(metric.year)">
                                {{ getAnnualActual(metric.year) }}
                                &nbsp;
                            </td>
                        }
                    }

                    @if (shouldShowRow(metric.date)) {
                        <!-- new data: audited annual actual -->
                        <td class="data-entry-col" [attr.rowspan]="spanRows(metric.year)" [class.has-diff]="showDiff(metric.year, 'auditedActual')">
                            {{ getAuditedAnnualReading(metric.year, { item: "auditedActual", type: "new" }) }}
                            &nbsp;
                        </td>
                        <!-- old data: audited annual actual -->
                        <td class="data-entry-col" [attr.rowspan]="spanRows(metric.year)" [class.has-diff]="showDiff(metric.year, 'auditedActual')">
                            {{ getAuditedAnnualReading(metric.year, { item: "auditedActual", type: "old" }) }}
                            &nbsp;
                        </td>
                    }

                    @if (shouldShowRow(metric.date)) {
                        <!-- new data: comment -->
                        <td class="data-entry-col" [attr.rowspan]="spanRows(metric.year)" [class.has-diff]="showDiff(metric.year, 'comment')">
                            {{ getAuditedAnnualReading(metric.year, { item: "comment", type: "new" }) }}
                            &nbsp;
                        </td>
                        <!-- old data: comment -->
                        <td class="data-entry-col" [attr.rowspan]="spanRows(metric.year)" [class.has-diff]="showDiff(metric.year, 'comment')">
                            {{ getAuditedAnnualReading(metric.year, { item: "comment", type: "old" }) }}
                            &nbsp;
                        </td>
                    }
                </tr>
            }
        </tbody>
    </table>
</section>
