/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Chart, Point } from "chart.js";

import { AdaaHelper } from "../../../core/utils";
import { Constants } from "../../constants/constants";
import { Language } from "../../constants/enums";

type FileToExportRow = { Chart: string } | { Period: string; [key: `Dataset ${number}`]: string | number | "" } | any;

type HeaderRow = {
  Period: string;
  [key: `Dataset ${number}`]: string;
};

type DataRow = {
  Period: string;
  [key: `Dataset ${number}`]: number | "" | Point | [number, number] | null;
};

@Component({
  selector: "adaa-chart-action-buttons",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./chart-action-buttons.component.html",
  styleUrl: "./chart-action-buttons.component.scss",
})
export class ChartActionButtonsComponent {
  elementId = input.required<string>();
  elementIds = input<string[]>();
  chartTitles = input<string[]>();
  extraContent = input<string[]>();
  exportName = input<string>();
  title = input<string>();
  csvChartInfo = input<string>();
  enableDownloadImage = input<boolean>(true);
  enableDownloadPDF = input<boolean>(true);
  enableDownloadCSV = input<boolean>(true);

  public exportPDF() {
    const injectStyles = `
      body {
        direction: ${AdaaHelper.getCurrentLang() === Language.Arabic ? "rtl" : "ltr"};
        margin: 0;
        padding: 0;
      }
      .print-container {
        text-align: center;
        margin: 50px 0;
      }
      .page-title{
        margin: 20px 0;
      }
      .chart-title{
        margin-top: 1000px 0;
      }
      img {
        max-width: 100%;
        height: auto;
      }
      @media print {
        * {
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
        }
      }
    `;

    const parentDiv = document.querySelector(".sys-layout--topbar");
    let canvasDataURL;

    if (parentDiv) {
      const secondChild = parentDiv.children[1];
      if (secondChild) {
        parentDiv.removeChild(secondChild);
      }
    }

    let printContents = `
    <div class="govbar">${parentDiv?.outerHTML}</div>
    ${this.title() ? '<div class="page-title">${this.title()}</div>' : ""}
  `;

    if (this.elementIds()) {
      for (let index = 0; index < this.elementIds()!.length; index++) {
        const element = this.elementIds()![index];
        const url = this._getCanvasDataURL(element);
        if (this.chartTitles()) {
          if (this.chartTitles()![index] && AdaaHelper.isDefinedAndNotEmpty(this.chartTitles()![index])) {
            printContents += `
            <div class="chart-title">
              <p>${this.chartTitles()![index]}</p>
            </div>`;
          }
        }
        printContents += `
        <div class="print-container">
          <img src="${url}" alt="Canvas Content" />
        </div>
        `;
        if (this.extraContent()) {
          if (this.extraContent()![index] && AdaaHelper.isDefinedAndNotEmpty(this.extraContent()![index])) {
            printContents += `${this.extraContent()![index]}`;
          }
        }
      }
    } else {
      canvasDataURL = this._getCanvasDataURL(this.elementId());
      if (!canvasDataURL) return;
      printContents += `
        <div class="print-container">
          <img src="${canvasDataURL}" alt="Canvas Content" />
        </div>
      `;
      const canvas = document.getElementById(this.elementId()) as HTMLCanvasElement;
      const chartInstance: any = Chart.getChart(canvas);
      if (chartInstance?.config._config.type === "pie") {
        printContents += this._getStringifiedChartData(canvas) ?? "";
      }
    }

    const popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    if (!popupWin) return;

    popupWin.document.write(`
      <html>
        <head>
          <title>CHART</title>
          <style>${injectStyles}</style>
        </head>
        <body onload="window.print(); window.close();">
          ${printContents}
        </body>
      </html>
    `);
    popupWin.document.close();
  }

  public exportImage() {
    const canvas = document.getElementById(this.elementId()) as HTMLCanvasElement;
    if (!canvas) {
      return;
    }

    const tempCanvas = document.createElement("canvas");
    const context = tempCanvas.getContext("2d");

    if (context) {
      const originalWidth = canvas.width;
      const originalHeight = canvas.height;

      const scaleFactor = 2;
      const padding = 50 * scaleFactor;
      tempCanvas.width = originalWidth * scaleFactor + padding * 2;
      tempCanvas.height = originalHeight * scaleFactor + padding * 2;

      context.fillStyle = "#ffffff";
      context.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

      const offsetX = (tempCanvas.width - originalWidth * scaleFactor) / 2;
      const offsetY = (tempCanvas.height - originalHeight * scaleFactor) / 2;

      context.drawImage(
        canvas,
        0,
        0,
        originalWidth,
        originalHeight,
        offsetX,
        offsetY,
        originalWidth * scaleFactor,
        originalHeight * scaleFactor
      );

      const chartInstance: any = Chart.getChart(canvas);
      if (chartInstance?.config._config.type === "pie") {
        const data = this._getStringifiedChartData(canvas) ?? "";

        context.font = `${11 * scaleFactor}px Arial`;
        context.fillStyle = "#706666";
        context.textAlign = "center";

        const textX = tempCanvas.width / 2;
        const textY = tempCanvas.height - 100 / 2;
        context.fillText(data!, textX, textY);
      }

      const imageData = tempCanvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = imageData;
      downloadLink.download = "chart.png";
      downloadLink.click();
    }
  }

  private _convertToCSV(data: FileToExportRow[]): string {
    if (!data || data.length === 0) return "";

    let csvContent = "";
    data.forEach((row) => {
      const keys = Object.keys(row);
      if (keys.length === 1 && keys[0] === "Chart") {
        csvContent += `\r\n${row[keys[0]]}\r\n`;
        return;
      }

      const values = keys.map((key) => (row[key] !== undefined && row[key] !== null ? JSON.stringify(row[key]) : ""));
      csvContent += values.join(",") + "\r\n";
    });

    return csvContent;
  }

  private _getStringifiedChartData(canvas: HTMLCanvasElement) {
    const chartInstance = Chart.getChart(canvas);
    if (!chartInstance?.data) {
      return;
    }
    const chartData = chartInstance.data;
    return chartData.labels?.map((label, index) => `${label}: ${chartData.datasets[0].data[index]}`).join(", ");
  }

  public exportCSV(): void {
    const ids = Array.isArray(this.elementIds()) ? this.elementIds() : [this.elementId()];

    const fileToExport: FileToExportRow[] = [];

    ids!.forEach((canvasId) => {
      const canvas = document.getElementById(canvasId!) as HTMLCanvasElement | null;
      if (!canvas) {
        return;
      }

      const chartInstance = Chart.getChart(canvas);
      if (!chartInstance) {
        return;
      }

      const lang = AdaaHelper.getFieldLanguage("name");
      const entity = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
        type: "prop",
        property: lang,
      });

      fileToExport.push({ Chart: entity });
      fileToExport.push({ Chart: this.csvChartInfo() ?? "" });

      const chartData = chartInstance.data;
      if (chartData?.labels && chartData.datasets) {
        const headerRow: HeaderRow = { Period: "Period" };
        chartData.datasets.forEach((dataset, i) => {
          headerRow[`Dataset ${i + 1}`] = dataset.label || `Series ${i + 1}`;
        });
        fileToExport.push(headerRow);

        chartData.labels.forEach((label, i) => {
          const dataRow: DataRow = { Period: label as string };
          chartData.datasets.forEach((dataset, j) => {
            dataRow[`Dataset ${j + 1}`] = dataset.data[i] !== undefined ? dataset.data[i] : "";
          });
          fileToExport.push(dataRow);
        });

        fileToExport.push({});
      }
    });

    const csvContent = this._convertToCSV(fileToExport);

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const fileName = `charts_export_${new Date().toISOString()}.csv`;
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private _getCanvasDataURL = (elementId: string): string | null => {
    const canvas = document.getElementById(elementId) as HTMLCanvasElement | null;
    if (!canvas) return null;
    return canvas.toDataURL();
  };
}
