<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title w-100 text-center">{{ "workflow.pending_action_users" | translate }}</h4>

        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body pb-3 pt-2 px-2" [class.overflow-x-hidden]="!tableData().length">
        @if (tableData().length) {
            <adaa-data-table
                [configKey]="tableConfList"
                [enableAdd]="false"
                [enablePrint]="false"
                [enableActionsCell]="false"
                [localData]="tableData()"
                [isLocalData]="true"
            ></adaa-data-table>
        } @else if (tableData().length === 0) {
            <h4 class="text-center d-block mb-4 mt-2 text-muted">
                {{ "common.tables.no_info" | translate }}
            </h4>

            <p class="text-center fw-bold fs-6 mt-2">
                {{ "workflow.workflow_pending_user_warn" | translate }}
            </p>
        } @else {
            <div class="w-100 d-flex justify-content-center py-3">
                <div class="zsk__loader zsk__loader--sm"></div>
            </div>
        }
    </div>
</ng-template>
