import { DatePipe } from "@angular/common";
import { Component, inject, input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import {
  DubaiTimePipe,
  NumberCommaPipe,
  PercentageValuePipe,
  RoundValuePipe,
  TranslateTokenPipe,
} from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { ImportExportStatus, ImportExportType, PublishedState } from "../../../constants/enums";
import { ParameterCatalog } from "../../../models";
import { FilesApiService } from "../../../services";

@Component({
  selector: "adaa-data-table-cell-special",
  standalone: true,
  templateUrl: "./data-table-cell-special.component.html",
  styleUrl: "./data-table-cell-special.component.scss",
  imports: [
    TranslateModule,
    RoundValuePipe,
    PercentageValuePipe,
    TranslateTokenPipe,
    DatePipe,
    NumberCommaPipe,
    DubaiTimePipe,
  ],
})
export class DataTableCellSpecialComponent {
  readonly filesApiService = inject(FilesApiService);
  private readonly _route = inject(ActivatedRoute);

  configKey = input.required<string>();
  column = input.required<ParameterCatalog>();
  data = input.required<{
    [k: string]: unknown;
  }>();

  readonly PublishedState = PublishedState;
  readonly ImportExportStatus = ImportExportStatus;
  readonly ImportExportType = ImportExportType;
  readonly Constants = Constants;
  readonly AdaaHelper = AdaaHelper;

  readonly isActivitiesViewPage = () => this._route.component?.name === "_ActivitiesViewComponent";

  public isCommentEmpty(data: { [k: string]: unknown }) {
    if (!data?.comments) return true;
    return !AdaaHelper.isDefinedAndNotEmpty((data.comments as string).trim());
  }

  public getNumberValue(fieldName: string) {
    return this.data()[fieldName] as number;
  }

  public getArrayValue(fieldName: string) {
    return this.data()[fieldName] as { categoryNameAE: string; categoryNameEN: string; comments: string; id: number }[];
  }
}
