<div class="row">
    <div class="col-12">
        <div class="slider-container bg-white shadow-md rounded p-1">
            <div class="row">
                @if (!dontShowYears()) {
                    <span class="col-12 panel-title">
                        {{ "landingPage.entity_page.select_period" | translate }}
                    </span>
                }
                <div class="col-12 text-center" [class.pb-2]="dontShowYears()">
                    @if (!isReady()) {
                        <div class="w-100 d-flex justify-content-center py-2">
                            <div class="zsk__loader zsk__loader--sm"></div>
                        </div>
                    } @else if (isReady() && selectedPeriod() && (options.stepsArray ?? []).length > 0) {
                        <ngx-slider [value]="selectedPeriod()!" [options]="options" (valueChange)="periodChanged($event)"></ngx-slider>
                    }
                </div>
            </div>
        </div>
    </div>
</div>
