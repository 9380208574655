import { AdaaBoolean, FilterType, Language } from "../constants/enums";

export enum ObjectStatus {
  ACTIVE = 1,
  REMOVE,
  DRAFT,
  CLOSED,
  ARCHIVED,
  PLANNING,
  IN_REVIEW,
}

export interface RadioOption {
  value: unknown;
  text: string;
}

export interface CustomDataTableButton {
  title: string;
  icon?: string;
}

export interface FormCatalog {
  entityId?: number;
  id: number;
  nameAE: string;
  nameEN: string;
  parameterCatalogs: ParameterCatalog[];
  planId?: number;
  searchKey?: string;
  status: number;
  type: number;
  updateTS?: number;
  updateUser?: string;
}

export interface ParameterCatalog {
  alignment: "R" | "L" | "C" | "B";
  animation?: string;
  dbFieldName: string;
  descriptionAE?: string;
  descriptionEN?: string;
  displayOrder: number;
  fieldName: string;
  fieldType: string;
  fieldTypeId: number;
  filterType: FilterType;
  formId: number;
  iconKey?: string;
  id: number;
  lang: Language;
  mandatory: AdaaBoolean;
  mobileDisplay: AdaaBoolean;
  nameAE: string;
  nameEN: string;
  permissions?: string;
  planDataRevId?: number;
  positionAE?: string;
  positionEN?: string;
  searchKey: string;
  show: boolean;
  status: number;
  step: "en" | "ae";
  targetEntity?: number;
  translateKey?: "string";
  updateTS?: number;
  updateUser?: string;
  url?: string;
  urlRoute?: string;
  visibleParameter: AdaaBoolean;
  userVisibleParameter?: AdaaBoolean;
  width?: number;

  //ONLY FE
  dropdown: ValueText[];
}

export interface ValueText {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  text: string;
  children?: ValueText[];
}
export interface FormTab {
  title: string;
  id?: string;
  hasError?: boolean;
  disabled?: boolean;
}

export type PropTypeModelType = {
  propType: number;
  nameAE: string;
  nameEN: string;
  displayOrder?: number;
  enabled: AdaaBoolean;
  value: string;
  propParentId?: number;
  propParentNameAE?: string;
  propParentNameEN?: string;
  propParentValue?: string;
  createTS: number;
  id: number;
  status: number;
  updateTS: number;
  updateUser?: string;
};

export type AttachFile = {
  id: number;
  filename: string;
};

export type TokenOptionType = {
  unit: number | string;
  str: string;
  as?: "percent";
};
