import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";

import { environment } from "../../core/environments/environment";
import { AdaaHelper } from "../../core/utils";
import { MainResponseModel } from "../models";
import { LoaderService } from "./loader.service";

type BaseRequestTypeOptionType = {
  url: string;
  apiUrl?: string;
  headers?: HttpHeaders;
  loader?: boolean;
  ignoreNotifications?: boolean;
  responseType?: "json" | "blob" | "arraybuffer" | "text" | undefined;
};
type GetRequestOptionType = BaseRequestTypeOptionType & {
  method: "GET" | "DELETE";
  data?: unknown;
};
type PostDataRequestOptionType = BaseRequestTypeOptionType & {
  method: "POST" | "PUT";
  data: unknown;
};
type RequestOptionType = GetRequestOptionType | PostDataRequestOptionType;

@Injectable({
  providedIn: "root",
})
export class MainService {
  private readonly _httpClient = inject(HttpClient);

  httpHeader = new HttpHeaders({
    "Content-Type": "application/json",
  });

  method = {
    GET: "GET",
    DELETE: "DELETE",
    PUT: "PUT",
    POST: "POST",
  };

  /**
   * Handles all supported HTTP Requests
   */
  public sendRequest<T = MainResponseModel>(args: RequestOptionType): Observable<T> {
    const context = new HttpContext();
    context.set(LoaderService.useLoaderToken, args.loader ?? true);
    context.set(AdaaHelper.suppressNotificationToken, args.ignoreNotifications ?? false);

    const url = `${args?.apiUrl ?? environment.apiUrl}${args.url}`;
    const headers = args?.headers ?? this.httpHeader;
    const responseType = args?.responseType ?? "json";

    if (args.method === "POST" || args.method === "PUT") {
      return this._httpClient
        .request(args.method, url, {
          body: args?.data,
          observe: "body",
          responseType,
          headers,
          context,
          reportProgress: true,
        })
        .pipe(catchError(this._processError));
    }

    return this._httpClient
      .request(args.method, url, {
        observe: "body",
        responseType,
        headers,
        context,
        reportProgress: true,
        ...(args.method === "DELETE" && args.data ? { body: args.data } : {}),
      })
      .pipe(catchError(this._processError));
  }

  /**
   * @description Here is the error handler
   * @param err
   * @praivate
   */
  private _processError(err: HttpErrorResponse): Observable<never> {
    let message = "";
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    return throwError(() => {
      new Error(message);
    });
  }
}
