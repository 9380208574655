/* eslint-disable  @typescript-eslint/no-explicit-any */

import { Component, inject, input, OnInit, signal } from "@angular/core";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../../../../core/pipes";
import { AdaaHelper } from "../../../../../../../core/utils";
import { Constants } from "../../../../../../constants/constants";
import { BenchmarkModelType, PropTypeModelType } from "../../../../../../models";
import { LanguageService, PropertiesService } from "../../../../../../services";
import { AttachmentDiffViewComponent } from "../attachment-diff-view/attachment-diff-view.component";

@Component({
  selector: "adaa-benchmark-diff-view",
  imports: [TranslateModule, TranslateTokenPipe, AttachmentDiffViewComponent, NgbAccordionModule],
  templateUrl: "./benchmark-diff-view.component.html",
  styleUrls: ["../styles.scss", "./benchmark-diff-view.component.scss"],
})
export class BenchmarkDiffViewComponent implements OnInit {
  private _propertiesService = inject(PropertiesService);
  readonly languageService = inject(LanguageService);
  benchmarks = signal<
    {
      hasDiff: boolean;
      new?: BenchmarkModelType;
      old?: BenchmarkModelType;
    }[]
  >([]);

  showOnlyNew = input.required<boolean>();
  newData = input<BenchmarkModelType[]>([]);
  oldData = input<BenchmarkModelType[]>([]);
  benchTypes = signal<PropTypeModelType[]>([]);
  Constants = Constants;

  public benchType(value: number) {
    const type = this.benchTypes().find((type) => type.id === value);
    return type ? AdaaHelper.getItemValueByToken(type, "name") : "";
  }

  public isEqual($new: unknown, $old: unknown) {
    return $new === $old;
  }

  public ngOnInit(): void {
    this._propertiesService.getPropById(Constants.CONSTANT_BENCHTYPE).subscribe({
      next: (response) => {
        this.benchTypes.set(response.responseData);
      },
    });
    this._processbenchmarks();
  }

  private _processbenchmarks() {
    const result = [];

    const newData = this.newData() ?? [];
    const oldData = this.oldData() ?? [];

    for (const newItem of newData) {
      const oldItem = oldData.find((data) => data.id === newItem.id);
      const hasDiff = oldItem ? newItem.status !== oldItem.status : false;

      result.push({
        new: { ...newItem },
        ...(oldItem && { old: { ...oldItem } }),
        hasDiff,
      });
    }

    for (const oldItem of oldData) {
      const newItem = newData.find((data) => data.id === oldItem.id);
      if (!newItem) {
        result.push({
          old: { ...oldItem },
          hasDiff: true,
        });
      }
    }

    this.benchmarks.set(result);
  }

  public mergeNewAndOld<T>(newData: any[], oldData: any[]): any[] {
    const mergedMap = new Map<any, { new?: T; old?: T }>();

    for (const newItem of newData) {
      mergedMap.set(newItem.id, { new: newItem });
    }

    for (const oldItem of oldData) {
      const idValue = oldItem.id;
      if (mergedMap.has(idValue)) {
        mergedMap.get(idValue)!.old = oldItem;
      } else {
        mergedMap.set(idValue, { old: oldItem });
      }
    }

    const res = Array.from(mergedMap.values());
    return res;
  }
}
