import { Component, computed, inject, Input, OnInit, Signal, viewChild } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { AdaaHelper, useNewDS } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { UserGrantModelType, ValueText } from "../../../models";
import { UserEditorService } from "../../../services";
import { FormDropdownComponent, FormDropdownTreeComponent, FormTwoWaySelectComponent } from "../../form";

@Component({
  selector: "adaa-user-grants-modal",
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    FormDropdownComponent,
    FormDropdownTreeComponent,
    FormTwoWaySelectComponent,
  ],
  templateUrl: "./user-grants-modal.component.html",
  styleUrl: "./user-grants-modal.component.scss",
})
export class UserGrantsModalComponent implements OnInit {
  private _translateService = inject(TranslateService);
  modal = inject(NgbActiveModal);
  userEditorService = inject(UserEditorService);

  @Input() entities: ValueText[] = [];
  @Input() userGrants: UserGrantModelType[] = [];

  private _grantsSelect = viewChild.required<FormTwoWaySelectComponent>("grantsSelect");

  grantsForm: FormGroup;
  adaaHelper = AdaaHelper;
  readonly useNewDS = () => useNewDS();

  orgUnitsOptions: Signal<ValueText[]> = computed(() => {
    const orgUnits = AdaaHelper.setDropdownArray(
      this.userEditorService.orgUnitsGrantsModal(),
      "id",
      AdaaHelper.getFieldLanguage("name"),
      "parentOrgUnit"
    );
    orgUnits.unshift({ value: null, text: this._translateService.instant("common.form.label.none") });

    return orgUnits;
  });

  public get grantsOptions(): ValueText[] {
    return this.grantsForm.get("entityId")?.value ? this.userEditorService.grants() : [];
  }

  public ngOnInit(): void {
    this.grantsForm = this.userEditorService.prepareGrantsForm();
    this.setGrantsDdlValue();
  }

  public entityIdChanged(value: number) {
    this.userEditorService.getOrgUnits(value, true);
    this.setGrantsDdlValue();
    this._grantsSelect().doSearch();
  }

  public orgUnitIdChanged() {
    this.setGrantsDdlValue();
    this._grantsSelect().doSearch();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public grantsChanged(values: any) {
    if (!values) return;
    const entityId = this.grantsForm.get("entityId")!.value as number;
    const orgUnitId = this.grantsForm.get("orgUnitId")!.value as number;

    const userObject = this.userEditorService.user();
    const entityObject = this.userEditorService.entities().find((e) => e.value === entityId);
    const orgUnitObject = this.userEditorService.orgUnitsGrantsModal().find((e) => e.id === orgUnitId);

    for (let index = 0; index < values.length; index++) {
      const roleId = values[index];

      const grant = this.userGrants.find(
        (e) => e.roleId === roleId && e.entityId == entityId && e.orgUnitId == orgUnitId
      );

      const grantObject = this.userEditorService.grants().find((e) => e.value === roleId);

      if (!grant)
        this.userGrants.push({
          id: undefined,
          fakeId: Math.floor(Math.random() * 217 * 1000),
          roleId: roleId,
          roleNameEN: grantObject ? grantObject.text : "",
          roleNameAE: grantObject ? grantObject.text : "",
          status: Constants.OBJECT_STATUS.ACTIVE,
          userId: userObject ? userObject.id : undefined,
          entityId: entityId,
          entityNameEN: entityObject ? entityObject.text : "",
          entityNameAE: entityObject ? entityObject.text : "",
          orgUnitId: orgUnitId,
          orgUnitNameEN: orgUnitObject ? orgUnitObject.nameEN : "",
          orgUnitNameAE: orgUnitObject ? orgUnitObject.nameAE : "",
          orgUnitPlanId: orgUnitObject && orgUnitObject.planId !== 0 ? orgUnitObject?.planId : undefined,
          orgUnitPlanNameEN: orgUnitObject ? orgUnitObject.planNameEN : "",
          orgUnitPlanNameAE: orgUnitObject ? orgUnitObject.planNameAE : "",
          updateUser: undefined,
          updateTS: undefined,
        });
    }

    //Selected entity and orgUnit
    let currentGrants = this.userGrants.filter((e) => e.entityId == entityId && e.orgUnitId == orgUnitId);

    //Other Grants
    const grants = this.userGrants.filter((e) => e.entityId != entityId || e.orgUnitId != orgUnitId);

    currentGrants = currentGrants.filter((e) => values.includes(e.roleId));

    this.userGrants = [...currentGrants, ...grants];

    this.setGrantsDdlValue();
  }

  public save(): void {
    this.modal.close(this.userGrants);
  }

  public setGrantsDdlValue(): void {
    const entityId = this.grantsForm.get("entityId")?.value;
    const orgUnitId = this.grantsForm.get("orgUnitId")?.value;

    const grants = this.userGrants
      .filter(
        (e) =>
          (entityId ? e.entityId === +entityId : e.entityId == null) &&
          (orgUnitId ? e.orgUnitId === +orgUnitId : e.orgUnitId == null)
      )
      .map((e) => e.roleId);

    //Sometimes the grants value will be null this to avoid that
    setTimeout(() => {
      this.grantsForm.get("grants")?.setValue(grants);
    }, 0);
  }
}
