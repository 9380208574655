import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import { AdaaBoolean } from "../constants/enums";
import { AuxGovDirection, GovDirection, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class GovDirectionApiService {
  private _mainService = inject(MainService);

  public getAll() {
    const url = `govdirection/getall`;

    return this._mainService
      .sendRequest<MainResponseModel<GovDirection[]>>({
        method: "GET",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getAllByVision(vision: AdaaBoolean = AdaaBoolean.Y) {
    const url = `govdirection/getallbyvision/${vision}`;

    return this._mainService.sendRequest<MainResponseModel<GovDirection[]>>({
      method: "GET",
      url: url,
    });
  }

  public delete(id: number) {
    const url = `govdirection/deleteById/${id}`;

    return this._mainService.sendRequest<MainResponseModel>({
      method: "DELETE",
      url,
    });
  }

  public getDirection(id: number) {
    const url = `govdirection/getbyid/${id}`;

    return this._mainService.sendRequest<MainResponseModel<GovDirection>>({
      method: "GET",
      url,
    });
  }

  public getAuxiliaryDirection(id: number) {
    const url = `govdirection/getbyid/${id}/auxiliary-directions`;

    return this._mainService.sendRequest<MainResponseModel<AuxGovDirection[]>>({
      method: "GET",
      url,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public saveDirection(action: "create" | "update", data: any, isFinish: boolean = true) {
    const url = `govdirection/${action}?isFinish=${isFinish}`;
    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      data,
      url,
    });
  }
}
