import { Component, computed, effect, inject, input, signal, untracked } from "@angular/core";
import { TranslatePipe } from "@ngx-translate/core";
import { filter, map } from "rxjs";

import { DataEntryMetricModel } from "../../../../../../models";
import { MetricApiService } from "../../../../../../services";
import { NormalDiffViewComponent } from "../../_lib";
import { commonMetricValueScreenConfig } from "./metric-actual-value-screen.config";

@Component({
  selector: "adaa-metric-actual-value-screen",
  standalone: true,
  imports: [TranslatePipe, NormalDiffViewComponent],
  templateUrl: "./metric-actual-value-screen.component.html",
  styleUrl: "./metric-actual-value-screen.component.scss",
})
export class MetricActualValueScreenComponent {
  private readonly _metricApiService = inject(MetricApiService);

  showOnlyNew = input.required<boolean>();
  isNew = input<boolean>(false);
  newObject = input.required<Record<string, never>>();
  oldObject = input.required<Record<string, never>>();
  viewOnlyDifferences = input<boolean>(false);

  isReady = signal<boolean>(false);
  metricsData = signal<DataEntryMetricModel | undefined>(undefined);

  newData = computed(() => {
    if (!this.metricsData()) return this.newObject();
    return {
      ...this.metricsData(),
      ...this.newObject(),
    } as Record<string, never>;
  });
  oldData = computed(() => {
    if (!this.metricsData()) return this.oldObject();
    return {
      ...this.metricsData(),
      ...this.oldObject(),
    } as Record<string, never>;
  });

  readonly config = commonMetricValueScreenConfig;

  constructor() {
    effect(() => {
      const data = this.newObject();

      untracked(() => {
        this._metricApiService
          .getById(data.metricId as number)
          .pipe(
            filter((res) => !res.inError),
            map((res) => res.responseData)
          )
          .subscribe({
            next: (data) => {
              this.metricsData.set(data);
              this.isReady.set(true);
            },
          });
      });
    });
  }
}
