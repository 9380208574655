import { inject, Injectable, signal } from "@angular/core";

import type { GovEnablerDirectionsModelType } from "../models";
import { CalculationEngineApiService } from "./calculation-engine-api.service";

@Injectable({
  providedIn: "root",
})
export class NationalStrategiesPerfService {
  private readonly _calcEngineApiService = inject(CalculationEngineApiService);

  directions = signal<GovEnablerDirectionsModelType[]>([]);

  public getQuarterlyGovEnablerDirections(periodId: number) {
    this._calcEngineApiService
      .getGovDirections({
        period: periodId,
        isAnnual: false,
      })
      .subscribe({
        next: (res) => {
          if (!res.inError) {
            this.directions.update(() => res.responseData);
          }
        },
      });
  }

  public getAnnualGovEnablerDirections(year: number) {
    this._calcEngineApiService
      .getGovDirections({
        period: year,
        isAnnual: true,
      })
      .subscribe({
        next: (res) => {
          if (!res.inError) {
            this.directions.update(() => res.responseData);
          }
        },
      });
  }
}
