import { Component } from "@angular/core";

@Component({
  selector: "adaa-transformational-targets-screen",
  standalone: true,
  imports: [],
  templateUrl: "./transformational-targets-screen.component.html",
  styleUrl: "./transformational-targets-screen.component.scss",
})
export class TransformationalTargetsScreenComponent {}
