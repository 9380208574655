import { Component, inject, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ChartDataset, ChartOptions } from "chart.js";
import { BaseChartDirective } from "ng2-charts";

import { ChartsService } from "../../../services";
import { ChartActionButtonsComponent } from "../../chart-action-buttons/chart-action-buttons.component";
import { FormActionButtonsComponent } from "../../form";

@Component({
  selector: "adaa-entity-comparison",
  imports: [BaseChartDirective, FormActionButtonsComponent, TranslateModule, ChartActionButtonsComponent],
  templateUrl: "./entity-comparison.component.html",
  styleUrl: "./entity-comparison.component.scss",
})
export class EntityComparisonComponent implements OnInit {
  chartService = inject(ChartsService);

  @Input() usePercentage = false;
  @Input() datasets: ChartDataset<"bar">[];
  @Input() labels: string[];
  @Input() options: ChartOptions<"bar">;
  @Input() title: string;
  @Input() chartId: string;
  @Input() usePerformance: boolean = true;

  modal: NgbActiveModal = inject(NgbActiveModal);

  public ngOnInit(): void {
    this.chartService.initEntityComparisonBarChartOptions({
      usePercentage: this.usePercentage,
      usePerformance: this.usePerformance,
    });
    this.options = this.chartService.barChartOptions!;
  }
}
