import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[alphabetOnly]",
})
export class AlphabetOnlyDirective {
  @HostListener("input", ["$event"]) public onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const filteredValue = input.value.replace(/[^a-zA-Z\s]/g, ""); // Allow only letters and spaces
    input.value = filteredValue;
  }

  @HostListener("keypress", ["$event"]) public onKeyPress(event: KeyboardEvent) {
    const regex = /^[a-zA-Z\s]+$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }
}
