<div class="container-fluid auth-layout" [dir]="languageService.direction()">
    <div class="row text-center d-flex justify-content-center flex-nowrap">
        <div class="col-xl-4 col-lg-6 col-sm-8 col-12 px-2">
            <img class="d-block mx-auto mb-4" alt="logo" ngSrc="assets/images/adaa_logo_login.png" width="320" height="180" priority />
            <ng-content />
        </div>
    </div>
</div>

<adaa-spinner></adaa-spinner>
