<div class="modal-header">
    <h4 class="modal-title w-100 text-center fw-bold">{{ modalTitle() }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
</div>
<div class="modal-body overflow-x-hidden">
    <div class="row">
        <div class="col-12">
            <adaa-form-dropdown
                appendTo=".modal-content"
                [setDefaultValue]="currentYear"
                [options]="entityYears"
                (inputChanges)="yearChanged($event)"
            />
        </div>
        <div class="col-12">
            @if (ekpiPerformances().length > 0 || averagePerformance()) {
                <adaa-chart-action-buttons elementId="ekpiChart" [enableDownloadCSV]="false" />
                <canvas
                    class="ekpi-chart"
                    id="ekpiChart"
                    baseChart
                    type="bar"
                    [labels]="ekpiChartLabels()"
                    [datasets]="performanceChartDataset()"
                    [options]="chartService.barChartOptions"
                ></canvas>
            }
        </div>
    </div>
</div>
