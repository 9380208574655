import { inject, Injectable, signal } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { CurrencyValuePipe, PercentageValuePipe } from "../../core/pipes";
import { Constants } from "../constants/constants";
import { SdgIconConfig, SDGModelType, SdgSubGovDirection, SelectedPeriodModelType } from "../models";
import { CalculationEngineApiService } from "./calculation-engine-api.service";

@Injectable({
  providedIn: "root",
})
export class SdgDashboardService {
  private _calcEngineApiService = inject(CalculationEngineApiService);
  private _translateService = inject(TranslateService);

  private _sdgConfig: SdgIconConfig[] = [
    {
      displayOrder: 1,
      iconUrl: "assets/images/SDG/E_SDG_Icons-1.png",
      top: "6px",
      iconWidth: "auto",
      right: "205px",
      bottom: "",
      colorCode: "#e5233d",
    },
    {
      displayOrder: 2,
      iconUrl: "assets/images/SDG/E_SDG_Icons-2.png",
      top: "36px",
      iconWidth: "auto",
      right: "118px",
      bottom: "",
      colorCode: "#dda73a",
    },
    {
      displayOrder: 3,
      iconUrl: "assets/images/SDG/E_SDG_Icons-3.png",
      top: "98px",
      iconWidth: "auto",
      right: "52px",
      bottom: "",
      colorCode: "#4ca146",
    },
    {
      displayOrder: 4,
      iconUrl: "assets/images/SDG/E_SDG_Icons-4.png",
      top: "182px",
      iconWidth: "auto",
      right: "16px",
      bottom: "",
      colorCode: "#c7212f",
    },
    {
      displayOrder: 5,
      iconUrl: "assets/images/SDG/E_SDG_Icons-5.png",
      top: "267px",
      iconWidth: "auto",
      right: "5px",
      bottom: "",
      colorCode: "#ef402d",
    },
    {
      displayOrder: 6,
      iconUrl: "assets/images/SDG/E_SDG_Icons-6.png",
      top: "351px",
      iconWidth: "auto",
      right: "28px",
      bottom: "",
      colorCode: "#27bfe6",
    },
    {
      displayOrder: 7,
      iconUrl: "assets/images/SDG/E_SDG_Icons-7.png",
      top: "429px",
      iconWidth: "auto",
      right: "86px",
      bottom: "",
      colorCode: "#fbc412",
    },
    {
      displayOrder: 8,
      iconUrl: "assets/images/SDG/E_SDG_Icons-8.png",
      top: "473px",
      iconWidth: "auto",
      right: "158px",
      bottom: "",
      colorCode: "#a31c44",
    },
    {
      displayOrder: 9,
      iconUrl: "assets/images/SDG/E_SDG_Icons-9.png",
      top: "496px",
      iconWidth: "auto",
      right: "256px",
      bottom: "",
      colorCode: "#f26a2e",
    },
    {
      displayOrder: 10,
      iconUrl: "assets/images/SDG/E_SDG_Icons-10.png",
      top: "468px",
      iconWidth: "auto",
      right: "332px",
      bottom: "",
      colorCode: "#e01483",
    },
    {
      displayOrder: 11,
      iconUrl: "assets/images/SDG/E_SDG_Icons-11.png",
      top: "422px",
      iconWidth: "auto",
      right: "408px",
      bottom: "",
      colorCode: "#f89d2a",
    },
    {
      displayOrder: 12,
      iconUrl: "assets/images/SDG/E_SDG_Icons-12.png",
      top: "348px",
      iconWidth: "auto",
      right: "463px",
      bottom: "",
      colorCode: "#bf8d2c",
    },
    {
      displayOrder: 13,
      iconUrl: "assets/images/SDG/E_SDG_Icons-13.png",
      top: "267px",
      iconWidth: "auto",
      right: "490px",
      bottom: "",
      colorCode: "#407f46",
    },
    {
      displayOrder: 14,
      iconUrl: "assets/images/SDG/E_SDG_Icons-14.png",
      top: "177px",
      iconWidth: "auto",
      right: "483px",
      bottom: "",
      colorCode: "#1f97d4",
    },
    {
      displayOrder: 15,
      iconUrl: "assets/images/SDG/E_SDG_Icons-15.png",
      top: "97px",
      iconWidth: "auto",
      right: "438px",
      bottom: "",
      colorCode: "#59ba47",
    },
    {
      displayOrder: 16,
      iconUrl: "assets/images/SDG/E_SDG_Icons-16.png",
      top: "41px",
      iconWidth: "auto",
      right: "375px",
      bottom: "",
      colorCode: "#136a9f",
    },
    {
      displayOrder: 17,
      iconUrl: "assets/images/SDG/E_SDG_Icons-17.png",
      top: "9px",
      iconWidth: "auto",
      right: "295px",
      bottom: "",
      colorCode: "#14496b",
    },
  ];

  selectedPeriod = signal<SelectedPeriodModelType | undefined>(undefined);
  sdgData = signal<SDGModelType | undefined>(undefined);
  sdgGovDirectionData = signal<SdgSubGovDirection | undefined>(undefined);

  public getSdg(selectedPeriod: SelectedPeriodModelType | undefined) {
    if (!selectedPeriod) return;
    this._calcEngineApiService
      .getSDG({
        isAnnual: selectedPeriod.type === "year",
        period: selectedPeriod.type === "year" ? selectedPeriod.period.year : selectedPeriod.period.id,
      })
      .subscribe((response) => {
        if (response.inError) return;

        this.sdgData.set(this._formatSdgData(response.responseData));
      });
  }

  public getSdgGovDirection(selectedPeriod: SelectedPeriodModelType | undefined, id: number | undefined) {
    if (!selectedPeriod || !id) return;

    this._calcEngineApiService
      .getSDG({
        isAnnual: selectedPeriod.type === "year",
        period: selectedPeriod.type === "year" ? selectedPeriod.period.year : selectedPeriod.period.id,
      })
      .subscribe((response) => {
        if (response.inError) return;

        const sdgGovDirection = response.responseData.subGovDirections?.find((e) => e.id === id);
        if (!sdgGovDirection) return;

        this.sdgData.set(this._formatSdgData(response.responseData));
        this.sdgGovDirectionData.set(this._formatSdgGovDirectionData(sdgGovDirection));
      });
  }

  private _formatSdgData(data: SDGModelType): SDGModelType {
    const percentageValuePipe = new PercentageValuePipe();
    const currencyValuePipe = new CurrencyValuePipe();

    data.subGovDirections?.forEach((e) => {
      const config = this._sdgConfig.find((config) => e.displayOrder === config.displayOrder);
      if (config) e.config = config;
    });

    const year = new Date(data.startDate).getFullYear();

    data.bars = [
      {
        value: percentageValuePipe.transform(data.performance),
        color: data.performanceColor ?? Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY,
        label: "landingPage.entity_page.annual_performance",
        subLabel: "sdg.for_current_year",
      },
      {
        value: percentageValuePipe.transform(data.initiativeProgress),
        color: data.initiativeProgressColor ?? Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY,
        label: "sdg.initiatives_progress",
        subLabel: "sdg.for_current_cycle",
      },
      {
        value: `${data.kpiCount ?? "-"}`,
        color: Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY,
        label: "sdg.total_kpis",
      },
      {
        value: `${data.initiativesCount ?? "-"}`,
        color: Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY,
        label: "sdg.total_initiatives",
        subLabel: this._translateService.instant("sdg.accumulate_from").replace("{year}", year),
      },
      {
        value: currencyValuePipe.transform(data.initiativesBudget, true),
        color: Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY,
        label: "sdg.goal_budget",
        subLabel: this._translateService.instant("sdg.accumulate_from").replace("{year}", year),
      },
      {
        value: currencyValuePipe.transform(data.initiativesExpenses, true),
        color: Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY,
        label: "sdg.goal_expenses",
        subLabel: this._translateService.instant("sdg.accumulate_from").replace("{year}", year),
      },
    ];

    return data;
  }

  private _formatSdgGovDirectionData(data: SdgSubGovDirection): SdgSubGovDirection {
    const percentageValuePipe = new PercentageValuePipe();
    const currencyValuePipe = new CurrencyValuePipe();

    data.bars = [
      {
        value: percentageValuePipe.transform(data.visionAchievement),
        color: data.visionAchievementColor ?? Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY,
        label: "sdg.goal_achievement",
      },
      {
        value: percentageValuePipe.transform(data.performance),
        color: data.performanceColor ?? Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY,
        label: "landingPage.entity_page.annual_performance",
      },
      {
        value: percentageValuePipe.transform(data.initiativeProgress),
        color: data.initiativeProgressColor ?? Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY,
        label: "sdg.initiatives_progress",
      },
      {
        value: `${data.initiativesCount ?? "-"}`,
        color: Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY,
        label: "sdg.total_initiatives",
      },
      {
        value: currencyValuePipe.transform(data.initiativesBudget, true),
        color: Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY,
        label: "sdg.goal_budget",
      },
      {
        value: currencyValuePipe.transform(data.initiativesExpenses, true),
        color: Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY,
        label: "sdg.goal_expenses",
      },
    ];

    return data;
  }
}
