<div class="modal-header">
    <h4 class="modal-title">{{ "national_projects.national_projects_milestone.singular_title" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body" [formGroup]="milestoneForm">
    <adaa-form-steps [tabs]="tabs" [showActions]="false" (currentTab)="activeTab.set($event)">
        <div class="tab-pane fade" id="tab-0" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 0 }">
            <div class="bg-white shadow-md rounded p-2 mb-2">
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            controlName="nameAE"
                            formControlName="nameAE"
                            [invalid]="submitted && milestoneForm.get('nameAE')?.invalid!"
                            [label]="'common.form.label.nameAE' | translate"
                            [setValidator]="formValidation"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            controlName="nameEN"
                            formControlName="nameEN"
                            [invalid]="submitted && milestoneForm.get('nameEN')?.invalid!"
                            [label]="'common.form.label.nameEN' | translate"
                            [setValidator]="formValidation"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            controlName="dscAE"
                            formControlName="dscAE"
                            [invalid]="submitted && milestoneForm.get('dscAE')?.invalid!"
                            [label]="'common.form.label.descriptionAE' | translate"
                            [setValidator]="formValidation"
                            [isTextArea]="true"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            controlName="dscEN"
                            formControlName="dscEN"
                            [invalid]="submitted && milestoneForm.get('dscEN')?.invalid!"
                            [label]="'common.form.label.descriptionEN' | translate"
                            [setValidator]="formValidation"
                            [isTextArea]="true"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input-date
                            controlName="startDate"
                            formControlName="startDate"
                            [invalid]="submitted && (milestoneForm.get('startDate')?.invalid! || milestoneForm.errors?.startDate_endDate_Invalid)"
                            [label]="'national_projects.planned_start_date' | translate"
                            [setValidator]="formValidation"
                            [minDate]="milestoneLimits.min"
                            [maxDate]="milestoneLimits.max"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input-date
                            controlName="endDate"
                            formControlName="endDate"
                            [invalid]="submitted && (milestoneForm.get('endDate')?.invalid! || milestoneForm.errors?.startDate_endDate_Invalid)"
                            [label]="'national_projects.planned_end_date' | translate"
                            [setValidator]="formValidation"
                            [minDate]="milestoneLimits.min"
                            [maxDate]="milestoneLimits.max"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-dropdown
                            controlName="entityId"
                            formControlName="entityId"
                            [clearable]="true"
                            [invalid]="submitted && milestoneForm.get('entityId')?.invalid!"
                            [label]="'national_projects.accountable_entity' | translate"
                            [setValidator]="formValidation"
                            [options]="parentEntities"
                            (inputChanges)="entityChanged($event)"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-dropdown
                            controlName="owner"
                            formControlName="owner"
                            [clearable]="true"
                            [invalid]="submitted && milestoneForm.get('owner')?.invalid!"
                            [label]="'common.form.label.owner' | translate"
                            [setValidator]="formValidation"
                            [options]="owners()"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <adaa-form-dropdown
                            controlName="otherEntityId"
                            formControlName="otherEntityId"
                            [clearable]="true"
                            [invalid]="submitted && milestoneForm.get('otherEntityId')?.invalid!"
                            [label]="'national_projects.other_accountable_entity' | translate"
                            [setValidator]="formValidation"
                            [options]="parentOtherEntities"
                            (inputChanges)="otherEntityChanged($event)"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            controlName="weight"
                            formControlName="weight"
                            inputType="positiveNumber"
                            [invalid]="submitted && milestoneForm.get('weight')?.invalid!"
                            [label]="'national_projects.workload' | translate"
                            [setValidator]="formValidation"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-input
                            controlName="budget"
                            formControlName="budget"
                            inputType="positiveNumber"
                            [invalid]="submitted && milestoneForm.get('budget')?.invalid!"
                            [label]="'initiatives.budget' | translate"
                            [setValidator]="formValidation"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <adaa-form-dropdown-multi
                            controlName="sectors"
                            formControlName="sectors"
                            [invalid]="submitted && milestoneForm.get('sectors')?.invalid!"
                            [label]="'national_projects.national_sector.title' | translate"
                            [setValidator]="formValidation"
                            [options]="parentSectors"
                            (inputChanges)="sectorsChanged($event)"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-pane fade" id="tab-1" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 1 }">
            <div class="bg-white shadow-md rounded p-2 mb-2">
                <adaa-contributing-entities
                    [id]="milestoneForm.get('id')?.value"
                    [itemType]="constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE"
                    entitiesControlName="cEntities"
                    otherEntitiesControlName="cOtherEntities"
                    [contributingEntities]="milestoneForm.get('contributingEntities')?.value"
                    [entities]="entities"
                    [otherEntities]="otherEntities"
                    (contributingEntitiesChanged)="milestoneForm.get('contributingEntities')?.setValue($event)"
                />
            </div>
        </div>

        @if (this.enableTeamMembers) {
            <div class="tab-pane fade" id="tab-2" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 2 }">
                <div class="bg-white shadow-md rounded p-2 mb-2">
                    <adaa-team-members-list
                        tableTitle="national_projects.national_projects_milestone.team_members"
                        [tableConf]="constants.MAIN_TABLE_LIST_CONF_KEY.MILESTONE_MEMBERS_CONF_LIST"
                        [teamMembers]="milestoneForm.get('teamMembers')?.value ?? []"
                        [entities]="filteredEntites"
                        [otherEntities]="filteredOtherEntites"
                        (teamMembersChanged)="milestoneForm.get('teamMembers')?.setValue($event)"
                    />
                </div>
            </div>
        }
    </adaa-form-steps>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ "common.form.button.close" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        {{ "common.form.button.save" | translate }}
    </button>
</div>
