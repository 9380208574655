<div class="d-flex flex-row mt-1 justify-content-center">
    @if (customActionButtons().length > 0) {
        @for (custom of customActionButtons(); track $index) {
            @if (!custom.hasOwnProperty("onlyIf") || (custom.onlyIf && custom.onlyIf(data()))) {
                <span
                    class="custom-action"
                    [ngClass]="
                        actionRules(actionsEvents.custom, 'isDisabled', custom) ? custom.iconName + ' pointer' : custom.disableIconName + ' disabled'
                    "
                    [title]="
                        actionRules(actionsEvents.custom, 'isDisabled', custom)
                            ? (custom.iconTitle | translate)
                            : (custom.iconDisabledTitle! | translate)
                    "
                    (click)="actionRules(actionsEvents.custom, 'isDisabled', custom) && customClicked.emit(custom.eventName)"
                ></span>
            }
        }
    }

    @if (enableConnectionsAction() && actionRules(actionsEvents.connections, "isHidden")) {
        <span
            [ngClass]="actionRules(actionsEvents.connections) ? 'adaa-icon-connections pointer' : 'adaa-icon-connections disabled'"
            [title]="actionRules(actionsEvents.connections) ? ('tooltips.connections' | translate) : ('tooltips.connections_disabled' | translate)"
            (click)="actionRules(actionsEvents.connections) && actionClicked.emit(actionsEvents.connections)"
        ></span>
    }

    @if (enableViewAction() && actionRules(actionsEvents.view, "isHidden")) {
        <span
            [ngClass]="actionRules(actionsEvents.view) ? 'adaa-icon-view1 pointer' : 'adaa-icon-view disabled'"
            [title]="actionRules(actionsEvents.view) ? ('tooltips.view' | translate) : ('tooltips.view_disabled' | translate)"
            *adaaHasPermission="viewPermission()"
            (click)="actionRules(actionsEvents.view) && actionClicked.emit(actionsEvents.view)"
        ></span>
    }

    @if (enableEditAction() && actionRules(actionsEvents.edit, "isHidden")) {
        <span
            [ngClass]="actionRules(actionsEvents.edit) ? 'adaa-icon-edit pointer' : 'adaa-icon-edit disabled'"
            [title]="actionRules(actionsEvents.edit) ? ('tooltips.edit' | translate) : ('tooltips.edit_disabled' | translate)"
            *adaaHasPermission="managePermission()"
            (click)="actionRules(actionsEvents.edit) && actionClicked.emit(actionsEvents.edit)"
        ></span>
    }

    @if (enableDeleteAction() && actionRules(actionsEvents.delete, "isHidden")) {
        <span
            [ngClass]="actionRules(actionsEvents.delete) ? 'adaa-icon-delete pointer' : 'adaa-icon-delete disabled'"
            [title]="actionRules(actionsEvents.delete) ? ('tooltips.delete' | translate) : ('tooltips.delete_disabled' | translate)"
            *adaaHasPermission="managePermission()"
            (click)="actionRules(actionsEvents.delete) && actionClicked.emit(actionsEvents.delete)"
        ></span>
    }
</div>
