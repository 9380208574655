import { Component, inject, input, type OnInit, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { filter } from "rxjs";

import { AdaaHelper } from "../../../../../../../core/utils";
import { Constants } from "../../../../../../constants/constants";
import type { PropTypeModelType } from "../../../../../../models";
import { LanguageService, PropertiesService } from "../../../../../../services";
import {
  AccordionDiffViewComponent,
  AttachmentDiffViewComponent,
  BenchmarkDiffViewComponent,
  ExtendedFieldsDiffComponent,
  FormulaDiffViewComponent,
  ListDiffViewComponent,
  NormalDiffViewComponent,
  TargetsDiffViewComponent,
} from "../../_lib";
import {
  AccordionSectionConfigType,
  CompositeSectionConfigType,
  ListSectionConfigType,
  ScreenSectionConfigType,
} from "../../wf-difference.types";
import { isDtkpi, isNtkpi, isSrvkpi } from "../../wf-difference.utils";
import { commonKpiScreenConfig } from "./kpi-screen.config";

@Component({
  selector: "adaa-kpi-screen",
  standalone: true,
  imports: [
    TranslateModule,
    NormalDiffViewComponent,
    TargetsDiffViewComponent,
    AttachmentDiffViewComponent,
    BenchmarkDiffViewComponent,
    ExtendedFieldsDiffComponent,
    FormulaDiffViewComponent,
    ListDiffViewComponent,
    AccordionDiffViewComponent,
  ],
  templateUrl: "./kpi-screen.component.html",
})
export class KpiScreenComponent implements OnInit {
  readonly languageService = inject(LanguageService);
  private readonly _propertiesService = inject(PropertiesService);

  isNew = input<boolean>(false);
  showOnlyNew = input.required<boolean>();
  newKpiObject = input<Record<string, never>>();
  oldKpiObject = input<Record<string, never>>();
  itemType = input<number>();
  isAuditTrail = input<boolean>();
  viewOnlyDifferences = input<boolean>(false);

  isScreenReady = signal<boolean>(false);
  props = signal<PropTypeModelType[]>([]);

  config = commonKpiScreenConfig;

  readonly $asListConfig = ($s: CompositeSectionConfigType) => $s as ListSectionConfigType;
  readonly $asAccordionConfig = ($s: CompositeSectionConfigType) => $s as AccordionSectionConfigType;

  public ngOnInit() {
    this._fetchConstants();
  }

  private _fetchConstants() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const constantIds = [
      Constants.CONSTANT_MEASUREMENT_UNIT,
      Constants.CONSTANT_FREQUENCY,
      Constants.CONSTANT_TREND,
      Constants.CONSTANT_YTP,
    ];

    if (isNtkpi(this.newKpiObject()!.kpiType) || isDtkpi(this.newKpiObject()!.kpiType)) {
      constantIds.push(Constants.CONSTANT_KPI_TARGET_TYPE);
    }
    if (isNtkpi(this.newKpiObject()!.kpiType)) {
      constantIds.push(
        ...[Constants.CONSTANT_TARGET_CATEGORY, Constants.CONSTANT_PUBLISHED_STATUS, Constants.CONSTANT_MONTHS]
      );
    }
    if (isSrvkpi(this.newKpiObject()!.kpiType)) {
      constantIds.push(...[Constants.CONSTANT_PERSPECTIVE_TYPE, Constants.CONSTANT_CLASSIFICATION_TYPE]);
    }

    this._propertiesService
      .getPropByIdList(constantIds)
      .pipe(filter((res) => !res.inError))
      .subscribe({
        next: (res) => {
          this.props.set(res.responseData ?? []);

          for (const section of this.config.sections) {
            if (section.view !== "normal" && section.view !== "list") continue;

            switch ((section as ScreenSectionConfigType).field) {
              case "targetCategory":
                (section as ScreenSectionConfigType).format = (data: { targetCategory: number }) =>
                  that._getTextFromProp(data.targetCategory, res.responseData);
                break;

              case "targetTypeId":
                (section as ScreenSectionConfigType).format = (data: { targetTypeId: number }) =>
                  that._getTextFromProp(data.targetTypeId, res.responseData);
                break;

              case "publishedState":
                (section as ScreenSectionConfigType).format = (data: { publishedState: number }) =>
                  that._getTextFromProp(data.publishedState, res.responseData);
                break;

              case "actualDataAvailabilityPeriod":
                (section as ScreenSectionConfigType).format = (data: { actualDataAvailabilityPeriod: number }) =>
                  that._getTextFromProp(data.actualDataAvailabilityPeriod, res.responseData);
                break;

              case "ytpCalc":
                (section as ScreenSectionConfigType).format = (data: { ytpCalc: number }) =>
                  that._getTextFromProp(data.ytpCalc, res.responseData);
                break;

              case "trend":
                (section as ScreenSectionConfigType).format = (data: { trend: number }) =>
                  that._getTextFromProp(data.trend, res.responseData);
                break;

              case "measurementUnit":
                (section as ScreenSectionConfigType).format = (data: { measurementUnit: number }) =>
                  that._getTextFromProp(data.measurementUnit, res.responseData);
                break;

              case "frequency":
                (section as ScreenSectionConfigType).format = (data: { frequency: number }) =>
                  that._getTextFromProp(data.frequency, res.responseData);
                break;

              case "perspective":
                (section as ScreenSectionConfigType).format = (data: { perspective: number }) =>
                  that._getTextFromProp(data.perspective, res.responseData);
                break;

              case "classification":
                (section as ScreenSectionConfigType).format = (data: { classification: number }) =>
                  that._getTextFromProp(data.classification, res.responseData);
                break;

              default:
                break;
            }
          }

          this.isScreenReady.set(true);
        },
      });
  }

  private _getTextFromProp(id: number, list: { id: number }[]) {
    const data = list.find((e) => e.id === id);
    if (data) {
      return AdaaHelper.getItemValueByToken(data, "name");
    }
    return "";
  }
}
