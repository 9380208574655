<div class="col-12 mb-3">
    <div class="d-flex justify-content-center gap-4">
        <button class="btn btn-primary" (click)="cancelClicked.emit()">
            <span class="fw-bolder">{{ cancelButtonTitle() | translate }}</span>
        </button>

        @if (showSaveButton()) {
            <button class="btn btn-primary" (click)="saveClicked.emit()" [disabled]="saveButtonDisabled()">
                <span class="fw-bolder">{{ saveButtonTitle() | translate }}</span>
            </button>
        }
    </div>
</div>
