<div class="table-responsive">
    <table class="table" [class]="isRowSelected ? 'selected-row' : isEven() ? 'even' : 'odd'">
        <tbody>
            <tr>
                @for (col of columns(); track col) {
                    <td [style.width]="col.width" [class]="structureViewService.getAlignment(col.alignment)">
                        @switch (col.type) {
                            @case ("text") {
                                {{ col.valueKey ? data()[col.valueKey] : "" }}
                                @if (col.showTags) {
                                    @if (data()["priorityHigh"] === AdaaBoolean.Y) {
                                        <span class="badge text-dark bg-white rounded-pill shadow-sm border border-1 border-secondary-subtle">
                                            {{ "initiatives.high_priority" | translate }}
                                        </span>
                                    }
                                    @if (data()["isLinked"] && data()["linkedKpiTypeId"]) {
                                        <span class="badge text-dark bg-white rounded-pill shadow-sm border border-1 border-secondary-subtle">
                                            {{ structureViewService.itemTranslationById(data(), "linkedKpiTypeId") }}
                                        </span>
                                    }
                                }
                            }
                            @case ("type") {
                                {{ structureViewService.itemTranslationById(data(), "type") }}
                            }
                            @case ("percentage") {
                                <span class="badge rounded-pill" [style.background]="structureViewService.getColor(data(), col.colorKey)">
                                    @if (col.valueKey && AdaaHelper.isDefined(data()[col.valueKey])) {
                                        {{ data()[col.valueKey] | percentageValue: 2 : "" }}
                                    }
                                </span>
                            }
                            @case ("status") {
                                @if (col.valueKey && AdaaHelper.isDefined(data()[col.valueKey])) {
                                    <span class="badge text-dark bg-white rounded-pill shadow-sm border border-1 border-secondary-subtle">
                                        @switch (data()[col.valueKey]) {
                                            @case (Constants.OBJECT_STATUS.DRAFT) {
                                                {{ "common.state.draft" | translate }}
                                            }
                                            @case (Constants.OBJECT_STATUS.ACTIVE) {
                                                {{ "common.state.approved" | translate }}
                                            }
                                            @case (Constants.OBJECT_STATUS.DRAFT) {
                                                {{ "status.in_review" | translate }}
                                            }
                                        }
                                    </span>
                                }
                            }
                            @case ("trend") {
                                @if (col.valueKey && AdaaHelper.isDefined(data()[col.valueKey])) {
                                    @switch (data()[col.valueKey]) {
                                        @case (Constants.TREND.INCREASING) {
                                            <span class="adaa-icon-increasing trend"> </span>
                                        }
                                        @case (Constants.TREND.DECREASING) {
                                            <span class="adaa-icon-decreasing trend"></span>
                                        }
                                        @case (Constants.TREND.ONTARGET) {
                                            <span class="adaa-icon-ontarget trend"></span>
                                        }
                                        @case (Constants.TREND.BOUNDED) {
                                            <span class="adaa-icon-bounded trend"></span>
                                        }
                                    }
                                }
                            }
                            @case ("actions") {
                                <span
                                    class="icon adaa-icon-view1 pointer"
                                    [title]="'tooltips.view' | translate"
                                    (click)="structureViewService.viewClicked(data(), col.valueKey)"
                                ></span>
                            }
                            @default {}
                        }
                    </td>
                }
            </tr>
        </tbody>
    </table>
</div>
