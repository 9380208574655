import { Component, inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { TableSelectedRow, UserEntitiesResponsibleFor } from "../../../models";
import { DataTableComponent } from "../../table/data-table/data-table.component";

@Component({
  selector: "adaa-user-responsiblities-modal",
  standalone: true,
  imports: [TranslateModule, DataTableComponent],
  templateUrl: "./user-responsiblities-modal.component.html",
  styleUrl: "./user-responsiblities-modal.component.scss",
})
export class UserResponsiblitiesModalComponent {
  modal: NgbActiveModal = inject(NgbActiveModal);

  @Input() selectedResponsiblities: UserEntitiesResponsibleFor[];
  @Input() entities: UserEntitiesResponsibleFor[];

  rowSelected: TableSelectedRow;

  public save(): void {
    this.modal.close(this.rowSelected?.allSelectedRows);
  }
}
