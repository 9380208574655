/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from "@angular/common";
import {
  Component,
  effect,
  inject,
  Input,
  input,
  isDevMode,
  OnChanges,
  OnInit,
  output,
  signal,
  SimpleChanges,
} from "@angular/core";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faAngleDown, faAngleLeft, faAngleRight, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { NgbModal, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import {
  DndDraggableDirective,
  DndDropEvent,
  DndDropzoneDirective,
  DndModule,
  DndPlaceholderRefDirective,
} from "ngx-drag-drop";
import { ToastrService } from "ngx-toastr";

import { PermissionActionDescriptorType } from "../../../../../adaa-types";
import { AdaaHelper } from "../../../../core/utils";
import { Language, PermissionAction } from "../../../constants/enums";
import { ResizeColumnDirective, SortableHeaderDirective, SortEvent } from "../../../directives";
import {
  CustomButton,
  CustomTableButton,
  FormCatalog,
  ItemAction,
  MainResponseModel,
  ParameterCatalog,
  RowWithIssueType,
  SearchFields,
  TableActionEvent,
  TableInputChangedOutput,
  TableInputs,
  TableSelectedRow,
  TableValueListOutput,
  TotalRows,
} from "../../../models";
import {
  AppService,
  DataTableDataService,
  DataTableExportService,
  DataTableService,
  LanguageService,
} from "../../../services";
import { DataTableActionsComponent } from "../data-table-actions/data-table-actions.component";
import { DataTableButtonsComponent } from "../data-table-buttons/data-table-buttons.component";
import { DataTableCellComponent } from "../data-table-cell/data-table-cell.component";
import { DataTableFiltersComponent } from "../data-table-filters/data-table-filters.component";
import { DataTableSettingsComponent } from "../data-table-settings/data-table-settings.component";

@Component({
  selector: "adaa-data-table",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DndModule,
    DndDropzoneDirective,
    DndDraggableDirective,
    DataTableCellComponent,
    DataTableActionsComponent,
    DataTableButtonsComponent,
    DndPlaceholderRefDirective,
    ResizeColumnDirective,
    SortableHeaderDirective,
    NgbPaginationModule,
    FontAwesomeModule,
  ],
  templateUrl: "./data-table.component.html",
  styleUrl: "./data-table.component.scss",
})
export class DataTableComponent implements OnInit, OnChanges {
  @Input() titleHeader: string;
  @Input() useSmHeader: boolean = false;
  @Input() required: boolean = false;

  @Input() tableClasses: string = "table-striped table-hover";

  @Input() disablePagination = false;

  @Input() enableAdd = true;
  @Input() enablePrint = false;
  @Input() enableSettings = true;
  @Input() enableFilter = true;
  @Input() enableExport = true;
  @Input() customTableButtons: CustomTableButton[] = [];

  @Input() enableActionsCell: boolean = true; // To show or hide actions cell in the table
  @Input() enableConnectionsAction: boolean = false;
  @Input() enableViewAction: boolean = true;
  @Input() enableEditAction: boolean = true;
  @Input() enableDeleteAction: boolean = true;
  @Input() customActionButtons: CustomButton[] = [];

  @Input() disableViewRules: ItemAction[];
  @Input() disableEditRules: ItemAction[];
  @Input() disableConnectionsRules: ItemAction[];
  @Input() disableDeleteRules: ItemAction[];

  @Input() hideViewRules: ItemAction[];
  @Input() hideEditRules: ItemAction[];
  @Input() hideConnectionsRules: ItemAction[];
  @Input() hideDeleteRules: ItemAction[];

  @Input() isLocalData: boolean = false;
  @Input() configKey: string; // The search key that we have to get the config list from (e.g. nkpis_conf_list) Important for filter
  @Input() apiPrefix: string; // To put a prefix before the main call,  (e.g. if prefix = 'pillar' then => "pillar/getallv2...")
  @Input() apiName: string = "getAllV2"; // You can use this instad of apiPrefix and add the apiName to data table data service
  @Input() paramsString: string; // to pass a url params example "userId=123&entityId=2"
  @Input() loader: boolean = true;
  @Input() tableInputs: TableInputs[] = [];

  @Input() public set localData(value: object[]) {
    if (value && value !== this.tableDataCopy) {
      this.tableData = value;
      this.tableDataCopy = value;
      this.tableDataFiltered = value;

      this.totalRows = value.length;
      this._applyLocalFilter();
    }
  }

  disableTableActions = input<boolean>(false);
  rowsWithIssues = input<RowWithIssueType[]>([]);
  staticSearchFields = input<SearchFields>({ searchFields: {} }); // *note: Used when the parent component wants filtered data on page initialization
  alwaysFilterBySearchFields = input<SearchFields>({ searchFields: {} }); // *note: This filters will be always called regadless of the current selected filters those filters will be kept even if we reset the filters

  //SELECTION
  @Input() enableSelection = false; // To allow selection in the table
  @Input() multiSelection: any[] | undefined = undefined; // To allow multi selection in the table (Set it to empty if you don't have selected array) otherwise pass your array
  @Input() rowSelected: number | undefined = undefined; // When the row selected, this is the variable that hold the value
  @Input() notAllowedToSelect: ItemAction[] = [];
  @Input() selectionId: string = "id";

  @Input() limit: number = 10;

  //Used for the table Permission just pass the value and table will hide the create/delete/manage/view buttons
  //You need to use Constants.CONSTANT_PERMISSIONS
  @Input() public set itemTypeId(data: number | undefined) {
    this._itemId = data;
    if (!this._itemId) return;

    this.createPermission.set({
      actor: {
        modifier: "and",
        permissions: [
          {
            permissionAction: PermissionAction.create,
            objectTypeId: this._itemId,
          },
        ],
      },
    });

    this.managePermission.set({
      actor: {
        modifier: "and",
        permissions: [
          {
            permissionAction: PermissionAction.manage,
            objectTypeId: this._itemId,
          },
        ],
      },
    });

    this.viewPermission.set({
      actor: {
        modifier: "and",
        permissions: [
          {
            permissionAction: PermissionAction.view,
            objectTypeId: this._itemId,
          },
        ],
      },
    });

    this.isNotArchived = this._dataTableService.checkIfNotArchived(this._itemId);
  }

  //Used if table has children
  tableWithChildren = input<boolean>(false);
  childKey = input<string>("children");
  enableChildActionsCell = input<boolean>(false);
  enableEditChildAction = input<boolean>(false);
  enableViewChildAction = input<boolean>(false);
  enableDeleteChildAction = input<boolean>(false);
  enableConnectionsChildAction = input<boolean>(false);

  inputListChanged = output<TableValueListOutput[]>();
  addNewClicked = output<void>();
  buttonClicked = output<string>();
  actionClicked = output<TableActionEvent>();
  childClicked = output<TableActionEvent>();
  getRowData = output<TableSelectedRow>(); // To send row information if clicked (@Input selection should be true)
  tableDataChanged = output<any[]>();

  private _dataTableService = inject(DataTableService);
  private _dataTableDataService = inject(DataTableDataService);
  private _dataTableExportService = inject(DataTableExportService);
  private _appService = inject(AppService);
  private _iconLibrary = inject(FaIconLibrary);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _modalService = inject(NgbModal);
  languageService = inject(LanguageService);

  private readonly _basicPermissioon: PermissionActionDescriptorType = {
    actor: {
      modifier: "and",
      permissions: [],
    },
  };

  private _itemId: number | undefined;
  private _formCatalog: FormCatalog;
  private _originalColumns: string[] = []; // To have all columns without any conditions.
  private _displayedColumns: string[] = []; // To have all columns that should be displayed based on special conditions.

  adaaHelper = AdaaHelper;
  language = Language;
  tableColumns: ParameterCatalog[] = [];
  offset: number = 0;
  currentPage: number = 1;
  totalRows: number = 0;
  searchFields: SearchFields = { searchFields: {} };
  currentSort: SortEvent;
  currentFilter: any;
  resizingInProgress: boolean = false;
  generatedID = Math.random().toString();
  tableData: any[] = [];
  tableDataCopy: any[] = [];
  tableDataFiltered: any[] = [];
  inputValues: TableValueListOutput[] = [];
  isNotArchived: boolean = true;
  innerWidth = window.innerWidth;

  //used for permissions
  createPermission = signal<PermissionActionDescriptorType>(this._basicPermissioon);
  managePermission = signal<PermissionActionDescriptorType>(this._basicPermissioon);
  viewPermission = signal<PermissionActionDescriptorType>(this._basicPermissioon);

  private get _stateConfigKey(): string {
    return `${this.configKey}_${this._itemId ?? ""}`;
  }

  public get isFilterApplied(): boolean {
    return Object.values(this.searchFields.searchFields).some(
      (e) => (e?.lov && (e?.lov as object[]).length > 0) || e?.range != undefined || e?.value != undefined
    );
  }

  public get totalPages(): number {
    return this.totalRows / this.limit;
  }

  constructor() {
    effect(() => {
      this._checkConfig(this._appService.language());
    });
  }

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faSortUp, faSortDown, faAngleLeft, faAngleRight, faAngleDown);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.paramsString) {
      if (
        !changes.paramsString.firstChange &&
        JSON.stringify(changes.paramsString.currentValue) !== JSON.stringify(changes.paramsString.previousValue)
      ) {
        this.paramsString = changes.paramsString.currentValue;
        this.resetFilters();
      }
    }
    if (changes.configKey) this._getConfig();
  }

  public onSort(event: SortEvent): void {
    this.currentSort = event;
    if (this.isLocalData) this._localSort(event);
    else {
      this.searchFields = this._dataTableService.getSortAndFilterData(
        this.tableColumns,
        this.currentFilter,
        this.currentSort
      );
      this._setTableState();
      this._getTableData(
        this.apiName,
        this.limit,
        this.offset,
        this.apiPrefix,
        this.paramsString,
        this.loader,
        this.searchFields
      );
    }
  }

  public onDrop(event: DndDropEvent): void {
    if (event.index === undefined) return;

    const index = this.tableColumns.findIndex((e) => e.id === event.data.id);
    if (index != -1) {
      this.tableColumns.splice(index, 1);
      this.tableColumns.splice(event.index, 0, event.data);
      this._saveTableSettings(true);
    }
  }

  public onPageChange(event: any): void {
    const target = event.target as HTMLElement;
    const pageItem = target.closest(".page-item"); // Get closest .page-item
    const anchor = target.closest("a"); // Get clicked <a> inside .page-item

    if (!pageItem || !anchor) return;

    const isDisabled = pageItem.classList.contains("disabled");
    const ariaLabel = anchor.getAttribute("aria-label");
    if (ariaLabel !== "Next" && ariaLabel !== "Previous" && isDisabled) return;

    // Handling "Next" button
    if (ariaLabel === "Next") {
      if (this.currentPage < this.totalRows) this.currentPage += 1; // Navigate to next page
    }
    // Handling "Previous" button
    else if (ariaLabel === "Previous") {
      if (this.currentPage > 1) this.currentPage -= 1; // Navigate to previous page
    }
    // Handling direct page clicks
    else {
      const pageNumber = Number(anchor.textContent?.trim());
      if (!isNaN(pageNumber)) {
        this.currentPage = pageNumber; // Handle direct number clicks
      }
    }

    this._doPagination();
  }

  //NOTE: to hide/show the loader if you didn't pass the loader it will take the component default value
  public loadTableData(loader?: boolean) {
    this._getTableData(
      this.apiName,
      this.limit,
      this.offset,
      this.apiPrefix,
      this.paramsString,
      AdaaHelper.isDefined(loader) ? loader : this.loader,
      this.searchFields
    );
  }

  public printClicked(event: boolean): void {
    if (!event) return;

    this._dataTableService.printTable(this.generatedID, this.titleHeader);
  }

  public filterClicked(event: boolean): void {
    if (!event) return;

    const modal = this._modalService.open(DataTableFiltersComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.configKey = this.configKey;
    modal.componentInstance.tableColumns = this.tableColumns;
    modal.componentInstance.filterValues = this.searchFields;

    modal.result.then((e) => {
      if (e === true) {
        this.resetFilters();
      } else if (e === undefined) {
        return;
      } else {
        this.isLocalData ? this._localFilter(e) : this._applyFilters(e);
      }
    });
  }

  public settingsClicked(event: boolean): void {
    if (!event) return;

    const modal = this._modalService.open(DataTableSettingsComponent, {
      centered: true,
      size: "lg",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.tableColumns = this._formCatalog.parameterCatalogs
      .filter((e) => {
        const hasStep = e.step === this._appService.language() || !e.step;
        const hasLang = e.lang === this._appService.language() || !e.lang;
        const isVisible = e.visibleParameter === "Y";

        return isVisible && hasStep && hasLang;
      })
      .sort((a, b) => (a.displayOrder < b.displayOrder ? -1 : 1));

    modal.result.then((e) => {
      if (e === undefined) {
        return;
      } else {
        this._applySettings(e);
      }
    });
  }

  public exportClicked(event: boolean): void {
    if (!event) return;

    if (this.isLocalData)
      this._dataTableExportService.exportTableData(
        this._displayedColumns,
        this._formCatalog.parameterCatalogs,
        this.tableDataCopy
      );
    else this._exportTable(this.apiName, this.apiPrefix, this.paramsString, this.loader);
  }

  public columnResized(width: number, column: ParameterCatalog) {
    column.width = width;
    const index = this.tableColumns.findIndex((e) => e.id === column.id);
    if (index != -1) {
      this.tableColumns[index] = column;
      this._saveTableSettings();
    }
  }

  public resetFilters(): void {
    this.currentFilter = null;
    this.currentPage = 1;
    this.offset = this.limit * (this.currentPage - 1);

    this._setTableState();
    this.searchFields = this._dataTableService.getSortAndFilterData(
      this.tableColumns,
      this.currentFilter,
      this.currentSort
    );

    if (this.isLocalData) {
      this._applyLocalFilter();
    } else
      this._getTableData(
        this.apiName,
        this.limit,
        this.offset,
        this.apiPrefix,
        this.paramsString,
        this.loader,
        this.searchFields
      );
  }

  public rowClicked(row: any): void {
    if (!this.enableSelection) return;
    let bypass = true;

    for (let index = 0; index < this.notAllowedToSelect.length; index++) {
      const rule = this.notAllowedToSelect[index];

      if (rule.operator === "equal") {
        if (row[rule.propertyName] === rule.compareWith) {
          bypass = false;
        }
      } else if (rule.operator === "not-equal") {
        bypass = false;
        if (row[rule.propertyName] === rule.compareWith) {
          bypass = true;
          break;
        }
      } else if (rule.operator === "notNull") {
        bypass = !AdaaHelper.isDefined(row[rule.propertyName]);
      } else if (rule.operator === "null") {
        bypass = !!AdaaHelper.isDefined(row[rule.propertyName]);
      }
    }

    if (!bypass) return;

    this.rowSelected = row[this.selectionId];
    if (this.multiSelection) {
      if (this.multiSelection.findIndex((a) => a[this.selectionId] === row[this.selectionId]) === -1) {
        this.multiSelection.push(row);
      } else {
        this.multiSelection.splice(
          this.multiSelection.findIndex((a) => a[this.selectionId] === row[this.selectionId]),
          1
        );
      }
    }

    this.getRowData.emit({
      item: row,
      allSelectedRows: this.multiSelection ? this.multiSelection : [],
    });
  }

  public isRowSelected(row: any): boolean {
    if (this.enableSelection && !this.multiSelection) {
      return row[this.selectionId] === this.rowSelected;
    }
    if (this.multiSelection) {
      return this.multiSelection.filter((a) => a[this.selectionId] === row[this.selectionId]).length > 0;
    }
    return false;
  }

  public rowHasIssue(data: any, $index: number) {
    if (this.rowsWithIssues().length === 0) return false;

    const issue = this.rowsWithIssues().find((row) => {
      if (row.use === "id" && row.propName) return data[row.propName] === row.value;
      if (row.use === "index") return $index === row.value;
      return false;
    });

    return !!issue;
  }

  public inputChanged(event: TableInputChangedOutput) {
    const index = this.inputValues.findIndex((e) => e.data.id === event.data.id && e.fieldName === event.fieldName);
    if (index === -1) {
      this.inputValues.push(event);
    } else this.inputValues[index] = event;
    this.inputListChanged.emit(this.inputValues);
  }

  public expandCollapseChild(row: any, isexpanded: boolean) {
    row["expanded"] = isexpanded;
  }

  private _getConfig(): void {
    this._dataTableService.getConfig(this.configKey).subscribe((response: MainResponseModel) => {
      this._formCatalog = response.responseData as FormCatalog;
      this._checkConfig(this._appService.language());
      this._checkTableState();
    });
  }

  /**
   * @description To have a proper displayColumns and originalColumns so we can show in settings
   */
  private _checkConfig(lang: Language) {
    if (!this.adaaHelper.isDefined(this._formCatalog)) return;

    this._displayedColumns = this._dataTableService.getTableSettings(
      "displayColumns",
      this._formCatalog.parameterCatalogs,
      this.enableActionsCell,
      lang
    );
    this._originalColumns = this._dataTableService.getTableSettings(
      "originalColumns",
      this._formCatalog.parameterCatalogs,
      this.enableActionsCell,
      lang
    );
    this.currentSort = this._dataTableService.getDefaultSort(this._formCatalog.parameterCatalogs, lang);

    this._updateTableColumns();
  }

  /**
   * @description Show/Hide table columns
   */
  private _updateTableColumns(): void {
    this.tableColumns = this._formCatalog.parameterCatalogs
      .filter((e) => this._displayedColumns.includes(e.fieldName))
      .sort((a, b) => (a.displayOrder < b.displayOrder ? -1 : 1));

    this.tableColumns = this._dataTableService.checkColumnsForFilterModal(this.tableColumns, this.configKey);
  }

  private _applySettings(event: any): void {
    this.tableColumns = event;
    this._saveTableSettings(true);
  }

  private _applyFilters(event: any): void {
    this.currentFilter = event;
    this.searchFields = this._dataTableService.getSortAndFilterData(
      this.tableColumns,
      this.currentFilter,
      this.currentSort
    );

    //Issue fix
    //After applying the filters we need to reset the table to the first page
    this.currentPage = 1;
    this.offset = this.limit * (this.currentPage - 1);

    this._setTableState();
    this._getTableData(
      this.apiName,
      this.limit,
      this.offset,
      this.apiPrefix,
      this.paramsString,
      this.loader,
      this.searchFields
    );
  }

  /**
   * @param apiName
   * @param limit
   * @param offset
   * @param [prefix="item"]
   * @param paramsString
   * @param [loader=false]
   * @param searchFields
   * @description Get the table data from the api
   */
  private _getTableData(
    apiName: string,
    limit?: number,
    offset?: number,
    prefix: string = "item",
    paramsString?: string,
    loader: boolean = false,
    searchFields?: SearchFields
  ) {
    if (this.isLocalData) return;

    if (!this._dataTableDataService[apiName as keyof DataTableDataService]) return;

    searchFields = {
      searchFields: {
        ...searchFields?.searchFields,
        ...this.alwaysFilterBySearchFields().searchFields,
      },
    };

    return this._dataTableDataService[apiName as keyof DataTableDataService](
      limit,
      offset,
      prefix,
      paramsString,
      loader,
      searchFields
    ).subscribe({
      next: (response: MainResponseModel) => {
        if (response.inError) {
          this.tableData = [];
          this.totalRows = 0;
          return;
        }
        this.tableData = response.responseData as object[];
        this.totalRows = (response.additionalFields as TotalRows).totalOfRows;

        this.tableDataChanged.emit(this.tableData);
      },
      error: () => {
        if (isDevMode() && window.location.hostname === "localhost") {
          // eslint-disable-next-line no-console
          console.log(
            "%c Hey Developer: Error While getting the table data Maybe @Input() is missing or there is another issue",
            "background: #222; color: red; font-size: 1.5em"
          );
        }
      },
    });
  }

  private _exportTable(apiName: string, prefix: string = "item", paramsString?: string, loader: boolean = false) {
    if (!this._dataTableDataService[apiName as keyof DataTableDataService]) return;

    return this._dataTableDataService[apiName as keyof DataTableDataService](
      undefined,
      0,
      prefix,
      paramsString,
      loader,
      { searchFields: {} }
    ).subscribe({
      next: (response: MainResponseModel) => {
        if (response.inError) return;
        this._dataTableExportService.exportTableData(
          this._displayedColumns,
          this._formCatalog.parameterCatalogs,
          response.responseData as object[]
        );
      },
      error: () => {
        if (isDevMode() && window.location.hostname === "localhost") {
          // eslint-disable-next-line no-console
          console.log(
            "%c Hey Developer: Error While getting the table data Maybe @Input() is missing or there is another issue",
            "background: #222; color: red; font-size: 1.5em"
          );
        }
      },
    });
  }

  private _saveTableSettings(reloadTable: boolean = false) {
    this._dataTableService.saveColumnsConfig(this.tableColumns).subscribe((response: MainResponseModel) => {
      if (!response.inError) {
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        if (reloadTable) {
          this._getConfig();
          this._getTableData(
            this.apiName,
            this.limit,
            this.offset,
            this.apiPrefix,
            this.paramsString,
            this.loader,
            this.searchFields
          );
        }
      }
    });
  }

  /**
   * Sort Table Data Without Calling API this Used when isLocalData enabled
   * @param event
   */
  private _localSort(event: SortEvent) {
    this.tableData = this._dataTableService.sortDataLocally(event, this.tableData);
    //Reset paginator
    this.currentPage = 1;
  }

  /**
   * Filter Table Data Without Calling API this Used when isLocalData enabled
   * @param event
   */
  private _localFilter(event: any): void {
    this.currentFilter = event;
    this._applyLocalFilter();
  }

  /**
   * To check the table state on init table component
   * get the filters and the paginaton
   */
  private _checkTableState(): void {
    //If we have a default filter don't save the table state
    if (this.staticSearchFields().searchFields && Object.keys(this.staticSearchFields().searchFields).length > 0) {
      this.searchFields = {
        searchFields: {
          ...this.searchFields.searchFields,
          ...this.staticSearchFields().searchFields,
        },
      };
    } else {
      const state = this._dataTableService.getPageTableState(this._stateConfigKey);
      if (state === undefined) {
        this._setTableState();
      } else {
        this.currentPage = state.page;
        this.currentSort = state.sort;
        this.currentFilter = state.filter;
      }

      this.searchFields = this._dataTableService.getSortAndFilterData(
        this.tableColumns,
        this.currentFilter,
        this.currentSort
      );
    }

    this.offset = this.limit * (this.currentPage - 1);

    this._getTableData(
      this.apiName,
      this.limit,
      this.offset,
      this.apiPrefix,
      this.paramsString,
      this.loader,
      this.searchFields
    );
  }

  /**
   * To save the table state to avoid reseting the table sort/pagination/filters
   */
  private _setTableState(): void {
    this._dataTableService.setPageTableState(this._stateConfigKey, {
      page: this.currentPage,
      sort: this.currentSort,
      filter: this.currentFilter,
    });
  }

  private _applyLocalFilter(): void {
    this.searchFields = this._dataTableService.getSortAndFilterData(
      this.tableColumns,
      this.currentFilter,
      this.currentSort
    );

    this.tableDataFiltered = this._dataTableService.filterDataLocally(
      this.searchFields,
      this.tableColumns,
      this.tableDataCopy
    );

    //Reset paginator
    this.totalRows = this.tableDataFiltered.length;
    this.currentPage = 1;
    this.offset = this.limit * (this.currentPage - 1);
    const arrayCopy = this.adaaHelper.clone(this.tableDataFiltered);
    this.tableData = arrayCopy.splice(this.offset, this.limit);
  }

  private _doPagination(): void {
    this.offset = this.limit * (this.currentPage - 1);
    if (this.isLocalData) {
      const arrayCopy = this.adaaHelper.clone(this.tableDataFiltered);
      this.tableData = arrayCopy.splice(this.offset, this.limit);
    } else {
      this._setTableState();
      this._getTableData(
        this.apiName,
        this.limit,
        this.offset,
        this.apiPrefix,
        this.paramsString,
        this.loader,
        this.searchFields
      );
    }
  }
}
