import { inject, Injectable } from "@angular/core";

import { MainResponseModel, TpSectorResponse } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class TpSectorApiService {
  private _mainService = inject(MainService);

  public getAll() {
    const url = `national-sector/getAll`;

    return this._mainService.sendRequest<MainResponseModel<TpSectorResponse[]>>({
      method: "GET",
      url,
    });
  }

  public getProjectSectors() {
    const url = `national-sector/getAllLinkedToNationProject`;

    return this._mainService.sendRequest<MainResponseModel<TpSectorResponse[]>>({
      method: "GET",
      url,
    });
  }

  public getAgmSectors() {
    const url = `national-sector/getAllLinkedToAnnualMeetingProject`;

    return this._mainService.sendRequest<MainResponseModel<TpSectorResponse[]>>({
      method: "GET",
      url,
    });
  }

  public getTPSectorById(tpSectorId: number) {
    const url = `national-sector/getById/${tpSectorId}`;

    return this._mainService.sendRequest<MainResponseModel<TpSectorResponse>>({
      method: "GET",
      url,
    });
  }

  public createTPSector(tpCycle: Partial<TpSectorResponse>) {
    const url = `national-sector/create`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data: tpCycle,
    });
  }

  public updateTPSector(data: Partial<TpSectorResponse>) {
    const url = `national-sector/update/${data.id}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "PUT",
      url,
      data,
    });
  }

  public deleteTPSector(tpSectorId: number) {
    const url = `national-sector/deleteById/${tpSectorId}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "DELETE",
      url,
    });
  }
}
