import { Component, computed, inject, input, OnInit, signal } from "@angular/core";
import { TranslatePipe } from "@ngx-translate/core";
import * as fileSaver from "file-saver";
import { forkJoin, map, tap } from "rxjs";

import { AdaaBoolean } from "../../../../../../constants/enums";
import type { CycleModelType, EntityModelType, OrgUnit, UserModelType } from "../../../../../../models";
import { CyclesApiService, EntitiesApiService, FilesApiService, UsersApiService } from "../../../../../../services";
import {
  AccordionDiffViewComponent,
  AttachmentDiffViewComponent,
  ListDiffViewComponent,
  NormalDiffViewComponent,
} from "../../_lib";
import {
  AccordionSectionConfigType,
  CompositeSectionConfigType,
  ListSectionConfigType,
} from "../../wf-difference.types";
import { commonTpMilestoneScreenConfig } from "./tp-milestone-screen.config";

@Component({
  selector: "adaa-tp-milestone-screen",
  standalone: true,
  imports: [
    TranslatePipe,
    AttachmentDiffViewComponent,
    NormalDiffViewComponent,
    AccordionDiffViewComponent,
    ListDiffViewComponent,
  ],
  templateUrl: "./tp-milestone-screen.component.html",
  styleUrl: "./tp-milestone-screen.component.scss",
})
export class TpMilestoneScreenComponent implements OnInit {
  private readonly _usersApiService = inject(UsersApiService);
  private readonly _entitiesApiService = inject(EntitiesApiService);
  private readonly _cyclesApiService = inject(CyclesApiService);
  private readonly _filesApiService = inject(FilesApiService);

  showOnlyNew = input.required<boolean>();
  isNew = input<boolean>(false);
  newObject = input.required<Record<string, never>>();
  oldObject = input.required<Record<string, never>>();
  viewOnlyDifferences = input<boolean>(false);

  isScreenReady = signal<boolean>(false);
  cycles = signal<CycleModelType[]>([]);
  entities = signal<EntityModelType[]>([]);
  owners = signal<UserModelType[]>([]);
  orgUnits = signal<OrgUnit[]>([]);

  newData = computed(() => {
    const owners = this.owners();
    if (owners && owners.length < 1) return this.newObject();

    let object = this.newObject();

    if ("owner" in object) {
      const item = owners.find(({ id }) => id === object.owner);
      if (!item) return object;

      object = {
        ...object,
        ownerNameAE: item.nameAE as never,
        ownerNameEN: item.nameEN as never,
      };
    }

    return object;
  });
  oldData = computed(() => {
    const owners = this.owners();
    if (owners && owners.length < 1) return this.oldObject();

    let object = this.oldObject();

    if ("owner" in object) {
      const item = owners.find(({ id }) => id === object.owner);
      if (!item) return object;

      object = {
        ...object,
        ownerNameAE: item.nameAE as never,
        ownerNameEN: item.nameEN as never,
      };
    }

    return object;
  });

  readonly config = commonTpMilestoneScreenConfig;
  readonly $asAccordionConfig = ($s: CompositeSectionConfigType) => $s as AccordionSectionConfigType;
  readonly $asListConfig = ($s: CompositeSectionConfigType) => $s as ListSectionConfigType;

  readonly #getOwners = () => {
    const newEntityId = this.newObject().entityId;
    const oldEntityId = this.oldObject().entityId;
    const getOwnersPerEntity$ = [this._usersApiService.getOwnersPerEntity(newEntityId)];

    if (newEntityId !== oldEntityId) {
      getOwnersPerEntity$.push(...getOwnersPerEntity$, this._usersApiService.getOwnersPerEntity(oldEntityId));
    }

    return forkJoin(getOwnersPerEntity$).pipe(
      map((res) => {
        const list = [];
        for (const { responseData } of res) {
          list.push(...(responseData ?? []));
        }
        return list;
      }),
      tap((data) => this.owners.set(data))
    );
  };
  readonly #apiRequests = () => {
    const api$ = [this._cyclesApiService.getAll(), this._entitiesApiService.getAll(AdaaBoolean.Y)];
    const obs$ = forkJoin(api$).pipe(
      tap({
        next: ([cycles, entities]) => {
          this.cycles.set(cycles.responseData as CycleModelType[]);
          this.entities.set(entities.responseData as EntityModelType[]);
        },
      })
    );

    return forkJoin([obs$, this.#getOwners()]).subscribe({
      next: () => {
        this.isScreenReady.set(true);
      },
    });
  };

  public ngOnInit() {
    this.#apiRequests();
  }

  public downloadFile(data: Record<string, unknown>) {
    data.downloading = true;
    this._filesApiService.download(data.id as number, true).subscribe({
      next: (blob) => {
        fileSaver.saveAs(blob, data.filename as string);
      },
      error: () => {
        data.downloading = false;
      },
      complete: () => {
        data.downloading = false;
      },
    });
  }
}
