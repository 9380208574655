import { Component, inject, output, signal, TemplateRef, viewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { Language } from "../../../constants/enums";
import { AppService } from "../../../services";
import { FormActionButtonsComponent, FormInputComponent } from "../../form";

@Component({
  selector: "adaa-helpdesk-comments",
  standalone: true,
  imports: [TranslateModule, FormActionButtonsComponent, FormInputComponent],
  templateUrl: "./helpdesk-comments.component.html",
  styleUrl: "./helpdesk-comments.component.scss",
})
export class HelpdeskCommentsComponent {
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);

  modal = viewChild.required<TemplateRef<unknown>>("content");
  comment = output<string>();
  value = signal<string>("");

  public open() {
    this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });
  }

  public submit() {
    this.comment.emit(this.value());
    this._modalService.dismissAll();
  }
}
