import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import type { MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class FavoritesApiService {
  private _mainService = inject(MainService);

  public getUserFavoritePerItem({ itemId, objectType }: { itemId: number; objectType: number }) {
    const url = `favorite/userv3/${itemId}/${objectType}`;

    return this._mainService
      .sendRequest<
        MainResponseModel<{
          userId: number;
          itemId: number;
          itemTypeId: number;
          id: number;
        }>
      >({
        method: "GET",
        url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public create({ itemId, objectType }: { itemId: number; objectType: number }) {
    const url = "favorite/create";

    return this._mainService
      .sendRequest<MainResponseModel<boolean>>({
        method: "POST",
        url,
        data: { itemId, itemTypeId: objectType },
      })
      .pipe(filter((res) => !res.inError));
  }

  public remove(id: number) {
    const url = `favorite/delete/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<boolean>>({
        method: "POST",
        url,
        data: {},
      })
      .pipe(filter((res) => !res.inError));
  }
}
