import { Component, computed, effect, inject, input, output, signal } from "@angular/core";
import { Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { PercentageValuePipe, TranslateTokenPipe } from "../../../../core/pipes";
import { Constants } from "../../../constants/constants";
import type { GovEnablerPillarModelType } from "../../../models";
import { FilesApiService } from "../../../services";

@Component({
  selector: "adaa-gov-enabler-perf-card",
  standalone: true,
  templateUrl: "./gov-enabler-perf-card.component.html",
  styleUrl: "./gov-enabler-perf-card.component.scss",
  imports: [FontAwesomeModule, TranslateTokenPipe, PercentageValuePipe, TranslateModule],
})
export class GovEnablerPerfCardComponent {
  readonly router = inject(Router);
  private readonly _fileService = inject(FilesApiService);

  data = input.required<GovEnablerPillarModelType>();

  graphClicked = output<GovEnablerPillarModelType>();

  logo = signal<string | undefined>(undefined);

  width = computed(() => {
    if (!this.data().performance) return "0";
    return `${this.data().performance}%`;
  });
  color = computed(
    () => Constants.COLOR_CODES[this.data().performanceColor ?? Constants.CONSTANT_COLORS.EVALUATION_GRAY]
  );

  readonly graphIcon = faChartSimple;

  constructor() {
    effect(() => {
      this._downloadFile();
    });
  }

  public clickGraphIcon(event: Event) {
    event.stopPropagation();
    this.graphClicked.emit(this.data());
  }

  private _downloadFile() {
    if (this.data()?.logo) {
      this._fileService.download(this.data().logo).subscribe({
        next: async (blob) => {
          const dataUrl = window.URL.createObjectURL(blob);
          this.logo.update(() => dataUrl);
        },
      });
    }
  }
}
