import { Component, computed, inject, input, type OnInit, output, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import type { TaskModelType } from "../../../models";
import { CommunicationsApiService } from "../../../services";

@Component({
  selector: "adaa-notification-alert",
  standalone: true,
  imports: [TranslateModule, TranslateTokenPipe],
  styleUrl: "./notification-alert.component.scss",
  template: `
    @if (!!data()) {
      <div class="alert d-flex" [class]="type()" role="alert">
        <div class="w-100 d-flex align-items-center">
          {{ data() | translateToken: "title" }}
        </div>

        <div class="flex-shrink-1">
          <button class="btn btn-sm px-2" role="" (click)="viewClicked()">
            {{ actionText() | translate }}
          </button>
        </div>
      </div>
    }
  `,
})
export class NotificationAlertComponent implements OnInit {
  private readonly _communicationsApiService = inject(CommunicationsApiService);

  itemId = input.required<number>();
  itemType = input.required<number>();

  actionText = input<string>("common.tables.view");
  type = input<"info" | "danger" | "success" | "warning">("danger");

  data = signal<TaskModelType | undefined>(undefined);

  wfItemType = computed<number>(() => AdaaHelper.getWorkflowItemId(this.itemType()));

  actionClicked = output<TaskModelType>();

  public ngOnInit() {
    this._communicationsApiService
      .pendingActions({
        itemId: this.itemId(),
        itemType: this.wfItemType(),
      })
      .subscribe({
        next: (res) => this.data.set(res.responseData),
      });
  }

  public viewClicked(): void {
    if (this.data()) this.actionClicked.emit(this.data()!);
  }
}
