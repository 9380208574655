<adaa-data-table
    #dataTable
    [titleHeader]="title()"
    [configKey]="tableConf()"
    [enableExport]="false"
    [enableAdd]="!isDisabled()"
    [enableEditAction]="!isDisabled()"
    [enableDeleteAction]="!isDisabled()"
    [enableViewAction]="!replaceViewWithDownload()"
    [customActionButtons]="customButtons"
    [isLocalData]="true"
    [localData]="attachmentsData"
    (addNewClicked)="addNewClicked()"
    (actionClicked)="actionClicked($event)"
></adaa-data-table>
