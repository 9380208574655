import { Component, computed, inject, signal, type TemplateRef, viewChild } from "@angular/core";
import { NgbModal, type NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs";

import { Constants } from "../../../../constants/constants";
import type { WorkflowMonitoringItemModelType } from "../../../../models";
import { LanguageService, WorkflowsApiService } from "../../../../services";
import { DataTableComponent } from "../../../table/data-table/data-table.component";

@Component({
  selector: "adaa-wf-history",
  standalone: true,
  imports: [TranslateModule, DataTableComponent],
  templateUrl: "./wf-history.component.html",
  styleUrl: "./wf-history.component.scss",
})
export class WfHistoryComponent {
  private readonly _workflowsApiService = inject(WorkflowsApiService);
  private readonly _translateService = inject(TranslateService);
  private readonly _languageService = inject(LanguageService);
  private readonly _modalService = inject(NgbModal);

  tableData = signal<object[]>([]);
  data = signal<{ itemId: number; itemTypeId: number } | undefined>(undefined);

  paramString = computed(() => `itemId=${this.data()?.itemId}&itemType=${this.data()?.itemTypeId}`);

  content = viewChild.required<TemplateRef<unknown>>("content");

  readonly tableConfList = Constants.MAIN_TABLE_LIST_CONF_KEY.WORKFLOW_HISTORY;
  readonly tableDataLimit = Number.MAX_VALUE;

  modalRef: NgbModalRef;

  public open({ itemId, itemTypeId }: Pick<WorkflowMonitoringItemModelType, "itemId" | "itemTypeId">) {
    this.data.set({ itemId, itemTypeId });
    this.getTableData(itemId, itemTypeId);

    this.modalRef = this._modalService.open(this.content(), {
      animation: true,
      scrollable: false,
      ariaLabelledBy: this._translateService.instant("workflow.workflow_history"),
      centered: true,
      size: "xl",
      modalDialogClass: `modal-${this._languageService.direction()}`,
      beforeDismiss: () => {
        this.destory();
        return true;
      },
    });
  }

  public destory() {
    this.tableData.set([]);
    this.data.set(undefined);
  }

  public getTableData(id: number, typeId: number) {
    this._workflowsApiService
      .getWorkflowHistory(id, typeId)
      .pipe(filter((res) => !res.inError))
      .subscribe({
        next: (res) => {
          this.tableData.set(res.responseData as object[]);
        },
      });
  }
}
