import { Component, computed, EventEmitter, input, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { TpAgmDecisions, ValueText } from "../../../models";
import { FormCheckboxComponent, FormInputComponent } from "../../form";

@Component({
  selector: "adaa-tp-pmo-desisions",
  standalone: true,
  imports: [TranslateModule, FormCheckboxComponent, FormInputComponent],
  templateUrl: "./tp-pmo-desisions.component.html",
  styleUrl: "./tp-pmo-desisions.component.scss",
})
export class TpPmoDesisionsComponent {
  pmoDesisions = input.required<ValueText[]>();
  decisions = input.required<TpAgmDecisions[] | undefined>();

  @Output() decisionsChanged = new EventEmitter<TpAgmDecisions[]>();

  otherOption = computed<ValueText>(() => this.pmoDesisions()[this.pmoDesisions().length - 1]);
  otherOptionComment = computed<string>(() => {
    const decision = this.decisions()?.find((e) => e.optionId === this.otherOption().value);

    return decision?.comment ? decision.comment : "";
  });

  public isDesisionChecked(option: ValueText): boolean {
    const decision = this.decisions()?.find((e) => option && e.optionId === option.value);

    if (decision) return true;

    return false;
  }

  public checkboxChanged(value: boolean, option: ValueText): void {
    const decisions: TpAgmDecisions[] = this.decisions() ? AdaaHelper.clone(this.decisions()) : [];

    if (value) {
      decisions.push({ optionId: option.value, status: Constants.OBJECT_STATUS.ACTIVE });
    } else {
      const decisionIndex = decisions?.findIndex((e) => e.optionId === option.value);
      if (decisionIndex > -1) decisions.splice(decisionIndex, 1);
    }

    this.decisionsChanged.emit(decisions);
  }

  public otherOptionChanged(value: string | undefined): void {
    const decisions: TpAgmDecisions[] = this.decisions() ? AdaaHelper.clone(this.decisions()) : [];

    const decision = decisions.find((e) => e.optionId === this.otherOption().value);
    if (decision) decision.comment = value;

    this.decisionsChanged.emit(decisions);
  }
}
