import { Component, computed, inject, Input, OnInit, signal } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseChartDirective } from "ng2-charts";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { Language } from "../../../constants/enums";
import { MasterEkpiActuals, ValueText } from "../../../models";
import { ChartsService, GraphApiService } from "../../../services";
import { ChartActionButtonsComponent } from "../..";
import { FormDropdownComponent } from "../../form";

@Component({
  selector: "adaa-ekpi-entity-comparison",
  imports: [BaseChartDirective, FormDropdownComponent, ChartActionButtonsComponent],
  templateUrl: "./ekpi-entity-comparison.component.html",
  styleUrl: "./ekpi-entity-comparison.component.scss",
})
export class EkpiEntityComparisonComponent implements OnInit {
  private _graphApiService = inject(GraphApiService);
  modal = inject(NgbActiveModal);
  chartService = inject(ChartsService);

  @Input() id: number;
  @Input() currentYear: number;
  @Input() entityYears: ValueText[] = [];

  averagePerformance = signal<MasterEkpiActuals | undefined>(undefined);

  modalTitle = computed(() => {
    if (AdaaHelper.isPMOEntity()) {
      return `${AdaaHelper.getCurrentLang() === Language.Arabic ? this.averagePerformance()?.masterEkpiNameAR : this.averagePerformance()?.masterEkpiNameEN} - ${this.currentYear}`;
    } else {
      const ekpi = this.averagePerformance()?.ekpis.find((e) => e.entityId === AdaaHelper.entity.id);
      return ekpi
        ? `${AdaaHelper.getCurrentLang() === Language.Arabic ? ekpi.ekpiNameAR : ekpi.ekpiNameEN} - ${this.currentYear}`
        : "";
    }
  });
  ekpiPerformances = computed(() => (this.averagePerformance() ? this.averagePerformance()!.ekpis : []));
  usePerformance = computed(
    () =>
      this.averagePerformance()?.trendId === Constants.TREND.ONTARGET ||
      this.averagePerformance()?.trendId === Constants.TREND.BOUNDED
  );
  ekpiChartLabels = computed(() =>
    this.ekpiPerformances().map((data) => {
      const label = AdaaHelper.getCurrentLang() === Language.Arabic ? data.entityNameAR : data.entityNameEN;
      if (!label) return "";
      return label;
    })
  );

  performanceChartDataset = computed(() => {
    const barChartData = this.ekpiPerformances().map((e) => (this.usePerformance() ? e.performance : e.actual));
    const barChartColors = this.ekpiPerformances().map((e) => {
      const label = AdaaHelper.getCurrentLang() === Language.Arabic ? e.entityNameAR : e.entityNameEN;

      if (!label) {
        return this._getColor(Constants.CONSTANT_COLORS.EVALUATION_GRAY);
      }
      return this._getColor(e.performanceColor);
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const datasets: any = [
      {
        data: barChartData,
        backgroundColor: barChartColors,
        order: 1,
        tension: 0.5,
        extraData: this.ekpiPerformances()?.map((e) => (this.usePerformance() ? e.actual : e.performance)),
        ignoredList: this.ekpiPerformances()?.map((e) => e.ignored),
      },
    ];

    if (this.averagePerformance() && AdaaHelper.isPMOEntity()) {
      const lineChartData = this.ekpiPerformances().map(() =>
        this.usePerformance() ? this.averagePerformance()?.performance : this.averagePerformance()?.actual
      );
      const lineChartColor = this._getColor(Constants.CONSTANT_COLORS.EVALUATION_LIGHTBLUE);

      datasets.push({
        order: 0,
        tension: 0.75,
        type: "line",
        fill: false,
        showLine: true,
        data: lineChartData,
        borderColor: lineChartColor,
        label: AdaaHelper.getItemValueByToken(this.averagePerformance(), "governmentName"),
        pointStyle: "line",
      });
    }

    return datasets;
  });

  public ngOnInit(): void {
    this._getGraphData();
  }

  public yearChanged(event: number): void {
    this.currentYear = event;
    this._getGraphData();
  }

  private _getGraphData(): void {
    this._graphApiService.getEkpiActuals(this.id, this.currentYear).subscribe({
      next: (response) => {
        if (response.inError) return;

        this.averagePerformance.set(response.responseData);
        this._buildChart(response.responseData);
      },
    });
  }

  private _buildChart(data: MasterEkpiActuals): void {
    this.chartService.initEntityComparisonBarChartOptions({
      title: this._getChartTitle(data),
      usePerformance: this.usePerformance(),
    });
  }

  private _getChartTitle(data: MasterEkpiActuals): string {
    if (AdaaHelper.isPMOEntity()) {
      return AdaaHelper.getCurrentLang() === Language.Arabic ? data.measurementUnitAR : data.measurementUnitEN;
    } else {
      const ekpi = data.ekpis.find((e) => e.entityId === AdaaHelper.entity.id);
      return ekpi
        ? AdaaHelper.getCurrentLang() === Language.Arabic
          ? ekpi.measurementUnitAR
          : ekpi.measurementUnitEN
        : "";
    }
  }

  private _getColor(code: number) {
    return Constants.COLOR_CODES[code];
  }
}
