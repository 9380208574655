@if (column) {
    @if (column.fieldType === CellType.text) {
        <!-- NOTE: IF THE ENGLISH NAME IS NULL THEN USE THE ARABIC NAME -->
        @if (column.fieldName === "nameEN" && !AdaaHelper.isDefinedAndNotEmpty(data[column.fieldName])) {
            {{ data["nameAE"] }}
        } @else {
            {{ data[column.fieldName] }}
        }
    } @else if (column.fieldType === CellType.date) {
        {{ data[column.fieldName] | date: Constants.normalDate }}
    } @else if (column.fieldType === CellType.datetime) {
        {{ data[column.fieldName] | date: Constants.dateTime }}
    } @else if (column.fieldType === CellType.trueFalse) {
        <div class="flex-center">
            <input [checked]="data[column.fieldName]" class="form-check-input" type="checkbox" disabled />
        </div>
    } @else if (column.fieldType === CellType.checkbox) {
        <!--[checked] prop will not work without a form-->
        <input
            [checked]="isCheckboxChecked"
            [disabled]="isInputDisabled"
            class="form-check-input"
            type="checkbox"
            (change)="checkboxValueChanged($event, column.fieldName)"
        />
    } @else if (column.fieldType === CellType.dropdown) {
        <select [disabled]="isInputDisabled" class="form-select sub-gov-dropdown" (change)="dropdownValueChanged($event, column.fieldName)">
            @for (item of getDdlOptions; track item) {
                <option [value]="item.value" [selected]="data[column.fieldName] === item.value">
                    {{ item.text }}
                </option>
            }
        </select>
    } @else if (column.fieldType === CellType.status) {
        @if (data[column.fieldName] === 1) {
            {{ "status.1" | translate }}
        } @else if (data[column.fieldName] === 2) {
            {{ "status.2" | translate }}
        } @else if (data[column.fieldName] === 3) {
            {{ "status.3" | translate }}
        } @else if (data[column.fieldName] === 4) {
            {{ "status.4" | translate }}
        } @else if (data[column.fieldName] === 5) {
            {{ "status.5" | translate }}
        } @else if (data[column.fieldName] === 6) {
            {{ "status.6" | translate }}
        } @else if (data[column.fieldName] === 7) {
            {{ "status.in_review" | translate }}
        }
    } @else if (column.fieldType === CellType.trend) {
        @if (data[column.fieldName] === Constants.TREND.INCREASING) {
            <span class="adaa-icon-increasing trend"> </span>
        } @else if (data[column.fieldName] === Constants.TREND.DECREASING) {
            <span class="adaa-icon-decreasing trend"></span>
        } @else if (data[column.fieldName] === Constants.TREND.ONTARGET) {
            <span class="adaa-icon-ontarget trend"></span>
        } @else if (data[column.fieldName] === Constants.TREND.BOUNDED) {
            <span class="adaa-icon-bounded trend"></span>
        }
    } @else if (column.fieldType === CellType.metric) {
        @if (data[column.fieldName] === Constants.METRICS_TYPE.GLOBAL) {
            {{ "main_types.G" | translate }}
        } @else if (data[column.fieldName] === Constants.METRICS_TYPE.LOCAL) {
            {{ "main_types.L" | translate }}
        } @else if (data[column.fieldName] === Constants.METRICS_TYPE.LOCAL_ALL) {
            {{ "main_types.LA" | translate }}
        } @else if (data[column.fieldName] === Constants.METRICS_TYPE.DIMENSION) {
            {{ "main_types.D" | translate }}
        }
    } @else if (column.fieldType === CellType.rules) {
        @if (data[column.fieldName] === Constants.RULE_STATUS.INACTIVE) {
            {{ "common.form.label.inactive" | translate }}
        } @else if (data[column.fieldName] === Constants.RULE_STATUS.ACTIVE) {
            {{ "common.form.label.active" | translate }}
        }
    } @else if (column.fieldType === CellType.workflow) {
        @if (data[column.fieldName] === Constants.CONSTANT_WORKFLOW_WFTYPES.APPROVAL) {
            {{ "workflow.approval" | translate }}
        } @else if (data[column.fieldName] === Constants.CONSTANT_WORKFLOW_WFTYPES.REVIEW) {
            {{ "workflow.review" | translate }}
        }
    } @else if (column.fieldType === CellType.yesNo) {
        @if (data[column.fieldName] === AdaaBoolean.Y) {
            {{ "common.form.label.yes" | translate }}
        } @else {
            {{ "common.form.label.no" | translate }}
        }
    } @else if (column.fieldType === CellType.percentage) {
        <span class="badge rounded-1" [style.background]="getPercentageColor()">
            @if (AdaaHelper.isDefined(data[column.fieldName])) {
                {{ data[column.fieldName] | percentageValue: 2 : "" }}
            }
        </span>
    } @else if (column.fieldType === CellType.percentagePeriod) {
        @if (data["hasPeriodReading"] === null || data["hasPeriodReading"] === true) {
            <span class="badge rounded-1" [style.background]="getPercentageColor()">
                @if (AdaaHelper.isDefined(data[column.fieldName])) {
                    {{ data[column.fieldName] | percentageValue: 2 : "" }}
                }
            </span>
        } @else if (data["hasPeriodReading"] === false) {
            {{ "kpi.no_period" | translate }}
        }
    } @else if (column.fieldType === CellType.enabled) {
        @if (data[column.fieldName] === AdaaBoolean.Y) {
            {{ "common.form.label.active" | translate }}
        } @else {
            {{ "common.form.label.inactive" | translate }}
        }
    } @else if (column.fieldType === CellType.html) {
        <span [innerHTML]="data[column.fieldName] | safeHtml"></span>
    } @else if (column.fieldType === CellType.actual) {
        @if (data["ignored"]) {
            N/A
        } @else {
            @if (data["measurementUnit"] === Constants.MEASUREMENT.CONSTANT_MEASUREMENT_PERCENTAGE) {
                {{ getNumberValue(column.fieldName) | percentageValue: 2 : "" : "%" : true : true }}
            } @else {
                {{ getNumberValue(column.fieldName) | roundValue: 2 : "" : true }}
            }
        }
    } @else if (column.fieldType === CellType.specialCase) {
        <adaa-data-table-cell-special [data]="data" [column]="column" [configKey]="configKey"></adaa-data-table-cell-special>
    }
}
