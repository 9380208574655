import { Component, computed, effect, inject, Input, input, signal, untracked } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { filter, map } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { AdaaBoolean } from "../../../constants/enums";
import { FormControlDisabledDirective } from "../../../directives";
import { ItemModel, ParameterCatalog, SearchFields } from "../../../models";
import { ItemApiService } from "../../../services";
import { FormDropdownComponent } from "../../form";

@Component({
  selector: "adaa-national-target-form",
  standalone: true,
  imports: [ReactiveFormsModule, FormDropdownComponent, FormControlDisabledDirective, TranslateModule],
  template: `
    <form class="w-100 d-block card my-3 py-2 px-3 bg-white border-0 form-section" [formGroup]="model">
      <adaa-form-dropdown
        controlName="ntKpiId"
        formControlName="ntKpiId"
        [clearable]="true"
        [searchable]="true"
        [label]="'breadcrumbs.ntkpi_module.list' | translate"
        [options]="options()"
        [invalid]="model.get('ntKpiId')?.touched! && model.get('ntKpiId')?.invalid!"
        [required]="isFieldRequired(validations(), 'ntKpiId')"
      />
    </form>
  `,
  styleUrl: "./national-target-form.component.scss",
})
export class NationalTargetFormComponent {
  @Input() public set ntkpiId(data: unknown) {
    this.model.get("ntKpiId")?.setValue(data as number);
  }

  private readonly _itemApiService = inject(ItemApiService);

  entityId = input<number>();
  itemType = input<number>();
  validations = input.required<ParameterCatalog[]>();

  ntKpiList = signal<ItemModel[]>([]);

  options = computed(() => AdaaHelper.setDropdownArray(this.ntKpiList(), "id", AdaaHelper.getFieldLanguage("name")));

  readonly isFieldRequired = AdaaHelper.isFieldRequired.bind(AdaaHelper);

  readonly handleSubmit = () => {
    this.model.markAllAsTouched();
    if (this.model.invalid) {
      throw new Error("National Target form is invalid");
    }

    const ntkpiId = this.model.get("ntKpiId")?.value;

    return {
      ntkpiId,
      ntkpiObject: this.ntKpiList().find(({ id }) => id === ntkpiId),
    };
  };

  readonly #getNtKpis = (entityId: number) => {
    const searchFields: SearchFields = {
      searchFields: {
        status: {
          order: undefined,
          value: undefined,
          lov: [1],
        },
      },
    };

    this._itemApiService
      .getAllV2(Constants.KPI_TYPE.NTKPI, AdaaBoolean.N, searchFields, entityId)
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => {
          this.ntKpiList.set(data ?? []);
        },
        complete: () => {
          const ntkpi = this.model.get("ntKpiId")?.value;

          if (ntkpi) {
            const index = this.ntKpiList().findIndex(({ id }) => id == ntkpi);
            if (index === -1) this.model.get("ntKpiId")?.setValue(null);
          }
        },
      });
  };

  model = new FormGroup({
    ntKpiId: new FormControl<number | null>(null, []),
  });

  constructor() {
    effect(() => {
      const entityId = this.entityId();

      if (entityId) {
        untracked(() => {
          this.#getNtKpis(entityId);
        });
      }
    });
  }
}
