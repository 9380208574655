import { Component, EventEmitter, inject, Input, input, Output, signal } from "@angular/core";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";

import { TranslateTokenPipe } from "../../../../../../core/pipes";
import { AdaaHelper } from "../../../../../../core/utils";
import { Constants } from "../../../../../constants/constants";
import type { CycleModelType, EntityModelType } from "../../../../../models";
import { SystemLayoutService } from "../../../../../services";

@Component({
  selector: "adaa-period-selector",
  standalone: true,
  imports: [NgbDropdownModule, TranslateTokenPipe],
  styleUrls: ["./selectors.scss"],
  template: `
    <div ngbDropdown class="period-selector">
      <button type="button" class="btn btn-outline-white main" id="sysLayoutCycleSelector" ngbDropdownToggle>
        @if (isExternalEntity()) {
          <span class="fw-bolder">
            <i class="fa fa-minus"></i>
          </span>
        } @else {
          <span>{{ activeCycle() | translateToken: "name" }}</span>
        }
      </button>

      @if (!isExternalEntity()) {
        <div ngbDropdownMenu aria-labelledby="sysLayoutCycleSelector" role="menu">
          @for (cycle of cycles(); track cycle.id) {
            <button ngbDropdownItem [class.active]="cycle.id === activeCycle().id" (click)="periodSelected.emit(cycle)">
              <span class="text">{{ cycle | translateToken: "name" }}</span>
            </button>
          }
        </div>
      } @else {
        <div ngbDropdownMenu aria-labelledby="sysLayoutCycleSelector" role="menu"></div>
      }
    </div>
  `,
})
export class PeriodSelectorComponent {
  @Input() public set activeEntity(entity: EntityModelType | null) {
    if (entity) {
      this.isExternalEntity.set(entity.entityType === Constants.CONSTANT_EXTERNAL_ENTITY_TYPE);
    }
  }
  private readonly _systemLayoutService = inject(SystemLayoutService);

  activeCycle = input.required<CycleModelType>();
  cycles = input.required<CycleModelType[]>();

  @Output() periodSelected = new EventEmitter<CycleModelType>();

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  isExternalEntity = signal<boolean>(false);

  public ngOnInit() {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        if (this._systemLayoutService.activeEntity()) {
          this.isExternalEntity.set(
            this._systemLayoutService.activeEntity()?.entityType === Constants.CONSTANT_EXTERNAL_ENTITY_TYPE
          );
        }
      },
    });
  }
}
