@if (hasTitle()) {
    <section class="position-relative item-slider--title">
        <ng-content select="[title]" />
    </section>
}

<section class="position-relative item-slider--content" [class.with-performance]="showPerformance()">
    <div class="position-relative item-slider--performance">
        <ng-content select="[performance]"></ng-content>
    </div>
    <div class="position-relative item-slider--carousel">
        <button class="item-slider--btn" (click)="onClick(el, languageService.direction() === 'ltr' ? 'left' : 'right')">
            @if (languageService.direction() === "ltr") {
                <fa-icon [icon]="leftArrow" />
            } @else {
                <fa-icon [icon]="rightArrow" />
            }
        </button>

        <div class="position-relative item-slider--stats" #el>
            <ng-content />
        </div>

        <button class="item-slider--btn" (click)="onClick(el, languageService.direction() === 'ltr' ? 'right' : 'left')">
            @if (languageService.direction() === "ltr") {
                <fa-icon [icon]="rightArrow" />
            } @else {
                <fa-icon [icon]="leftArrow" />
            }
        </button>
    </div>
</section>
