import { Component, computed, inject, input, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { debounceTime, distinctUntilChanged, filter, forkJoin, map, type Observable, switchMap } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { AdaaBoolean } from "../../../constants/enums";
import type { TagModelType, TagType } from "../../../models";
import { TagsApiService } from "../../../services";

@Component({
  selector: "adaa-object-tags",
  standalone: true,
  imports: [TranslateModule, FormsModule, NgbTypeaheadModule],
  styleUrl: "./styles.scss",
  template: `
    <section class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 p-2 object-tags shadow-sm">
      <div class="w-100 d-flex align-content-center object-tags--item">
        <span class="d-block w-50 fw-bold mb-2">
          {{ "plusbutton.tags" | translate }}
        </span>
      </div>

      <div class="w-100">
        <div class="input-group">
          <input
            type="search"
            class="form-control"
            id="view-card-tag"
            aria-describedby="view-card-tag"
            [(ngModel)]="model"
            [ngbTypeahead]="search"
          />

          <button class="btn btn-sm btn-secondary input-group-text cursor-pointer" (click)="addTag()">
            <i class="fa fa-plus"></i> {{ "tooltips.add" | translate }}
          </button>
        </div>
      </div>

      <div class="w-100 mt-2">
        @for (tag of myTags(); track tag.id) {
          <span
            class="badge text-white m-1 fs-6"
            [class.text-bg-warning]="tag.isPmo === 'N'"
            [class.text-bg-info]="tag.isPmo === 'Y'"
          >
            {{ tag.nameEN }}
            <span class="px-1 text-white fw-bold fs-6 cursor-pointer" (click)="deleteTag(tag.id)">
              <i class="adaa-icon-delete"></i>
            </span>
          </span>
        }
      </div>
    </section>
  `,
})
export class ObjectTagsComponent {
  private readonly _tagsApiService = inject(TagsApiService);

  id = input.required<number>();
  type = input.required<number>();
  itemType = input.required<number>();

  tags = signal<TagType[]>([]);
  associatedTags = signal<TagType[]>([]);

  myTags = computed<TagType[]>(() => {
    const userEntityId = AdaaHelper.getLocalStorage("user", { type: "prop", property: "entityId" });
    if (!AdaaHelper.isPMOEntity(Number(userEntityId))) {
      return this.associatedTags().filter(({ isPmo }) => isPmo === AdaaBoolean.N);
    }
    return this.associatedTags();
  });

  readonly search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter((term: string) => term.length > 0),
      map((term: string) => term.trim()),
      map((term: string) =>
        this._filteredTags().filter(({ nameEN }) =>
          nameEN.toLowerCase().valueOf().includes(term.toLowerCase().valueOf())
        )
      ),
      map((c) => c.map(({ nameEN }) => nameEN))
    );

  model: unknown;

  public ngOnInit() {
    this.getTags();
  }

  public getTags() {
    forkJoin([
      this._tagsApiService.getAssociatedTags({ itemId: this.id(), itemType: this.itemType() }),
      this._tagsApiService.getAll({ itemId: this.id(), itemType: this.itemType() }),
    ]).subscribe({
      next: ([associatedTags, allTags]) => {
        this.tags.set([...(allTags.responseData || [])]);
        this.associatedTags.set([...(associatedTags.responseData || [])]);
      },
    });
  }

  public addTag() {
    const userEntityId = AdaaHelper.getLocalStorage("user", { type: "prop", property: "entityId" });
    const tag: TagModelType = {
      isPmo: AdaaHelper.isPMOEntity(Number(userEntityId)) ? AdaaBoolean.Y : AdaaBoolean.N,
      itemID: this.id(),
      itemTypeID: this.itemType(),
      nameAE: (this.model as string).trim(),
      nameEN: (this.model as string).trim(),
      tagColor: "#d29f35",
    };
    const tags = this.tags().filter((data) => data.nameEN === tag.nameEN);
    const oldTag = this._filteredTags().find((data) => data.nameEN === tag.nameEN);

    if (!tags.length && !oldTag) {
      return this._tagsApiService
        .createTag(tag, { itemId: this.id(), itemType: this.itemType() })
        .pipe(
          switchMap((res) =>
            this._tagsApiService.associateTag(
              {
                id: res.responseData,
                tagId: res.responseData,
                itemId: this.id(),
                itemTypeId: this.itemType(),
              },
              { itemId: this.id(), itemType: this.itemType() }
            )
          )
        )
        .subscribe({
          next: () => {
            this.getTags();
            this.model = "";
          },
        });
    }

    return this._tagsApiService
      .associateTag(
        {
          id: oldTag?.id as number,
          tagId: oldTag?.id as number,
          itemId: this.id(),
          itemTypeId: this.itemType(),
        },
        { itemId: this.id(), itemType: this.itemType() }
      )
      .subscribe({
        next: () => {
          this.getTags();
          this.model = "";
        },
      });
  }

  public deleteTag(id: number) {
    this._tagsApiService
      .deleteTag({
        itemId: this.id(),
        itemType: this.itemType(),
        id,
      })
      .subscribe({
        next: () => this.getTags(),
      });
  }

  private _filteredTags() {
    const list = [...this.tags()];

    this.associatedTags().forEach((tag) => {
      const foundTags = list.filter(({ nameEN }) => nameEN.valueOf() === tag.nameEN.valueOf());

      for (const item of foundTags) {
        const index = list.findIndex((tag) => tag.id === item.id);
        if (index !== -1) {
          list.splice(index, 1);
        }
      }
    });

    return list;
  }
}
