import { inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MergeCellsSettings, Settings } from "handsontable/plugins/mergeCells";
import { CellMeta } from "handsontable/settings";
import { evaluate, round } from "mathjs";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import { AdaaBoolean } from "../constants/enums";
import {
  AuditItem,
  ColumnsIndices,
  DataEntry,
  DataEntryAuditEntry,
  DataEntryDetails,
  DataEntryMetricDetail,
} from "../models";

@Injectable({
  providedIn: "root",
})
export class DataentryService {
  private _translateService = inject(TranslateService);

  public getOptionCell(row: number, column: number, isNTKPI: boolean = false): string {
    const relatedObjects = isNTKPI
      ? `<span class="adaa-icon-adaa-related-objects pointer tableAction" style="margin: 3px;font-size: 16px" data-type="related" data-row="${row}" data-col="${column}"></span>`
      : "";

    return `<div data-row="${row}" data-col="${column}" style="display: inline-flex; align-items: center;">
        <span class="adaa-icon-dimensions pointer tableAction" style="margin: 3px;font-size: 20px" data-type="dimensions" data-row="${row}" data-col="${column}"></span>
        <span class="adaa-icon-edit pointer tableAction" style="margin: 3px;font-size: 20px" data-type="edit" data-row="${row}" data-col="${column}"></span>
        ${relatedObjects}
    </div>`.trim();
  }

  public getLabelForPeriodColumn(dataEntry: DataEntry, frequency: number): string {
    switch (frequency) {
      case Constants.FREQUENCY_MONTHLY:
        return `${AdaaHelper.getMonthName(dataEntry.month, AdaaHelper.getCurrentLang(), false)} ${dataEntry.year}`;
      case Constants.FREQUENCY_QUARTERLY:
        return `${dataEntry.year} ${this._translateService.instant(`data_entry.q${dataEntry.quarter}`)}`;
      case Constants.FREQUENCY_SEMIANNUAL:
        return `${this._translateService.instant(`targets.periods.semestral.${dataEntry.semester}`)} ${dataEntry.year}`;
      default:
        return dataEntry.year ? dataEntry.year?.toString() : "";
    }
  }

  public setCellColorFormulaKpi(
    row: number,
    column: number,
    dataEntry: Map<number, DataEntry[]>,
    auditEntries: DataEntryAuditEntry[] | undefined,
    columnsIndices: ColumnsIndices
  ): CellMeta {
    const actual = columnsIndices.actualColumns.find((e) => e.index === column);
    const auditActual = columnsIndices.auditAnnualActualColumns.find((e) => e.index === column);
    if (actual) {
      const entry = Array.from(dataEntry.values())[row];
      if (entry) {
        const metricEntry = entry.find((e) => e.metricNameEN == actual.metricNameEN);
        return this._getActualCellColor(metricEntry, AdaaHelper.isDefined(actual.metricType));
      }
    } else if (auditActual) {
      const entry = Array.from(dataEntry.values())[row];
      if (entry?.[0]) {
        const auditEntry = auditEntries?.find((e) => e.year === entry[0].year);
        if (auditEntry?.hasAuditIssue) return { className: "hasAuditIssueCell", readOnly: true };
      }
    }

    return {};
  }

  public setCellColorNoFormulaKpi(row: number, column: number, dataEntry: DataEntryDetails): CellMeta {
    if (column === 1) {
      if (!dataEntry.dataEntries) return {};
      const entry = dataEntry.dataEntries![row];

      return this._getActualCellColor(entry);
    } else if ((this._isBounded(dataEntry) && column === 5) || (!this._isBounded(dataEntry) && column === 4)) {
      const entry = dataEntry.dataEntries![row];

      if (entry) {
        const issue = dataEntry.auditEntries?.find((e) => e.year == entry.year);
        if (issue?.hasAuditIssue) return { className: "hasAuditIssueCell", readOnly: true };
      }
    }
    return {};
  }

  public setCellColorEKpi(row: number, column: number, dataEntry: DataEntryDetails, columnsIndices: ColumnsIndices) {
    if (row === 0) {
      const auditActual = columnsIndices.auditAnnualActualColumns.find((e) => e.index === column);
      if (auditActual) {
        const audit = dataEntry.auditEntries?.find((e) => e.year === auditActual.year);
        if (audit?.hasAuditIssue) return { className: "hasAuditIssueCell", readOnly: true };
      }
    } else {
      const actual = columnsIndices.actualColumns.find((e) => e.index === column);
      const auditActual = columnsIndices.auditAnnualActualColumns.find((e) => e.index === column);
      if (actual) {
        const entity = dataEntry.entityDataEntries![row - 1];
        if (entity) {
          const entry = entity.dataEntries?.find(
            (e) =>
              (actual.metricNameEN ? e.metricNameEN === actual.metricNameEN : true) && e.periodId === actual.periodId
          );
          if (entry) {
            return this._getActualCellColor(
              entry,
              !actual.metricNameEN ? false : AdaaHelper.isDefined(actual.metricType) // if no formula don't check the enable/disable by metric type
            );
          }
        }
      } else if (auditActual) {
        const entity = dataEntry.ekpiAuditEntries![row - 1];
        if (entity) {
          const audit = entity.audits?.find((e) => e.year === auditActual.year);
          if (audit?.hasAuditIssue) return { className: "hasAuditIssueCell", readOnly: true };
        }
      }
    }
    return {};
  }

  public setCellColorKpiAudit(row: number, column: number, dataEntry: DataEntryDetails, updatedAudits: AuditItem[]) {
    if (
      (this._isBounded(dataEntry) && (column === 4 || column === 5)) ||
      (!this._isBounded(dataEntry) && (column === 3 || column === 4))
    ) {
      const numberOfMetrics = dataEntry.metricDetails?.length ?? 1;
      const entry = dataEntry.dataEntries ? dataEntry.dataEntries[row * numberOfMetrics] : undefined;
      if (!entry) return {};

      const audit = dataEntry.auditEntries?.find((e) => e.year == entry.year);
      if (audit) {
        if (AdaaHelper.isDefined(audit.wfProcessCtlId)) return { readOnly: true, className: "inReviewCell" };
        else if (
          this._checkAuditValueDiff(
            audit,
            updatedAudits,
            (this._isBounded(dataEntry) && column === 4) || (!this._isBounded(dataEntry) && column === 3)
          )
        )
          return { className: "" };
        else if (
          this._checkAuditValueDiff(
            audit,
            updatedAudits,
            (this._isBounded(dataEntry) && column === 5) || (!this._isBounded(dataEntry) && column === 4)
          )
        )
          return { className: "" };
        else if (audit.auditDataStatus === Constants.OBJECT_WF_STATUS.ACTIVE) return { className: "approvedCell" };
      }
    }

    return {};
  }

  public setCellColorEKpiAudit(
    row: number,
    col: number,
    dataEntry: DataEntryDetails,
    updatedAudits: AuditItem[],
    columnsIndices: { year: number; col: number; isComment: boolean }[]
  ) {
    const column = columnsIndices.find((e) => e.col === col);
    if (!column) return {};

    if (row === 0) {
      const audit = dataEntry.auditEntries?.find((e) => e.year === column.year);

      if (audit) {
        if (AdaaHelper.isDefined(audit.wfProcessCtlId)) return { readOnly: true, className: "inReviewCell" };
        else if (this._checkAuditValueDiff(audit, updatedAudits, column.isComment)) return { className: "" };
        else if (audit.auditDataStatus === Constants.OBJECT_WF_STATUS.ACTIVE) return { className: "approvedCell" };
      }
    } else {
      const entity = dataEntry.ekpiAuditEntries![row - 1];
      if (entity) {
        const audit = entity.audits?.find((e) => e.year === column.year);
        if (audit) {
          if (AdaaHelper.isDefined(audit.wfProcessCtlId)) return { readOnly: true, className: "inReviewCell" };
          else if (this._checkAuditValueDiff(audit, updatedAudits, column.isComment)) return { className: "" };
          else if (audit.auditDataStatus === Constants.OBJECT_WF_STATUS.ACTIVE) return { className: "approvedCell" };
        }
      }
    }

    return {};
  }

  public getMergedCells(
    dataEntry: DataEntryDetails,
    annualCol: number,
    isFormula: boolean = false,
    numberOfMetrics: number = 0
  ): Settings {
    const output: Settings = [];

    if (!dataEntry.dataEntries) return output;

    const annualGroupingOfMetrics: { [key: number]: Array<DataEntry> } = {};

    dataEntry.dataEntries?.forEach((e) => {
      if (!AdaaHelper.isDefined(annualGroupingOfMetrics[e.year])) annualGroupingOfMetrics[e.year] = [];

      const samePeriodInArray = annualGroupingOfMetrics[e.year].some((p) => p.periodId === e.periodId);
      if (!samePeriodInArray) annualGroupingOfMetrics[e.year].push(e);
    });

    const ANNUAL_ACTUAL_COLSPAN =
      annualCol +
      (!this._isBounded(dataEntry) ? 0 : 1) +
      (!isFormula ? 0 : 1) +
      (!this._hasTarget(dataEntry) ? 0 : numberOfMetrics) +
      numberOfMetrics;

    const AUDITED_ANNUAL_ACTUAL_COLSPAN = ANNUAL_ACTUAL_COLSPAN + 1;
    const COMMENT_COLSPAN = AUDITED_ANNUAL_ACTUAL_COLSPAN + 1;
    const COL_SPAN = 1;

    let lastIndex = 0;
    const generateMergeCellsOutput = (year: string, col: number) => {
      const multiplication_factor = annualGroupingOfMetrics[+year].length;
      const res: MergeCellsSettings = {
        col,
        colspan: COL_SPAN,
        row: lastIndex,
        rowspan: multiplication_factor,
      };

      lastIndex = lastIndex + multiplication_factor;
      output.push(res);
    };

    //Annual Actual Cell Merging
    lastIndex = 0;
    Object.keys(annualGroupingOfMetrics).forEach((year: string): void =>
      generateMergeCellsOutput(year, ANNUAL_ACTUAL_COLSPAN)
    );

    //Audited Annual Actual Cell Merging
    lastIndex = 0;
    Object.keys(annualGroupingOfMetrics).forEach((year: string): void =>
      generateMergeCellsOutput(year, AUDITED_ANNUAL_ACTUAL_COLSPAN)
    );

    //Audited Annual Actual Cell Merging
    lastIndex = 0;
    Object.keys(annualGroupingOfMetrics).forEach((year: string): void =>
      generateMergeCellsOutput(year, COMMENT_COLSPAN)
    );

    return output;
  }

  public calcFormula(
    dataEntries: DataEntry[],
    metrics: DataEntryMetricDetail[] | undefined,
    formula: string | undefined,
    isAuditPage: boolean = false
  ) {
    metrics?.forEach((metric, _index) => {
      const entry = dataEntries.find((e) => e.metricNameEN === metric.metricNameEN);
      if (entry && formula) formula = formula.replace(new RegExp(`${metric.metricNameEN}`, "g"), `${entry.actual}`);
    });

    try {
      const val = formula ? round(evaluate(formula), isAuditPage ? 5 : 2) : null;

      if (isAuditPage) return AdaaHelper.isDefined(val) && !isNaN(val) && isFinite(val) ? val : null;
      else return AdaaHelper.isDefined(val) && !isNaN(val) ? val : null;
    } catch (_e) {
      return null;
    }
  }

  private _getActualCellColor(
    entry: DataEntry | undefined,
    isActualReadOnly: boolean = false //ONLY CUSTOM METERICS ALLOW TO EDIT
  ): CellMeta {
    if (!entry) return {};

    const isReadOnlyMetric = this._isReadOnlyMetric(entry.metricType);

    if (entry.inWorkflow) return { readOnly: true, className: "inReviewCell" };
    else if (entry.invalid) return { readOnly: isActualReadOnly || isReadOnlyMetric, className: "errorCell" };
    else if (entry.actual !== entry.liveActual || entry.ignored !== entry.liveIgnored)
      return { readOnly: isActualReadOnly || isReadOnlyMetric, className: "" };

    return {
      readOnly: isReadOnlyMetric
        ? true
        : entry.actual != null || entry.ignored
          ? false
          : !entry.allowEdit || isActualReadOnly,
      className: entry.actual != null || entry.ignored ? "approvedCell" : !entry.allowEdit ? "disabledCell" : "",
    };
  }

  private _isBounded(dataEntry: DataEntryDetails) {
    return dataEntry.trend === Constants.TREND.BOUNDED;
  }

  private _hasTarget(dataEntry: DataEntryDetails) {
    return dataEntry.hasTarget === AdaaBoolean.Y;
  }

  private _checkAuditValueDiff(audit: DataEntryAuditEntry, updatedAudits: AuditItem[], isComment: boolean) {
    const updatedAudit = updatedAudits.find((e) => e.entityId === audit.entityId && e.year === audit.year);
    if (!updatedAudit) return false;

    return isComment
      ? audit.comment !== updatedAudit.comment
      : audit.auditedActual !== updatedAudit.auditedActual || audit.annualActualIgnored !== updatedAudit.ignored;
  }

  private _isReadOnlyMetric(metricType: string | undefined): boolean {
    switch (metricType) {
      case Constants.METRICS_TYPE.DIMENSION:
      case Constants.METRICS_TYPE.GLOBAL:
      case Constants.METRICS_TYPE.GLOBAL_LOCAL:
      case Constants.METRICS_TYPE.LOCAL:
      case Constants.METRICS_TYPE.LOCAL_ALL:
        return true;
      default:
        return false;
    }
  }
}
