import { inject, Injectable } from "@angular/core";
import { filter, map, switchMap, take, timer } from "rxjs";

import { environment } from "../../core/environments/environment";
import { MainResponseModel, ReportRequestModel, ReportResponse } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class ReportDataService {
  private _mainService = inject(MainService);

  public startReportDownload(data: ReportRequestModel[]) {
    return this._mainService.sendRequest<MainResponseModel<ReportResponse[]>>({
      method: "POST",
      url: "report/request",
      apiUrl: environment.reportingUrl,
      data,
      loader: false,
    });
  }

  public getReportStatus(requestId: number | string, multiplier: number) {
    return timer(1000 + multiplier * 1000, 2000 + multiplier * 1000).pipe(
      switchMap(() => this.getDownloadStatus(requestId as number)),
      filter(
        ({ responseData: { status } }: { responseData: ReportResponse }) =>
          status.toLowerCase() === "completed" || status.toLowerCase() === "failed"
      ),
      take(1)
    );
  }

  public getDownloadStatus(requestId: number) {
    return this._mainService.sendRequest<MainResponseModel<ReportResponse>>({
      method: "GET",
      url: `report/request/${requestId}/status`,
      apiUrl: environment.reportingUrl,
      loader: false,
    });
  }

  public downloadReportFile(requestId: string | number, filename: string) {
    return this._mainService
      .sendRequest<Blob>({
        method: "GET",
        url: `report/download/${requestId}`,
        apiUrl: environment.reportingUrl,
        responseType: "blob",
        loader: false,
      })
      .pipe(
        map((response) => {
          this._downloadBlob(response, filename);
        })
      );
  }

  private _downloadBlob(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
