import { Component, inject, input, OnInit, output, signal } from "@angular/core";
import { Router } from "@angular/router";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { TranslatePipe } from "@ngx-translate/core";

import { PercentageValuePipe, TranslateTokenPipe } from "../../../../../core/pipes";
import type { VisionSubGovDirection } from "../../../../models";
import { FilesApiService } from "../../../../services";

@Component({
  selector: "adaa-sub-gov-vision-card",
  imports: [TranslateTokenPipe, PercentageValuePipe, FaIconComponent, TranslatePipe],
  templateUrl: "./sub-gov-vision-card.component.html",
  styleUrl: "./sub-gov-vision-card.component.scss",
})
export class SubGovVisionCardComponent implements OnInit {
  readonly router = inject(Router);
  private readonly _filesApiService = inject(FilesApiService);

  readonly data = input.required<VisionSubGovDirection>();

  readonly image = signal<string | undefined>(undefined);

  readonly graphClicked = output<void>();

  readonly graphIcon = faChartSimple;

  public ngOnInit() {
    this._downloadLogo();
  }

  private _downloadLogo() {
    this._filesApiService.download(this.data().logo).subscribe({
      next: (blob) => {
        const url = window.URL.createObjectURL(blob);
        this.image.set(url);
      },
    });
  }
}
