import { inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { AdaaHelper, useNewDS } from "../../core/utils";
import { isDtkpi, isNtkpi } from "../components";
import { Constants } from "../constants/constants";
import { CustomButton, KpiTypeConstantType } from "../models";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class KpisService {
  private readonly _userService = inject(UserService);
  private readonly _translateService = inject(TranslateService);

  public getConfigKey(kpiType: number): string {
    switch (kpiType) {
      case Constants.KPI_TYPE.UAE:
        return "dkpis_conf_list";
      case Constants.KPI_TYPE.NKPI:
        return "nkpis_conf_list";
      case Constants.KPI_TYPE.NTKPI:
        return "ntkpis_conf_list";
      case Constants.KPI_TYPE.DTKPI:
        return "dtkpis_conf_list";
      case Constants.KPI_TYPE.SKPI:
        return "skpis_conf_list";
      case Constants.KPI_TYPE.EKPI:
        return "ekpis_conf_list";
      case Constants.KPI_TYPE.MOKPI:
        return "mokpis_conf_list";
      case Constants.KPI_TYPE.MTKPI:
        return "mtkpis_conf_list";
      case Constants.KPI_TYPE.OPM:
        return "opm_conf_list";
      case Constants.KPI_TYPE.SRVKPI:
        return "srvkpis_conf_list";
      default:
        return "";
    }
  }

  public getTableTitle(kpiType: number, str: "list" | "view" | "edit" | "new", useSingular = false): string {
    switch (kpiType) {
      case Constants.KPI_TYPE.UAE:
        return `breadcrumbs.dkpi_module.${str}`;
      case Constants.KPI_TYPE.NKPI:
        return `breadcrumbs.nkpi_module.${str}`;
      case Constants.KPI_TYPE.NTKPI:
        return `breadcrumbs.ntkpi_module.${str}`;
      case Constants.KPI_TYPE.DTKPI:
        return `breadcrumbs.dtkpi_module.${str}`;
      case Constants.KPI_TYPE.SKPI:
        return `breadcrumbs.skpi_module.${str}`;
      case Constants.KPI_TYPE.SRVKPI:
        return `breadcrumbs.srvkpi_module.${str}`;
      case Constants.KPI_TYPE.EKPI:
        return `breadcrumbs.ekpi_module.${str}`;
      case Constants.KPI_TYPE.MOKPI:
        return `breadcrumbs.mokpi_module.${str}`;
      case Constants.KPI_TYPE.MTKPI:
        return `breadcrumbs.mtkpi_module.${str}`;
      case Constants.KPI_TYPE.OPM: {
        if (!useNewDS()) return `breadcrumbs.opm_module.${str}`;
        if (str === "view") return "opm.view_opm_measure";
        if (str === "edit") return "opm.edit_opm_measure";
        if (str === "new") return "opm.create_opm_measure";
        return `opm.${useSingular ? "opm_measure" : "opm_measure"}`;
      }
      default:
        return "";
    }
  }

  public getKpiTypeString(kpiType: number): string {
    switch (kpiType) {
      case Constants.KPI_TYPE.UAE:
        return "dkpi";
      case Constants.KPI_TYPE.NKPI:
        return "nkpi";
      case Constants.KPI_TYPE.NTKPI:
        return "ntkpi";
      case Constants.KPI_TYPE.DTKPI:
        return "dtkpi";
      case Constants.KPI_TYPE.SKPI:
        return "skpi";
      case Constants.KPI_TYPE.SRVKPI:
        return "srvkpi";
      case Constants.KPI_TYPE.EKPI:
        return "ekpi";
      case Constants.KPI_TYPE.MOKPI:
        return "mokpi";
      case Constants.KPI_TYPE.MTKPI:
        return "mtkpi";
      case Constants.KPI_TYPE.OPM: {
        return "opm";
      }
      default:
        throw new Error("KPI not known");
    }
  }

  public getWorkflowItemId(kpiType: number): number {
    switch (kpiType) {
      case Constants.KPI_TYPE.SRVKPI:
        return Constants.CONSTANT_WORKFLOW.SEKPI;
      case Constants.KPI_TYPE.UAE:
        return Constants.CONSTANT_WORKFLOW.DKPI;
      case Constants.KPI_TYPE.NKPI:
        return Constants.CONSTANT_WORKFLOW.NKPI;
      case Constants.KPI_TYPE.NTKPI:
        return Constants.CONSTANT_WORKFLOW.NATIONAL_TARGET;
      case Constants.KPI_TYPE.DTKPI:
        return Constants.CONSTANT_WORKFLOW.DIRECTIONAL_TARGET;
      case Constants.KPI_TYPE.SKPI:
        return Constants.CONSTANT_WORKFLOW.SKPI;
      case Constants.KPI_TYPE.EKPI:
        return Constants.CONSTANT_WORKFLOW.EKPI;
      case Constants.KPI_TYPE.MOKPI:
        return Constants.CONSTANT_WORKFLOW.MOKPI;
      case Constants.KPI_TYPE.MTKPI:
        return Constants.CONSTANT_WORKFLOW.MTKPI;
      case Constants.KPI_TYPE.OPM:
        return Constants.CONSTANT_WORKFLOW.OKPI;
      default: {
        throw new Error("default case");
      }
    }
  }

  public getValidationKey(kpiType: number) {
    switch (kpiType) {
      case Constants.KPI_TYPE.UAE:
        return Constants.VALIDATORS_CONF_KEY.VALIDATION_UAEKPI;
      case Constants.KPI_TYPE.NKPI:
        return Constants.VALIDATORS_CONF_KEY.VALIDATION_NKPI;
      case Constants.KPI_TYPE.NTKPI:
        return Constants.VALIDATORS_CONF_KEY.VALIDATION_NTKPI;
      case Constants.KPI_TYPE.DTKPI:
        return Constants.VALIDATORS_CONF_KEY.VALIDATION_DTKPI;
      case Constants.KPI_TYPE.SKPI:
        return Constants.VALIDATORS_CONF_KEY.VALIDATION_SKPI;
      case Constants.KPI_TYPE.SRVKPI:
        return Constants.VALIDATORS_CONF_KEY.VALIDATION_SRVKPI;
      case Constants.KPI_TYPE.EKPI:
        return Constants.VALIDATORS_CONF_KEY.VALIDATION_EKPI;
      case Constants.KPI_TYPE.MOKPI:
        return Constants.VALIDATORS_CONF_KEY.VALIDATION_MOKPI;
      case Constants.KPI_TYPE.MTKPI:
        return Constants.VALIDATORS_CONF_KEY.VALIDATION_MTKPI;
      case Constants.KPI_TYPE.OPM:
        return Constants.VALIDATORS_CONF_KEY.VALIDATION_OPMKPI;
      default: {
        throw new Error("Kpi type has no validation key");
      }
    }
  }

  public getKpiPermission(kpiType: number) {
    switch (kpiType) {
      case Constants.KPI_TYPE.UAE:
        return Constants.CONSTANT_PERMISSIONS.DKPI;
      case Constants.KPI_TYPE.NKPI:
        return Constants.CONSTANT_PERMISSIONS.NKPI;
      case Constants.KPI_TYPE.NTKPI:
        return Constants.CONSTANT_PERMISSIONS.NTKPI;
      case Constants.KPI_TYPE.DTKPI:
        return Constants.CONSTANT_PERMISSIONS.DTKPI;
      case Constants.KPI_TYPE.SKPI:
        return Constants.CONSTANT_PERMISSIONS.SKPI;
      case Constants.KPI_TYPE.SRVKPI:
        return Constants.CONSTANT_PERMISSIONS.SRVKPI;
      case Constants.KPI_TYPE.EKPI:
        return Constants.CONSTANT_PERMISSIONS.EKPI;
      case Constants.KPI_TYPE.MOKPI:
        return Constants.CONSTANT_PERMISSIONS.MOKPI;
      case Constants.KPI_TYPE.MTKPI:
        return Constants.CONSTANT_PERMISSIONS.MTKPI;
      case Constants.KPI_TYPE.OPM:
        return Constants.CONSTANT_PERMISSIONS.OPM;
      case Constants.KPI_TYPE.GSKPI:
        return Constants.KPI_TYPE.GSKPI;
      default: {
        throw new Error("Kpi type has no validation key");
      }
    }
  }

  public getKpiActionButtons(): CustomButton[] {
    return [
      {
        onlyIf: (data) => {
          return (
            data.type === Constants.KPI_TYPE.EKPI &&
            (AdaaHelper.isPMOEntity() ? true : AdaaHelper.isDefined(data.parentKpiId))
          );
        },
        eventName: "entityComparison",
        iconName: "fa fa-chart-simple",
        iconTitle: this._translateService.instant("common.form.label.entity_comparison"),
      },
      {
        // Ntkpi Card
        onlyIf: (data) => {
          return data.type === Constants.KPI_TYPE.NTKPI && data.status !== Constants.OBJECT_STATUS.IN_REVIEW;
        },
        eventName: "successStory",
        iconName: "fa fa-link",
        iconTitle: this._translateService.instant("common.form.button.view_related_objects"),
      },
    ];
  }

  public showDeleteAction(kpiType: number) {
    if (
      kpiType === Constants.KPI_TYPE.NTKPI &&
      this._userService.currentUser()?.entityId !== Constants.CONSTANT_PMO_ID
    ) {
      return false;
    }

    if ((isNtkpi(kpiType) || isDtkpi(kpiType)) && !AdaaHelper.isPMOEntity()) return false;
    return !(kpiType === Constants.KPI_TYPE.EKPI && !AdaaHelper.isPMOEntity());
  }

  public getListPageRoute(kpiType: number) {
    type KpiTypeConstantType = keyof typeof Constants.KPI_TYPE;

    const kpiTypes = Object.keys(Constants.KPI_TYPE);
    const kpiTypeId = kpiTypes.find((k) => Constants.KPI_TYPE[k as KpiTypeConstantType] === kpiType);
    if (!kpiTypeId) return ["/console", "kpi"];

    return ["/console", "kpi", kpiTypeId.toLowerCase()];
  }

  public getEditPageRoute(kpiType: number, id: number) {
    type KpiTypeConstantType = keyof typeof Constants.KPI_TYPE;

    const kpiTypes = Object.keys(Constants.KPI_TYPE);
    const kpiTypeId = kpiTypes.find((k) => Constants.KPI_TYPE[k as KpiTypeConstantType] === kpiType);
    if (!kpiTypeId) return "/console/kpi";

    return "/console/kpi/" + kpiTypeId.toLowerCase() + "/edit/" + id;
  }

  public getKpiTypeId(type: string) {
    const kpiTypes = Object.keys(Constants.KPI_TYPE) as KpiTypeConstantType[];
    const kpiTypeKey = kpiTypes.find((k) => k.toLowerCase() === type);

    if (!kpiTypeKey) {
      throw new Error("KPI type not found");
    }

    return Constants.KPI_TYPE[kpiTypeKey];
  }

  public checkIfItemKpi(itemType: number) {
    switch (itemType) {
      case Constants.KPI_TYPE.NKPI:
      case Constants.KPI_TYPE.SKPI:
      case Constants.KPI_TYPE.MOKPI:
      case Constants.KPI_TYPE.MTKPI:
      case Constants.KPI_TYPE.NTKPI:
      case Constants.KPI_TYPE.DTKPI:
      case Constants.KPI_TYPE.OPM:
      case Constants.KPI_TYPE.UAE:
      case Constants.KPI_TYPE.EKPI:
      case Constants.KPI_TYPE.SRVKPI:
      case Constants.KPI_TYPE.GSKPI:
        return true;
      default:
        return false;
    }
  }
}
