import { Component, EventEmitter, inject, input, OnInit, Output, signal } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { AdaaBoolean, PageMode } from "../../../constants/enums";
import { ScopeMainKpi, TableActionEvent, ValueText } from "../../../models";
import { LanguageService, PropertiesService } from "../../../services";
import { ConfirmationModalComponent } from "../../general";
import { DataTableComponent } from "../../table/data-table/data-table.component";
import { ScopeKpiDeleteModalComponent } from "../scope-kpi-delete-modal/scope-kpi-delete-modal.component";
import { ScopeKpiModalComponent } from "../scope-kpi-modal/scope-kpi-modal.component";

@Component({
  selector: "adaa-scope-kpi-list",
  standalone: true,
  imports: [DataTableComponent],
  templateUrl: "./scope-kpi-list.component.html",
  styleUrl: "./scope-kpi-list.component.scss",
})
export class ScopeKpiListComponent implements OnInit {
  private _modalService = inject(NgbModal);
  private _languageService = inject(LanguageService);
  private _propertiesService = inject(PropertiesService);

  scopeId = input.required<number | undefined>();
  kpis = input.required<ScopeMainKpi[]>();
  entityId = input.required<number>();
  enableEdit = input.required<boolean>();

  @Output() kpisChanged = new EventEmitter<ScopeMainKpi[]>();

  readonly CONSTANTS_SUM = 28;
  private readonly _ytpOptions = signal<ValueText[]>([]);
  constants = Constants;

  public get kpisData(): ScopeMainKpi[] {
    return this.kpis()
      .filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE)
      .map((e, index) => ({
        number: `#${index + 1}`,
        ...e,
      }));
  }

  public ngOnInit(): void {
    this._getYTP();
  }

  public addNewClicked(): void {
    const kpi: ScopeMainKpi = {
      id: undefined,
      status: Constants.OBJECT_STATUS.ACTIVE,
      govScoresScopeSubKpiList: [],
      govScoreScopeMetricsList: [],
      govScoresScopeDimensionList: [],
      govMainKpiProjectsList: [],
      metrics: [],
      metricsCopy: [],
      fakeId: Math.floor(Math.random() * 217 * 1000),
      hasChanged: AdaaBoolean.N,
    };

    this._openScopeKpiModal(kpi, PageMode.create);
  }

  public actionClicked(action: TableActionEvent): void {
    switch (action.event) {
      case "delete":
        AdaaHelper.isDefined(action.data.fakeId)
          ? this._deleteScopeKpi(action.data)
          : this._softDeleteScopeKpi(action.data.id);
        break;
      case "edit":
        this._openScopeKpiModal(action.data, PageMode.edit);
        break;
      case "view":
        this._openScopeKpiModal(action.data, PageMode.view);
        break;
      default:
        break;
    }
  }

  private _openScopeKpiModal(kpi: ScopeMainKpi, pageMode: PageMode): void {
    const modal = this._modalService.open(ScopeKpiModalComponent, {
      centered: true,
      backdrop: "static",
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.kpi = kpi;
    modal.componentInstance.entityId = this.entityId();
    modal.componentInstance.pageMode = pageMode;
    modal.componentInstance.ytpOptions = this._ytpOptions();

    modal.result.then((e) => {
      if (!e) return;
      if (pageMode === PageMode.create) this.kpis().push(e);
      else {
        const kpiIndex = this.kpis().findIndex((kpi) => (e.fakeId ? e.fakeId === kpi.fakeId : e.id === kpi.id));
        if (kpiIndex > -1) this.kpis().splice(kpiIndex, 1, e);
      }

      this.kpisChanged.emit(this.kpis());
    });
  }

  private _deleteScopeKpi(kpi: ScopeMainKpi): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = "government_scores.scopes.main_kpi";
    modal.componentInstance.title = "government_scores.scopes.main_kpi_remove";

    modal.result.then((e) => {
      if (e) this._deleteScopeKpiConfirmed(kpi);
    });
  }

  private _deleteScopeKpiConfirmed(kpi: ScopeMainKpi): void {
    const index = this.kpis().findIndex((e) => e.fakeId === kpi.fakeId);
    if (index > -1) this.kpis().splice(index, 1);
    this.kpisChanged.emit(this.kpis());
  }

  private _softDeleteScopeKpi(kpiId: number): void {
    const modal = this._modalService.open(ScopeKpiDeleteModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.entityId = this.entityId();
    modal.componentInstance.scopeId = this.scopeId();

    modal.result.then((e: { setToDraft: boolean; scope: number | undefined }) => {
      if (!AdaaHelper.isDefined(e)) return;

      const kpi = this.kpis().find((e) => e.id === kpiId);
      if (!kpi) return;

      if (e.setToDraft) {
        kpi.status = Constants.OBJECT_STATUS.REMOVE;
        kpi.newScopeId = undefined;
        kpi.setToDraft = true;
      } else {
        kpi.status = Constants.OBJECT_STATUS.REMOVE;
        kpi.newScopeId = e.scope;
        kpi.setToDraft = false;
      }

      this.kpisChanged.emit(this.kpis());
    });
  }

  private _getYTP(): void {
    this._propertiesService.getPropById(Constants.CONSTANT_YTP).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._ytpOptions.set(
          AdaaHelper.setDropdownArray(
            response.responseData.filter((e) => e.enabled !== AdaaBoolean.N),
            "id",
            AdaaHelper.getFieldLanguage("name")
          )
        );
      },
    });
  }
}
