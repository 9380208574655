<adaa-item-slider class="my-3" [showPerformance]="true">
    <h5 title class="py-2">
        {{ "national_agenda.title" | translate }}
    </h5>

    @if (visionDirections() && visionDirections().length) {
        <adaa-doughnut
            performance
            [size]="'md'"
            [amount]="visionDirections()[0].visionAchievement"
            [label]="'landingPage.entity_page.national_target_performance' | translate"
            [colorId]="overallPerfColor"
            (onclick)="router.navigateByUrl('/console/kpi/ntkpi')"
        />
    }

    @for (item of subGovVisionDirections(); track item.id) {
        <adaa-uae-vision-perf-card [data]="item" (cardClicked)="router.navigateByUrl('/console/kpi/ntkpi?sub_gov_direction_id=' + $event.id)" />
    }
</adaa-item-slider>
