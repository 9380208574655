import { Component, computed, inject, input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faAngleDown, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { PercentageValuePipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../constants/constants";
import { AdaaBoolean, Language } from "../../../../constants/enums";
import { ItemModel, StructureViewColumnConfig } from "../../../../models";
import {
  GovScoresPerfrormancesApiService,
  ItemApiService,
  KpisService,
  StructureViewService,
} from "../../../../services";

@Component({
  selector: "adaa-nested-table-row",
  standalone: true,
  imports: [TranslateModule, PercentageValuePipe, FontAwesomeModule],
  templateUrl: "./nested-table-row.component.html",
  styleUrl: "./nested-table-row.component.scss",
})
export class NestedTableRowComponent implements OnInit, OnChanges {
  private _iconLibrary = inject(FaIconLibrary);
  private _itemApiService = inject(ItemApiService);
  private _govScoresPerfrormancesApiService = inject(GovScoresPerfrormancesApiService);
  private _kpiService = inject(KpisService);
  structureViewService = inject(StructureViewService);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data = input.required<any>();
  columns = input.required<StructureViewColumnConfig[]>();
  isEven = input.required<boolean>();
  level = input.required<number>();
  periodId = input.required<number>();
  useGovScoresConfig = input<boolean>(false);

  hasChildKey = computed(() => (this.useGovScoresConfig() ? "hasChildren" : "hasChilds"));

  AdaaHelper = AdaaHelper;
  AdaaBoolean = AdaaBoolean;
  Constants = Constants;
  Language = Language;

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faAngleLeft, faAngleRight, faAngleDown);
  }

  public ngOnChanges({ data }: SimpleChanges): void {
    if (JSON.stringify(data?.previousValue ?? []) !== JSON.stringify(data?.currentValue ?? [])) {
      const index = this.structureViewService.openedNodes.findIndex((e) => e === this.data().id);
      if (index > -1) {
        this.data()["expanded"] = true;
        this.useGovScoresConfig() ? this._getGovScoresChilds() : this._getChilds();
      }
    }
  }

  public expandCollapseChild(isexpanded: boolean) {
    this.data()["expanded"] = isexpanded;
    if (isexpanded) {
      this.structureViewService.openedNodes.push(this.data().id);
      this.useGovScoresConfig() ? this._getGovScoresChilds() : this._getChilds();
    } else {
      this.data()["childs"] = [];
      const index = this.structureViewService.openedNodes.findIndex((e) => e === this.data().id);
      if (index > -1) this.structureViewService.openedNodes.slice(index, 1);
    }
  }

  private _getChilds() {
    this._itemApiService
      .getAllChildItems<ItemModel>({
        itemId: this.data().id,
        currentType: this.data().type,
        periodId: this.periodId(),
        useV2: true,
      })
      .subscribe({
        next: (response) => {
          if (response.inError) return;
          this.data()["childs"] = response.responseData;
        },
      });
  }

  private _getGovScoresChilds() {
    const itemTypeId = this.data().itemTypeId;

    if (itemTypeId === Constants.GOV_SCORES_SECTOR) this._getGovScores(Constants.GOV_SCORES_ENTITY);
    else if (itemTypeId === Constants.GOV_SCORES_ENTITY) this._getGovScores(Constants.GOV_SCORES_PILLAR);
    else if (itemTypeId === Constants.GOV_SCORES_PILLAR) this._getGovScores(Constants.GOV_SCORES_SCOPE);
    else if (itemTypeId === Constants.GOV_SCORES_SCOPE) this._getKpis();
    else if (this._kpiService.checkIfItemKpi(this.data().itemTypeId)) this._getKpiChilds();
  }

  private _getGovScores(itemType: number) {
    this._govScoresPerfrormancesApiService
      .getScoresByPeriodAndItemType(this.periodId(), itemType, this.data().id)
      .subscribe({
        next: (response) => {
          if (response.inError) return;

          this.data()["childs"] = response.responseData;
        },
      });
  }

  private _getKpis() {
    this._govScoresPerfrormancesApiService.getKpisByPeriodAndScopeId(this.periodId(), this.data().id).subscribe({
      next: (response) => {
        if (response.inError) return;

        this.data()["childs"] = response.responseData;
      },
    });
  }

  private _getKpiChilds() {
    this._govScoresPerfrormancesApiService.getKpiChildren(this.data().id).subscribe({
      next: (response) => {
        if (response.inError) return;

        this.data()["childs"] = response.responseData;
      },
    });
  }
}
