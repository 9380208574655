import { Component, computed, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { PercentageValuePipe } from "../../../../core/pipes";
import { Constants } from "../../../constants/constants";

@Component({
  selector: "adaa-progress-indicator",
  standalone: true,
  templateUrl: "./progress-indicator.component.html",
  styleUrl: "./progress-indicator.component.scss",
  imports: [PercentageValuePipe, TranslateModule],
})
export class ProgressIndicatorComponent {
  value = input<number | undefined>();
  color = input<number | undefined>();
  label = input<string | undefined>();
  height = input<number>(30);
  fontSize = input<string>("0.75rem");
  showValue = input<boolean>(true);
  background = input<string>("rgba(51, 51, 51, 0.35)");

  colorString = computed(() => Constants.COLOR_CODES[this.color() ?? Constants.CONSTANT_COLORS.EVALUATION_GRAY]);
  width = computed(() => {
    if (!this.value()) return 0;
    return this.value();
  });
}
