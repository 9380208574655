import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { GovDirection } from "../../../models";

@Component({
  selector: "adaa-directions-card-grid",
  standalone: true,
  imports: [TranslateModule, TranslateTokenPipe],
  templateUrl: "./directions-card-grid.component.html",
  styleUrl: "./directions-card-grid.component.scss",
})
export class DirectionsCardGridComponent {
  governmentDirectionsTitle = input.required<string>();
  governmentSubDirectionsTitle = input.required<string>();
  govDirectionsList = input.required<GovDirection[]>();
  mainSubGovDirection = input<number | undefined>(undefined);
}
