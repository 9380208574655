<div class="table-responsive">
    <table class="table">
        <thead>
            <th [style.width]="'2%'"></th>
            @for (col of columns(); track col) {
                <th [style.width]="col.width" [ngClass]="col.alignment ? 'align-' + col.alignment : 'align-C'">{{ col.title | translate }}</th>
            }
        </thead>
        <tbody></tbody>
    </table>
</div>

<div class="row print-fh">
    <div class="col-12">
        @for (row of data(); track $index; let i = $index) {
            <adaa-nested-table-row
                [data]="row"
                [columns]="columns()"
                [isEven]="i % 2 === 0"
                [level]="0"
                [periodId]="periodId()"
                [useGovScoresConfig]="useGovScoresConfig()"
            />
        }
    </div>
</div>
