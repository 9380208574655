import { inject, Injectable } from "@angular/core";

import { Constants } from "../../shared/constants/constants";
import { Language } from "../../shared/constants/enums";
import { PeriodModelType } from "../../shared/models";
import { LanguageService } from "../../shared/services";

@Injectable({
  providedIn: "root",
})
export class PeriodLabel {
  private readonly _languageService = inject(LanguageService);

  readonly getQuarterlyLabel = (quarter: number) => {
    if (quarter === 1) return this._languageService.translate("targets.periods.quarters.1");
    if (quarter === 2) return this._languageService.translate("targets.periods.quarters.2");
    if (quarter === 3) return this._languageService.translate("targets.periods.quarters.3");
    return this._languageService.translate("targets.periods.quarters.4");
  };

  readonly getMonthlyLabel = (epoch: number) => {
    const dt = new Intl.DateTimeFormat(this._languageService.current() === Language.Arabic ? "ar-AR" : "en-GB", {
      month: "long",
      localeMatcher: "best fit",
      timeZone: Constants.uaeTimezoneName,
    });

    return dt.format(epoch);
  };

  readonly getLabel = ({ item, frequency }: { item: PeriodModelType; frequency: number }) => {
    const annualBasedLabel =
      frequency === Constants.FREQUENCY_ANNUAL ||
      frequency === Constants.FREQUENCY_EVERY_TWO_YEARS ||
      frequency === Constants.FREQUENCY_EVERY_THREE_YEARS ||
      frequency === Constants.FREQUENCY_EVERY_FOUR_YEARS ||
      frequency === Constants.FREQUENCY_EVERY_FIVE_YEARS;
    if (annualBasedLabel) return `${item.year}`;

    if (frequency === Constants.FREQUENCY_SEMIANNUAL && this._languageService.current() === Language.Arabic) {
      const label = this._languageService.translate(`targets.periods.semestral.${item.semester}`);
      return `${label} ${item.year}`;
    }

    if (frequency === Constants.FREQUENCY_SEMIANNUAL && this._languageService.current() === Language.English) {
      const label = this._languageService.translate("targets.semiannual");
      return `${label} ${item.semester} ${item.year}`;
    }

    if (frequency === Constants.FREQUENCY_QUARTERLY) {
      return `${item.year} ${this.getQuarterlyLabel(item.quarter)}`;
    }

    return this.getMonthlyLabel(item.date) + " " + item.year;
  };
}
