import { inject, Injectable } from "@angular/core";

import { MainResponseModel, SubGovDirection } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class SubGovDirectionApiService {
  private _mainService = inject(MainService);

  public getAll() {
    const url = "subgovdirection/getall";

    return this._mainService.sendRequest<MainResponseModel<SubGovDirection[]>>({
      method: "GET",
      url: url,
    });
  }

  public getAllbyGovDirection(govDirectionId: number) {
    const url = `subgovdirection/getallbygovdirection/${govDirectionId}`;

    return this._mainService.sendRequest<MainResponseModel<SubGovDirection[]>>({
      method: "GET",
      url: url,
    });
  }

  public getAllSubsbyGovDirection(govDirectionId: number) {
    const url = `subgovdirection/getallsubsbygovdirection/${govDirectionId}`;

    return this._mainService.sendRequest<MainResponseModel<SubGovDirection[]>>({
      method: "GET",
      url: url,
    });
  }
}
