import { inject, Injectable } from "@angular/core";

import { MainResponseModel, PropTypeModelType } from "../models";
import { GovPillarEntity, GovScoresPillar, GovSector, GovSectorEntity } from "../models/gov-scores.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class GovScoresApiService {
  private _mainService = inject(MainService);

  public getAvailableSectorEntities() {
    const url = `govScoresSector/availableEntities`;

    return this._mainService.sendRequest<MainResponseModel<GovSectorEntity[]>>({
      method: "GET",
      url,
    });
  }

  public getAvailablePillarSectorEntities() {
    const url = `govScoresPillars/getAvailableEntities`;

    return this._mainService.sendRequest<MainResponseModel<GovSectorEntity[]>>({
      method: "GET",
      url,
    });
  }

  public getAvailablePillarTypes() {
    const url = `govScoresPillars/getAvailableTypes`;

    return this._mainService.sendRequest<MainResponseModel<PropTypeModelType[]>>({
      method: "GET",
      url,
    });
  }

  public getGovScoresSector(id: number) {
    const url = `govScoresSector/getById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<GovSector>>({
      method: "GET",
      url,
    });
  }

  public createGovScoresSector(data: GovSector) {
    const url = `govScoresSector/create?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public createGovScoresPillars(data: Partial<GovScoresPillar>[]) {
    const url = `govScoresPillars/create`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public updateGovScoresPillars(data: Partial<GovScoresPillar>[]) {
    const url = `govScoresPillars/update`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public updateGovScoresSector(data: GovSector) {
    const url = `govScoresSector/update?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public deleteGovScoresSector(id: number) {
    const url = `govScoresSector/deleteById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "DELETE",
      url,
    });
  }

  public deletePillarByEntityId(id: number) {
    const url = `govScoresPillars/deleteByEntityId/${id}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "DELETE",
      url,
    });
  }

  public updateSectorsOrders(data: { id: number; displayOrder: number }[]) {
    const url = `govScoresSector/sortSectors?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<GovSector>>({
      method: "POST",
      url,
      data,
    });
  }

  public getGovScoresPillars() {
    const url = `govScoresPillars/getAllPillarsByEntity?limit=10&offset=0`;

    return this._mainService.sendRequest<MainResponseModel<GovPillarEntity[]>>({
      method: "POST",
      url,
      data: {},
    });
  }

  public getGovScoresPillarsByEntity(id: number) {
    const url = `govScoresPillars/getByEntityId/${id}`;

    return this._mainService.sendRequest<MainResponseModel<GovScoresPillar[]>>({
      method: "GET",
      url,
    });
  }

  public getPillar(id: number) {
    const url = `govScoresPillars/getById/${id}`;

    return this._mainService.sendRequest<MainResponseModel<GovScoresPillar[]>>({
      method: "GET",
      url,
    });
  }
}
