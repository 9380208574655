import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  inject,
  input,
  Output,
  Renderer2,
  viewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import Handsontable from "handsontable";
import { CellChange } from "handsontable/common";
import Core from "handsontable/core";
import { ColumnSettings, GridSettings } from "handsontable/settings";

import { environment } from "../../../../core/environments/environment";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { AdaaBoolean, Language } from "../../../constants/enums";
import { ColumnsIndices, DataEntry, DataEntryDetails, DataEntryOptionClicked } from "../../../models";
import { DataentryService } from "../../../services";
import { TableLegendDataEntryComponent } from "../table-legend-data-entry/table-legend-data-entry.component";

@Component({
  selector: "adaa-formula-kpi",
  standalone: true,
  imports: [TableLegendDataEntryComponent],
  templateUrl: "./formula-kpi.component.html",
  styleUrl: "./formula-kpi.component.scss",
})
export class FormulaKpiComponent implements AfterViewInit {
  private _translateService = inject(TranslateService);
  private _dataEntryService = inject(DataentryService);
  private _elementRef = inject(ElementRef);
  private _renderer = inject(Renderer2);

  dataEntryDetails = input.required<DataEntryDetails>();
  dataEntrySave = input.required<DataEntryDetails>();

  @Output() tableOptionsClicked = new EventEmitter<DataEntryOptionClicked>();
  @Output() valueChanged = new EventEmitter<DataEntryDetails>();

  hotTableContainer = viewChild.required<ElementRef>("hotTableContainer");

  private _columnsIndices: ColumnsIndices = {
    optionsColumns: [],
    targetsColumns: [],
    actualColumns: [],
    auditAnnualActualColumns: [],
    calcColumns: [],
  };
  hotInstance: Handsontable;

  metricsLength = computed<number>(() =>
    this.dataEntryDetails().metricDetails ? this.dataEntryDetails().metricDetails!.length : 0
  );
  hasTarget = computed<boolean>(() => this.dataEntryDetails().hasTarget === AdaaBoolean.Y);
  isBounded = computed<boolean>(() => this.dataEntryDetails().trend === Constants.TREND.BOUNDED);
  isNTKPI = computed<boolean>(() => this.dataEntryDetails().kpiType === Constants.KPI_TYPE.NTKPI);
  hasAuditIssue = computed<boolean>(() =>
    this.dataEntryDetails().auditEntries ? this.dataEntryDetails().auditEntries!.some((e) => e.hasAuditIssue) : false
  );
  dataEntriesGroupedByPeriod = computed<Map<number, DataEntry[]>>(() =>
    this.dataEntryDetails().dataEntries
      ? AdaaHelper.groupBy(this.dataEntryDetails().dataEntries!, (e: { periodId: number }) => e.periodId)
      : new Map<number, DataEntry[]>([[0, []]])
  );

  constructor() {
    this._renderer.listen(this._elementRef.nativeElement, "click", (event) => this._handleOnCellMouseDown(event));
  }

  public ngAfterViewInit(): void {
    const container = this.hotTableContainer().nativeElement;
    const options = this._getOptions();
    this.hotInstance = new Handsontable(container, options);
  }

  public legendClicked(event: { checkbox: "options" | "targets" | "calculation"; checked: boolean }): void {
    if (!this.hotInstance || this.hotInstance.isDestroyed) return;

    switch (event.checkbox) {
      case "options":
        event.checked
          ? this.hotInstance
              .getPlugin("hiddenColumns")
              .showColumns(this._columnsIndices.optionsColumns.map((e) => e.index))
          : this.hotInstance
              .getPlugin("hiddenColumns")
              .hideColumns(this._columnsIndices.optionsColumns.map((e) => e.index));
        this.hotInstance.render();
        break;
      case "targets":
        event.checked
          ? this.hotInstance.getPlugin("hiddenColumns").showColumns(this._columnsIndices.targetsColumns)
          : this.hotInstance.getPlugin("hiddenColumns").hideColumns(this._columnsIndices.targetsColumns);
        this.hotInstance.render();
        break;
      case "calculation":
        event.checked
          ? this.hotInstance
              .getPlugin("hiddenColumns")
              .showColumns(this._columnsIndices.calcColumns.map((e) => e.index))
          : this.hotInstance
              .getPlugin("hiddenColumns")
              .hideColumns(this._columnsIndices.calcColumns.map((e) => e.index));
        this.hotInstance.render();
        break;
    }
  }

  private _getOptions(): GridSettings {
    const currentLang = AdaaHelper.getCurrentLang();
    const { columnWidths, columns } = this._getColumns();

    return {
      data: this._getData(),
      rowHeaders: this._getRowHeaders(),
      rowHeaderWidth: 150,
      nestedHeaders: this._getColHeaders(),
      columns: columns,
      colWidths: columnWidths,
      cells: (row, column, _prop) =>
        this._dataEntryService.setCellColorFormulaKpi(
          row,
          column,
          this.dataEntriesGroupedByPeriod(),
          this.dataEntryDetails().auditEntries,
          this._columnsIndices
        ),
      mergeCells: this.hasAuditIssue()
        ? this._dataEntryService.getMergedCells(
            this.dataEntryDetails(),
            2,
            true,
            this.dataEntryDetails().metricDetails ? this.dataEntryDetails().metricDetails!.length : 0
          )
        : false,
      afterChange: (changes, _source) => this._handleCellValueChanged(changes),
      //Same Conf
      stretchH: "none",
      height: "auto",
      viewportColumnRenderingOffset: 999999,
      viewportRowRenderingOffset: 999999,
      hiddenColumns: true,
      layoutDirection: currentLang === Language.Arabic ? "rtl" : "ltr",
      language: currentLang === Language.Arabic ? "ar-AR" : "en-US",
      licenseKey: environment.handsontable_key,
    };
  }

  private _getData() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let innerData: any[] = [];

    if (!this.dataEntriesGroupedByPeriod()) return data;

    this.dataEntriesGroupedByPeriod().forEach((dataEntry, _key) => {
      innerData = [""];

      this.dataEntryDetails().metricDetails?.forEach((metric) => {
        const entry = dataEntry.find((e) => e.metricNameEN === metric.metricNameEN);
        if (!entry) return;

        //actuals
        if (entry.ignored) innerData.push("N/A");
        else if (this.dataEntryDetails().textmapId) innerData.push("");
        else innerData.push(entry.actual);

        //targets - For detailed targets
        if (this.hasTarget()) {
          if (this.isBounded()) {
            const dataArray = entry.isBaseLineYear
              ? [this._translateService.instant("kpi.baselineYear"), this._translateService.instant("kpi.baselineYear")] //baseline
              : this.dataEntryDetails().textmapId
                ? ["", ""] //textmapid
                : [entry.lowerLimit, entry.highLimit]; //default;

            innerData.push(...dataArray);
          } else {
            const dataArray = entry.isBaseLineYear
              ? [this._translateService.instant("kpi.baselineYear")] //baseline
              : this.dataEntryDetails().textmapId
                ? [""] //textmapid
                : [entry.target]; //default;
            innerData.push(...dataArray);
          }
        }
      });

      //targets - Not detailed targets
      if (!this.hasTarget() && dataEntry[0]) {
        if (this.isBounded()) {
          const dataArray = dataEntry[0].isBaseLineYear
            ? [this._translateService.instant("kpi.baselineYear"), this._translateService.instant("kpi.baselineYear")] //baseline
            : this.dataEntryDetails().textmapId
              ? ["", ""] //textmapid
              : [dataEntry[0].lowerLimit, dataEntry[0].highLimit]; //default;

          innerData.push(...dataArray);
        } else {
          const dataArray = dataEntry[0].isBaseLineYear
            ? [this._translateService.instant("kpi.baselineYear")] //baseline
            : this.dataEntryDetails().textmapId
              ? [""] //textmapid
              : [dataEntry[0].target]; //default

          innerData.push(...dataArray);
        }
      }

      //Calc
      innerData.push(
        this._dataEntryService.calcFormula(
          dataEntry,
          this.dataEntryDetails().metricDetails,
          this.dataEntryDetails().formula
        )
      );

      if (this.hasAuditIssue() && dataEntry[0] && this.dataEntryDetails().auditEntries) {
        const index = this.dataEntryDetails().auditEntries!.findIndex(
          (auditMetric) => auditMetric.year === dataEntry[0].year
        );

        if (index !== -1) {
          //Annual Actual Column
          innerData.push(
            this.dataEntryDetails().auditEntries![index].annualActualIgnored
              ? "N/A"
              : this.dataEntryDetails().auditEntries![index].annualActual
          );

          //Audited Annual Actual Column
          innerData.push(
            this.dataEntryDetails().auditEntries![index].auditedActualIgnored
              ? "N/A"
              : this.dataEntryDetails().auditEntries![index].auditedActual
          );

          //Audited Annual Actual Column
          innerData.push(this.dataEntryDetails().auditEntries![index].comment);
        }
      }

      data.push(innerData);
    });

    return data;
  }

  private _getColHeaders() {
    const firstHeader = [];
    if (this.isBounded() && !this.hasTarget()) {
      firstHeader.push(
        "",
        {
          label: this._translateService.instant("data_entry.actuals"),
          colspan: this.metricsLength(),
        },
        this._translateService.instant("kpi.lower_limit"),
        this._translateService.instant("kpi.upper_limit"),
        this._translateService.instant("data_entry.calc")
      );
    } else if (this.isBounded() && this.hasTarget()) {
      firstHeader.push(
        "",
        {
          label: this._translateService.instant("data_entry.actual_target"),
          colspan: this.metricsLength() * 3,
        },
        this._translateService.instant("data_entry.calc")
      );
    } else if (!this.isBounded() && !this.hasTarget()) {
      firstHeader.push(
        "",
        {
          label: this._translateService.instant("data_entry.actuals"),
          colspan: this.metricsLength(),
        },
        this._translateService.instant("data_entry.target"),
        this._translateService.instant("data_entry.calc")
      );
    } else if (!this.isBounded() && this.hasTarget()) {
      firstHeader.push(
        "",
        {
          label: this._translateService.instant("data_entry.actual_target"),
          colspan: this.metricsLength() * 2,
        },
        this._translateService.instant("data_entry.calc")
      );
    }

    //hasAuditIssue
    if (this.hasAuditIssue()) {
      firstHeader.push(
        this._translateService.instant("data_entry.annual_actual"),
        this._translateService.instant("data_entry.audited_annual_actual"),
        this._translateService.instant("common.form.label.comments")
      );
    }

    const secondHeader = [""];
    this.dataEntryDetails().metricDetails?.forEach((metric) => {
      secondHeader.push(AdaaHelper.getItemValueByToken(metric, "metricName"));

      if (this.hasTarget() && !this.isBounded()) {
        secondHeader.push(this._translateService.instant("data_entry.target"));
      } else if (this.hasTarget() && this.isBounded()) {
        secondHeader.push(
          this._translateService.instant("kpi.lower_limit"),
          this._translateService.instant("kpi.upper_limit")
        );
      }
    });

    if (!this.isBounded() && !this.hasTarget()) secondHeader.push("");
    else if (this.isBounded() && !this.hasTarget()) secondHeader.push("", "");

    secondHeader.push("");

    //hasAuditIssue
    if (this.hasAuditIssue()) {
      secondHeader.push("", "", "");
    }

    return [firstHeader, secondHeader];
  }

  private _getRowHeaders(): string[] {
    const headers: string[] = [];
    this.dataEntriesGroupedByPeriod().forEach((dataEntry, _key) => {
      if (dataEntry[0])
        headers.push(this._dataEntryService.getLabelForPeriodColumn(dataEntry[0], this.dataEntryDetails().frequency!));
    });
    return headers;
  }

  private _getColumns() {
    let colIndex = 0;
    const columnWidths: number[] = [100];
    const columns: Array<ColumnSettings> = [{ readOnly: true, renderer: this._renderOptionsCell }];
    this._columnsIndices.optionsColumns.push({ index: colIndex });

    this.dataEntryDetails().metricDetails?.forEach((metric) => {
      colIndex += 1;

      //actuals
      columnWidths.push(150);
      if (this.dataEntryDetails().textmapId) {
        columns.push({
          type: "dropdown",
          source: [],
        });
      } else {
        columns.push({});
      }

      this._columnsIndices.actualColumns.push({
        index: colIndex,
        metricNameEN: metric.metricNameEN,
        metricType: metric.metricType,
      });

      //targets - For detailed targets
      if (this.hasTarget()) {
        if (this.isBounded()) {
          //lower and upper width
          columnWidths.push(150, 150);

          //lower and upper
          columns.push({ readOnly: true, className: "disabledCell" }, { readOnly: true, className: "disabledCell" });

          colIndex += 2;
          this._columnsIndices.targetsColumns.push(colIndex - 1, colIndex);
        } else {
          //target width
          columnWidths.push(150);

          //target
          columns.push({ readOnly: true, className: "disabledCell" });

          colIndex += 1;
          this._columnsIndices.targetsColumns.push(colIndex);
        }
      }
    });

    //targets - For not detailed targets
    if (!this.hasTarget()) {
      if (this.isBounded()) {
        //lower and upper width
        columnWidths.push(150, 150);

        //lower and upper
        columns.push({ readOnly: true, className: "disabledCell" }, { readOnly: true, className: "disabledCell" });

        colIndex += 2;
        this._columnsIndices.targetsColumns.push(colIndex - 1, colIndex);
      } else {
        //target width
        columnWidths.push(150);

        //target
        columns.push({ readOnly: true, className: "disabledCell" });

        colIndex += 1;
        this._columnsIndices.targetsColumns.push(colIndex);
      }
    }

    //calc
    colIndex += 1;
    columns.push({ readOnly: true, className: "disabledCell" });
    columnWidths.push(150);
    this._columnsIndices.calcColumns.push({ index: colIndex });

    //audit
    if (this.hasAuditIssue()) {
      colIndex += 3;
      columns.push(
        ...[
          { readOnly: true, className: "disabledCell" }, //annual actual
          { readOnly: true, className: "disabledCell" }, //audited annual actual
          { readOnly: true, className: "disabledCell" }, //comment column
        ]
      );
      columnWidths.push(150, 150, 200);
      this._columnsIndices.auditAnnualActualColumns.push({ index: colIndex - 1 });
    }

    return { columnWidths, columns };
  }

  private _renderOptionsCell = (_instance: Core, TD: HTMLTableCellElement, row: number, column: number) => {
    TD.innerHTML = this._dataEntryService.getOptionCell(row, column, this.isNTKPI());
  };

  private _handleCellValueChanged = (changes: CellChange[] | null) => {
    if (!changes || !this.hotInstance || this.hotInstance.isDestroyed) return;

    changes.forEach((change) => {
      let invalidCell = false;
      let [row, col, prev, next] = change;

      row = +row;
      col = +col;

      //If the change was on the calc column skip
      if (this._columnsIndices.calcColumns.find((e) => e.index === col)) return;

      const isNA = typeof next === "string" && next.toUpperCase() === "N/A";

      if (!AdaaHelper.isDefinedAndNotEmpty(prev)) prev = undefined;
      if (!AdaaHelper.isDefinedAndNotEmpty(next)) next = undefined;

      if (isNA) next = next.toUpperCase();

      //Avoid saving changes on empty cells
      if (!AdaaHelper.isDefined(prev) && !AdaaHelper.isDefined(next)) return;

      //Avoid saving if metric does not exists
      if (!AdaaHelper.isDefined(this.dataEntryDetails()?.dataEntries![row])) return;

      if (isNA || !next) {
        this.hotInstance.removeCellMeta(row, col, "valid");
        invalidCell = false;
      } else if (isNaN(next)) {
        this.hotInstance.setCellMeta(row, col, "valid", false);
        invalidCell = true;
      } else {
        this.hotInstance.removeCellMeta(row, col, "valid");
        invalidCell = false;
      }

      const metric = this._columnsIndices.actualColumns.find((e) => e.index === col);
      if (metric) {
        const entry = Array.from(this.dataEntriesGroupedByPeriod().values())[row].find(
          (e) => e.metricNameEN === metric.metricNameEN
        );
        if (entry) {
          const tableEntry = this.dataEntryDetails().dataEntries?.find((e) => e.id === entry.id);
          if (tableEntry) {
            tableEntry.invalid = invalidCell;
            tableEntry.ignored = isNA;
            tableEntry.actual = isNA ? undefined : +next;
            this._updateDataEntrySave(tableEntry);
          }
        }
      }

      const value = this._dataEntryService.calcFormula(
        Array.from(this.dataEntriesGroupedByPeriod().values())[row],
        this.dataEntryDetails().metricDetails,
        this.dataEntryDetails().formula
      );
      this.hotInstance.setDataAtCell(row, this._columnsIndices.calcColumns[0].index, value);
    });
    this.hotInstance.render();
  };

  private _handleOnCellMouseDown = (event: { target: Element }) => {
    const action = (<Element>event.target).attributes.getNamedItem("data-type");
    if (action?.value) {
      const row = (<Element>event.target).attributes.getNamedItem("data-row");
      if (!row) return;

      const rowIndex =
        +row.value * (this.dataEntryDetails().metricDetails ? this.dataEntryDetails().metricDetails!.length : 1);

      this.tableOptionsClicked.emit({
        action: action.value,
        periodId: this.dataEntryDetails().dataEntries![rowIndex].periodId!,
        dateLabel: this._dataEntryService.getLabelForPeriodColumn(
          this.dataEntryDetails().dataEntries![rowIndex],
          this.dataEntryDetails().frequency!
        ),
      });
    }
  };

  private _updateDataEntrySave(tableEntry: DataEntry) {
    const entry = this.dataEntrySave().dataEntries?.find((e) => e.id === tableEntry.id);
    if (entry) {
      entry.ignored = tableEntry.ignored;
      entry.actual = tableEntry.actual;
      entry.invalid = tableEntry.invalid;
    } else {
      this.dataEntrySave().dataEntries?.push({
        id: tableEntry.id,
        actual: tableEntry.actual,
        ignored: tableEntry.ignored,
        periodId: tableEntry.periodId,
        year: tableEntry.year,
        allowEdit: tableEntry.allowEdit,
        invalid: tableEntry.invalid,
      });
    }

    this.valueChanged.emit(this.dataEntrySave());
  }
}
