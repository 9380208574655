import { Component, inject, Input, signal } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { ValueText } from "../../../models";
import { DraggableSortListComponent } from "../../draggable-sort-list/draggable-sort-list.component";

@Component({
  selector: "adaa-items-order-modal",
  standalone: true,
  imports: [DraggableSortListComponent, TranslateModule],
  templateUrl: "./items-order-modal.component.html",
  styleUrl: "./items-order-modal.component.scss",
})
export class ItemsOrderModalComponent {
  modal = inject(NgbActiveModal);

  @Input() data: ValueText[] = [];
  @Input() inputLabel: string = "";

  values = signal<number[]>([]);

  public save(): void {
    this.modal.close(this.values());
  }
}
