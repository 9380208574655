/* eslint-disable @typescript-eslint/no-explicit-any */
import { NgClass } from "@angular/common";
import { Component, inject, input, OnChanges, signal, SimpleChanges } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { ItemModel, NodeTreeConfig, ObjectsTreeConfig, StructureViewColumnConfig } from "../../../models";
import { ItemApiService, StructureViewService } from "../../../services";
import { ObjectsTreeRowComponent } from "./objects-tree-row/objects-tree-row.component";

@Component({
  selector: "adaa-objects-tree",
  standalone: true,
  imports: [ObjectsTreeRowComponent, NgClass, TranslateModule],
  templateUrl: "./objects-tree.component.html",
  styleUrl: "./objects-tree.component.scss",
})
export class ObjectsTreeComponent implements OnChanges {
  private _itemApiService = inject(ItemApiService);
  structureViewService = inject(StructureViewService);

  data = input.required<any[]>();
  columns = input.required<StructureViewColumnConfig[]>();
  periodId = input.required<number>();

  tree = signal<ObjectsTreeConfig[]>([]);

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["data"] &&
      JSON.stringify(changes["data"].previousValue) != JSON.stringify(changes["data"].currentValue)
    ) {
      const tree: ObjectsTreeConfig[] = [];
      const objectsNode: NodeTreeConfig = {
        type: Constants.CONSTANT_OBJECTIVETYPE,
        data: this.data(),
      };
      tree.push({ level: 0, nodes: [objectsNode] });

      this.tree.set(tree);
      this._openTree();
    }
  }

  public selectRow(data: any, level: number): void {
    const nodeIndex = this.structureViewService.openedTreeNodes.findIndex((e) => e.level === level);
    if (nodeIndex > -1) {
      const node = this.structureViewService.openedTreeNodes[nodeIndex];

      this.structureViewService.openedTreeNodes.splice(nodeIndex, 1);
      this._removeChilds(level);

      if (node.id !== data.id) {
        this.structureViewService.openedTreeNodes.push({ id: data.id, level: level });
        this._getChilds(data, level);
      }
    } else {
      this.structureViewService.openedTreeNodes.push({ id: data.id, level: level });
      this._getChilds(data, level);
    }
  }

  private _getChilds(data: any, level: number): void {
    if (!data.hasChilds) return;

    this._itemApiService
      .getAllChildItems<ItemModel>({
        itemId: data.id,
        currentType: data.type,
        periodId: this.periodId(),
        useV2: true,
      })
      .subscribe({
        next: (response) => {
          if (response.inError) return;

          this._formatChilds(response.responseData, level);
        },
      });
  }

  private _formatChilds(data: ItemModel[], level: number): void {
    const tree = AdaaHelper.clone(this.tree()) as ObjectsTreeConfig[];

    const dataMap = AdaaHelper.groupBy(data, (e: { type: any }) => e.type);
    const dataArray: NodeTreeConfig[] = [];

    dataMap.forEach((e, key) => {
      dataArray.push({ type: key, data: e });
    });

    tree.push({ level: level + 1, nodes: dataArray });

    this.tree.set(tree);

    data.forEach((d) => {
      const node = this.structureViewService.openedTreeNodes.find((e) => e.id === d.id && e.level === level + 1);
      if (node) this._getChilds(d, level + 1);
    });
  }

  private _removeChilds(level: number) {
    let tree = AdaaHelper.clone(this.tree()) as ObjectsTreeConfig[];
    tree = tree.filter((e) => level >= e.level || e.level === 0);
    this.tree.set(tree);

    this.structureViewService.openedTreeNodes = this.structureViewService.openedTreeNodes.filter(
      (e) => level > e.level
    );
  }

  private _openTree(): void {
    this.structureViewService.openedTreeNodes.forEach((on) => {
      const branch = this.tree().find((b) => b.level === on.level);
      if (!branch) return;

      branch.nodes.forEach((n) => {
        const data = n.data.find((e) => e.id === on.id);
        if (data) this._getChilds(data, on.level);
      });
    });
  }
}
