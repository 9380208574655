import { Component, effect, inject, input, output, signal } from "@angular/core";
import { DomSanitizer, type SafeUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { PercentageValuePipe, TranslateTokenPipe } from "../../../../core/pipes";
import { Constants } from "../../../constants/constants";
import type { NationalAgendaSubGovModelType, VisionSubGovDirection } from "../../../models";
import { FilesApiService } from "../../../services";

export type CommonMeasureType = {
  id?: number;
  logo: number;
  performance?: number;
  performanceColor?: number;
  visionAchievement?: number;
  visionAchievementColor?: number;
  ntKpiCount?: number;
  nameAE?: string;
  nameEN?: string;
};

@Component({
  selector: "adaa-uae-vision-perf-card",
  standalone: true,
  templateUrl: "./uae-vision-perf-card.component.html",
  styleUrl: "./uae-vision-perf-card.component.scss",
  imports: [TranslateTokenPipe, TranslateModule, PercentageValuePipe],
})
export class UaeVisionPerfCardComponent {
  readonly router = inject(Router);
  private readonly _domSanitizer = inject(DomSanitizer);
  private readonly _fileService = inject(FilesApiService);

  data = input.required<VisionSubGovDirection | NationalAgendaSubGovModelType | CommonMeasureType>();
  logoType = input<"svg" | "normal">("normal");
  useIndicator = input<{
    performance: boolean;
    visionAchievement: boolean;
    ntkpiCount: boolean;
  }>({
    performance: true,
    visionAchievement: true,
    ntkpiCount: true,
  });

  cardClicked = output<CommonMeasureType>();

  logo = signal<SafeUrl | string | undefined>(undefined);

  constructor() {
    effect(() => {
      this._downloadFile();
    });
  }

  public getColor(code: number) {
    return Constants.COLOR_CODES[code];
  }

  private _downloadFile() {
    this._fileService.download(this.data().logo).subscribe({
      next: async (blob) => {
        if (this.logoType() === "svg") {
          this._generateDataUrl(blob);
          return;
        }

        const dataUrl = window.URL.createObjectURL(blob);
        this.logo.update(() => dataUrl);
      },
    });
  }

  private _generateDataUrl = (blob: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      const dataUrl = reader.result as string;
      const logo = atob(dataUrl?.split(",")[1]);

      this.logo.update(() => this._constructImageFromSvg(logo));
    };
  };

  private _constructImageFromSvg = (svg: string) => {
    const blob = new Blob([svg], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);

    return this._domSanitizer.bypassSecurityTrustUrl(url);
  };
}
