import { inject, Injectable } from "@angular/core";
import { filter, map } from "rxjs";

import type { MainResponseModel, ObjectiveModelType } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class ObjectivesApiService {
  private _mainService = inject(MainService);

  public getAllEnabler() {
    const url = `objective/getallbyenabler`;

    return this._mainService.sendRequest<MainResponseModel<ObjectiveModelType[]>>({
      method: "GET",
      url,
    });
  }

  public deleteObjective(id: number) {
    const url = `objective/deleteById/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<unknown>>({
        method: "DELETE",
        url: url,
      })
      .pipe(filter((res) => !res.inError));
  }

  public create(data: unknown, isFinish = false) {
    const url = `objective/create?isFinish=${isFinish}`;

    return this._mainService
      .sendRequest<MainResponseModel<unknown>>({
        method: "POST",
        url: url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public update(data: unknown, isFinish = false) {
    const url = `objective/update?isFinish=${isFinish}`;

    return this._mainService
      .sendRequest<MainResponseModel<unknown>>({
        method: "POST",
        url: url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getEnablerCardById(id: number) {
    const url = `objective/getEnablerCardById/${id}`;

    const obs$ = this._mainService.sendRequest<MainResponseModel<ObjectiveModelType>>({
      method: "GET",
      url: url,
    });

    return obs$.pipe(
      filter((res) => !res.inError),
      map((res) => {
        if (typeof res.responseData.keySuccessFactorsAE === "string") {
          try {
            res.responseData.keySuccessFactorsAE = JSON.parse(res.responseData.keySuccessFactorsAE) satisfies {
              nameEN: string;
              nameAE: string;
            }[];
          } catch (_e) {
            res.responseData.keySuccessFactorsAE = [];
          }
        }

        if (typeof res.responseData.keySuccessFactorsEN === "string") {
          try {
            res.responseData.keySuccessFactorsEN = JSON.parse(res.responseData.keySuccessFactorsEN) satisfies {
              nameEN: string;
              nameAE: string;
            }[];
          } catch (_e) {
            res.responseData.keySuccessFactorsEN = [];
          }
        }

        return res;
      })
    );
  }

  public getEnablerById(id: number) {
    const url = `objective/getenablerbyid/${id}`;

    const obs$ = this._mainService.sendRequest<MainResponseModel<ObjectiveModelType>>({
      method: "GET",
      url: url,
    });

    return obs$.pipe(
      filter((res) => !res.inError),
      map((res) => {
        if (typeof res.responseData.keySuccessFactorsAE === "string") {
          try {
            res.responseData.keySuccessFactorsAE = JSON.parse(res.responseData.keySuccessFactorsAE) satisfies {
              nameEN: string;
              nameAE: string;
            }[];
          } catch (_e) {
            res.responseData.keySuccessFactorsAE = [];
          }
        }

        if (typeof res.responseData.keySuccessFactorsEN === "string") {
          try {
            res.responseData.keySuccessFactorsEN = JSON.parse(res.responseData.keySuccessFactorsEN) satisfies {
              nameEN: string;
              nameAE: string;
            }[];
          } catch (_e) {
            res.responseData.keySuccessFactorsEN = [];
          }
        }

        return res;
      })
    );
  }

  public getById(id: number) {
    const url = `objective/getbyid/${id}`;

    return this._mainService
      .sendRequest<MainResponseModel<ObjectiveModelType>>({
        method: "GET",
        url: url,
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => {
          if (typeof res.responseData.keySuccessFactorsAE === "string") {
            try {
              res.responseData.keySuccessFactorsAE = JSON.parse(res.responseData.keySuccessFactorsAE) satisfies {
                nameEN: string;
                nameAE: string;
              }[];
            } catch (_e) {
              /* empty */
            }
          }

          if (typeof res.responseData.keySuccessFactorsEN === "string") {
            try {
              res.responseData.keySuccessFactorsEN = JSON.parse(res.responseData.keySuccessFactorsEN) satisfies {
                nameEN: string;
                nameAE: string;
              }[];
            } catch (_e) {
              /* empty */
            }
          }

          return res;
        })
      );
  }

  public getObjectivesByEntityAndPlanV2({
    entityId,
    planId,
    type,
  }: {
    entityId: number;
    planId: number;
    type?: number;
  }) {
    const url = `objective/getObjectivesByEntityAndPlanV2`;
    const qs = new URLSearchParams({ entityId: `${entityId}`, planId: `${planId}` });

    if (type) qs.append("type", `${type}`);

    return this._mainService.sendRequest<MainResponseModel<ObjectiveModelType[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getObjectivesByEntityAndPlan({ entityId, planId, type }: { entityId: number; planId: number; type?: number }) {
    const url = `objective/getObjectivesByEntityAndPlan`;
    const qs = new URLSearchParams({ entityId: `${entityId}`, planId: `${planId}` });

    if (type) qs.append("type", `${type}`);

    return this._mainService.sendRequest<MainResponseModel<ObjectiveModelType[]>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }

  public getItemObjectivesByEntityAndPlanV2({
    entityId,
    planId,
    periodId,
  }: {
    entityId: number;
    planId: number;
    periodId: number;
  }) {
    const url = `objective/getItemObjectivesByEntityAndPlanV2`;
    const qs = new URLSearchParams({ entityId: `${entityId}`, planId: `${planId}`, periodId: `${periodId}` });

    return this._mainService
      .sendRequest<MainResponseModel<ObjectiveModelType[]>>({
        method: "GET",
        url: `${url}?${qs.toString()}`,
      })
      .pipe(filter((res) => !res.inError));
  }

  public getObjectivesByType(type: number) {
    const url = `objective/getObjectivesByType/${type}`;

    return this._mainService.sendRequest<MainResponseModel<ObjectiveModelType[]>>({
      method: "GET",
      url: `${url}`,
    });
  }

  public getObjectivesLinkedToMainServices() {
    const url = `objective/getObjectivesLinkedToMainServices`;

    return this._mainService.sendRequest<MainResponseModel<ObjectiveModelType[]>>({
      method: "GET",
      url,
    });
  }
}
