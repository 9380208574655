import { Component, computed, inject, Input, input, signal, type TemplateRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../constants/constants";
import { AdaaBoolean } from "../../../../constants/enums";
import {
  type AttributeModelType,
  type AttributeTextMapType,
  type ExtendedFieldAttributeModelType,
  OrgUnit,
  type UserModelType,
  type ValueText,
} from "../../../../models";
import { ExtendedFieldsService, LanguageService, OrgUnitApiService, UsersApiService } from "../../../../services";
import {
  FormDropdownComponent,
  FormDropdownTreeComponent,
  FormInputComponent,
  FormInputDateComponent,
} from "../../../form";
import * as AttributeHelpers from "../utils";

@Component({
  selector: "adaa-ext-fields-tab",
  standalone: true,
  imports: [
    TranslateModule,
    TranslateTokenPipe,
    FormInputComponent,
    FormInputDateComponent,
    FormDropdownTreeComponent,
    FormDropdownComponent,
  ],
  templateUrl: "./ext-fields-tab.component.html",
  styleUrl: "../ext-fields.scss",
})
export class ExtFieldsTabComponent {
  @Input() public set attributes(data: ExtendedFieldAttributeModelType[]) {
    this.selectedAttributes.set(data);
  }

  private readonly _extendedFieldsService = inject(ExtendedFieldsService);
  private readonly _translateService = inject(TranslateService);
  private readonly _orgUnitApiService = inject(OrgUnitApiService);
  private readonly _usersApiService = inject(UsersApiService);
  private readonly _langaugeService = inject(LanguageService);
  private readonly _modalService = inject(NgbModal);

  itemType = input.required<number>();
  itemId = input<string | number>();
  mode = input<"create" | "edit">();

  isDirty = signal<boolean>(false);
  orgUnits = signal<OrgUnit[]>([]);
  orgUnitOptions = signal<ValueText[]>([]);
  users = signal<UserModelType[]>([]);
  usersOptions = signal<ValueText[]>([]);
  fields = signal<AttributeModelType[]>([]);
  cachedFields = signal<AttributeModelType[]>([]);
  selectedAttributes = signal<ExtendedFieldAttributeModelType[]>([]);

  unselectedFields = computed(() =>
    this.fields().filter(({ id }) => {
      const index = this.selectedFields().findIndex((field) => id === field.id);
      return index === -1;
    })
  );

  selectedFields = computed(() => {
    const fields: (AttributeModelType & { value: unknown })[] = [];
    for (const attr of this.selectedAttributes()) {
      const field = this.fields().find(({ id }) => id === attr.attrId);
      if (field)
        fields.push({
          ...field,
          value: AttributeHelpers.getValue(field, attr),
        });
    }

    for (const field of this.fields()) {
      const selectedField = fields.find(({ id }) => field.id === id);
      if (!selectedField && field.mandatory === AdaaBoolean.Y) {
        let value: unknown = undefined;
        if (field.defaultValue && !isNaN(Number(field.defaultValue)) && this.mode() === "edit") {
          value = Number(field.defaultValue);
        } else if (field.defaultValue && isNaN(Number(field.defaultValue)) && this.mode() === "edit") {
          value = field.defaultValue;
        }

        fields.push({
          ...field,
          value,
        });
      }
    }

    return fields;
  });

  readonly attributeHelpers = AttributeHelpers;

  readonly isFieldCached = ({ id }: AttributeModelType) =>
    this.cachedFields().findIndex((field) => field.id === id) !== -1;

  readonly textmapOptions = (attr: AttributeTextMapType[] = []) => [
    { value: undefined, text: this._translateService.instant("common.form.label.none") },
    ...AdaaHelper.setDropdownArray(attr ?? [], "lovNumber", AdaaHelper.getFieldLanguage("lovText")),
  ];

  readonly submit = () => this.selectedFields();

  public ngOnInit() {
    let id: number | undefined;
    if (!this.itemId()) {
      id = Number(this.itemId());
    }

    this._getOrgUnits();
    this._getUsers();
    this._extendedFieldsService.getAvailableFields(this.itemType(), id).subscribe({
      next: (res) => {
        this.fields.set(res.responseData);
      },
    });
  }

  public cacheField(field: AttributeModelType) {
    const isCached = this.isFieldCached(field);
    if (isCached) {
      this.cachedFields.update((fields) => fields.filter(({ id }) => field.id !== id));
    } else {
      this.cachedFields.update((fields) => [...fields, field]);
    }
  }

  public captureInput(event: unknown, field: AttributeModelType & { value: unknown }) {
    field.value = event;
  }

  public addField() {
    let id: number | undefined;
    if (!this.itemId()) {
      id = Number(this.itemId());
    }

    this.selectedAttributes.update((attrs) => [
      ...attrs,
      ...this.cachedFields().map((field) => ({
        itemId: id,
        itemType: this.itemType(),
        attrId: field.id,
        valueTextAE: undefined,
        valueTextEN: undefined,
        valueNumber: undefined,
        valueDate: undefined,
        dataType: field.dataType,
        nameAE: field.nameAE,
        nameEN: field.nameEN,
        status: Constants.OBJECT_STATUS.DRAFT,
      })),
    ]);

    this.isDirty.set(true);
  }

  public removeField(field: AttributeModelType) {
    this.selectedAttributes.update((attrs) => attrs.filter(({ attrId }) => attrId !== field.id));
    this.isDirty.set(true);
  }

  public isValid() {
    for (const field of this.selectedFields()) {
      if (field.mandatory === AdaaBoolean.Y && !AdaaHelper.isDefined(field.value)) {
        return false;
      }
    }
    return true;
  }

  public openModal(ref: TemplateRef<unknown>) {
    this._modalService.open(ref, {
      animation: true,
      scrollable: false,
      keyboard: false,
      centered: true,
      modalDialogClass: this._langaugeService.modalDirection(),
      beforeDismiss: () => {
        this.cachedFields.set([]);
        return true;
      },
    });
  }

  private _getOrgUnits() {
    this._orgUnitApiService
      .getbyEntityidAndObjectype(Number(AdaaHelper?.entity?.id), this._getItemPermission(this.itemType()))
      .subscribe({
        next: (res) => {
          this.orgUnits.set(res.responseData);

          this.orgUnitOptions.set([
            { value: undefined, text: this._translateService.instant("common.form.label.none") },
            ...AdaaHelper.setDropdownArray(
              res.responseData ?? [],
              "id",
              AdaaHelper.getFieldLanguage("name"),
              "parentOrgUnit"
            ),
          ]);
        },
      });
  }

  private _getUsers() {
    this._usersApiService.getOwnersPerEntity(Number(AdaaHelper?.entity?.id)).subscribe({
      next: (res) => {
        this.users.set(res.responseData);

        this.usersOptions.set([
          { value: undefined, text: this._translateService.instant("common.form.label.none") },
          ...AdaaHelper.setDropdownArray(res.responseData ?? [], "id", AdaaHelper.getFieldLanguage("name")),
        ]);
      },
    });
  }

  private _getItemPermission(itemType: number): number {
    switch (itemType) {
      case Constants.CONSTANT_INITIATIVE:
        return Constants.CONSTANT_PERMISSIONS.INITIATIVES;
      case Constants.CONSTANT_ACTIVITYTYPE:
        return Constants.CONSTANT_PERMISSIONS.ACTIVITIES;
      case Constants.CONSTANT_OBJECTIVETYPE:
        return Constants.CONSTANT_PERMISSIONS.OBJECTIVES;
      case Constants.CONSTANT_SERVICE:
        return Constants.CONSTANT_PERMISSIONS.SERVICES_KHADAMATI;
      case Constants.KPI_TYPE.UAE:
        return Constants.CONSTANT_PERMISSIONS.DKPI;
      case Constants.KPI_TYPE.NKPI:
        return Constants.CONSTANT_PERMISSIONS.NKPI;
      case Constants.KPI_TYPE.NTKPI:
        return Constants.CONSTANT_PERMISSIONS.NTKPI;
      case Constants.KPI_TYPE.DTKPI:
        return Constants.CONSTANT_PERMISSIONS.DTKPI;
      case Constants.KPI_TYPE.SKPI:
        return Constants.CONSTANT_PERMISSIONS.SKPI;
      case Constants.KPI_TYPE.SRVKPI:
        return Constants.CONSTANT_PERMISSIONS.SRVKPI;
      case Constants.KPI_TYPE.EKPI:
        return Constants.CONSTANT_PERMISSIONS.EKPI;
      case Constants.KPI_TYPE.MOKPI:
        return Constants.CONSTANT_PERMISSIONS.MOKPI;
      case Constants.KPI_TYPE.MTKPI:
        return Constants.CONSTANT_PERMISSIONS.MTKPI;
      case Constants.KPI_TYPE.OPM:
        return Constants.CONSTANT_PERMISSIONS.OPM;
      case Constants.KPI_TYPE.GSKPI:
        return Constants.KPI_TYPE.GSKPI;
      default:
        return 0;
    }
  }
}
