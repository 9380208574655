import { NgClass } from "@angular/common";
import { Component, computed, inject, Input, OnInit, signal } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { filter, forkJoin, map } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import { Constants } from "../../constants/constants";
import { FileInputType } from "../../constants/enums";
import { FormControlDisabledDirective } from "../../directives";
import { AttachFile, NtkpiChallenge, NtkpiSuccessStory, ParameterCatalog, ValueText } from "../../models";
import { NationalTargetCardApiService, PropertiesService, ValidatorApiService } from "../../services";
import { FormDropdownComponent, FormInputComponent, FormInputDateComponent, FormInputFileComponent } from "../form";
import { challengeAnalysisForm, successStoryForm } from "./ntkpi-card-forms";

@Component({
  selector: "adaa-national-target-card-modal",
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    TranslateModule,
    FormInputComponent,
    FormInputDateComponent,
    FormDropdownComponent,
    FormInputFileComponent,
    FormControlDisabledDirective,
  ],
  templateUrl: "./national-target-card-modal.component.html",
  styleUrl: "./national-target-card-modal.component.scss",
})
export class NationalTargetCardModalComponent implements OnInit {
  private _validatorApiService = inject(ValidatorApiService);
  private _nationalTargetCardApiService = inject(NationalTargetCardApiService);
  private _propertiesService = inject(PropertiesService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  modal: NgbActiveModal = inject(NgbActiveModal);

  @Input() header: string;
  @Input() ntkpiId: number;
  @Input() periodId: number;

  readonly isRequired = AdaaHelper.isFieldRequired.bind(AdaaHelper);

  periodList = signal<ValueText[]>([]);
  disableForm = signal<boolean>(false);
  selectedTab = signal<number>(1);
  leadershipLevels = signal<ValueText[]>([]);
  challengeAssessments = signal<ValueText[]>([]);
  continuityOptions = signal<ValueText[]>([]);
  data = {
    challengeAnalysis: signal<NtkpiChallenge | undefined>(undefined),
    successStory: signal<NtkpiSuccessStory | undefined>(undefined),
  };
  validations = {
    challengeAnalysis: signal<ParameterCatalog[]>([]),
    successStory: signal<ParameterCatalog[]>([]),
  };
  successStoryForm: FormGroup;
  challengeAnalysisForm: FormGroup;
  allowedFiles: FileInputType[] = [FileInputType.pdf, FileInputType.txt, FileInputType.doc, FileInputType.docx];

  submitButtonContent = computed<string>(() =>
    this.selectedTab() === 1
      ? this._translateService.instant("ntkpi_card.submit_challenge")
      : this._translateService.instant("ntkpi_card.submit_success_story")
  );

  public get evidenceFileChallenge(): AttachFile {
    const attachment = this.challengeAnalysisForm?.get("supportingEvidenceAttachment")?.value;
    if (attachment) {
      return {
        id: attachment.id,
        filename: attachment.filename,
      };
    }
    return {
      id: attachment ? attachment.id : 0,
      filename: attachment ? attachment.filename : "",
    };
  }

  public get evidenceFileSuccessStory(): AttachFile {
    const attachment = this.successStoryForm?.get("supportingEvidenceAttachment")?.value;
    return {
      id: attachment ? attachment.id : 0,
      filename: attachment ? attachment.filename : "",
    };
  }

  public ngOnInit(): void {
    this._fetchProps();
    this._fetchValidators();
  }

  public evidenceFileChallengeChanged(event: AttachFile | null): void {
    this.challengeAnalysisForm.get("supportingEvidenceAttachmentId")?.setValue(event ? event.id : null);
    this.challengeAnalysisForm.get("supportingEvidenceAttachment")?.setValue(event ? event : null);
  }

  public evidenceFileSuccessStoryChanged(event: AttachFile | null): void {
    this.successStoryForm.get("supportingEvidenceAttachmentId")?.setValue(event ? event.id : null);
    this.successStoryForm.get("supportingEvidenceAttachment")?.setValue(event ? event : null);
  }

  public updatePeriodId(id: number) {
    if (id === this.periodId) return;
    this.periodId = id;
    this._fetchData();
  }

  public saveChallenge(): void {
    this.challengeAnalysisForm.markAllAsTouched();
    if (!this.challengeAnalysisForm.valid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    let data = this.data.challengeAnalysis();
    const isUpdate = AdaaHelper.isDefined(data) && AdaaHelper.isDefined(data.id);
    const challenge = this.challengeAnalysisForm.getRawValue();

    // remove id if it's not defined
    if (!AdaaHelper.isDefined(challenge.id)) {
      delete challenge.id;
      challenge.ntkpiId = this.ntkpiId;
      challenge.periodId = this.periodId;
    }

    if (isUpdate) {
      data = {
        ...data,
        ...challenge,
      };
    } else {
      data = {
        ...challenge,
      };
    }

    this._nationalTargetCardApiService.saveChallenge(data as NtkpiChallenge, isUpdate).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this.modal.close();
      },
    });
  }

  public saveSuccessStory(): void {
    this.successStoryForm.markAllAsTouched();
    if (!this.successStoryForm.valid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    let data = this.data.successStory();
    const isUpdate = AdaaHelper.isDefined(data) && AdaaHelper.isDefined(data.id);
    const successStory = this.successStoryForm.getRawValue();

    // remove id if it's not defined
    if (!AdaaHelper.isDefined(successStory.id)) {
      delete successStory.id;
      successStory.ntkpiId = this.ntkpiId;
      successStory.periodId = this.periodId;
    }

    if (isUpdate) {
      data = {
        ...data,
        ...successStory,
      };
    } else {
      data = {
        ...successStory,
      };
    }

    this._nationalTargetCardApiService.saveSuccessStory(data as NtkpiSuccessStory, isUpdate).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this.modal.close();
      },
    });
  }

  private _fetchValidators() {
    forkJoin([
      this._validatorApiService.searchByKey(Constants.VALIDATORS_CONF_KEY.NATIONAL_TARGET_SUCCESS_STORY_FORM),
      this._validatorApiService.searchByKey(Constants.VALIDATORS_CONF_KEY.NATIONAL_TARGET_CHALLENGE_ANALYSIS_FORM),
    ])
      .pipe(
        filter(([successStory, challengeAnalysis]) => !successStory.inError && !challengeAnalysis.inError),
        map(([successStory, challengeAnalysis]) => [successStory.responseData, challengeAnalysis.responseData])
      )
      .subscribe({
        next: ([successStory, challengeAnalysis]) => {
          this.successStoryForm = successStoryForm(successStory.parameterCatalogs);
          this.challengeAnalysisForm = challengeAnalysisForm(challengeAnalysis.parameterCatalogs);

          this.validations.challengeAnalysis.set(challengeAnalysis.parameterCatalogs);
          this.validations.successStory.set(successStory.parameterCatalogs);

          this._fetchData();
        },
      });
  }

  private _fetchProps() {
    forkJoin({
      props: this._propertiesService.getPropByIdList([
        Constants.CONSTANT_LEADERSHIP_LEVEL,
        Constants.CONSTANT_CHALLENGE_ASSESSMENT,
      ]),
      translations: this._translateService.get(["ntkpi_card.countinuous", "ntkpi_card.completed"]),
    }).subscribe({
      next: (results) => {
        if (results.props.inError) return;

        this.challengeAssessments.set(
          AdaaHelper.setDropdownArray(
            results.props.responseData.filter((e) => e.propType === Constants.CONSTANT_CHALLENGE_ASSESSMENT),
            "id",
            AdaaHelper.getFieldLanguage("name")
          ).map((e) => ({ ...e, value: `${e.value}` }))
        );

        this.leadershipLevels.set(
          AdaaHelper.setDropdownArray(
            results.props.responseData.filter((e) => e.propType === Constants.CONSTANT_LEADERSHIP_LEVEL),
            "id",
            AdaaHelper.getFieldLanguage("name")
          ).map((e) => ({ ...e, value: `${e.value}` }))
        );

        this.continuityOptions.set([
          { value: "continuity", text: results.translations["ntkpi_card.countinuous"] },
          { value: "completed", text: results.translations["ntkpi_card.completed"] },
        ]);
      },
    });
  }

  private _fetchData() {
    forkJoin([
      this._nationalTargetCardApiService.getSuccessStory(this.ntkpiId, this.periodId),
      this._nationalTargetCardApiService.getChallenge(this.ntkpiId, this.periodId),
    ]).subscribe({
      next: ([successStory, challengeAnalysis]) => {
        // success story form
        if (!successStory.inError) {
          const data = successStory.responseData;

          if (data) {
            this.successStoryForm.setValue({
              id: data.id,
              achievementTitle: data.achievementTitle,
              leadershipLevel: data.leadershipLevel,
              achievementDsc: data.achievementDsc,
              impactAchievementTarget: data.impactAchievementTarget,
              impactAchievementSociety: data.impactAchievementSociety,
              projectLaunchDate: data.projectLaunchDate,
              beneficiaries: data.beneficiaries,
              projectContinuity: data.projectContinuity,
              supportingEvidenceAttachmentId: data.supportingEvidenceAttachment?.id ?? null,
              supportingEvidenceAttachment: data.supportingEvidenceAttachment ?? null,
            });
          }
          this.data.successStory.set(successStory.responseData as NtkpiSuccessStory);
        }

        // challenge analysis form
        if (!challengeAnalysis.inError) {
          const data = challengeAnalysis.responseData;
          if (data) {
            this.challengeAnalysisForm.setValue({
              id: data.id,
              analysis: data.analysis,
              rootCause: data.rootCause,
              initialProposedTreatment: data.initialProposedTreatment,
              pmoRequirement: data.pmoRequirement,
              assessment: data.assessment,
              supportingEvidenceAttachmentId: data.supportingEvidenceAttachment?.id ?? null,
              supportingEvidenceAttachment: data.supportingEvidenceAttachment ?? null,
            });

            this.data.challengeAnalysis.set(challengeAnalysis.responseData as NtkpiChallenge);
          }
        }
      },
    });
  }
}
