<ng-template #content let-modal>
    <div class="modal-header bg-white">
        <h4 class="modal-title w-100 text-center">
            @if (isAuditTrail()) {
                {{ "auditrail.audittrail_detail_info" | translate }}
            } @else if (!showOnlyNew()) {
                {{ "communication.difference.title" | translate }}
            } @else {
                {{ "communication.difference.view_object" | translate }}
            }
        </h4>

        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>

    <div class="modal-body px-4 py-3 bg-white">
        @if (isAuditTrail()) {
            @if (showViewOnlyDiffSwitch()) {
                <div class="position-relative d-flex justify-content-center w-100 mb-3 mt-2">
                    <ul
                        class="nav nav-pills nav-fill gap-2 p-1 small bg-primary shadow rounded-5 w-25"
                        id="pillNav2"
                        role="tablist"
                        style="
                            --bs-nav-link-color: var(--bs-white);
                            --bs-nav-pills-link-active-color: var(--bs-primary);
                            --bs-nav-pills-link-active-bg: var(--bs-white);
                            --bs-link-hover-color: var(--bs-primary-bg-subtle);
                            --bs-nav-link-font-weight: 700;
                            --bs-nav-link-font-size: 14px;
                        "
                    >
                        <li class="nav-item" role="presentation">
                            <a
                                class="nav-link active rounded-5"
                                id="home-tab2"
                                data-bs-toggle="tab"
                                type="button"
                                role="tab"
                                aria-selected="true"
                                href="#viewAll"
                                (click)="$event.stopPropagation(); viewOnlyDifferences.set(false)"
                            >
                                {{ "common.form.button.view_all" | translate }}
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a
                                class="nav-link rounded-5"
                                id="profile-tab2"
                                data-bs-toggle="tab"
                                type="button"
                                role="tab"
                                aria-selected="false"
                                href="#viewDifferences"
                                (click)="$event.stopPropagation(); viewOnlyDifferences.set(true)"
                            >
                                {{ "communication.view_differences" | translate }}
                            </a>
                        </li>
                    </ul>
                </div>
            }

            <div class="row d-flex align-content-center">
                <div class="col-4 fw-bold px-3 py-2 border border-white tbl-col rounded-start-1">{{ "auditrail.username" | translate }}</div>
                <div class="col-8 px-3 py-2 border border-white tbl-col rounded-end-1">{{ auditDiff()?.userName }}</div>
            </div>

            <div class="row d-flex align-content-center">
                <div class="col-4 fw-bold px-3 py-2 border border-white tbl-col rounded-start-1">{{ "auditrail.page" | translate }}</div>
                <div class="col-8 px-3 py-2 border border-white tbl-col rounded-end-1">{{ auditDiff()?.accessPage }}</div>
            </div>

            <div class="row d-flex align-content-center">
                <div class="col-4 fw-bold px-3 py-2 border border-white tbl-col rounded-start-1">{{ "auditrail.date" | translate }}</div>
                <div class="col-8 px-3 py-2 border border-white tbl-col rounded-end-1">
                    {{ auditDiff()?.date! | dubaiTime | date: constants.dateFormat }}
                </div>
            </div>

            @if (!!auditDiff()?.actionTypeId) {
                <div class="row d-flex align-content-center">
                    <div class="col-4 fw-bold px-3 py-2 border border-white tbl-col rounded-start-1">{{ "auditrail.object" | translate }}</div>
                    <div class="col-8 px-3 py-2 border border-white tbl-col rounded-end-1">{{ auditObjectTypeText() }}</div>
                </div>
            }

            @if (showWfInfo()) {
                <div class="row d-flex align-content-center">
                    <div class="col-4 fw-bold px-3 py-2 border border-white tbl-col rounded-start-1">
                        {{ "workflow.workflowactiontype" | translate }}
                    </div>
                    <div class="col-8 px-3 py-2 border border-white tbl-col rounded-end-1">{{ auditActionTypeText() }}</div>
                </div>

                <div class="row d-flex align-content-center">
                    <div class="col-4 fw-bold px-3 py-2 border border-white tbl-col rounded-start-1">
                        {{ "workflow.approval_rejection_comment" | translate }}
                    </div>
                    <div class="col-8 px-3 py-2 border border-white tbl-col rounded-end-1">
                        @if (auditDiff()?.wfGeneralComments && (auditDiff()?.wfGeneralComments ?? "").length > 0) {
                            <span
                                class="category"
                                [class.category--no]="(auditDiff()?.categorizedComments ?? []).length < 1"
                                [attr.data-title]="'workflow.general' | translate"
                            >
                                {{ auditDiff()?.wfGeneralComments }}
                            </span>
                        }

                        @for (comment of auditDiff()?.categorizedComments ?? []; track $index) {
                            <span class="category" [attr.data-title]="comment | translateToken: 'categoryName'">
                                {{ comment?.comments }}
                            </span>
                        }
                    </div>
                </div>
            }

            <div class="d-block clearfix mb-2"></div>
        }

        @if (!displayData()) {
            <div class="row d-flex align-content-center mt-3 mb-2">
                <div class="col-12 border border-white tbl-col p-3 d-flex align-items-center rounded-1">
                    <p class="fw-bold my-0">{{ "common.tables.no_info" | translate }}</p>
                </div>
            </div>
        } @else {
            @switch (screenType()) {
                @case ("kpi") {
                    <adaa-kpi-screen
                        [itemType]="itemType()"
                        [isAuditTrail]="isAuditTrail()"
                        [isNew]="isNew()"
                        [showOnlyNew]="showOnlyNew()"
                        [newKpiObject]="newObject()"
                        [oldKpiObject]="oldObject()"
                        [viewOnlyDifferences]="viewOnlyDifferences()"
                    />
                }

                @case ("data-entry") {
                    <adaa-data-entry-screen
                        [showOnlyNew]="showOnlyNew() || isNew()"
                        [newDataentryObject]="newObject()"
                        [oldDataentryObject]="oldObject()"
                        [viewOnlyDifferences]="viewOnlyDifferences()"
                    />
                }

                @case ("audit-data-entry") {
                    <adaa-audit-data-entry-screen
                        [isAuditTrail]="isAuditTrail()"
                        [showOnlyNew]="showOnlyNew() || isNew()"
                        [newDataentryObject]="newObject()"
                        [oldDataentryObject]="oldObject()"
                        [viewOnlyDifferences]="viewOnlyDifferences()"
                    />
                }

                @case ("tp") {
                    <adaa-tp-screen
                        [isNew]="isNew()"
                        [showOnlyNew]="showOnlyNew()"
                        [newObject]="newObject()!"
                        [oldObject]="oldObject()!"
                        [viewOnlyDifferences]="viewOnlyDifferences()"
                    />
                }

                @case ("agm") {
                    <adaa-agm-screen
                        [itemType]="itemType()"
                        [isNew]="isNew()"
                        [showOnlyNew]="showOnlyNew()"
                        [newObject]="newObject()!"
                        [oldObject]="oldObject()!"
                        [viewOnlyDifferences]="viewOnlyDifferences()"
                    />
                }

                @case ("tp-milestone") {
                    <adaa-tp-milestone-screen
                        [isNew]="isNew()"
                        [showOnlyNew]="showOnlyNew()"
                        [newObject]="newObject()!"
                        [oldObject]="oldObject()!"
                        [viewOnlyDifferences]="viewOnlyDifferences()"
                    />
                }

                @case ("metric-actual-value") {
                    <adaa-metric-actual-value-screen
                        [isNew]="isNew()"
                        [showOnlyNew]="showOnlyNew()"
                        [newObject]="newObject()!"
                        [oldObject]="oldObject()!"
                        [viewOnlyDifferences]="viewOnlyDifferences()"
                    />
                }

                @case ("ntkpi-card") {
                    <adaa-ntkpi-card-screen />
                }

                @case ("transformational-targets") {
                    <adaa-transformational-targets-screen />
                }

                @case ("transformational-agreements") {
                    <adaa-transformational-agreements-screen />
                }

                @case ("kpi-annual-weights") {
                    <adaa-kpi-annual-weights-screen [data]="kpiAnnualWeights()" [wfProcessCtlId]="wfProcessCtlId()" />
                }

                @case ("simple") {
                    <adaa-simple-screen
                        [isNew]="isNew()"
                        [itemType]="itemType()"
                        [showOnlyNew]="showOnlyNew()"
                        [newObject]="newObject()"
                        [oldObject]="oldObject()"
                        [viewOnlyDifferences]="viewOnlyDifferences()"
                    />
                }
            }
        }
    </div>

    <div class="modal-footer d-flex w-100 align-content-center justify-content-center py-2">
        <button type="button" class="btn btn-primary" (click)="modal.close()">
            {{ "common.form.button.close" | translate }}
        </button>
    </div>
</ng-template>
