import { CommonModule, NgClass } from "@angular/common";
import {
  Component,
  computed,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  SimpleChanges,
  viewChild,
} from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faRemove, faUpload } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { FileInputType } from "../../../constants/enums";
import { AttachFile, ParameterCatalog } from "../../../models";
import { FilesApiService, LanguageService } from "../../../services";
import { PictureComponent } from "../../general/picture/picture.component";

@Component({
  selector: "adaa-form-input-file",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FontAwesomeModule, TranslateModule, PictureComponent, NgClass],
  templateUrl: "./form-input-file.component.html",
  styleUrl: "./form-input-file.component.scss",
})
export class FormInputFileComponent {
  private _filesApiService = inject(FilesApiService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  languageService = inject(LanguageService);

  adaaHelper = AdaaHelper;
  uploadSuccess: boolean = false;
  uploadError: boolean = false;
  isLoading: boolean = false;
  fileName = signal<string>("");
  fileSize = signal<number>(0);
  uploadProgress = signal<number>(0);

  fileIcon = computed(() => {
    const filename = this.fileName();
    const $ext = filename.substring(filename.lastIndexOf("."));

    if (this.showContent) {
      return "fa-solid fa-photo-film";
    }

    switch ($ext) {
      case FileInputType.doc:
      case FileInputType.docx: {
        return "fa-solid fa-file-word";
      }
      case FileInputType.ppt:
      case FileInputType.pptx: {
        return "fa-solid fa-file-powerpoint";
      }
      case FileInputType.csv: {
        return "fa-solid fa-file-csv";
      }
      case FileInputType.xls:
      case FileInputType.xlsx: {
        return "fa-solid fa-file-excel";
      }
      case FileInputType.zip: {
        return "fa-solid fa-file-zipper";
      }
      case FileInputType.pdf: {
        return "fa-solid fa-file-pdf";
      }
      case FileInputType.txt: {
        return "fa-solid fa-file-lines";
      }
      default: {
        return "fa-solid fa-file";
      }
    }
  });

  @Input() controlName: string | null = null;
  @Input() required: boolean = false;
  @Input() invalid: boolean = false;
  @Input() label: string = "";
  @Input() isDisabled: boolean = false;
  @Input() file: AttachFile = { id: -1, filename: "" };
  @Input() allowedFileSize: number | undefined = undefined;
  @Input() allowedFilesMessage: string = "documents.files_allowed";
  @Input() dropFileZoneMessage: string = "common.form.label.drop_file";
  @Input() dropFileZoneDisabledMessage: string = "common.tables.no_info";
  @Input() onDeleteRemoveFile: boolean = true;
  @Input() attachUrl: string;
  @Input() allowedFiles: FileInputType[] = [
    FileInputType.txt,
    FileInputType.pdf,
    FileInputType.doc,
    FileInputType.docx,
  ];

  @Input()
  public set setValidator(validatorList: { parameterCatalogs: ParameterCatalog[] }) {
    if (validatorList) {
      if (validatorList.parameterCatalogs) {
        const field = validatorList.parameterCatalogs.find((e) => e.fieldName === this.controlName);

        if (field) {
          this.required = field.mandatory === "Y";
        }
      }
    }
  }

  @Output() inputChanges = new EventEmitter<AttachFile | null>();

  private _fileInput = viewChild<ElementRef>("fileInput");

  private _iconLibrary: FaIconLibrary = inject(FaIconLibrary);

  /**
   * To Show the preview if the file type is image
   */
  public get showContent(): boolean {
    const images: FileInputType[] = [
      FileInputType.bmp,
      FileInputType.gif,
      FileInputType.jpeg,
      FileInputType.jpeg,
      FileInputType.jpg,
      FileInputType.png,
      FileInputType.svg,
    ];
    const extension = this.fileName().substring(this.fileName().lastIndexOf("."));
    return images.findIndex((e) => e === extension.toLowerCase()) > -1;
  }

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faUpload);
    this._iconLibrary.addIcons(faRemove);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["file"] && changes["file"].previousValue != changes["file"].currentValue) {
      this.fileName.set(changes.file.currentValue.filename);
    }
  }

  public downloadFile() {
    if (this.file?.id && this.file?.filename) {
      this._filesApiService.save(this.file.id, this.file.filename);
    }
  }

  // Handler for file input change
  public onFileChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const file = files[0] as File | null;
    this._uploadFile(file);
  }

  // Handler for file drop
  public onFileDrop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] as File | null;
    this._uploadFile(file);
  }

  // Prevent default dragover behavior
  public onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  // Method to remove the uploaded file
  public removeFile(): void {
    this.fileName.set("");

    this._fileInput()!.nativeElement.value = null;
    this.inputChanges.emit(null);

    if (this.onDeleteRemoveFile)
      this._filesApiService.deleteFile(this.file.id).subscribe({
        next: () => {
          this.fileSize.set(0);
          this.uploadSuccess = false;
          this.uploadError = false;
          this.uploadProgress.set(0);
        },
      });
  }

  private _checkFileType() {
    const extension = this.fileName().substring(this.fileName().lastIndexOf("."));

    if (this.allowedFiles.find((e) => e === extension.toLowerCase())) {
      return true;
    } else {
      this._toastrService.error(this._translateService.instant("notification.error.file_type_invalid"));
      return false;
    }
  }

  // Method to handle file upload
  private _uploadFile(file: File | null): void {
    if (file) {
      this.fileName.set(file.name); // Set file name

      if (!this._checkFileType()) {
        this.fileName.set("");
        return;
      }

      if (this.allowedFileSize && file.size > this.allowedFileSize) {
        this._toastrService.warning(this._translateService.instant("notification.warning.fileSize"));
        return;
      }

      this.isLoading = true;
      this.fileSize.set(Math.round(file.size / 1024)); // Set file size in KB
      this._filesApiService.upload(file, false, this.attachUrl).subscribe({
        next: (response) => {
          this.file.id = response.responseData as number;
          this.file.filename = file.name;
          this.uploadSuccess = true;
          this.uploadError = false;
          this.inputChanges.emit(this.file);
          this.isLoading = false;
        },
      });
    } else {
      this.uploadSuccess = false;
      this.uploadError = true;
      this.isLoading = false;
      this.fileName.set("");
    }
  }
}
