import { CommonModule } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { DataTableComponent } from "../table/data-table/data-table.component";

@Component({
  selector: "adaa-integrity-error",
  standalone: true,
  imports: [TranslateModule, CommonModule, DataTableComponent],
  templateUrl: "./integrity-error.component.html",
  styleUrl: "./integrity-error.component.scss",
})
export class IntegrityErrorComponent {
  @Input() errorMessages = [];

  modal: NgbActiveModal = inject(NgbActiveModal);
}
