import { inject, Injectable } from "@angular/core";

import { MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class ContributingEntitiesApiService {
  private _mainService = inject(MainService);

  public getCardByKpiId(id: number) {
    const url = `contributingentity/getCardByKpiId/${id}`;

    return this._mainService.sendRequest<
      MainResponseModel<{ id: number; nameAE: string; nameEN: string; kpiId: number }[]>
    >({
      method: "GET",
      url,
    });
  }
}
