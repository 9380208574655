/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, computed, inject, input, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Chart, ChartConfiguration } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { BaseChartDirective } from "ng2-charts";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import type { PillarPerformanceModelType } from "../../../models";
import { LanguageService } from "../../../services";

@Component({
  selector: "adaa-pillar-performance-graph",
  standalone: true,
  imports: [BaseChartDirective],
  styleUrl: "./government-enabler-pillars.component.scss",
  template: `<canvas
    class="pillar-performance-chart"
    [id]="chartId()"
    baseChart
    type="pie"
    [datasets]="datasets()"
    [labels]="labels()"
    [options]="options()"
    (chartClick)="clicked($event)"
  ></canvas>`,
})
export class PillarPerformanceGraphComponent {
  private readonly _languageService = inject(LanguageService);
  private _router = inject(Router);

  data = input.required<PillarPerformanceModelType>();
  chartId = input.required<string>();
  periodId = input<number>();

  datasets = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    const pillarData: any = {
      backgroundColor: this.getColor(this.data().evaluation),
      borderWidth: 0,
      data: [100],
      datalabels: {
        display: true,
        align: "center",
        textAlign: "center",
        anchor: "start",
        color: "#FFF",
        backgroundColor: null,
        borderRadius: 3,
        offset: 0,
        font: {
          size: 24,
          weight: "bold",
        },
        formatter: function (): string {
          const value = that.data().pillarPerformance;
          if (!AdaaHelper.isDefined(value)) return "";
          return AdaaHelper.percentageValue(that.data().pillarPerformance);
        },
      },
    };

    const ekpiData: any = {
      backgroundColor: this.data().ekpiList.map((ekpi) => this.getColor(ekpi.evaluation)),
      data: this.data().ekpiList.map(() => 100 / this.data().ekpiList.length),
      datalabels: {
        display: false,
      },
      ekpiIds: this.data().ekpiList.map((ekpi) => ekpi.id),
    };

    return [ekpiData, pillarData];
  });

  labels = computed(() => [
    ...this.data().ekpiList.map((ekpi) => AdaaHelper.getItemValueByToken(ekpi, "ekpiName")),
    AdaaHelper.getItemValueByToken(this.data(), "pillarName"),
  ]);

  options = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    return {
      plugins: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: "index",
        },
        tooltip: {
          xAlign: "center",
          yAlign: "top",
          position: "nearest",
          bodySpacing: 5,
          backgroundColor: "#FFF",
          titleColor: "#838383",
          bodyColor: "#838383",
          titleFont: { weight: "bold", size: 10 },
          padding: 10,
          cornerRadius: 5,
          borderWidth: 2,
          rtl: this._languageService.direction() === "rtl",
          textDirection: this._languageService.direction(),
          borderColor: (ctx) => {
            const [item] = ctx.tooltipItems;
            if (!item) return Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY;
            let data: number;
            if (item.raw === 100) {
              data = this.data().evaluation ?? Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY;
            } else {
              data = this.data().ekpiList[item.dataIndex].evaluation ?? Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY;
            }
            return AdaaHelper.hexToRgba(Constants.COLOR_CODES[data], "0.5");
          },
          callbacks: {
            title: (items) => {
              const [item] = items;
              if (item.datasetIndex === 1) return that.labels()[this.labels().length - 1];
              const datalist = [...this.labels()];
              datalist.pop();
              return this._wrapText(datalist[item.dataIndex], 5);
            },
            label: (item) => {
              let data: number | undefined;
              if (item.datasetIndex === 1) data = this.data().pillarPerformance;
              else data = this.data().ekpiList[item.dataIndex].actual;

              const symbol =
                this.data().ekpiList[item.dataIndex].measurementUnit === Constants.CONSTANT_MEASUREMENT_PERCENTAGE_01
                  ? "%"
                  : " ";
              return AdaaHelper.percentageValue(data, 2, "-", symbol);
            },
            labelColor: (item) => {
              let data: number | undefined;
              if (item.datasetIndex === 1) data = this.data().evaluation;
              else data = this.data().ekpiList[item.dataIndex].evaluation;

              return {
                backgroundColor: Constants.COLOR_CODES[data || Constants.CONSTANT_COLORS.EVALUATION_GRAY],
                borderColor: "white",
                borderWidth: 1,
                borderDashOffset: 5,
              };
            },
          },
        },
        legend: {
          display: false,
        },
      },
      onHover: (event: any, elements) => {
        if (event && elements.length) {
          event.native.target.style.cursor = "pointer";
        } else {
          event.native.target.style.cursor = "default";
        }
      },
    } as ChartConfiguration["options"];
  });

  chart = viewChild<BaseChartDirective>(BaseChartDirective);

  public ngOnInit() {
    Chart.register(ChartDataLabels);
  }

  public clicked(event: any) {
    if (event.active && event.active.length > 0) {
      const dataIndex = event.active[0].index;

      const canvas = document.getElementById(this.chartId()) as HTMLCanvasElement;
      const chartInstance: any = Chart.getChart(canvas);
      const id = chartInstance?.data.datasets[0].ekpiIds[dataIndex];

      if (id) this._router.navigateByUrl(`/console/kpi/ekpi/${id}?periodId=${this.periodId()}`);
    }
  }

  public getColor(code?: number) {
    return Constants.COLOR_CODES[code ?? Constants.CONSTANT_COLORS.EVALUATION_GRAY];
  }

  private _wrapText(text: string, maxLength: number) {
    const words = text.split(" ");
    const lines = [];
    let currentLine: string[] = [];

    words.forEach((word) => {
      currentLine.push(word);
      if (currentLine.length === maxLength) {
        lines.push(currentLine.join(" "));
        currentLine = [];
      }
    });

    if (currentLine.length > 0) {
      lines.push(currentLine.join(" "));
    }

    return lines;
  }
}
