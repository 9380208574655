import { CommonModule, NgOptimizedImage } from "@angular/common";
import { Component, inject } from "@angular/core";

import { LoaderService } from "../../services";

@Component({
  selector: "adaa-spinner",
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: "./spinner.component.html",
  styleUrl: "./spinner.component.scss",
})
export class SpinnerComponent {
  loaderService: LoaderService = inject(LoaderService);
}
