import { inject, Injectable } from "@angular/core";

import type { AttachmentModelType, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class AttachmentsApiService {
  private _mainService = inject(MainService);

  public getByItemIdItemType({ id, type, periodId }: { id: number; type: number; periodId?: number }) {
    const url = `attachment/getByItemIdItemType/${id}/${type}`;
    const qs = new URLSearchParams();
    if (periodId) qs.set("period", `${periodId}`);

    return this._mainService.sendRequest<MainResponseModel<AttachmentModelType[]>>({
      method: "GET",
      url: `${url}?${qs}`,
    });
  }

  public getBase64ById(fileId: number) {
    const url = `attachment/getBase64ById/${fileId}`;

    return this._mainService.sendRequest<MainResponseModel<AttachmentModelType[]>>({
      method: "GET",
      url,
    });
  }
}
