<div class="position-relative d-flex flex-column justify-content-center align-items-center card-type">
    <h6 class="fw-bold text-center text-truncate w-100 pt-3" [attr.title]="data() | translateToken: 'name'">{{ data() | translateToken: "name" }}</h6>

    <img [src]="logo()" />

    <adaa-progress-indicator
        class="w-100"
        [value]="data().visionAchievement!"
        [color]="data().visionAchievementColor!"
        [label]="('landingPage.entity_page.overall_performance' | translate) + ':'"
    />

    <adaa-progress-indicator
        class="w-100"
        [value]="data().performance!"
        [color]="data().performanceColor!"
        [label]="('landingPage.entity_page.annual_performance' | translate) + ':'"
    />

    <adaa-progress-indicator
        class="w-100"
        [value]="data().initiativeProgress!"
        [color]="data().initiativeProgressColor!"
        [label]="('box_status.initiative_progress' | translate) + ':'"
    />
</div>
