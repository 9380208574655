{
  "sdg": {
    "breadcrumb": "عرض الهدف",
    "goals_achievement": "نسبة تحقيق الأهداف",
    "goal_achievement": "نسبة تحقيق الهدف",
    "initiatives_expenditure": "المصروفـات",
    "goal_expenses": "مصروفات الهدف",
    "goal_budget": "موازنة الهدف",
    "allocated_budget": "الموازنـة المـالية",
    "total_initiatives": "عدد المبادرات",
    "sdg_target_achievement": "نسبة تحقيق الأهداف",
    "goal_targets": "مستهدفـات الهدف",
    "goal_initiatives": "مبادرات الهدف",
    "title": "أهداف التنمية المستدامة"
  },
  "waiting_translation": "في انتظار الترجمة",
  "monitoring": {
    "email": "البريد الإلكتروني",
    "name": "الاسم",
    "username": "اسم المستخدم"
  },
  "new_password": {
    "error": {
      "error_message": "كلمة المرور يجب ان تكون",
      "check_english_letters": "جزء من مجموعة الأحرف الإنجليزية [A-Z] [a-z]",
      "check_numbers": "تحتوي الأرقام [0-9]",
      "check_password_length": "تحتوي على 8 خانات على الأقل"
    }
  },
  "access_denied": {
    "title": "تم رفض الدخول للنظام",
    "body": "لا توجد لديك صلاحية للدخول للصفحة المطلوبة"
  },
  "helpdesk": {
    "edit": "تعديل تذكرة الدعم الفني",
    "new_ticket": "تسجيل طلب دعم",
    "attachment": "المرفق",
    "service_now_status": "وضعية طلب الدعم",
    "changes_justifications": "مبررات التغييرات",
    "comment_add": "إضافة ملاحظة",
    "attachments": "المرفقات",
    "attachment_add": "إضافة مرفق",
    "on": "- ",
    "comment": "تفاصيل الملاحظة",
    "comments_log": "سجل الملاحظات",
    "comments": "مرجع الملاحظة",
    "expected_resolution_date": "تاريخ الإنجاز المتوقع",
    "attachment_name": "اسم المرفق",
    "service_now_reference": "مرجع الدعم الفني",
    "status": "الحالة",
    "assigned_to": "مخصص ل",
    "creation_date": "تاريخ الإنشاء",
    "created_by": "أنشئ بواسطة",
    "url": "الرابط",
    "object": "العنصر",
    "section": "القسم",
    "priority": "الأولوية",
    "type": "النوع",
    "ticket": {
      "priority": {
        "critical": "عالي الأهمية",
        "high": "مهم",
        "medium": "متوسط الأهمية",
        "low": "منخفض الأهمية"
      },
      "type": {
        "clarification": "التوضيحات",
        "error": "خطأ",
        "change": "تغيير",
        "other": "أخرى"
      },
      "status": {
        "new": "جديد",
        "in_progress": "قيد الإجراء",
        "resolved": "تم الحل",
        "reopen": "إعادة فتح الطلب",
        "closed": "مغلق"
      }
    },
    "console": "مركز المساعدة",
    "create": "إنشاء تذكرة دعم فني جديدة",
    "title": "مركز المساعدة",
    "chart": {
      "unresolved_issues_per_priority": "الطلبات غير المحلولة حسب الأولوية",
      "issues_per_status": "الطلبات حسب الحالة"
    }
  },
  "language": {
    "forms": {
      "english": " النماذج باللغة العربية",
      "arabic": " النماذج باللغة الإنجليزية"
    },
    "change_lang": "English"
  },
  "report": {
    "org_unit": "الوحدة التنظيمية للمؤشر",
    "owner": "مسؤول المؤشر",
    "select_strategic_year": "السنة",
    "pre_generated_report_alert": "سيتم تنزيل تقرير مستخرج مسبقا إن وجد",
    "info_message_pmo": "يرجى اختيار نوع المؤشر وجهة اتحادية واحدة على الأقل",
    "info_message_entity": "يرجى اختيار نوع المؤشر",
    "title": "التقارير",
    "output_format": "صيغة التقرير",
    "kpi_type": "نوع المؤشر",
    "show_benchmark": " إظهار المقارنات المعيارية",
    "loading_report": "... تحميل التقرير",
    "nkpi_list": "قائمة المؤشرات الوطنية",
    "dkpi_list": "قائمة مؤشرات التوجهات الحكومية",
    "entities_list": "قائمة الجهات",
    "ekpi_list": " قائمة مؤشرات الممكنات الحكومية",
    "initiative_list": "قائمة المبادرات",
    "pillar_list": "قائمة المحاور",
    "empty_report": "التقرير لا يحتوي على معلومات",
    "pdf": "PDF",
    "excel": "EXCEL",
    "word": "WORD",
    "language": "اللغة",
    "expiration_date": "تاريخ الإنتهاء",
    "please_select": "الرجاء الاختيار",
    "background_message": "ملاحظة: جاري تحميل التقرير، في حال التنقل لشاشة أخرى، يمكنكم الاطلاع على التقرير في وحدة \"التقارير المستخرجة\" ضمن خيارات وحدة التحكم",
    "strategic_cycle_list": "يرجى اختيار الدورة الاستراتيجية التي تود المقارنة بها:",
    "generated_reports": {
      "title": "التقارير المستخرجة",
      "console_menu_item": "التقارير المستخرجة"
    },
    "messages": {
      "more_than_10_reports": ".لقد قمت باختيار أكثر من 10 تقارير. الرجاء اختيار 10 أو أقل",
      "more_than_10_user_msg": "الحد الأقصى للتقارير التي يمكن استخراجها هي 10 تقارير معاً"
    }
  },
  "notification": {
    "error": {
      "code_17_2": "تم تجاوز الحد الأقصى لعدد محاولات تسجيل الدخول",
      "negative_expenses": "المصروفات يجب ألا تكون أقل من صفر",
      "negative_budget": "الميزانية يجب ألا تكون أقل من صفر",
      "dm_resources_date_task_range": "تاريخ البدء و الإنتهاء المدخل غير مطابق لتاريخ بدء و انتهاء المهمة",
      "dm_resources_date_activity_range": "تاريخ البدء و الإنتهاء المدخل غير مطابق لتاريخ بدء و انتهاء النشاط",
      "ad_user_not_exists": "المستخدم غير موجود، يرجى التواصل مع مسؤول النظام",
      "password_requirements_failed": "كلمة المرور الجديدة لا تطابق درجة التعقيد، عدد الأحرف / الرموز المطلوبة ، أو قد تكون هذه الكلمة مستخدمة من قبل",
      "action_date_filter_error": "يمكنك اختيار فترة زمنية محصورة بثلاث أشهر فقط",
      "error_uploading": "حدث خطأ أثناء تحميل الملف",
      "save": "حدث خطأ أثناء حفظ المعلومات",
      "generic": "حدث خطأ. حاول مرة أخرى",
      "code_generic": "حدث خطأ. حاول مرة أخرى",
      "dates_difference": "يرجى اختيار تاريخ في الفترة القادمة",
      "code_50": "اسم المستخدم موجود",
      "code_55": "البريد الإلكتروني مستخدم",
      "code_14": "الرؤية موجودة",
      "code_2": "حدث خطأ في تنفيذ العملية. يرجى المحاولة مرة أخرى والتواصل مع مسؤول النظام في حال تكرار المشكلة",
      "code_26": "حدث خطأ في تنفيذ العملية. يرجى المحاولة مرة أخرى والتواصل مع مسؤول النظام في حال تكرار المشكلة",
      "valuesMinMaxDifference": "الحد الأدنى لا يمكن أن يكون أكبر من الحد الأعلى",
      "rule_exists": "يوجد قاعده بالتكرار المدرج ونوع العنصر",
      "session_expired": "انتهت مدة التسجيل",
      "dates_span_initiative": "تاريخ النشاط ليس ضمن تاريخ المبادرة المحددة",
      "dates_span_activity": "تاريخ المهمة ليس ضمن تاريخ النشاط المحدد",
      "object_edited_by_another_user": "يتم تعديل البيان من قبل مستخدم آخر",
      "object_in_approval": "العنصر قيدالموافقة ولا يمكن تعديله",
      "file_type_invalid": "نوع ملف غير صحيح",
      "notmatch": "كلمة المرور القديمة غير مطابقة",
      "no_periods": "لا توجد دورات في التواريخ المختارة",
      "workload_not_100": "حجم العمل للمهام يجب ان يساوي 100",
      "workload": "يجب أن تكون القيمة بين 0 و 100",
      "progress": "نسبة الإنجاز يجب أن تكون بين 0 و 100",
      "code_21": "يمكنك إنشاء خطة واحدة في نفس الفترة الزمنية",
      "code_57": "المستخدم غير موجود. يرجى التواصل مع مسؤول النظام",
      "code_56": "خطأ في كلمة المرور الحالية",
      "code_22": "مدة الخطة يجب أن لا تقل عن شهر واحد",
      "code_29": "الرمز غير صحيح",
      "measurement_unit_required": "يرجى اختيار وحدة القياس",
      "removeUser": "لا يمكنك حذف المستخدم الخاص بك",
      "twoTasks": "يجب إنشاء مهمتين على الأقل لكل نشاط",
      "task_same_name": "توجد مهمة أخرى لهذا النشاط بنفس الاسم",
      "dependency_exists": "توجد عناصر أخرى مرتبطة بهذا العنصر",
      "removeLookup": "حدث خطأ أثناء حذف القيمة",
      "code_8": "بيانات دخول غير صحيحة",
      "integrity_system": "خطأ في نظام قواعد العمل",
      "somethingWrong_tryAgain": "حدث خطأ الرجاء المحاولة مرة أخرى",
      "upload_error": "كان هناك خطأ",
      "org_unit_in_use": "الوحدة التنظيمية التي تحاول حذفها قد تم تعيينها لمستخدمين في النظام",
      "risk_same_name": "يوجد مخاطرة أخرى بنفس الاسم مرتبطة بهذا النشاط ",
      "object_in_use": "العنصر مرتبط بعنصر آخر",
      "activities_workload_not_100": "وزن الأنشطة يجب أن يساوي 100",
      "wrong_place": "الإجراء غير مسموح",
      "initiative_under_objective": "ترتبط المبادرة بهدف فقط",
      "activity_under_initiative": "يرتبط النشاط بمبادرة فقط",
      "opm_under_activity": "يرتبط المؤشر التغيلي بنشاط فقط",
      "skpi_under_objective": "يرتبط المؤشر الإستراتيجي بهدف فقط",
      "cycle_remove_entities": "لا يمكنك حذف الجهات من الخطة النشطة",
      "code_23": "ليس لديك الصلاحية الكافية لإستعراض هذا العنصر",
      "atributtes_add_error_4001": "لا يمكن حذف الخاصية لانها محددة على انها مضافة",
      "download_file": "There was an error downloading the file. Please try again later.",
      "atributtes_add_error_4000": "هذه الخاصية إلزامية "
    },
    "warning": {
      "user_group_must_have_users": "يجب أن تحتوي مجموعة المستخدمين على مستخدم واحد على الأقل",
      "no_selection": "يرجى اختيار عنصر واحد على الأقل",
      "missing_info": "الرجاء التحقق من المعلومات",
      "missing_map_id": "لا يوجد خطة لهذه الدورة",
      "missing_connections": "الرجاء اختيار عنصر واحد على الأقل للربط",
      "missing_data": "البيانات غير متوفرة",
      "required_field": "يرجى إدخال البيانات في الحقل",
      "fileSize": "حجم الملف",
      "sponsor_other_entity": "الجهة المسؤولة الأخرى غير متوفرة",
      "password_must_field": "يجب ان يتم إدخال جميع حقول كلمة المرور",
      "passwords_must_match": "كلمة المرور غير متطابقة",
      "passwordMinimum": "يجب أن تكون كلمة المرور سبعة أحرف على الأقل",
      "required_field_user_profile": "الرجاء إدخال رقم الهاتف أو أسئلة الأمان",
      "no_data_to_modify": "لا توجد تغييرات",
      "invalid_email": "البريد الإلكتروني غير صحيح",
      "will_affect": "سيؤثر التغيير على العناصر التي يتم إنشاؤها من الان فصاعداً. العناصر الموجودة لن تتأثر بالتغيير",
      "draft_subkpis": "يجب تفعيل المؤشرات الفرعية",
      "date_change_check_actuals": "تم تعديل التواريخ، يرجى التحقق من القيم الفعلية",
      "repeated_usergroup": "تم اختيار هذه المجموعة مسبقاً للإعدادات",
      "no_usergroups": "يرجى اختيار مجموعة مستخدمين للإعدادات",
      "only_numbers": "يمكنك إدخال قيمة رقمية فقط في هذا الحقل",
      "formula_validation": "التحقق من معادلة القياس",
      "formula_activated": "تم تفعيل معادلة القياس",
      "formula_diactivated": "تم إالغاء تفعيل معادلة القياس",
      "formula_statuschanged": "تم تغيير حالة معادلة القياس بنجاح",
      "text_filter_length": "يجب إدخال 3 أحرف على الأقل",
      "text_search_length": "يجب أن تحتوي كلمة البحث على ثلاثة أحرف على الأقل",
      "n_a": "القيمة غير موجودة"
    },
    "success": {
      "deactivate": "غير مفعل",
      "activate": "مفعل",
      "save": "تم حفظ المعلومات",
      "remove": "تم حذف المعلومات",
      "last": "لقد قمت بحذف اَخر مؤشر تشغيلي مرتبط بالنشاط",
      "upload_complete": "اكتمل التحميل"
    },
    "no_objectives": {
      "missing_objectives": "لاتوجد أهداف للخطة والجهة المحددين"
    },
    "no_changes": "لا توجد تغيرات"
  },
  "userBar": {
    "profile": "الملف الشخصي",
    "contacts": "بيانات الاتصال",
    "mailbox": "البريد",
    "logout": "تسجيل الخروج"
  },
  "navigation": {
    "dashboard": "Dashboard STG",
    "menu": "عرض الخطة",
    "home": "الصفحةالرئيسية",
    "console": "وحدة التحكم",
    "tree_view": "طريقة عرض الشجرة",
    "list_view": "طريقة عرض القائمة",
    "plan_wizard": "إنشاء الخطة",
    "auditing": "التدقيق",
    "reporting": "التقارير",
    "messages": "مركز الإشعارات",
    "help": "مركز المساعدة",
    "send_email": "إرسال بريد إلكتروني",
    "plan_navigation": "عرض الخطة",
    "menu_name": "مركز الإشعارات"
  },
  "targets": {
    "periods": {
      "quarters": {
        "1": "ربع سنوي1",
        "2": "ربع سنوي 2",
        "3": "ربع سنوي 3",
        "4": "ربع سنوي 4"
      },
      "semestral": {
        "1": "نصف سنوي 1",
        "2": "نصف سنوي 2"
      }
    },
    "quarter": "ربع سنوي",
    "semiannual": "نصف سنوي",
    "annual": "سنوي",
    "every_two_years": "كل سنتين",
    "every_three_years": "كل ثلاث سنوات",
    "every_four_years": "كل أربع سنوات",
    "every_five_years": "كل خمس سنوات"
  },
  "data_entry": {
    "has_audit_issues": "ملاحظة تدقيق",
    "audited_data_entry_title": "إدخال القيم المدققة",
    "annual_actual": "المحقق السنوي",
    "annual_target": "المستهدف السنوي",
    "audited_annual_actual": "المحقق السنوي المدقق",
    "calc_target": "المستهدف المحتسب",
    "calc_actual": "القيمة الفعلية المحتسبة",
    "multiple_files": "المرفقات",
    "kpi_type_name": "نوع المؤشر",
    "metric_description": "الوصف (بالإنجليزية)",
    "metric_name": "اسم عنصر القياس",
    "kpi_name": "اسم المؤشر",
    "kpi_type": "نوع المؤشر",
    "linked_to": "مرتبط ب",
    "period": "التاريخ",
    "frequency": "دورية القياس",
    "percentage": "نسبة اكتمال القيم الفعلية",
    "performance": "الأداء",
    "target": "المستهدف",
    "value": "القيمة",
    "formula": "معادلة القياس",
    "objective_name": "اسم الهدف",
    "sponsor": "المنسق",
    "score": "النتيجة",
    "areas_of_improvement": "فرص التحسين",
    "recommendations": "التوصيات",
    "analysis": "التحليل",
    "metrics": "بيانات القياس",
    "kpi_actuals": "القيم الفعلية للمؤشرات",
    "edit_actuals": "تعديل القيم الفعلية",
    "description": "الوصف",
    "activity_name": "اسم النشاط",
    "initiative_name": "اسم المبادرة",
    "previous": "السابق",
    "actual": "الفعلي",
    "status": "الحالة",
    "analysisEN": "التحليل (بالإنجليزية)",
    "analysisAE": "التحليل (بالعربية)",
    "recomendationsEN": "التوصيات (بالإنجليزية)",
    "recomendationsAE": "التوصيات (بالعربية)",
    "areasOfImprovementsEN": "فرص التحسين (بالإنجليزية)",
    "areasOfImprovementsAE": "فرص التحسين (بالعربية)",
    "approved": "معتمد",
    "due": "المطلوب حاليا ادخاله",
    "toBeApproved": "قيد الموافقة",
    "actuals": "القيم الفعلية",
    "cannotAddMessage": "توجد قيمة لهذه الفترة",
    "metric_value_delete_yes_no_title": "حذف القيمة",
    "metric_value_delete_yes_no_information": "أنت على وشك حذف قيمة عنصر القياس",
    "metric_add_edit_title": "تنبيه!",
    "metric_add_edit_information": "أنت على وشك تعديل قيم عنصر المقاييس للعديد من المؤشرات، تبعا لذلك سيؤدي هذا الإجراء لتحديث بيانات القيم الفعلية لهذه المؤشرات وإعادة احتساب قيم الأداء لها.هل تريد المتابعة؟",
    "edit_value": "تعديل القيمة",
    "enter_value": "القيمة",
    "overdue": "متأخرة",
    "pillar_name": "المحور",
    "no_periods": "لا توجد فترة للتواريخ التي تم اختيارها",
    "metrics_title": "عناصر القياس",
    "govdir": "اسم التوجه الحكومي الفرعي",
    "subService_name": "اسم الخدمة الفرعية",
    "mainService_name": "اسم الخدمة الرئيسية",
    "parent_KPI": "المؤشر الرئيسي",
    "sponsor_other": "جهة مسؤولة أخرى",
    "max_textarea_chars": "الحد الأقصى للأحرف و الرموز التي يمكن إدخالها هو 3000",
    "attachment_data_entry": "إدخال النتائج ",
    "value_empty": "لا توجد قيمة",
    "notifications": {
      "CODE_27": "تم إرسال الطلب للموافقة",
      "DONE_OK_01": "تم الاحتساب بنجاح",
      "DONE_OK_02": "تم الاحتساب بنجاح",
      "DONE_ERR_01": "لا توجد قيم فعلية",
      "DONE_ERR_02": "لا توجد مستهدفات",
      "DONE_ERR_03": "لا توجد مستهدفات وقيم فعلية",
      "NOT_PROCESSED_01": "لم يتم الاحتساب بعد",
      "NOT_PROCESSED_02": "لم يتم الاحتساب بعد",
      "ERR_01": "خطأ في الاحتساب",
      "NO_PROCESS_INFO": "لم يتم الاحتساب",
      "no_actual": "لا توجد قيمة فعلية",
      "no_target": "لا توجد قيمة للمستهدف"
    },
    "q1": "الربع الأول",
    "q2": "الربع الثاني",
    "q3": "الربع الثالث",
    "q4": "الربع الرابع",
    "calc": "القيمة المحتسبة",
    "actual_target": "Actuals & Targets",
    "target_upper_limit": "الحد الأعلى",
    "target_lower_limit": "الحد الأدنى",
    "edit_title": "إدخال القيم الفعلية",
    "show_dimension": "الخيارات",
    "show_targets": "القيم المستهدفة",
    "show_calculation": "الاحتساب",
    "metric_approved": "معتمد",
    "metric_under_approval": "قيد الاعتماد",
    "no_dimensions": "لا توجد أبعاد لهذا المؤشر"
  },
  "breadcrumbs": {
    "audit_issue": "ملاحظات التدقيق",
    "rule_management": {
      "view": " عرض القاعدة العملية",
      "edit": "تعديل القاعدة العملية",
      "add": "إنشاء قاعدة عملية جديدة"
    },
    "contextual_help": {
      "view": "عرض المساعدة السياقية",
      "edit": "تعديل المساعدة السياقية",
      "add": "إنشاء مساعدة سياقية جديدة",
      "list": "المساعدة السياقية"
    },
    "faqs_category": {
      "list": "فئات الأسئلة الأكثر تكراراً",
      "view": "عرض الأسئلة الأكثر تكراراً",
      "edit": "تعديل فئة للأسئلة الأكثر تكراراً",
      "new": "إضافة فئة جديدة للأسئلة الأكثر تكراراً"
    },
    "faqs": {
      "list": "الأسئلة الأكثر تكراراً",
      "view": "عرض سؤال أكثر تكراراً",
      "edit": "تعديل سؤال أكثر تكراراً",
      "new": "إنشاء سؤال أكثر تكراراً جديد",
      "faqs-search": "الأسئلة الأكثر تكراراً"
    },
    "smart-tool-tips": {
      "list": "الرسائل التوضيحية",
      "edit": "تعديل الرسالة التوضيحية",
      "invalid_text": "نص الرسالة التوضيحية غير صحيح"
    },
    "formulas": {
      "view": "عرض قالب المعادلة ",
      "edit": "تعديل قالب المعادلة",
      "new": "إضافة قالب معادلة جديد"
    },
    "exec_team": {
      "view": "عرض الفريق التنفيذي",
      "list": "الفرق التنفيذية",
      "edit": "تعديل الفريق التنفيذي",
      "new": "إضافة فريق تنفيذي جديد"
    },
    "ekpi_module": {
      "view": "عرض مؤشر ممكنات حكومية",
      "list": "مؤشرات الممكنات الحكومية",
      "edit": "تعديل مؤشر ممكنات حكومية",
      "new": "إنشاء مؤشر ممكنات حكومية جديد"
    },
    "srvkpi_module": {
      "view": "عرض مؤشر الخدمة",
      "list": "مؤشرات الخدمات",
      "edit": "تعديل مؤشرات الخدمات",
      "new": "إنشاء مؤشرات الخدمات"
    },
    "opm_module": {
      "view": "عرض المؤشر التشغيلي",
      "list": "المؤشرات التشغيلية",
      "edit": " تعديل مؤشر تشغيلي",
      "new": "إنشاء مؤشر تشغيلي جديد"
    },
    "dkpi_module": {
      "view": "عرض مؤشر التوجه الحكومي",
      "list": "مؤشرات التوجهات الحكومية",
      "edit": "تعديل مؤشر التوجه الحكومي",
      "new": "مؤشر توجه حكومي جديد"
    },
    "nkpi_module": {
      "view": "عرض المؤشر الوطني",
      "list": "المؤشرات الوطنية",
      "edit": "تعديل مؤشر وطني",
      "new": "إنشاء مؤشر وطني جديد"
    },
    "skpi_module": {
      "view": "عرض المؤشر الاستراتيجي",
      "list": "المؤشرات الاستراتيجية",
      "edit": " تعديل مؤشر استراتيجي",
      "new": "إنشاء مؤشر استراتيجي جديد"
    },
    "activity": {
      "view": "عرض النشاط",
      "list": "الأنشطة",
      "new": " إنشاء نشاط جديد",
      "edit": "تعديل النشاط"
    },
    "initiative_module": {
      "view": "عرض المبادرة",
      "list": "المبادرات",
      "new": "إنشاء مبادرة جديدة",
      "edit": "تعديل المبادرة"
    },
    "help_desk_module": {
      "view": "عرض تذكرة الدعم الفني",
      "list": "مركز المساعدة",
      "new": "إنشاء تذكرة الدعم الفني",
      "edit": "تعديل تذكرة الدعم الفني"
    },
    "attributes_module": {
      "view": "عرض الخاصية",
      "list": "الخصائص",
      "new": "إنشاء خاصية جديدة",
      "edit": "تعديل الخاصية"
    },
    "dimensions_module": {
      "view": "عرض البعد",
      "list": "الأبعاد",
      "new": "إنشاء بعد جديد",
      "edit": "تعديل البعد"
    },
    "directions_module": {
      "view": "عرض التوجه الحكومي",
      "list": "التوجهات الحكومية",
      "new": "إنشاء توجه حكومي جديد",
      "edit": "تعديل التوجه الحكومي"
    },
    "entity_module": {
      "view": "عرض الجهة",
      "list": "الجهات",
      "new": "إضافة جهة",
      "edit": "تعديل الجهة"
    },
    "cycle_module": {
      "view": "عرض الدورة الاستراتيجية",
      "list": "الدورات الاستراتيجية",
      "new": "إنشاء دورة جديدة",
      "edit": "تعديل الدورة الاستراتيجية"
    },
    "pillar_module": {
      "view": "عرض المحور",
      "list": "المحاور",
      "new": "إضافة محور جديد",
      "edit": "تعديل المحور"
    },
    "wf_monitoring_module": {
      "list": "مراقبة سير العمل"
    },
    "wf_trigger_module": {
      "view": "عرض مشغل سير العمل",
      "list": "مشغلات سير العمل",
      "new": "إنشاء مشغل سير عمل جديد",
      "edit": "تعديل مشغل سير العمل"
    },
    "plan_review_module": {
      "list": "مراجعة وموافقة الخطة"
    },
    "translation_module": {
      "view": "الترجمة"
    },
    "audit_trail_module": {
      "list": "سجلات النظام"
    },
    "audit_data_module": {
      "kpi_actuals": "تدقيق القيم الفعلية للمؤشرات",
      "sub_menu": "التدقيق"
    },
    "reporting": "التقارير",
    "user_manual": "دليل المستخدم",
    "user_profile": "الملف الشخصي",
    "console": "وحدة التحكم",
    "users": "إدارة المستخدمين",
    "access_profiles": "ملف التعريف",
    "access-profiles": "ملف التعريف",
    "access_profiles_new": "إنشاء ملف التعريف",
    "access_profiles_edit": "تعديل ملف التعريف",
    "entities": "الجهات",
    "metrics": "عناصر القياس",
    "dimensions": "الأبعاد",
    "attributes": "الخصائص",
    "workflow_triggers": "مشغلات سير العمل",
    "sworkflow_triggers_detail": "إنشاء مشغل سير عمل جديد",
    "enablerobjectives": {
      "view": "عرض الهدف الممكن",
      "list": "الأهداف الممكنة",
      "new": "إنشاء هدف ممكن جديد",
      "edit": "تعديل الهدف الممكن"
    },
    "objectives": {
      "edit": "تعديل الهدف",
      "objectives": "الأهداف",
      "detail": "التفاصيل",
      "create": "إضافة هدف جديد",
      "list": "قائمة الأهداف",
      "key_success_factors": "عوامل النجاح الأساسية"
    },
    "objective": "الهدف",
    "enablerobjective": "الأهداف الممكنة",
    "initiatives": "المبادرات",
    "initiative": "المبادرة",
    "new_initiative": "إنشاء مبادرة جديدة",
    "activities": "الأنشطة",
    "kpi": "المؤشرات",
    "nkpi": "المؤشرات الوطنية",
    "new_nkpi": "مؤشر وطني",
    "skpi": "المؤشرات الاستراتيجية",
    "ekpi": "مؤشرات الممكنات الحكومية",
    "uae": "مؤشرات التوجهات الحكومية",
    "opm": "المؤشرات التشغيلية",
    "directions": "التوجهات الحكومية",
    "cycles": "الدورات الاستراتيجية",
    "pillars": "المحاور",
    "mandatory": "الحقول الإلزامية",
    "services": {
      "expenditures": "المصروفات",
      "edit_variation_service": "تعديل الخدمة الفرعية",
      "edit_auxiliary_service": "تعديل الخدمة التكميلية",
      "edit_main_service": "تعديل الخدمة الرئيسية",
      "add_variation_service": "إضافة خدمة فرعية",
      "add_auxiliary_service": "إضافة خدمة تكميلية",
      "add_main_service": "إضافة خدمة رئيسية",
      "variation_service": "الخدمة الفرعية",
      "auxiliary_service": "تكميلية",
      "main_service": "الخدمة الرئيسية",
      "list": "الخدمات"
    },
    "data-entry": "إدخال النتائج ",
    "executive-teams": "الفرق التنفيذية",
    "provisioning": "بيانات الجهة",
    "dashboard": "لوحة العرض",
    "communications": "مركز الإشعارات",
    "detail": "التفاصيل",
    "kpis": {
      "kpi": "المؤشر",
      "national": "المؤشر الوطني",
      "create_national": "إضافة مؤشر وطني",
      "strategic": "المؤشرات الاستراتيجية",
      "create_strategic": "إنشاء مؤشر إستراتيجي"
    },
    "mandatory_fields": "الحقول الإلزامية",
    "create_strategic": "إنشاء مؤشر استراتيجي",
    "short_opm": "المؤشرات التشغيلية",
    "enabler": "المؤشر الممكن",
    "workflow_profiles": "ملفات تعريف سير العمل ",
    "create": "إنشاء",
    "Formulas": "قوالب المعادلات ",
    "help_modal": "المساعدة",
    "search": "بحث",
    "srvkpi": "مؤشرات الخدمات",
    "list": "القائمة",
    "edit": "تعديل",
    "new": "إنشاء",
    "khadamati": {
      "title": "التكامل مع خدماتي",
      "list": "سجل المزامنة",
      "schedule": "جدول المزامنة"
    },
    "metric_edit": "تعديل عنصر القياس",
    "edit_service": "تعديل خدمة",
    "edit_user": "تعديل المستخدم",
    "user_related_objects": "العناصر ذات الصلة",
    "edit_user_roles": "تعديل دور المستخدمين",
    "edit_user_groups": "تعديل مجموعة المستخدمين",
    "edit_help_modal": "شاشة تعديل المساعدة",
    "new_user": "إنشاء مستخدم جديد",
    "new_user_role": "إنشاء دور مستخدمين جديد",
    "new_user_group": "إنشاء مجموعة مستخدمين جديدة",
    "new_dkpi": "مؤشر توجه حكومي جديد",
    "edit_dkpi": "تعديل مؤشر التوجه الحكومي",
    "view_dimension": "عرض البعد ",
    "view_metric": "عرض عنصر القياس",
    "view_entity": "عرض الجهة",
    "view_attribute": "عرض الخاصية",
    "view_initiative": "عرض المبادرة",
    "view_enabler_objectives": "عرض الهدف الممكن",
    "view_objective": "عرض الهدف",
    "view_activity": "عرض النشاط",
    "view_nkpi": "عرض المؤشر الوطني",
    "view_skpi": "عرض المؤشر الاستراتيجي",
    "view_ekpi": "عرض مؤشر الممكن الحكومي",
    "view_opm": "عرض المؤشر التشغيلي",
    "view_dkpi": "عرض مؤشر التوجه الحكومي",
    "view_srvkpi": "عرض مؤشر الخدمة",
    "view_direction": "عرض التوجه الحكومي",
    "view_var_service": "عرض الخدمة الفرعية",
    "view_aux_service": "عرض الخدمة التكميلية",
    "view_main_service": "عرض الخدمة الرئيسية",
    "view_service": "عرض الخدمة",
    "view_cycle": "عرض الدورة الاستراتيجية",
    "view_pillar": "عرض المحور",
    "view_exec_team": "عرض الفريق التنفيذي",
    "view_user_group": "عرض مجموعة المستخدمين",
    "view_user_role": "عرض دور المستخدمين",
    "view_user": "عرض المستخدم",
    "view_helpdesk": "عرض تذكرة الدعم الفني",
    "notifications": "إدارة الإشعارات",
    "user": {
      "user": "المستخدمين",
      "profile": "الملف الشخصي"
    },
    "delivery_module": {
      "title": "متابعة تنفيذ المبادرات",
      "view": "متابعة تنفيذ المبادرات",
      "edit": "متابعة تنفيذ المبادرات"
    },
    "text-mapping": "تصنيف النتائج الحرفية",
    "text_mappings_create": "إنشاء تصنيف جديد",
    "text_mappings_edit": "تعديل التصنيف",
    "text_mappings_view": "عرض التصنيف",
    "helpdesk_add": "إنشاء تذكرة دعم فني",
    "edit_mandatory_fields": "تعديل الحقول الإلزامية",
    "plan_import_export": "تنزيل / تحميل الخطة",
    "workflow": {
      "edit_assignment": "تعديل سير العمل",
      "add_assignment": "إنشاء سير عمل جديد",
      "view_assignment": "عرض سير العمل",
      "edit_trigger": "تعديل مشغل سير العمل",
      "add_trigger": "إنشاء مشغل سير العمل",
      "view_trigger": "تعديل مشغل سير العمل"
    },
    "import_export": "تحميل / تنزيل الخطة",
    "kpi_annual_weights": "الأوزان السنوية للمؤشرات"
  },
  "leftbar": {
    "copy_right": "جميع الحقوق محفوظة",
    "dashboard": "لوحة العرض",
    "quick_links": "الروابط سريعة",
    "objectives": "الأهداف",
    "reporting": "التقارير",
    "model": "النموذج",
    "messages": "الرسائل",
    "help": "المساعدة",
    "hello": "مرحباً",
    "search": "بحث",
    "pmo": "مكتب رئاسة مجلس الوزراء",
    "administrator": "مدير النظام",
    "metrics": "عناصر القياس",
    "datasources": "مصادر البيانات",
    "milestones": "المراحل",
    "kpitypes": "أنواع المؤشرات"
  },
  "help": {
    "faqs": {
      "title": "الأسئلة الأكثر تكراراً",
      "answerAE": "الجواب (بالعربية)",
      "answerEN": "الجواب (بالإنجليزية)",
      "questionAE": "السؤال (بالعربية)",
      "questionEN": "السؤال (بالإنجليزية)",
      "icon": "الصورة",
      "onlineHelpLinkAE": "رابط دليل استخدام النظام (بالعربية)",
      "onlineHelpLinkEN": "رابط دليل استخدام النظام (بالإنجليزية) ",
      "videoLinkAE": "رابط الفيديو (بالعربية)",
      "videoLinkEN": "رابط الفيديو (بالإنجليزية)",
      "published": "نشر ",
      "delete_yes_no_title": "تأكيد حذف الأسئلة الأكثر تكراراً ",
      "delete_yes_no_information": "أنت على وشك حذف السؤال",
      "upload_tool_tip": "الرجاء اختيار صورة من نوع svg"
    },
    "faqs-category": {
      "title": "فئات الأسئلة الأكثر تكراراً",
      "edit_category": "تعديل فئة للأسئلة الأكثر تكراراً",
      "add_new_category": "إضافة فئة جديدة للأسئلة الأكثر تكراراً",
      "delete_yes_no_title": "تأكيد حذف  فئة للأسئلة الأكثر تكراراً",
      "delete_yes_no_information": "أنت على وشك حذف الفئة",
      "cancel_yes_no_title": "تأكيد الإلغاء",
      "cancel_yes_no_information": "أنت على وشك الإلغاء"
    },
    "help_modal": {
      "delete": {
        "delete_yes_no_information": "أنت على وشك حذف المساعدة السياقية",
        "delete_yes_no_title": "تأكيد حذف المساعدة السياقية"
      },
      "title": "المساعدة السياقية",
      "page_content_en": "(محتوى الصفحة (بالإنجليزية",
      "page_content_ae": "(محتوى الصفحة (بالعربية",
      "section_dscAE": "محتوى الصفحة (بالعربية)",
      "section_dscEN": "محتوى الصفحة (بالإنجليزية)",
      "section_titleEN": "عنوان الصفحة (بالإنجليزية)",
      "section_titleAE": "عنوان الصفحة (بالعربية)",
      "section_publish": "نشر الصفحة",
      "imageAE": "رابط الصورة (بالعربية)",
      "imageEN": "رابط الصورة (بالإنجليزية)",
      "image_only": "صورة فقط",
      "page_title": "اسم الصفحة ",
      "page_description": "محتوى الصفحة",
      "image_path": "رابط الصورة ",
      "contextual_published": "نشر المساعدة السياقية",
      "video_en_url": "رابط الفيديو (بالإنجليزية)",
      "video_ae_url": "رابط الفيديو (بالعربية)",
      "page_name": "اسم الصفحة ",
      "add_step": "إضافة محتوى",
      "edit_step": "تعديل المحتوى"
    },
    "contextual_help": "المساعدة السياقية",
    "help": "المساعدة",
    "smart-tool-tips": "الرسائل التوضيحية",
    "user-manual": "دليل المستخدم"
  },
  "topbar": {
    "help": {
      "context": "المساعدة السياقية",
      "guided": "الجولة الإرشادية",
      "faq": "الأسئلة الأكثر تكراراً",
      "entitymanual": "دليل المستخدم",
      "usermanual": "دليل المستخدم - PDF"
    },
    "pmo": "مكتب رئاسة مجلس الوزراء",
    "control_panel": "لوحة التحكم"
  },
  "activities": {
    "cancel_yes_no_title": "تأكيد إلغاء النشاط",
    "cancel_yes_no_information": "هل أنت متأكد من إلغاء النشاط؟",
    "title": "الأنشطة",
    "activity": "النشاط",
    "department": "الوحدة تنظيمية",
    "risks": "المخاطر",
    "risk": "الخطر",
    "addRisk": "إضافة المخاطر",
    "impact": "الأثر",
    "name": "النشاط",
    "delete_yes_no_title": "تأكيد حذف النشاط",
    "delete_yes_no_information": "أنت على وشك حذف النشاط",
    "expenses": "المصروفات",
    "variances": "التباين",
    "workload": "وزن النشاط",
    "hyperiondRefCode": "مرجع النظام المالي",
    "budget": "الميزانية",
    "objective": "الهدف",
    "activity_canceled": "تم إلغاء النشاط! ",
    "cancel_activity": "إلغاء النشاط"
  },
  "dashboard": {
    "strategies_directions_performance": "أداء الاستراتيجيات الوطنية",
    "initiatives_progress": "إنجاز المبادرات",
    "investment_per_objective": "الاستثمار لكل هدف",
    "updates": "التحديثات",
    "pillars": {
      "min": "أقل قيمة",
      "max": "أعلى قيمة",
      "last": "آخر قيمة",
      "sum": "المجموع"
    }
  },
  "add": "إضافة",
  "attributes": {
    "lov": "قيمة",
    "add": "إضافة",
    "add_new_lov": "إضافة قيمة جديدة",
    "create_new": "خاصية جديدة",
    "attributes": "الخصائص",
    "create": {
      "information": "المعلومات",
      "add_button_list": "إنشاء خاصية جديدة",
      "list": "القائمة",
      "title": "إضافة خصائص جديدة",
      "list_of_values": "قائمة القيم",
      "attribute": "الخاصية",
      "item_type": "نوع العنصر",
      "data_type": "نوع البيانات",
      "delete_yes_no_title": "هل أنت متأكد",
      "delete_yes_no_information": "تأكيد حذف الخاصية"
    }
  },
  "entity": {
    "strategic_manager": "مدير الاستراتيجية",
    "nkpi_focal_point": "منسق المؤشرات الوطنية",
    "ekpi_focal_point": "منسق مؤشرات الممكنات",
    "services_focal_point": "منسق مؤشرات الخدمات",
    "dkpi_focal_point": "منسق مؤشرات التوجهات الحكومية",
    "skpiopm_focal_point": "منسق المؤشرات الاستراتيجية والتشغيلية",
    "max_file_size": "الحد الأقصى لحجم الملف هو 2048 كيلو بايت ",
    "files_allowed": "أنواع المرفقات المتاحة PNG, GIF, JPEG, SVG, BMP",
    "opm_focal_point": "منسق المؤشرات التشغيلية",
    "strategic_focal_point": "منسق المؤشرات الاستراتيجية ",
    "strategic_team": "فريق الأداء الاستراتيجي",
    "nkpi_team": "فريق الأجندة الوطنية ",
    "dkpi_team": "فريق التوجهات الحكومية",
    "ekpi_team": "فريق الممكنات الحكومية",
    "services_team": "فريق الخدمات الحكومية",
    "opm_team": "فريق المؤشرات التشغيلية",
    "create": {
      "title": "جهة جديدة",
      "pillar_entity": "الحالة و المحور",
      "information": "المعلومات",
      "list": "الجهات",
      "department_unit": "الإدارة",
      "people": "الأشخاص",
      "is_pillar": "جهة ممكنة",
      "add_button_list": "إضافة جهة",
      "type": "النوع",
      "entity": "الجهة",
      "geo_location": "الموقع الجغرافي",
      "shortNameAE": "الاسم المختصر (بالعربية)",
      "shortNameEN": "الاسم المختصر (بالإنجليزية)",
      "add_new_address": "موقع جديد",
      "federal": "إتحادي",
      "enabler": "ممكن",
      "add_entity_logo": "إضافة شعار الجهة",
      "address_arabic": "العنوان (بالعربية)",
      "address_english": "العنوان (بالإنجليزية)",
      "zip_code_arabic": "صندوق البريد",
      "zip_code_english": "صندوق البريد (بالإنجليزية)",
      "city_arabic": "المدينة (بالعربية)",
      "city_english": "المدينة (بالإنجليزية)",
      "geo_x": "خط الطول",
      "geo-y": "دائرة العرض",
      "entity_offices": "مكاتب الجهات",
      "pillars": "المحاور",
      "hq": "المقر الرئيسي",
      "delete_yes_no_title": "تأكيد حذف الجهة",
      "delete_yes_no_information": "أنت على وشك حذف الجهة",
      "add_new_user": "إضافة مستخدم جديد",
      "add_new_org_unit": "إضافة وحدة تنظيمية جديدة",
      "org_unit": "الوحدة التنظيمية",
      "nameAE": "الاسم (بالعربية)",
      "nameEN": "الاسم (بالإنجليزية)",
      "part_of": "جزء من",
      "level": "المستوى التنظيمي",
      "manager": "المدير",
      "entity_type": "نوع الجهة",
      "logo_horizontal": "الشعار الأفقي",
      "logo_vertical": "الشعار الرأسي",
      "shortName": "الاسم المختصر",
      "horizontal_logo_guidelines": "يجب أن يكون حجم الشعار ببعد (820 عرض,129 ارتفاع) بيكسل",
      "vertical_logo_guidelines": "يجب أن يكون حجم الشعار ببعد (129 عرض,820 ارتفاع) بيكسل",
      "edit_org_unit": "تعديل الوحدة التنظيمية"
    }
  },
  "lov": {
    "value_delete_yes_no_information": "أنت على وشك حذف القيمة",
    "value_delete_yes_no_title": "هل أنت متأكد؟"
  },
  "user": {
    "create": {
      "title": "المستخدمين",
      "add_button_list": "إضافة مستخدم جديد",
      "delete_yes_no_title": "تأكيد حذف المستخدم",
      "delete_yes_no_information": "أنت على وشك حذف المستخدم",
      "add_role": "إضافة دور",
      "add_group": "إضافة مجموعة",
      "roles": "الأدوار",
      "groups": "المجموعات",
      "responsibleFor": "نطاق المسؤولية (الجهات)",
      "entity": "الجهة",
      "add_responsibleFor": "تعيين الجهات",
      "modal": {
        "role": {
          "title": "دور المستخدم",
          "organizationunit": "الوحدة التنظيمية",
          "avaliableroles": "الأدوار المتاحة ",
          "userroles": "أدوار المستخدمين"
        },
        "group": {
          "title": "مجموعة المستخدمين"
        },
        "responsible": {
          "title": "مسؤول عن",
          "responsibleFor": "مسؤول عن"
        }
      }
    },
    "pmo_privileges": {
      "label": "صلاحيات مكتب رئاسة مجلس الوزراء"
    },
    "access_profiles": {
      "access_profile": "ملف التعريف",
      "modal_title": "إضافة ملف التعريف",
      "add_more": "إضافة المزيد"
    },
    "console": "إدارة المستخدمين"
  },
  "people": {
    "title": "الأشخاص",
    "add_button_list": "إضافة شخص جديد"
  },
  "department_unit": {
    "title": "إدارة",
    "add_button_list": "إنشاء إدارة جديدة"
  },
  "dimension": {
    "label_name_exists": "تم اختيار نفس الاسم مسبقاً. يرجى استخدام اسم آخر",
    "show_more": "عرض المزيد",
    "no_formula": "لا توجد معادلة",
    "dimension": "الأبعاد",
    "available": "متاح",
    "create": {
      "title": "الأبعاد",
      "information": "المعلومات",
      "list": "الأبعاد",
      "dimensions_name_labels": "اسم البعد",
      "add_more": "إضافة المزيد",
      "add_button_list": "إنشاء بعد جديد",
      "type": "النوع",
      "entity": "اسم الجهة",
      "geo_location": "الموقع الجغرافي",
      "delete_yes_no_title": "تأكيد حذف البعد",
      "delete_yes_no_information": "أنت على وشك حذف البعد",
      "value_type": "نوع القيمة",
      "applicable_to": "ينطبق على",
      "new_dimension_value": "إضافة قيمة جديدة"
    },
    "success": {
      "remove": "حذف"
    },
    "navigation": "عرض الخطة"
  },
  "metrics": {
    "metrics_not_available": "لا توجد عناصر قياس",
    "metrics_information_missing": "عنصر القياس مفقود",
    "metrics_selections": "اختيار عنصر القياس",
    "global": "عنصر قياس مشترك (حكومي)",
    "local": "عنصر قياس (الجهة)",
    "showError": {
      "dots": " يجب أن يحتوي اسم عنصر القياس على أحرف وأرقام فقط"
    },
    "type": {
      "public": "مشترك (الحكومة)",
      "local": "مشترك (الجهة)"
    },
    "metrics": "عناصر القياس",
    "title": "عناصر القياس",
    "information": "المعلومات",
    "list": "قائمة عناصر القياس",
    "add_button_list": "إنشاء عنصر قياس جديد",
    "total_found": "عناصر القياس الموجودة",
    "total_found_singular": "عناصر القياس الموجودة",
    "local_var": "عناصر القياس",
    "global_var": "عنصر قياس مشترك (حكومي)",
    "global_local_var": "عنصر قياس مشترك / محلي",
    "custom_var": "عناصر المقاييس المخصصة",
    "label_name": "عناصر القياس",
    "label_source": "المصدر",
    "label_description": "الوصف",
    "label_entity": "الجهة",
    "label_type": "نوع عنصر القياس",
    "label_data_type": "نوع البيانات",
    "delete_yes_no_title": "تأكيد حذف عنصر القياس",
    "delete_yes_no_information": "أنت على وشك حذف عنصر القياس",
    "values_and_targets": "القيم وعناصر القياس",
    "values_and_targets_dates": "الرجاء إختيار التردد وتاريخ البدء والإنتهاء",
    "no_information": "لا توجد معلومات",
    "frequency": "دورية القياس",
    "available": "متاح",
    "create": {
      "add_button_list": "إنشاء عنصر قياس"
    },
    "data_source": "مصدر البيانات",
    "creation_date": "تاريخ الإنشاء",
    "metrics_used": "عنصر القياس الحالي مستخدم بواسطة عناصر أخرى:",
    "metrics_save_modal": "لا يمكنك تغيير نوع البيانات، نوع عنصر القياس، أو الجهات بسبب ارتباط عنصر القياس بالمؤشرات الاتية",
    "metrics_save_modal_title": "تأكيد حفظ عنصر القياس",
    "metrics_delete_modal": "يتوجب فك ارتباط عنصر القياس مع المؤشرات الاتية لتتمكن من حذف العنصر",
    "created_by": "إنشاء بواسطة"
  },
  "workflow_profile": {
    "title": "ملف تعريف سير العمل",
    "add_button_list": "إنشاء ملف تعريف سير العمل",
    "details": "التفاصيل",
    "operations": "العمليات",
    "grants": "الصلاحيات",
    "levels": "المراحل",
    "approval_level": "مرحلة الموافقة",
    "user": "المستخدم",
    "item_type": "النوع"
  },
  "access_profiles": {
    "title": "ملف التعريف",
    "information": "المعلومات",
    "list": "ملفات تعريف الوصول",
    "add_button_list": "إنشاء ملف التعريف",
    "allow_vision_view": "السماح بمشاهدة الرؤية ",
    "allow_item_management": "تحرير عنصر القياس",
    "allow_data_management": "تحرير بيانات عنصر القياس",
    "allow_data_visualization": "مشاهدة البيانات",
    "label_entity": "الجهة",
    "label_global_view": "نظرة عامة",
    "label_provisioning": "ملفات الجهات",
    "label_plan_view": "مشاهدة الخطة",
    "label_data_management": "إدارة البيانات",
    "label_plan_management": "خطة البيانات",
    "label_item_type": "نوع العنصر",
    "label_item": "العنصر",
    "delete_yes_no_title": "تأكيد حذف الصلاحية",
    "delete_yes_no_information": "أنت على وشك حذف دور المستخدمين",
    "access_exceptions": {
      "title": "إستثناءات الوصول",
      "delete_yes_no_title": "تأكيد حذف إستثناءات الوصول",
      "delete_yes_no_information": "أنت على وشك حذف إستثناء الوصول"
    }
  },
  "initiatives": {
    "budget_expenditures": "المصروف من الميزانية",
    "cancel_yes_no_title": "تأكيد إلغاء المبادرة",
    "cancel_yes_no_information": "هل أنت متأكد من إلغاء المبادرة؟",
    "variance": "التباين",
    "expenses": "المصروفات",
    "initiatives": "المبادرات",
    "general_information": "المعلومات عامة",
    "title": "المبادرة",
    "initiative": "مبادرة",
    "expected_result": "القيمة المتوقعة",
    "current_progress": "الإنجاز الحالي",
    "current_expected_result": "القيمة الحالية والمتوقعة",
    "organization_unit": "الوحدة التنظيمية",
    "objective": "الهدف",
    "linking_and_owner": "ربط المالك",
    "dates": "التواريخ",
    "directions": "التوجهات",
    "managers": "المديرين",
    "financial": "الميزانية",
    "assign_managers": "تعيين مدراء",
    "initiative_manager": "مدير المبادرة",
    "progress_manager": "مدير متابعة الإنجاز",
    "initiative_manager_name_ae": "(الإنجليزية) مدير المبادرة",
    "initiative_manager_name_en": "(العربية) مدير المبادرة",
    "progress_manager_name_ae": "(الإنجليزية) مدير متابعة الإنجاز",
    "progress_manager_name_en": "(العربية) مدير متابعة الإنجاز",
    "initiative_budget": "الميزانية",
    "hyperion_reference": "مرجع النظام المالي",
    "hyper": "رمز المرجع",
    "value": "القيمة",
    "is_this_main_subgov": "توجه حكومي فرعي 'رئيسي'",
    "main_subgovernment_direction": "التوجه الحكومي الفرعي الرئيسي",
    "entity": "الجهة",
    "owner": "المسؤول عن المبادرة",
    "manager": "مدير المبادرة",
    "budget": "الميزانية",
    "monitored": "متابعة التنفيذ",
    "high_priority_justification": "معايير الاختيار ",
    "monitored_kpi": "متابعة المؤشرات",
    "KPIS": "المؤشرات",
    "attachments": "المرفقات",
    "available_fields": "الحقول المتاحة",
    "added_fields": "الحقول المضافة",
    "high_priority": "ذات أولوية",
    "government_directions": "التوجهات الحكومية",
    "government_direction": "التوجه الحكومي",
    "sub_government_directions": "التوجهات الحكومية الفرعية",
    "add_subgovernment_direction": "إضافة توجه حكومي فرعي",
    "create_initiative": "إنشاء المبادرة",
    "view_initiative": "عرض المبادرة",
    "manual_input": "إدخال يدوي",
    "delete_yes_no_title": "تأكيد حذف المبادرة",
    "delete_yes_no_information": "أنت على وشك حذف المبادرة",
    "main": "رئيسي",
    "current": "الأداء الحالي",
    "year_to_period": "الأداء السنوي",
    "gov_direction_error": "الرجاء اختيار اتجاه حكومي واحد او اتجاه حكومي فرعي واحد على الأقل",
    "main_sub_gov_error": "الرجاء اختيار اتجاه حكومي فرعي كاتجاه رئيسي",
    "subgov_direction_modal_title": "اختر مسؤول الإتجاه الحكومي الفرعي الرئيسي",
    "cancel_initiative": "إلغاء المبادرة",
    "cancel_initiative_button": "إلغاء المبادرة",
    "initiative_canceled": "تم إلغاء المبادرة",
    "activity_workload": "تعديل وزن النشاط",
    "activity_workload_title": "وزن الأنشطة"
  },
  "directions": {
    "subgov_icon": "صورة التوجه الحكومي الفرعي",
    "subgov_mandatory": "التوجه الحكومي الفرعي إلزامي",
    "government_directions": "التوجهات الحكومية",
    "government_direction": "التوجه الحكومي",
    "team_owner": "الفريق المسؤول",
    "attachments": "المرفقات",
    "add_new_attachment": "إضافة مرفق جديد",
    "add_new_direction": "إنشاء توجه حكومي جديد",
    "add_new_subDirection": "إنشاء توجه حكومي فرعي جديد",
    "save_and_add_sub_directions": "إنشاء وحفظ التوجهات الفرعية",
    "add_new_kpi": "إضافة مؤشر جديد",
    "sub_government": "التوجه الحكومي الفرعي",
    "subDirectionRemove": "سوف يتم حذف التوجه الحكومي الفرعي",
    "directionRemove": "سوف يتم حذف التوجه الحكومي",
    "attachmentsRemove": "سوف يتم حذف المرفق",
    "edit_weight": "تعديل القيمة",
    "overweight_warning": "المجموع لا يمكن أن يتجاوز 100 ",
    "vision": "الرؤية",
    "identical_title": "إنشاء نفس التوجه الحكومي الفرعي",
    "main": "رئيسي",
    "identical_text": "هل ترغب في إنشاء توجه حكومي فرعي متطابق مع نفس الإسم والوصف للتوجه الحكومي الرئيسي ؟",
    "uploading_tooltip": "الرجاء اختيار صورة من نوع svg"
  },
  "subDirection": {
    "subDirection": "التوجه الحكومي الفرعي",
    "no_kpi_weight_found": "لا توجد قيمة للمؤشر",
    "no_attachments": "لا توجد ملفات متاحة"
  },
  "tasks": {
    "tasks": "المهام",
    "evidences": "المستندات الثبوتية",
    "linking_and_owner": "الربط",
    "activity": "النشاط",
    "evidence_id": "رمز المستند الثبوتي"
  },
  "services": {
    "title": "الخدمات",
    "create_title": "الخدمة الرئيسية",
    "service_kpi": "مؤشر الأداء",
    "metrics_target": "مستهدف عناصر القياس",
    "benchmarking": "المقارنة المعيارية",
    "attachments": "المرفقات",
    "extended_fields": "الحقول الإضافية",
    "is_high_priority": "ذات أولوية",
    "parent_service": "الخدمة الرئيسية",
    "service_type": "نوع الخدمة",
    "auxiliary_service:": "خدمة تكميلية",
    "auxiliary_service": "خدمة تكميلية",
    "variation_service": "خدمة فرعية",
    "variation_services": "الخدمات الفرعية",
    "auxiliary_services": "الخدمات التكميلية",
    "khadamati_id": "الرمز التعريفي في نظام خدماتي",
    "last_sync_date": "تاريخ آخر مزامنة",
    "sub_service_type": "صنف الخدمة",
    "service_classification": "تصنيف الخدمة",
    "service_channels": "قنوات الخدمات",
    "variations": "الإختلافات",
    "expenditures": "المصروفات",
    "classification": "التصنيف",
    "type": "نوع",
    "value": "قيمة",
    "expenses": "الميزانية المعتمدة",
    "variance": "المصروفات",
    "total": "التباين",
    "service": "خدمة",
    "aux_var_service": "خدمة فرعية",
    "main_service": "خدمة رئيسية"
  },
  "kpi": {
    "card": {
      "initiatives": "المبادرات",
      "card_recalculate": "إعادة الإحتساب"
    },
    "": "",
    "error": {
      "activity_date": "تاريخ البدء والانتهاء لا تتوافق مع تاريخ البدء والانتهاء للنشاط",
      "cycle_date": "تاريخ البدء والانتهاء لا تتوافق مع تاريخ البدء والانتهاء للدورة"
    },
    "baseline_attachment_title": "عرض",
    "baseline_attachment_view": "مرفق مؤشر خط الأساس",
    "linked_kpi_type": "نوع المؤشر المرتبط",
    "general_information": "المعلومات العامة",
    "attachment": "مرفق مؤشر خط أساس",
    "kpi": "المؤشر",
    "kpis": "المؤشرات",
    "subkpi": "المؤشر الفرعي",
    "metrics_target": "مستهدف عنصر القياس",
    "benchmarking": "المقارنة المعيارية",
    "attachments": "المرفقات",
    "extended_fields": "الحقول الإضافية",
    "dates": "التواريخ",
    "linking_and_owner": "المسؤول",
    "contributing_entities": "الجهات المساهمة",
    "contributing_entities_en": "الجهات المساهمة الأخرى (بالإنجليزية)",
    "contributing_entities_ae": "الجهات المساهمة الأخرى (بالعربية)",
    "guideline_measurement": "إرشادات القياس",
    "has_dimension": "له أبعاد",
    "parent_kpi": "المؤشر الرئيسي",
    "legacy_kpi": "المؤشر التاريخي",
    "behaviour": "حالة المؤشر",
    "measurement_type": "نوع القياس",
    "frequency": "دورية القياس",
    "unit": "الوحدة",
    "year_to_period": "الأداء السنوي",
    "hasTarget": "بمستهدف",
    "trend": "النمط",
    "formula_metrics": "المعادلة والمقاييس",
    "validate": "التحقق",
    "valid": "فعال",
    "validate_formula": "التحقق من معادلة القياس",
    "formula": "المعادلة",
    "baselineYear": "سنة الأساس",
    "entity": "الجهة",
    "measurement_unit": "وحدة القياس",
    "view_kpis": "عرض مؤشرات الأداء",
    "file": "الملف",
    "kpis_list": "قائمة مؤشرات الأداء",
    "translations": "الترجمة",
    "subkpis_alertMessage": "يجب اختيار مؤشر أداء واحد على الأقل",
    "subkpis_empty": "لا توجد مؤشرات أداء فرعية",
    "delete_yes_no_title": "تأكيد حذف المؤشر",
    "are_you_sure_ekpi": "هل أنت متأكد من حذف مؤشر الممكن الحكومي ؟",
    "delete_yes_no_information": "أنت على وشك حذف المؤشر",
    "delete_yes_no_information_ekpi": "سيتم حذف نسخة مؤشر الممكن الحكومي للجهة الممكنة والجهات المتأثرة.",
    "targets_in_review": "المستهدفات أو النتائج قيد الاعتماد",
    "plusbutton_add_national_kpi": "إنشاء مؤشر وطني",
    "plusbutton_add_strategic_kpi": "إنشاء مؤشر استراتيجي",
    "plusbutton_add_uae_KPI": "إنشاء مؤشر توجه حكومي",
    "plusbutton_add_enabler_KPI": "إنشاء مؤشر ممكن حكومى",
    "plusbutton_add_opm_KPI": "إنشاء مؤشر تشغيلي",
    "plusbutton_add_service_KPI": "إنشاء مؤشر خدمة جديد",
    "year1": "سنة",
    "year2": "سنتان",
    "cycle": "الدورة",
    "full_trend": "كامل الفترة",
    "value_type": "آلية القياس",
    "formula_modal_title": "اختيار قالب معادلة القياس",
    "extended_fields_modal": {
      "title": "ادخال بيانات الحقول الإضافية"
    },
    "formula_validate": "جاري التأكد من صحة المعادلة",
    "no_formula": "لا توجد معادلة للتأكد من صحتها",
    "benchmark_country_exists": "هذه الدولة موجودة بالفعل في السنه المختارة!",
    "bench_download_reports": "تحميل التقارير",
    "file_size": "حجم الملف:",
    "max_file_size": "اقصى حجم للملف:",
    "calculation_type": "آلية الاحتساب",
    "status_analysis": "تحليل الوضع",
    "areas_of_improvement": "فرص التحسين",
    "recommendations": "التوصيات",
    "pmo_notes": "ملاحظات مكتب رئاسة مجلس الوزراء",
    "govKpi": "موشر حكومي",
    "benchmark_name_exists": "اسم المقارنة المعيارية موجود",
    "optimal_target": "مستهدف الرؤية",
    "dkpi_target": "المستهدف الأمثل",
    "nkpi_target": "مستهدف الرؤية",
    "subkpis_moreThenHundred": "مجموع أوزان المؤشرات الفرعية أكثر من 100",
    "subkpis_belowHundred": "مجموع أوزان المؤشرات الفرعية أقل من 100",
    "subkpis_generic_error": "يرجى التحقق من معلومات المؤشرات الفرعية",
    "subkpis_error": "خطأ في المؤشر الفرعي",
    "subkpis_repeatedName": "هنالك مؤشر فرعي اَخر بنفس الاسم",
    "data_table": "تفاصيل المؤشر",
    "lower_limit": "الحد الأدنى",
    "targets_subkpis": "المستهدفات موجودة على مستوى المؤشرات الفرعية",
    "upper_limit": "الحد الأقصى",
    "targets_limits": "قيمة الحد الأدنى يجب أن لا تتجاوز قيمة الحد الأعلى",
    "affected_information": "واحد أو أكثر من الجهات المتأثرة تم حذفة ",
    "different_entity_title": "تغيير الجهة",
    "different_entity": "سيتم تحويلك لجهة إتحادية أخرى",
    "formula_changed": "المعادلة",
    "formula_changed_decision": "<b>تغيير المعادلة سيؤدي إلى حذف النتائج السابقة، أو إعادة احتساب المؤشر (إن توفرت البيانات المطلوبة).</b> </br>يرجى اختيار الاجراء المطلوب بناءً على تغير معادلة القياس، أو إغلاق الطلب بالضغط على علامة X",
    "formula_recalculate": "إعادة الإحتساب",
    "benchmark": "المقارنة المعيارية",
    "formula_create_new": "إنشاء مؤشر جديد",
    "table_data_title": "تفاصيل المؤشر",
    "NOFORMULAV": "لا توجد معادلة"
  },
  "nkpi": {
    "create_national_kpi": "إنشاء مؤشر وطني",
    "general_information": "معلومات عامة",
    "nkpi": "المؤشرات الوطنية",
    "sponsorEntity": "المنسق",
    "nonFederalEntityName": "الجهات الأخرى",
    "contributingEntities": "الجهات المساهمة",
    "owner": "المسؤول",
    "baseline": "خط الأساس",
    "scopeMeasurement": "نطاق القياس",
    "scopeMeasurementAE": "نطاق القياس (بالعربية)",
    "scopeMeasurementEN": "نطاق القياس (بالإنجليزية)",
    "visionTarget": "مستهدف الرؤية",
    "targetValue": "القيمة المستهدفة",
    "aggChildren": "مجموع من مؤشرات فرعية",
    "dontAggr": "من دون تجميع",
    "onlyYearlyTarget": "الهدف السنوى فقط",
    "baselineValue": "قيمة خط الأساس",
    "baselineYear": "سنة الأساس",
    "baselineReport": "ملف تقرير قيمة خط الأساس",
    "dimensions": "الأبعاد",
    "metrics": "بيانات القياس",
    "targets": "القيم المستهدفة",
    "target": "المستهدف",
    "add_new_benchmarks": "إضافة مقارنة معيارية",
    "add_new_benchmark": "إضافة مقارنة معيارية",
    "available_fields": "الحقول المتاحة",
    "added_fields": "الحقول المضافة",
    "aggDimensions": "مجموع من الأبعاد",
    "targetFormula": "مستهدف بمعادلة قياس",
    "targetValues": "القيمة المستهدفة",
    "source": "المصدر",
    "sourceAE": "المصدر",
    "sourceEN": "المصدر",
    "benchType": "النوع",
    "local": "محلي",
    "international": "دولي",
    "add_new_attachment": "إضافة مرفق",
    "attachmentType": "نوع المرفق",
    "attachment": "المرفق",
    "attachmentDate": "التاريخ",
    "operand": "المتغير",
    "measurement_unit": "وحدة القياس",
    "measurement_unit_dsc": "وصف وحدة القياس",
    "measurement_unit_dsc_ae": "وصف وحدة القياس",
    "measurement_unit_dsc_en": "وصف وحدة القياس (بالإنجليزية)",
    "no_formula_info": "معلومات معادلة القياس غير متوفرة",
    "no_formula": "لا توجد معادلة",
    "published": "مٌعلن",
    "other_entity": "جهة أخرى",
    "other_entity_ae": "جهة أخرى",
    "other_entity_en": "جهة أخرى",
    "benchYear": "السنة",
    "benchValue": "القيمة",
    "executive_Team": "الفريق التنفيذي",
    "role": "الوظيفة",
    "entity": "الجهة",
    "weight": "الوزن",
    "subkpi": "المؤشر الفرعي",
    "lowerLimit": "الحد الأدنى",
    "upperLimit": "الحد الأقصى",
    "upload_file": "إضافة ملف",
    "other": "أخرى",
    "executive_team": "الفريق التنفيذي",
    "executiveteamAE": "الفريق التنفيذي (بالعربية)",
    "executiveteamEN": "الفريق التنفيذي (بالإنجليزية",
    "data_source_entity": "البيانات المدخلة",
    "data_source_entity_source": "مصدر البيانات",
    "deleteWeightInformation": "سيتم حذف جميع مؤشرات الأداء الرئيسية الفرعية!",
    "deleteWeightQuestion": "هل أنت متأكد?",
    "trend": "النمط",
    "last_update": "آخر تحديث",
    "performance_ytp": "الأداء السنوي",
    "actual_target": "مستوى الأداء",
    "details": "تفاصيل المؤشر",
    "amount": "الكمية",
    "sub_kpi": "المؤشر الفرعي",
    "actual": "الفعلي",
    "pending_kpi": "قيد المراجعة",
    "frequency": "دورية القياس",
    "vision_target": "نسبة تحقيق مستهدف الرؤية",
    "legacy_kpi": "مؤشر الأداء التاريخي",
    "yearly": "سنوي",
    "monthly": "شهري",
    "percentage": "النسبة",
    "ytp": "الأداء السنوي",
    "performance": "الأداء الحالي",
    "attachmentName": "اسم المرفق",
    "invalid_formula": "معادلة القياس يجب أن تكون صحيحة",
    "country": "الدولة",
    "no_data_recorded": "لا توجد بيانات مسجلة",
    "edit_benchmark_value": "تعديل القيمة",
    "benchmark_delete_yes_no_title": "حذف معيار",
    "select_textmap_id": "اختر تصنيف",
    "max_rank": "مجموع الدول المشاركة",
    "benchmark_delete_yes_no_information": "أنت على وشك حذف المقارنة المعيارية",
    "is_subkpi": "لا يمكن أن يكون مؤشر فرعي لأن المؤشر مرتبط بمؤشر آخر",
    "baselineKpi": "مؤشر خط أساس",
    "linked_to_object": "لا يمكن أن يكون مؤشر فرعي لأن المؤشر مرتبط بمؤشر آخر",
    "benchmark_delete_attachment_yes_no_information": "سوف يتم حذف المرفق\nهل أنت متأكد؟",
    "benchmark_delete_attachment_yes_no_title": "مرفقات المقارنة المعيارية"
  },
  "no_formula": "لا توجد معادلة",
  "skpi": {
    "objective": "الهدف",
    "create_strategic_kpi": "إنشاء مؤشر استراتيجي",
    "no_formula": "لا توجد معادلة",
    "skpi": "المؤشرات الاستراتيجية",
    "relatedDkpi": "مؤشر توجه حكومي ذو صلة",
    "relatedNkpi": "مؤشر وطني ذو صلة"
  },
  "ekpi": {
    "ekpi": "مؤشرات الممكنات الحكومية",
    "create_enabler_kpi": "إنشاء مؤشر ممكن حكومي",
    "general_information": "معلومات عامة",
    "enablerEntity": "الجهة الممكنة",
    "nonFederalEntityName": "اسم الجهة المحلية",
    "contributingEntities": "الجهات المساهمة",
    "owner": "المسؤول",
    "objective": "الأهداف",
    "piller": "المحاور",
    "baseline": "خط الأساس",
    "scopeMeasurement": "نطاق القياس",
    "visionTarget": "مستهدف التوجه",
    "targetValue": "قيمة المستهدف",
    "aggChildren": "مجموع من مؤشرات فرعية",
    "dontAggr": "من دون تجميع",
    "onlyYearlyTarget": "الهدف سنوي",
    "baselineValue": "قيمة خط الأساس",
    "baselineYear": "سنة خط الأساس",
    "baselineReport": "تقرير خط الأساس",
    "dimensions": "الأبعاد",
    "metrics": "عنصر القياس",
    "targets": "القيم المستهدفة",
    "target": "المستهدف",
    "add_new_benchmarks": "إضافة مقارنات معيارية",
    "add_new_benchmark": "إضافة مقارنة معيارية",
    "available_fields": "الحقول المتاحة",
    "added_fields": "الحقول المضافة",
    "aggDimensions": "التجميع من الأبعاد",
    "targetFormula": "مستهدف في معادلة القياس",
    "targetValues": "القيم المستهدفة",
    "source": "المصدر",
    "benchType": "النوع",
    "local": "محلي",
    "international": "دولي",
    "add_new_attachment": "إضافة مرفق",
    "attachmentType": "النوع",
    "attachmentDate": "التاريخ",
    "operand": "المتغير",
    "measurement_unit": "وحدة القياس",
    "measurement_unit_dsc": "وصف وحدة القياس",
    "no_formula": "لا توجد معادلة",
    "no_formula_info": "معلومات معادلة القياس غير متوفرة",
    "published": "تم النشر",
    "other_entity": "جهة أخرى",
    "benchYear": "الفترة",
    "benchValue": "القيمة",
    "executive_Team": "الفريق التنفيذي",
    "role": "الوظيفة",
    "entity": "الجهة",
    "weight": "الوزن",
    "subkpi": "المؤشر الفرعي",
    "lowerLimit": "الحد الأدنى",
    "upperLimit": "الحد الأقصى",
    "upload_file": "تحميل ملف",
    "other": "أخرى",
    "data_source_entity": "البيانات المدخلة",
    "data_source_entity_source": "مصدر البيانات",
    "deleteWeightInformation": "سيتم حذف جميع مؤشرات الأداء الرئيسية الفرعية!",
    "deleteWeightQuestion": "هل أنت متأكد",
    "trend": "النمط",
    "last_update": "آخر تحديث",
    "performance_ytp": "الأداء السنوي",
    "actual_target": "مستوى الأداء",
    "details": "التفاصيل",
    "amount": "الكمية",
    "sub_kpi": "المؤشر الفرعي",
    "actual": "الفعلي",
    "pending_kpi": "قيد المراجعة",
    "frequency": "دورية القياس",
    "vision_target": "مستهدف الرؤية",
    "legacy_kpi": "مؤشر الأداء التاريخي",
    "yearly": "سنوي",
    "monthly": "شهري",
    "percentage": "النسبة",
    "ytp": "الأداء السنوي",
    "performance": "الأداء الحالي",
    "affectedEntities": "الجهات المعنية",
    "delete_yes_no_title": "هل أنت متأكد من حذف المؤشر؟",
    "delete_yes_no_information": "أنت على وشك حذف المؤشر",
    "selected_entities": "الجهات المختارة",
    "select_all_entities": "اختر كل الجهات",
    "remove_all_entities": "حذف كل الجهات",
    "data_input": "البيانات المدخلة",
    "input_actuals": "إدخال القيم الفعلية",
    "input_targets": "إدخال المستهدفات",
    "targets_approval": "موافقات المستهدفات",
    "missing_affected_entities": "لم يتم اختيار الجهات المساهمة",
    "actuals_approval": "موافقات القيم الفعلية",
    "affected_entities_selection": "اختيار ادخال بيانات الجهات المتأثرة",
    "pmo_org_unit": "الوحدة التنظيمية بمكتب رئاسة مجلس الوزراء",
    "pmo_owner": "المسؤول بمكتب رئاسة مجلس الوزراء",
    "card_view_all_data_button": "إظهار كافة القيم"
  },
  "opm": {
    "opm": "المؤشرات التشغيلية",
    "create_opm": "إنشاء مؤشر تشغيلي",
    "entities": "الجهات",
    "initiative": "المبادرات",
    "activity": "نشاط"
  },
  "srvkpi": {
    "reason_veryImportant": "أسباب تصنيف الخدمة كمهم جداً",
    "veryImportant": "مهم جداً",
    "perspective": "المنظور",
    "actualValues": "تم التحقق من القيم الفعلية",
    "importValues": "إستيراد القيم",
    "classification": "التصنيف",
    "org_unit": "الوحدة التنظيمية",
    "auxVarService": "الخدمات التكميلية/الفرعية",
    "serviceObjective": "الأهداف",
    "srvkpi": "مؤشرات الخدمات",
    "mainService": "الخدمة الرئيسية",
    "create_srvkpi": "إنشاء مؤشر خدمة",
    "service": "الخدمة الرئيسية"
  },
  "uae": {
    "direction_target": "مستهدف التوجه",
    "uae": "مؤشرات التوجهات الحكومية",
    "create_uae": "إنشاء مؤشر توجه حكومي",
    "entities": "الجهات",
    "initiative": "المبادرات",
    "activity": "النشاط",
    "ultimateVisionTarget": "مستهدف الرؤية النهائي"
  },
  "objectives": {
    "notes": {
      "delete_message": "تأكيد حذف الملاحظة",
      "delete_confirmation": "أنت على وشك حذف الملاحظة"
    },
    "add_notes": "إضافة تعليق",
    "title": "الأهداف",
    "linking_and_owner": "المسؤول",
    "agg_children": "مجموع من مؤشرات فرعية",
    "has_performance": "مرتبط بمؤشر",
    "key_success_factors": "عوامل النجاح",
    "add_success_factors": "إضافة عوامل النجاح",
    "ytp_calc": "الأداء السنوي",
    "ytp_calc_aux": "الأداء السنوي",
    "extended_fields": "الحقول الإضافية",
    "performance": "الأداء",
    "agregate": "المجموع",
    "manual_input": "إدخال يدوى",
    "dont_calculate": "لا تحسب",
    "add_extended_fields": "الحقول الإضافية المضافة",
    "enabler_objective": "هدف ممكن",
    "objective": "الهدف",
    "delete_yes_no_title": "تأكيد حذف الهدف",
    "delete_enabler_yes_no_title": "تأكيد حذف الهدف",
    "delete_yes_no_information": "أنت على وشك حذف الهدف",
    "delete_enabler_yes_no_information": "أنت على وشك حذف الهدف"
  },
  "strategic_map": {
    "title": "الخريطة الإستراتيجية",
    "start_date": "تاريخ البدء:",
    "end_date": "تاريخ الانتهاء:",
    "approval": "الموافقة",
    "plan_navig": "رؤية الخطة"
  },
  "communication": {
    "file_download": "الملف قيد التنزيل",
    "subject": "الموضوع",
    "title_en": "العنوان (بالإنجليزية)",
    "title_ae": "العنوان (بالعربية)",
    "title": "مركز الإشعارات",
    "message": "رسالة",
    "minutes_ago": "دقائق مضت",
    "hours_ago": "ساعات مضت",
    "show_more_messages": "عرض المزيد من الرسائل",
    "hide_more_messages": "إخفاء المزيد من الرسائل",
    "action_needed": "الإجراءات المطلوبة",
    "no_notifications": "لا توجد إشعارات",
    "send": "إرسال",
    "reply": "الرد",
    "reply_and_attachments": "الرد والمرفقات",
    "save_as_draft": "الحفظ كمسودة",
    "notifications_title": "الإشعارات",
    "new_message": "رسالة جديدة",
    "show_previous_messages": "عرض الرسائل السابقة",
    "view_differences": "مشاهدة الفروق",
    "data_entry": "إدخال البيانات - عرض القيمة",
    "action_delete": "تم حذف - عرض العنصر",
    "action_create": "تمت إضافة - عرض العنصر",
    "notification_templates": "إدارة الإشعارات",
    "hide_previous_messages": "إخفاء الرسائل السابقة",
    "difference": {
      "title": "عرض الاختلافات",
      "new_value": "البيانات الحالية",
      "old_value": "البيانات السابقة",
      "key": "الحقل",
      "view_object": "عرض العنصر",
      "value": "البيانات"
    }
  },
  "progress_readings": {
    "title": "قراءات التقدم",
    "next_steps": "الخطوات القادمة",
    "main_challenges": "التحديات الرئيسية"
  },
  "executive_teams": {
    "edit_members_label": "تعديل أعضاء الفريق التنفيذي",
    "members_label": "أعضاء الفريق التنفيذي",
    "title_edit": "تعديل الفريق التنفيذي",
    "add_member": "إضافة عضو إلى الفريق التنفيذي",
    "title": "الفرق التنفيذية",
    "add_button": "إضافة فريق تنفيذي جديد",
    "delete_yes_no_title": "تأكيد حذف الفريق التنفيذي",
    "submit": "موافق",
    "delete_yes_no_information": "أنت على وشك حذف الفريق التنفيذي",
    "members": {
      "modal_title_edit": "تعديل بيانات عضو الفريق التنفيذي",
      "modal_title": "عرض بيانات عضو الفريق التنفيذي",
      "title": "أعضاء الفريق التنفيذي",
      "title_within_team": "دور العضو",
      "delete_yes_no_title": "تأكيد حذف عضو الفريق التنفيذي",
      "delete_yes_no_information": "أنت على وشك حذف عضو الفريق التنفيذي",
      "adda3_user": "مستخدم النظام"
    },
    "nkpi_table": {
      "title": "المؤشرات الوطنية/ مؤشرات التوجهات الحكومية للفريق التنفيذي"
    }
  },
  "common": {
    "form": {
      "label": {
        "select": "يرجى الاختيار",
        "actual_comparison": "مقارنة الفعلي",
        "performance_comparison": "مقارنة الأداء",
        "highPriorityJustification": "معايير الاختيار",
        "initiativeManagerName": "مدير المبادرة",
        "progressManagerName": "مدير متابعة الإنجاز",
        "by": "بواسطة",
        "grid_column_arabic": "قائمة البيانات باللغة العربية",
        "grid_column_english": "قائمة البيانات باللغة الإنجليزية",
        "answer": "الإجابة",
        "security_question": "سؤال الأمان",
        "newPasswordConfirmation": "تأكيد كلمة المرور",
        "newPassword": "كلمة مرور جديدة",
        "currentPassword": "كلمة المرور الحالية",
        "refcode": "الرمز التعريفي",
        "refCode": "الرمز التعريفي",
        "name": "الاسم",
        "nameAE": "الاسم (بالعربية)",
        "nameEN": "الاسم (بالإنجليزية)",
        "description": "الوصف",
        "descriptionAE": "الوصف (بالعربية)",
        "errorMessageAE": "نص رسالة الخطأ (بالعربية)",
        "errorMessageEN": "نص رسالة الخطأ (بالإنجليزية)",
        "descriptionEN": "الوصف (بالإنجليزية)",
        "status": "الحالة",
        "yes": "نعم",
        "no": "لا",
        "username": "اسم المستخدم",
        "password": "كلمة المرور",
        "confirmPassword": "تأكيد كلمة المرور",
        "email": "البريد الإلكتروني",
        "active": "مفعل",
        "inactive": "غير مفعل",
        "available": "متاح",
        "search": "البحث",
        "filter": "تنقية نتائج البحث",
        "manager": "المدير",
        "information": "المعلومات",
        "entity": "الجهة",
        "comment": "الملاحظة",
        "comments": "الملاحظات",
        "commentsAE": "الملاحظات",
        "commentsEN": "الملاحظات",
        "monitored": "متابعة التنفيذ",
        "high_priority": "ذات أولوية",
        "start_date": "تاريخ البدء",
        "end_date": "تاريخ الإنتهاء",
        "date": "التاريخ",
        "owner": "المسؤول",
        "object_type": "نوع العنصر",
        "organization_unit": "الوحدة التنظيمية",
        "add_comment": "أضف ملاحظة",
        "add_percentage": "إضافة النسبة المئوية",
        "attachment_document": "الملف المرفق",
        "file": "الملف",
        "dimensions": "الأبعاد",
        "dimension": "البعد",
        "sub_dimension": "البعد الفرعي",
        "weight": "الوزن",
        "weight_kpi": "المؤشر و الأوزان",
        "please_select": "الرجاء الاختيار",
        "checkbox_yes_no_information": "سوف يتم حذف جميع الأسماء",
        "checkbox_yes_no_information_one": "سيتم حذف التصنيف",
        "are_you_sure": "هل أنت متأكد؟",
        "geo_location_confirmation": "تأكيد الموقع الجغرافي",
        "geo_location": "الموقع الجغرافي",
        "title": "الاسم",
        "titleAE": "الاسم (بالعربية)",
        "titleEN": "الاسم (بالإنجليزية)",
        "total": "المجموع",
        "value": "القيمة",
        "x": "خط الطول",
        "y": "خط العرض",
        "coordinates": "ينسق",
        "enter_your_message": "أدخل رسالتك",
        "attachments": "المرفقات",
        "extended_fields": "الحقول الإضافية",
        "enable_objective": "تفعيل الهدف",
        "drag_zone": "اسحب الحقل للإضافة",
        "all": "الكل",
        "search_results_for": "بحث النتائج",
        "risk": "المخاطر",
        "label": "العنوان",
        "english": "الأنجليزية",
        "arabic": "العربية",
        "mandatory": "إلزامي",
        "field": "الحقل",
        "order": "الترتيب",
        "average": "متوسط",
        "dgdp_noitems": "لاتوجد عناصر للسحب والإدراج",
        "weight_average": "متوسط الوزن",
        "min": "أقل قيمة",
        "max": "أعلى قيمة",
        "last": "آخر قيمة",
        "sum": "المجموع",
        "loading": "جاري التحميل...",
        "mitigation": "إجراءات تقليل المخاطر",
        "mitigationAE": "التوصية المقترحة (بالعربية)",
        "mitigationEN": "التوصية المقترحة (بالإنجليزية)",
        "jobTitleAE": "المسمى الوظيفي (بالعربية)",
        "jobTitleEN": "المسمى الوظيفي (بالإنجليزية)",
        "jobTitle": "المسمى الوظيفي",
        "directPhone": "الهاتف المباشر",
        "mobilePhone": "الهاتف المتحرك",
        "default_value": "القيمة الأفتراضية",
        "mission_ae": "الرسالة (بالعربية)",
        "mission": "الرسالة (بالإنجليزية)",
        "vision_ae": "الرؤية (بالعربية)",
        "vision": "الرؤية (بالإنجليزية)",
        "strength_ae": "نقاط القوة (بالعربية)",
        "strength": "نقاط القوة (بالإنجليزية)",
        "weakness_ae": "نقاط الضعف (بالعربية)",
        "weakness": "نقاط الضعف (بالإنجليزية)",
        "opportunities_ae": "الفرص (بالعربية)",
        "opportunities": "الفرص (بالإنجليزية)",
        "values_ae": "القيم (بالعربية)",
        "values_en": "القيم (بالإنجليزية)",
        "threats_ae": "التحديات (بالعربية)",
        "threats": "التحديات (بالإنجليزية)",
        "add_value": "إضافة قيمة",
        "drag_drop_no_items_to_add": "لا توجد عناصر للإضافة",
        "drag_drop_no_items_to_remove": "لا توجد عناصر للحذف",
        "none": "يرجى الإختيار",
        "add_link": "الربط",
        "reject": "رفض",
        "reject_amend": "رفض التعديلات",
        "approve": "موافقة",
        "metric": "تعديل عنصر القياس",
        "position": "الوحدة التنظيمية",
        "drop_file": "الرجاء وضع الملف هنا",
        "search_message": "رسالة البحث",
        "new_dimension": "قيمة البعد الجديد",
        "channel": "المسار",
        "strategyManager": "مدير الاستراتيجية",
        "nkiFocalPoint": "منسق المؤشرات الوطنية",
        "ekpiFocalPoint": "منسق مؤشرات الممكنات",
        "servicesFocalPoint": "منسق مؤشرات الخدمات",
        "upload_or": "أو",
        "browse_file": "عرض الملف",
        "no_files_uploaded": "لا توجد ملفات متاحة",
        "veryImportantReason": "أسباب تصنيف الخدمة كذات أولوية",
        "applyretro": "بأثر رجعي؟",
        "missing_information": "المعلومات غير متوفرة",
        "pmo_only": "لاستخدام مكتب رئاسة مجلس الوزراء",
        "pmo_role": "دور مكتب رئاسة مجلس الوزراء",
        "captcha_select_the": "يرجى اختيار",
        "bell": "الجرس",
        "pencil": "القلم",
        "book": "الكتاب",
        "eye": "العين",
        "clock": "الساعة",
        "paper_clip": "مشبك الورق",
        "select_all": "الكل",
        "linked": "مرتبط",
        "templateAE": "محتوى النموذج (بالعربية)",
        "reply_attachment": "الرد والمرفقات",
        "message": "رسالة",
        "adaa": "أداء",
        "templateEN": "محتوى النموذج (بالإنجليزية)",
        "entities": "الجهات",
        "responsible": "المسؤول",
        "objective": "الهدف",
        "using_item_delete": "من المحتمل ان يكون هذا العنصر مرتبط بكيان واحد أو عده كيانات. هل أنت متأكد؟",
        "questionMark": "علامة الاستفهام",
        "cross": "إشارة الضرب",
        "star": "النجمة",
        "magnifier": "العدسة المكبرة",
        "graph": "الرسم البياني",
        "values": "القيم",
        "expand": "فتح",
        "assignLandingPages": "تعيين الصفحة الرئيسية",
        "reporting": "التقارير",
        "generate": "إستخراج",
        "attachment_date": "التاريخ",
        "kpi_list": "قائمة المؤشرات"
      },
      "validations": {
        "required": "* إلزامية",
        "all_fields_required": "الحقول الإلزامية",
        "weight_mandatory": "الوزن إلزامي",
        "subkpi_fields_required": "الاتجاه,التردد,وحدة القياس مطلوبة لعرض مؤشرات الأداء الفرعية"
      },
      "modals": {
        "all_data_lost": "سوف يتم فقدان جميع البيانات.",
        "cancel_yes_no_title": "تأكيد الإلغاء",
        "cancel_yes_no_information": "أنت على وشك الإلغاء",
        "input_default_data": "إدخال البيانات الإضافية",
        "remove_all_data_information": "هل أنت متأكد من حذف البيانات السابقة التي تحتوي على هذه الخاصية؟ عدد السجلات المتأثرة:",
        "input_default_data_information": "هل ترغب في إدخال البيانات الإفتراضية؟ عدد السجلات المتأثرة:",
        "tag": "علامة",
        "title_list_of_values": "قائمة القيم",
        "delete_list_of_values_confirmation_title": "تأكيد حذف القيمة",
        "change_activity": "تغيير المهمة من النشاط",
        "will_change_activity": "سوف يتم تغيير المهمة إلى نشاط آخر",
        "remove_data_information": "هل ترغب بحذف جميع البيانات السابقة؟",
        "title_workflow_delete": "تأكيد حذف سير العمل",
        "remove_workflow_data_information": "أنت على وشك حذف سير العمل",
        "delete_confirmation": "تأكيد حذف المهمة",
        "delete_confirmation_tasks": "أنت على وشك حذف المهمة"
      },
      "modals_leave": {
        "cancel_yes_no_title": "ترك النموذج الرئيسي",
        "cancel_yes_no_information": "أنت على وشك ترك النموذج"
      },
      "button": {
        "validate": "التحقق",
        "apply": "تطبيق",
        "change_password": "تغيير كلمة المرور",
        "save": "حفظ",
        "cancel": "إلغاء",
        "edit": "تعديل",
        "delete": "حذف",
        "upload": "إضافة ملف",
        "close": "إغلاق",
        "save_as_draft": "الحفظ كمسودة",
        "send": "إرسال",
        "reject_amend": "رفض التعديل",
        "reject": "رفض",
        "approve": "موافقة",
        "update": "تعديل",
        "back": "السابق",
        "next": "التالي",
        "previous": "السابق",
        "finish": "إنهاء",
        "view_all": "عرض الكل",
        "read_more": "إقرأ المزيد",
        "send_to_pre_approval": "الإرسال للموافقة المبدئية",
        "terminate": "إنهاء",
        "ignore": "رفض",
        "review": "مراجعة",
        "view_related_objects": "عرض العناصر ذات الصلة",
        "confirm": "تأكيد",
        "print": "طباعة",
        "settings": "الإعدادات",
        "send_to_entities": "إرسال الى الجهات الحكومية",
        "simulate": "محاكاة",
        "reset": "إعادة تعيين"
      },
      "attachments": {
        "same_name": "الاسم (بالعربية) موجود"
      },
      "user": {
        "user_information": "معلومات المستخدم"
      }
    },
    "tables": {
      "location": "الموقع",
      "type": "نوع",
      "name": "الاسم",
      "actual": "القيمة الفعلية",
      "score": "النتيجة",
      "progress": "نسبة الإنجاز",
      "information": "المعلومات",
      "info": "المعلومات",
      "trend": "النمط",
      "total": "المجموع",
      "weight": "الوزن",
      "team_owner": "الفريق المسؤول",
      "performance": "أداء الفترة",
      "date": "الفترة",
      "value": "القيمة",
      "label": "العنوان",
      "ytp": "الأداء السنوي",
      "actions": "الإجراءات",
      "address": "العنوان",
      "gps": "إحداثيات الموقع",
      "email": "البريد الإلكتروني",
      "phone": "الهاتف",
      "reports_to": "تقارير إلي",
      "manager": "المدير",
      "send_to_pre_approval": "الإرسال للموافقة المبدائية",
      "terminate": "إنهاء",
      "period": "الفترة",
      "favorites": "المفضلة",
      "no_info": "لا توجد معلومات",
      "form": "نموذج",
      "last_calculation": "آخر عملية احتساب",
      "usage_on": "استخدام في",
      "check_all": "التأكد من الكل",
      "use_weight": "إستخدم الأوزان",
      "uncheck_all": "إلغاء الجميع",
      "yes": "نعم",
      "no": "لا",
      "manage": "إدارة",
      "object_actions": "العنصر/الإجراء",
      "create": "إنشاء",
      "view": "عرض",
      "execute": "التنفيذ",
      "entity_name": "اسم الجهة",
      "organization_unit": "الوحدة التنظيمية",
      "notification": "الإشعار",
      "category": "الفئة",
      "active": "فعال",
      "start": "بداية",
      "end": "نهاية",
      "last-edit": "تاريخ آخر تعديل",
      "budget": "ميزانية",
      "workload": "وزن النشاط",
      "sms": "رسالة",
      "open_cycle": "الفترة الحالية",
      "formula": "معادلة القياس",
      "frequency": "دورية القياس",
      "missing_cycle": "الفترة المطلوبة",
      "last_modification": "آخر تحديث",
      "modified_by": "عدلت بواسطة",
      "filter_modal": "تنقية نتائج البحث",
      "view_all_data": "إظهار كافة القيم",
      "description": "الوصف",
      "range": {
        "from": "من",
        "to": "إلى"
      }
    },
    "state": {
      "workloads_missing": "يجب إدخال أوزان الأنشطة لحساب نسبة إنجاز المبادرة",
      "on_approval": "قيد الموافقة",
      "draft": "مسودة",
      "approved": "معتمد",
      "deleted": "محذوف",
      "new": "جديد",
      "data_approved": "القيم الفعلية معتمدة",
      "data_missing": "القيم الفعلية غير متوفرة",
      "data_onreview": "القيم الفعلية قيد الاعتماد"
    }
  },
  "team_owner": {
    "strategy_and_policy_sector": "قطاع الإستراتيجية والسياسات",
    "public_diplomacy": "الدبلوماسية العامة",
    "government_performance_department": "إدارة الأداء الحكومي",
    "other": "أخرى",
    "team_owner": "الفريق المسؤول"
  },
  "trend": {
    "increase": "تزايد أفضل",
    "decrease": "تناقص أفضل",
    "bounded": "محدد بنطاق",
    "on_target": "حسب المخطط"
  },
  "attachment_type": {
    "report": "التقرير"
  },
  "sidebar_tables": {
    "executive_team": "الفريق التنفيذي",
    "contributing_entities": "الجهات المساهمة",
    "scope_measurement": "نطاق القياس",
    "baseline": "خط الأساس",
    "benchmarks": "المقارنات المعيارية",
    "attachments": "المرفقات",
    "communication_centre": "مركز الإشعارات",
    "last_periods": "آخر 12 فترة",
    "notes": "الملاحظات",
    "evolution_graph": "تطور النتائج"
  },
  "khadamati": {
    "service_channels": {
      "canApply": "تقديم طلب الخدمة؟",
      "canDeliver": "الحصول على الخدمة؟",
      "canPay": "الدفع للخدمة؟"
    },
    "comment": {
      "SERVICES": "الخدمة الرئيسية",
      "SUBSERVICES": "الخدمات الفرعية",
      "SERVICECENTERS": "مراكز الخدمة"
    },
    "sync_status": {
      "failed": "خطأ في المزامنة",
      "finished": "تمت المزامنة بنجاح",
      "in_progress": "المزامنة قيد التنفيذ",
      "stopped": "المزامنة"
    },
    "last_sync_date": "تاريخ آخر تعديل",
    "sync_details": "تفاصيل المزامنة",
    "title_integration": "التكامل مع خدماتي",
    "sync_log": "سجل المزامنة",
    "title": "التكامل مع خدماتي",
    "list": "سجل المزامنة",
    "manual_sync": {
      "title": "المزامنة اليدوية"
    },
    "schedule": {
      "schedule_sync": "حفظ",
      "title": "جدول المزامنة",
      "integration": "التكامل",
      "configuration": "الإعدادات",
      "monthly": "يوم من الشهر",
      "dayOfMonth": "يوم من الشهر",
      "weekly": "اختر يوم من الاسبوع",
      "daily": "يومي",
      "inactive": "غير مفعل",
      "start_sync": "بدء المزامنة",
      "stop_sync": "إيقاف المزامنة",
      "insert_day": "إدخال يوم",
      "week": {
        "choose": "يرجى الاختيار",
        "monday": "الإثنين",
        "tuesday": "الثلاثاء",
        "wednesday": "الأربعاء",
        "thursday": "الخميس",
        "friday": "الجمعة",
        "saturday": "السبت",
        "sunday": "الأحد"
      }
    }
  },
  "login": {
    "password_incorrect": "كلمة مرور غير صحيحة",
    "send": "إرسال",
    "error_answers": "إجابات غير صحيحة",
    "user_not_found_instructions": "يجب إدخال بريد إلكتروني صحيح",
    "user_not_found": "المستخدم غير موجود في قاعدة البيانات",
    "error_email": "الرجاء إدخال البريد الإلكتروني بطريقة صحيحة",
    "select_channel": "اختر طريقة إرسال رمز التحقق",
    "error_user": "مستخدم غير صحيح",
    "select": "اختر",
    "select_the": "الرجاء اختيار",
    "welcome": "مرحباً... الرجاء الدخول للإستمرار",
    "email": "البريد الإلكتروني",
    "password": "كلمة المرور",
    "login": "تسجيل الدخول",
    "forgot": "نسيت كلمة المرور؟",
    "forgot_password_title": "نسيت كلمة المرور",
    "forgot_text": "سوف نرسل لك رسالة بريد إلكتروني تحتوي على رابط لإنشاء كلمة مرور جديدة للحساب الخاص بك",
    "reset_password": "إعادة إنشاء كلمة المرور",
    "two_steps_title": "خطوة التحقق الثانية",
    "two_steps_text": "يرجى تحديد الطريقة التي ترغب بها لتلقي رمز التحقق من 6 أرقام",
    "more_options": "المزيد من الاختيارات",
    "confirm": "موافق",
    "confirmation_code": "رمز الموافقة",
    "text_message": "رسالة نصية",
    "channel": "طريقة الارسال",
    "dont_ask": "لا تسأل مره أخرى على هذا الحاسوب",
    "go_back": "الرجوع",
    "send_reset_email_text": "تم إرسال بريد إلكتروني إلى",
    "send_reset_email_instructions": "انقر على الرابط المرسل لك لإعاده تعيين كلمه المرور",
    "text_select_identity": "الرجاء اختيار طريقة التحقق المرادة",
    "text_questions": "الأسئلة النصية",
    "answer": "إجابة",
    "create_password": "إنشاء كلمة مرور",
    "captcha_error": "لم يتم التحقق من الاختيار",
    "password_confirm": "تأكيد كلمة المرور",
    "resendCode": "إعادة إرسال الرمز",
    "sentCodeSms": "تم إرسال الرمز إلى الهاتف",
    "sentCodeEmail": "تم إرسال الرمز إلى البريد الإلكتروني",
    "logToContin": "يرجى الدخول للاستمرار",
    "request_password": "لقد قمت بطلب كلمة مرور جديدة. اضغط على الرابط للاستمرار",
    "error_code": "رمز غير صحيح",
    "betaversion": "النسخة التجريبية",
    "error_code_15": "الرمز غير صحيح"
  },
  "mandatory": {
    "title": "الحقول الإلزامية",
    "information": "المعلومات",
    "update_fields": "تحديث الحقول",
    "update_changes": "هل ترغب في تطبيق التغييرات؟"
  },
  "graphic": {
    "risk_management": {
      "title": "حالة الخطر",
      "x_axis_impact": "التأثير",
      "y_axis_risk": "الأولوية",
      "delete_yes_no_title": "تأكيد خذف خطر",
      "delete_yes_no_information": "أنت على وشك حذف الخطر",
      "association": "مصدر الخطر"
    },
    "gantt": {
      "title": "المخطط الزمني",
      "no_attachments": "لا يوجد مرفقات"
    },
    "kpi": {
      "actual": "النتيجة الفعلية",
      "actuals": "القيم الفعلية",
      "year_to_period": "الأداء السنوي"
    }
  },
  "provisioning": {
    "addresses": "العناوين",
    "organization_structure": "الهيكل التنظيمي",
    "organization_unit": "الهيكل التنظيمي",
    "personnel": "الموظفين",
    "title": "بيانات الجهة",
    "swot_title": " تحليل البيئة الداخلية والخارجية",
    "directives": "الوثيقة الاستراتيجية",
    "address": {
      "delete_yes_no_title": "تأكيد حذف العنوان",
      "delete_yes_no_information": "أنت على وشك حذف العنوان"
    },
    "orgunit": {
      "delete_yes_no_title": "تأكيد حذف الإدارة",
      "delete_yes_no_information": "أنت على وشك حذف الإدارة",
      "title": "الإدارة"
    },
    "orgpeople": {
      "delete_yes_no_title": "تأكيد حذف المستخدم",
      "delete_yes_no_information": "أنت على وشك حذف المستخدم",
      "title": "المستخدم"
    }
  },
  "cycle": {
    "full_cycle": "دورة كاملة",
    "title": "الدورات الاستراتيجية",
    "create": "إنشاء دورة جديدة",
    "delete_yes_no_title": "تأكيد مسح الدورة",
    "delete_yes_no_information": "أنت على وشك حذف الدورة الاستراتيجية",
    "send_to_entities": "سوف يتم اخطار الجهات ببدء إدارة الخطة",
    "title_send": "تقديم لإدارة الخطة",
    "available_entities": "الجهات المتاحة",
    "move_to_available": "أضف الكل",
    "move_to_connected": "حذف الكل",
    "connected_entities": "الجهات المعنية",
    "no_data_row": "تم إخطار جميع الجهات",
    "notify_entities": "إخطار الجهات",
    "entity_name": "اسم الجهة",
    "last_update": "آخر تحديث",
    "no_entities_selected": "لا توجد جهات مختارة",
    "notify_entities_confirm": "تأكيد اشعار الجهات ",
    "data_entry_check": "هناك مؤشرات بدون قيم مكتملة",
    "data_entry_closing": "تأكيد إغلاق الدورة",
    "activate_cycle": "تفعيل الدورة ",
    "reopen_cycle": "إعادة فتح الدورة ",
    "close_cycle": "إغلاق الدورة"
  },
  "pillar": {
    "title": "المحاور",
    "create": "إضافة محور جديد",
    "enabling_entities": "تمكين الجهات",
    "delete_yes_no_title": "تأكيد حذف المحور",
    "delete_yes_no_information": "أنت على وشك حذف المحور",
    "manage": "المحاور",
    "contribution": "المتأثرة",
    "performance": {
      "pillar_performance": "أداء المحور",
      "actual_value": "النتيجة الفعلية",
      "kpi_performance": "أداء المؤشر"
    }
  },
  "audit_trace": {
    "title": "سجلات النظام"
  },
  "connections": {
    "double_click_delete": "الضغط المزدوج على الرابط سيحذفه من شبكة الروابط المعروضة",
    "focus": "التركيز",
    "view_soft_connections": "روابط مرنة",
    "view_hard_connections": "روابط صلبة",
    "title": "الروابط",
    "unlinked": "العناصر غير المرتبطة",
    "linked": "العناصر المرتبطة",
    "hard_connection": "روابط صلبة",
    "soft_connections": "روابط مرنة",
    "reload": "إعادة تحميل",
    "show_all": "عرض الكل",
    "Export": "تنزيل",
    "kpi": "مؤشر",
    "objective": "هدف",
    "initiative": "مبادرة",
    "service": "خدمة",
    "activity": "نشاط",
    "print": "طباعة",
    "gov_direction": "التوجه الحكومي"
  },
  "plusbutton": {
    "audit_trail": "عرض متابعة الإجراءات",
    "delivery_module": "توجه الى نموذج متابعة المبادرات",
    "newhelpdeskticket": "تسجيل طلب دعم",
    "initiatives": "إنشاء مبادرة جديدة",
    "objectives": "إنشاء هدف جديد",
    "enablerobjective": "إنشاء هدف ممكن جديد",
    "nkpi": "إنشاء مؤشر وطني جديد",
    "print": "طباعة",
    "ekpi": "إنشاء مؤشر ممكن حكومي جديد",
    "comment": "ملاحظة",
    "info": "المعلومات",
    "tags": "العلامات",
    "connect": "الروابط",
    "dtinput": "إدخال البيانات",
    "activities": "الأنشطة",
    "sendmessage": "إرسال رسالة",
    "export": "تنزيل",
    "workflow_history": "بيانات سير العمل التاريخية",
    "showvision": "عرض الرؤية"
  },
  "wf_status": {
    "D": "حذفت",
    "E": "عدلت",
    "A": "مفعل",
    "O": "قيد الموافقة",
    "R": "مرفوضة"
  },
  "status": {
    "1": "معتمدة",
    "2": "حذف",
    "3": "مسودة",
    "4": "مغلقة",
    "5": "مؤرشفة",
    "6": "قيد التخطيط",
    "I": "حذف",
    "A": "مفعل",
    "M": "دمج",
    "L4": "4 المستوى",
    "L3": "3 المستوى",
    "L2": "2 المستوى",
    "L1": "1 المستوى",
    "level": "المستوى",
    "D": "مسودة",
    "kpi1": "المؤشر معتمد",
    "kpi3": "مؤشر مسودة",
    "kpi4": "المؤشر قيد الاعتماد",
    "objective1": "هدف مسودة",
    "objective2": "الهدف قيد الاعتماد",
    "objective3": "الهدف معتمد",
    "in_review": "قيد الاعتماد"
  },
  "communications_status": {
    "A": "الإجراءات",
    "R": "طلبات",
    "I": "المعلومات"
  },
  "communications_tatus": {
    "D": "موافقة",
    "R": "طلب",
    "I": "المعلومات"
  },
  "tooltips": {
    "audit-trail": "التدقيق",
    "connections": "الروابط",
    "related_objects": "الأهداف ذات الصلة",
    "edit_connections": "تعديل الروابط",
    "view": "عرض",
    "edit": "تعديل",
    "delete": "حذف",
    "plans": "خطط",
    "add": "إضافة",
    "delete_disabled": "لايمكن حذف العنصر الحالي",
    "edit_disabled": "لا يمكن تعديل العنصر الحالي",
    "new": "جديد",
    "export": "تنزيل",
    "pending": "مسودة",
    "notification_center": "مركز الإشعارات",
    "help_center": "مركز المساعدة",
    "import": "تحميل",
    "tree_view": "طريقة عرض الشجرة",
    "list_view": "طريقة عرض القائمة",
    "settings": "الإعدادات",
    "print": "طباعة",
    "download_excel": "تنزيل ملف إيكسيل",
    "filter": "تنقية نتائج البحث",
    "chart": "رسم بياني",
    "download_image": "تنزيل صورة",
    "download": "",
    "open": "Open"
  },
  "main_types": {
    "G": "مشترك (حكومي)",
    "L": "محلي (الجهة)",
    "LA": "الجميع "
  },
  "time": {
    "ago": "الماضي",
    "minute": "الدقيقة",
    "minutes": "الدقائق",
    "hour": "الساعة",
    "hours": "الساعات",
    "day": "اليوم",
    "days": "الأيام",
    "time": "زمن"
  },
  "library": {
    "metrics": "عناصر القياس",
    "dimensions": "الأبعاد",
    "attributes": "الخصائص",
    "formulas": "قوالب المعادلات "
  },
  "context_menu": {
    "create": {
      "initiative": "إنشاء مبادرة جديدة",
      "skpi": "إنشاء مؤشر إستراتيجي",
      "activity": "إنشاء نشاط جديد",
      "opm": "إنشاء مؤشر تشغيلي",
      "servicekpi": "إنشاء مؤشر خدمة جديد"
    },
    "add": {
      "service": "إضافة خدمة",
      "kpi": "إضافة مؤشر"
    }
  },
  "search": {
    "loading": "تحميل النتائج ...؟",
    "noresults": "لا توجد نتائج",
    "searchingfor": "أنت تبحث عن",
    "found": "من",
    "of": "أصل",
    "documents": "المستندات",
    "services": "الخدمات",
    "attachments": "المرفقات",
    "activities": "أنشطة",
    "resultsfound": "عدد النتائج: "
  },
  "help_modal": {
    "list": "مساعدة",
    "watch_video": "مشاهدة الفيديو",
    "video": "فيديو",
    "preview": "معاينة",
    "content1AE": "المحتوي 1 (عربي)",
    "content1EN": "المحتوي 1 (بالانجليزية)",
    "content2AE": "المحتوي 2 (عربي)",
    "content2EN": "المحتوي 2 (بالانجليزية)",
    "content3AE": "المحتوي 3 (عربي)",
    "content3EN": "المحتوي 3 (بالانجليزية)",
    "delete_yes_no_title": "تأكيذ حذف المساعدة السياقية ",
    "delete_yes_no_information": "الرجاء التأكيد"
  },
  "plan_editor": {
    "added_kpis": "مؤشرات مضافة",
    "unlinked_kpis": "مؤشرات غير مرتبطة",
    "add_kpis": "إضافة مؤشر",
    "add_services": "إضافة خدمة",
    "added_services": "خدمة مضافة",
    "unlinked_services": "خدمات غير مرتبطة",
    "title": "الرؤية المستقبلية",
    "manage": "إدارة الخطة",
    "weight_review": "مراجعة الوزن",
    "submit_review": "إرسال للمراجعة",
    "add_objective": "إنشاء هدف",
    "intro_message": "إبدأ بإضافة هدفك الأول",
    "temporary": "مؤقت",
    "add_enabler_objective": "إنشاء هدف ممكن"
  },
  "user_roles": {
    "grid": "أدوار المستخدمين",
    "title": "أدوار المستخدمين",
    "add_button_list": "إضافة دور المستخدم",
    "modals": {
      "refresh_pmo_permissions": {
        "title": "تحديث الصلاحيات",
        "info": "الصلاحيات المختارة سوف يتم حذفها"
      },
      "delete_yes_no": {
        "title": "تأكيد حذف الصلاحية",
        "info": "أنت على وشك حذف دور المستخدمين"
      }
    }
  },
  "user_groups": {
    "title": "مجموعات المستخدمين",
    "add_button_list": "إضافة مجموعة",
    "add_button_user": "إضافة مستخدم",
    "modals": {
      "add_user": {
        "title": "إضافة مستخدم"
      },
      "delete_yes_no": {
        "title": "تأكيد حذف مجموعة المستخدمين",
        "info": "أنت على وشك حذف مجموعة المستخدمين"
      }
    }
  },
  "data_cycle_automation": {
    "title": "الإطار الزمني لإدخال النتائج",
    "add_rule": "قاعدة دورية قياس جديدة",
    "all_metrics": "جميع المقاييس",
    "frequency": "دورية القياس",
    "days_before": "بداية فترة إدخال النتائج",
    "days_after": "نهاية فترة إدخال النتائج",
    "affected": "متأثر",
    "delete_yes_no_title": "حذف قاعدة دورية القياس",
    "delete_yes_no_information": "أنت على وشك حذف قاعدة دورية القياس ."
  },
  "user_profile": {
    "channels": "قنوات الإشعارات",
    "wizard": {
      "responsible_for": "نطاق المسؤولية (الجهات)",
      "preferred_language": "اللغة المفضلة",
      "preferred_channel": "القناة المفضلة",
      "notification_settings": "إعدادات الإشعارات",
      "title": "الملف الشخصي",
      "personalInformation": "البيانات الشخصية",
      "security": "معلومات الأمان",
      "owned_objects": "المسؤوليات",
      "informationSaved": "تم حفظ المعلومات بنجاح",
      "error": "يتوجب عليك اختيار أسئلة مختلفة",
      "allQuestions": "يتوجب عليك الإجابة على جميع الأسئلة"
    }
  },
  "translation_module": {
    "title": "الترجمة",
    "arabic": "النماذج باللغة العربية",
    "english": "النماذج باللغة الإنجليزية"
  },
  "landingPages": {
    "activeDkpi": "مؤشر توجه حكومي مفعل",
    "enabler_team": "فريق الممكنات الحكومية ",
    "directional_team": "فريق التوجهات الحكومية ",
    "service_kpi_team": "فريق الخدمات الحكومية",
    "skpi_opm_team": "فريق الأداء الاستراتيجي",
    "national_team": "فريق الأجندة الوطنية ",
    "entity_team": "الجهة الحكومية",
    "activeNkpi": "مؤشر وطني مفعل"
  },
  "landingPage": {
    "mytasks": {
      "title": "مهامي",
      "workflowRequest": "طلب سير العمل",
      "numberDestinationWorkflow": "عدد وجهات سير العمل",
      "numberOfRequests": "عدد الطلبات",
      "NamesOfEntities": "عدد الجهات"
    },
    "subgovdirection": {
      "title": "التوجهات الحكومية الفرعية"
    },
    "pillars": {
      "title": "المحاور",
      "ekpis_list": "مؤشرات الممكنات الحكومية",
      "no_pillars": "لا توجد محاور"
    },
    "kpiTable": {
      "dkpi": "مؤشر توجه حكومي",
      "nkpi": "مؤشر وطني",
      "kpiName": "اسم الؤشر الوطني",
      "entity": "جهة",
      "dataAvailability": "توافر البيانات",
      "trend": "النمط",
      "lastUpdate": "آخر تحديث"
    },
    "menu": {
      "get": "فريق الممكنات الحكومية",
      "nat": "فريق الأجندة الوطنية",
      "gspd": "فريق الخدمات الحكومية",
      "dkpi": "فريق التوجهات الحكومية",
      "entity": "الجهة الحكومية",
      "skpi-opm": "فريق الأداء الاستراتيجي"
    },
    "activeNkpis": "المؤشرات الوطنية الفعالة",
    "activeDkpi": "مؤشرات التوجهات الحكومية الفعالة",
    "executiveTeam": {
      "title": "الفرق التنفيذية",
      "name": "الاسم"
    },
    "audit": {
      "title": "برنامج التدقيق",
      "first": "التدقيق",
      "operational": "تنفيذي"
    },
    "favorites": {
      "title": "المفضلة",
      "item": "بند",
      "type": "نوع",
      "entity": "جهة اتحادية",
      "direction": "توجه",
      "view": "عرض"
    },
    "highpriority": {
      "title": "المبادرات ذات الأولوية"
    },
    "initiatives": {
      "name": "الاسم",
      "percentage": "نسبة مئوية",
      "action": "إجراء"
    },
    "entity_tasks": {
      "title": "إجراء"
    },
    "entity_page": {
      "enabler_performance": "أداء الممكنات",
      "government_enabler_performance": "أداء الممكنات الحكومية",
      "government_overall_performance": "الأداء العام لحكومة دولة الإمارات",
      "entities_update": "تحديثات الجهات",
      "last_update_khadamati": "آخر تحديث من نظام خدماتي",
      "kpi_rate_update": "إتمام إدخال البيانات",
      "government_enabler_pillars": "محاور الممكنات الحكومية",
      "entity_landing_page": "الصفحة الرئيسية للجهات",
      "perfomance": " الأداء الاستراتيجي",
      "entity_overall_performance": "الأداء العام",
      "overall_performance": "الأداء العام",
      "select_period": "الفترة",
      "annual_performance": "الأداء السنوي",
      "performance_on": "الأداء في",
      "kpi_issues": "هناك فروقات بين النتائج المدخلة في النظام والنتائج المدقق عليها"
    },
    "entityUp": {
      "name": "الاسم",
      "oneQ": "الربع الأول",
      "twoQ": "الربع الثاني",
      "threeQ": "الربع الثالث",
      "fourQ": "الربع الرابع",
      "average": "متوسط",
      "last_update": "آخر تحديث",
      "communication": "اتصال"
    },
    "entityupdate": {
      "title": "تحديث الجهة",
      "type": "نوع",
      "objectname": "اسم العنصر",
      "entity": "جهة حكومية"
    },
    "activeNkpi": "مؤشر وطني مفعل",
    "enablingEntity": "الجهات الممكنة",
    "integration_settings": "إعدادات التكامل",
    "entity_landing_page": "الصفحة الرئيسية للجهة",
    "kpi_updates": "تحديث المؤشرات",
    "error_message": "لم يتم تعيين صفحة رئيسية لحساب المستخدم الخاص بك ، يرجى التواصل مع مسؤول النظام",
    "entities_tasks": "قائمة الإجراءات"
  },
  "workflow": {
    "review": "قيد اعتماد الجهة",
    "approval": "قيد اعتماد مكتب رئاسة مجلس الوزراء",
    "changetodefault": "هناك إعداد سير عمل آخر تم تعيينه كافتراضي. بالضغط على نعم، سيصبح إعداد سير العمل هذا هو الإعداد الافتراضي الوحيد.",
    "changedefault ": "سيتم تعيين سير العمل هذا كسير عمل افتراضي للإعدادات التي تم اختيارها",
    "default": "سير العمل التلقائي",
    "configuration": "سير العمل",
    "create_rule_trigger": "إنشاء قاعدة تشغيل سير العمل",
    "workflow_template": "نموذج سير العمل",
    "rule_type": "نوع القاعدة العملية",
    "field": "الحقل",
    "operator": "رمز المقارنة",
    "value": "القيمة",
    "rule_parameter": "عناصر القاعدة",
    "title": "إدارة سير العمل",
    "workflow_history": "تاريخ سير العمل",
    "workflow_assignment": "إعداد سير العمل",
    "workflow_trigger": "مشغلات سير العمل",
    "workflowconfiguration": "إدارة سير العمل ",
    "workflowprofiles": "ملفات سير العمل",
    "workflow_process_id": "الرقم التعريفي لسير العمل",
    "workflowtype": "نوع سير العمل",
    "workflowactiontype": "نوع الإجراء",
    "addusergroup": "إضافة مجموعات مستخدمين",
    "inputgrantlevels": "مستويات إتاحة الإدخال",
    "grantlevels": "مستويات",
    "workflowlevels": "مستويات سير العمل",
    "workflowprofilelevels": "معتمدي الإجراءات",
    "aprovallevel": "مستويات الموافقة",
    "availableusers": "مجموعة المستخدمين",
    "grantedusers": "المستخدمين المعتمدين",
    "workflowedit": "تعديل ملف سير العمل",
    "grant_users": "المستخدمين المعتمدين",
    "close": "إغلاق",
    "itemTypeId": "رمز نوع العنصر",
    "itemType": "نوع العنصر",
    "action": "الإجراءات",
    "workflow_triggers": "مشغلات سير العمل",
    "workflow_monitoring": "مراقبة سير العمل",
    "trigger_rules": "قواعد التشغيل ",
    "workflow": "سير العمل",
    "savetrigger": "حفظ",
    "override_monitored": "تعديل سير العمل",
    "override_monitored_for": "إلى ",
    "pending_users": "المستخدمين قيد الإنتظار",
    "override_message": "مبرر تعديل نتيجة سير العمل",
    "override_button": "موافق",
    "pending_action_users": "إجراء سير العمل عالق عند",
    "same_name_triggers": "يوجد مشغل سير العمل بنفس الاسم يرجى التأكد.",
    "override_monitored_decision": "تعديل سير العمل",
    "level_one": "YYXX1 مستوى",
    "level_two": "2 مستوى",
    "level_three": "3 مستوى",
    "level": "مستوى",
    "level_four": "4 مستوى",
    "level_org": "L",
    "bulk_approval": {
      "approve": "الموافقة الجماعية",
      "reject": "الرفض الجماعي",
      "select_all": "اختر الكل",
      "all_selected": "تم تحديد الكل",
      "title": "الموافقة الجماعية",
      "bulk_approval_button": "تنفيذ الموافقة الجماعية"
    },
    "workflow_pending_user_warn": "عذرا هذه القائمة تحتاج بعض الوقت للإنشاء، الرجاء إغلاق النافذة وإعادة فتحها في غضون 10 ثوان إذا كانت القائمة فارغة."
  },
  "chart": {
    "png": "تحميل PNG",
    "svf": "تحميل SVF",
    "svg": "تحميل SVG",
    "pdf": "تحميل PDF",
    "print_chart": "طباعة المخطط",
    "periods": "الفترات",
    "jpg": "تحميل JPG"
  },
  "notification_config": {
    "templates": {
      "modal": {
        "placeholders_title": "العناصر المتاحة",
        "title": "إعدادات النموذج"
      },
      "edit_buttons": {
        "app": "تعديل نموذج التطبيق",
        "sms": "تعديل نموذج الرسائل",
        "email": "تعديل نموذج البريد الالكتروني"
      }
    }
  },
  "importExport": {
    "upload_file": "تحميل ملف",
    "operation_type": {
      "export": "تنزيل",
      "import": "تحميل"
    },
    "file_status": {
      "fail": "خطأ في العملية",
      "pending": "قيد التنفيذ",
      "done": "تمت",
      "success": "تمت العملية بنجاح"
    },
    "console": "تنزيل / تحميل الخطة",
    "data_entry": "تنزيل / تحميل القيم الفعلية",
    "kpi": "تنزيل / تحميل مؤشرات الأداء",
    "title_kpi": "تنزيل / تحميل مؤشرات الأداء",
    "title_plan": "تنزيل / تحميل الخطة",
    "title_kpidata": " تنزيل / تحميل القيم الفعلية",
    "choose_file": "الرجاء اختيار الملف"
  },
  "faqs": {
    "faqs": "الأسئلة الأكثر تكراراً",
    "no_results": "المعلومات غير موجودة"
  },
  "delivery_module": {
    "risk_management": "مصفوفة المخاطر",
    "prog_status": {
      "green": "أخضر",
      "lime": " ليموني",
      "ambar": "أصفر",
      "red": "أحمر"
    },
    "title": "متابعة تنفيذ المبادرات",
    "tasks": "المهام",
    "task": "مهمة",
    "add_task": "مهمة",
    "contributingentities": "الجهات المساهمة",
    "associate_with": "مصدر الخطر",
    "impact": "الأثر",
    "priority": "الأولوية",
    "last-edit": "تاريخ آخر تعديل",
    "risk_status": "حاله الخطر",
    "workload": "وزن النشاط",
    "challange": "التحديات",
    "stakeholders": "الجهات المساهمة",
    "addchallenges": "إضافة تحديات",
    "nextsteps": "الخطوات القادمة",
    "progress_report_freq": "دورية تقرير الإنجاز",
    "team_members": "فريق العمل",
    "actions": "الإجراءات",
    "add_action": "إضافة إجراء",
    "progress_status": "تقييم الإنجاز",
    "add_resource": "إضافة موارد",
    "add_members": "إضافة عضو فريق",
    "linked_kpi": "المؤشرات المرتبطة",
    "resources": "الموارد",
    "input_duration": "مدة الإدخال",
    "duration": "المدة الزمنية",
    "description": "وصف المبادرة",
    "highpriority": "مبادرة ذات أولوية",
    "govs_subgovs": "التوجهات الحكومية والتوجهات الحكومية الفرعية",
    "monitored": "متابعة التنفيذ",
    "competences": "الموارد / المهارات",
    "linked": "المؤشرات المرتبطة",
    "risks": "المخاطر",
    "progressManager": "مدير متابعة الإنجاز",
    "initiativeManager": "مدير المبادرة",
    "warningActivity": "يجب عليك اختيار مهمة للنشاط",
    "pleaseReviewInformation": "يرجى مراجعة المعلومات",
    "person_company": "الشخص / الشركة",
    "start_date": "تاريخ البدء",
    "continous": "مستمرة",
    "other": "أخرى",
    "resources_name_ae": "اسم المورد (بالعربية)",
    "resources_name_en": "اسم المورد(بالإنجليزية)",
    "warning_no_activities": "لا توجد أنشطة مرتبطة بهذه المبادرة",
    "difference": "التباين",
    "probability": "الاحتمالية",
    "progress_warning": "يجب تعديل قيمة الإنجاز و حفظها",
    "progress_missing": "قيمة الإنجاز غير موجودة",
    "progress_updated": "تم تعديل قيمة الإنجاز",
    "initiative": "المبادرة",
    "is_task_completed": "اكتملت المهمة"
  },
  "kpis": {
    "subkpis_selectFromExist": "اختر من القائمة",
    "subkpis_weight": "الوزن",
    "subkpis_name": "الاسم",
    "subkpis_listSelected": "المؤشرات المختارة",
    "subkpis_createNew": "إضافة مؤشر فرعي",
    "subkpis_compatible": "حالة التوافق",
    "subkpis_buttonAdd": "موافق",
    "subkpis_createKpi": "إنشاء مؤشر فرعي جديد",
    "subkpis_selectSub": "اختر مؤشر",
    "subkpis_nameEN": "الاسم بالإنجليزية",
    "subkpis_modalQuestion": "هل أنت متأكد؟",
    "subkpis_aggreModalContent": "إذا قمت بالمتابعة سوف يتم حذف المعادلة",
    "subkpis_titleModal": "تنبيه!",
    "subkpis_cannotBeChanged": "يرجى العلم أن بعد الإرسال لا يمكنك تغيير هذا الخيار",
    "subkpis_nameAE": "الاسم بالعربية",
    "subkpis_loseInformation": "سيتم أيضاً تعديل مؤشرات الأداء الفرعية",
    "subkpis_compatible_N": "غير متوافق",
    "subkpis_compatible_Y": "متوافق",
    "subkpis_errorDate": "لا يتوافق مع تاريخ البدء والانتهاء",
    "subkpis_errorTrend": "لا يتوافق مع النمط",
    "subkpis_errorMeasures": "لا يتوافق مع وحدة القياس",
    "subkpis_errorFrequency": "لا يتوافق مع دورية القياس",
    "subkpis_errorTitle": "لا يتوافق مع المؤشرات الفرعية",
    "subkpis_fill_info": "يجب إدخال المعلومات للمؤشر الفرعي"
  },
  "smart_tool_tips": {
    "title": "الرسائل التوضيحية",
    "edit": "تعديل الرسالة التوضيحية",
    "show": "عرض الرسالة التوضيحية",
    "field_name_ae": "(اسم الحقل (بالعربية",
    "field_name_en": "(اسم الحقل (بالإنجليزية",
    "descriptionEN": "(إظهار الرسالة التوضيحية (بالإنجليزية",
    "descriptionAE": "(إظهار الرسالة التوضيحية (بالعربية",
    "buttonLabel": "تم",
    "invalid_text": "الرسالة التوضيحية غير مناسبة",
    "notool_tips": "لا توجد رسالة توضيحية لهذا العنصر"
  },
  "text_mappings": {
    "delete_message": "سيتم حذف التصنيف الحرفي",
    "delete_confirmation": "تأكيد حذف التصنيف الحرفي",
    "title": "تصنيف النتائج الحرفية",
    "create": {
      "title": "تصنيف جديد"
    }
  },
  "import_export": {
    "console": "تصنيف جديد",
    "title": "تصنيف جديد",
    "status": "حالة تحميل الخطة"
  },
  "formulas": {
    "formula_list": "قائمة المعادلات",
    "formula_tooltip_kpi": "يمكنك إدخال عنصر قياس مشترك بإستخدام رمز @ و إدراج عنصر قياس (الجهة) باستخدام الرمز #",
    "formula_tooltip": " يمكنك استخدام الرموز التالية في قالب المعادلة- ، + ،/ ،* ،()",
    "no_formulas_available": "لا توجد معادلة قياس",
    "delete_yes_no_title": "تأكيد حذف قالب معادلة القياس",
    "delete_yes_no_message": "أنت على وشك حذف قالب معادلة القياس",
    "title_manage": " قالب المعادلة",
    "formula_pick_name": "اسم المعادلة الحسابية",
    "formula_pick_structure": " صيغة المعادلة",
    "formula_console_icon": "إدارة قوالب المعادلات ",
    "formulas_template": "إدارة قوالب المعادلات"
  },
  "info": {
    "reporting": "يتم حالياً تحديث التقارير",
    "pmo_manual_progress": "يتم حالياً تحديث دليل مستخدم مكتب رئاسة مجلس الوزراء",
    "manual_progress": "يتم حالياً تحديث دليل المستخدم"
  },
  "premissions_error": "ليس لديك الصلاحية ",
  "lookup_manager": {
    "title": "إدارة عنصر البحث",
    "enabled_active": "مفعل",
    "enabled_deactive": "غير مفعل"
  },
  "rules_management": {
    "rule": {
      "delete_yes_no_title": "تأكيد حذف قاعدة العمل",
      "delete_yes_no_information": "سيتم حذف قاعدة العمل"
    },
    "rules_management": "إدارة قواعد العمل",
    "title": "إدارة قواعد العمل",
    "add_ruleset": "إضافة قاعدة عملية",
    "edit_ruleset": "تعديل قواعد العمل",
    "ruleset_type": "تصنيف قواعد العمل",
    "ruleset_severity": "مستوى الخطورة",
    "ruleset_combination": "آلية تطبيق القاعدة",
    "snooze": "وقف التفعيل",
    "scope": "نطاق التنفيذ",
    "entities": "الجهات",
    "selected_entities": "الجهات المحددة",
    "ruleType": "نوع قاعدة العمل",
    "table": "الجدول",
    "field": "الحقل",
    "operator": "العامل",
    "value": "القيمة",
    "create": "إنشاء",
    "select_schedule": "تحديد الجدول",
    "start_hour": "ساعة البدء",
    "rules": "القواعد",
    "is_not_null": "ليست بدون قيمة",
    "conditional": "التحقق من خلال شرط",
    "complex_file": " التحقق من خلال ملف جافا",
    "available_objects": "الحقول المتوفرة",
    "trigger_actions": "الإجراء",
    "trigger_already_Exists": "المشغل موجود",
    "add_rule": "إضافة قاعدة",
    "show_log": "سجل",
    "delete_yes_no_title": "تأكيد حذف القاعدة العملية",
    "delete_yes_no_information": "أنت على وشك حذف القاعدة العملية",
    "simulation": "محاكاة"
  },
  "calculation": {
    "calculation_management": "إدارة الاحتسابات",
    "calculation_entity": "إعدادات الأداء للجهة",
    "calculation_government": "إعدادات الأداء للحكومة",
    "calculation_engine_status": "حالة محرك الحساب",
    "calculation_queue": "إدارة عملية الحساب",
    "calculation_queue_mng": "قائمة انتظار الحساب",
    "last_run": "آخر عملية ",
    "restart": "إعادة التشغيل",
    "object_type": "نوع العنصر",
    "calculation_date": "تاريخ الإحتساب",
    "result_set": "مجموعة النتيجة",
    "launch": "البدء"
  },
  "guided_tour": {
    "question": "هل تريد المساعدة، إبدأ الجولة الإرشادية ",
    "modal_title": "الجولة الإرشادية لنظام أداء 3.0"
  },
  "lookup": {
    "management": {
      "title": "إدارة عنصر البحث",
      "mes_unit": "وحدة القياس"
    },
    "content": {
      "title": "محتوى عنصر البحث",
      "delete_yes_no_title": "تأكيدحذف عنصر البحث",
      "delete_yes_no_information": "أنت على وشك حذف عنصر البحث"
    }
  },
  "box_status": {
    "annual_performance": "الأداء السنوي",
    "activity_progress": "نسبة إنجـاز النشاط",
    "current": "الأداء الحالي",
    "initiative_progress": "إنجاز المبادرات",
    "year_to_period": "الأداء السنوي"
  },
  "auditrail": {
    "title": "سجلات النظام",
    "audittrail_detail_info": "التفاصيل",
    "username": "اسم المستخدم",
    "page": "الصفحة",
    "date": "التاريخ",
    "object": "العنصر",
    "entities": "Entities",
    "kpi_type": "نوع المؤشر"
  },
  "entity_migration": {
    "title": "إدارة بيانات الجهة"
  },
  "perform_analysis": {
    "console_icon": "تحليل الأداء",
    "title": "الأداء",
    "card_title": "تحليل الأداء",
    "modal_title": "تحليل الأداء",
    "action_date": "التاريخ",
    "actions": "الإجراءات",
    "new_action": "إجراء جديد",
    "pending": "قيد الإنتظار",
    "work_in_progress": "قيد التنفيذ",
    "done": "تم",
    "users_comments": "ملاحظات المستخدم ",
    "request_status": "حالة الطلب",
    "view_card": "عرض",
    "button_done": "تعديل حالة الطلب",
    "performStatus_pending": "حالة الطلب بحاجة لتعديل"
  },
  "attachment": {
    "max_file_size": "الحد الأقصى لحجم الملف هو 25 ميجا بايت",
    "import_file_types_allowed": "أنواع المرفقات المتاحة: XLS, XLSX, XLSM",
    "file_types_allowed": "أنواع المرفقات المتاحة: PDF,ZIP,TXT,Microsoft Office (Word,Excel,PowerPoint)"
  },
  "plan_wizard": {
    "title": {
      "review_approve": "مراجعة واعتماد الخطة"
    },
    "console_icon": {
      "review_approve": "مراجعة واعتماد الخطة"
    },
    "labels": {
      "missionAE": "الرسالة (بالعربية)",
      "missionEN": "الرسالة (بالإنجليزية)",
      "visionAE": "الرؤية (بالعربية)",
      "visionEN": "الرؤية (بالإنجليزية)",
      "strengthAE": " نقاط القوة (بالعربية)",
      "strengthEN": "نقاط القوة (بالإنجليزية)",
      "opportunityAE": "الفرص (بالعربية)",
      "opportunityEN": "الفرص (بالإنجليزية)",
      "weaknessAE": "نقاط الضعف (بالعربية)",
      "weaknessEN": "نقاط الضعف (بالإنجليزية)",
      "threatAE": "المخاطر (بالعربية)",
      "threatEN": " المخاطر (بالإنجليزية)",
      "challenges": "التحديات"
    },
    "steps": {
      "strategic_plan": "الخطة الاستراتيجية",
      "mission_vision": "الرؤية والرسالة"
    },
    "status_button": {
      "send_to_review": "إرسال للمراجعة",
      "send_to_approval": "إرسال للاعتماد"
    },
    "plan_status": {
      "approved": "تم الاعتماد",
      "pmo_approval": "اعتماد مكتب رئاسة مجلس الوزراء",
      "reviewed": "تمت المراجعة",
      "pmo_review": "مراجعة مكتب رئاسة مجلس الوزراء",
      "not_reviewed": "لم تتم المراجعة"
    }
  },
  "documents": {
    "title": "تقارير الجهة",
    "files_allowed": "أنواع المرفقات المتاحة PDF, DOC, DOCX, TXT",
    "new": "تحميل ملف",
    "remove_file_title": "تأكيد حذف المستند\n",
    "remove_file": "أنت على وشك حذف المستند",
    "attachdate": "تاريخ"
  },
  "workflow_rejection_scheduler": {
    "title": "جدولة رفض سير العمل",
    "pending_days": "عدد الأيام العالقة",
    "buttons": {
      "submit": "موافق",
      "submit_config": "موافق"
    },
    "form_titles": {
      "scheduler_configuration": "إعدادات جدولة سير العمل"
    }
  },
  "audit_data": {
    "tooltips": {
      "clone": "استنساخ"
    },
    "errors": {
      "oneGroupAtLeast": "يجب أن يكون هناك مجموعة تقييم واحدة على الأقل.",
      "oneEvaluationItemAtLeast": "يجب أن يكون هناك عنصر تقييم واحد لكل مجموعة على الأقل",
      "invalidGroup": "مجموعة مكررة",
      "invalidSet": "عنصر مكرر داخل مجموعة",
      "invalid_id": "بطاقة التعريف غير صالحة",
      "connected_cycles": "لا يمكن الحذف ، هناك دورات تدقيق متصلة بهذه المجموعة",
      "duplicate_record": "تسجيل مزدوج"
    },
    "clone_criteria_set": "مجموعة معايير الاستنساخ",
    "cloned_name_ae": "اسم المجموعة المستنسخة (عربي)",
    "cloned_name_en": "اسم المجموعة المستنسخة (الإنجليزية)",
    "part_of": "جزء من",
    "audit_criteria_item_delete_yes_no_title": "حذف مجموعة / عنصر التقييم",
    "audit_criteria_item_delete_yes_no_information": "هل أنت متأكد أنك تريد حذف هذه المجموعة / العنصر؟",
    "create_custom_evaluation_item": "قم بإنشاء مجموعة / عنصر تقييم",
    "view_audit_criteria": "عرض معايير المراجعة",
    "update_audit_criteria": "تحديث معايير التدقيق",
    "view_audit_cycle": "عرض دورة التدقيق",
    "update_audit_cycle": "تحديث دورة التدقيق",
    "create": "انشاء",
    "add_evaluation_group": "أضف مجموعة التقييم",
    "evaluation_group": "مجموعة التقييم",
    "create_custom_evaluation_group": "قم بإنشاء مجموعة تقييم مخصصة",
    "existing_audit_criteria_set": "مجموعة المعايير الحالية",
    "audit_criteria_delete_yes_no_title": "تأكيد حذف معايير التدقيق",
    "audit_criteria_delete_yes_no_information": "معايير التدقيق على وشك الحذف ، هل أنت متأكد؟",
    "audit_cycle_delete_yes_no_title": "تأكيد حذف دورة التدقيق",
    "audit_cycle_delete_yes_no_information": "دورة التدقيق على وشك الحذف ، هل أنت متأكد؟",
    "add_audit_criteria": "إنشاء معايير التدقيق",
    "evaluation_items": "عناصر التقييم",
    "audit_criteria": "معايير المراجعة",
    "audit_cycle": "دورة المراجعة",
    "delete_yes_no_title": "تأكيد الحذف",
    "connected_entities": "الجهات المتصلة",
    "available_kpis": "المؤشرات المتاحة",
    "connected_kpis": "المؤشرات المتصلة",
    "available_entities": "الجهات المتاحة",
    "delete_yes_no_information": "تأكيد الحذف",
    "title": "التدقيق",
    "kpi_actuals": "تدقيق القيم الفعلية للمؤشرات",
    "sponsor_entity": "جهة الراعية",
    "audit_cycles": "دورات المراجعة",
    "audit_criterias": "معايير المراجعة",
    "add_audit_cycle": "قم بإنشاء دورة تدقيق جديدة",
    "audit_year": "سنة المراجعة",
    "audit_criteria_set": "مجموعة معايير التدقيق",
    "entities": "الجهات",
    "kpi_types": "أنواع مؤشرات الأداء الرئيسية",
    "kpi_audit_issues_statement": "هناك ملاحظة تدقيق لهذا المؤشر للسنوات التالية",
    "audit_issues": "قائمة المؤشرات التي بحاجة للمراجعة والتصحيح وفق عمليات التدقيق",
    "define_target_issues_statement": "يجب تحديد قيم المستهدفات"
  },
  "kpi_annual_weights": {
    "conf": "إعداد الأوزان السنوية للمؤشرات",
    "strategic_kpi": "المتوسطات الموزونة للمؤشرات الاستراتيجية",
    "enabler_kpi": "المتوسطات الموزونة لمؤشرات الممكنات",
    "operational_kpi": "المتوسطات الموزونة للمؤشرات التشغيلية",
    "service_kpi": "المتوسطات الموزونة لمؤشرات الخدمة",
    "prev_page": "الصفحة السابقة",
    "next_page": "الصفحة التالية",
    "num_of_pages": "عدد الصفحات الكلي",
    "enabled": "مفعل",
    "disabled": "معطل",
    "next": "الصفحة التالية",
    "previous": "الصفحة السابقة",
    "start": "يبدأ",
    "end": "ينتهي",
    "sort": "Sort",
    "prev": "السابق",
    "search": "بحث بواسطة اسم المؤشر",
    "curr": "الحالي",
    "tooltips": {
      "period_kpi_issue": "الأوزان السنوية لهذه السنة بحاجة لتصحيح، يمكنكم الضغط على السنة لمعرفة أي أداء بحاجة لمراجعة وتصحيح الأوزان السنوية",
      "kpi_annual_weights_issue_homepage": "لا يمكن احتساب هذه القيمة لأن مجموع الأوزان السنوية لا يساوي 100%. الرجاء الذهاب إلى صفحة الأوزان السنوية للمؤشرات للتصحيح",
      "equal_distribution": "توزيع الأوزان بشكل متساوٍ",
      "conf_disabled_warn_msg": "تم إلغاء تفعيل الأوزان لهذه السنة"
    },
    "msg": {
      "wfconfig_not_default": "لا يمكن تفعيل الأوزان السنوية للمؤشرات لعدم توفر إعدادات سير العمل المناسبة.\nالرجاء التواصل مع مسؤول النظام",
      "no_weight_distribution": "توزيع الأوزان بشكل متساوٍغير متاح",
      "kpi_created": "تم إنشاء المؤشر المميز أعلاه",
      "kpi_deleted": "تم حذف المؤشر",
      "kpi_updated": "تم تعديل المؤشر المميز أعلاه"
    },
    "modal": {
      "equal_distribution": {
        "title": "هل أنت متأكد",
        "msg": "أنت على وشك تعديل الأوزان السنوية لكل المؤشرات في هذه السنة"
      }
    }
  }
}
