@if (!showDIffOnly() || hasAnyDiff()) {
    <div class="d-block w-100 py-4">
        <h3 class="fs-3 mb-1">{{ "nkpi.targets" | translate }}</h3>
    </div>

    <div class="d-flex row">
        <div class="col-4 fw-bold px-3 py-2 text-center text-primary tbl-col tbl-target-col border border-white"></div>

        <div class="col-4 fw-bold px-3 py-2 text-center text-primary tbl-col tbl-target-col border border-white" [class.col-8]="showOnlyNew()">
            {{ !showOnlyNew() ? ("communication.difference.new_value" | translate) : ("communication.difference.value" | translate) }}
        </div>

        @if (!showOnlyNew()) {
            <div class="col-4 fw-bold px-3 py-2 text-center text-primary tbl-col tbl-target-col border border-white">
                {{ "communication.difference.old_value" | translate }}
            </div>
        }
    </div>

    @if (!isBounded()) {
        <div class="d-flex row">
            @for (item of data(); track item.id) {
                @if (!showDIffOnly() || showOnlyNew() || item.isDifferent) {
                    <div class="col-4 fw-bold px-3 py-2 tbl-col tbl-target-col border border-white">
                        {{ getPeriod(item) }}
                    </div>

                    <div
                        class="col-4 px-3 py-2 text-center tbl-col tbl-target-col border border-white"
                        [class.col-8]="showOnlyNew()"
                        [class.tbl-diff]="item.isDifferent"
                    >
                        {{ getValue("new", item) }}
                    </div>

                    @if (!showOnlyNew()) {
                        <div class="col-4 px-3 py-2 text-center tbl-col tbl-target-col border border-white" [class.tbl-diff]="item.isDifferent">
                            {{ getValue("old", item) }}
                        </div>
                    }
                }
            }
        </div>
    }

    @if (isBounded()) {
        <div class="d-flex row">
            <div class="col-4 fw-bold px-3 py-2 text-center text-primary tbl-col tbl-target-col border border-white"></div>

            <div class="col-2 fw-bold px-3 py-2 text-center text-primary tbl-col tbl-target-col border border-white" [class.col-4]="showOnlyNew()">
                {{ "kpi.lower_limit" | translate }}
            </div>

            <div class="col-2 fw-bold px-3 py-2 text-center text-primary tbl-col tbl-target-col border border-white" [class.col-4]="showOnlyNew()">
                {{ "kpi.upper_limit" | translate }}
            </div>

            @if (!showOnlyNew()) {
                <div class="col-2 fw-bold px-3 py-2 text-center text-primary tbl-col tbl-target-col border border-white">
                    {{ "kpi.lower_limit" | translate }}
                </div>

                <div class="col-2 fw-bold px-3 py-2 text-center text-primary tbl-col tbl-target-col border border-white">
                    {{ "kpi.upper_limit" | translate }}
                </div>
            }
        </div>

        <div class="d-flex row">
            @for (item of data(); track item.id) {
                @if (!showDIffOnly() || showOnlyNew() || item.isDifferent) {
                    <div class="col-4 fw-bold px-3 py-2 tbl-col tbl-target-col border border-white">
                        {{ getPeriod(item) }}
                    </div>

                    <div
                        class="col-2 px-3 py-2 text-center tbl-col tbl-target-col border border-white"
                        dir="ltr"
                        [class.col-4]="showOnlyNew()"
                        [class.tbl-diff]="item.isDifferent"
                    >
                        {{ getLimit("new", "low", item) }}
                    </div>

                    <div
                        class="col-2 px-3 py-2 text-center tbl-col tbl-target-col border border-white"
                        dir="ltr"
                        [class.col-4]="showOnlyNew()"
                        [class.tbl-diff]="item.isDifferent"
                    >
                        {{ getLimit("new", "high", item) }}
                    </div>

                    @if (!showOnlyNew()) {
                        <div
                            class="col-2 px-3 py-2 text-center tbl-col tbl-target-col border border-white"
                            dir="ltr"
                            [class.tbl-diff]="item.isDifferent"
                        >
                            {{ getLimit("old", "low", item) }}
                        </div>

                        <div
                            class="col-2 px-3 py-2 text-center tbl-col tbl-target-col border border-white"
                            dir="ltr"
                            [class.tbl-diff]="item.isDifferent"
                        >
                            {{ getLimit("old", "high", item) }}
                        </div>
                    }
                }
            }
        </div>
    }
}
