import { NgClass } from "@angular/common";
import { Component, EventEmitter, inject, input, OnInit, Output, signal } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { CurrencyValuePipe, PercentageValuePipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { FilesApiService, LanguageService } from "../../../services";
import { CountUpComponent } from "../../count-up.component";

const LG_FULL_CIRCLE = 754;
const MD_FULL_CIRCLE = 630;
const SM_FULL_CIRCLE = 503;
const XS_FULL_CIRCLE = 311.358;
const XXS_FULL_CIRCLE = 190;

@Component({
  selector: "adaa-doughnut",
  standalone: true,
  templateUrl: "./doughnut.component.html",
  styleUrl: "./doughnut.component.scss",
  imports: [FontAwesomeModule, TranslateModule, CountUpComponent, CurrencyValuePipe, PercentageValuePipe, NgClass],
})
export class DoughnutComponent implements OnInit {
  readonly _languageService = inject(LanguageService);
  private readonly _filesApiService = inject(FilesApiService);

  colorId = input.required<number>();
  label = input<string>();
  amount = input.required<number, number>({
    transform: ($a: number | null | undefined) => {
      if (!AdaaHelper.isDefined($a)) return 0;
      const $n = Number($a);
      if (isNaN($n)) return 0;
      return $n;
    },
  });
  description = input<string>();
  size = input<"lg" | "md" | "sm" | "xs" | "xxs">("sm");
  showProgressCircle = input<boolean>(true);
  circleColor = input<string>("#d2d2d2");
  showGraphIcon = input<boolean>(false);
  symbol = input<"%" | "N" | "C">("%");
  enableAnimation = input<boolean>(true);
  labelAsImage = input<boolean>(false);
  labelImage = input<{ imageId: number | string; imageSize: "sm" | "xs" | "xxs" }>();

  labelImageURI = signal<string | undefined>(undefined);

  @Output() onclick = new EventEmitter<"graphClicked" | "chartClicked">();

  readonly graphIcon = faChartSimple;

  public get color() {
    return Constants.COLOR_CODES[this.colorId()];
  }

  public get graphCurve() {
    const value = this.amount();
    if (!value) {
      return "0 999";
    }
    const percentage = (this.getHundredPercent * value) / 100;
    return `${percentage} 999`;
  }

  public get chartConfig() {
    switch (this.size()) {
      case "lg":
        return this._chartSize().lg;
      case "md":
        return this._chartSize().md;
      case "sm":
        return this._chartSize().sm;
      case "xs":
        return this._chartSize().xs;
      case "xxs":
        return this._chartSize().xxs;
    }
  }

  public get getHundredPercent() {
    switch (this.size()) {
      case "lg":
        return LG_FULL_CIRCLE;
      case "md":
        return MD_FULL_CIRCLE;
      case "sm":
        return SM_FULL_CIRCLE;
      case "xs":
        return XS_FULL_CIRCLE;
      case "xxs":
        return XXS_FULL_CIRCLE;
    }
  }

  public ngOnInit() {
    this._downloadImage();
  }

  public graphClicked(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    this.onclick.emit("graphClicked");
  }

  public chartClicked(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    this.onclick.emit("chartClicked");
  }

  private _downloadImage() {
    const labelAsImage = this.labelAsImage();
    const imageDetails = this.labelImage();
    if (labelAsImage && imageDetails && typeof imageDetails?.imageId === "number") {
      this._filesApiService.download(imageDetails.imageId, false).subscribe({
        next: (blob) => {
          this.labelImageURI.set(window.URL.createObjectURL(blob));
        },
      });
    } else {
      this.labelImageURI.set(<string>imageDetails?.imageId);
    }
  }

  private _chartSize() {
    return {
      lg: {
        cy: 140,
        cx: 140,
        r: 120,
        container: "275px",
        label: {
          position: "6.25rem",
          amountFontSize: "2rem",
          labelFontSize: "1.175rem",
        },
      },
      md: {
        cy: 120,
        cx: 120,
        r: 100,
        container: "235px",
        label: {
          position: this.labelAsImage() ? "2.25rem" : "3.5rem",
          amountFontSize: "1.925rem",
          labelFontSize: "0.975rem",
        },
      },
      sm: {
        cy: 100,
        cx: 100,
        r: 80,
        container: "195px",
        label: {
          position: this.labelAsImage() ? "2.125rem" : "3.215rem",
          amountFontSize: "1.725rem",
          labelFontSize: "0.725rem",
        },
      },
      xs: {
        cy: 70,
        cx: 70,
        r: 50,
        container: "135px",
        label: {
          position: "2.7rem",
          amountFontSize: "1.1rem",
          labelFontSize: undefined,
        },
      },
      xxs: {
        cy: 50,
        cx: 50,
        r: 30,
        container: "100px",
        label: {
          position: "1.9rem",
          amountFontSize: "0.8rem",
          labelFontSize: undefined,
        },
      },
    };
  }
}
