<div class="row mb-2">
    <div class="col-11">
        @if (cardTitle()) {
            <h6 [class]="classes()">{{ cardTitle()! | translate }}</h6>
        }
    </div>
    <div class="col-1 d-flex flex-row-reverse">
        @if (isEditable()) {
            <button type="button" class="btn btn-sm bg-white text-primary rounded-circle shadow-md" (click)="openAddModal()">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            </button>
        }
    </div>
</div>
@if (comments() && comments()!.length > 0) {
    @for (comment of comments(); track comment.id; let i = $index) {
        <adaa-note
            [number]="showNumber() ? i + 1 : undefined"
            [title]="comment | translateToken: titleToken()"
            [subTitle]="comment[dateToken()] | date: constants.dateTime"
            [message]="comment | translateToken: messageToken()"
            [isEditable]="isEditable()"
            (removeClicked)="commentRemoved.emit(comment)"
        />
    }
} @else if (comments() == null || comments()!.length === 0) {
    <div class="row">
        <div class="col-12">
            {{ noCommentsMessage() | translate }}
        </div>
    </div>
}
