<div class="modal-header">
    <h4 class="modal-title">{{ "user.create.modal.role.title" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body" [formGroup]="grantsForm">
    @if (adaaHelper.isPMOEntity()) {
        <div class="row">
            <div class="col-12">
                <adaa-form-dropdown
                    [label]="'common.form.label.entity' | translate"
                    [options]="entities"
                    formControlName="entityId"
                    controlName="entityId"
                    [clearable]="true"
                    [searchable]="true"
                    (inputChanges)="entityIdChanged($event)"
                ></adaa-form-dropdown>
            </div>
        </div>
    }
    <div class="row">
        <div class="col-12">
            <adaa-form-dropdown-tree
                [label]="'user.create.modal.role.organizationunit' | translate"
                [options]="orgUnitsOptions()"
                formControlName="orgUnitId"
                controlName="orgUnitId"
                (inputChanges)="orgUnitIdChanged()"
            ></adaa-form-dropdown-tree>
            @if (useNewDS() && grantsForm.get("orgUnitId")!.value !== null) {
                <label class="text-warning text-center fw-bold">
                    {{ "user.create.modal.role.organizationUnitWarning" | translate }}
                </label>
            }
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <adaa-form-two-way-select
                #grantsSelect
                [notSelectedLabel]="'user.create.modal.role.avaliableroles'"
                [selectedLabel]="'user.create.modal.role.userroles'"
                [options]="grantsOptions"
                formControlName="grants"
                controlName="grants"
                (inputChanges)="grantsChanged($event)"
            ></adaa-form-two-way-select>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        {{ "common.form.button.close" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        {{ "common.form.button.save" | translate }}
    </button>
</div>
