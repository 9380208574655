import { inject, Injectable, signal } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { TpTeamMembersModalComponent } from "../components";
import { genericFloatButtons } from "../components/general/float-action";
import { Constants } from "../constants/constants";
import { Language } from "../constants/enums";
import {
  AttachmentModelType,
  CardNote,
  MainResponseModel,
  MilestoneSignOffAttachment,
  SubMilestone,
  TableButtonClicked,
  TeamMemberType,
  TpMilestone,
} from "../models";
import { AppService } from "./app.service";
import { CardNoteApiService } from "./card-note-api.service";
import { FilesApiService } from "./files-api.service";
import { TpMilestoneApiService } from "./tp-milestone-api.service";

@Injectable({
  providedIn: "root",
})
export class MilestoneDetailsService {
  private _tpMilestoneApiService = inject(TpMilestoneApiService);
  private _cardNoteApiServide = inject(CardNoteApiService);
  private _filesApiService = inject(FilesApiService);
  private _appService = inject(AppService);
  private _modalService = inject(NgbModal);

  private readonly _genericFloatButtons = genericFloatButtons();
  milestone = signal<TpMilestone | undefined>(undefined);
  notes = signal<CardNote[]>([]);
  subMilestones = signal<SubMilestone[]>([]);

  readonly fetchMilestone = (id: number) => {
    return forkJoin([
      this._tpMilestoneApiService.getMilestone(id),
      this._cardNoteApiServide.getAllByItemType(id, Constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE),
      this._tpMilestoneApiService.getSubMilestones(id),
    ]);
  };

  public init([milestone, notes, subMilestones]: [
    MainResponseModel<TpMilestone>,
    MainResponseModel<CardNote[]>,
    MainResponseModel<SubMilestone[]>,
  ]) {
    this.milestone.set(this._formatMilestone(milestone.responseData));
    this.notes.set(this._formatNotes(notes.responseData));
    this.subMilestones.set(this._formatSubMilestone(subMilestones.responseData));

    this._floatActions(milestone.responseData);
  }

  public downloadAttachment(attachment: AttachmentModelType | MilestoneSignOffAttachment) {
    this._filesApiService.save(attachment.id, attachment.filename, true);
  }

  public openTeamMembers(teamMembers: TeamMemberType[] | undefined = undefined) {
    const modal = this._modalService.open(TpTeamMembersModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.teamMembers = teamMembers ?? this.milestone()?.teamMembers;
    modal.componentInstance.configKey = "milestone_member_conf_list";
    modal.componentInstance.title = teamMembers
      ? "national_projects.national_projects_milestone.sub_milestone_team_members"
      : "national_projects.national_projects_milestone.team_members";
  }

  public openSubMilestoneTeamMembers(event: TableButtonClicked) {
    if (event.event === "teamMembers") {
      this.openTeamMembers(event.data.teamMembers);
    }
  }

  public getNotes(): void {
    if (this.milestone()?.id) {
      this._cardNoteApiServide
        .getAllByItemType(this.milestone()!.id, Constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE)
        .subscribe({
          next: (response) => {
            if (response.inError) return;

            this.notes.set(this._formatNotes(response.responseData));
          },
        });
    }
  }

  private _formatNotes(notes: CardNote[]): CardNote[] {
    return notes.sort((a, b) => {
      if (a.createTS < b.createTS) return 1;
      else if (a.createTS > b.createTS) return -1;
      else return 0;
    });
  }

  private _formatSubMilestone(subMilestones: SubMilestone[]): SubMilestone[] {
    subMilestones.forEach((e, index) => {
      e.number = `#${index + 1}`;
      e.teamMembers = e.teamMembers
        ? e.teamMembers.map((t, tindex) => ({
            ...t,
            number: `#${tindex + 1}`,
          }))
        : [];
    });

    return subMilestones;
  }

  private _formatMilestone(milestone: TpMilestone): TpMilestone {
    if (AdaaHelper.isDefined(milestone.teamMembers)) {
      milestone.teamMembers = milestone.teamMembers.map((e, index) => ({
        ...e,
        number: `#${index + 1}`,
      }));
    }
    return milestone;
  }

  private _floatActions(milestone: TpMilestone): void {
    this._genericFloatButtons([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "edit",
        data: {
          url: `/console/national-project/project-milestones/edit/${milestone.id}`,
        },
      },
      {
        key: "print",
        data: undefined,
      },
      {
        key: "connection",
        data: undefined,
      },
      {
        key: "addNote",
        data: {
          itemId: milestone.id,
          itemTypeId: Constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE,
        },
      },
      {
        key: "workflowHistory",
        data: {
          itemId: milestone.id,
          itemTypeId: Constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE,
        },
      },
      {
        key: "email",
        data: {
          objectName: AdaaHelper.getItemValueByToken(milestone, "name"),
        },
      },
      {
        key: "systemLog",
        data: {
          itemId: milestone.id,
          itemTypeId: [Constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE],
        },
      },
    ]);
  }
}
