@if (showView()) {
    <h4 class="mt-4 mb-2">
        {{ useNewDS() ? ("national_strategies.strategies" | translate) : ("initiatives.directions" | translate) }}
    </h4>

    <section class="row">
        <div
            class="col-6 p-2 border border-white tbl-col rounded-start-1 bg-white text-primary fw-bold text-center"
            [class.text-start]="showOnlyNew()"
            [class.ms-1]="showOnlyNew()"
        >
            {{ showOnlyNew() ? ("communication.difference.value" | translate) : ("communication.difference.new_value" | translate) }}
        </div>

        @if (!showOnlyNew()) {
            <div class="col-6 p-2 border border-white tbl-col bg-white text-primary fw-bold text-center">
                {{ "communication.difference.old_value" | translate }}
            </div>
        }
    </section>

    <section class="row">
        <div class="col-6" [class.col-12]="showOnlyNew()">
            @for (govDir of newData()?.govDirections; track govDir.id) {
                <adaa-linked-national-strategies-card-view [data]="govDir" [useNewDS]="useNewDS()" />
            }
        </div>

        @if (!showOnlyNew()) {
            <div class="col-6">
                @for (govDir of oldData()?.govDirections; track govDir.id) {
                    <adaa-linked-national-strategies-card-view [data]="govDir" [useNewDS]="useNewDS()" />
                }
            </div>
        }
    </section>
}
