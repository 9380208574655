import { Component, computed, inject, input } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { Constants } from "../../../../../../constants/constants";
import { AdaaBoolean, Language } from "../../../../../../constants/enums";
import { LanguageService } from "../../../../../../services";
import type {
  BaseTargetsViewDataType,
  BoundedTargetsViewDataType,
  TargetsViewDataType,
  UnboundedTargetsViewDataType,
} from "../../wf-difference.types";

@Component({
  selector: "adaa-targets-diff-view",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./targets-diff-view.component.html",
  styleUrl: "../styles.scss",
})
export class TargetsDiffViewComponent {
  private _languageService = inject(LanguageService);
  private _translateService = inject(TranslateService);

  showOnlyNew = input.required<boolean>();
  newData = input<Record<string, never>>();
  oldData = input<Record<string, never>>();
  showDIffOnly = input<boolean>(false);

  isBounded = computed(() => this.newData()!.hasTarget === AdaaBoolean.Y);
  trend = computed(() => this.newData()!.trend as number);
  frequency = computed(() => this.newData()!.frequency as number);
  baselineYear = computed(() => (this.newData()?.baselineYear ?? null) as number | null);
  isBaselineKpi = computed(() => this.newData()?.baselineKpi === AdaaBoolean.Y);
  newTargets = computed(() => {
    if (!this.newData()) return [];
    if (!this.newData()?.targets) return [];
    if (!Array.isArray(this.newData()?.targets)) return [];
    return this.newData()!.targets as BaseTargetsViewDataType[];
  });
  oldTargets = computed(() => {
    if (!this.oldData()) return [];
    if (!this.oldData()?.targets) return [];
    if (!Array.isArray(this.oldData()?.targets)) return [];
    return this.oldData()!.targets as BaseTargetsViewDataType[];
  });
  hasAnyDiff = computed(() => {
    if (this.showOnlyNew()) return true;

    const data = this.data();
    return data.some(({ isDifferent }) => isDifferent);
  });
  data = computed<TargetsViewDataType[]>(() => {
    const cache: TargetsViewDataType[] = [];
    for (const data of this.newTargets()) {
      const oldData = this.oldTargets().find(({ id }) => id === data.id);

      if (this.isBounded() && oldData) {
        const object: BoundedTargetsViewDataType = {
          ...data,
          targetsType: "bounded",
          newHighLimit: data.highLimit,
          oldHighLimit: oldData.highLimit as number | null,
          newLowerLimit: data.lowerLimit,
          oldLowerLimit: oldData.lowerLimit as number | null,
          isDifferent: oldData.highLimit !== data.highLimit || oldData.lowerLimit !== data.lowerLimit,
        };

        cache.push(object);
      } else if (!this.isBounded() && oldData) {
        const object: UnboundedTargetsViewDataType = {
          ...data,
          targetsType: "unbounded",
          newValue: data.value,
          oldValue: oldData.value as number | null,
          isDifferent: oldData.value !== data.value,
        };

        cache.push(object);
      } else {
        cache.push({
          ...data,
          targetsType: "unbounded",
          newValue: data.value,
          oldValue: null,
          isDifferent: true,
        });
      }
    }

    for (const data of this.oldTargets()) {
      const newData = this.newTargets().find(({ id }) => id === data.id);

      if (newData) continue;

      if (this.isBounded()) {
        const object: BoundedTargetsViewDataType = {
          ...data,
          targetsType: "bounded",
          newHighLimit: null,
          oldHighLimit: data.highLimit,
          newLowerLimit: null,
          oldLowerLimit: data.lowerLimit,
          isDifferent: true,
        };

        cache.push(object);
      } else {
        const object: UnboundedTargetsViewDataType = {
          ...data,
          targetsType: "unbounded",
          newValue: null,
          oldValue: data.value,
          isDifferent: true,
        };

        cache.push(object);
      }
    }

    return cache;
  });

  public getPeriod({ month, year, quarter, semester }: TargetsViewDataType): string {
    let periodText = "";

    switch (this.frequency()) {
      case Constants.FREQUENCY_MONTHLY:
        periodText = Constants.months[this._languageService.current()][month] + " " + year;
        break;

      case Constants.FREQUENCY_QUARTERLY: {
        if (this._languageService.current() === Language.Arabic) {
          const text = this._translateService.instant(`targets.periods.quarters.${quarter}`);
          periodText = `${text} ${year}`;
        } else {
          const quarterlyText = this._translateService.instant(`targets.quarter`);
          periodText = `${quarterlyText}${quarter} ${year}`;
        }
        break;
      }

      case Constants.FREQUENCY_SEMIANNUAL: {
        if (this._languageService.current() === Language.Arabic) {
          const text = this._translateService.instant(`targets.periods.semestral.${semester}`);
          periodText = `${text} ${year}`;
        } else {
          const semesterText = this._translateService.instant(`targets.semiannual`);
          periodText = `${semesterText} ${semester} ${year}`;
        }
        break;
      }

      case Constants.FREQUENCY_ANNUAL:
      case Constants.FREQUENCY_EVERY_TWO_YEARS:
      case Constants.FREQUENCY_EVERY_THREE_YEARS:
      case Constants.FREQUENCY_EVERY_FOUR_YEARS:
      case Constants.FREQUENCY_EVERY_FIVE_YEARS:
        periodText = year.toString();
        break;
      default:
        return "";
    }

    if (this.baselineYear() && year === this.baselineYear() && this.isBaselineKpi()) {
      const baselineText = this._translateService.instant("kpi.baselineYear");
      periodText += ` (${baselineText}) `;
    }

    return periodText;
  }

  public getValue(type: "new" | "old", item: TargetsViewDataType) {
    if (this.isBounded()) return;

    if (type === "new") {
      return (item as UnboundedTargetsViewDataType).newValue;
    } else {
      return (item as UnboundedTargetsViewDataType).oldValue;
    }
  }

  public getLimit(type: "new" | "old", side: "high" | "low", item: TargetsViewDataType) {
    if (!this.isBounded()) return;

    if (type === "new") {
      return (item as BoundedTargetsViewDataType)[side === "high" ? "newHighLimit" : "newLowerLimit"];
    } else {
      return (item as BoundedTargetsViewDataType)[side === "high" ? "oldHighLimit" : "oldLowerLimit"];
    }
  }
}
