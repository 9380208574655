import { Component, inject, Input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { PageMode, WFTriggerRuleType } from "../../../constants/enums";
import { ValueText, WorkflowTriggerConfig } from "../../../models";
import { WorkflowsApiService } from "../../../services";
import { FormDropdownComponent, FormInputComponent } from "../../form";

@Component({
  selector: "adaa-workflow-triggers-rules-modal",
  imports: [TranslateModule, ReactiveFormsModule, FormInputComponent, FormDropdownComponent],
  templateUrl: "./workflow-triggers-rules-modal.component.html",
  styleUrl: "./workflow-triggers-rules-modal.component.scss",
})
export class WorkflowTriggersRulesModalComponent implements OnInit {
  private readonly _formBuilder = inject(FormBuilder);
  private readonly _toastrService = inject(ToastrService);
  private readonly _translateService = inject(TranslateService);
  private readonly _wfApiService = inject(WorkflowsApiService);
  readonly modal = inject(NgbActiveModal);

  @Input() rule: WorkflowTriggerConfig;
  @Input() pageMode: PageMode;
  @Input() entityId: number;
  @Input() actionTypeId: number;
  @Input() itemTypeId: number;

  PageMode = PageMode;
  ruleForm: FormGroup;
  submited = false;
  templates = signal<ValueText[]>([]);
  rules = signal<ValueText[]>([]);
  configurations = signal<ValueText[]>([]);
  fieldTypes = signal<ValueText[]>([]);
  operatorOptions = signal<ValueText[]>([
    { value: "<", text: "<" },
    { value: ">", text: ">" },
    { value: "=", text: "=" },
  ]);

  public get isFieldRequired() {
    return (
      this.ruleForm.get("workflowRuleTypeId")?.value === WFTriggerRuleType.FIELD_CHECK ||
      this.ruleForm.get("workflowRuleTypeId")?.value === WFTriggerRuleType.VALUE_CHECK
    );
  }

  public get isOperatorAndValueRequired() {
    return this.ruleForm.get("workflowRuleTypeId")?.value === WFTriggerRuleType.VALUE_CHECK;
  }

  public ngOnInit(): void {
    this._prepareForm();
    this._initPage();

    //Make Sure the field is number
    if (this.rule) this.rule.field = this.rule.field ? +this.rule.field : undefined;
    this.ruleForm.patchValue(this.rule);

    if (this.pageMode !== PageMode.view) this.ruleTypeChanged(this.rule?.workflowRuleTypeId);
  }

  public ruleTypeChanged(event: number | undefined): void {
    const ruleParamsFields = ["field", "operator", "value"];

    switch (event) {
      case WFTriggerRuleType.FIELD_CHECK:
        this.ruleForm.get("field")?.enable();
        this.ruleForm.get("operator")?.disable();
        this.ruleForm.get("value")?.disable();
        break;
      case WFTriggerRuleType.VALUE_CHECK:
        ruleParamsFields.forEach((field) => {
          this.ruleForm.get(field)?.enable();
        });
        break;
      case WFTriggerRuleType.FALL_BACK_CHECK:
      case undefined:
      case null:
        ruleParamsFields.forEach((field) => {
          this.ruleForm.get(field)?.setValue(null);
          this.ruleForm.get(field)?.disable();
        });
        break;
      default:
        break;
    }
  }

  public save(): void {
    this.submited = true;

    if (this.ruleForm.invalid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const data = this.ruleForm.getRawValue();

    if (!data.id) delete data.id;
    else delete data.fakeId;

    const template = this.templates().find((e) => e.value === data.workflowTemplateId);
    data.workflowTemplateNameAE = template?.text;
    data.workflowTemplateNameEN = template?.text;

    this.modal.close(data);
  }

  private _prepareForm(): void {
    const isView = this.pageMode === PageMode.view;

    this.ruleForm = this._formBuilder.group({
      id: { value: null, disabled: isView },
      field: { value: null, disabled: true },
      nameAE: [{ value: null, disabled: isView }, Validators.required],
      nameEN: [{ value: null, disabled: isView }, Validators.required],
      operator: { value: null, disabled: true },
      order: { value: null, disabled: isView },
      status: { value: null, disabled: isView },
      triggerOrder: { value: null, disabled: isView },
      value: { value: null, disabled: true },
      wfConfigId: { value: null, disabled: isView },
      workflowRuleTypeId: [{ value: null, disabled: isView }, Validators.required],
      workflowTemplateId: [{ value: null, disabled: isView }, Validators.required],
      workflowTemplateNameAE: { value: null, disabled: isView },
      workflowTemplateNameEN: { value: null, disabled: isView },
      fakeId: { value: null, disabled: isView },
    });
  }

  private _initPage(): void {
    forkJoin({
      templates: this._wfApiService.getWorkflowTriggerTemplates(),
      rules: this._wfApiService.getWorkflowTriggerRuleTypes(),
      configurations: this._wfApiService.getWorkflowTriggerConfiguration(
        this.entityId,
        this.actionTypeId,
        this.itemTypeId
      ),
      fieldTypes: this._wfApiService.getWorkflowTriggerFieldypes(this.itemTypeId),
    }).subscribe({
      next: (results) => {
        if (!results.templates.inError)
          this.templates.set(
            AdaaHelper.setDropdownArray(results.templates.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.rules.inError)
          this.rules.set(
            AdaaHelper.setDropdownArray(results.rules.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.configurations.inError)
          this.configurations.set(
            AdaaHelper.setDropdownArray(results.configurations.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.fieldTypes.inError)
          this.fieldTypes.set(
            AdaaHelper.setDropdownArray(results.fieldTypes.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );
      },
    });
  }
}
