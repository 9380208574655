import { Component, inject, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { PageMode } from "../../../constants/enums";
import { AuxGovDirection } from "../../../models";
import { GovernmentDirectionsEditorService } from "../../../services";
import { FormInputComponent } from "../../form";

@Component({
  selector: "adaa-aux-direction-modal",
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, FormInputComponent],
  templateUrl: "./aux-direction-modal.component.html",
  styleUrl: "./aux-direction-modal.component.scss",
})
export class AuxDirectionModalComponent {
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);

  modal: NgbActiveModal = inject(NgbActiveModal);
  directionsService = inject(GovernmentDirectionsEditorService);

  @Input() auxDirection: AuxGovDirection;
  @Input() pageMode: PageMode = PageMode.view;

  submitted: boolean = false;
  PageMode = PageMode;
  auxDirectionForm: FormGroup;

  public ngOnInit(): void {
    this.auxDirectionForm = this.directionsService.prepareAuxDirectionForm();
    this.auxDirectionForm.patchValue(this.auxDirection);
  }

  public save(): void {
    this.submitted = true;
    if (!this.auxDirectionForm.valid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    this.auxDirection.nameAE = this.auxDirectionForm.get("nameAE")?.value;
    this.auxDirection.nameEN = this.auxDirectionForm.get("nameEN")?.value;
    this.auxDirection.dscAE = this.auxDirectionForm.get("dscAE")?.value;
    this.auxDirection.dscEN = this.auxDirectionForm.get("dscEN")?.value;

    this.modal.close(this.auxDirection);
  }
}
