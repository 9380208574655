export enum CountryFlags {
  AFGHANISTAN = "af",
  ALBANIA = "al",
  ALGERIA = "dz",
  ANDORRA = "ad",
  ANGOLA = "ao",
  ARGENTINA = "ar",
  ARMENIA = "am",
  AUSTRALIA = "au",
  AUSTRIA = "at",
  AZERBAIJAN = "az",
  BAHAMAS = "bs",
  BAHRAIN = "bh",
  BANGLADESH = "bd",
  BARBADOS = "bb",
  BELARUS = "by",
  BELGIUM = "be",
  BELIZE = "bz",
  BENIN = "bj",
  BHUTAN = "bt",
  BOLIVIA = "bo",
  BOSNIA_AND_HERZEGOVINA = "ba",
  BOTSWANA = "bw",
  BRAZIL = "br",
  BRUNEI = "bn",
  BULGARIA = "bg",
  BURKINA_FASO = "bf",
  BURUNDI = "bi",
  CAMBODIA = "kh",
  CAMEROON = "cm",
  CANADA = "ca",
  CAPE_VERDE = "cv",
  CENTRAL_AFRICAN_REPUBLIC = "cf",
  CHAD = "td",
  CHILE = "cl",
  CHINA = "cn",
  COLOMBIA = "co",
  COMOROS = "km",
  CONGO_DEMOCRATIC = "cd",
  CONGO_REPUBLIC = "cg",
  COSTA_RICA = "cr",
  CROATIA = "hr",
  CUBA = "cu",
  CYPRUS = "cy",
  CZECHIA = "cz",
  DENMARK = "dk",
  DJIBOUTI = "dj",
  DOMINICA = "dm",
  DOMINICAN_REPUBLIC = "do",
  ECUADOR = "ec",
  EGYPT = "eg",
  EL_SALVADOR = "sv",
  EQUATORIAL_GUINEA = "gq",
  ERITREA = "er",
  ESTONIA = "ee",
  ESWATINI = "sz",
  ETHIOPIA = "et",
  FIJI = "fj",
  FINLAND = "fi",
  FRANCE = "fr",
  GABON = "ga",
  GAMBIA = "gm",
  GEORGIA = "ge",
  GERMANY = "de",
  GHANA = "gh",
  GREECE = "gr",
  GRENADA = "gd",
  GUATEMALA = "gt",
  GUINEA = "gn",
  GUINEA_BISSAU = "gw",
  GUYANA = "gy",
  HAITI = "ht",
  HONDURAS = "hn",
  HUNGARY = "hu",
  ICELAND = "is",
  INDIA = "in",
  INDONESIA = "id",
  IRAN = "ir",
  IRAQ = "iq",
  IRELAND = "ie",
  ISRAEL = "il",
  ITALY = "it",
  IVORY_COAST = "ci",
  JAMAICA = "jm",
  JAPAN = "jp",
  JORDAN = "jo",
  KAZAKHSTAN = "kz",
  KENYA = "ke",
  KIRIBATI = "ki",
  KUWAIT = "kw",
  KYRGYZSTAN = "kg",
  LAOS = "la",
  LATVIA = "lv",
  LEBANON = "lb",
  LESOTHO = "ls",
  LIBERIA = "lr",
  LIBYA = "ly",
  LIECHTENSTEIN = "li",
  LITHUANIA = "lt",
  LUXEMBOURG = "lu",
  MADAGASCAR = "mg",
  MALAWI = "mw",
  MALAYSIA = "my",
  MALDIVES = "mv",
  MALI = "ml",
  MALTA = "mt",
  MARSHALL_ISLANDS = "mh",
  MAURITANIA = "mr",
  MAURITIUS = "mu",
  MEXICO = "mx",
  MICRONESIA = "fm",
  MOLDOVA = "md",
  MONACO = "mc",
  MONGOLIA = "mn",
  MONTENEGRO = "me",
  MOROCCO = "ma",
  MOZAMBIQUE = "mz",
  MYANMAR = "mm",
  NAMIBIA = "na",
  NAURU = "nr",
  NEPAL = "np",
  NETHERLANDS = "nl",
  NEW_ZEALAND = "nz",
  NICARAGUA = "ni",
  NIGER = "ne",
  NIGERIA = "ng",
  NORTH_KOREA = "kp",
  NORTH_MACEDONIA = "mk",
  NORWAY = "no",
  OMAN = "om",
  PAKISTAN = "pk",
  PALAU = "pw",
  PANAMA = "pa",
  PAPUA_NEW_GUINEA = "pg",
  PARAGUAY = "py",
  PERU = "pe",
  PHILIPPINES = "ph",
  POLAND = "pl",
  PORTUGAL = "pt",
  QATAR = "qa",
  REPUBLIC_OF_KOREA = "kr",
  ROMANIA = "ro",
  RUSSIA = "ru",
  RWANDA = "rw",
  SAUDI_ARABIA = "sa",
  SENEGAL = "sn",
  SERBIA = "rs",
  SEYCHELLES = "sc",
  SIERRA_LEONE = "sl",
  SINGAPORE = "sg",
  SLOVAKIA = "sk",
  SLOVENIA = "si",
  SOLOMON_ISLANDS = "sb",
  SOMALIA = "so",
  SOUTH_AFRICA = "za",
  SOUTH_SUDAN = "ss",
  SPAIN = "es",
  SRI_LANKA = "lk",
  SUDAN = "sd",
  SURINAME = "sr",
  SWEDEN = "se",
  SWITZERLAND = "ch",
  SYRIA = "sy",
  TAJIKISTAN = "tj",
  TANZANIA = "tz",
  THAILAND = "th",
  TIMOR_LESTE = "tl",
  TOGO = "tg",
  TONGA = "to",
  TRINIDAD_AND_TOBAGO = "tt",
  TUNISIA = "tn",
  TURKEY = "tr",
  TURKMENISTAN = "tm",
  UGANDA = "ug",
  UKRAINE = "ua",
  UNITED_ARAB_EMIRATES = "ae",
  UNITED_KINGDOM = "gb",
  UNITED_STATES = "us",
  URUGUAY = "uy",
  UZBEKISTAN = "uz",
  VANUATU = "vu",
  VATICAN_CITY = "va",
  VENEZUELA = "ve",
  VIETNAM = "vn",
  YEMEN = "ye",
  ZAMBIA = "zm",
  ZIMBABWE = "zw",
}

export enum CountryFlagsArabic {
  أفغانستان = "af",
  ألبانيا = "al",
  الجزائر = "dz",
  أندورا = "ad",
  أنغولا = "ao",
  الأرجنتين = "ar",
  أرمينيا = "am",
  أستراليا = "au",
  النمسا = "at",
  أذربيجان = "az",
  البحرين = "bh",
  بنغلاديش = "bd",
  بيلاروسيا = "by",
  بلجيكا = "be",
  بليز = "bz",
  بنين = "bj",
  بوتان = "bt",
  بوليفيا = "bo",
  البوسنة_والهرسك = "ba",
  بوتسوانا = "bw",
  البرازيل = "br",
  بلغاريا = "bg",
  بوركينا_فاسو = "bf",
  بوروندي = "bi",
  كمبوديا = "kh",
  الكاميرون = "cm",
  كندا = "ca",
  تشيلي = "cl",
  الصين = "cn",
  كولومبيا = "co",
  جزر_القمر = "km",
  كوستاريكا = "cr",
  كرواتيا = "hr",
  كوبا = "cu",
  قبرص = "cy",
  التشيك = "cz",
  الدنمارك = "dk",
  جيبوتي = "dj",
  جمهورية_الكونغو_الديمقراطية = "cd",
  الإكوادور = "ec",
  مصر = "eg",
  السلفادور = "sv",
  إريتريا = "er",
  إستونيا = "ee",
  إثيوبيا = "et",
  فنلندا = "fi",
  فرنسا = "fr",
  الجابون = "ga",
  جورجيا = "ge",
  ألمانيا = "de",
  غانا = "gh",
  اليونان = "gr",
  غواتيمالا = "gt",
  غينيا = "gn",
  هايتي = "ht",
  الهند = "in",
  إندونيسيا = "id",
  إيران = "ir",
  العراق = "iq",
  أيرلندا = "ie",
  إسرائيل = "il",
  إيطاليا = "it",
  اليابان = "jp",
  الأردن = "jo",
  كازاخستان = "kz",
  كينيا = "ke",
  الكويت = "kw",
  قيرغيزستان = "kg",
  لاوس = "la",
  لاتفيا = "lv",
  لبنان = "lb",
  ليبيا = "ly",
  ليتوانيا = "lt",
  لوكسمبورغ = "lu",
  مدغشقر = "mg",
  ماليزيا = "my",
  المالديف = "mv",
  مالي = "ml",
  مالطا = "mt",
  موريتانيا = "mr",
  موريشيوس = "mu",
  المكسيك = "mx",
  مولدوفا = "md",
  موناكو = "mc",
  منغوليا = "mn",
  المغرب = "ma",
  موزمبيق = "mz",
  ميانمار = "mm",
  ناميبيا = "na",
  نيبال = "np",
  هولندا = "nl",
  نيوزيلندا = "nz",
  نيكاراغوا = "ni",
  النيجر = "ne",
  نيجيريا = "ng",
  النرويج = "no",
  سلطنة_عمان = "om",
  باكستان = "pk",
  فلسطين = "ps",
  بنما = "pa",
  باراغواي = "py",
  بيرو = "pe",
  الفلبين = "ph",
  بولندا = "pl",
  البرتغال = "pt",
  دولة_قطر = "qa",
  جمهورية_كوريا = "kr",
  رومانيا = "ro",
  روسيا = "ru",
  رواندا = "rw",
  المملكة_العربية_السعودية = "sa",
  السنغال = "sn",
  صربيا = "rs",
  سنغافورة = "sg",
  سلوفاكيا = "sk",
  سلوفينيا = "si",
  الصومال = "so",
  جنوب_أفريقيا = "za",
  إسبانيا = "es",
  سريلانكا = "lk",
  السودان = "sd",
  السويد = "se",
  سويسرا = "ch",
  سوريا = "sy",
  تايوان = "tw",
  طاجيكستان = "tj",
  تنزانيا = "tz",
  تايلاند = "th",
  تونس = "tn",
  تركيا = "tr",
  تركمانستان = "tm",
  أوغندا = "ug",
  أوكرانيا = "ua",
  الإمارات_العربية_المتحدة = "ae",
  المملكة_المتحدة = "gb",
  الولايات_المتحدة_الأمريكية = "us",
  أوزبكستان = "uz",
  فنزويلا = "ve",
  فيتنام = "vn",
  اليمن = "ye",
  زامبيا = "zm",
  زيمبابوي = "zw",
}
