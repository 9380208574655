import { Component, inject, input, type OnDestroy, type OnInit, signal } from "@angular/core";
import type { ChartConfiguration, ChartType } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { Subscription } from "rxjs";

import { ChartsService, LanguageService, SystemLayoutService } from "../../../services";
import { DataProviderService } from "./_data-provider.service";

@Component({
  selector: "adaa-entity-overall-performance",
  standalone: true,
  imports: [BaseChartDirective],
  templateUrl: "./entity-overall-performance.component.html",
  styleUrl: "./entity-overall-performance.component.scss",
})
export class EntityOverallPerformanceComponent implements OnInit, OnDestroy {
  private readonly _languageService = inject(LanguageService);
  private readonly _systemLayoutService = inject(SystemLayoutService);
  private readonly _dataProvider = inject(DataProviderService);
  private readonly _chartService = inject(ChartsService);

  entityId = input<number>();
  periodId = input<number>();
  aggregationType = input<number>();

  chartData = signal<ChartConfiguration["data"]>({
    labels: [],
    datasets: [],
  });
  chartOptions = signal<ChartConfiguration["options"] | undefined>(undefined);

  readonly lineChartType: ChartType = "line";

  private _subscription = new Subscription();

  public ngOnInit() {
    this._getData();

    this._subscription.add(
      this._systemLayoutService.hasActiveEntityChanged$.subscribe({
        next: () => this._getData(),
      })
    );

    this._subscription.add(
      this._systemLayoutService.hasCycleChanged$.subscribe({
        next: () => this._getData(),
      })
    );
  }

  public ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private _getData() {
    this._dataProvider.gatherData(this.aggregationType()!, this.entityId()!, this.periodId()!).subscribe({
      next: (data) => {
        this._chartService.initLineChartOptions({
          title: "",
          isRank: false,
        });

        this.chartOptions.update(() => ({
          ...this._chartService.lineChartOptions,
        }));

        this.chartData.update(() => data);
      },
    });
  }
}
